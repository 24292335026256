import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';


@Component({
	selector: 'staff-overview-view-part',
	templateUrl: './overview-view.part.html',
	styleUrls: ['./overview-view.part.scss'],
})
export class StaffOverviewViewPart extends DestroyablePart implements OnInit {


	constructor(public util: UtilityService,
		public ds: DataService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

}