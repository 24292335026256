import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { DestroyablePart } from '@me-access-parts';
import { Field, FieldStatus, FieldTool, FieldValueType, LabelKey } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { LabelsService } from '@me-services/ui/labels';
import { BehaviorSubject } from 'rxjs';
import { applyDefaultFieldProperties } from '../apply-default-field-properties';
import { FieldFormState } from '../field-form-state';
import { BaseField } from './base-field';

@Component({
	selector: 'base-field',
	template: '', // ignored since this is a base component
})

export abstract class BaseFieldPart<T extends FieldValueType> extends DestroyablePart implements BaseField, OnInit, OnDestroy, OnChanges {

	@Input() field: Field;
	@Input() value: FieldValueType;
	@Input() tool: FieldTool;
	@Input() forceValidation = false;
	@Input() state: FieldFormState;
	@Output() fieldUpdate = new EventEmitter<FieldValueType>();


	translatedValueIn: T;
	currentValue: T;
	pendingValue: T;

	isFocused = false;
	hasBeenFocused = false;

	//
	// If status is set to any of the named statuses then it will show in the bottom-right
	//
	status: FieldStatus = undefined;
	@Output() statusChange = new BehaviorSubject<FieldStatus>(undefined);

	//
	// Any string assigned will display as an error and disallow saving
	//
	errorText: string = undefined;

	text = {
		label: '',
		errorRequired: '',
		statusPending: '',
		statusSaving: '',
		statusSaved: '',
		statusNoAuth: '',
		statusChanged: '',
		optional: '',
		placeholder: '',
	};

	questionKey: LabelKey = undefined;
	questionLiteral: string = undefined;

	clearStatusTimeout: number;

	constructor(
		protected func: FuncService,
		protected labelsService: LabelsService,
	) {
		super();
	}

	/**
	 * This method is used with the beforeunload window event. It determines
	 * whether the user should be warned about possibly losing changes when
	 * attempting to close the browser or tab.
	 */
	alertIfPending = (e => {
		if (this.status == 'pending' || this.status == 'saving') {
			if (e) {
				e.preventDefault();
				e.returnValue = this.text.statusPending;
			}
			return this.text.statusPending;
		}
	}).bind(this);


	async ngOnChanges(changes: SimpleChanges) {

		if (changes.value) {

			this.translatedValueIn = this.translateValueIn(changes.value.currentValue);

			if (!this.valuesAreSame(this.currentValue, this.translatedValueIn)) {

				if (!this.isFocused || !this.isChanged) {

					this.reset();

					if (!changes.value.isFirstChange()) {
						this.setStatus('changed', true);
					}
				}

				if (!changes.value.isFirstChange()) {
					this.recordWhetherCompleted();
				}
			}
		}


		if (changes.forceValidation &&
			changes.forceValidation.currentValue &&
			!changes.forceValidation.isFirstChange()) {

			this.checkForError();
		}

	}


	async ngOnInit() {
		super.initDestroyable();

		//
		// Check for required attributes
		//
		if (!this.field) throw new Error('Missing required attribute: field');
		if (!this.state) throw new Error('Missing required attribute: state');
		if (!this.tool) throw new Error('Missing required attribute: tool');


		//
		// Warn the user if they are attempting to close the browser tab with pending changes
		//
		window.addEventListener('beforeunload', this.alertIfPending);


		//
		// Preprocess the field
		//
		const field = this.field;
		applyDefaultFieldProperties(field);


		this.recordWhetherCompleted();


		if (field.maxLength >= 20) {
			const placeholder = await this.labelsService.get({ key: 'Up to XXXX characters' });
			this.text.placeholder = placeholder.split('XXXX').join(field.maxLength.toLocaleString());
		}
		else this.text.placeholder = '';

		if (field.labelIsQuestion) {

			if (typeof field.label == 'string') this.questionLiteral = field.label;
			else this.questionKey = field.label;

			this.text.label = await this.labelsService.get({ key: 'Your Answer' });
		}
		else {
			this.text.label = await this.labelsService.get(field.label);
		}

		//
		// Text translations
		//
		this.text.optional = await this.labelsService.get({ key: 'optional' });
		this.text.errorRequired = await this.labelsService.get({ key: 'Required field' });
		this.text.statusPending = await this.labelsService.get({ key: 'Pending Changes' });
		this.text.statusSaving = await this.labelsService.get({ key: 'Saving' });
		this.text.statusSaved = await this.labelsService.get({ key: 'Saved' });
		this.text.statusNoAuth = await this.labelsService.get({ key: 'Not Authorized' });
		this.text.statusChanged = await this.labelsService.get({ key: 'Changed by Someone Else' });

		if (this.forceValidation) this.checkForError();

	}


	override ngOnDestroy() {
		super.ngOnDestroy();
		window.removeEventListener('beforeunload', this.alertIfPending);
	}


	/**
	 * Returns true if the pending value is different than the current value.
	 */
	public get isChanged() {
		return !this.valuesAreSame(this.pendingValue, this.currentValue);
	}


	private reset(skipForceValidation = false) {

		this.currentValue = this.translatedValueIn;
		this.pendingValue = this.translatedValueIn;
		this.errorText = undefined;
		if (this.forceValidation && !skipForceValidation) this.checkForError();

	}


	/**
	 * This should be called whenever a subclass changes a value.
	 * It should happen reasonably rapidly, such as with each key
	 * press in a text or textarea.
	 * @param pendingValue 
	 */
	public setPendingValue(pendingValue: T) {

		if (this.valuesAreSame(this.currentValue, pendingValue)) {
			if (this.status == 'pending') this.setStatus(undefined);
		}
		else {
			if (this.status !== 'pending') this.setStatus('pending');
		}


		if (this.valuesAreSame(this.pendingValue, pendingValue)) return;

		this.pendingValue = pendingValue;
		this.checkForError();

	}


	/**
	 * This should be called whenever the input control in a
	 * subclass receives or loses focus.
	 * @param focused 
	 */
	public setFocused(isFocused: boolean) {

		const wasFocused = this.isFocused;
		this.isFocused = isFocused;
		if (isFocused) this.hasBeenFocused = true;

		if (!isFocused && wasFocused) {
			if (this.isChanged) {
				this.attemptToSave();
			}
			else {
				if (!this.valuesAreSame(this.translatedValueIn, this.currentValue)) {
					//
					// If these three conditions were met then we reset
					//   1. Focus was lost (it was focused and then removed)
					//   2. Nothing was changed
					//   3. The value is different than when the focus was gained
					//
					this.reset(true);
					this.setStatus('changed', true);
				}

				this.checkForError();
			}
		}
	}


	/**
	 * If there are changes and no errors then we save it
	 */
	async attemptToSave() {

		const uncleanedValue = this.pendingValue;
		this.pendingValue = this.cleanValue(this.pendingValue);

		if (this.checkForError()) return;

		if (!this.isChanged) {
			//
			// If pendingValue is different than currentValue only due to pendingValue being 
			// cleaned then we update the current value to the cleaned one.
			//
			if (this.pendingValue !== uncleanedValue) {

				//
				// Set the uncleaned value and then the pending value with timeouts to
				// circumvent the distinctUntilChanged behavior of the databinding.
				//
				setTimeout(() => {
					this.currentValue = uncleanedValue;
					setTimeout(() => {
						this.currentValue = this.pendingValue; // pendingValue was cleaned a few lines ago
					});
				});

			}

			this.recordWhetherCompleted();
			return;
		}


		this.setStatus('saving');

		const pendingValueTranslated = this.translateValueOut(this.pendingValue);
		const success = await this.func.field.update({ field: this.field.context, value: pendingValueTranslated, tool: this.tool });

		if (success) {
			this.translatedValueIn = this.currentValue = this.pendingValue;
			this.fieldUpdate.emit(pendingValueTranslated);
			this.reset();
			this.setStatus('saved', true);
		}
		else {
			this.setStatus('no-auth');
		}

		this.recordWhetherCompleted();
	}

	/**
	 * Remove any pending status
	 */
	revert() {
		if (this.status !== 'pending') return;

		this.reset();
		this.setStatus(undefined);
		this.checkForError();
	}


	/**
	 * When the status is set to either 'changed' or 'saved' then a call to this method
	 * will schedule for that status to be cleared after a few seconds.
	 */
	private setClearStatusTimeout() {
		this.clearStatusTimeout = window.setTimeout((() => { this.setStatus(undefined); }).bind(this), 3000);
	}


	/**
	 * When the status is set to either 'changed' or 'saved' then a timeout is scheduled
	 * to clear that value.  Before setting the status, this method should be called to
	 * cancel any pending timeout, otherwise the new status would be removed.
	 */
	private clearPendingStatusTimeout() {

		if (this.clearStatusTimeout) {
			window.clearTimeout(this.clearStatusTimeout);
			this.clearStatusTimeout = undefined;
		}
	}


	/**
	 * Sets the current status, which the html will then display appropriately.
	 * @param status 
	 * @param autoRemoveAfterDelay
	 */
	protected setStatus(status: FieldStatus, autoRemoveAfterDelay = false) {

		this.clearPendingStatusTimeout();

		if (status !== this.status) {
			this.status = status;
			this.statusChange.next(status);
		}

		if (autoRemoveAfterDelay) this.setClearStatusTimeout();
	}


	private checkForError(): boolean {

		let hasError = false;

		if (this.field.required && !this.hasPendingValue()) {
			this.errorText = this.text.errorRequired;
			hasError = true;
		}

		if (!hasError) hasError = this.checkForAdditionalError();

		this.recordWhetherCompleted();

		if (!hasError) this.errorText = undefined;
		return hasError;
	}


	/**
	 * Record if this field is considered completed or not in the state
	 * object. This powers the progress bar and whether the user can
	 * submit the application.
	 */
	private recordWhetherCompleted() {
		this._completed =
			!this.errorText &&
			(this.status == undefined || this.status == 'saved') &&
			(!this.field.required || this.hasCurrentValue());

		this.state.setCompleted(this.field.key, this._completed);
	}

	private _completed: boolean;
	public get completed() { return this._completed; }



	////////////////////////////////////////////////////////////////////////////////////////////
	//                      Methods that may be overidden in the sub class                    //
	////////////////////////////////////////////////////////////////////////////////////////////


	/**
	 * Determine if there are any errors specifically for the subclassed field part.
	 * Override this method in subclass that needs its own checks
	 */
	public checkForAdditionalError(): boolean {
		return false;
	}


	/**
	* This method can be changed in a sub class to tell the checkForError()
	* method whether the pending value is considered missing. The default
	* implementation returns whether the pending value is truthy.
	*/
	protected hasPendingValue(): boolean {
		return !!this.cleanValue(this.pendingValue);
	}


	/**
	* This method can be changed in a sub class to determine
	* whether the field has a valid value. The default
	* implementation returns whether the current value is truthy.
	*/
	protected hasCurrentValue(): boolean {
		return !!this.currentValue;
	}


	/**
	 * This method can be changed in a sub class to make any adjustments to
	 * the internal pending value, such as trimming, fixing case, etc. The
	 * default implementation returns the value unchanged.
	 * 
	 * Use translateValue() instead if you need to make changes that are
	 * related with data I/O such as dealing with null.
	 */
	protected cleanValue(value: T): T {
		return value; // By default, do nothing
	}


	/**
	 * This method can be changed in a sub class to determine if two values
	 * are semantically the same. The default implementation does a simple
	 * == comparison.
	 */
	protected valuesAreSame(value1: T, value2: T): boolean {
		return value1 == value2;
	}


	/**
	 * This method may be changed in a sub class to make any adjustments to
	 * the value just after it came into this component (as read from the db)
	 */
	protected translateValueIn(value: FieldValueType): T {
		return <T>value;
	}

	/**
	 * This method may be changed in a sub class to make any adjustments to
	 * the value just before it is sent out of this component (as saved to the db).
	 */
	protected translateValueOut(value: T): FieldValueType {
		if (value === '' && this.field.saveEmptyStringAsNull) return null;
		if (value === 0 && this.field.saveZeroAsNull) return null;
		return value;
	}
}