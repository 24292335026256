import { ApplicationStatusId } from '@me-interfaces';
import { DdApplicationStatusRow } from './dd-application-status-row';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';

/**
 * @deprecated Use the DataService instead
 */
export class DdApplicationStatus extends DdObject<DdApplicationStatusRow> implements DdApplicationStatusRow {

	constructor(dd: OldDomainDataService, data: DdApplicationStatusRow) {
		super(data);
	}

	public get applicationStatusId(): ApplicationStatusId {
		return this._data.applicationStatusId;
	}

	public get name(): string {
		return this._data.name;
	}
}