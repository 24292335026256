<div class='application-display' *ngIf='application'>

	<div class='application-header' *ngIf='!showHeader'>
		<me-h2>{{ application.overview.programInstanceName }} </me-h2>
		<div>{{ application.overview.siteName }}</div>
	</div>

	<ng-container *ngIf='!access.canUpdate'>
		<div *ngIf='application.overview.tool === "Application_StaffViewer"' class="application-status" [class.div-green]="divColor ==='green'"
			[class.div-red]="divColor ==='red'">
			{{getApplicationStatusName(application.overview.applicationStatusId)}}
		</div>

		<application-viewer-message *ngIf='viewerMessage && showViewerMessage' [viewerMessage]='viewerMessage'>
		</application-viewer-message>

		<application-viewer *ngIf='!isApplStaffEditor' [application]='application'
			(statusChange)='changeStatus($event)'></application-viewer>
		<div class='cannot-update' *ngIf='isApplStaffEditor'>This application cannot be edited at this time. The program
			is not in the Accepting Applications stage.</div>
	</ng-container>

	<application-editor *ngIf='access.canUpdate' [application]='application' (statusChange)='changeStatus($event)'>
	</application-editor>

</div>