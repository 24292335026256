<div class='acc-final-tab-part'>

	<acc-stage-summary [accStageId]="accStageId"></acc-stage-summary>

	<div class="inline-check-list">
		<me-icon *ngIf="stageStatusIcon" [icon]="stageStatusIcon.icon"></me-icon>
		<me-icon *ngIf="eventsStatusIcon" [href]="eventsStatusIcon.link" [icon]="eventsStatusIcon.icon"></me-icon>
		<me-icon *ngIf="judgesStatusIcon" [href]="judgesStatusIcon.link" [icon]="judgesStatusIcon.icon">
		</me-icon>
		<me-icon *ngIf="teamsStatusIcon" [href]="teamsStatusIcon.link" [icon]="teamsStatusIcon.icon">
		</me-icon>
	</div>

	<acc-judge-counts-part></acc-judge-counts-part>

</div>