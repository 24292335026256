import { Component, Input, OnChanges } from '@angular/core';

@Component({
	selector: 'grid-cell-ratings',
	templateUrl: './ratings.part.html',
	styleUrls: ['ratings.part.scss'],
})
export class RatingsPart implements OnChanges {

	//
	// A four-number array representing counts of N!, N, Y, and Y!
	//
	@Input() ratings: [number, number, number, number];

	r1 = 0;
	r2 = 0;
	r3 = 0;
	r4 = 0;

	ngOnChanges() {
		const r = this.ratings;
		if (r) {
			this.r1 = this.getNumber(r[0]);
			this.r2 = this.getNumber(r[1]);
			this.r3 = this.getNumber(r[2]);
			this.r4 = this.getNumber(r[3]);
		}
		else {
			this.r1 = 0;
			this.r2 = 0;
			this.r3 = 0;
			this.r4 = 0;
		}
	}


	private getNumber(value: unknown) {
		if (typeof value === 'number') return value;
		const num = parseInt('' + value, 10);
		if (isNaN(num)) return 0;
		return num;
	}


	getClass() {
		if (this.r1) return 'rating score-1';
		else if (this.r2) return 'rating score-2';
		else if (this.r3) return 'rating score-3';
		else if (this.r4) return 'rating score-4';
	}
}
