import { Component, Input, OnChanges } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PersonPositionExtract } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { DialogService } from '@me-services/ui/dialog';
import { getIconForIndustry, Icon } from '@me-shared-parts/UI-common';
import { CompanyExtractDialog } from '../../../CM-company/company-extract-dialog/SHR-CM_company-extract-dialog.dialog';
import { PersonExtractDialog } from '../SHR-PN_person-extract-dialog.dialog';

@Component({
	selector: 'person-extract-positions',
	templateUrl: './SHR-PN_person-extract-positions.part.html',
	styleUrls: ['./SHR-PN_person-extract-positions.part.scss']
})
export class PersonExtractPositionsPart implements OnChanges {

	@Input() positions: PersonPositionExtract[];
	@Input() dialogRef: MatDialogRef<PersonExtractDialog>;

	getIconForIndustry = getIconForIndustry;

	icons = {
		company: Icon.concept_company,
	};

	showAllPositions = false;

	constructor(private dialogService: DialogService, private func: FuncService) {
	}

	ngOnChanges() {
		this.showAllPositions = false;
	}

	async openCompany(position: PersonPositionExtract) {

		if (!position.company.industryId) return;

		const company = await this.func.company.get({ companyId: position.company.companyId })

		this.dialogRef.close();

		await this.dialogService.showCustom(
			CompanyExtractDialog,
			{
				data: {
					company,
				},
			},
			800, 500);

	}
}