<app-area-access [areaService]='accAreaService' accessKey="assessments" [showReadonlyMessage]="true">

	<div class='acc-final-pitch-editor-view-part' *ngIf="data$|async; let data;">
		<message-line *ngIf='accelerator?.accStageId !== AccStageId_Curriculum && !readonly' alertType='warning' [closeable]="false">
			Accelerator must be in stage "Curriculum" to make changes.
		</message-line>
		<div class="selection-toolbar">
			<div>
				<div>Judge</div>
				<me-droplist [items]='data.judgeList' [selectedItem]='judgeSelectedItem' sortBy='text'
					(selectionChange)='onJudgeChange($event)' [readonly]='readonly'>
				</me-droplist>
			</div>
			<div>
				<div>Team</div>
				<me-droplist [items]='data.teamList' [selectedItem]='teamSelectedItem' sortBy='text'
					(selectionChange)='onTeamChange($event)' [readonly]='readonly || !judgeSelectedItem'>
				</me-droplist>
			</div>
			<spinner-button
				[disabled]='readonly || !teamSelectedItem || !judgeSelectedItem || accelerator.accStageId !== AccStageId_Curriculum'
				(safeClick)='load()'>Add/Edit</spinner-button>

		</div>

		<div *ngIf='!readonly && judgeAssessment'>
			<div>
				<form [formGroup]="form" novalidate>
					<me-h2>Review of: {{ teamSelectedItem.data.companyName }}</me-h2>

					<editable-questions [answers]='judgeAssessment.answers' [stepper]='stepper' [formGroup]='form'>
					</editable-questions>

					<button-container>
						<spinner-button (safeClick)='save()' [disabled]='form.invalid || !form.dirty || saving'
							[spinning]='saving'>
							<span *ngIf='!this.judgeAssessment.answersExist'>Submit Review</span>
							<span *ngIf='this.judgeAssessment.answersExist'>Update Review</span>
						</spinner-button>
					</button-container>
				</form>
			</div>
		</div>
	</div>

</app-area-access>