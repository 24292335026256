import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { CaptionedLayout } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { StaffMemberService } from '@me-services/old-services-and-wrappers/staff-member';
import { UserAreaService } from '@me-user-area';
import { SiteAreaService } from '@SITE-area';
import { lastValueFrom, take } from 'rxjs';


interface ViewModel {
	director: CaptionedLayout,
	altDirector: CaptionedLayout,
	eforallStaff: CaptionedLayout[],
	canSetDirector: boolean,
}


@Component({
	selector: 'site-config-part',
	templateUrl: './config-site.part.html',
	styleUrls: ['./config-site.part.scss'],
})
export class SiteConfigPart extends DestroyablePart implements OnInit {
	readonly: boolean;

	vm: ViewModel = {
		director: undefined,
		altDirector: undefined,
		canSetDirector: false,
		eforallStaff: []
	};


	constructor(
		private ds: DataService,
		private siteAreaService: SiteAreaService,
		private staffService: StaffMemberService,
		private userAreaService: UserAreaService) {
		super();
	}

	async ngOnInit() {
		super.initDestroyable();

		super.subscribe(
			[
				this.siteAreaService.accessAndId$,
				this.siteAreaService.data$,
			],
			async ([accessAndId, data]) => {
				this.readonly = accessAndId?.access?.sub['config'] != 'Write';

				if (data) {
					if (data.site) {
						const directorsMap = await this.ds.admin.person.getManyAsMap([data.site.directorId, data.site.altDirectorId]);
						this.vm.director = {
							caption: '',
							person: directorsMap[data.site.directorId],
							text: '',
						};

						this.vm.altDirector = data.site.altDirectorId ? {
							caption: '',
							person: directorsMap[data.site.altDirectorId],
							text: '',
						} : undefined;

					}
				}
			}
		);


		const user = await lastValueFrom(this.userAreaService.user$.pipe(take(1)));
		const staffMembers = await this.staffService.getCrmUsers();
		this.vm.eforallStaff = staffMembers.map(person => ({
			text: person._name,
			caption: person._email,
			person,
		}));
		this.vm.canSetDirector = user.isEForAllAdmin;
	}



	async setDirector(director: CaptionedLayout) {
		await this.siteAreaService.config.actions.setExecutiveDirector({
			directorId: director.person.personId,
			previousDirectorId: this.vm.director.person.personId,
		});
	}

	async setAltDirector(altDirector: CaptionedLayout) {
		await this.siteAreaService.config.actions.setAltExecutiveDirector({
			altDirectorId: altDirector.person.personId,
			previousAltDirectorId: this.vm.altDirector?.person.personId,
		});

	}

	async removeAltDirector() {
		if (!this.vm.altDirector) return;
		await this.siteAreaService.config.actions.setAltExecutiveDirector({
			altDirectorId: undefined,
			previousAltDirectorId: this.vm.altDirector?.person.personId,
		});
	}
}
