import { SearchMatchLevel } from "@me-services/core/data/search/engine/interfaces";
import { getIconContext } from "./get-icon-context";
import { Icon } from "./icon";
import { IconContext } from "./icon-context";
import { IconHideTextAt } from "./icon-hide-text-at";
import { IconStyle } from "./icon-style";

/**
 * Return the Icon value for a given Search Level
 */
export function getIconForSearchMatchLevel(level: SearchMatchLevel): Icon {

	if (level == SearchMatchLevel.Exact) return Icon.level_exact;
	if (level == SearchMatchLevel.High) return Icon.level_high;
	if (level == SearchMatchLevel.Medium) return Icon.level_medium;
	if (level == SearchMatchLevel.Low) return Icon.level_low;
	if (level == SearchMatchLevel.Related) return Icon.level_related;

	return Icon.status_missing;
}


export function getIconContextForSearchMatchLevel(level: SearchMatchLevel, iconStyle?: IconStyle, hideText?: IconHideTextAt, text?: string, hasRedFlag?: boolean): IconContext {
	return getIconContext(getIconForSearchMatchLevel(level), iconStyle, hideText, text, hasRedFlag);
}