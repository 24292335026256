import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DbsPerson } from '@me-interfaces';
import { OldEntityService } from '@me-services/old-services-and-wrappers/entity';
import { OldDomainDataService } from '@me-services/core/old-dd';
import { UtilityService } from '@me-services/core/utility';


@Component({
	selector: 'person-do-not-contact-editor',
	templateUrl: './SHR-ED_person-do-not-contact-editor.part.html',
	styleUrls: ['SHR-ED_person-do-not-contact-editor.part.scss'],
})
export class PersonDoNotContactEditorPart implements OnChanges {

	@Input() person: DbsPerson;
	@Output() updatePerson = new EventEmitter<DbsPerson>();

	newValue: string;
	orgValue: string;



	constructor(
		private util: UtilityService,
		private entityService: OldEntityService,
		public dd: OldDomainDataService) { }

	ngOnChanges() {
		this.orgValue = this.person.doNotContactNote || '';
		this.newValue = this.person.doNotContactNote || '';
	}

	onInput(e) {
		this.newValue = this.util.text.fixCase((e.target.value ?? '').trim(), 'sentence-case');
		this.checkIfCanSave();
	}

	checkIfCanSave(): boolean {
		return this.newValue != this.orgValue;
	}

	async save() {
		await this.entityService.wrapPerson(this.person).doNotContact.update(this.newValue);
		this.updatePerson.emit(this.person);
		this.resetValue();
	}

	async delete() {
		await this.entityService.wrapPerson(this.person).doNotContact.remove();
		this.updatePerson.emit(this.person);
		this.resetValue();
	}

	revert() {
		this.newValue = this.person.doNotContactNote;
	}

	resetValue() {
		this.orgValue = this.person.doNotContactNote || '';
		this.newValue = this.person.doNotContactNote || '';
	}
}
