<button #anchor (click)="toggle()" class="btn" [style.display]="popupConfig ? 'inline' : 'none'">
	<me-icon *ngIf='popupConfig' [icon]='popupConfig.icon'></me-icon>
</button>
<div class="me-droplist-part">

	<kendo-popup #popup [anchor]="anchor" class="me-droplist-button-menu-content" *ngIf="show"
		[animate]="{ type: 'slide', direction: 'down', duration: 200 }">

		<me-h3 style='margin:0 0 0.5em 0.25em'>{{popupConfig.message}}</me-h3>
		<ng-container *ngIf='popupConfig'>
			<ng-container *ngTemplateOutlet="dropdownTemplate"></ng-container>
		</ng-container>

	</kendo-popup>

	<ng-container *ngIf='!popupConfig'>
		<ng-container *ngTemplateOutlet="dropdownTemplate"></ng-container>
	</ng-container>

	<ng-template #dropdownTemplate>
		<kendo-dropdownlist [value]='selectedItem' [data]="data" [valueField]="'uniqueId'" [textField]="'text'"
			[virtual]="true" [filterable]="filterable" [itemDisabled]="itemDisabled" [defaultItem]="defaultItem"
			[disabled]='readonly' (filterChange)="handleFilter($event)" (valueChange)='valueChangeEvent($event)'
			[ngClass]='{"navigation-droplist": navigation, "btn": navigation}' style='width:100%;'
			(close)="onClose($event)">

			<ng-template kendoDropDownListNoDataTemplate>
				<i>{{missingDataMessage}}</i>
			</ng-template>

			<ng-template kendoDropDownListValueTemplate let-dataItem>
				<me-icon *ngIf='dataItem' [icon]='createListValueTemplate(dataItem)'></me-icon>
			</ng-template>

			<ng-template kendoDropDownListItemTemplate let-dataItem>
				<me-icon [icon]='createIconContext(dataItem)'></me-icon>
			</ng-template>

		</kendo-dropdownlist>
	</ng-template>
</div>