<div class="container-fluid">
	<div class="row justify-content-center">
		<div class="col-12 col-sm-8 col-md-6 col-lg-4">
			<div class="card" style='margin: 15px 0;'>
				<div class="card-body">
					<a class='btn' href="/access/my/dashboard" *ngIf="sessionMatches === undefined">
						<me-label key='Dashboard'></me-label>
					</a>
					<ng-container *ngIf="sessionMatches">
						<session-person-part [person]='sessionMatches[index]' (sendCode)="result($event)"
							*ngIf="index < sessionMatches.length"></session-person-part>
						<session-add-me-part (join)="addMe()" (cancel)='index = 0' *ngIf="index >= sessionMatches.length">
						</session-add-me-part>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>