import { AppAreaApplyResponse, AppAreaFuncResponse, AppAreaIdentifier, GoalManagement } from "@me-interfaces";
import { AppSubArea } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";

export class UserSubAreaCompany extends AppSubArea {

	constructor(
		private func: FuncService,
		ds: DataService,
		util: UtilityService,
		getId: () => AppAreaIdentifier<string>,
		applyResponse: (response: AppAreaFuncResponse) => Promise<AppAreaApplyResponse>,
	) {
		super(ds, util, getId, applyResponse);
	}


	async addCompanyAndPosition(parameters: { companyName: string, companyTypeId: number, industryId: number, title: string }) {
		return await this.call(this.func.areas.user.company.addCompanyAndPosition, parameters);
	}

	async updateCompanyTypeAndIndustry(parameters: { companyId: number, companyTypeId: number, industryId: number, title: string }) {
		return await this.call(this.func.areas.user.company.setCompanyTypeAndPositionTitle, parameters);
	}

	async addApplication(parameters: { accId: number, picId: number, companyId: number, title: string }) {
		return await this.call(this.func.areas.user.company.addApplication, parameters);
	}



}