import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DbcEventAttendee, DbsEvent } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogContext } from '@me-services/ui/dialog';
import { getActions } from '@me-shared-parts/ED-editors/shared-editors/event/event-dialog/dialog/get-actions';
import { AdminAreaService } from '../../../../../../admin/admin-area/area/admin-area.service';
import { EventAttendeeRow } from '../SHR-ED_event-attendees.part';

@Component({
	selector: 'event-attendee-dialog',
	templateUrl: './SHR-ED_event-attendee.dialog.html',
	styleUrls: ['SHR-ED_event-attendee.dialog.scss'],
})
export class EventAttendeeDialog {

	actions = getActions();

	eventAttendee: DbcEventAttendee;
	readonly: boolean;
	isAdd: boolean;

	pendingChanges: DbcEventAttendee;
	headerText = 'Add Attendee';
	wasSaved = false;
	adding = false;
	editing = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ readonly: boolean, eventAttendee: EventAttendeeRow | DbcEventAttendee, isAdd: boolean }>,
		public dialogRef: MatDialogRef<EventAttendeeDialog>,
		public ds: DataService,
		public adminAreaService: AdminAreaService,
	) {
		this.setupActions();

		const { eventAttendee, isAdd, readonly } = dialog.data;

		this.readonly = readonly;
		this.isAdd = isAdd;


		this.eventAttendee = isAdd == true ? <DbcEventAttendee>{
			...eventAttendee,
			personId: undefined,
			status: undefined,
			_concept: 'eventAttendee',
		} : { ...eventAttendee };

		if (!isAdd) this.headerText = 'Update Attendee';

		if (isAdd) {
			this.eventAttendee.personId = undefined;
			this.actions.save.label = 'Add';
			this.adding = true;
			this.setEditing(true);
		}
		else {
			this.setEditing(false);
		}

	}


	setPendingChanges(pendingChanges: DbcEventAttendee) {
		if (this.pendingChanges && !pendingChanges) this.wasSaved = true;
		this.pendingChanges = pendingChanges;
		this.actions.save.enabled = !!this.pendingChanges;
	}


	setEditing(editing: boolean) {
		this.actions.close.visible = !editing;
		this.actions.save.visible = true;
		this.actions.cancel.visible = editing;
		this.editing = editing;
	}


	setupActions() {
		this.actions.close.callback = this.close.bind(this);
		this.actions.save.callback = this.save.bind(this);
		this.actions.cancel.callback = this.cancelEditing.bind(this);
	}


	/**
	 * Called when the Close action button is clicked. If the user has saved an edit,
	 * then we return the save action with the updated attendee, instead of the close action.
	 */
	async close(): Promise<void> {
		if (this.wasSaved) {
			this.actions.save.callbackResult = await this.ds.admin.event.getOnePackage(this.eventAttendee.eventId);
			this.dialogRef.close(this.actions.save);
		}
		else {
			this.dialogRef.close(this.actions.close);
		}
	}


	/**
	 * Called when the Save action button is clicked.
	 */
	async save(): Promise<DbsEvent> {

		if (!this.pendingChanges || this.readonly) return undefined;

		this.adminAreaService.events.upsertAttendee({ eventAttendee: this.pendingChanges });
		this.eventAttendee = this.pendingChanges;

		this.actions.save.callbackResult = await this.ds.admin.event.getOnePackage(this.pendingChanges.eventId);

		this.setPendingChanges(undefined)
		this.setEditing(false);

		if (this.dialog.data.isAdd) {
			this.dialogRef.close(this.actions.save);
		}

	}


	/**
	 * Called when the Cancel action button is clicked.
	 */
	async cancelEditing() {
		if (this.adding) {
			this.dialogRef.close(this.actions.cancel);
		}
		else {
			this.setEditing(false);
		}
	}
}
