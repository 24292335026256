import { Company, PhoneTypeId } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { LinkOrExplorable } from "../../link-or-explorerable";

export async function getButtons(ds: DataService, company: Company): Promise<LinkOrExplorable[]> {

	const buttons: LinkOrExplorable[] = [];

	//
	// Phone
	//
	if (company.phone) {
		buttons.push({ link: { linkType: 'call', phone: company.phone } });

		if (company.phoneTypeId == PhoneTypeId.Mobile) {
			buttons.push({ link: { linkType: 'sms', phone: company.phone } });
		}
	}


	//
	// Weblinks
	//
	const webLinksByEntityId = await ds.admin.webLink.getArraysByForeignIds('entityId', [company.entityId]);
	const webLinks = webLinksByEntityId[company.entityId];
	const webLinksOrderByType = webLinks.sort((a, b) => a.webLinkTypeId - b.webLinkTypeId);

	for (const webLink of webLinksOrderByType) {
		buttons.push({ link: { linkType: 'webLink', url: webLink.url, webLinkTypeId: webLink.webLinkTypeId } });
	}

	//
	// Address
	//
	if (company.inUS && company.zipId) {
		buttons.push({ link: { linkType: 'address', street: company.address, zipId: company.zipId } });
	}

	// accessFlags

	return buttons;
}