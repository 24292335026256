import { AccAreaService, AccCohortPageService, AccessAtStage, TopicMaterialDialog } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DBLCLICK_GRID_ACTION_KEY, GridAction, GridColumnType, GridSetup } from '@me-grid';
import { AccSessionTopicSurveyResponse, AccStageId, DbaAccSession, DbaAccSessionTopic, DbaAccTopic, DbaAccTopicSpecialist, DbdTopic, Person } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction } from '@me-services/ui/dialog';
import { Icon } from '@me-shared-parts/UI-common';
import { Observable, combineLatest, mergeMap } from 'rxjs';
import { TopicConfigDialog } from './config-dialog/topic-config.dialog';


interface TopicRow {
	topicId: number,
	accTopicId: number,
	accSessionId: number,
	specialistNames: string,
	topicName: string,
	accTopicSpecialistIds: number[],
	sessionName: string,
	hasSpecialistOrSession: boolean,
	ddTopic: DbdTopic,
	materialsCount: number,
	topicSurveyResults: number,
	topicSurveyRelevanceScore: number,
	topicSurveyNeedScore: number,
	topicSurveyUnderstandingScore: number,
	topicSurveyDurationScore: number,
}


@Component({
	selector: 'acc-curriculum-topics-view-part',
	templateUrl: './acc-curriculum-topics-view.part.html',
})
export class AccCurriculumTopicsViewPart extends DestroyablePart implements OnInit {
	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.Curriculum);
	readonly: boolean;
	public gridSetup = this.setupGrid();

	public rows$: Observable<TopicRow[]>;

	constructor(
		private accAreaService: AccAreaService,
		public ds: DataService,
		private util: UtilityService,
		public pageService: AccCohortPageService,) {
		super();
	}

	async ngOnInit(): Promise<void> {
		super.initDestroyable();

		this.rows$ = combineLatest([
			this.ds.admin.singletonsAsOfUTC$,
			this.accessAtStage$,
			this.accAreaService.curriculum.accTopics$,
			this.accAreaService.curriculum.topicSpecialists$,
			this.accAreaService.curriculum.sessions$,
			this.accAreaService.curriculum.sessionTopics$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1], data[2], data[3], data[4], data[5])));

	}


	async buildRows(
		singletonsAsOfUTC: number,
		accessAtStage: AccessAtStage,
		accTopics: DbaAccTopic[],
		topicSpecialists: {
			person: Person,
			topicSpecialist: DbaAccTopicSpecialist,
		}[],
		sessions: DbaAccSession[],
		sessionTopics: DbaAccSessionTopic[]
	) {

		this.readonly = accessAtStage?.access != 'Write';

		if (!accTopics || !topicSpecialists || !sessions || !sessionTopics) return;

		const acc = this.accAreaService.accelerator.acc$.value;

		const ddTopics = this.ds.domain.topic.getAll();
		const ddTopicMap = this.util.array.toMap(ddTopics, topic => topic.topicId);

		//
		// Get session events
		//
		const eventIds = sessions.map(session => session.eventId);
		const eventsMap = await this.ds.admin.event.getManyAsMap(eventIds);

		//
		// Get all the topic survey records for this set of session surveys
		//
		const topicResponsesByAccId = await this.ds.admin.accSessionTopicSurveyResponse.getByAccIds([acc.accId]);
		const topicResponses = topicResponsesByAccId[acc.accId];


		const topicMaterialsMap = this.util.array.toArrayMap(this.ds.domain.topicMaterial.getAll(), r => r.topicId);



		const rows: TopicRow[] = [];
		rows.push(...accTopics.map(accTopic => {

			const ddTopic = ddTopicMap[accTopic.topicId];

			const specialists: { person: Person; topicSpecialist: DbaAccTopicSpecialist; }[] = [];
			let specialistNames = 'n/a';

			if (ddTopic.needsSpecialist) {
				specialists.push(...topicSpecialists.filter(specialist => specialist.topicSpecialist.accTopicId == accTopic.accTopicId));
				specialistNames = specialists.map(sp => sp.person.fullName).sort((a, b) => a > b ? 1 : -1).join(', ');
			}

			const sessionTopic = sessionTopics.find(sessionTopic => sessionTopic.accTopicId == accTopic.accTopicId);
			const session = sessionTopic ? sessions.find(session => session.accSessionId == sessionTopic.accSessionId) : undefined;


			//
			// Get all the topic responses for this topic and this session
			//
			const allTopicResponses: AccSessionTopicSurveyResponse[] = topicResponses.filter(r => r.eventId == session?.eventId && r.topicId == accTopic.topicId);


			return {
				accTopicId: accTopic.accTopicId,
				topicId: accTopic.topicId,
				materialsCount: topicMaterialsMap[accTopic.topicId]?.length || 0,
				sessionName: session ? this.util.date.formatUTC(eventsMap[session.eventId].startUTC, 'MMM D, YYYY (DOW)', 'H:MM AM EST', this.ds.languageId) : '',//TBD session include week
				accSessionId: session?.accSessionId,
				accTopicSpecialistIds: specialists?.map(s => s.topicSpecialist.personId) || [],
				topicName: ddTopic.shortNameLabel,
				specialistNames,
				hasSpecialistOrSession: !!specialists.length || !!session,
				ddTopic,

				topicSurveyResults: allTopicResponses.length || null,
				topicSurveyRelevanceScore: this.util.nps.calcScore(allTopicResponses.map(result => result.relevance)),
				topicSurveyNeedScore: this.util.nps.calcScore(allTopicResponses.map(result => result.need)),
				topicSurveyUnderstandingScore: this.util.nps.calcScore(allTopicResponses.map(result => result.understanding)),
				topicSurveyDurationScore: this.util.nps.calcScore(allTopicResponses.map(result => result.duration)),
			};

		}));


		//Phantom state of topics to add if editable
		if (!this.readonly) {
			const topicIdsAlreadyAdded = accTopics.map(accTopic => accTopic.topicId);
			const filteredTopics = ddTopics.filter(topic => topic.enabled && !topicIdsAlreadyAdded.includes(topic.topicId));

			for (const topic of filteredTopics) {
				rows.push({
					accTopicId: undefined,
					topicId: topic.topicId,
					materialsCount: topicMaterialsMap[topic.topicId]?.length || 0,
					sessionName: undefined,
					accSessionId: undefined,
					accTopicSpecialistIds: [],
					topicName: topic.shortNameLabel,
					specialistNames: undefined,
					hasSpecialistOrSession: false,
					ddTopic: topic,
					topicSurveyResults: undefined,
					topicSurveyRelevanceScore: undefined,
					topicSurveyNeedScore: undefined,
					topicSurveyUnderstandingScore: undefined,
					topicSurveyDurationScore: undefined,
				});
			}

		}

		return rows.sort((a, b) => a.ddTopic.displayOrder > b.ddTopic.displayOrder ? 1 : -1);

	}

	private setupGrid(): GridSetup<TopicRow> {
		const topicInActiveFlag = (row: TopicRow) => {
			return !row.ddTopic.enabled;
		};

		const topicInActiveText = (row: TopicRow) => {
			if (!row.ddTopic.enabled) return `Topic In-Active.`;
			else return undefined;
		};

		const setup: GridSetup<TopicRow> = {
			experience: 'none',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Topic",
			rowPluralName: "Topics",
			rowKey: "topicId",
			stateKey: "acc-curriculum-topics-view-part",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: "topicId", header: "topicId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "accTopicId", header: "accTopicId", width: 70, type: GridColumnType.number, hidden: true },
				{ field: "accSessionId", header: "accSessionId", width: 80, type: GridColumnType.number, hidden: true },
				{ field: "topicName", header: "Topic", width: 120, type: GridColumnType.text, strikethrough: topicInActiveFlag, tooltip: topicInActiveText },
				{ field: "sessionName", header: "Session", width: 100, type: GridColumnType.text },
				{ field: "specialistNames", header: "Specialists", width: 120, type: GridColumnType.text },
				{ field: "materialsCount", header: "# Materials", headerTooltip: 'Count of Materials', width: 60, type: GridColumnType.number, hidden: true, },
				{ field: "topicSurveyResults", header: "# Surveys", headerTooltip: 'Topic Surveys Taken', width: 60, type: GridColumnType.number },
				{ field: "topicSurveyRelevanceScore", header: "Relevance Score", headerTooltip: 'Relevance Score [0-100] Asked: "This topic is important for my business"', width: 70, type: GridColumnType.number, hidden: true },
				{ field: "topicSurveyNeedScore", header: "Need Score", headerTooltip: 'Need Score [0-100] Asked: "I will use this topic knowledge within the next three months"', width: 70, type: GridColumnType.number, hidden: true },
				{ field: "topicSurveyUnderstandingScore", header: "Understanding Score", headerTooltip: 'Understanding Score [0-100] Asked: "I have a better understanding of this topic and can more confidently apply it"', width: 70, type: GridColumnType.number, hidden: true },
				{ field: "topicSurveyDurationScore", header: "Duration Score", headerTooltip: 'Duration Score [0-100] Asked: "There was adequate time dedicated to this topic"', width: 70, type: GridColumnType.number, hidden: true },

			],
			actions: [
				{ key: 'config-topic', icon: Icon.concept_event, label: 'Configure Topic', enabled: false },
				{ key: 'view-training-materials', icon: Icon.action_view, label: 'View Training Materials', enabled: false },
			],
			actionEnabler: this.gridActionEnabler.bind(this),

		};

		return setup;
	}

	gridActionEnabler(action: GridAction, rows: TopicRow[]) {
		if (rows.length == 0) return false;

		if (action.key == 'config-topic') {
			if (this.readonly) return false;
		}
		return true;
	}

	async gridActionHandler(action: { actionKey: string, rows: TopicRow[] }) {
		const row = action.rows[0];

		if (action.actionKey == 'config-topic' || action.actionKey == DBLCLICK_GRID_ACTION_KEY) {

			if (this.readonly) return;

			const action: DialogAction<{ accSessionId: number, specialistPersonIds: number[] }> = await this.ds.dialogService.showCustom(
				TopicConfigDialog,
				{
					data: {
						readonly: this.readonly,
						topicId: row.topicId,
						accTopicSpecialistIds: row.accTopicSpecialistIds,
						accSessionId: row.accSessionId,
					},
				},
				450, 290
			);

			const id = action?.id;
			if (id == 'save') {
				await this.accAreaService.curriculum.actions.setTopicSessionAndSpecialists({ topicId: row.topicId, accSessionId: action.callbackResult.accSessionId, personIds: action.callbackResult.specialistPersonIds });
			}


		}

		else if (action.actionKey == 'view-training-materials') {
			const acc = this.accAreaService.accelerator.acc$.value;

			await this.ds.dialogService.showCustom(
				TopicMaterialDialog,
				{
					data: {
						topicId: row.topicId,
						languageId: acc.accLanguageSeason.languageId,
					},
				},
				600, 600
			);

		}

		else if (action.actionKey == 'remove-topic') {
			if (this.readonly || !row.accTopicId) return;

			if (row.hasSpecialistOrSession) {
				await this.ds.dialogService.showMessage(`${row.topicName} cannot be removed because it is either assigned to a session or has specialists.`, 400, 150, 'Okay');
			}
			else {
				const yes = await this.ds.dialogService.confirm(`Are you sure you want to remove Topic ${row.topicName}?`, 400, 250, 'Remove', 'Cancel');
				if (yes) await this.accAreaService.curriculum.actions.removeTopic({ accTopicId: row.accTopicId });
			}
		}


	}

}