import 'zone.js';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { determineLanguageFromUrl } from '@me-services/ui/url/determine-language-and-configuration';
import { init, routingInstrumentation } from "@sentry/angular-ivy";
import { Integrations } from "@sentry/tracing";
// import { H as Highlight } from 'highlight.run';
// import 'zone.js/dist/zone'; // Included with Angular CLI. (moved from polyfills.ts to fix "Error: "Zone.js has detected that ZoneAwarePromise `(window|global).Promise` has been overwritten." error)
import { AppModule } from './app.module';
import { environment } from './environments/environment';
import { RELEASE_VERSION } from './interfaces/release-version';
import { setupGoogleTagManager } from './tag-manager';



const body = document.getElementsByTagName('body')[0];
const { isSpanish } = determineLanguageFromUrl();

const env = environment.name == 'ME-PROD' ? 'Prod' : environment.name == 'ME-DEV' ? 'Dev' : 'Localhost';


if (env == 'Prod') enableProdMode();

if (env == 'Prod') body.classList.add('production');
else if (env == 'Dev') body.classList.add('development');
else if (env == 'Localhost') body.classList.add('localhost');

if (isSpanish) body.classList.add('lang-spanish');
else body.classList.add('lang-english');

body.classList.add('config-eforall');


init({
	dsn: "https://8ca8713f3a2c4d8da02639183e3a0800@o1055524.ingest.sentry.io/6041682",
	environment: env,
	release: `${env}-${RELEASE_VERSION}`,
	integrations: [
		new Integrations.BrowserTracing({
			tracingOrigins: [
				'https://eforall.app',
				'https://dev.eforall.app',
				'http://localhost:4200',

				'https://eparatodos.app',
				'https://dev.eparatodos.app',
				'http://eparatodos.localhost:4200',

				'https://us-central1-me-prod.cloudfunctions.net',
				'https://us-central1-me-dev-9a029.cloudfunctions.net',
				'http://localhost:5000',

			],
			routingInstrumentation: routingInstrumentation,
		}),
		// new Integrations.Breadcrumbs({
		// 	console: false, // console: environment.name !== 'LOCALHOST',
		// }),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});


// Highlight.init(environment.highlightConfig.projectId, {
// 	environment: environment.highlightConfig.environment,
// 	version: `${environment.highlightConfig.environment}-${RELEASE_VERSION}`,
// 	tracingOrigins: [
// 		'https://eforall.app',
// 		'https://dev.eforall.app',
// 		'http://localhost:4200',

// 		'https://eparatodos.app',
// 		'https://dev.eparatodos.app',
// 		'http://eparatodos.localhost:4200',

// 		'https://us-central1-me-prod.cloudfunctions.net',
// 		'https://us-central1-me-dev-9a029.cloudfunctions.net',
// 		'http://localhost:5000',
// 	],
// 	networkRecording: {
// 		enabled: true,
// 		recordHeadersAndBody: true,
// 		urlBlocklist: [
// 			// insert full or partial urls that you don't want to record here
// 			// Out of the box, Highlight will not record these URLs (they can be safely removed):
// 		],
// 	},
// });

setupGoogleTagManager();
platformBrowserDynamic().bootstrapModule(AppModule);