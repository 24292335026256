import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { DbsTag, TagPrefixId } from '@me-interfaces';

@Component({
	selector: 'me-tag',
	templateUrl: './SHR-CMN_tag.part.html',
	styleUrls: ['SHR-CMN_tag.part.scss'],
})
export class TagPart implements OnInit {

	@Input() tag: DbsTag;
	@Output() tagClicked?= new EventEmitter();
	@Input() clickable: boolean;
	@Input() disabled?: boolean;
	@Input() showPrefix = true;


	ngOnInit() {
		this.onLoad();
	}


	@HostListener('load')
	onLoad() {
		const tagPrefixId = this.tag.tagPrefixId;

		if (tagPrefixId == TagPrefixId.EforAll) this.eforall = true;
		else if (tagPrefixId == TagPrefixId.Eforever) this.eforever = true;
		else if (tagPrefixId == TagPrefixId.HQ) this.hq = true;
		else if (tagPrefixId == TagPrefixId.Lang) this.lang = true;
		else if (tagPrefixId == TagPrefixId.Specialty) this.specialty = true;
		else if (this.tag.name.includes('not-approved')) this.notApproved = true;
		else if (this.tag.isAutoTag || this.tag.name == 'follower') this.site = true;
		else this.manualTag = true;

		if (!this.clickable) {
			if (this.disabled) this.restricted = true;
		}
		else this.enabled = true;
	}

	@HostBinding('class.enabled') enabled;

	@HostBinding('class.restricted') restricted;

	@HostBinding('class.eforall') eforall;

	@HostBinding('class.eforever') eforever;

	@HostBinding('class.hq') hq;

	@HostBinding('class.lang') lang;

	@HostBinding('class.specialty') specialty;

	@HostBinding('class.site') site;

	@HostBinding('class.manual-tag') manualTag;

	@HostBinding('class.not-approved') notApproved;

	@HostListener('click')
	onClick() {
		if (this.clickable) this.tagClicked.emit(this.tag);
	}
}
