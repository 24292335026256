
import { DbcZip } from '@me-interfaces';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';
import { DdVenueRow } from './dd-venue-row';

/**
 * @deprecated Use the DataService instead
 */
export class DdVenue extends DdObject<DdVenueRow> implements DdVenueRow {

	constructor(private dd: OldDomainDataService, data: DdVenueRow) {
		super(data);
	}

	public get venueId(): number {
		return this._data.venueId;
	}

	public get officialName(): string {
		return this._data.officialName;
	}

	public get displayedName(): string {
		return this._data.displayedName;
	}

	public get address(): string {
		return this._data.address;
	}

	public get zipId(): number {
		return this._data.zipId;
	}

	public get zip(): DbcZip {
		return this.dd.getCachedZip(this.zipId);
	}

	public get placeId(): string {
		return this._data.placeId;
	}

	public get latitude(): number {
		return this._data.latitude;
	}

	public get longitude(): number {
		return this._data.longitude;
	}

	public get isActive(): boolean {
		return this._data.isActive;
	}

	public get website(): string | undefined {
		return this._data.website;
	}

	public get createdUTC(): number {
		return this._data.createdUTC;
	}

	public get createdByPersonId(): number {
		return this._data.createdByPersonId;
	}

	public get updatedUTC(): number {
		return this._data.updatedUTC;
	}

	public get updatedByPersonId(): number {
		return this._data.updatedByPersonId;
	}

	public get publicNotes(): string | undefined {
		return this._data.publicNotes;
	}

	public get internalNotes(): string | undefined {
		return this._data.internalNotes;
	}

}