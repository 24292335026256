import { DbConceptName, Person } from "@me-interfaces";
import { Icon } from "@me-shared-parts/UI-common";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForCompanies(ds: DataService, person: Person): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const positionsByPersonId = await ds.admin.position.getArraysByForeignIds('personId', [person.asSingleton.personId]);
	const positions = positionsByPersonId[person.asSingleton.personId];

	const companyIds = positions.map(position => position.companyId);
	const companiesMap = await ds.admin.company.getManyPackagesAsMap(companyIds);

	for (const position of positions) {

		const company = companiesMap[position.companyId];

		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Position,
			header: 'Company',
			buttons: [
				{ explorer: { nameOverride: company.longName, explorableId: { conceptName: DbConceptName.Company, id: position.companyId }, navTooltip: `Company: ${company.longName}` } },
				{ explorer: { nameOverride: position.title, explorableId: { conceptName: DbConceptName.Company, id: position.companyId }, navIconOverride: Icon.concept_position, navTooltip: `Title: ${position.title}` } },
			],
			notes: '',
		};

		if (position.phone) {
			box.buttons.push({ link: { linkType: 'call', phone: position.phone, ext: position.extension } });
		}

		const notes: string[] = [];
		if (position.isFounder) notes.push('Founder');
		if (position.isAdmin) notes.push('Admin');
		box.notes = notes.join(', ');

		boxes.push(box);
	}

	return boxes;
}