<app-area-access [areaService]='regionAreaService'>
	<ng-container>
		<div class='region-overview-page'>
			<region-toolbar></region-toolbar>

			<page-tabs [tabNames]='tabNames' [pageService]='pageService' (layoutChange)='onLayoutChange($event)'>
				<ng-template pageTab1>
					<region-views-part [readonly]='readonly'></region-views-part>
				</ng-template>

				<ng-template pageTab2>
					<get-help></get-help>
				</ng-template>
			</page-tabs>

		</div>
	</ng-container>
</app-area-access>