<div class='person-table-link-part'>
	<table class='pro-table pro-table-no-header pro-table-with-labels pro-table-with-thin-rows'>

		<tr>
			<td>
				<me-label key='Name'></me-label>
			</td>
			<td>
				<person-extract-link *ngIf="!publicFacingPerson" [person]='person'></person-extract-link>
				<public-person-link *ngIf="publicFacingPerson" [person]='publicFacingPerson'>
				</public-person-link>
			</td>
		</tr>

		<tr>
			<td>
				<me-label key='Email'></me-label>
			</td>
			<td>
				<a class="link" (click)="emailPerson(email)">{{email}}</a>
			</td>
		</tr>

		<tr>
			<td>
				<me-label key='Phone'></me-label>
			</td>
			<td>
				<div *ngIf="!phone?.phone" class="missing-value">
					<me-label key='Phone not provided'></me-label>
				</div>
				<phone-link *ngIf="phone?.phone" [phone]="phone">
				</phone-link>
			</td>
		</tr>

	</table>
</div>