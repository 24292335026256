import { WebLinkTypeId } from "@me-interfaces";
import { getIconContext } from "./get-icon-context";
import { Icon } from "./icon";
import { IconContext } from "./icon-context";
import { IconHideTextAt } from "./icon-hide-text-at";
import { IconStyle } from "./icon-style";

/**
 * Return the Icon value for a given WebLinkTypeId
 */
export function getIconForWebLinkType(webLinkTypeId: WebLinkTypeId): Icon {

	if (webLinkTypeId == WebLinkTypeId.Website) return Icon.link_website;
	if (webLinkTypeId == WebLinkTypeId.Facebook) return Icon.link_facebook;
	if (webLinkTypeId == WebLinkTypeId.LinkedIn) return Icon.link_linkedIn;
	if (webLinkTypeId == WebLinkTypeId.Twitter) return Icon.link_twitter;

	return Icon.status_missing;
}


export function getIconContextForWebLinkType(webLinkTypeId: WebLinkTypeId, iconStyle?: IconStyle, hideText?: IconHideTextAt, text?: string, hasRedFlag?: boolean): IconContext {
	return getIconContext(getIconForWebLinkType(webLinkTypeId), iconStyle, hideText, text, hasRedFlag);
}