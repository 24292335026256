import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ApplicationFields, ApplicationStatusId } from '@me-interfaces';

@Component({
	selector: 'application-viewer',
	templateUrl: './SHR-PG_application-viewer.part.html',
	styles: [`
			.application-viewer-part {
				margin: 0 5px;
			}
			.application-viewer-part .readonly-field-section me-h3 {
				margin-top: 1em;
				text-decoration: underline;
			}
		`
	],
})
export class ApplicationViewerPart extends DestroyablePart implements OnInit, OnChanges {

	@Input() application: ApplicationFields;
	@Output() statusChange = new EventEmitter<ApplicationStatusId.AcceptPending>();

	reopening = false;

	constructor(private cd: ChangeDetectorRef) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

	ngOnChanges(/* simpleChanges: SimpleChanges */) {
		if (!this.application) throw new Error('Missing required attribute: application');
		this.cd.detectChanges();
	}

	reopen() {
		// this.reopening = true;
		this.statusChange.emit(ApplicationStatusId.AcceptPending);
		// this.reopening = false;
	}
}
