
import { DdEventTypeRow } from './dd-event-type-row';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';
import { EventAttendedBy } from '@me-interfaces';

/**
 * @deprecated Use the DataService instead
 */
export class DdEventType extends DdObject<DdEventTypeRow> implements DdEventTypeRow {

	constructor(dd: OldDomainDataService, data: DdEventTypeRow) {
		super(data);
	}

	public get eventTypeId(): number {
		return this._data.eventTypeId;
	}

	public get fullName(): string {
		return this._data.fullName;
	}

	public get name(): string {
		return this._data.name;
	}

	public get toolNameLabelKey(): string {
		return this._data.toolNameLabelKey;
	}

	public get org(): 'EforAll' | 'Eforever' {
		return this._data.org;
	}

	public get attendedBy(): EventAttendedBy {
		return this._data.attendedBy;
	}
}