import { Component, Input, OnChanges } from '@angular/core';
import { Application } from '@me-interfaces';
import { DataService } from '@me-services/core/data/data.service';
import { FuncService } from '@me-services/core/func';
import { UtilityService } from '@me-services/core/utility';


@Component({
	selector: 'application-explorer-questions',
	templateUrl: './questions.part.html',
	styleUrls: ['./questions.part.scss'],
})
export class ApplicationExplorerQuestionsPart implements OnChanges {
	@Input() application: Application;

	applicationQuestions: { ideaAnswer: string, hearAnswer: string };


	constructor(
		private func: FuncService,
		private ds: DataService,
		public util: UtilityService,
	) { }



	ngOnChanges() {
		if (!this.application) throw new Error('Missing required attribute: application');
		this.applicationQuestions = undefined;
	}


	async retrieve() {
		this.applicationQuestions = await this.func.explorer.getApplicationQuestions({ applicationId: this.application.applicationId });
	}

}