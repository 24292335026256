<app-area-access [areaService]='accAreaService'>
	<ng-container *ngIf='accTeamData$ | async; let accTeamData'>
		<div class='acc-team-page'>
			<me-toolbar icon='Acc'>

				<div class="acc-team-toolbar-items">

					<me-droplist class='acc-application-status' [items]='accTeamData.accTeamList'
						[selectedItem]='accTeamData.accTeamSelectedItem' sortBy='text' [filterable]="false"
						[popupConfig]='{ message:"Select an application to open..." }'
						(selectionChange)='onAccTeamChange($event)'>
					</me-droplist>


					<button class='btn' (click)='inspectAccTeam(accTeamData.accTeam.accTeamId)'>
						<me-icon [icon]='icons.accTeam'></me-icon>
					</button>

					<button class='btn' (click)='inspectCompany(accTeamData.company.companyId)'>
						<me-icon [icon]='icons.company'></me-icon>
					</button>

					<button class='btn' (click)='inspectApplication(accTeamData.accTeam.applicationId)'>
						<me-icon [icon]='icons.appl'></me-icon>
					</button>

					<text-dialog-button [header]="'Team Notes'" [text]="accTeamData.accTeam.notes"
						(textChanged)="onTeamNotesChange(accTeamData.accTeam.accTeamId, $event)" [readonly]="readonly">
					</text-dialog-button>

					<button class='btn'
						(click)='toggleDroppedOut(accTeamData.acc, accTeamData.accTeam.accTeamId, accTeamData.accTeam.droppedOutWeek)'
						[disabled]='readonly'>
						<me-icon *ngIf='!accTeamData.accTeam.droppedOutWeek' [icon]='icons.check'></me-icon>
						<me-icon *ngIf='accTeamData.accTeam.droppedOutWeek' [icon]='icons.x'>
						</me-icon>
						<span *ngIf='accTeamData.accTeam.droppedOutWeek'>: {{accTeamData.accTeam.droppedOutWeek}}</span>
					</button>

				</div>
			</me-toolbar>

			<page-tabs [tabNames]='[accTeamData.accTeam.application.company._name, "Overview"]'
				[pageService]='pageService' (layoutChange)='onLayoutChange($event)'>
				<ng-template pageTab1>
					<acc-team-views-part [accTeamId]='accTeamId' [readonly]="readonly"></acc-team-views-part>
				</ng-template>

				<ng-template pageTab2>
					<acc-details-part [accelerator]="accTeamData.acc"></acc-details-part>

					<div *ngIf='accTeamData.accTeam.application.companyName !== accTeamData.accTeam.application.company._name'
						class='message-box'>
						<message-box alertType='info'>
							The business name has changed. In the application the name was
							<b>{{accTeamData.accTeam.application.companyName}}</b>, but it is now
							<i>{{accTeamData.accTeam.application.company._name}}</i>.
						</message-box>
					</div>

					<ng-container *ngIf='accTeamData.awardsCount'>
						<acc-team-awards-part [accTeamId]='accTeamId'></acc-team-awards-part>
					</ng-container>

					<ng-container *ngIf='accTeamData.applicationText'>
						<me-h2>About {{accTeamData.accTeam.application.company._name}}</me-h2>
						{{accTeamData.applicationText}}
					</ng-container>

				</ng-template>
			</page-tabs>
		</div>
	</ng-container>
</app-area-access>