import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { LabelKey } from '@me-interfaces';
import { LabelsService, MISSING_TRANSLATION } from '@me-services/ui/labels';

@Component({
	selector: 'me-label',
	templateUrl: './SHR-CMN_label.part.html',
	styleUrls: ['./SHR-CMN_label.part.scss'],
})
export class LabelPart extends DestroyablePart implements OnInit, OnChanges, OnDestroy {

	/**
	 * The key is either a straight up key as a string or
	 * the LabelKey interface that may optionally have
	 * field replacement values
	 */
	@Input() key: string | LabelKey;

	public isMarkdown = false;
	public text = '';
	public missingLabel = false;

	constructor(
		public labelsService: LabelsService,
		private cd: ChangeDetectorRef,
		private elem: ElementRef) {

		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

	async ngOnChanges(changes: SimpleChanges) {

		const change = changes['key'];

		if (change) {

			if (change.firstChange || !this.labelsService.labelsAreSame(change.currentValue, change.previousValue)) {

				//
				// The key Input is either a label key as a string or a LabelKey object. It is never
				// a string literal. This is different than LabelsService.get which considers strings
				// to be literal text that should not be translated. So, to get plain strings passed
				// into this part translated, we map them into a LabelKey object. If the string has a
				// starting colon (the old, deprecated way) then we strip it off.
				//
				if (typeof this.key == 'string') {
					if (this.key.length && this.key[0] == ':') this.key = this.key.substr(1);
					this.key = { key: this.key };
				}


				//
				// Determine if the translated value should be rendered as markdown
				//
				this.isMarkdown = this.key.key.startsWith('md:');


				//
				// Translate the text / determine if there is a translation
				//
				this.text = await this.labelsService.get(this.key, true);

				if (this.key) this.elem.nativeElement.labelKey = this.key.key;

				this.missingLabel = this.text == undefined;
				if (this.text == undefined) this.text = `${MISSING_TRANSLATION} ${this.key.key}`;


				this.cd.detectChanges();
			}
		}
	}
}
