import { GridColumn, GridColumnType } from '@me-grid';

export type FilterType = 'text' | 'numeric' | 'boolean' | 'date';

export interface FilterableGridColumn<T> extends GridColumn<T> {
	filterType?: FilterType;
}

export const filterTypes: { [index: string]: FilterType } = {};
filterTypes[GridColumnType.boolean] = 'boolean';
filterTypes[GridColumnType.date] = 'date';
filterTypes[GridColumnType.dateUtc] = 'date';
filterTypes[GridColumnType.dateAndTime] = 'date';
filterTypes[GridColumnType.dateAndTimeUtc] = 'date';
filterTypes[GridColumnType.number] = 'numeric';
filterTypes[GridColumnType.dollars] = 'numeric';
filterTypes[GridColumnType.zipCode] = 'numeric';
filterTypes[GridColumnType.percent] = 'numeric';
filterTypes[GridColumnType.text] = 'text';
filterTypes[GridColumnType.icon] = undefined;
filterTypes[GridColumnType.iconContext] = undefined;
filterTypes[GridColumnType.entityNotes] = 'text';
filterTypes[GridColumnType.time] = undefined;
filterTypes[GridColumnType.timeUtc] = undefined;