import { Component, Input, OnChanges } from '@angular/core';
import { ADDROW_GRID_ACTION_KEY, DBLCLICK_GRID_ACTION_KEY, GridColumnType, GridSetup, GridValueStyle } from '@me-grid';
import { DbConceptDataType, DbConceptName, DbsAward, DbsCompany, Event, TeamsForAward } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogService } from '@me-services/ui/dialog';
import { AwardDisplayDialog } from '@me-shared-parts/PG-program';
import { Icon } from '@me-shared-parts/UI-common';
import { InlineGridSize, PageTabsMainTabGridSize } from '@me-shared-parts/UI-common/grid/interfaces/grid-size';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AdminAreaService } from '../../../../admin/admin-area/area/admin-area.service';


export interface AwardRow extends DbsAward {
	award: string,
	company: DbsCompany,
	companyName: string,
	kind: string,
	decidedBy: string,
	awardeeNames: string,
	awardeeEmails: string,
}



const EDIT_AWARD_ACTION_KEY = 'edit-award';
const DELETE_AWARD_ACTION_KEY = 'delete-award';

@Component({
	selector: 'event-awards-view',
	templateUrl: './SHR-PG_event-awards.part.html',
	styleUrls: ['SHR-PG_event-awards.part.scss'],
})
export class EventAwardsPart implements OnChanges {

	@Input() readonly: boolean;
	@Input() eventId: number;
	@Input() list: TeamsForAward[] = [];
	@Input() gridSize: PageTabsMainTabGridSize | InlineGridSize;

	public gridSetup: GridSetup<AwardRow> = this.setupGrid(false);

	public rows$: Observable<AwardRow[]>;

	eventTypeId: number;


	constructor(
		private adminAreaService: AdminAreaService,
		private ds: DataService,
		private dialogService: DialogService,
	) { }


	async ngOnChanges() {
		this.gridSetup = this.setupGrid(!this.readonly);
		this.rows$ = this.ds.admin.singletonsAsOfUTC$
			.pipe(mergeMap(async singletonsAsOfUTC => await this.createRows(singletonsAsOfUTC)));
	}




	async createRows(singletonsAsOfUTC: number): Promise<AwardRow[]> {
		const event = await this.ds.admin.event.getOnePackage(this.eventId);
		if (!event) return [];

		this.eventTypeId = event.eventTypeId;
		const eventTypeName = this.ds.domain.eventType.getOne(event.eventTypeId).name;
		const awards = await this.ds.admin.award.getManyPackagesAsArray(event.awards.map(award => award.awardId));
		const program = event.accId ? await this.ds.admin.accelerator.getOnePackage(event.accId) : await this.ds.admin.pitchContest.getOnePackage(event.picId);
		const downloadFileName = program ? `${program.siteProgram.site.code6}_${program.longName}_${eventTypeName}_${program.siteProgram.program.language.name}` : undefined;
		this.gridSetup = this.setupGrid(!this.readonly, downloadFileName);


		return awards.map(award => {
			let awardeeNames = '';
			let awardeeEmails = '';
			let company: DbsCompany;

			if (award.accTeamId) {
				company = award.accTeam.application.company;
				const members = award.accTeam.members.filter(m => m.member.role == 'E');
				awardeeNames = members.map(member => member.person._name).sort((a, b) => a > b ? 1 : -1).join(', ');
				awardeeEmails = members.map(member => member.person._email).sort((a, b) => a > b ? 1 : -1).join(', ');
			}
			if (award.picTeamId) {
				company = award.picTeam.application.company;
				awardeeNames = award.picTeam.members.map(member => member.person._name).sort((a, b) => a > b ? 1 : -1).join(', ');
				awardeeEmails = award.picTeam.members.map(member => member.person._email).sort((a, b) => a > b ? 1 : -1).join(', ');
			}


			return {
				...award,
				award: this.ds.domain.awardName.getOne(award.awardNameId).name,
				company,
				companyName: company._name,
				kind: this.ds.domain.awardKind.getOne(award.awardKindId).name,
				decidedBy: this.ds.domain.decidingRole.getOne(award.decidingRoleId).name,
				awardeeNames,
				awardeeEmails,
			};
		})
	}


	private setupGrid(canWrite: boolean, downloadFileName?: string): GridSetup<AwardRow> {
		const valueStyle = this.valueStyle.bind(this);

		const setup: GridSetup<AwardRow> = {
			experience: 'AWARD',
			size: this.gridSize,
			rowSingularName: "Award",
			rowPluralName: "Awards",
			rowKey: "awardId",
			stateKey: "event-awards-view-part",
			canAdd: canWrite,
			canRefresh: false,
			canDownload: true,
			downloadFileName,
			columnsToAdd: [
				{ field: 'AWARD_eventStatus', header: 'Status', width: 100, type: GridColumnType.text, hidden: true },
				{ field: 'AWARD_eventStart', header: 'Event Start', width: 130, type: GridColumnType.dateAndTimeUtc, hidden: true },
				{ field: 'AWARD_awardeeNames', header: 'Awardee Names', width: 220, type: GridColumnType.text, hidden: false },
				{ field: 'AWARD_awardeeEmails', header: 'Awardee Emails', width: 240, type: GridColumnType.text, hidden: false },
				{ field: 'AWARD_kind', header: 'Kind', width: 160, type: GridColumnType.text, hidden: false },
				{ field: "notes", header: "Notes", width: 70, type: GridColumnType.text, valueStyle },
			],
		};

		if (canWrite) {
			setup.actions = [
				{ key: EDIT_AWARD_ACTION_KEY, icon: Icon.action_edit, label: 'Edit Award' },
				{ key: DELETE_AWARD_ACTION_KEY, icon: Icon.action_delete, label: 'Delete Award' },
			];
		}

		return setup;
	}


	private valueStyle(row: AwardRow, field: string): GridValueStyle {
		const value = row[field];

		if (field == 'notes') { // Program
			if (value) return { icon: 'far fa-file-alt', textStyle: 'Hidden' };
			else return;
		}

		return { icon: 'fal fa-question', iconColor: 'Red', textStyle: 'Hidden' };
	}


	/**
	 * Handle events from the grid
	 */
	async gridActionHandler(action: { actionKey: string, rows: AwardRow[] }) {
		const row = action.rows[0];

		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {

			if (this.readonly) return;

			if (this.eventId) {
				const award: DbsAward = {
					_concept: DbConceptName.Award,
					_dt: DbConceptDataType.AdminSingletonData,
					awardId: 0,
					eventId: this.eventId,
					accTeamId: undefined,
					picTeamId: undefined,
					awardNameId: undefined,
					decidingRoleId: undefined,
					awardKindId: undefined,
					value: undefined,
					notes: undefined,
					createdUTC: undefined,
					createdByPersonId: undefined,
					updatedUTC: undefined,
					updatedByPersonId: undefined,
				};

				await this.dialogService.showCustom(
					AwardDisplayDialog,
					{
						data: {
							eventTypeId: this.eventTypeId,
							readonly: this.readonly,
							award,
							isAdd: true,
							list: this.list,
						}
					},
					700, 500
				);

			}
			else {
				await this.dialogService.showMessage('Please add an event first.  The awards will then be attached to the event.', 280, 150, 'Okay');
			}

		}


		else if (action.actionKey == DELETE_AWARD_ACTION_KEY) {

			if (this.readonly) return;

			await this.adminAreaService.events.deleteAward({ awardId: row.awardId });

		}


		else if (action.actionKey == DBLCLICK_GRID_ACTION_KEY || action.actionKey == EDIT_AWARD_ACTION_KEY) {
			if (this.readonly) return;

			await this.dialogService.showCustom(
				AwardDisplayDialog,
				{
					data: {
						eventTypeId: this.eventTypeId,
						readonly: this.readonly,
						award: row,
						isAdd: false,
						list: this.list,
					}
				},
				700, 500
			);
		}
	}

}
