import { DbConceptName, Site, WebLinkTypeId } from "@me-interfaces";
import { LinkOrExplorable } from "../../link-or-explorerable";

export function getButtons(site: Site): LinkOrExplorable[] {

	const buttons: LinkOrExplorable[] = [];

	//
	// Website
	//
	if (site.eforallOrgEn) buttons.push({ link: { linkType: 'webLink', url: site.eforallOrgEn, webLinkTypeId: WebLinkTypeId.Website, overrideText:'eforall.org' } });
	if (site.eforallOrgEs) buttons.push({ link: { linkType: 'webLink', url: site.eforallOrgEs, webLinkTypeId: WebLinkTypeId.Website, overrideText: 'eparatodos.org'} });

	//
	// ED and PM
	//
	buttons.push(
		{ explorer: { nameOverride: `ED: ${site.directorName}`, explorableId: { conceptName: DbConceptName.Person, id: site.directorId }, navTooltip: `ED: ${site.directorName}`, } },
	);


	return buttons;
}