<div class='staff-overview-view-part' *ngIf="viewDroplistItems.length">

	<ng-container *ngIf='selectedViewItem$ | async; let selectedViewItem'>

		<me-droplist class="views-droplist" [items]='viewDroplistItems' [selectedItem]='selectedViewItem'
			(selectionChange)='onViewDroplistChange($event)' [filterable]='false' [navigation]='true'
			sortBy='customSort' style='display:inline-block'>
		</me-droplist>

		<staff-overview-view-part *ngIf="showView('Overview', selectedViewItem)">
		</staff-overview-view-part>

		<staff-departments-view-part *ngIf="showView('Departments', selectedViewItem)">
		</staff-departments-view-part>

		<staff-all-staff-view-part *ngIf="showView('AllStaff', selectedViewItem)">
		</staff-all-staff-view-part>

		<staff-teams-view-part *ngIf="showView('Teams', selectedViewItem)">
		</staff-teams-view-part>

	</ng-container>

</div>