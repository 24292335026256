import { Component, Input, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { LabelsService } from '@me-services/ui/labels';

@Component({
	selector: 'application-editor-section-title',
	templateUrl: './SHR-PG_application-editor-section-title.part.html',
	styles: [`
		.application-section-title-part {
			display: grid;
			grid-template-columns: auto auto;
		}

		.application-section-title-part > div:nth-child(2) {
			font-size: 0.8em;
			margin: .1em 1.5em 0 0;
			text-align: right;
		}

		.application-section-title-part .completed-text {
			color: #060;
		}

		.application-section-title-part .remaining-text {
			color: #900;
		}

	`],
})
export class ApplicationEditorSectionTitlePart extends DestroyablePart implements OnInit {

	@Input() title: string;
	@Input() remaining: number;


	inlineLabels = this.labels.trackInlineLabels(this, [
		'Completed',
		'Remaining',
	]);


	constructor(public labels: LabelsService) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

}
