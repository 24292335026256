import { Injectable } from '@angular/core';
import { PageTabsLayoutPageService } from '@me-shared-parts/UI-common';


/**
 * The list of tabs on this page
 */
export enum TabId {
	Staff = 1,
	Counts = 2,
}

/**
 * The list if main tab droplist views on this page
 */
export enum ViewId {
	Overview = 1,
	Departments = 2,
	AllStaff = 3,
	Teams = 4,
}


@Injectable({ providedIn: 'root' })
export class StaffOverviewPageService extends PageTabsLayoutPageService<TabId, ViewId> {


	constructor() {
		super(StaffOverviewPageService.name, TabId.Staff, ViewId.Overview);
	}

}