import { AdminAreaService, SharedDashboardsService } from '@ADMIN-area';
import { NationalDashboardPageService } from '@ADMIN-area';
import { Component, OnInit } from '@angular/core';
import { DataService } from '@me-services/core/data';
import { PageTabsLayout } from '@me-shared-parts/UI-common';


@Component({
	selector: 'national-page',
	templateUrl: './national.page.html',
})
export class NationalPage implements OnInit {

	constructor(
		public ds: DataService,
		public pageService: NationalDashboardPageService,
		public adminAreaService: AdminAreaService,
		public sharedViewsService: SharedDashboardsService,
	) {
	}


	ngOnInit() {
		this.pageService.initializeUrl();
		this.sharedViewsService.monitorLayout(this.pageService.layout$);
	}


	onLayoutChange(layout: PageTabsLayout) {
		this.pageService.changeLayout(layout);
	}

}