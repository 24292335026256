import { Accelerator, AccInterviewer, DbsAccInterviewer } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { AcceleratorPackageManager } from "./accelerator";
import { PersonPackageManager } from "./person";

export class AccInterviewerPackageManager extends PackageManager<DbsAccInterviewer, AccInterviewer> {

	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsAccInterviewer>,
		private domain: DomainDataManagers,
		private person: PersonPackageManager,
		private accelerator: AcceleratorPackageManager,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Convert an array of DbcAccInterviewer to an array of AccInterviewer
	 */
	protected async _createPackages(dbsAccInterviewers: DbsAccInterviewer[]): Promise<AccInterviewer[]> {

		//
		// Get all the people and companies
		//
		const personIds: number[] = [];
		const acceleratorIds: number[] = [];

		for (const dbsAccInterviewer of dbsAccInterviewers) {
			personIds.push(dbsAccInterviewer.personId);
			personIds.push(dbsAccInterviewer.updatedByPersonId);
			acceleratorIds.push(dbsAccInterviewer.accId);
		}

		const personMap = await this.person.getManyPackagesAsMap(personIds);
		const acceleratorMap = await this.accelerator.getManyPackagesAsMap(acceleratorIds);


		//
		// Package 'em up
		//
		const accInterviewers: AccInterviewer[] = dbsAccInterviewers.map(dbsAccInterviewer => {

			const accelerator = acceleratorMap[dbsAccInterviewer.accId];
			const person = personMap[dbsAccInterviewer.personId];
			const updatedByPerson = personMap[dbsAccInterviewer.updatedByPersonId];


			return {
				...dbsAccInterviewer,
				accelerator,
				person,
				updatedByPersonName: updatedByPerson?.name || `Person #${dbsAccInterviewer.updatedByPersonId}`,
				appUrl: this.createAppUrl(accelerator),
			};
		});

		return accInterviewers;
	}

	private createAppUrl(acc: Accelerator): string {
		const siteId = acc.siteId;
		const accId = acc.accId;
		return `/access/admin/national/sites/${siteId}/accelerators/${accId}/pre-accelerator?tab=1&view=11`;
	}

	/**
	 * Get all acc interviewers for each personId provided
	 */
	public async getByPersonIds(personIds: readonly number[]): Promise<Record<number, ReadonlyArray<AccInterviewer>>> {

		return await this.getPackagesAsArraysByForeignIds('personId', personIds);
	}

	/**
	 * Get all acc interviewers for each accId provided
	 */
	public async getByAccIds(accIds: readonly number[]): Promise<Record<number, ReadonlyArray<AccInterviewer>>> {

		return await this.getPackagesAsArraysByForeignIds('accId', accIds);
	}

}