<div class="card-body text-center root-login-logo-part">

	<ng-container *ngIf='logo==="eforall"'>
		<img src="assets/img/logo/eforall_230x75.png" alt="EforAll">
	</ng-container>

	<ng-container *ngIf='logo==="eparatodos"'>
		<img src="assets/img/logo/eparatodos_230x55.png" alt="EparaTodos">
	</ng-container>

	<div>
		<div class='device-info'>
			{{browser}}<br>{{os}}
		</div>
	</div>

</div>