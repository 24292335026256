<div class='site-config-programs-tab' *ngIf='languageList$ | async; let languageList'>
	<ng-container *ngFor='let list of languageList'>
		<me-h2>{{list.language}} Programs</me-h2>
		<mat-accordion class='grid-accordion site-programs'>
			<mat-expansion-panel [@.disabled]='accordionDisableAnimation' *ngFor='let program of list.programs;'
				[expanded]="currentlyExpandedProgramId === program.program.programId" (opened)="onExpand(program)">
				<mat-expansion-panel-header>
					<mat-panel-title>
						<div>
							<i class="fal fa-fw mr-2"
								[class.fa-check]="program.siteProgram && program.siteProgram.isActive"
								[class.fa-times]="!program.siteProgram || program.siteProgram && !program.siteProgram.isActive"></i>
							{{program.program.name}}
							<small
								*ngIf='program.program.languageId !== LanguageId_English'>({{program.program.programType}})</small>
						</div>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<ng-container>

					<mat-checkbox [checked]='program.siteProgram && program.siteProgram.isActive'
						(change)='setProgramOffered(program, $event)' [disabled]='readonly'>Offered by
						the {{site.code}} Site
					</mat-checkbox>

					<ng-container *ngIf='program.siteProgram && program.siteProgram.isActive'>
						<div class='manager-intern-layout'>
							<me-h3>Manager</me-h3>
							<captioned-layout-select *ngIf='program.manager' placeholder='Manager' [list]='eforallStaff'
								[value]='program.manager' [disabled]='readonly'
								(selection)='setManager($event, program)'>
							</captioned-layout-select>

							<me-h3>Alternate Manager</me-h3>
							<div class='program-alt-manager'>
								<captioned-layout-select placeholder='Alternate Manager' [list]='eforallStaff'
									[value]='program.altManager' [disabled]='readonly'
									(selection)='setAltManager($event, program)'>
								</captioned-layout-select>
								<spinner-button *ngIf='program.altManager'
									title='Remove {{program.altManager._name}} as an Alternate Manager' [caution]='true'
									(safeClick)='removeAltManager(program)' [disabled]='readonly'><i
										class='far fa-times'></i></spinner-button>
							</div>
						</div>
						<div *ngIf='program.interns.length'>
							<me-h3>Interns with Access</me-h3>
							<p *ngIf='program.numInternsAssigned === 0'><i class='fas fa-info-circle info-color'></i> No
								interns have access to this program. </p>
							<div class='intern-access-checkbox' *ngIf='program.interns.length'>
								<mat-checkbox *ngFor='let intern of program.interns'
									(change)='addProgramIntern($event, program, intern)' [checked]='intern.isIntern'
									[disabled]='readonly'>
									<me-avatar [avatar]='intern.person' size='35px'></me-avatar>
									{{intern.person._name}}
								</mat-checkbox>
							</div>
						</div>
						<p class='m-1' *ngIf='!program.interns.length'><i class='fas fa-info-circle info-color'></i>
							Site has no interns.</p>
					</ng-container>
				</ng-container>
			</mat-expansion-panel>
		</mat-accordion>
	</ng-container>
</div>