export interface AccGoalEditRow {
    accTeamGoalId: number,
    summary: string,
    details: string,
    progress: string
}

export interface AccGoalAddRow {
    accTeamId: number,
    quarter: number,
    summary: string,
    details: string
}

export const QUARTER_NAMES = {
    1: "Q1",
    2: "Q2",
    3: "Q3",
    4: "Q4"
}