import { AccAreaService, AccPreAcceleratorPageService } from '@ACC-area';
import { Component } from '@angular/core';
import { PageTabsLayout } from '@me-shared-parts/UI-common';


@Component({
	selector: 'acc-pre-accelerator-page',
	templateUrl: './acc-pre-accelerator.page.html',
})
export class AccPreAcceleratorPage {


	constructor(
		public accAreaService: AccAreaService,
		public pageService: AccPreAcceleratorPageService
	) {
	}


	onLayoutChange(layout: PageTabsLayout) {
		this.pageService.changeLayout(layout);
	}

}
