<div class='acc-mm-schedule-heatmap-editor-view-part'>

	<div>Mentor / Team</div>

	<me-droplist *ngIf="items$|async" [items]='items$|async' style="width: 300px; display:inline-block"
		[selectedItem]="selectedRoleToEdit$|async" (selectionChange)='selectedRoleToEdit$.next($event)'
		sortBy='customSort'>
	</me-droplist>

	<ng-container *ngIf="selectedRoleToEdit$|async; let selectedRoleToEdit;">

		<div class="schedulee-description">
			{{selectedRoleToEdit.data.description}}
		</div>

		<div style="margin: 10px 0px">
			<message-line *ngIf='readonly' [alertType]="error" [closeable]="false">Cannot edit the schedule editor as the accelerator is not
				in Mentor Matching stage.</message-line>
			<acc-mm-schedule-selector [schedulee]="selectedRoleToEdit.data.schedulee"></acc-mm-schedule-selector>
		</div>
	</ng-container>

</div>