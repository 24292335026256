<div class='acc-match-tab-part'>

	<acc-stage-summary [accStageId]="accStageId"></acc-stage-summary>

	<div class="tab-section">
		<acc-match-checks-part></acc-match-checks-part>
	</div>

	<section class="tab-section">
		<acc-match-item-counts-part></acc-match-item-counts-part>
	</section>

</div>