import { Application, ApplicationStatusId, DbConceptName } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { LinkOrExplorable } from "../../link-or-explorerable";

export async function getButtons(ds: DataService, application: Application): Promise<LinkOrExplorable[]> {

	const buttons: LinkOrExplorable[] = [];

	//
	// Site
	//
	buttons.push(
		{ explorer: { explorableId: { conceptName: DbConceptName.Site, id: application.siteProgram.siteId }, navTooltip: `Site: ${application.siteProgram.site.name}`, } },
	);

	//
	// Program- Acc or Pic
	//
	if (application.accId) buttons.push({ explorer: { explorableId: { conceptName: DbConceptName.Accelerator, id: application.accId }, navTooltip: `Accelerator: ${application.accelerator.name}`, } });
	if (application.picId) buttons.push({ explorer: { explorableId: { conceptName: DbConceptName.PitchContest, id: application.picId }, navTooltip: `Pitch Contest: ${application.pitchContest.name}`, } });


	//
	// Company
	//
	buttons.push(
		{ explorer: { explorableId: { conceptName: DbConceptName.Company, id: application.companyId }, navTooltip: `Business: ${application.company._name}`, } },
	);


	//
	// If Team
	//
	if (application.accId && application.applicationStatusId == ApplicationStatusId.TeamPromoted) {

		const accTeamsByApplicationId = await ds.admin.accTeam.getByApplicationIds([application.applicationId]);
		const accTeams = accTeamsByApplicationId[application.applicationId];

		for (const accTeam of accTeams) {
			buttons.push({ explorer: { nameOverride: `${accTeam.name} Team`, explorableId: { conceptName: DbConceptName.AccTeam, id: accTeam.accTeamId }, navTooltip: `Team: ${application.company._name}` } },);
		}
	}

	//
	// if Pic
	//

	if (application.picId && application.applicationStatusId == ApplicationStatusId.TeamPromoted) {

		const picTeamsByApplicationId = await ds.admin.picTeam.getByApplicationIds([application.applicationId]);
		const picTeams = picTeamsByApplicationId[application.applicationId];

		for (const picTeam of picTeams) {
			buttons.push({ explorer: { nameOverride: picTeam.name, explorableId: { conceptName: DbConceptName.PicTeam, id: picTeam.picTeamId }, navTooltip: `Team: ${application.company._name}` } },);
		}
	}

	if (application.copiedFromApplicationId) {
		buttons.push({ explorer: { nameOverride: `Show the Original Application`, explorableId: { conceptName: DbConceptName.Application, id: application.copiedFromApplicationId }, navTooltip: `Show the Original Application` } },);
	}

	if (application.copiedToApplicationId) {
		buttons.push({ explorer: { nameOverride: `Show the New Copy of this Application`, explorableId: { conceptName: DbConceptName.Application, id: application.copiedToApplicationId }, navTooltip: `Show the New Copy of this Application` } },);
	}


	return buttons;
}