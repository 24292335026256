import { Component, Input, OnChanges } from '@angular/core';
import { ADDROW_GRID_ACTION_KEY, GridColumnType, GridSetup } from '@me-grid';
import { DbsEvent, EventContext } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { OldDomainDataService } from '@me-services/core/old-dd';
import { ShowEventDialogService } from '@me-shared-parts/ED-editors';
import { InlineGridSize, PageTabsMainTabGridSize } from '@me-shared-parts/UI-common/grid/interfaces/grid-size';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'acc-single-event-grid',
	template: `<me-grid [setup]='gridSetup' [rows]='rows$ | async' (gridAction)='gridActionHandler($event)'></me-grid>`,
})
export class AccSingleEventGridPart implements OnChanges {

	@Input() eventId: number;
	@Input() eventContext: EventContext;
	@Input() stateKey: string;
	@Input() readonly: boolean;
	@Input() gridSize: PageTabsMainTabGridSize | InlineGridSize;

	public gridSetup: GridSetup<DbsEvent>;

	public rows$: Observable<DbsEvent[]>;

	constructor(
		private dd: OldDomainDataService,
		private ds: DataService,
		private showEventDialogService: ShowEventDialogService) {
	}

	ngOnChanges() {

		this.gridSetup = this.setupGrid();

		this.rows$ = this.ds.admin.event
			.observeEvent(this.eventId)
			.pipe(map(event => this.buildRows(event)));

		if (this.readonly || this.eventId) this.gridSetup.canAdd = false;
	}


	buildRows(event: DbsEvent) {
		if (!event) {
			if (!this.readonly) this.gridSetup.canAdd = true;
			return [];
		}
		return [event];
	}

	private setupGrid(): GridSetup<DbsEvent> {

		return {
			experience: 'EVENT',
			size: this.gridSize,
			rowSingularName: 'Event',
			rowPluralName: 'Events',
			rowKey: 'eventId',
			stateKey: this.stateKey,
			canAdd: true,
			canRefresh: false,
			canDownload: true,
			groupArea: 'hide',
			columnsToAdd: [
				{ field: 'EVENT_endUTC', header: 'End', width: 150, type: GridColumnType.dateAndTimeUtc, hidden: false },
				{ field: 'EVENT_toolStartUTC', header: 'Tool Start', width: 150, type: GridColumnType.dateAndTimeUtc, hidden: true },
				{ field: 'EVENT_toolEndUTC', header: 'Tool End', width: 150, type: GridColumnType.dateAndTimeUtc, hidden: true },
			],
			actions: [],
		};
	}


	async gridActionHandler(action: { actionKey: string, rows: DbsEvent[] }) {

		const row = action.rows[0];
		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {
			if (this.readonly || action.rows.length) return;

			await this.showEventDialogService.add(this.eventContext);
		}
	}

}