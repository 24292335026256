import { Component, Input, OnChanges } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { AccMatchingRating } from './acc-matching-rating';


@Component({
	selector: 'acc-matching-rating-part',
	templateUrl: './acc-matching-rating.part.html',
	styleUrls: ['./acc-matching-rating.part.scss'],
})
export class AccMatchingRatingPart implements OnChanges {

	@Input() rating: AccMatchingRating;

	private rating$ = new ReplaySubject<AccMatchingRating>(1);


	ngOnChanges() {
		if (this.rating) this.rating$.next(this.rating);
	}


	getRatingText() {
		if (this.rating?.value == 1) return 'N!';
		if (this.rating?.value == 2) return 'N';
		if (this.rating?.value == 3) return 'Y';
		if (this.rating?.value == 4) return 'Y!';
		return '-';
	}


	getRatingClass() {
		let cssClass = `acc-matching-rating-part `;
		if (this.rating?.value == 1) cssClass += 'score-1';
		else if (this.rating?.value == 2) cssClass += 'score-2';
		else if (this.rating?.value == 3) cssClass += 'score-3';
		else if (this.rating?.value == 4) cssClass += 'score-4';
		else cssClass += 'score-missing';
		return cssClass;
	}
}