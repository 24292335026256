import { AccAreaService, AccPreAcceleratorPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { combineLatest, mergeMap } from 'rxjs';
import { AccPreAcceleratorStageId } from '../../acc-application-status-helpers';
import { buildInterviewGeneralCounts } from './build-interview-general-counts';


@Component({
	selector: 'acc-interview-tab-part',
	templateUrl: './acc-interview-tab.part.html',
	styleUrls: ['./acc-interview-tab.part.scss'],
})
export class AccInterviewTabPart extends DestroyablePart implements OnInit {

	accStageId: AccPreAcceleratorStageId = AccStageId.Interviewing;

	public generalCounts$ = combineLatest([
		this.accAreaService.accessAndId$,
		this.accAreaService.applications.applications$,
		this.accAreaService.interviewing.interviewers$,
		this.accAreaService.interviewing.assessments$,
	]).pipe(
		mergeMap(async ([accessAndId, applications, interviewers, assessments]) => buildInterviewGeneralCounts(this.ds, this.util, this.accStageId, applications, interviewers, assessments))
	);



	constructor(
		private ds: DataService,
		private util: UtilityService,
		public accAreaService: AccAreaService,
		public pageService: AccPreAcceleratorPageService,
	) {
		super();
	}


	ngOnInit(): void {
		super.initDestroyable();
	}

}
