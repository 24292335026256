import { Component, Input } from '@angular/core';

@Component({
	selector: 'page-tag',
	styleUrls: ['./SHR-CMN_page-tag.part.scss'],
	template: `{{label}}`
})
export class PageTagPart {

	@Input() label: string;
}
