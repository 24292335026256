import { AccStageId, ApplicationStatusId } from '@me-interfaces';

export type AccPreAcceleratorStageId = AccStageId.Accepting | AccStageId.Reading | AccStageId.Interviewing | AccStageId.SelectingCohort | AccStageId.MatchingMentors;
export type AccApplicationStatusName = 'Pending' | 'Abandoned' | 'Withdrawn' | 'Rejected' | 'Deferred' | 'CopiedForward' | 'Submitted' | 'Accepted' | 'Selected' | 'In Cohort' | 'Beyond';

export const SET_TO_PENDING_ACTION_KEY = 'set-to-pending-action';
export const SET_TO_ABANDONED_ACTION_KEY = 'set-to-abandoned-action';
export const SET_TO_WITHDRAWN_ACTION_KEY = 'set-to-withdrawn-action';
export const SET_TO_REJECTED_ACTION_KEY = 'set-to-rejected-action';
export const SET_TO_DEFERRED_ACTION_KEY = 'set-to-deferred-action';
export const SET_TO_ACCEPTED_ACTION_KEY = 'set-to-accepted-action';
export const SET_TO_COPIEDFORWARD_ACTION_KEY = 'set-to-copied-forward-action';

export type ApplicationSetStatusAction =
	'set-to-pending-action' |
	'set-to-abandoned-action' |
	'set-to-withdrawn-action' |
	'set-to-rejected-action' |
	'set-to-deferred-action' |
	'set-to-accepted-action';

export interface AccApplicationStageStatus {
	applicationStatusId: ApplicationStatusId,
	name: AccApplicationStatusName,
}

export interface AccApplicationStatusesForStage {

	stageId: AccPreAcceleratorStageId,

	pending: AccApplicationStageStatus,
	abandoned: AccApplicationStageStatus,
	withdrawn: AccApplicationStageStatus,
	rejected: AccApplicationStageStatus,
	deferred: AccApplicationStageStatus,
	copiedForward: AccApplicationStageStatus,
	accepted: AccApplicationStageStatus,

}


/**
 * Given an accelerator pre-accelerator stage, return the named application statuses.
 */
export function getStageStatuses(accStageId: AccPreAcceleratorStageId): AccApplicationStatusesForStage {

	if (accStageId == AccStageId.Accepting) {
		return {
			stageId: AccStageId.Accepting,
			pending: { applicationStatusId: ApplicationStatusId.AcceptPending, name: 'Pending' },
			abandoned: { applicationStatusId: ApplicationStatusId.AcceptAbandoned, name: 'Abandoned' },
			withdrawn: { applicationStatusId: ApplicationStatusId.AcceptWithdrawn, name: 'Withdrawn' },
			rejected: { applicationStatusId: ApplicationStatusId.AcceptRejected, name: 'Rejected' },
			deferred: { applicationStatusId: ApplicationStatusId.AcceptDeferred, name: 'Deferred' },
			copiedForward: { applicationStatusId: ApplicationStatusId.AcceptCopiedForward, name: 'CopiedForward' },
			accepted: { applicationStatusId: ApplicationStatusId.AcceptSubmitted, name: 'Submitted' },
		};
	}
	else if (accStageId == AccStageId.Reading) {
		return {
			stageId: AccStageId.Reading,
			pending: { applicationStatusId: ApplicationStatusId.ReadPending, name: 'Pending' },
			abandoned: { applicationStatusId: ApplicationStatusId.ReadAbandoned, name: 'Abandoned' },
			withdrawn: { applicationStatusId: ApplicationStatusId.ReadWithdrawn, name: 'Withdrawn' },
			rejected: { applicationStatusId: ApplicationStatusId.ReadRejected, name: 'Rejected' },
			deferred: { applicationStatusId: ApplicationStatusId.ReadDeferred, name: 'Deferred' },
			copiedForward: { applicationStatusId: ApplicationStatusId.ReadCopiedForward, name: 'CopiedForward' },
			accepted: { applicationStatusId: ApplicationStatusId.ReadAccepted, name: 'Accepted' },
		};
	}
	else if (accStageId == AccStageId.Interviewing) {
		return {
			stageId: AccStageId.Interviewing,
			pending: { applicationStatusId: ApplicationStatusId.InterviewPending, name: 'Pending' },
			abandoned: { applicationStatusId: ApplicationStatusId.InterviewAbandoned, name: 'Abandoned' },
			withdrawn: { applicationStatusId: ApplicationStatusId.InterviewWithdrawn, name: 'Withdrawn' },
			rejected: { applicationStatusId: ApplicationStatusId.InterviewRejected, name: 'Rejected' },
			deferred: { applicationStatusId: ApplicationStatusId.InterviewDeferred, name: 'Deferred' },
			copiedForward: { applicationStatusId: ApplicationStatusId.InterviewCopiedForward, name: 'CopiedForward' },
			accepted: { applicationStatusId: ApplicationStatusId.InterviewAccepted, name: 'Accepted' },
		};
	}
	else if (accStageId == AccStageId.SelectingCohort) {
		return {
			stageId: AccStageId.SelectingCohort,
			pending: { applicationStatusId: ApplicationStatusId.SelectPending, name: 'Pending' },
			abandoned: { applicationStatusId: ApplicationStatusId.SelectAbandoned, name: 'Abandoned' },
			withdrawn: { applicationStatusId: ApplicationStatusId.SelectWithdrawn, name: 'Withdrawn' },
			rejected: { applicationStatusId: ApplicationStatusId.SelectRejected, name: 'Rejected' },
			deferred: { applicationStatusId: ApplicationStatusId.SelectDeferred, name: 'Deferred' },
			copiedForward: { applicationStatusId: ApplicationStatusId.SelectCopiedForward, name: 'CopiedForward' },
			accepted: { applicationStatusId: ApplicationStatusId.SelectSelected, name: 'Selected' },
		};
	}
}


/**
 * Determine the name of an application status in the context of a given pre-accelerator stage
 */
export function getApplicationStatusNameByStage(accStageId: AccPreAcceleratorStageId, applicationStatusId: ApplicationStatusId): AccApplicationStatusName {

	const status = getStageStatuses(accStageId);

	if (applicationStatusId == status.pending.applicationStatusId) return status.pending.name;
	else if (applicationStatusId == status.abandoned.applicationStatusId) return status.abandoned.name;
	else if (applicationStatusId == status.withdrawn.applicationStatusId) return status.withdrawn.name;
	else if (applicationStatusId == status.rejected.applicationStatusId) return status.rejected.name;
	else if (applicationStatusId == status.deferred.applicationStatusId) return status.deferred.name;
	else if (applicationStatusId == status.copiedForward.applicationStatusId) return status.copiedForward.name;
	else if (applicationStatusId == status.accepted.applicationStatusId) return status.accepted.name;
	else if (applicationStatusId > status.accepted.applicationStatusId) return 'Beyond';


	//
	// If we get here then applicationStatusId < status.pending.applicationStatusId
	//
	throw new Error(`getApplicationStatusName() called with applicationStatusId ${applicationStatusId} which is not valid for accStageId ${accStageId}`);
}

/**
 * Given an applicationStatusId return the status name
 * @param showAccepted - if True: displays the current stage accepted state. If False: displays the next stage pending state.
 */
export function getApplicationStatusName(applicationStatusId: ApplicationStatusId, showAccepted = false) {

	if (applicationStatusId == ApplicationStatusId.AcceptPending) return 'Accept Pending';
	else if (applicationStatusId == ApplicationStatusId.AcceptAbandoned) return 'Accept Abandoned';
	else if (applicationStatusId == ApplicationStatusId.AcceptWithdrawn) return 'Accept Withdrawn';
	else if (applicationStatusId == ApplicationStatusId.AcceptRejected) return 'Accept Rejected';
	else if (applicationStatusId == ApplicationStatusId.AcceptDeferred) return 'Apply Deferred';
	else if (applicationStatusId == ApplicationStatusId.AcceptCopiedForward) return 'Apply CopiedForward';
	else if (applicationStatusId == ApplicationStatusId.AcceptSubmitted) return showAccepted ? 'Accept Submitted' : 'Read Pending';
	else if (applicationStatusId == ApplicationStatusId.ReadAbandoned) return 'Read Abandoned';
	else if (applicationStatusId == ApplicationStatusId.ReadWithdrawn) return 'Read Withdrawn';
	else if (applicationStatusId == ApplicationStatusId.ReadRejected) return 'Read Rejected';
	else if (applicationStatusId == ApplicationStatusId.ReadDeferred) return 'Read Deferred';
	else if (applicationStatusId == ApplicationStatusId.ReadCopiedForward) return 'Read CopiedForward';
	else if (applicationStatusId == ApplicationStatusId.ReadAccepted) return showAccepted ? 'Read Accepted' : 'Interview Pending';
	else if (applicationStatusId == ApplicationStatusId.InterviewAbandoned) return 'Interview Abandoned';
	else if (applicationStatusId == ApplicationStatusId.InterviewWithdrawn) return 'Interview Withdrawn';
	else if (applicationStatusId == ApplicationStatusId.InterviewRejected) return 'Interview Rejected';
	else if (applicationStatusId == ApplicationStatusId.InterviewDeferred) return 'Interview Deferred';
	else if (applicationStatusId == ApplicationStatusId.InterviewCopiedForward) return 'Interview CopiedForward';
	else if (applicationStatusId == ApplicationStatusId.InterviewAccepted) return showAccepted ? 'Interview Accepted' : 'Selection Pending';
	else if (applicationStatusId == ApplicationStatusId.SelectAbandoned) return 'Selection Abandoned';
	else if (applicationStatusId == ApplicationStatusId.SelectWithdrawn) return 'Selection Withdrawn';
	else if (applicationStatusId == ApplicationStatusId.SelectRejected) return 'Selection Rejected';
	else if (applicationStatusId == ApplicationStatusId.SelectDeferred) return 'Selection Deferred';
	else if (applicationStatusId == ApplicationStatusId.SelectCopiedForward) return 'Selection CopiedForward';
	else if (applicationStatusId == ApplicationStatusId.SelectSelected) return showAccepted ? 'Select Selected' : 'Team Pending';
	else if (applicationStatusId == ApplicationStatusId.TeamPromoted) return 'Added to Cohort';
	else throw new Error(`getApplicationStatusName() called with applicationStatusId ${applicationStatusId} which is not valid`);

}

function isNextStage(displayingStage: AccPreAcceleratorStageId, currentStage: AccStageId): boolean {

	if (displayingStage == AccStageId.Accepting && currentStage == AccStageId.Reading) return true;
	if (displayingStage == AccStageId.Reading && currentStage == AccStageId.Interviewing) return true;
	if (displayingStage == AccStageId.Interviewing && currentStage == AccStageId.SelectingCohort) return true;
	if (displayingStage == AccStageId.SelectingCohort && currentStage == AccStageId.SelectingCohort) return true;	// Promotion is a phantom stage.

	return false;
}


export function canSetApplicationStatus(
	canUpdateApplicationStatuses: boolean,
	currentAccStageId: AccStageId,
	displayingAccStageId: AccPreAcceleratorStageId,
	action: ApplicationSetStatusAction,
	selectedStatusIds: ApplicationStatusId[],
) {

	if (!canUpdateApplicationStatuses) return false;

	//
	// Get all the status ids and names for the stage being displayed
	//
	const statuses = getStageStatuses(displayingAccStageId);


	//
	// It is never possible to change an application that is already copiedForward
	//
	if (selectedStatusIds.includes(statuses.copiedForward.applicationStatusId)) return false;


	//
	// Put the statuses in an array for quick and easy checking if the row statuses match
	// MUST NOT include "copiedForward" because it was already checked
	//
	const statusIdsAtStage = [
		statuses.pending.applicationStatusId,
		statuses.abandoned.applicationStatusId,
		statuses.withdrawn.applicationStatusId,
		statuses.rejected.applicationStatusId,
		statuses.deferred.applicationStatusId,
		statuses.accepted.applicationStatusId,
	];


	//
	// If the status of a selected row is not in our local stage statuses then we always return false
	// This could be because a "Beyond" row was selected
	//
	for (const selectedStatusId of selectedStatusIds) {
		if (!statusIdsAtStage.includes(selectedStatusId)) return false;
	}


	const selected = {
		pending: selectedStatusIds.includes(statuses.pending.applicationStatusId),
		abandoned: selectedStatusIds.includes(statuses.abandoned.applicationStatusId),
		withdrawn: selectedStatusIds.includes(statuses.withdrawn.applicationStatusId),
		rejected: selectedStatusIds.includes(statuses.rejected.applicationStatusId),
		deferred: selectedStatusIds.includes(statuses.deferred.applicationStatusId),
		// copiedForward: not needed
		accepted: selectedStatusIds.includes(statuses.accepted.applicationStatusId),
	};


	//
	// Pending is only available if we are displaying the current stage
	//
	if (action == 'set-to-pending-action' && !(displayingAccStageId == currentAccStageId)) return false;

	//
	// Accepted is only available if we are displaying the current stage or the one just before the current stage
	//
	if (action == 'set-to-accepted-action' && !(displayingAccStageId == currentAccStageId || isNextStage(displayingAccStageId, currentAccStageId))) return false;


	//
	// Only enabled if one or more other statuses ae selected
	//
	let enabled = false;
	if (action == 'set-to-pending-action') enabled = selected.abandoned || selected.withdrawn || selected.rejected || selected.deferred || selected.accepted;
	if (action == 'set-to-abandoned-action') enabled = selected.pending || selected.withdrawn || selected.rejected || selected.deferred || selected.accepted;
	if (action == 'set-to-withdrawn-action') enabled = selected.pending || selected.abandoned || selected.rejected || selected.deferred || selected.accepted;
	if (action == 'set-to-rejected-action') enabled = selected.pending || selected.abandoned || selected.withdrawn || selected.deferred || selected.accepted;
	if (action == 'set-to-deferred-action') enabled = selected.pending || selected.abandoned || selected.withdrawn || selected.rejected || selected.accepted;
	if (action == 'set-to-accepted-action') enabled = selected.pending || selected.abandoned || selected.withdrawn || selected.deferred || selected.rejected;

	return enabled;
}