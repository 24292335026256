import { AccStageId, DbConceptName, Site } from "@me-interfaces";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForAccelerators(ds: DataService, site: Site): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const acceleratorsBySiteId = await ds.admin.accelerator.getBySiteIds([site.siteId]);
	const accelerators = acceleratorsBySiteId[site.siteId].filter(acc => acc.accStageId != AccStageId.Complete);

	for (const accelerator of accelerators.sort((a, b) => b.applStartUTC - a.applStartUTC)) {

		const applicationsByAccId = await ds.admin.application.getByAccIds([accelerator.accId]);
		const applications = applicationsByAccId[accelerator.accId];

		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Accelerator,
			header: `${accelerator.siteProgram.program.language.name} - ${accelerator.stage.name}`,
			buttons: [
				{ explorer: { explorableId: { conceptName: DbConceptName.Accelerator, id: accelerator.accId }, navTooltip: `Accelerator: ${accelerator.name}` } },
				{ explorer: { nameOverride: `PM: ${accelerator.managerName}`, explorableId: { conceptName: DbConceptName.Person, id: accelerator.managerId }, navTooltip: `PM: ${accelerator.managerName}` } },
			],
			notes: `${applications.length} Applications`,
		};

		boxes.push(box);
	}

	return boxes;
}