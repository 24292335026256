<div class="error-generic-data-part container-fluid" [style.background]='background'>
	<div class="row justify-content-center">
		<div class="col-10 col-sm-8 col-md-6 col-lg-4">
			<div class="card">
				<div class="card-header text-center">{{activity}}</div>
				<div class="card-body text-center">
					<h3>{{name}}</h3>
					<p></p>
					<p class="card-text">{{message}}</p>
					<p></p>

					<div class="help-text" *ngIf='name === "Failed to Connect"'>
						<me-label key='md:Failed To Connect Guidance'></me-label>
					</div>

					<div class="help-text">
						<me-label key='md:Data Error Guidance'></me-label>
					</div>

					<code class="text-left">{{stack}}</code>
					<div class='btn-container'>
						<button class='btn' (click)='goBack()'>
							<me-label key='OK'></me-label>
						</button>
						<button class='btn' (click)='goToDashboard()'>
							<me-label key='Dashboard'></me-label>
						</button>
						<button [hidden]='!canlogout' class='btn' (click)='logout()'>
							<me-label key='Log out'></me-label>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>