import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DB_LENGTH, QuarterlyGoalFlagsByQuarter } from '@me-interfaces';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { take } from 'rxjs/operators';
import { QUARTER_NAMES } from '../acc-goals-interfaces';
import { UtilityService } from '@me-services/core/utility';

export type AccGoalsAddDialogCallbackValue = {
	quarter: number,
	summary: string,
	details: string
};

@Component({
	selector: 'acc-goals-add-dialog',
	templateUrl: './SHR-PG_acc-goals-add.dialog.html',
	styleUrls: ['SHR-PG_acc-goals-add.dialog.scss'],
})
export class AccGoalsAddDialog extends DestroyablePart implements OnInit {

	values = {
		quarter: {
			readonly: true,
			value: null,
			changed: false,
			min: undefined,
			max: undefined,
			valid: false,
		},
		summary: {
			readonly: true,
			value: '',
			changed: false,
			min: DB_LENGTH.accTeamGoal.summary[0],
			max: DB_LENGTH.accTeamGoal.summary[1],
			valid: false,
		},
		details: {
			readonly: false,
			value: '',
			changed: false,
			min: undefined,
			max: undefined,
			valid: true,
		},

	};

	//
	// The action buttons
	//
	addAction: DialogAction<AccGoalsAddDialogCallbackValue> = {
		id: 'add',
		enabled: false,
		visible: true,
		label: 'Add',
		linkType: 'callback',
		callback: async () => ({
			quarter: this.values.quarter.value,
			summary: this.values.summary.value,
			details: this.values.details.value,
		}),
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: 'Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction<AccGoalsAddDialogCallbackValue | void>[] = [this.addAction, this.cancelAction];
	icon = 'fa-plus';
	headerText = 'Add Goal';

	quarterOptions: { text: string, value: number }[] = [];
	quarterOption: { text: string, value: number };

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ quarterlyGoalFlags: QuarterlyGoalFlagsByQuarter }>,
		public dialogRef: MatDialogRef<AccGoalsAddDialog>,
		private util: UtilityService,
		public labels: LabelsService,
	) {
		super();
	}

	async ngOnInit() {
		super.initDestroyable();

		const getLabel = await this.labels.getLabel$.pipe(take(1)).toPromise();

		this.headerText = getLabel({ key: 'Add Goal' });
		this.addAction.label = getLabel({ key: 'Add' });
		this.cancelAction.label = getLabel({ key: 'Cancel' });

		const quarterlyGoalFlags = this.dialog.data.quarterlyGoalFlags;

		//Build quarterOptions based on the quarterlyGoalFlags.goalsEnabled
		if (quarterlyGoalFlags) {
			for (const q in quarterlyGoalFlags) {
				const quarter = quarterlyGoalFlags[q];
				if (quarter.goalsEnabled) {
					const quarterText = QUARTER_NAMES[+q];
					this.quarterOptions.push({ text: getLabel({ key: quarterText }), value: +q })
				}
				if (this.quarterOptions.length == 1) {
					this.quarterOption = this.quarterOptions[0];
					this.values.quarter.value = this.quarterOption.value;
					this.values.quarter.valid = this.values.quarter.changed = true;
				}
			}
		}
	}


	/**
	 * Event raised when the value of the quarter dropdown changes.
	 */
	quarterDroplistChange(field: 'quarter', quarterTypeOption: { text: string, value: number }) {
		const id = quarterTypeOption.value;
		this.values[field].value = id;
		this.values[field].valid = id != null || id != undefined ? true : false;
		this.determineCanSave();
	}

	/**
	 * Event raised when the value of the summary or details field changes.
	 */
	onInput(field: 'summary' | 'details', e) {
		const text = (e.target.value ?? '').trim();
		this.setPendingValue(field, text);
		this.determineCanSave();
	}

	/**
	 * Checks if it is valid (within the length range)
	 */
	setPendingValue(field: 'summary' | 'details', text: string) {

		//
		// Cleanup the text and fix case
		//
		text = (text ?? '').trim();
		text = this.util.text.fixCase(text, 'sentence-case');


		const values = this.values[field];
		values.value = text;

		//Check length for summary field.
		if (field == 'summary') values.valid = text && text.length >= values.min && text.length <= values.max;

		this.determineCanSave();
	}

	/**
	 * Event raised when the fields loses focus.
	 */
	onBlur(field: 'summary' | 'details') {
		this.values[field].changed = true;
		this.determineCanSave();
	}

	/**
	 * Whenever something changes, check if the save button should be enabled.
	 */
	determineCanSave() {
		const allValid =
			this.values.quarter.valid &&
			this.values.summary.valid &&
			this.values.details.valid;

		this.addAction.enabled = allValid;
	}
}
