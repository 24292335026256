import { DbConceptName, ExplorableConceptName, NoteCategoryId } from "@me-interfaces";
import { DataService } from "../../data.service";
import { SearchableData } from "./interfaces";
import { SearchFilters } from "./parse-search-text";

/**
 * Get all the data to consider for searching.
 */
export async function getSearchableData(ds: DataService, concept: ExplorableConceptName, filters: SearchFilters): Promise<SearchableData> {

	//
	// First fetch the data (limited if a concept was passed as a parameter)
	//
	const data: SearchableData = {
		accTeams: concept == DbConceptName.AccTeam || concept == undefined ? await ds.admin.accTeam.getAllPackagesAsArray() : [],
		accelerators: concept == DbConceptName.Accelerator || concept == undefined ? await ds.admin.accelerator.getAllPackagesAsArray() : [],
		applications: concept == DbConceptName.Application || concept == undefined ? await ds.admin.application.getAllPackagesAsArray() : [],
		companies: concept == DbConceptName.Company || concept == undefined ? await ds.admin.company.getAllPackagesAsArray() : [],
		events: concept == DbConceptName.Event || concept == undefined ? await ds.admin.event.getAllPackagesAsArray() : [],
		persons: concept == DbConceptName.Person || concept == undefined ? await ds.admin.person.getAllPackagesAsArray() : [],
		picTeams: concept == DbConceptName.PicTeam || concept == undefined ? await ds.admin.picTeam.getAllPackagesAsArray() : [],
		pitchContests: concept == DbConceptName.PitchContest || concept == undefined ? await ds.admin.pitchContest.getAllPackagesAsArray() : [],
		sites: concept == DbConceptName.Site || concept == undefined ? await ds.admin.site.getAllPackagesAsArray() : [],
		venues: concept == DbConceptName.Venue || concept == undefined ? await ds.admin.venue.getAllPackagesAsArray() : [],

		entityNotesByEntityId: {},
		positionsByPersonId: {},
		zipByZipId: {},
	}

	let entityNotes = concept == DbConceptName.Company || concept == DbConceptName.Person || concept == undefined ? await ds.admin.entityNote.getAllAsArray() : [];
	data.entityNotesByEntityId = ds.util.array.toArrayMap(entityNotes, note => note.entityId);

	let positions = concept == DbConceptName.Person || concept == undefined ? await ds.admin.position.getAllAsArray() : [];
	data.positionsByPersonId = ds.util.array.toArrayMap(positions, position => position.positionId);

	const zips = await ds.domain.zip.getAll();
	data.zipByZipId = ds.util.array.toMap(zips, zip => zip.zipId);


	//
	// Second, apply the filters
	//

	if (filters.awardee) {
		data.accTeams = data.accTeams.filter(t => t.awardedValue > 0);
		data.accelerators = [];
		data.applications = [];
		data.companies = data.companies.filter(c => c.awardedValue > 0);
		data.events = [];
		data.persons = data.persons.filter(p => p.awardedValue > 0);
		data.picTeams = data.picTeams.filter(t => t.awardedValue > 0);
		data.pitchContests = [];
		data.sites = [];
		data.venues = [];
	}

	if (filters.contactAdmin) {
		data.accTeams = [];
		data.accelerators = [];
		data.applications = [];
		data.companies = [];
		data.events = [];
		data.persons = data.persons.filter(p => p.asSingleton.isCrmUser);
		data.picTeams = [];
		data.pitchContests = [];
		data.sites = [];
		data.venues = [];
	}

	if (filters.deceased) {
		data.accTeams = [];
		data.accelerators = [];
		data.applications = [];
		data.companies = [];
		data.events = [];
		data.persons = data.persons.filter(p => p.asSingleton.isDeceased);
		data.picTeams = [];
		data.pitchContests = [];
		data.sites = [];
		data.venues = [];
	}

	if (filters.droppedOut) {
		data.accTeams = data.accTeams.filter(a => a.droppedOutWeek > 0)
		data.accelerators = [];
		data.applications = [];
		data.companies = [];
		data.events = [];
		data.persons = [];
		data.picTeams = [];
		data.pitchContests = [];
		data.sites = [];
		data.venues = [];
	}

	if (filters.eforallAdmin) {
		data.accTeams = [];
		data.accelerators = [];
		data.applications = [];
		data.companies = [];
		data.events = [];
		data.persons = data.persons.filter(p => p.asSingleton.isEForAllAdmin);
		data.picTeams = [];
		data.pitchContests = [];
		data.sites = [];
		data.venues = [];
	}


	if (filters.noContact) {
		data.accTeams = [];
		data.accelerators = [];
		data.applications = [];
		data.companies = [];
		data.events = [];
		data.persons = data.persons.filter(p => p.doNotContact);
		data.picTeams = [];
		data.pitchContests = [];
		data.sites = [];
		data.venues = [];
	}

	if (filters.techAdmin) {
		data.accTeams = [];
		data.accelerators = [];
		data.applications = [];
		data.companies = [];
		data.events = [];
		data.persons = data.persons.filter(p => p.asSingleton.isTechAdmin);
		data.picTeams = [];
		data.pitchContests = [];
		data.sites = [];
		data.venues = [];
	}

	if (data.companies.length == 0 && data.persons.length == 0) {
		entityNotes = [];
		data.entityNotesByEntityId = {};
		positions = [];
		data.positionsByPersonId = {};
	}

	if (filters.redFlag) {

		const redFlagNotes = entityNotes.filter(n => n.noteCategoryId == NoteCategoryId.RedFlag);
		const redFlagsByEntityId = ds.util.array.toArrayMap(redFlagNotes, note => note.entityId);

		data.accTeams = [];
		data.accelerators = [];
		data.applications = [];
		data.companies = data.companies.filter(c => !!redFlagsByEntityId[c.entityId]);
		data.events = [];
		data.persons = data.persons.filter(p => !!redFlagsByEntityId[p.asSingleton.entityId]);
		data.picTeams = [];
		data.pitchContests = [];
		data.venues = [];
	}

	return data;

}