import { AccAreaData, AccAreaService, AccOverviewPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridColumnType, GridSetup } from '@me-grid';
import { AgreementTypeId, Award } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { Observable, combineLatest } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

interface MissingSignatureRow {
	id: number,
	personId: number,
	agreementType: string,
	role: 'Entrepreneur' | 'Mentor',
}

@Component({
	selector: 'acc-all-missing-signatures-view-part',
	templateUrl: './all-missing-signatures-view.part.html',
})
export class AccAllMissingSignaturesViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();
	public rows$: Observable<MissingSignatureRow[]>;

	constructor(
		public ds: DataService,
		public accAreaService: AccAreaService,
		public util: UtilityService,
		private pageService: AccOverviewPageService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		this.rows$ = combineLatest([
			this.ds.admin.singletonsAsOfUTC$,
			this.accAreaService.data$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1])));
	}

	async buildRows(singletonsAsOfUTC: number, data: AccAreaData) {
		if (!data) return;
		const { entrepreneurPersonIds, mentorPersonIds } = data.team.teams.filter(team => !team.droppedOutWeek).reduce((a: { entrepreneurPersonIds: number[], mentorPersonIds: number[] }, team) => {
			team.members.map(member => {
				if (member.member.role == 'E') {
					a.entrepreneurPersonIds.push(member.member.personId);
				}
				else if (member.member.role == 'C' || member.member.role == 'M') {
					a.mentorPersonIds.push(member.member.personId);
				}
			})
			return a;
		}, { entrepreneurPersonIds: [], mentorPersonIds: [] });

		const signatures = await this.ds.admin.agreementSignature.getByPersonIds([...entrepreneurPersonIds, ...mentorPersonIds]);
		const mentorAgreementTypeVersionIds = (await this.ds.admin.agreementVersion.getArrayByForeignId('agreementTypeId', AgreementTypeId.MentorHandbook)).map(version => version.agreementVersionId);
		const entrepreneurAgreementTypeVersionIds = (await this.ds.admin.agreementVersion.getArrayByForeignId('agreementTypeId', AgreementTypeId.EntrepreneurHandbook)).map(version => version.agreementVersionId);
		const mediaAgreementTypeVersionIds = (await this.ds.admin.agreementVersion.getArrayByForeignId('agreementTypeId', AgreementTypeId.MediaConsent)).map(version => version.agreementVersionId);

		let i = 0;
		const entrepreneurs: MissingSignatureRow[] = this.util.array.cleanNumericIds(entrepreneurPersonIds).reduce((a: MissingSignatureRow[], personId) => {
			if (!signatures[personId].find(sign => entrepreneurAgreementTypeVersionIds.includes(sign.agreementVersionId))) {
				a.push({
					agreementType: 'Entrepreneur Handbook',
					id: i++,
					personId,
					role: 'Entrepreneur',
				});
			}
			if (!signatures[personId].find(sign => mediaAgreementTypeVersionIds.includes(sign.agreementVersionId))) {
				a.push({
					agreementType: 'Media Consent',
					id: i++,
					personId,
					role: 'Entrepreneur',
				});
			}

			return a;
		}, []);


		const mentors: MissingSignatureRow[] = this.util.array.cleanNumericIds(mentorPersonIds).reduce((a: MissingSignatureRow[], personId) => {
			if (!signatures[personId].find(sign => mentorAgreementTypeVersionIds.includes(sign.agreementVersionId))) {
				a.push({
					agreementType: 'Mentor Handbook',
					id: i++,
					personId,
					role: 'Mentor',
				});
			}
			if (!signatures[personId].find(sign => mediaAgreementTypeVersionIds.includes(sign.agreementVersionId))) {
				a.push({
					agreementType: 'Media Consent',
					id: i++,
					personId,
					role: 'Mentor',
				});
			}

			return a;
		}, []);


		return [...entrepreneurs, ...mentors];

	}


	private setupGrid(): GridSetup<Award> {
		return {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				shrinkBy: 0,
				heightMultiplier: 1,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Missing Signature",
			rowPluralName: "Missing Signatures",
			rowKey: "id",
			stateKey: "all-acc-missing-signatures",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: 'agreementType', header: 'Agreement Type', width: 250, type: GridColumnType.text },
				{ field: 'role', header: 'Role', width: 100, type: GridColumnType.text },
			],
			initialState: {
				group: [{ field: 'agreementType' }]
			},
		};
	}

}