import { Component, Input, OnChanges } from '@angular/core';
import { AddressLink, CallLink, EmailLink, Link, SmsLink, ToolLink, WebLink } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogService } from '@me-services/ui/dialog';
import { getIconContext, Icon, IconContext, IconStyle } from '@me-shared-parts/UI-common';
import { getIconDefaultText, getIconForWebLinkType } from '../icon';

@Component({
	selector: 'link-button',
	templateUrl: './link-button.part.html',
	styleUrls: ['./link-button.part.scss'],
})
export class LinkButtonPart implements OnChanges {

	@Input() link: Link;
	@Input() iconStyle: IconStyle = 'fa-regular';

	private longPress = false;
	private iconContext: IconContext;
	private clipboardIconContext: IconContext;
	private clipboardText: string;


	public get iconToShow() {
		return this.longPress ? this.clipboardIconContext : this.iconContext;
	}

	public url = '';
	public target = '';
	public tooltip: string = null


	constructor(
		private ds: DataService,
		private util: UtilityService,
		private dialogService: DialogService,
	) {
	}


	get href() {
		if (!this.longPress) return this.url;
		else return '#';
	}


	ngOnChanges() {
		if (!this.link) throw new Error(`Missing required attribute: link`);

		if (this.link.linkType == 'address') this.configureAddressButton(this.link);
		if (this.link.linkType == 'call') this.configureCallButton(this.link);
		if (this.link.linkType == 'email') this.configureEmailButton(this.link);
		if (this.link.linkType == 'sms') this.configureSmsButton(this.link);
		if (this.link.linkType == 'webLink') this.configureWebLinkButton(this.link);
		if (this.link.linkType == 'tool') this.configureToolLinkButton(this.link);
	}


	onLongPress(longPress: boolean) {
		this.longPress = longPress;
	}


	public async click(event: PointerEvent) {

		if (this.longPress) {
			event.preventDefault();

			await this.util.clipboard.writeText(this.clipboardText);
			await this.dialogService.showMessage(`The following text has been copied to the clipboard.\n\n${this.clipboardText}`, 400, 250);

			this.longPress = false;
			return false;
		}
	}


	getClipboardIcon(text: string): IconContext {
		return getIconContext(Icon.action_clipboard, 'fa-regular', undefined, text);
	}


	configureAddressButton(link: AddressLink) {
		const zip = this.ds.domain.zip.getOne(+link.zipId);
		const text = link.street ? link.street : zip.cityAndState;
		this.iconContext = getIconContext(Icon.action_map, this.iconStyle, undefined, text);
		this.clipboardIconContext = this.getClipboardIcon(text);
		this.clipboardText = `${text} ${zip.code}`;
		this.url = this.buildMapLink(link.zipId, link.street);
		this.target = 'google-map';
		this.tooltip = 'Google Map';
	}


	configureCallButton(link: CallLink) {
		const text = this.util.phone.format(link.phone, link.ext);
		this.iconContext = getIconContext(Icon.action_call, this.iconStyle, undefined, text);
		this.clipboardIconContext = this.getClipboardIcon(text);
		this.clipboardText = text;
		this.url = this.util.phone.makePhoneUrl(link.phone, 'tel');
		this.target = '_self';
		this.tooltip = `Call ${text}`;
	}


	configureEmailButton(link: EmailLink) {
		this.iconContext = getIconContext(Icon.action_email, this.iconStyle, undefined, link.email);
		this.clipboardIconContext = this.getClipboardIcon(link.email);
		this.clipboardText = link.email;
		this.url = `mailto:${link.email}`;
		this.target = '_self';
		this.tooltip = `Email ${link.email}`;
	}


	configureSmsButton(link: SmsLink) {
		const text = `Text (SMS)`;

		this.iconContext = getIconContext(Icon.action_sms, this.iconStyle, undefined, text);
		this.clipboardIconContext = this.getClipboardIcon(text);
		this.clipboardText = link.phone;
		this.url = this.util.phone.makePhoneUrl(link.phone, 'sms');
		this.target = '_self';
		this.tooltip = `Text ${this.util.phone.format(link.phone)}`;
	}


	configureWebLinkButton(link: WebLink) {
		const icon = getIconForWebLinkType(+link.webLinkTypeId);
		const text = link.overrideText ?? getIconDefaultText(icon);

		this.iconContext = getIconContext(icon, this.iconStyle, undefined, text);
		this.clipboardIconContext = this.getClipboardIcon(text);
		this.clipboardText = link.url;
		this.url = link.url;
		this.target = text;	// The default text is 'Website', 'Facebook', 'Twitter', etc.

		const urlSplit = this.url.split('://');
		this.tooltip = urlSplit[urlSplit.length - 1];
		if (this.tooltip.endsWith('/')) this.tooltip = this.tooltip.substring(0, this.tooltip.length - 1);
		if (this.tooltip.toLowerCase().startsWith('www.')) this.tooltip = this.tooltip.substring(4);
	}


	configureToolLinkButton(link: ToolLink) {
		const icon = link.enabled ? Icon.tool_enabled : Icon.tool_disabled;
		const text = link.overrideText ?? getIconDefaultText(icon);

		this.iconContext = getIconContext(icon, this.iconStyle, undefined, text);
		this.clipboardIconContext = this.getClipboardIcon(text);
		this.clipboardText = link.url;
		this.url = link.url;
		this.target = text;	// The default text is 'Tool Enabled', 'Tool Disabled';

		const urlSplit = this.url.split('://');
		this.tooltip = urlSplit[urlSplit.length - 1];
		if (this.tooltip.endsWith('/')) this.tooltip = this.tooltip.substring(0, this.tooltip.length - 1);
		if (this.tooltip.toLowerCase().startsWith('www.')) this.tooltip = this.tooltip.substring(4);
	}


	/**
	 * Formats an address into a Google Maps search string
	 * e.g. https://www.google.com/maps?q=1600+Pennsylvania+Avenue+NW,Washington,DC,20500
	 */
	buildMapLink(zipId: number, street = '') {

		const parts: string[] = [];

		street = (street || '').trim();
		if (street.length) {
			// format the street for google map search
			parts.push(street
				.split(' ')
				.filter(p => !!p.length)
				.join('+')
			);
		}

		const zip = this.ds.domain.zip.getOne(+zipId);
		parts.push(zip.city, zip.state, zip.code);

		return `https://www.google.com/maps?q=` + parts.join(',');
	}
}