import { AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { AccAreaAccess, AccTeam, AppAreaIdentifierWithSiteId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import * as Bowser from 'bowser';
import { Observable, combineLatest } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface CountsData {
	teamsCount: number,
	teamPersonIds: number[],
	mentorsCount: number,
	mentorPersonIds: number[],
	coachesCount: number,
	coachesPersonIds: number[],
	entrepreneursCount: number,
	entrepreneursPersonIds: number[],
	droppedOutTeams: { accTeamId: number, name: string }[],
	quitMentorNames: string[],
}


@Component({
	selector: 'acc-cohort-counts-part',
	templateUrl: './acc-cohort-counts.part.html',
	styleUrls: ['./acc-cohort-counts.part.scss'],
})
export class AccCohortCountsPart extends DestroyablePart implements OnInit {
	readonly: boolean;

	public countsData$: Observable<CountsData>;
	os: string;

	constructor(
		private accAreaService: AccAreaService,
		private ds: DataService,
		private router: Router,
		private route: ActivatedRoute,
	) {
		super();
		const bowser = Bowser.getParser(window.navigator.userAgent);
		this.os = bowser.getOSName();
	}

	ngOnInit(): void {
		super.initDestroyable();

		this.accAreaService.subscribeAccess(this.destroyed$, this.accessChanged);

		this.countsData$ = combineLatest([
			this.accAreaService.teams.teams$,
		]).pipe(mergeMap(data => this.buildData(data[0])));
	}


	accessChanged = async (value: { access: AccAreaAccess, id: AppAreaIdentifierWithSiteId<number> }) => {
		this.readonly = value?.access?.root != 'Write';
	}


	async buildData(
		accTeams: readonly AccTeam[],
	): Promise<CountsData> {

		if (!accTeams) return undefined;

		const activeTeamsPersonIds = [];
		const mentorPersonIds = [];
		const coachesPersonIds = [];
		const entrepreneurPersonIds = [];
		const droppedOutTeamNames = [];
		const quitMentorNames = [];

		for (const accTeam of accTeams) {
			if (accTeam.droppedOutWeek) {
				droppedOutTeamNames.push({ accTeamId: accTeam.accTeamId, name: accTeam.application.company._name });
				continue;
			}

			for (const member of accTeam.members) {
				activeTeamsPersonIds.push(member.member.personId);

				if (member.member.role === 'M') {
					mentorPersonIds.push(member.member.personId);
				}
				if (member.member.role === 'C') {
					coachesPersonIds.push(member.member.personId);
				}
				if (member.member.role === 'E') {
					entrepreneurPersonIds.push(member.member.personId);
				}
				if (member.member.role === 'X') quitMentorNames.push(member.person._name);
			}
		}

		return {
			teamsCount: accTeams.filter(t => !t.droppedOutWeek).length,
			teamPersonIds: [...new Set(activeTeamsPersonIds)],
			mentorsCount: [...new Set(mentorPersonIds)].length,
			mentorPersonIds: [...new Set(mentorPersonIds)],
			coachesCount: [...new Set(coachesPersonIds)].length,
			coachesPersonIds: [...new Set(coachesPersonIds)],
			entrepreneursCount: [...new Set(entrepreneurPersonIds)].length,
			entrepreneursPersonIds: [...new Set(entrepreneurPersonIds)],
			droppedOutTeams: droppedOutTeamNames.sort((a, b) => a.name < b.name ? -1 : 1),
			quitMentorNames: quitMentorNames.sort((a, b) => a.name < b.name ? -1 : 1),
		};
	}


	openTeam(accTeamId: number) {
		this.router.navigate([accTeamId], { relativeTo: this.route });
	}


	async openContactor(personIds: number[]) {
		await this.ds.contactor.show(
			this.readonly,
			personIds,
			'acc-cohort-counts-part',
		);
	}
}