
import { DdPrefixRow } from './dd-prefix-row';
import { DdPrefix } from './dd-prefix';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdPrefixes extends DdObjects<DdPrefixRow, DdPrefix> {

	constructor(private dd: OldDomainDataService) {
		super('prefixId');
	}

	async loadData(hash: string, data: DdPrefixRow[]) {
		const objects = data.map(d => new DdPrefix(this.dd, d));
		this.loadObjects(hash, objects);
	}
}