import { AccAreaService, AccOverviewPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccAreaAccess, AppAreaIdentifierWithSiteId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { PageTabsLayout } from '@me-shared-parts/UI-common';


@Component({
	selector: 'acc-overview-page',
	templateUrl: './acc-overview.page.html',
})
export class AccOverviewPage extends DestroyablePart implements OnInit {

	readonly: boolean;

	constructor(
		public pageService: AccOverviewPageService,
		public ds: DataService,
		public accAreaService: AccAreaService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();

		this.accAreaService.subscribeAccess(this.destroyed$, this.accessChanged);
	}


	accessChanged = async (value: { access: AccAreaAccess, id: AppAreaIdentifierWithSiteId<number> }) => {
		this.readonly = value?.access?.root != 'Write';
	}

	onLayoutChange(layout: PageTabsLayout) {
		this.pageService.changeLayout(layout);
	}

}