<div class='public-entity-address-editor-part'>
	<me-save-changes-message *ngIf='messagedisplay' [hidden]="messagedisplay.disabled"></me-save-changes-message>
	<div class='public-entity-address-editor-content'>
		<form [formGroup]="form" novalidate>
			<table class='pro-table pro-table-no-header pro-table-with-labels'>
				<tr>
					<td>
						<span [title]="inlineLabels['I Live in The U.S.']">
							<me-label key='I Live in The U.S.'></me-label>
						</span>
					</td>
					<td>
						<input type="checkbox" kendoCheckBox [checked]='form.controls.inUS.value'
							(change)='toggleCheckbox("US")' />
					</td>
				</tr>
				<tr>
					<td>
						{{getStreet(form.controls.inUS.value)}}
					</td>

					<td>
						<textarea kendoTextArea [class.street-address]='form.controls.inUS.value'
							[class.full-address]='!form.controls.inUS.value'
							[placeholder]="getStreet(form.controls.inUS.value)" formControlName="address"
							(blur)='constants.blur.trim(form.controls.address)'></textarea>
					</td>
				</tr>
				<tr *ngIf='form.controls.inUS.value'>
					<td>
						<span [title]="inlineLabels['Zip Code']">
							<me-label key='Zip Code'></me-label>
						</span>
					</td>
					<td>
						<input class="k-textbox" [placeholder]="inlineLabels['Zip Code']" formControlName="zipCode"
							maxlength="5" [class.has-error]='!form.controls.zipCode.value'>
						<div *ngIf='!form.controls.zipCode.value' class="k-tooltip-validation">
							<div>
								<me-label key='Required field'></me-label>
							</div>
						</div>
						<div *ngIf='form.controls.zipCode.errors' class="k-tooltip-validation">
							<div *ngIf='form.controls.zipCode.errors.pattern'>#####
							</div>
							<div *ngIf='form.controls.zipCode.errors.badzip'>
								<me-label key='Invalid ZIP'></me-label>
							</div>
						</div>
						<div fxLayout="row" fxLayoutAlign="end" style="font-size:0.8em" *ngIf='zip.city'>{{zip.city}}</div>
					</td>
				</tr>
			</table>

			<button-container>
				<button class='btn' (click)='resetForm()' [disabled]='hasNoChanges() || updating'>
					<me-label key='Revert'></me-label>
				</button>
				<spinner-button #messagedisplay (safeClick)='save()'
					[disabled]='form.invalid || updating || disableSave()' [spinning]='updating'>
					<me-label key='Save Changes'></me-label>
				</spinner-button>
			</button-container>
		</form>
	</div>
</div>