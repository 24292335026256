<div class='explorer-part application-explorer-part'>

	<div class="icon-bar">
		<me-icon-bar #iconBar [icons]='views' [orientation]="'Vertical'" (selectionsChange)='setViews($event)'>
		</me-icon-bar>
	</div>

	<div class="content">
		<ng-container *ngIf='selectedViews'>
			<div class="explorer-header">
				<me-icon [icon]="icons.application"></me-icon>
			</div>
			<h4 class="explorer-sub-header">{{subHeader}}</h4>

			<div *ngIf='applicationStatus' class="explorer-small-header">Status:<span
					class="explorer-highlight-section">{{applicationStatus}}</span></div>

			<section>
				<explorer-buttons [buttons]='buttons'>
					<button *ngIf="application.hasRedFlag" class="text-btn text-btn-not-inline border-red"
						(click)="click('red-flag')">
						<me-icon [icon]="icons.redFlag"></me-icon>
					</button>
				</explorer-buttons>

				<div *ngIf='hasRelatedProgramAppl'>
					<br>
					<message-line alertType='warning'>
						There are related applications of the same program type.  See below.
					</message-line>
				</div>
			</section>

			<explorer-section header="Application Details" (tabClose)="tabClose($event)"
				[style.display]="getDisplay('details')">
				<application-explorer-details [application]="application"></application-explorer-details>
			</explorer-section>

			<explorer-section header="Questions" (tabClose)="tabClose($event)"
				[style.display]="getDisplay('questions')">
				<application-explorer-questions [application]="application"></application-explorer-questions>
			</explorer-section>

			<explorer-section
				[header]="util.text.singularOrPluralWithCount(participantBoxes.length, 'Participant', 'Participants')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('participants')">
				<explorer-boxes>
					<ng-container *ngFor="let box of participantBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

			<explorer-section
				[header]="util.text.singularOrPluralWithCount(relatedNoteBoxes.length, 'Related Note', 'Related Notes')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('related notes')">
				<explorer-note-boxes>
					<ng-container *ngFor="let box of relatedNoteBoxes">
						<explorer-note-box [box]="box"> </explorer-note-box>
					</ng-container>
				</explorer-note-boxes>
			</explorer-section>

			<explorer-section
				[header]="util.text.singularOrPluralWithCount(relatedApplicationBoxes.length, 'Related Application', 'Related Applications')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('related applications')">
				<explorer-boxes>
					<ng-container *ngFor="let box of relatedApplicationBoxes">
						<explorer-box [box]="box"> </explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

		</ng-container>
	</div>
</div>