<selectable-view *ngIf='profileSelectors' [selectorList]='profileSelectors' listTitle='Profile'
	[initialSelectorKey]='initialSelectorKey' (selectorKeyChanged)='setSelectedKey($event)'>
	<div class='selector-content-section' *ngIf='key'>
		<ng-container>
			<div class='selector-editor-section'>
				<div *ngIf='key === "Basic Info"'>
					<selectable-view-header>Basic Info</selectable-view-header>
					<person-basic-info-editor [person]='person' (updatePerson)='personChanged($event)'>
					</person-basic-info-editor>
				</div>
				<div *ngIf='key === "Emails"'>
					<selectable-view-header>Emails</selectable-view-header>
					<person-emails-editor [person]='person'></person-emails-editor>
				</div>
				<div *ngIf='key === "Do Not Contact"'>
					<selectable-view-header>Do Not Contact</selectable-view-header>
					<person-do-not-contact-editor [person]='person' (updatePerson)='personChanged($event)'>
					</person-do-not-contact-editor>
				</div>
				
				<div *ngIf='key === "Address"'>
					<selectable-view-header>Address</selectable-view-header>
					<entity-address-editor [personWrapper]='entityService.wrapPerson(person)'>
					</entity-address-editor>
				</div>
				<div *ngIf='key === "Languages"'>
					<selectable-view-header>Languages & Bios</selectable-view-header>
					<person-languages-editor [person]='person'></person-languages-editor>
				</div>
				<div *ngIf='key === "Web Links"'>
					<selectable-view-header>Web Links</selectable-view-header>
					<entity-online-editor [personWrapper]='entityService.wrapPerson(person)'>
					</entity-online-editor>
				</div>
				<div *ngIf='key === "Permissions"'>
					<selectable-view-header>Permissions</selectable-view-header>
					<person-permissions-editor [person]='person'></person-permissions-editor>
				</div>
				<div *ngIf='key === "Demographics"'>
					<selectable-view-header>Demographics</selectable-view-header>
					<message-line alertType='warning' *ngIf='!user.canEditDemographics'>You do not have Permissions to
						view or edit Demographics.</message-line>
					<person-demographics-editor *ngIf='user.canEditDemographics' adminAccess='true'
						[personId]='person.personId'>
					</person-demographics-editor>
				</div>
			</div>
		</ng-container>
	</div>
</selectable-view>