import { LabelsService } from '@me-services/ui/labels';
import { ColumnComponent, DataStateChangeEvent, GridComponent } from "@progress/kendo-angular-grid";
import { GroupDescriptor } from "@progress/kendo-data-query";
import { Subscription } from "rxjs";
import { GridSetup } from '../interfaces';
import { FilterableGridController } from './filterable-grid-controller';


const EMPTY_TEXT = 'Drag a column header and drop it here';

export class GroupableGridController<ROW> extends FilterableGridController<ROW> {

	public groups: GroupDescriptor[] = [];
	private lastGroups: GroupDescriptor[] = [];
	private gridGroupsSubscription: Subscription;


	/**
	* Functionality for controlling a grid that supports adding and patching filters, and row selection
	* @param gridSetup A configuration object that defines grid properties
	* @param labelsService Pass a labels service in if this grid is on a public-facing page
	*/
	constructor(
		gridSetup: GridSetup<ROW>,
		labelsService: LabelsService = undefined,
	) {

		super(gridSetup);

		const hideGroupText = gridSetup.groupArea == 'showWithoutInstructions';

		this.groupable = {
			emptyText: hideGroupText ? ' ' : EMPTY_TEXT,
			enabled: true,
			showFooter: false,
		};

		if (!hideGroupText && labelsService) this.setTranslatedEmptyText(labelsService);
	}


	async setTranslatedEmptyText(labelsService: LabelsService) {

		const getLabel = await labelsService.getLabel();
		const emptyText = getLabel({ key: EMPTY_TEXT });

		this.groupable = { ...this.groupable, emptyText };
	}


	public override setGridComponent(gridComponent: GridComponent | undefined) {
		// Remove subscription from prior grid
		if (this.gridComponent && this.gridComponent !== gridComponent) {
			this.gridGroupsSubscription.unsubscribe();
		}

		super.setGridComponent(gridComponent);

		if (gridComponent) {
			// Listen for group changes on this grid
			this.gridGroupsSubscription = gridComponent.groupChange.subscribe(this.groupChange.bind(this));
		}
	}

	public override dataStateChange(state: DataStateChangeEvent): void {
		this.lastGroups = this.state.group || [];
		super.dataStateChange(state);
	}


	private groupChange(groups: GroupDescriptor[]) {

		const changes: { [index: string]: string } = {};

		//
		// Assume all existing groups are being removed
		//
		for (const group of this.lastGroups) {
			changes[group.field] = 'remove';
		}

		//
		// Any new ones are being added, if were there before then ignore
		//
		for (const group of groups) {
			if (changes[group.field]) changes[group.field] = 'ignore';
			else changes[group.field] = 'add';
		}

		const gridComponent = this.gridComponent;

		//
		// Hide columns as needed
		//
		for (const field of Object.keys(changes)) {
			const column = (this.gridComponent.columns.toArray() as ColumnComponent[]).find(c => c.field == field)
			if (column) {
				if (changes[field] == 'remove') column.hidden = false;
				else if (changes[field] == 'add') column.hidden = true;
			}
		}

		//
		// There seems to be a bug, at least for now, with the Kendo grid that focuses the right-most
		// column header upon grouping.  This forces the horizontal scrollbar all the way to the right.
		// This is an unexpected and jarring experience. To overcome this, we force the focus to the
		// left-most column header.
		//
		setTimeout(() => {
			gridComponent.focusCell(0, 0);
		}, 0);
	}
}