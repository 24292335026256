<me-dialog-frame [header]='header' [showToolbar]='true' [dialogRef]='dialogRef' icon='far fa-user'>

	<ng-container toolbar *ngIf='person'>
		<a class="btn" (click)='openPersonEdit()'>
			<me-icon [icon]="{ icon:'far fa-pencil', text:'Edit', hideText:'lt-md' }"></me-icon>
		</a>

		<a class="btn" (click)='openPersonEdit(2)'>
			<me-icon [icon]="notesIcon"></me-icon>
		</a>

		<div class="toolbar-separator"></div>

		<a class="btn" *ngIf='emailLink' [href]='emailLink'>
			<me-icon [icon]="{ icon:'far fa-envelope', text:'Email', hideText:'always' }"></me-icon>
		</a>

		<a class="btn" *ngIf='telLink' [href]='telLink'>
			<me-icon [icon]="{ icon:'far fa-phone', text:'Call', hideText:'always' }"></me-icon>
		</a>

		<a class="btn" *ngIf='smsLink' [href]='smsLink'>
			<me-icon [icon]="{ icon:'far fa-comment-alt-lines', text:'Text Message', hideText:'always' }">
			</me-icon>
		</a>

		<div class="toolbar-separator" *ngIf='person._entityExtracts?.webLinks'></div>

		<a class="btn" *ngFor='let webLink of person._entityExtracts?.webLinks || []' target='_blank'
			[href]='webLink.url'>
			<me-icon [icon]="getWebLinkIcon(webLink)">
			</me-icon>
		</a>

	</ng-container>

	<div class="person-role-badges-container">
		<div class='person-role-badges-area'>
			<person-roles [tagIds]='person._personExtracts.tagIds'
				[associations]='person._personExtracts.associations || []'>
			</person-roles>
		</div>
	</div>

	<kendo-tabstrip class='tabs-only tabs-have-page-background'>
		<kendo-tabstrip-tab [title]="'Info'" [selected]="true">
			<ng-template kendoTabContent>
				<person-extract-info *ngIf='person' [person]="person" [dialogRef]='dialogRef'></person-extract-info>
			</ng-template>
		</kendo-tabstrip-tab>
		<kendo-tabstrip-tab [title]="'Tags'">
			<ng-template kendoTabContent>
				<person-tags-overview [tags]='personTags'></person-tags-overview>
			</ng-template>
		</kendo-tabstrip-tab>
		<kendo-tabstrip-tab [title]="'Associations'">
			<ng-template kendoTabContent>
				<person-extract-associations *ngIf='person' [person]="person"></person-extract-associations>
			</ng-template>
		</kendo-tabstrip-tab>
	</kendo-tabstrip>

</me-dialog-frame>