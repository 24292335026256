import { AccAreaService } from '@ACC-area';
import { SiteAreaService } from '@SITE-area';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DbdTopic } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { GroupResult, groupBy } from '@progress/kendo-data-query';


interface SessionDropDown { accSessionId: number, weekWithDate: string }


@Component({
	selector: 'topic-config-dialog',
	templateUrl: './topic-config.dialog.html',
})
export class TopicConfigDialog extends DestroyablePart implements OnInit {
	//
	// The action buttons
	//
	saveAction: DialogAction<{ accSessionId: number, specialistPersonIds: number[] }> = {
		id: 'save',
		enabled: true,
		visible: true,
		label: 'Save',
		linkType: 'callback',
		callback: async () => (this.newTopicConfig),
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: 'Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction<{ accSessionId: number, specialistPersonIds: number[] } | void>[] = [this.saveAction, this.cancelAction];
	icon = 'fa-pen';
	readonly: boolean;

	orgTopicConfig: { accSessionId: number, specialistPersonIds: number[] };
	newTopicConfig: { accSessionId: number, specialistPersonIds: number[] };
	sessions: SessionDropDown[];
	public defaultSession: SessionDropDown = {
		accSessionId: undefined,
		weekWithDate: '',
	};
	ddTopic: DbdTopic;
	specialists: { name: string, personId: number, status: string }[] = [];
	public groupedData: GroupResult[] | { name: string, personId: number, status: string, }[];

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ readonly: boolean, accSessionId: number, topicId: number, accTopicSpecialistIds: number[], }>,
		public dialogRef: MatDialogRef<TopicConfigDialog>,
		private util: UtilityService,
		private ds: DataService,
		private accAreaService: AccAreaService,
		private siteAreaService: SiteAreaService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		const { readonly, accSessionId, accTopicSpecialistIds, topicId } = this.dialog.data;
		this.readonly = readonly;
		this.orgTopicConfig = { accSessionId, specialistPersonIds: accTopicSpecialistIds.sort((a, b) => a - b) || [] };
		this.newTopicConfig = { ...this.orgTopicConfig };
		this.ddTopic = this.ds.domain.topic.getOne(topicId);
		super.subscribe([
			this.accAreaService.accelerator.acc$,
			this.accAreaService.curriculum.sessions$,
			this.siteAreaService.data$,
		], async ([acc, sessions, siteAreaData]) => {

			if (!acc || !sessions || !siteAreaData) return;
			const eventsMap = sessions.length ? await this.ds.admin.event.getManyAsMap(sessions.map(s => s.eventId)) : {};

			this.sessions = sessions.map(session => ({
				accSessionId: session.accSessionId,
				weekWithDate: this.util.date.formatUTC(eventsMap[session.eventId].startUTC, 'MMM D, YYYY (DOW)', 'H:MM AM EST', this.ds.languageId),
			}));

			const sessionFound = this.sessions.find(e => e.accSessionId == this.newTopicConfig.accSessionId);
			if (!sessionFound) this.newTopicConfig.accSessionId = undefined;

			this.specialists.push({
				name: acc.directorName,
				personId: acc.directorId,
				status: 'Program Staff',
			});

			if (acc.directorId != acc.managerId) {
				this.specialists.push({
					name: acc.managerName,
					personId: acc.managerId,
					status: 'Program Staff',
				});
			}

			const siteSpecialists = (await this.ds.admin.specialist.getBySiteIds([siteAreaData.site.siteId]))[siteAreaData.site.siteId];

			this.specialists.push(...siteSpecialists
				.filter(ss => ss.topicId == topicId && acc.siteProgram.program.languageId == ss.languageId)
				.map(specialist => ({ name: specialist.person.fullName, personId: specialist.personId, status: `${specialist.status} Specialists` }))
			);

			this.specialists = this.specialists.sort((a, b) => a.name > b.name ? 1 : -1);


			this.groupedData = groupBy(this.specialists, [
				{ field: "status" },
			]);
		});

		this.checkIfCanSave();
	}

	onSelectionChange(e: number | undefined) {
		this.newTopicConfig.accSessionId = e;
		this.checkIfCanSave();
	}

	specialistsChange(e: number[]) {
		this.newTopicConfig.specialistPersonIds = [...e].sort((a, b) => a - b);
		this.checkIfCanSave();
	}

	checkIfCanSave() {
		this.saveAction.enabled = !this.readonly &&
			(this.newTopicConfig.accSessionId != this.orgTopicConfig.accSessionId
				|| !this.util.values.areSame(this.newTopicConfig.specialistPersonIds, this.orgTopicConfig.specialistPersonIds));
	}


}