import { getIconContext, Icon, IconContext } from '../icon';

export interface DroplistPopupConfig {

	/**
	 * Provide an instructional messsage to be displayed above the droplist
	 * in the popup. e.g. "Select a Person"
	 */
	message: string,

	/**
	 * Optionally change the icon displayed in the button.
	 * Default: "{ icon: "fad fa-arrows-alt-h", color:"#666" }"
	 */
	icon?: IconContext,

}


export const DEFAULT_POPUP_CONFIG: DroplistPopupConfig = {
	message: 'Select an Item...',
	icon: getIconContext(Icon.droplist_defaultSelectAnItem, undefined, 'always'),
};