import { AppAreaName } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { DashboardData, GeneralIndicatorValues } from "../interfaces";

/**
 * @returns Get general counts like number of regions, number of sites, etc.
 */
export async function getGeneralIndicatorValues(
	areaName: AppAreaName,
	data: DashboardData,
	ds: DataService,
	util: UtilityService,
): Promise<GeneralIndicatorValues> {

	const regions = areaName === 'Admin' ? data.overall.regionIds.length : 0;
	const sites = areaName !== 'Site' ? data.overall.siteIds.length : 0;
	const staff = 0;
	const boardMembers = 0;
	const zipCodes = 0;

	return {
		regions,
		sites,
		staff,
		boardMembers,
		zipCodes,
	};

}