import { AccTeam, DbConceptName } from "@me-interfaces";
import { Icon } from "@me-shared-parts/UI-common";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForAwards(ds: DataService, accTeam: AccTeam): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const awardsByAccTeamId = await ds.admin.award.getByAccTeamIds([accTeam.accTeamId]);
	const awards = awardsByAccTeamId[accTeam.accTeamId];

	for (const award of [...awards].sort((a, b) => a.awardNameId > b.awardNameId ? 1 : -1)) {
		const eventType = ds.domain.eventType.getOne(award.event.eventTypeId).name;
		const awardName = ds.domain.awardName.getOne(award.awardNameId).name;
		const awardKind = ds.domain.awardKind.getOne(award.awardKindId).name;

		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Award,
			header: `Award`,
			buttons: [
				{ explorer: { nameOverride: eventType, explorableId: { conceptName: DbConceptName.Event, id: award.eventId }, navTooltip: `Event: ${eventType}` } },
				{ explorer: { nameOverride: `$${award.value} - ${awardName}`, explorableId: { conceptName: DbConceptName.Event, id: award.eventId }, navIconOverride: Icon.concept_award, navTooltip: `Award: $${award.value} - ${awardName}` } },
			],
			notes: awardKind,
		};

		boxes.push(box);
	}

	return boxes;
}