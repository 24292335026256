import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DbsEvent, EventContext } from '@me-interfaces';
import { IconContext } from '@me-shared-parts/UI-common';
import { EventDialogResult } from '../../event/event-dialog/SHR-ED_event-dialog-result';
import { ShowEventDialogService } from '../../event/event-dialog/SHR-ED_show-event-dialog.service';


const ADD_EVENT_ICON: IconContext = { text: "Add an Event", iconClass: "fal fa-calendar-plus", hideText: 'always' };

@Component({
	selector: 'event-button',
	templateUrl: './SHR-ED_event-button.part.html',
	styleUrls: ['./SHR-ED_event-button.part.scss']
})
export class EventButtonPart implements OnChanges, OnInit {

	@Input() existingEvent: DbsEvent;
	@Input() newEvent: EventContext;
	@Input() buttonClass = '';
	@Output() eventChanged = new EventEmitter<EventDialogResult>();

	displayStyle: 'NONE' | 'ADD' | 'EDIT' = 'NONE';
	icon: IconContext = ADD_EVENT_ICON;

	constructor(private eventDialogService: ShowEventDialogService) {
	}

	ngOnInit() {
		if (!this.existingEvent && !this.newEvent) throw new Error(`EventButton part missing required attribute: existingEvent OR newEvent`);
	}


	ngOnChanges() {
		this.setupView(this.existingEvent, this.newEvent);
	}


	async showDialog() {
		let result: EventDialogResult;

		if (this.displayStyle == 'ADD') result = await this.eventDialogService.showAdd(this.newEvent);
		else if (this.existingEvent) result = await this.eventDialogService.showEdit(this.existingEvent);

		if (result) {
			//
			// If the event was deleted, then we configure a NewEventContext with the deleted
			// event's context values. This way it will be rendered for the user to click a
			// button to add another event with the same context.
			//
			if (result.action == 'DELETED') this.setupView(undefined, {
				eventTypeId: result.event.eventTypeId,
				siteId: result.event.siteId,
				accId: result.event.accId,
				picId: result.event.picId,
				languageId: result.event.languageId,
			});

			//
			// If it was added or updated then configure the event provided.
			//
			else this.setupView(result.event, undefined);
			this.eventChanged.emit(result);
		}
	}


	/**
	 * Configure properties that define how the HTML will be rendered.
	 */
	setupView(existingEvent: DbsEvent, newEvent: EventContext) {

		if (!event) return;

		this.displayStyle = 'NONE';
		this.existingEvent = existingEvent;

		if (newEvent) {
			this.icon = ADD_EVENT_ICON;
			this.displayStyle = 'ADD';
		}
		else {
			const text = new Date(existingEvent.startUTC * 1000).toLocaleString();
			this.icon = { text, iconClass: "far fa-calendar", hideText: 'never' };
			this.displayStyle = 'EDIT';
		}
	}

}