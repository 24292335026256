import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { AlertType, DashboardCardButton, LabelKey } from '@me-interfaces';


@Component({
	selector: 'dashboard-card',
	styleUrls: ['./SHR-CMN_dashboard-card.part.scss'],
	templateUrl: './SHR-CMN_dashboard-card.part.html',
})
export class DashboardCardPart implements OnChanges {

	@HostBinding('class') classes = '';
	@Input() headerKey: LabelKey | string;
	@Input() alertType: AlertType = AlertType.Info;
	@Input() buttons: DashboardCardButton[] = [];


	ngOnChanges() {
		let classes = '';
		if (this.alertType == AlertType.Info) classes += 'card-alert-info ';
		if (this.alertType == AlertType.Warning) classes += 'card-alert-warning ';
		if (this.alertType == AlertType.Error) classes += 'card-alert-error ';
		this.classes = classes;
	}

	getTypeOf(value: LabelKey | string) {
		return typeof value;
	}
}