
import { DdAccLanguageSeasonRow } from './dd-acc-season-row';
import { DdAccLanguageSeason } from './dd-acc-season';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use AccLanguageSeason from the DataService instead
 */
export class DdAccLanguageSeasons extends DdObjects<DdAccLanguageSeasonRow, DdAccLanguageSeason> {

	constructor(private dd: OldDomainDataService) {
		super('accLanguageSeasonId');
	}

	async loadData(hash: string, data: DdAccLanguageSeasonRow[]) {
		const objects = data.map(d => new DdAccLanguageSeason(this.dd, d));
		this.loadObjects(hash, objects);
	}
}