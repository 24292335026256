<div class='company-description-editor-part'>
	<me-save-changes-message *ngIf='messagedisplay' [hidden]="messagedisplay.disabled"></me-save-changes-message>
	<div class='company-description-editor-content'>
		<form [formGroup]="form" novalidate>
			<table class='pro-table pro-table-no-header'>
				<tr>
					<td>
						<textarea kendoTextArea formControlName="description" placeholder="Description (multiple lines)"
							rows='5' (blur)='constants.blur.fixCaseOfValue(form.controls.description)'></textarea>
						<div fxLayout='row'
							*ngIf='form.controls.description.errors && form.controls.description.errors.maxlength'
							class="k-tooltip-validation">
							{{tooManyCharacters(form.controls.description)}} </div>
					</td>
				</tr>
			</table>
			<button-container>
				<spinner-button (safeClick)='resetForm()' [disabled]='!form.dirty || updating'>Revert
				</spinner-button>
				<spinner-button #messagedisplay (safeClick)='save()'
					[disabled]='form.invalid || !form.dirty || updating' [spinning]='updating'>Save
					Changes
				</spinner-button>
			</button-container>
		</form>
	</div>
</div>