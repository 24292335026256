<div *ngIf="!person._personExtracts.positions.length" class='mb-3'>
	<i>{{person.firstName}} has no known positions</i>
</div>
<div *ngFor='let pos of person._personExtracts.positions' class='person-position'>
	<button class='btn' routerLink='/access/contacts/companies/{{pos.company.companyId}}/overview'><i
			class='fal fa-building' [title]='"Go to " + pos.company._name'></i></button>
	<div>
		{{pos.position.title}}
		<person-badge *ngIf='pos.position.isFounder' badge='position-founder'></person-badge>
		<person-badge *ngIf='pos.position.isAdmin' badge='position-admin'></person-badge>
	</div>
	<div>{{pos.company._name}}</div>
	<hr>
</div>