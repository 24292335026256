<div class='explorer-part region-explorer-part'>

	<div class="icon-bar">
		<me-icon-bar #iconBar [icons]='views' [orientation]="'Vertical'" (selectionsChange)='setViews($event)'>
		</me-icon-bar>
	</div>

	<div class="content">
		<ng-container *ngIf='selectedViews'>
			<div class="explorer-header">
				<me-icon [icon]="icons.region"></me-icon>
			</div>
			<h4 class="explorer-sub-header">Region</h4>


			<section>
				<explorer-buttons [buttons]='buttons'></explorer-buttons>
			</section>

			<explorer-section header="Region Details" (tabClose)="tabClose($event)"
				[style.display]="getDisplay('details')">
				<region-explorer-details [region]="region"></region-explorer-details>
			</explorer-section>

			<explorer-section [header]="util.text.singularOrPluralWithCount(siteBoxes.length, 'Site', 'Sites')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('sites')">
				<explorer-boxes>
					<ng-container *ngFor="let box of siteBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

		</ng-container>
	</div>
</div>