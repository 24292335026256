import { Accelerator, DbsAccelerator, DbsPerson, SiteProgram } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { UrlService } from "@me-services/ui/url";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { SiteProgramPackageManager } from "./site-program";


export class AcceleratorPackageManager extends PackageManager<DbsAccelerator, Accelerator> {


	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsAccelerator>,
		private urlService: UrlService,
		private domain: DomainDataManagers,
		private person: SingletonsManager<DbsPerson>,
		private siteProgram: SiteProgramPackageManager,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Convert an array of DbcAccelerator to an array of Accelerator
	 */
	protected async _createPackages(dbsAccs: DbsAccelerator[]): Promise<Accelerator[]> {

		//
		// Get all the people
		//
		const personIds: number[] = [];
		for (const dbsAcc of dbsAccs) {
			personIds.push(dbsAcc.directorId);
			personIds.push(dbsAcc.managerId);
			personIds.push(dbsAcc.updatedByPersonId);
		}

		const personMap = await this.person.getManyAsMap(personIds);


		//
		// Get all the site programs
		//
		const siteProgramIds = dbsAccs.map(acc => acc.siteProgramId);
		const sitePrograms = await this.siteProgram.getManyPackagesAsArray(siteProgramIds);
		const accStageMap = this.domain.accStage.getAllAsMap();
		const accMeetingTimesMap = this.domain.accMeetingTimes.getAllAsMap();
		const accLanguageSeasonsMap = this.domain.accLanguageSeason.getAllAsMap();

		//
		// Package 'em up
		//
		const accs: Accelerator[] = dbsAccs.map(acc => {

			const director = personMap[acc.directorId];
			const manager = personMap[acc.managerId];
			const updatedByPerson = personMap[acc.updatedByPersonId];
			const siteProgram = sitePrograms.find(sp => sp.siteProgramId == acc.siteProgramId);
			const baseUrl = this.urlService.baseUrl;
			const accLanguageSeason = accLanguageSeasonsMap[acc.accLanguageSeasonId];

			return {
				...acc,
				id: acc.accId,
				explorerName: `${siteProgram.site.code6} ${acc.name}`,
				directorName: director?._name || `Person #${acc.directorId}`,
				managerName: manager?._name || `Person #${acc.managerId}`,
				updatedByPersonName: updatedByPerson?._name || `Person #${acc.updatedByPersonId}`,
				siteProgram,
				stage: accStageMap[acc.accStageId],
				accMeetingTimes: accMeetingTimesMap[acc.accMeetingTimesId],
				accLanguageSeason: accLanguageSeason,
				season: `${accLanguageSeason.accSeasonId} - ${accLanguageSeason.name}`,
				appUrl: this.createAppUrl(acc, siteProgram),
				hasRedFlag: false,
				userTools: {
					applicationUrl: `${baseUrl}/access/my/programs/applications/apply?key=${siteProgram.programId}-${acc.accId}`,
					readingUrl: `${baseUrl}/access/sites/${siteProgram.site.code.toLowerCase()}/accelerators/${acc.accId}/read`,
					interviewingUrl: `${baseUrl}/access/sites/${siteProgram.site.code.toLowerCase()}/accelerators/${acc.accId}/interview`,
					matchingUrl: `${baseUrl}/access/sites/${siteProgram.site.code.toLowerCase()}/accelerators/${acc.accId}/matching`,
					judgingUrl: `${baseUrl}/access/sites/${siteProgram.site.code.toLowerCase()}/accelerators/${acc.accId}/judge`,
				},

			};
		});

		return accs;
	}

	private createAppUrl(acc: DbsAccelerator, sp: SiteProgram): string {
		const accId = acc.accId;
		const siteId = sp.site.siteId;
		return `/access/admin/national/sites/${siteId}/accelerators/${accId}/overview`;
	}


	/**
	* Get all accelerators for each siteId provided
	*/
	public async getBySiteIds(siteIds: number[]): Promise<Readonly<Record<number, ReadonlyArray<Accelerator>>>> {

		return await this.getPackagesAsArraysByForeignIds('siteId', siteIds);
	}

}