<div class='event-attendee-edit'>
	<ng-container *ngIf='newAttendee'>
		<table style='width:100%' class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>

			<tr *ngIf='!isAdd'>
				<td>Name</td>
				<td>
					<person-extract-link *ngIf='newAttendee.person' [person]='newAttendee.person'></person-extract-link>
				</td>
			</tr>

			<tr *ngIf='isAdd'>
				<td [ngClass]='{"pro-table-label-error": missing.personId}'>Search</td>
				<td>
					<button class="text-btn text-btn-not-inline" (click)="searchPerson()">
						<me-icon [icon]="searchIcon"></me-icon>
					</button>
				</td>
			</tr>


			<tr>
				<td [ngClass]='{"pro-table-label-error": missing.status}'>Status</td>
				<td>
					<kendo-dropdownlist [style.width.%]='100' [value]="newAttendee.status"
						(valueChange)='setStatus($event)' [data]='statuses'>
					</kendo-dropdownlist>
				</td>
			</tr>
		</table>
	</ng-container>
</div>