import { EventEmitter } from "@angular/core";
import { UtilityService } from "@me-services/core/utility";
import { Icon } from "@me-shared-parts/UI-common/icon";
import { CtrlAltShift } from "../controller/selectable-grid-controller";
import { DBLCLICK_GRID_ACTION_KEY, GridSetup } from "../interfaces";
import { GridExperience } from "./grid-experience";


/**
 * Standard experience for a generic grid with no base
 */
export class GenericGridExperience<ROW> extends GridExperience<ROW> {


	constructor(
		util: UtilityService,
		setup: GridSetup<ROW>,
		gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>
	) {
		super(util, setup, [], [], [], [], gridAction, Icon.action_menu);
	}


	public async applyBaseValues(rows: ROW[]) {
		// GenericGridExperience has no base values 
	}

	
	public sortRows(rows: ROW[]) {
		// GenericGridExperience performs no sorting
	}


	public override async doGridAction(actionKey: string, rows: ROW[]) {
		this.gridAction.emit({ actionKey, rows });
	}


	public override async handleDoubleClick(keys: CtrlAltShift, row: ROW) {
		this.gridAction.emit({ actionKey: DBLCLICK_GRID_ACTION_KEY, rows: [row] });
	}


	public async explore(row: ROW) {
		// GenericGridExperience performs no explore
	}


	public async open(row: ROW) {
		// GenericGridExperience performs no open
	}
	
	
	public async editNotes(row: ROW) {
		// GenericGridExperience performs no notes
	}
}