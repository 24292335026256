<div class='explorer-part pitch-contest-explorer-part'>

	<div class="icon-bar">
		<me-icon-bar #iconBar [icons]='views' [orientation]="'Vertical'" (selectionsChange)='setViews($event)'>
		</me-icon-bar>
	</div>

	<div class="content">
		<ng-container *ngIf='selectedViews'>
			<div class="explorer-header">
				<me-icon [icon]="icons.pic"></me-icon>
			</div>

			<h4 class="explorer-sub-header">Pitch Contest</h4>
			<div class="explorer-small-header">Stage:<span
					class="explorer-highlight-section">{{pitchContest.stage.name}}</span></div>

			<section>
				<explorer-buttons [buttons]='buttons'></explorer-buttons>
			</section>

			<explorer-section header="Venue Details" (tabClose)="tabClose($event)"
				[style.display]="getDisplay('details')">
				<pitch-contest-explorer-details [pitchContest]="pitchContest"></pitch-contest-explorer-details>
			</explorer-section>

			<explorer-section
				[header]="util.text.singularOrPluralWithCount(participantsBoxes.length, 'Participant', 'Participants')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('participants')">
				<explorer-boxes>
					<ng-container *ngFor="let box of participantsBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

			<explorer-section
				[header]="util.text.singularOrPluralWithCount(applicationBoxes.length, 'Application', 'Applications')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('applications')">
				<explorer-boxes>
					<ng-container *ngFor="let box of applicationBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

			<explorer-section [header]="util.text.singularOrPluralWithCount(eventBoxes.length, 'Event', 'Events')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('events')">
				<explorer-boxes>
					<ng-container *ngFor="let box of eventBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

			<explorer-section [header]="util.text.singularOrPluralWithCount(awardBoxes.length, 'Award', 'Awards')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('awards')">
				<explorer-boxes>
					<ng-container *ngFor="let box of awardBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

		</ng-container>
	</div>
</div>