import { Component, Input, OnChanges } from '@angular/core';
import { DbsPerson, Event, Venue } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { ShowEventDialogService } from '@me-shared-parts/ED-editors';
import { convertEventToMutable } from '@me-shared-parts/ED-editors/shared-editors/event/convert-event-to-mutable';
import { DatesProcessor } from '@me-shared-parts/ED-editors/shared-editors/event/event-dialog/dates-processor';
import { Icon, getIconContext } from '@me-shared-parts/UI-common';


@Component({
	selector: 'event-explorer-details',
	templateUrl: './details.part.html',
	styleUrls: ['./details.part.scss'],
})
export class EventExplorerDetailsPart implements OnChanges {
	@Input() event: Event;

	dates: DatesProcessor;
	venue: Venue;
	host: DbsPerson;
	timezone = this.util.date.getTimezone();
	toolIconMessage: string;
	editIcon = getIconContext(Icon.action_edit, undefined, 'never', 'Edit Event');

	constructor(
		public util: UtilityService,
		public ds: DataService,
		private showEventDialogService: ShowEventDialogService,
	) {
	}

	async ngOnChanges() {
		if (!this.event) throw new Error('Missing required attribute: event');

		this.venue = await this.ds.admin.venue.getOnePackage(this.event.venueId);
		this.dates = this.event ? new DatesProcessor(convertEventToMutable(this.event), this.util) : undefined;
		this.host = this.event.hostPersonId ? await this.ds.admin.person.getOne(this.event.hostPersonId) : undefined;
		const eventType = this.ds.domain.eventType.getOne(this.event.eventTypeId);

		if (eventType.toolNameLabelKey) {
			this.toolIconMessage = `The ${eventType.toolNameLabelKey} will be automatically enabled during this time.`;
		}
	}

	toolIconClick() {
		window.alert(this.toolIconMessage);
	}

	async openEventDialog() {
		await this.showEventDialogService.edit(this.event, false);
	}
}