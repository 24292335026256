import { Component, Input } from '@angular/core';


@Component({
	selector: 'message-box',
	templateUrl: './SHR-CMN_message-box.part.html',
	styleUrls: ['SHR-CMN_message-box.part.scss'],
})
export class MessageBoxPart {

	@Input() alertType: 'info' | 'warning' | 'error' | 'success' = 'info';
	@Input() closeable = false;

}
