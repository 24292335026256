import { SiteAreaService, SiteDashboardPageService } from '@SITE-area';
import { Component, Input, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-ACS-parts';
import { ADDROW_GRID_ACTION_KEY, GridAction, GridColumnType, GridSetup, PersonCols } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { DialogAction } from '@me-services/ui/dialog';
import { LayoutService } from '@me-services/ui/layout';
import { Icon } from '@me-shared-parts/UI-common';
import { SiteVolunteerRoleAddDialog, VolunteerRoleCallBackValue } from './add-dialog/volunteer-role-add.dialog';
import { DbsPersonTag } from '@me-interfaces';


interface VolunteerRoleRow {
	personId: number,
	name: string,
	status: 'Consider' | 'Approved' | 'Not Approved',
}


@Component({
	selector: 'volunteer-role-management-part',
	templateUrl: './volunteer-role-management.part.html',
})
export class VolunteerRoleManagementPart extends DestroyablePart implements OnInit {

	public readonly: boolean;
	@Input() role: 'reader' | 'judge' | 'mentor' | 'interviewer' | 'eventhelper' | 'coach';
	siteCode: string;
	rows: VolunteerRoleRow[];
	public gridSetup = this.setupGrid(false);

	constructor(
		private ds: DataService,
		public layout: LayoutService,
		public siteAreaService: SiteAreaService,
		public pageService: SiteDashboardPageService,

	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();

		super.subscribe([
			this.siteAreaService.accessAndId$,
			this.siteAreaService.data$,
		], async ([accessAndId, data]) => {

			this.readonly = accessAndId?.access?.root != 'Write';

			if (!data) return;

			this.siteCode = data.site.code.toLowerCase();

			const roleTags = await this.ds.admin.tag.getTagsForSiteByRole(data.site.siteId, this.role);
			const manualRoleTagIds: number[] = roleTags.reduce((a, tag) => {
				if (!tag.isAutoTag) a.push(tag.tagId);
				return a;
			}, []);

			this.gridSetup = this.setupGrid(!this.readonly);

			const peopleWithSiteTagsByTagId = await this.ds.admin.personTag.getArraysByForeignIds('tagId', roleTags.map(tag => tag.tagId));

			const peopleWithSiteTags: DbsPersonTag[] = [];

			for (const tagId in peopleWithSiteTagsByTagId) {
				peopleWithSiteTags.push(...peopleWithSiteTagsByTagId[tagId]);
			}

			const people = await this.ds.admin.person.getManyPackagesAsArray(peopleWithSiteTags.map(person => person.personId));

			const filteredPeople: {
				personId: number, name: string, tags: {
					readonly tagId: number,
					readonly tagPrefixId: number,
					readonly isAutoTag: boolean,
					readonly fullName: string,
					readonly siteId: number,
				}[]
			}[] = people.filter(person => person.tags.find(tag => manualRoleTagIds.includes(tag.tagId))).reduce((a, person) => {
				a.push({
					personId: person.personId,
					name: person.fullName,
					tags: person.tags.filter(tag => manualRoleTagIds.includes(tag.tagId)),
				});
				return a;
			}, []);

			this.rows = filteredPeople.map(person => ({
				personId: person.personId,
				name: person.name,
				status: this.getPersonTagStatus(person.tags),
			}));
		});
	}


	private getPersonTagStatus(tags: {
		readonly tagId: number,
		readonly tagPrefixId: number,
		readonly isAutoTag: boolean,
		readonly fullName: string,
		readonly siteId: number,
	}[]) {
		if (!!tags.find(tag => tag.fullName.endsWith('not-approved'))) return 'Not Approved';
		if (!!tags.find(tag => tag.fullName.endsWith('approved'))) return 'Approved';
		if (!!tags.find(tag => tag.fullName.endsWith('consider'))) return 'Consider';
		else '';
	}



	private setupGrid(canWrite: boolean): GridSetup<VolunteerRoleRow> {

		let role = 'Volunteer';

		if (this.role) {
			if (this.role == 'eventhelper') role = 'Event Helper';
			else role = this.role.charAt(0).toUpperCase() + this.role.substring(1);
		}

		console.log(`ROLE: ${role}`);


		const setup: GridSetup<VolunteerRoleRow> = {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: role,
			rowPluralName: `${role}s`,
			rowKey: "personId",
			stateKey: `site-volunteer-${this.role}-view-part`,
			canAdd: canWrite,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: "status", header: "Status", width: 95, type: GridColumnType.text },
				{ field: PersonCols.phone, header: 'Phone', width: 130, type: GridColumnType.phone, hidden: false },
				{ field: PersonCols.email, header: 'Email', width: 220, type: GridColumnType.text, hidden: false },
			],
			actions: [
				{ key: 'set-to-consider', icon: Icon.action_edit, label: 'Set to Consider' },
				{ key: 'set-to-approved', icon: Icon.action_edit, label: 'Set to Approved' },
				{ key: 'set-to-not-approved', icon: Icon.action_edit, label: 'Set to Not Approved' },
				{ key: 'remove-role', icon: Icon.action_delete, label: `Remove ${role}`, enabled: false },
			],
			actionEnabler: this.gridActionEnabler.bind(this)
		};


		return setup;
	}

	gridActionEnabler(action: GridAction, rows: VolunteerRoleRow[]) {

		if (this.readonly) {
			return false;
		}
		else {
			const row = rows[0];
			if (action.key == 'set-to-consider' && row.status == 'Consider') return false;
			if (action.key == 'set-to-approved' && row.status == 'Approved') return false;
			if (action.key == 'set-to-not-approved' && row.status == 'Not Approved') return false;
			return true;
		}
	}


	async gridActionHandler(action: { actionKey: string, rows: VolunteerRoleRow[] }) {
		if (this.readonly) return;


		const row = action.rows[0];

		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {

			const conceptAndId = await this.ds.searcher.selectPerson();

			if (conceptAndId) {
				const person = await this.ds.admin.person.getOne(conceptAndId.id);

				const roleFound = this.rows.find(r => r.personId == person.personId);
				if (roleFound) {
					const message = `${person._name} is already ${this.role} with status '${roleFound.status}' for this site.`;
					await this.ds.dialogService.showMessage(message, 300, 140);
				}
				else {
					const action: DialogAction<VolunteerRoleCallBackValue> = await this.ds.dialogService.showCustom(SiteVolunteerRoleAddDialog, {
						data: {
							personId: person.personId,
							role: this.role,
						}
					}, 450, 350);

					const id = action?.id;
					if (id == 'add') {
						if (action.callbackResult) await this.saveRole(action.callbackResult);
					}
				}

			}

		}

		else if (action.actionKey == 'set-to-consider') {
			if (row.status == 'Consider') return;
			await this.siteAreaService.volunteer.actions.setSiteRoleTag({ personId: row.personId, tagFullName: `${this.siteCode}:${this.role}-consider` });

		}
		else if (action.actionKey == 'set-to-approved') {
			if (row.status == 'Approved') return;
			await this.siteAreaService.volunteer.actions.setSiteRoleTag({ personId: row.personId, tagFullName: `${this.siteCode}:${this.role}-approved` });

		}
		else if (action.actionKey == 'set-to-not-approved') {
			if (row.status == 'Not Approved') return;

			await this.siteAreaService.volunteer.actions.setSiteRoleTag({ personId: row.personId, tagFullName: `${this.siteCode}:${this.role}-not-approved` });

		}
		else if (action.actionKey == 'remove-role') {
			if (this.readonly) return;

			const yes = await this.ds.dialogService.confirm(`Are you sure you want to remove ${row.name} as ${this.role} ?`, 400, 250, 'Remove', 'Cancel');
			if (yes) {
				let tag = `${this.siteCode}:${this.role}-`;
				if (row.status == 'Approved') tag = `${tag}approved`;
				else if (row.status == 'Not Approved') tag = `${tag}not-approved`;
				else if (row.status == 'Consider') tag = `${tag}consider`;
				await this.siteAreaService.volunteer.actions.removeSiteRoleTag({ personId: row.personId, tagFullName: tag });
			}

		}
	}

	private async saveRole(callback: VolunteerRoleCallBackValue) {
		if (this.readonly || !callback) return;

		const personId = callback.personId;
		let tag = `${this.siteCode}:${this.role}-`;
		if (callback.status == 'Approved') tag = `${tag}approved`;
		else if (callback.status == 'Not Approved') tag = `${tag}not-approved`;
		else if (callback.status == 'Consider') tag = `${tag}consider`;


		await this.siteAreaService.volunteer.actions.setSiteRoleTag({ personId, tagFullName: tag });
	}


}