<div class='explorer-part acc-team-explorer-part'>

	<div class="icon-bar">
		<me-icon-bar #iconBar [icons]='views' [orientation]="'Vertical'" (selectionsChange)='setViews($event)'>
		</me-icon-bar>
	</div>

	<div class="content">
		<ng-container *ngIf='selectedViews'>
			<div class="explorer-header">
				<me-icon [icon]="icons.accTeam"></me-icon>
			</div>
			<h4 class="explorer-sub-header">Accelerator Team</h4>

			<div *ngIf='accTeam.droppedOutWeek' class="explorer-small-header">Dropped Out Week:<span
					class="explorer-highlight-section">{{accTeam.droppedOutWeek}}</span></div>

			<section>
				<explorer-buttons [buttons]='buttons'>
					<button *ngIf="accTeam.hasRedFlag" class="text-btn text-btn-not-inline border-red"
						(click)="click('red-flag')">
						<me-icon [icon]="icons.redFlag"></me-icon>
					</button>
					<button *ngIf="accTeam.awardedValue > 0" class="text-btn text-btn-not-inline"
						(click)="click('awards')">
						<me-icon [icon]="icons.award"></me-icon>
					</button>
				</explorer-buttons>
			</section>

			<explorer-section header="Acc Team Details" (tabClose)="tabClose($event)"
				[style.display]="getDisplay('details')">
				<acc-team-explorer-details [accTeam]="accTeam"></acc-team-explorer-details>
			</explorer-section>

			<explorer-section
				[header]="util.text.singularOrPluralWithCount(memberBoxes.length, 'Team Member', 'Team Members')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('members')">
				<explorer-boxes>
					<ng-container *ngFor="let box of memberBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

			<explorer-section
				[header]="util.text.singularOrPluralWithCount(relatedNoteBoxes.length, 'Related Note', 'Related Notes')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('related notes')">
				<explorer-note-boxes>
					<ng-container *ngFor="let box of relatedNoteBoxes">
						<explorer-note-box [box]="box"> </explorer-note-box>
					</ng-container>
				</explorer-note-boxes>
			</explorer-section>

			<explorer-section [header]="util.text.singularOrPluralWithCount(awardBoxes.length, 'Award', 'Awards')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('awards')">
				<explorer-boxes>
					<ng-container *ngFor="let box of awardBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

			<explorer-section header='Accelerator' (tabClose)="tabClose($event)"
				[style.display]="getDisplay('accelerator')">
				<explorer-boxes>
					<ng-container *ngFor="let box of accBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

		</ng-container>
	</div>
</div>