import { SiteAreaData, SiteAreaService, SiteDashboardPageService } from '@SITE-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-ACS-parts';
import { ADDROW_GRID_ACTION_KEY, DBLCLICK_GRID_ACTION_KEY, GridAction, GridColumnType, GridSetup, GridValueStyle, PersonCols } from '@me-grid';
import { AppAreaIdentifier, AppAreaIdentifierAndAccess, SiteAreaAccess, SpecialistStatus } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogAction } from '@me-services/ui/dialog';
import { LayoutService } from '@me-services/ui/layout';
import { Icon } from '@me-shared-parts/UI-common';
import { SiteSpecialistEditDialog, SpecialistCallBackValue } from './edit-dialog/specialist-edit.dialog';
import { Observable, combineLatest, mergeMap, of } from 'rxjs';


interface SpecialistRow {
	specialistId: number,
	personId: number,
	topicId: number,
	topicName: string,
	languageId: number,
	language: string,
	status: SpecialistStatus,
	note: string,
	associationCounts: number,
	personName: string,
}


@Component({
	selector: 'site-volunteer-specialist-view-part',
	templateUrl: './specialist.part.html',
})
export class SpecialistPart extends DestroyablePart implements OnInit {

	public readonly: boolean;
	rows$: Observable<SpecialistRow[]> = of([]);
	public gridSetup = this.setupGrid(false);

	constructor(
		private ds: DataService,
		public layout: LayoutService,
		public siteAreaService: SiteAreaService,
		public pageService: SiteDashboardPageService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();


		this.rows$ = combineLatest([
			this.ds.admin.singletonsAsOfUTC$,
			this.siteAreaService.accessAndId$,
			this.siteAreaService.data$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1], data[2])));
	}

	async buildRows(
		singletonsAsOfUTC: number,
		accessAndId: AppAreaIdentifierAndAccess<AppAreaIdentifier<number>, SiteAreaAccess>,
		data: SiteAreaData,
	) {

		this.readonly = accessAndId?.access?.root != 'Write';

		if (!data) return;

		this.gridSetup = this.setupGrid(!this.readonly);

		const specialists = (await this.ds.admin.specialist.getBySiteIds([data.site.siteId]))[data.site.siteId];

		const rows: SpecialistRow[] = specialists.map(specialist => ({
			language: specialist.language.name,
			languageId: specialist.languageId,
			note: specialist.note,
			personId: specialist.personId,
			specialistId: specialist.specialistId,
			status: specialist.status,
			topicId: specialist.topicId,
			topicName: specialist.topic.shortNameLabel,
			associationCounts: specialist.associationCounts,
			personName: specialist.person.fullName,
		}));

		return rows;
	}


	private setupGrid(canWrite: boolean): GridSetup<SpecialistRow> {

		const valueStyle = this.valueStyle.bind(this);

		const setup: GridSetup<SpecialistRow> = {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Specialist",
			rowPluralName: "Specialists",
			rowKey: "specialistId",
			stateKey: "site-specialist-part",
			canAdd: canWrite,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: "specialistId", header: "specialistId", width: 80, type: GridColumnType.number, hidden: true },
				{ field: "topicName", header: "Topic", width: 180, type: GridColumnType.text },
				{ field: "language", header: "Language", width: 95, type: GridColumnType.text },
				{ field: "status", header: "Status", width: 95, type: GridColumnType.text },
				{ field: "note", header: "Remark", width: 80, type: GridColumnType.text, valueStyle },
				{ field: "associationCounts", header: "Associations", width: 80, type: GridColumnType.number },
				{ field: PersonCols.phone, header: 'Phone', width: 130, type: GridColumnType.phone, hidden: false },
			],
			actions: [
				{ key: 'edit-specialist', icon: Icon.action_edit, label: 'Edit Specialist', enabled: false },
				{ key: 'remove-specialist', icon: Icon.action_delete, label: 'Remove Specialist', enabled: false },
			],
			actionEnabler: this.gridActionEnabler.bind(this),

		};

		return setup;
	}


	gridActionEnabler(action: GridAction, rows: SpecialistRow[]) {
		if (rows.length == 0) return false;

		if (action.key == 'edit-specialist' || action.key == 'remove-specialist') {
			if (this.readonly) return false;
		}
		return true;
	}


	private valueStyle(row: SpecialistRow, field: string): GridValueStyle {
		const value = row[field];

		if (field == 'note') {
			if (value) return { icon: 'fas fa-file-alt', textStyle: 'Hidden' };
			else return { icon: 'fat fa-file', textStyle: 'Hidden' };
		}

		return undefined;
	}


	async gridActionHandler(action: { actionKey: string, rows: SpecialistRow[] }) {
		if (this.readonly) return;


		const row = action.rows[0];

		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {

			const conceptAndId = await this.ds.searcher.selectPerson();

			if (conceptAndId) {
				const person = await this.ds.admin.person.getOne(conceptAndId.id);

				const action: DialogAction<SpecialistCallBackValue> = await this.ds.dialogService.showCustom(SiteSpecialistEditDialog, {
					data: {
						isAdd: true,
						personId: person.personId,
						topicId: undefined,
						languageId: undefined,
						status: undefined,
						note: undefined
					}

				}, 450, 350);

				const id = action?.id;
				if (id == 'add') {
					if (action.callbackResult) await this.saveSpecialist(action.callbackResult);
				}

			}

		}

		else if (action.actionKey == 'edit-specialist' || action.actionKey == DBLCLICK_GRID_ACTION_KEY) {
			const action: DialogAction<SpecialistCallBackValue> = await this.ds.dialogService.showCustom(SiteSpecialistEditDialog, {
				data: {
					isAdd: false,
					personId: row.personId,
					topicId: row.topicId,
					languageId: row.languageId,
					status: row.status,
					note: row.note,
				}

			}, 450, 350);

			const id = action?.id;
			if (id == 'update') {
				if (action.callbackResult) await this.saveSpecialist(action.callbackResult);
			}
		}
		else if (action.actionKey == 'remove-specialist') {
			if (this.readonly) return;

			if (row.associationCounts) {
				await this.ds.dialogService.showMessage(`${row.personName} is associated with 1 or more accelerators and may not be removed.`, 400, 150, 'Okay');
			}
			else {
				const yes = await this.ds.dialogService.confirm(`Are you sure you want to remove ${row.personName} as an ${row.language.toLowerCase()} specialist for ${row.topicName} ?`, 400, 250, 'Remove', 'Cancel');
				if (yes) await this.siteAreaService.volunteer.actions.removeSpecialist({ specialistId: row.specialistId });
			}
		}
	}

	private async saveSpecialist(callback: SpecialistCallBackValue) {
		if (this.readonly) return;

		await this.siteAreaService.volunteer.actions.setSpecialist(callback);
	}


}