import { AccAreaData } from "@ACC-area";
import { DAYLIGHT_SAVINGS_TIMEZONES } from "@me-interfaces";
import { DialogService } from "@me-services/ui/dialog";
import { shiftMeetings } from "./shift-meetings";


/**
 * Check all the accelerator timezones and make sure they are compatible with each other.
 * Warn the user if there are any teams or mentors without schedule data.
 * 
 * @param data - The data from the accelerator area service
 * @returns If successful, a copy of the area data is returned with adjusted schedules where they all match the same time zone.
 */
export async function ensureCompatibleTimeZones(dialogService: DialogService, data: AccAreaData): Promise<AccAreaData | undefined> {

	//
	// Make a copy of the data
	//
	data = JSON.parse(JSON.stringify(data));

	const mm = data.mentorMatching.v2Schedules;
	const blockoutTimeZoneId = mm.blockout.timeZoneId;
	const usingStandardFourTimeZones = DAYLIGHT_SAVINGS_TIMEZONES.includes(blockoutTimeZoneId);


	//
	// Check if all teams have schedules and if the time zones are compatible with the blockout time zone.
	//
	let missingTeamSchedules = 0;
	let incompatibleTimeZones = 0;

	for (const team of data.team.teams) {

		const availability = mm.teamAvailability[team.accTeamId];

		if (availability) {
			if (usingStandardFourTimeZones && !DAYLIGHT_SAVINGS_TIMEZONES.includes(availability.timeZoneId)) incompatibleTimeZones++;
			else if (!usingStandardFourTimeZones && DAYLIGHT_SAVINGS_TIMEZONES.includes(availability.timeZoneId)) incompatibleTimeZones++;
		}
		else missingTeamSchedules++;
	}


	if (incompatibleTimeZones > 0) {
		await dialogService.showMessage(`ERROR: The blockout time zone is set to ${mm.blockout.timeZoneName} but there are ${incompatibleTimeZones} teams with incompatible time zones.`);
		return undefined;
	}


	if (missingTeamSchedules > 0) {
		const yes = await dialogService.confirm(`ERROR: There are ${missingTeamSchedules} teams without schedules.  They will be set to 100% available.
		
Would you like to continue?`);
		if (!yes) return undefined;
	}


	//
	// Check if all mentors have schedules and if the time zones are compatible with the blockout time zone.
	//
	let missingMentorSchedules = 0;
	incompatibleTimeZones = 0;

	for (const mentor of data.mentorMatching.matchableMentors) {

		const availability = mm.mentorAvailability[mentor.personId];

		if (availability) {
			if (usingStandardFourTimeZones && !DAYLIGHT_SAVINGS_TIMEZONES.includes(availability.timeZoneId)) incompatibleTimeZones++;
			else if (!usingStandardFourTimeZones && DAYLIGHT_SAVINGS_TIMEZONES.includes(availability.timeZoneId)) incompatibleTimeZones++;
		}
		else missingMentorSchedules++;
	}

	if (incompatibleTimeZones > 0) {
		await dialogService.showMessage(`ERROR: The blockout time zone is set to ${mm.blockout.timeZoneName} but there are ${incompatibleTimeZones} mentors with incompatible time zones.`);
		return undefined;
	}


	if (missingMentorSchedules > 0) {
		const yes = await dialogService.confirm(`ERROR: There are ${missingMentorSchedules} mentors without schedules.  They will be set to 100% available.
		
Would you like to continue?`);
		if (!yes) return undefined;
	}


	//
	// Set any missing team schedules to the blockout schedule
	//
	for (const team of data.team.teams) {

		if (!mm.teamAvailability[team.accTeamId]) mm.teamAvailability[team.accTeamId] = { ...mm.blockout };
	}


	//
	// Set any missing mentor schedules to the blockout schedule
	//
	for (const mentor of data.mentorMatching.matchableMentors) {

		if (!mm.mentorAvailability[mentor.personId]) mm.mentorAvailability[mentor.personId] = { ...mm.blockout };
	}


	//
	// If the standard four time zones are being used, then any team and mentor time zones
	// different than the blockout time zone need to be shifted to be the same as the blockout.
	//
	if (usingStandardFourTimeZones) {

		const blockoutTimeZoneIndex = DAYLIGHT_SAVINGS_TIMEZONES.indexOf(blockoutTimeZoneId);
		let shiftedHours = 0;

		//
		// Shift team schedules to the blockout schedule
		//
		for (const team of data.team.teams) {

			const available = mm.teamAvailability[team.accTeamId];
			const availableTimeZoneIndex = DAYLIGHT_SAVINGS_TIMEZONES.indexOf(available.timeZoneId);	// Already checked above
			shiftedHours = blockoutTimeZoneIndex - availableTimeZoneIndex;

			mm.teamAvailability[team.accTeamId] = {

				meetingsId: available.meetingsId,
				timeZoneId: blockoutTimeZoneId,
				timeZoneName: mm.blockout.timeZoneName,
				mon: shiftMeetings(available.mon, shiftedHours),
				tue: shiftMeetings(available.tue, shiftedHours),
				wed: shiftMeetings(available.wed, shiftedHours),
				thu: shiftMeetings(available.thu, shiftedHours),
				fri: shiftMeetings(available.fri, shiftedHours),
				meetings: 0,	// The count of meetings may change after shifting. Not recalculating because unneeded for the matching algorithm.

			};
		}


		//
		// Shift mentor schedules to the blockout schedule
		//
		for (const mentor of data.mentorMatching.matchableMentors) {

			const available = mm.mentorAvailability[mentor.personId];
			const availableTimeZoneIndex = DAYLIGHT_SAVINGS_TIMEZONES.indexOf(available.timeZoneId);	// Already checked above
			shiftedHours = blockoutTimeZoneIndex - availableTimeZoneIndex;

			mm.mentorAvailability[mentor.personId] = {

				meetingsId: available.meetingsId,
				timeZoneId: blockoutTimeZoneId,
				timeZoneName: mm.blockout.timeZoneName,
				mon: shiftMeetings(available.mon, shiftedHours),
				tue: shiftMeetings(available.tue, shiftedHours),
				wed: shiftMeetings(available.wed, shiftedHours),
				thu: shiftMeetings(available.thu, shiftedHours),
				fri: shiftMeetings(available.fri, shiftedHours),
				meetings: 0,	// The count of meetings may change after shifting. Not recalculating because unneeded for the matching algorithm.

			};
		}
	}


	return data;
}