<me-dialog-frame header='Filters' [actions]='actions' [dialogRef]='dialogRef' icon='fa-filter' alertType='editor'>

	<!-- <div *ngFor="let exp of filterExpressions">{{ exp.field }}</div> -->

	<kendo-filter [value]="originalFilters" (valueChange)="onFiltersChange($event)">

		<kendo-filter-field *ngFor="let exp of filterExpressions" [field]="exp.field" [title]="exp.title"
			[editor]="exp.editor"></kendo-filter-field>

	</kendo-filter>

</me-dialog-frame>