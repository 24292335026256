import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { DroplistItem, getIconClass, getIconContext, Icon } from '@me-shared-parts/UI-common';

interface PendingValue {
	value: string,
	changed: boolean,
	pending: string,
	valid: boolean,
}

export type AccTeamMemberRemoveMentorDialogCallbackValue = { accTeamMemberId: number };
export type AccTeamMemberEditMentorDialogCallbackValue = { accTeamMemberId: number, role: 'M' | 'C' | 'X' };

@Component({
	selector: 'acc-team-members-edit-mentor-dialog',
	templateUrl: './acc-team-members-edit-mentor.dialog.html',
	styleUrls: ['./acc-team-members-edit-mentor.dialog.scss']
})
export class AccTeamMembersEditMentorDialog extends DestroyablePart implements OnInit {

	//
	// Configuration for the input fields 
	//
	values: { role: PendingValue } = {
		role: {
			value: null,
			changed: false,
			pending: undefined,
			valid: true,
		},
	};

	readonly: boolean;

	items: DroplistItem[] = [
		{ uniqueId: 1, iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Mentor'), customSort: '1', },
		{ uniqueId: 2, iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Coach'), customSort: '2' },
		{ uniqueId: 3, iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Quit'), customSort: '3' },
	];
	selectedItem: DroplistItem;

	//
	// The action buttons
	//

	updateAction: DialogAction<AccTeamMemberEditMentorDialogCallbackValue> = {
		id: 'update',
		enabled: false,
		visible: true,
		label: 'Update',
		linkType: 'callback',
		callback: async () => ({
			accTeamMemberId: this.dialog.data.accTeamMemberId,
			role: this.getRole()
		}),
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: ':Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	removeAction: DialogAction<AccTeamMemberRemoveMentorDialogCallbackValue> = {
		id: 'remove',
		enabled: true,
		visible: true,
		label: ':Remove',
		linkType: 'callback',
		callback: async () => ({
			accTeamMemberId: this.dialog.data.accTeamMemberId
		}),
		willCloseDialog: true,
	};

	actions: DialogAction<AccTeamMemberRemoveMentorDialogCallbackValue | void | AccTeamMemberEditMentorDialogCallbackValue>[] = [this.updateAction, this.cancelAction, this.removeAction];
	icon = getIconClass(Icon.action_edit);
	memberName: string;
	role: string;


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ readonly: boolean, accTeamMemberId: number, memberName: string, role: string }>,
		public dialogRef: MatDialogRef<AccTeamMembersEditMentorDialog>,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		this.readonly = this.dialog.data.readonly;
		this.memberName = this.dialog.data.memberName;
		this.role = this.dialog.data.role;

		if (this.role === 'Mentor') this.selectedItem = this.items[0];
		else if (this.role === 'Coach') this.selectedItem = this.items[1];
		else if (this.role === 'Quit') this.selectedItem = this.items[2];

		//
		// Initialize with the values passed in 
		//
		this.values.role.value = this.dialog.data.role;

		//
		// Set the pending values, which will also check their validity
		//
		this.setPendingValue(this.values.role, this.values.role.value);

		this.checkIfCanSave();

	}


	/**
	 * Record the pending value and check if it is valid (within the length range)
	 */
	setPendingValue(values: PendingValue, value: string) {
		values.pending = value;
		values.valid = !!value;

		this.checkIfCanSave();
	}


	/**
	 * This function is called as the user selects start or end date
	 */
	onValueChange(field: 'role', data: DroplistItem) {
		const value = data ?? undefined;
		const values = this.values[field];
		values.changed = true;
		values.value = data.iconContext.text;
		this.setPendingValue(values, values.value);
		this.checkIfCanSave();
	}

	getRole() {
		if (this.values.role.value === 'Mentor') return 'M';
		else if (this.values.role.value === 'Coach') return 'C';
		else if (this.values.role.value === 'Quit') return 'X';
	}


	/**
	 * Look at each value and determine if the save/update button should be enabled
	 */
	checkIfCanSave() {

		const allValid = this.values.role.valid;
		// Determine if any field has changed or has a pending change
		const somethingChanged =
			this.values.role.changed ||
			this.values.role.value !== this.values.role.pending;

		this.updateAction.enabled = allValid && somethingChanged;

	}

}