<div class='search-header-part'>
	<kendo-multiselect [placeholder]="'Filters'" [data]="filters" (valueChange)="setSearchFilters($event)"
		[value]="selectedFilters$ | async" [checkboxes]="true" [clearButton]="false" [popupSettings]="filterPopup"
		[kendoMultiSelectSummaryTag]="0" [filterable]="false" [autoClose]="false" textField="text" valueField="value"
		[disabled]="ds.searcher.searchService.busy$ | async">

		<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
			{{ getFiltersSummaryTag(dataItems.length) }}
		</ng-template>

	</kendo-multiselect>

	<kendo-textbox #textField type="text" placeholder='Search Text' [value]='ds.searcher.searchService.text$ | async'
		(valueChange)="searchFieldChange($event)" (keyup)='searchFieldKeyup($event)'
		[disabled]="ds.searcher.searchService.busy$ | async" [clearButton]="true">
	</kendo-textbox>

	<button class="btn search-btn btn-green" [disabled]="(ds.searcher.searchService.canSearch$ | async) === false"
		(click)="ds.searcher.searchService.search()">

		<ng-container *ngIf="(ds.searcher.searchService.loading$ | async) === undefined">Search</ng-container>

		<ng-container *ngIf="(ds.searcher.searchService.loading$ | async) !== undefined">
			<i class="fas fa-spinner-third fa-spin"></i>
			{{ds.searcher.searchService.loading$ | async}}
		</ng-container>

	</button>

	<button class="btn" (click)="help()">
		<i class="fas fa-question"></i>
	</button>

</div>