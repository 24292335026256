<div class="follow-up-date-dialog">

	<me-dialog-frame header='Follow Up Date' [actions]='actions' [dialogRef]='dialogRef' icon='fa-calendar-edit'
		alertType='editor'>

		<div class="me-field">
			<div class="follow-up-date-dialog-content">
				<p>The default filter on the applications list will display any application with a follow up date within
					seven calendar days of the current date, regardless of it's status. </p>
				<kendo-datepicker #dateField [value]='followUpDate.value' [min]='followUpDate.min'
					[max]='followUpDate.max' (valueChange)="valueChange($event)" [class.has-error]='!!errorText' [disabled]='readonly'>
				</kendo-datepicker>
				<div fxLayout="row" *ngIf='errorText' class="k-tooltip-validation">
					{{errorText}}
				</div>
			</div>
		</div>

	</me-dialog-frame>
</div>