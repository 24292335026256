import { DbConceptName } from "@me-interfaces";
import { getIconContext } from "./get-icon-context";
import { Icon } from "./icon";
import { IconContext } from "./icon-context";
import { IconHideTextAt } from "./icon-hide-text-at";
import { IconStyle } from "./icon-style";


/**
 * Return the Icon value for a given DbConceptName
 */
export function getIconForConceptName(conceptName: DbConceptName): Icon {

	if (conceptName == DbConceptName.Accelerator) return Icon.concept_accelerator;
	if (conceptName == DbConceptName.Application) return Icon.concept_application;
	if (conceptName == DbConceptName.AccTeam) return Icon.concept_accTeam;
	if (conceptName == DbConceptName.Company) return Icon.concept_company;
	if (conceptName == DbConceptName.Event) return Icon.concept_event;
	if (conceptName == DbConceptName.PicTeam) return Icon.concept_picTeam;
	if (conceptName == DbConceptName.PitchContest) return Icon.concept_pitchContest;
	if (conceptName == DbConceptName.Person) return Icon.concept_person;
	if (conceptName == DbConceptName.Program) return Icon.concept_program;
	if (conceptName == DbConceptName.Region) return Icon.concept_region;
	if (conceptName == DbConceptName.Site) return Icon.concept_site;
	if (conceptName == DbConceptName.Venue) return Icon.concept_venue;

	return Icon.status_missing;
}


export function getIconContextForConceptName(conceptName: DbConceptName, iconStyle?: IconStyle, hideText?: IconHideTextAt, text?: string, hasRedFlag?: boolean): IconContext {
	return getIconContext(getIconForConceptName(conceptName), iconStyle, hideText, text, hasRedFlag);
}