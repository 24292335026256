import { AccApplicationContext, AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId, ApplicationStatusId, DbaAccInterview, Event, EventTypeId } from '@me-interfaces';
import { Icon, IconContext, getIconClass } from '@me-shared-parts/UI-common';
import { AccPreAcceleratorStageId } from '../../../acc-application-status-helpers';


export interface StatusIcon {
	icon: IconContext;
	link?: string;
}


@Component({
	selector: 'acc-interview-checks-part',
	templateUrl: './acc-interview-checks.part.html',
})
export class AccInterviewChecksPart extends DestroyablePart implements OnInit {

	accStageId: AccPreAcceleratorStageId = AccStageId.Interviewing;

	interviewersStatusIcon: StatusIcon;
	applicationsStatusIcon: StatusIcon;
	eventsStatusIcon: StatusIcon;


	constructor(public accAreaService: AccAreaService) {
		super();
	}


	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe([this.accAreaService.accelerator.acc$, this.accAreaService.applications.applications$, this.accAreaService.interviewing.interviewers$, this.accAreaService.accelerator.events$, this.accAreaService.interviewing.assessments$], async ([acc, apps, interviewers, events, assessments]) => {
			this.setApplicationsStatusIcon(apps?.filter(a => a.application.applicationStatusId >= ApplicationStatusId.ReadAccepted), assessments?.map(a => a.interview));
			this.setInterviewersStatusIcon(interviewers?.length, apps?.filter(a => a.application.applicationStatusId >= ApplicationStatusId.ReadAccepted).length);
			this.setEventsStatusIcon(events?.filter(e => e.eventTypeId == EventTypeId.AccInterviewing));
		});
	}

	setApplicationsStatusIcon(apps: readonly AccApplicationContext[], interviews: DbaAccInterview[]) {
		const minInterviews = apps?.reduce((min: number, app) => {
			const interviewCount = interviews?.filter(i => !!i.rating && i.applicationId == app.application.applicationId).length;
			if (min == undefined) min = interviewCount;
			else if (interviewCount < min) min = interviewCount;
			return min;
		}, undefined);

		const icon: Icon = apps?.length > 0 && minInterviews >= 3 ? Icon.status_success : Icon.status_x;
		const link = '';
		const text = apps?.length == 0 ? '0 Applications' : `Each Application has at least ${minInterviews} Interview${minInterviews != 1 ? 's' : ''}`;

		this.applicationsStatusIcon = {
			icon: {
				icon,
				iconClass: getIconClass(icon),
				text,
			},
			link: apps?.length > 0 ? undefined : link,
		};
	}


	setInterviewersStatusIcon(totalInterviewers: number, totalApps: number) {
		const appsPerInterviewer: number = totalInterviewers > 0 ? (totalApps / totalInterviewers) : totalApps;
		const icon: Icon = totalInterviewers > 0 && appsPerInterviewer <= 10 ? Icon.status_success : Icon.status_x;
		const link = '';
		const text = totalInterviewers == 0 ? `0 Interviewers` : `${appsPerInterviewer?.toFixed(2)} Application${appsPerInterviewer != 1 ? 's' : ''} per Interviewer`;

		this.interviewersStatusIcon = {
			icon: {
				icon,
				iconClass: getIconClass(icon),
				text,
			},
			link: totalInterviewers > 0 ? undefined : link,
		};
	}


	setEventsStatusIcon(events: Event[]) {
		const now = Date.now() / 1000;
		const openToolEvents = events?.filter(e => now > e?.toolStartUTC && now < e?.toolEndUTC) || [];
		const icon: Icon = openToolEvents?.length > 0 ? Icon.status_success : Icon.status_x;
		const text = events?.length == 0 ? 'No Interview Events' : `${events?.length} Interview Event${events?.length != 1 ? 's' : ''} (${openToolEvents.length} Interview Tool${openToolEvents.length != 1 ? 's' : ''} open)`;
		const link = '';

		this.eventsStatusIcon = {
			icon: {
				icon,
				iconClass: getIconClass(icon),
				text,
			},
			link: events?.length > 0 ? undefined : link,
		};
	}

}
