<div class='get-help-part' style="padding-top: .5em; padding-bottom: .5em;">
	<div>
		<me-h2>App How-To's: Videos and Materials</me-h2>
		For walkthroughs, explainers, and step-by-step instuctions of processes and proceedures across the app, <b><a
				href="https://docs.google.com/spreadsheets/d/10vbZsiIZ6z1zy-7_uOD7HGHc-TUpo-O_0JsHU8febDs/edit?usp=sharing"
				target="training-videos">click here</a></b>.
	</div>
	<div style="padding-top: .5em;">
		<i>Accelerator and Pitch Contest training videos can be found in the Toolbar for each program.</i>
	</div>

	<div style="padding-top: 1.25em;">
		<me-h2>Ask for Support</me-h2>
		Introducing the
		<b><a href='https://chat.google.com/room/AAAAlOvAAAc' target='eforall-app-help'>Tech Team Support</a></b>
		space. This is where you can share your app ideas, ask questions, and get help.

		We recommend that you pin the space in the Google Mail app for easy access.
	</div>
	<div style="padding-top: .5em;">
		<i>
			If the above link doesn't work, sign out of all Google accounts and sign back in with your eforall.org
			account. It should then work.
		</i>
	</div>

	<div style="padding-top: 1.25em;">
		<me-h2>Troubleshooting Help: Screen Recording</me-h2>
		Sometimes it's tricky to explain a problem you're having. The best way to show it is to install
		<b><a href="https://www.screencastify.com/" target="get-screencastify">Screencastify</a></b> and use that to
		record your interaction with
		the app. Then, add a new topic in the support space, and paste in the video link.
	</div>
</div>