import { Component, Input, OnInit } from '@angular/core';
import { DbsPerson } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { ShowPersonExtractDialogService } from '../person-extract-dialog/SHR-PN_show-person-extract-dialog.service';

@Component({
	selector: 'person-extract-link',
	templateUrl: './SHR-PN_person-extract-link.part.html',
	styleUrls: ['./SHR-PN_person-extract-link.part.scss']
})
export class PersonExtractLinkPart implements OnInit {

	@Input() person?: DbsPerson;
	@Input() personId?: number;

	constructor(
		private func: FuncService,
		public showPersonExtractDialogService: ShowPersonExtractDialogService,) {
	}

	async ngOnInit() {
		if (!this.person && !this.personId) throw new Error(`Missing person and personId attribute`);
		if (!this.person) this.person = await this.func.person.get(this.personId);
	}

	async open() {
		await this.showPersonExtractDialogService.show(this.person);
	}
}