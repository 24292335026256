import { Explorable } from "@me-services/core/data/explorable";
import { PendingSearchMatch, SearchFieldMatch, SearchMatchLevel, SearchRegExps, TextToMatch } from "../interfaces";

/**
 * Determines if the text is an exact match. If so, it adds a PendingSearchMatch
 * to the matches array and returns true. Otherwise retuns false.
 */
export function checkTexts(
	explorable: Explorable,
	texts: TextToMatch[],
	re: SearchRegExps,
): PendingSearchMatch {

	//
	// The level will ratchet up to the highest of the matched fields
	//
	let highestLevel = SearchMatchLevel.None;

	const fieldMatches: SearchFieldMatch[] = [];

	for (const t of texts) {

		if (t.text == undefined || t.text.length < 3) continue;

		//
		// Initialize with no match
		//
		const fieldMatch: SearchFieldMatch = {
			field: t.field,
			level: SearchMatchLevel.None,
		};

		//
		// First check if the text is contained
		//
		if (re.contains.test(t.text)) {
			fieldMatch.level = Math.max(fieldMatch.level, SearchMatchLevel.Low);
			highestLevel = Math.max(highestLevel, fieldMatch.level);

			//
			// If it is contained then it might start
			//
			if (re.startsWith.test(t.text)) {
				fieldMatch.level = Math.max(fieldMatch.level, SearchMatchLevel.Medium);
				highestLevel = Math.max(highestLevel, fieldMatch.level);

				//
				// If it starts with then it might be a full match
				//
				if (re.full.test(t.text)) {
					fieldMatch.level = Math.max(fieldMatch.level, t.fullMatchIsExact ? SearchMatchLevel.Exact : SearchMatchLevel.High);
					highestLevel = Math.max(highestLevel, fieldMatch.level);
				}
			}
		}


		if (fieldMatch.level == SearchMatchLevel.None) continue;


		//
		// Matches on the display name are bumped up one level because it looks better in the results
		//
		if (t.field == 'Name' && fieldMatch.level < SearchMatchLevel.Exact) {
			fieldMatch.level++;
			highestLevel = Math.max(highestLevel, fieldMatch.level);
		}


		fieldMatches.push(fieldMatch);

	}


	return { explorable, level: highestLevel, fieldMatches };

}