import { DialogAction } from '@me-services/ui/dialog';

export const saveAction: DialogAction<string> = {
		id: 'save',
		enabled: true,
		visible: true,
		label: ':Save',
		linkType: 'callback',
		willCloseDialog: true,
	};

export const cancelAction: DialogAction = {
    id: 'close',
    enabled: true,
    visible: true,
    label: ':Cancel',
    linkType: "no-action",
    willCloseDialog: true,
};