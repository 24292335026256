<div class='entity-editor-dialog'>
	<me-dialog-frame *ngIf='profile' header='{{ profile.person ? profile.person._name : profile.company._name }}'
		[actions]='actions' [dialogRef]='dialogRef' [icon]='profile.person ? "fa-user" : "fa-building"'
		alertType='editor'>
		<person-editors *ngIf='profile && profile.person' [person]='profile.person' [editorKey]='selectedEditor'
			[selectorKey]='initialSelectorKey' inDialog='true' class='kendo-tabstrip.dialog'>
		</person-editors>
		<company-editors *ngIf='profile && profile.company' [profile]='profile.company' [editorKey]='selectedEditor'
			[selectorKey]='initialSelectorKey' inDialog='true' class='kendo-tabstrip.dialog'>
		</company-editors>
	</me-dialog-frame>
</div>