import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppAreaApplyResponse, DbcUser, DbsEvent, EventAccess, EventAttendedBy, EventContext, MutableDbsEvent, TeamsForAward } from '@me-interfaces';
import { StaffMemberService } from '@me-services/old-services-and-wrappers/staff-member';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogContext, DialogService } from '@me-services/ui/dialog';
import { DialogGridSize } from '@me-shared-parts/UI-common/grid/interfaces/grid-size';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { AdminAreaService } from '../../../../../../admin/admin-area/area/admin-area.service';
import { convertEventToMutable } from '../../convert-event-to-mutable';
import { getEventContextRows } from '../get-event-context-rows';
import { getActions } from './get-actions';


const ADD_ICON = 'fa-calendar-plus';
const EDIT_ICON = 'fa-calendar';


@Component({
	selector: 'event-dialog',
	templateUrl: './SHR-ED_event.dialog.html',
	styleUrls: ['./SHR-ED_event.dialog.scss']
})
export class EventDialog {

	actions = getActions();
	event: MutableDbsEvent;
	pendingChanges: MutableDbsEvent;
	wasSaved = false;

	icon = ADD_ICON;
	userCanEdit = false;
	adding = false;
	editing = false;
	showAwards = false;
	showAttendees = false;

	header = 'Event';
	eventContextRows: { label: string, value: string, site?: string }[] = [];
	toolIconMessage: string;
	teamsForAward: TeamsForAward[] = [];

	timezone = this.util.date.getTimezone();

	selectedTab = 0;
	awardsGridSize: DialogGridSize = {
		fitTo: 'DIALOG',
		dialogActions: true,
		dialogContext: {
			height: this.dialog.height,
			width: this.dialog.width - 27, //for the tabStrip and extra padding
			data: this.dialog.data,
			instanceIdentifier: this.dialog.instanceIdentifier,
		},
		heightMultiplier: 1,
		shrinkBy: 56, //for the tabStrip and extra padding
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ layout: 'ADD' | 'EDIT', event?: DbsEvent, newEvent?: EventContext, viewOnly: boolean, user: DbcUser, teamsForAward: TeamsForAward[] }>,
		public dialogRef: MatDialogRef<EventDialog>,
		private dialogService: DialogService,
		public util: UtilityService,
		private ds: DataService,
		private adminAreaService: AdminAreaService,
		public staffMemberService: StaffMemberService,
	) {

		this.setupActions();

		const { layout, event, newEvent, viewOnly, user, teamsForAward } = dialog.data;


		//
		// The function that provided the event should have determined if the event can be edited
		// by the current user. We store that value to determine if editing should be turned off.
		//
		this.userCanEdit = !!newEvent;
		if (event?.eventId && !viewOnly) this.checkUserAccess(user, event.eventId);


		//
		// Create a copy of the original event, and make an initialized
		// event object if we are starting with just an EventContext.
		//
		this.event = !!newEvent ? <MutableDbsEvent>{
			...newEvent,
			eventId: 0,
			startUTC: undefined,
			endUTC: undefined,
			toolStartUTC: undefined,
			toolEndUTC: undefined,
			venueId: undefined,
			link: '',
			publicNotes: '',
			attendees: [],
			awards: [],
			_concept: 'event',
		} : { ...convertEventToMutable(event) };


		this.initialize(teamsForAward, layout, user);

	}


	private initialize(teamsForAward: TeamsForAward[], layout: string, user: DbcUser) {

		//
		// Show awards to only applicable event types.
		//
		this.showAwards = !!this.ds.domain.awardNameEventType.getAll().find(a => a.eventTypeId == this.event.eventTypeId);

		this.teamsForAward = teamsForAward;


		//
		// Show attendees to only applicable event types.
		//
		this.showAttendees = this.ds.domain.eventType.getOne(this.event.eventTypeId)?.attendedBy == EventAttendedBy.Person;

		//
		// Ensure link and notes are trimmed strings (in case was sent as null/undefined)
		//
		this.event.link = (this.event.link || '').trim();
		this.event.publicNotes = (this.event.publicNotes || '').trim();

		const eventType = this.ds.domain.eventType.getOne(this.event.eventTypeId);
		this.header = `${eventType.fullName} Event - (${this.event.languageId == 2 ? 'ES' : 'EN'})`;

		if (eventType.toolNameLabelKey) {
			this.toolIconMessage = `The ${eventType.toolNameLabelKey} will be automatically enabled during this time.`;
		}

		this.eventContextRows = [];
		(async () => { this.eventContextRows = await getEventContextRows(this.ds, this.event); })();


		if (layout == 'ADD') {
			this.event.eventId = 0;
			if (!this.event.hostPersonId) this.event.hostPersonId = user.personId;

			this.actions.save.label = 'Add';
			this.adding = true;
			this.selectedTab = 1;
			this.setEditing(true);
		}

		else {
			this.icon = EDIT_ICON;
			this.setEditing(false);
		}
	}

	async checkUserAccess(user: DbcUser, eventId: number) {

		const event = await this.ds.admin.event.getOnePackage(eventId);
		const access = await this.ds.getEventAccess({ ...user }, event);
		this.userCanEdit = access == EventAccess.Write;
	}


	/**
	 * Hookup and add all the available action buttons
	 */
	setupActions() {
		this.actions.close.callback = this.close.bind(this);
		this.actions.save.callback = this.save.bind(this);
		this.actions.delete.callback = this.delete.bind(this);
		this.actions.cancel.callback = this.cancelEditing.bind(this);
	}


	setPendingChanges(pendingChanges: MutableDbsEvent) {
		if (this.pendingChanges && !pendingChanges) this.wasSaved = true;
		this.pendingChanges = pendingChanges;
		this.actions.save.enabled = !!this.pendingChanges;
	}


	/**
	 * Called when the Close action button is clicked. If the user has saved an edit,
	 * then we return the save action with the updated event, instead of the close action.
	 */
	async close(): Promise<void> {
		if (this.wasSaved) {
			this.actions.save.callbackResult = this.event;
			this.dialogRef.close(this.actions.save);
		}
		else {
			this.dialogRef.close(this.actions.close);
		}
	}


	/**
	 * Called when the Save action button is clicked.
	 */
	async save(): Promise<DbsEvent> {

		if (!this.pendingChanges) return undefined;

		let response: AppAreaApplyResponse;

		if (this.dialog.data.layout == 'ADD') {
			response = await this.adminAreaService.events.addEvent({ event: this.pendingChanges });
			//TODO: return the new eventId and this.event = await this.ds.admin.event.getOneWhenNotLooping
		}
		else if (this.dialog.data.layout == 'EDIT') {
			response = await this.adminAreaService.events.updateEvent({ event: this.pendingChanges });
			if (response.success) this.event = convertEventToMutable(await this.ds.admin.event.getOnePackage(this.pendingChanges.eventId));
		}

		this.setPendingChanges(undefined);
		this.setEditing(false);

		if (this.dialog.data.layout == 'ADD' && response.success) {
			this.dialogRef.close(this.actions.save);
		}

		return this.event;
	}


	/**
	 * Called when the Delete action button is clicked.
	 */
	async delete(): Promise<DbsEvent> {

		if (!await this.dialogService.confirm(`Are you sure you want to delete this event #${this.event.eventId}?`)) return;

		const succeeded = await this.adminAreaService.events.deleteEvent({ eventId: this.event.eventId });

		if (succeeded) {
			const event = this.event = undefined;
			this.ds.admin.event.removeEvent(this.event.eventId);
			this.dialogRef.close(this.actions.delete);
			return event;
		}

		return undefined;
	}


	/**
	 * Called when the Cancel action button is clicked.
	 */
	async cancelEditing() {
		if (this.adding) {
			this.dialogRef.close(this.actions.cancel);
		}
		else {
			this.setEditing(false);
		}
	}


	toolIconClick() {
		window.alert(this.toolIconMessage);
	}


	setEditing(editing: boolean) {
		this.actions.close.visible = !editing;
		this.actions.save.visible = editing;
		this.actions.delete.visible = editing && !this.adding;
		this.actions.cancel.visible = editing;
		this.editing = editing;
	}


	onTabSelect(tab: SelectEvent) {
		this.selectedTab = tab.index;

		this.setEditing(tab.index == 1);
	}
}