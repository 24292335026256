import { PrefixId } from '@me-interfaces';
import { DdPrefixRow } from './dd-prefix-row';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';

/**
 * @deprecated Use the DataService instead
 */
export class DdPrefix extends DdObject<DdPrefixRow> implements DdPrefixRow {

	constructor(dd: OldDomainDataService, data: DdPrefixRow) {
		super(data);
	}

	public get prefixId(): PrefixId {
		return this._data.prefixId;
	}

	public get name(): string {
		return this._data.name;
	}
}