import { DbConceptName, DbcZip, DbsSite } from "@me-interfaces";
import { DataService } from "../../data.service";
import { Explorable } from "../../explorable";
import { PendingSearchMatch, SearchFieldMatch, SearchMatch, SearchMatchLevel } from "./interfaces";

/**
 * Build a SearchMatch object for each of the pending matches, complete with
 * the location and site code objects.
 */
export async function createSearchMatches(ds: DataService, pendingSearchMatches: PendingSearchMatch[]): Promise<SearchMatch[]> {

	const matches: SearchMatch[] = await ds.util.array.awaitedMap(pendingSearchMatches, async m => {

		const { exactMatches, highMatches, mediumMatches, lowMatches } = formatMatches(m.fieldMatches);

		const match: SearchMatch = {
			id: m.explorable.id,
			conceptName: m.explorable._concept,
			level: m.level,
			updatedUTC: m.explorable.updatedUTC,
			name: m.explorable.name,
			siteCodes: await getSiteCodes(ds, m.explorable),
			location: await getLocation(ds, m.explorable),
			exactMatches, highMatches, mediumMatches, lowMatches
		};

		return match;
	});


	return matches;
}


async function getSiteCodes(
	ds: DataService,
	explorable: Explorable,
): Promise<string> {

	const siteBySiteId = await ds.admin.site.getAllAsMap();
	let siteId = undefined;

	if (explorable._concept == DbConceptName.Site) {
		return explorable.code;
	}

	else if (explorable._concept == DbConceptName.Venue) {
		const eventsByVenueId = await ds.admin.event.getByVenueIds([explorable.id]);
		const events = eventsByVenueId[explorable.id];
		return getSiteCodesFromSiteIds(events.map(event => event.siteId), siteBySiteId, ds);
	}

	else if (explorable._concept == DbConceptName.Person) {
		const applications = await ds.admin.application.getManyPackagesAsArray([...explorable.accApplicationIds, ...explorable.picApplicationIds]);
		const siteIds = [...applications.map(application => application.siteProgram.siteId), ...explorable.tags.map(tag => tag.siteId)];
		return getSiteCodesFromSiteIds(siteIds, siteBySiteId, ds);
	}

	else if (explorable._concept == DbConceptName.Company) {
		const applications = await ds.admin.application.getManyPackagesAsArray([...explorable.accApplicationIds, ...explorable.picApplicationIds]);
		const siteIds = applications.map(application => application.siteProgram.siteId);
		return getSiteCodesFromSiteIds(siteIds, siteBySiteId, ds);
	}


	else if (explorable._concept == DbConceptName.Event) {
		siteId = explorable.siteId;
	}

	else if (explorable._concept == DbConceptName.Accelerator || explorable._concept == DbConceptName.PitchContest || explorable._concept == DbConceptName.Application) {
		siteId = explorable.siteProgram.siteId;
	}
	else if (explorable._concept == DbConceptName.AccTeam || explorable._concept == DbConceptName.PicTeam) {
		siteId = explorable.application.siteProgram.siteId;
	}

	return siteId ? siteBySiteId[siteId].code : '';

}

function getLocation(ds: DataService, explorable: Explorable) {
	const zipByZipId: Record<number, DbcZip> = ds.util.array.toMap(ds.domain.zip.getAll(), zip => zip.zipId);
	let zipId: number = undefined;

	if (explorable._concept == DbConceptName.Site) {
		return explorable.name;
	}
	else if (explorable._concept == DbConceptName.Event) {
		if (explorable.venueId) zipId = explorable.venue.zipId;
		else return 'Online';
	}
	else if (explorable._concept == DbConceptName.Person) {
		zipId = explorable.asSingleton.zipId;
	}
	else if (explorable._concept == DbConceptName.Company || explorable._concept == DbConceptName.Venue) {
		zipId = explorable.zipId;
	}
	else if (explorable._concept == DbConceptName.Accelerator || explorable._concept == DbConceptName.PitchContest) {
		zipId = explorable.siteProgram.site.zipId;
	}
	else if (explorable._concept == DbConceptName.Application) {
		zipId = explorable.company.zipId || explorable.person.zipId;
	}
	else if (explorable._concept == DbConceptName.AccTeam || explorable._concept == DbConceptName.PicTeam) {
		zipId = explorable.application.company.zipId ? explorable.application.company.zipId : explorable.application.person.zipId;
	}

	return zipId ? zipByZipId[zipId].cityAndState : '';
}


function formatMatches(fieldMatches: SearchFieldMatch[]): { exactMatches: string, highMatches: string, mediumMatches: string, lowMatches: string, } {

	//
	// De-dup the levels of matches
	//

	const exactMatches = [...new Set(
		fieldMatches.filter(match => match.level == SearchMatchLevel.Exact).map(filter => filter.field)
	)].sort();

	const highMatches = [...new Set(
		fieldMatches.filter(match => match.level == SearchMatchLevel.High).reduce((a: string[], filter) => {
			if (!exactMatches.includes(filter.field)) a.push(filter.field);
			return a;
		}, [])
	)].sort();

	const mediumMatches = [...new Set(
		fieldMatches.filter(match => match.level == SearchMatchLevel.Medium).reduce((a: string[], filter) => {
			if (![...exactMatches, ...highMatches].includes(filter.field)) a.push(filter.field);
			return a;
		}, [])
	)].sort();

	const lowMatches = [...new Set(
		fieldMatches.filter(match => match.level == SearchMatchLevel.Low).reduce((a: string[], filter) => {
			if (![...exactMatches, ...highMatches, ...mediumMatches].includes(filter.field)) a.push(filter.field);
			return a;
		}, [])
	)].sort();


	return { exactMatches: exactMatches.join(', '), highMatches: highMatches.join(', '), mediumMatches: mediumMatches.join(', '), lowMatches: lowMatches.join(', ') };

}


function getSiteCodesFromSiteIds(siteIds: number[], siteBySiteId: Readonly<Record<number, DbsSite>>, ds: DataService): string {
	siteIds = ds.util.array.cleanNumericIds(siteIds);
	return siteIds.map(siteId => siteBySiteId[siteId].code).sort().join(' ') || '';
}