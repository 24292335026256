<div class='config-site-part'>
	<me-h2>Site Director:</me-h2>

	<captioned-layout-select *ngIf='vm.eforallStaff' [disabled]='readonly || !vm.canSetDirector' placeholder='Director'
		[list]='vm.eforallStaff' [value]='vm.director' (selection)='setDirector($event)'>
	</captioned-layout-select>

	<me-h2>Site Alternate Director:</me-h2>

	<div class='config-site-alt-director'>
		<captioned-layout-select *ngIf='vm.eforallStaff' placeholder='Alternate Director' [list]='vm.eforallStaff'
			[value]='vm.altDirector' [disabled]='readonly || !vm.canSetDirector' (selection)='setAltDirector($event)'>
		</captioned-layout-select>

		<spinner-button *ngIf='vm.altDirector' title='Remove Alternate Director'
			[caution]='true' (safeClick)='removeAltDirector()' [disabled]='readonly || !vm.canSetDirector'><i
				class='far fa-times'></i>
		</spinner-button>
	</div>

</div>