import { AgreementSignature, DbsAgreementSignature, DbsAgreementType, DbsAgreementVersion, DbsPerson } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";


export class AgreementSignaturePackageManager extends PackageManager<DbsAgreementSignature, AgreementSignature> {


	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsAgreementSignature>,
		private person: SingletonsManager<DbsPerson>,
		private agreementVersion: SingletonsManager<DbsAgreementVersion>,
		private agreementType: SingletonsManager<DbsAgreementType>,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}

	/**
	 * Convert an array of DbsAgreementSignature to an array of AgreementSignature
	 */
	protected async _createPackages(dbsAgreementSignatures: DbsAgreementSignature[]): Promise<AgreementSignature[]> {

		//
		// Get all the people
		//
		const personIds: number[] = [];

		const agreementTypeMap = await this.agreementType.getAllAsMap();
		const agreementVersionMap = await this.agreementVersion.getAllAsMap();



		for (const agreementSignature of dbsAgreementSignatures) {
			personIds.push(agreementSignature.updatedByPersonId, agreementSignature.personId);
		}

		const personMap = await this.person.getManyAsMap(personIds);

		//
		// Package 'em up
		//
		const agreementSignatures: AgreementSignature[] = dbsAgreementSignatures.map(agreementSignature => {

			const version = agreementVersionMap[agreementSignature.agreementVersionId];
			return {
				...agreementSignature,
				agreementType: agreementTypeMap[version.agreementTypeId],
				agreementVersion: version,
				person: personMap[agreementSignature.personId],
				updatedByPersonName: personMap[agreementSignature.updatedByPersonId]._name,
			};
		});

		return agreementSignatures;
	}



	/**
	 * Get all signatures for each personId provided
	 */
	public async getByPersonIds(personIds: readonly number[]): Promise<Readonly<Record<number, ReadonlyArray<AgreementSignature>>>> {

		return await this.getPackagesAsArraysByForeignIds('personId', personIds);
	}
}