import { Component, Input, OnInit } from "@angular/core";
import { DestroyablePart } from '@me-access-parts';
import { ApplicationOverview, LabelKey } from '@me-interfaces';
import { OldDomainDataService } from '@me-services/core/old-dd';

@Component({
	selector: 'application-instructions',
	templateUrl: './SHR-PG_application-instructions.part.html',
	styleUrls: ['./SHR-PG_application-instructions.part.scss'],
})
export class ApplicationInstructionsPart extends DestroyablePart implements OnInit {

	@Input() overview: ApplicationOverview;

	instructionKey = "md:Application Instructions for Accelerator";
	applicationOverviewKey: LabelKey;

	constructor(private dd: OldDomainDataService) {
		super();
	}


	ngOnInit() {
		super.initDestroyable();

		const programType = this.overview.accId ? "Accelerator" : this.overview.picId ? "Pitch Contest" : undefined;

		if (programType == "Pitch Contest") this.instructionKey = "md:Application Instructions for Pitch Contest";

		this.applicationOverviewKey = {
			key: 'md:Application Overview Instructions',
			fields: {
				'pm-full-name': this.overview.programManager,
				'pm-email-address': this.overview.pmEmail,
			}
		};
	}

}