import { ImmutableArray } from '@me-interfaces';


export function cleanArray(originalIds: ImmutableArray<number>): number[] {

	if (!originalIds) return [];

	//
	// Remove undefined values
	//
	let ids = originalIds.filter(id => !!id);


	//
	// Remove duplicates
	//
	ids = [...new Set(ids)];


	//
	// Sort for faster sql reading (matches primary key)
	//
	return ids.sort((id1, id2) => id1 - id2);

}