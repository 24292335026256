import { cleanNumericIds } from "./clean";

interface AggregateByCount<GROUP> {
	method: 'Count',
	setter: (group: GROUP, value: number) => void,
}


interface AggregateByCountUnique<ITEM, GROUP> {
	method: 'CountUnique',
	getter: (i: ITEM) => number,
	setter: (group: GROUP, value: number) => void,
}

interface AggregateBySum<ITEM, GROUP> {
	method: 'Sum',
	getter: (i: ITEM) => number,
	setter: (group: GROUP, value: number) => void,
}


interface AggregateByConcat<ITEM, GROUP> {
	method: 'Concat',
	getter: (i: ITEM) => string,
	setter: (group: GROUP, value: string) => void,
	/** Whether to sort the array of strings prior to joining them. If omitted, the array will not be sorted */
	sort?: number,
	/** A string used to separate one element of the array from the next in the resulting string. If omitted, the array elements are separated with a comma. */
	separator?: string,
}


/** Not Implemented yet */
interface AggregateByArrayOfNumbers<ITEM, GROUP> {
	method: 'ArrayOfNumbers',
	// 	getter: (i: ITEM) => number,
	// 	setter: (group: GROUP, value: number[]) => void,
}


/** Not Implemented yet */
interface AggregateByArrayOfStrings<ITEM, GROUP> {
	method: 'ArrayOfStrings',
	// 	getter: (i: ITEM) => number,
	// 	setter: (group: GROUP, value: number[]) => void,
}


export type Aggregator<ITEM, GROUP> =
	AggregateByCount<GROUP> |
	AggregateByCountUnique<ITEM, GROUP> |
	AggregateBySum<ITEM, GROUP> |
	AggregateByConcat<ITEM, GROUP> |
	AggregateByArrayOfNumbers<ITEM, GROUP> |
	AggregateByArrayOfStrings<ITEM, GROUP>;


/**
 * Given any array of objects, where each object has a value for a named property,
 * return a map where each value points to a subset array. Each item in each subset
 * array will have an idProperty value matching the map key.
 */
function toArrayMap<T, K extends number>(
	array: readonly T[],
	getId: (item: T) => number,
): Record<K, T[]> {

	return array.reduce((map, item) => {
		const id = getId(item);
		map[id] = map[id] ?? [];
		map[id].push(item);
		return map;
	}, <Record<K, T[]>>{});

}


/**
 * Given an array of items, organize by a grouping value and then perform aggregate functions on it.
 * This works much like the Group By clause in SQL.
 * @param groupBy A function that returns the value to group on from each array item
 * @param aggregators Instructions for calculating aggregate values
 * @returns An array of resulting groups with calculated aggregates
 */
export function groupBy<ITEM, GROUP>(
	items: ITEM[],
	groupBy: (i: ITEM) => number,
	setter: (g: GROUP, value: number) => void,
	aggregators: Aggregator<ITEM, GROUP>[],
): GROUP[] {

	const ids = cleanNumericIds(items.map(groupBy));
	const mappedItems = toArrayMap(items, groupBy);

	const groups = ids.map(id => {

		const items = mappedItems[id];
		const group = <GROUP>{};

		setter(group, id);

		for (const agg of aggregators) {

			if (agg.method == 'Count') {
				agg.setter(group, items.length);
			}

			else if (agg.method == 'CountUnique') {
				const unique = cleanNumericIds(items.map(i => agg.getter(i)));
				agg.setter(group, unique.length);
			}

			else if (agg.method == 'Sum') {
				const sum = items
					.map(i => agg.getter(i))
					.reduce((partialSum, a) => partialSum + a, 0);

				agg.setter(group, sum);
			}

			else if (agg.method == 'Concat') {
				let strings = items
					.map(i => agg.getter(i))
					.filter(s => s !== undefined);

				if (agg.sort) strings = strings.sort();

				agg.setter(group, strings.join(agg.separator));
			}
		}

		return group;

	});

	return groups;
}