export enum GridColumnType {

	/**
	 * Non wrappable text. An ellipsis will be shown if it doesn't fit.
	 */
	text = 'text',

	/**
	 * A number will be rendered per the locale of the browser.
	 * GridColumn.fractionDigits can be used to control the number of decimal places to include.
	 */
	number = 'number',

	/**
	 * A number will be rendered as US dollars.
	 */
	dollars = 'dollars',

	/**
	 * A number will be rendered as five digit zip code.
	 */
	zipCode = 'zipCode',

	/**
	 * A number will be rendered as a percentage. The value should range from 0..100.
	 * The value 75 will show as '75%'
	 */
	percent = 'percent',

	/**
	 * Displays string formated (###) ###-####
	 */
	phone = 'phone',

	/**
	 * Displays just the date part. The value must be a JavaScript Date object.
	 */
	date = 'date',

	/**
	* Displays just the date part. The value must be a UTC number value from the database.
	*/
	dateUtc = 'dateUtc',

	/**
	* Displays both the date and time parts. The value must be a JavaScript Date object.
	*/
	dateAndTime = 'datetime',

	/**
	* Displays both the date and time parts. The value must be a UTC number value from the database.
	*/
	dateAndTimeUtc = 'datetimeUtc',

	/**
	 * Displays just the time part. The value must be a JavaScript Date object.
	 */
	time = 'time',

	/**
	* Displays just the time part. The value must be a UTC number value from the database.
	*/
	timeUtc = 'timeUtc',

	/**
	 * Renders a boolean value as either 'No' or 'Yes'.
	 * GridColumn.booleanDisplay can be used to display different text.
	 */
	boolean = 'boolean',

	/**
	 * Renders an icon without text. 
	 */
	icon = 'icon',

	/**
	 * Renders an icon context with icon and text. 
	 */
	iconContext = 'iconContext',

	/**
	 * Displays an icon that represents the state of entity notes for a person or company.
	 * The icon is tristate; red-flag, has-notes, no-notes.
	 */
	entityNotes = 'entityNotes',

	/**
	 * Displays an icon that represents whether the row item has a red flag.
	 */
	redFlag = 'redFlag',

	/**
	 * Displays an icon that represents whether the row item has a note (Not for a person or company but for application, accteam etc).
	 */
	notes = 'notes',

	/**
	 * Renders fa-gauge based on the gaugeThresholds[#,#,#,#]. 
	 */
	gauge = 'gauge',

	/**
	 * Renders a progress bar based on the progressThresholds[#,#]. 
	 */
	progressBar = 'progressBar',

	/**
	 * Renders four counts showing the number of Y!, Y, N, N! ratings.
	 * The value must be a four-element number array with counts for N!, N, Y, Y! respectively.
	 */
	ratings = 'ratings',

	/**
	 * Renders an indicator for a single rating (Y!, Y, N, or N!).
	 * The value must be a single number 1..4 representing N!, N, Y, Y! respectively.
	 */
	rating = 'rating',

	/**
	 * Renders an AccSeasonId as the English name
	 */
	season = 'season',

	/**
	 * A four-digit number representing a year. e.g. 2023 will be rendered as '2023'
	 */
	year = 'year',
}