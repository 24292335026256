import { NationalDashboardPageService, SharedDashboardsService } from '@ADMIN-area';
import { Component } from '@angular/core';
import { GridSetup } from '@me-grid';
import { map } from 'rxjs/operators';


@Component({
	selector: 'shared-acc-mentors-view-part',
	templateUrl: './acc-mentors-view.part.html',
})
export class SharedAccMentorsViewPart {

	public gridSetup = this.setupGrid();

	public rows$ = this.sharedDashboardsService.dashboardData$.pipe(
		map(data => data ? data.accelerator.accMentorPersonIds.map(personId => ({ personId })) : [])
	);


	constructor(
		public pageService: NationalDashboardPageService,
		private sharedDashboardsService: SharedDashboardsService,
	) {
	}


	private setupGrid(): GridSetup<{ personId: number }> {

		return {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Mentor",
			rowPluralName: "Mentors",
			rowKey: "personId",
			stateKey: "shared-acc-mentors-view-part",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			initialState: {},
		};
	}

}