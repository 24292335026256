import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'access-error-404-page',
	templateUrl: './error-404.page.html',
	styleUrls: ['./error-404.page.scss']
})
export class AccessError404Page {
	
	router: Router;

	constructor(router: Router) {
		this.router = router;

		dataLayer.push({
			'event': '404-error'
		});
	}

	goToDashboard(): void {
		this.router.navigate(['/access/my/dashboard']);
	}
}