import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DbsPerson } from '@me-interfaces';
import { OldEntityService } from '@me-services/old-services-and-wrappers/entity';
import { DdTag, OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from '@me-services/core/func';

@Component({
	selector: 'simple-tag-editor',
	templateUrl: './SHR-CMN_simple-tag-editor.part.html',
	styleUrls: ['SHR-CMN_simple-tag-editor.part.scss'],
})
export class SimpleTagEditorPart implements OnInit {

	@Input() person: DbsPerson;
	@Input() prefixTags: DdTag[] = [];
	@Input() canEdit: boolean;
	@Output() tagsChanged = new EventEmitter<DbsPerson>();

	prefix: string;
	tagValues: { [index: number]: boolean } = {};
	personTags: DdTag[] = [];

	manualPrefixTags: DdTag[] = [];
	tagPrefixHasAutoTags = false;
	autoPrefixTags: DdTag[] = [];

	constructor(
		private func: FuncService,
		private dd: OldDomainDataService,
		private entityService: OldEntityService
	) { }

	ngOnInit() {
		if (this.person && this.prefixTags.length) {
			this.prefix = this.prefixTags[0].prefix;
			this.tagValues = {};
			const prefixId = this.prefixTags[0].tagPrefixId;

			this.manualPrefixTags = this.prefixTags.filter(tag => !tag.isAutoTag);
			this.tagPrefixHasAutoTags = !!this.prefixTags.find(tag => tag.isAutoTag);

			this.personTags = this.dd.tags.getByIds(this.person._personExtracts.tagIds);
			//
			// Get all of this person's tags for the tag prefix we are working with
			//
			const currentTags = this.personTags.filter(tag => tag.tagPrefixId == prefixId);

			for (const tag of this.manualPrefixTags) {
				this.tagValues[tag.tagId] = !!currentTags.find(t => t.tagId == tag.tagId);
			}

			this.autoPrefixTags = currentTags.filter(tag => tag.isAutoTag);

		}
	}

	async toggleTag(tagId: number) {
		const hasTag = this.person._personExtracts.tagIds.find(tId => tId == tagId);
		const tagName = this.dd.tags.getById(tagId).name;
		if (hasTag) await this.entityService.wrapPerson(this.person).tags.remove(tagId);
		else await this.entityService.wrapPerson(this.person).tags.add(tagId);

		//Fetch the latest domain data for facilitator-approved tag
		if (tagName === 'facilitator-approved') await this.dd.fetchLatestData();

		this.tagsChanged.emit(this.person);
	}
}
