import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DbConceptName } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { getIconClass, getIconContext, Icon } from '@me-shared-parts/UI-common';
import { SiteAreaService } from '@SITE-area';


export interface VolunteerRoleCallBackValue { personId: number, status: 'Approved' | 'Not Approved' | 'Consider' }


@Component({
	selector: 'site-volunteer-role-add-dialog',
	templateUrl: './volunteer-role-add.dialog.html',
})
export class SiteVolunteerRoleAddDialog extends DestroyablePart implements OnInit {
	//
	// The action buttons
	//
	addAction: DialogAction<VolunteerRoleCallBackValue> = {
		id: 'add',
		enabled: false,
		visible: true,
		label: 'Add',
		linkType: 'callback',
		callback: this.save.bind(this),
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: 'Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction<VolunteerRoleCallBackValue | void>[] = [this.addAction, this.cancelAction];
	icon = getIconClass(Icon.action_add);

	personIcon = getIconContext(Icon.concept_person, undefined, 'never', 'Person');

	header = 'Add Role';

	statuses = ['Consider', 'Approved', 'Not Approved'];
	selectedStatus: 'Approved' | 'Not Approved' | 'Consider';

	readonly: boolean;
	siteId: number;

	newSelectedValue: VolunteerRoleCallBackValue = { personId: undefined, status: undefined };


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ personId: number, role: 'reader' | 'judge' | 'mentor' | 'interviewer' | 'eventhelper' | 'coach' }>,
		public dialogRef: MatDialogRef<SiteVolunteerRoleAddDialog>,
		private ds: DataService,
		private siteAreaService: SiteAreaService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		this.siteId = this.siteAreaService.getId().id;

		super.subscribe([
			this.siteAreaService.accessAndId$,
		], async ([accessAndId]) => {
			this.readonly = accessAndId?.access?.root != 'Write';
		});

		const { personId, role } = this.dialog.data;

		this.personIcon.text = (await this.ds.admin.person.getOne(personId))._name;


		this.header = 'Add Role';

		if (role) {
			if (role == 'eventhelper') this.header == 'Add Event Helper';
			else this.header = 'Add ' + role.charAt(0).toUpperCase() + role.substring(1);
		}

		this.newSelectedValue = { personId, status: undefined };
		this.checkIfCanSave();
	}

	checkIfCanSave() {
		this.addAction.enabled = !this.readonly
			&& !!this.newSelectedValue.status
			&& !!this.newSelectedValue.personId;
	}


	public onStatusSelectionChange(e: 'Approved' | 'Not Approved' | 'Consider') {
		this.newSelectedValue.status = e;
		this.checkIfCanSave();
	}


	async explorePerson() {
		if (!this.newSelectedValue.personId) return;
		await this.ds.explorer.explore(DbConceptName.Person, this.newSelectedValue.personId);
	}


	async save() {
		return this.newSelectedValue;
	}
}