import { AppAreaIdentifier, AppAreaIdentifierWithSite, AppAreaIdentifierWithSiteId } from "@me-interfaces";


/**
 * When an area is nested under the site admin area,
 * call this to tack the site code onto the identifier.
 */
export function mapToIdentifierWithSite<T extends number | string>(values: [
	site: AppAreaIdentifier<string>,
	area: AppAreaIdentifier<T>,
]
): AppAreaIdentifierWithSite<T> {

	const [site, area] = values;

	if (site == undefined || area == undefined) return undefined;
	return { ...area, siteCode: site.id };
}


/**
 * When an area is nested under the site admin area,
 * call this to tack the site id onto the identifier.
 */
export function mapToIdentifierWithSiteId<T extends number | string>(values: [
	site: AppAreaIdentifier<number>,
	area: AppAreaIdentifier<T>,
]
): AppAreaIdentifierWithSiteId<T> {

	const [site, area] = values;

	if (site == undefined || area == undefined) return undefined;
	return { ...area, siteId: site.id };
}