<me-dialog-frame header='Manage {{header}}' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon'
	alertType='editor'>
	<div class="manage-volunteers-dialog-part">

		<div>Select from the following people with tag {{tagName}}.</div>

		<div>
			<button class="text-btn text-btn-not-inline add-role-button" (click)="onSearchClick()">
				<me-icon [icon]="addIcon"></me-icon>
			</button>
			<me-multiselect style="display:inline-block; width:100%; height:340px;" [buttonIcon]="buttonIcon"
				[items]="people$ | async" [readonly]="readonly" (selectionChange)="onSelectionChange($event)"
				(buttonClick)="onButtonClick($event)">
			</me-multiselect>
		</div>
		<span>
			<small>{{selectedPersonIds.length}} of {{totalPersonIds.length}} selected. </small><br>
			<small><i class='far fa-info-circle'></i> People with 1 or more assessments cannot be removed.</small>
		</span>

	</div>
</me-dialog-frame>