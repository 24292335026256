import { Injectable } from '@angular/core';
import { OldDomainDataService, DdSite } from '@me-services/core/old-dd';
import { FuncService } from '@me-services/core/func';
import { SiteWrapper } from './site-wrapper';
import { UtilityService } from '@me-services/core/utility';
import { ActivatedRouteSnapshot } from '@angular/router';

/** @deprecated DO NOT USE THIS!*/
@Injectable({ providedIn: 'root' })
export class OldSiteService {

	/** @deprecated */
	constructor(
		private func: FuncService,
		private dd: OldDomainDataService,
		private util: UtilityService,
	) {
	}

	public getSiteCodeFromUrl(url?: string): string {

		let siteCode = '';
		if (!url) url = window.location.pathname.toLowerCase();

		if (url.startsWith('/access/sites/')) {
			const parts = url.split('/');
			if (parts.length >= 4) siteCode = parts[3];
		}
		if (url.startsWith('/access/admin/communities/')) {
			const parts = url.split('/');
			if (parts.length >= 4) siteCode = parts[4];
		}

		return siteCode.toUpperCase();
	}


	public getDdSiteFromUrl(url?: string): DdSite {

		let site: DdSite = undefined;
		const siteCode = this.getSiteCodeFromUrl(url);

		if (siteCode) site = this.dd.sites.getByCode(siteCode);

		return site;
	}


	public getDdSiteFromSiteId(siteId?: number): DdSite {

		let site: DdSite = undefined;
		if (siteId) site = this.dd.sites.getById(siteId);

		return site;
	}


	public getDdSiteFromRoute(route: ActivatedRouteSnapshot): DdSite {

		const url = this.util.router.routeToUrl(route);
		return this.getDdSiteFromUrl(url);
	}


	public wrapSite(site: DdSite): SiteWrapper {
		return new SiteWrapper(site, this.func, this.dd);
	}
}