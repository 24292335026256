import { LinkOrExplorable } from "../link-or-explorerable";

export enum ExplorerBoxClass {
	Accelerator = 'explorer-box-accelerator',
	Application = 'explorer-box-application',
	Award = 'explorer-box-award',
	Cohort = 'explorer-box-cohort',
	Event = 'explorer-box-event',
	Interview = 'explorer-box-interview',
	Judgement = 'explorer-box-judgement',
	LangBio = 'explorer-box-lang-bio',
	Member = 'explorer-box-member',
	Mentor = 'explorer-box-mentor',
	Note = 'explorer-box-note',
	Person = 'explorer-box-person',
	PitchContest = 'explorer-box-pitchContest',
	Position = 'explorer-box-position',
	Reading = 'explorer-box-reading',
	RedFlagNote = 'explorer-box-note-red-flag',
	Relationship = 'explorer-box-relationship',
	Site = 'explorer-box-site',
	Tag = 'explorer-box-tag',
	Volunteer = 'explorer-box-volunteer',
}

export interface ExplorerBox {
	explorerBoxClass: ExplorerBoxClass,
	header: string,
	buttons: LinkOrExplorable[],
	notes: string,
}