<app-area-access [areaService]='accAreaService' accessKey="assessments">

	<div class='acc-reading-assessment-view-part' *ngIf="data$|async;let data;">

		<me-droplist style='display:inline-block' *ngIf="orderList" [items]='orderList'
			[selectedItem]='orderSelectedItem' (selectionChange)='changeOrder($event)'>
		</me-droplist>

		<assessment-results [data]='data' [order]='order'></assessment-results>
	</div>
</app-area-access>