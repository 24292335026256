import { EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { DbConceptName } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { Icon } from "@me-shared-parts/UI-common/icon";
import { EXPLORE_GRID_ACTION_KEY, GridColumnType, GridSetup, GRID_EXPERIENCE, OPEN_GRID_ACTION_KEY } from "../interfaces";
import { GridExperience } from "./grid-experience";

//
// Column keys
//
const PICTEAM_ID = 'PICTEAM-picTeamId';
const PICTEAM_REDFLAG = 'PICTEAM_redFlag';

/**
 * Standard experience for a grid of pic teams
 */
export class PicTeamGridExperience<ROW> extends GridExperience<ROW> {

	public static readonly experience: GRID_EXPERIENCE = 'PICTEAM';

	constructor(
		private ds: DataService,
		util: UtilityService,
		setup: GridSetup<ROW>,
		gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
		private router: Router,
	) {
		super(
			util, setup,
			[
				{ field: PICTEAM_ID, header: 'picTeamId', width: 100, type: GridColumnType.number, hidden: true },
			],
			[
				{ field: PICTEAM_REDFLAG, header: "Flag", width: 50, type: GridColumnType.redFlag },
			],
			[
			],
			[
				{ key: EXPLORE_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Explore ${setup.rowSingularName}` },
				{ key: OPEN_GRID_ACTION_KEY, icon: Icon.action_open, label: `Open ${setup.rowSingularName}` },
				// { key: NOTES_GRID_ACTION_KEY, icon: Icon.action_editNotes, label: `Edit Team Notes` },
			],
			gridAction,
			Icon.concept_picTeam,
		);
	}


	public async applyBaseValues(rows: ROW[]) {

		const picTeamMap = await this.ds.admin.picTeam.getManyPackagesAsMap(rows.map(row => row['picTeamId']));

		for (const row of rows) {
			const picTeamId = row['picTeamId'];
			const picTeam = picTeamMap[picTeamId];

			row[PICTEAM_ID] = picTeam.picTeamId;
			row[PICTEAM_REDFLAG] = picTeam.hasRedFlag;
			row['siteCode'] = picTeam.application.siteProgram.site.code;
			row['siteId'] = picTeam.application.siteProgram.site.siteId;
			row['picId'] = picTeam.picId;

		}
	}


	public sortRows(rows: ROW[]) {
		rows.sort((row1: ROW, row2: ROW) => {
			return 0;
		});
	}


	public async explore(row: ROW) {
		await this.ds.explorer.explore(DbConceptName.PicTeam, row[PICTEAM_ID]);
	}


	public async open(row: ROW) {
		await this.router.navigate([`access/admin/communities/${row['siteCode'].toLowerCase()}/programs/pitch-contests/${row['picId']}/participants/${row[PICTEAM_ID]}`]);
	}


	public async editNotes(row: ROW) {
		// Notes dialog not implemented
	}
}