import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DataService } from '@me-services/core/data';

@Injectable()
export class AccTeamMenuLabelResolver {

	constructor(
		private ds: DataService,
	) { }

	async resolve(route: ActivatedRouteSnapshot) {

		const teamId = +route.params.accTeamId;
		if (isNaN(teamId)) throw new Error(`Unexpected Id: ${teamId}`);

		const menuLabel = (await this.ds.admin.accTeam.getOnePackage(teamId))?.application.company._name || `${teamId}?`;
		return menuLabel;
	}
}