import { MenuItem } from '@me-interfaces';


export const helpMenu: MenuItem = {

	label: `Help`,
	url: 'https://chat.google.com/room/AAAAlOvAAAc',
	target: 'eforall-app-help',
	icon: 'fas fa-question',

}