import { Component, Input, OnInit } from '@angular/core';
import { DbsCompany } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { ShowCompanyExtractDialogService } from '../company-extract-dialog/SHR-CM_show-company-extract-dialog.service';

@Component({
	selector: 'company-extract-link',
	templateUrl: './SHR-CM_company-extract-link.part.html',
	styleUrls: ['./SHR-CM_company-extract-link.part.scss']
})
export class CompanyExtractLinkPart implements OnInit {

	@Input() company?: DbsCompany;
	@Input() companyId?: number;

	constructor(
		private func: FuncService,
		public showCompanyExtractDialogService: ShowCompanyExtractDialogService,) {
	}

	async ngOnInit() {
		if (!this.company && !this.companyId) throw new Error(`Missing company and companyId attribute`);
		if (!this.company) this.company = await this.func.company.get({ companyId: this.companyId });
	}

	async open() {
		await this.showCompanyExtractDialogService.show(this.company);
	}
}