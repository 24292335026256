<div class="explorer-tag-box-part {{box.explorerBoxClass}}">

	<div class="explorer-box-header">{{ box.header }}</div>

	<div class="explorer-box-buttons">
		<explorer-button *ngIf="box.button && box.button.explorer" [button]="box.button.explorer"></explorer-button>
		<div style="padding: 0 5px;">
			<ng-container *ngFor="let icon of box.icons">
				<me-icon [icon]="icon"></me-icon>
			</ng-container>
		</div>
	</div>
</div>