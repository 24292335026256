import { AccSessionSurveyResponse, DbaAccSession, DbaSessionAttendee, DbdTopic, DbsAccSessionSpecialistSurveyResponse, DbsAccSessionTopicSurveyResponse } from "@me-interfaces";
import { DataService } from "@me-services/core/data/data.service";
import { UtilityService } from "@me-services/core/utility";
import { UrlService } from "@me-services/ui/url";
import { AccAreaData } from "admin/acc-area/area/interfaces";


export interface SurveyCountsData {

	/** The eventId for this session */
	eventId: number,

	/** The display name for the session's event */
	eventName: string,

	/** The number of entrepreneurs that attended the session */
	attendeesPresent: number,

	/** Each attendee of this session by its attendeeId */
	attendeeByAttendeeId: Record<number, DbaSessionAttendee>,

	/** Array of session surveys for this session */
	sessionSurveys: readonly AccSessionSurveyResponse[],
	
	/** Each topic by the topicId */
	topicByTopicId: Readonly<Record<number, DbdTopic>>,
	
	/** Array of topic surveys for this session */
	topicSurveys: DbsAccSessionTopicSurveyResponse[],

	/** Topic surveys for any topicId for this session */
	topicSurveysByTopicId: Record<string | number, DbsAccSessionTopicSurveyResponse[]>,

	/** Specialist surveys for any topicId for this session */
	specialistSurveysByTopicId: Record<string | number, DbsAccSessionSpecialistSurveyResponse[]>,

	/** Each specialist for this session by the specialist's personId */
	specialistByPersonId: Record<number, {
		personId: number;
		name: string;
		accTopicSpecialistId: number;
	}>,
}


/**
 * Gather all the data used when calculating the aggregates
 */
export async function getSurveyCountsData(
	ds: DataService,
	util: UtilityService,
	urlService: UrlService,
	session: DbaAccSession,
	accAreaData: AccAreaData,
): Promise<SurveyCountsData> {


	const eventId = session.eventId;
	const event = await ds.admin.event.getOne(eventId);

	//
	// Get the event name
	//
	const eventName = util.date.formatUTC(
		event.startUTC,
		'MMM D, YYYY (DOW)',
		'H:MM AM EST',
		urlService.languageId,
	)


	const topicByTopicId = await ds.domain.topic.getAllAsMap();


	//
	// Get all session Surveys for this Session (using eventId)
	//
	const sessionSurveysByEventId = await ds.admin.accSessionSurveyResponse.getByEventIds([eventId]);
	const sessionSurveys = sessionSurveysByEventId[eventId];


	//
	// Get all topic Surveys for this Session
	//
	const allTopicSurveys = await ds.admin.accSessionTopicSurveyResponse.getAllAsArray();
	const topicSurveys = allTopicSurveys.filter(s => s.eventId == eventId);
	const topicSurveysByTopicId = util.array.toArrayMap(topicSurveys, s => s.topicId);


	//
	// Get all sepcialist Surveys for the topics above
	//
	const allSpecialistSurveys = await ds.admin.accSessionSpecialistSurveyResponse.getAllAsArray();
	const specialistSurveys = allSpecialistSurveys.filter(s => s.eventId == eventId);
	const specialistSurveysByTopicId = util.array.toArrayMap(specialistSurveys, s => s.topicId);


	//
	// Get all Attendees
	//
	const sessionAttendees = accAreaData.curriculum.sessionAttendees.filter(s => s.attendee.accSessionId == session.accSessionId);
	const attendeeByAttendeeId = util.array.toMap(sessionAttendees, s => s.accSessionAttendeeId);
	const attendeesPresent = sessionAttendees.filter(s => s.attendee.status == 'Present').length;


	//
	// Get Specialists
	//
	const specialistByPersonId = util.array.toMap(
		accAreaData.curriculum.topicSpecialists.map(s => ({
			personId: s.person.personId,
			name: s.person.name,
			accTopicSpecialistId: s.topicSpecialist.accTopicSpecialistId,
		})), s => s.personId);

	

	return {
		eventId,
		eventName,
		attendeesPresent,
		sessionSurveys,
		topicByTopicId,
		topicSurveys,
		topicSurveysByTopicId,
		attendeeByAttendeeId,
		specialistSurveysByTopicId,
		specialistByPersonId,
	};
}