<div class='event-explorer-details-part' *ngIf="event">

	<div id="container" class="explorer-details-container">

		<table class='pro-table pro-table-no-header pro-table-with-labels'>
			<tr>
				<td>Event Id</td>
				<td>
					{{event.eventId}}
				</td>
			</tr>

			<tr *ngIf="dates">
				<td>Event Time</td>
				<td>
					<div *ngIf='dates.eventDates.description'>{{ dates.eventDates.description }}</div>
					<div *ngIf='!dates.eventDates.description' class='missing-value'>Dates Not Provided</div>
				</td>
			</tr>

			<!------------- Tool start and end dates/times  --------------->

			<tr *ngIf="dates">
				<td kendoTooltip>
					<i class='far fa-question-circle tool-info-icon' [title]='toolIconMessage'
						(click)='toolIconClick()'></i>
					Tool Enabled
				</td>
				<td>
					<div *ngIf='dates.toolDates.description'>{{ dates.toolDates.description }}</div>
					<div *ngIf='!dates.toolDates.description' class='missing-value'>Dates Not Provided</div>
				</td>
			</tr>

			<!------------- Host  --------------->

			<tr>
				<td>Host</td>
				<td>
					<explorer-button *ngIf='host'
						[button]="{ explorableId: { conceptName: 'person', id: host.personId }}" [tooltip]="host._name">
					</explorer-button>
					<div *ngIf='!host' class='missing-value'>Host Not Provided</div>
				</td>
			</tr>

			<!------------- Location  --------------->

			<tr>
				<td>Location</td>
				<td>
					<div *ngIf='venue'>
						<explorer-button [button]="{ explorableId: { conceptName: 'venue', id: venue.venueId }}">
						</explorer-button>
					</div>
					<div *ngIf='!venue'>Online</div>
				</td>
			</tr>

			<!------------- Link and Notes  --------------->

			<tr>
				<td>Link</td>
				<td>

					<link-button *ngIf="!venue && event.link"
						[link]="{ linkType: 'webLink', webLinkTypeId:'1', url: event.link , overrideText: 'Online Meeting Link' }">
					</link-button>

					<link-button *ngIf="venue && event.link"
						[link]="{ linkType: 'webLink', webLinkTypeId:'1', url: event.link , overrideText: 'Web Link' }">
					</link-button>

					<div *ngIf='!event.link' class="missing-value">Link Not Provided</div>
				</td>
			</tr>

			<tr>
				<td>Public Notes</td>
				<td>
					<div *ngIf='event.publicNotes' class="public-notes">{{event.publicNotes}}</div>
					<div *ngIf='!event.publicNotes' class="missing-value">Notes Not Provided</div>
				</td>
			</tr>

			<tr>
				<td>Created By</td>
				<td>
					<explorer-button [button]="{ explorableId: { conceptName: 'person', id: event.createdByPersonId }}"
						[tooltip]="event.createdByPersonName">
					</explorer-button>
					<div class="small-nowrap-text">
						on {{util.date.formatUTC(event.createdUTC, 'MMM D, YYYY', 'H:MM AM EST',
						ds.languageId)}}
					</div>
				</td>
			</tr>

			<tr>
				<td>Last Edited By</td>
				<td>
					<explorer-button [button]="{ explorableId: { conceptName: 'person' , id: event.updatedByPersonId }}"
						[tooltip]="event.updatedByPersonName">
					</explorer-button>
					<div class="small-nowrap-text">
						on {{util.date.formatUTC(event.updatedUTC, 'MMM D, YYYY', 'H:MM AM EST',
						ds.languageId)}}
					</div>
				</td>
			</tr>

		</table>

		<div class="explorer-details-right">

			<div class='timezone-message'>
				<small>All times are local to {{timezone}}.</small>
			</div>

			<button class='btn' (click)='openEventDialog()'>
				<me-icon [icon]='editIcon'></me-icon>
			</button>
		</div>

	</div>
</div>