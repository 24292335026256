import { Component, ViewChild } from "@angular/core";
import { FuncService } from '@me-services/core/func';
import { LabelsService } from '@me-services/ui/labels';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';
import * as moment from 'moment';
import { BaseFieldPart } from '../base/SHR-UI_base-field.part';

@Component({
	selector: 'date-field',
	templateUrl: './SHR-UI_date-field.part.html',
})

export class DateFieldPart extends BaseFieldPart<Date> {

	basePart: BaseFieldPart<Date> = this;

	@ViewChild('dateField') dateField: DatePickerComponent;

	constructor(
		protected override func: FuncService,
		protected override labelsService: LabelsService,
	) {
		super(func, labelsService);
	}

	onKeyUp(event: KeyboardEvent) {
		if (event.isTrusted && event.key == 'Enter') {
			// The user pressed Enter
			this.attemptToSave();
		}
	}

	translateValueToDate(value: number) {
		return value ? moment(value * 1000).toDate() : undefined;
	}


	override translateValueIn(value: number): Date {
		return value ? moment(value * 1000).toDate() : undefined;
	}


	override translateValueOut(value: Date): number {
		return value ? value.getTime() / 1000 : undefined;
	}


	onValueChange(event: Date) {
		this.setPendingValue(event);
	}


	override checkForAdditionalError(): boolean {

		const value: Date = this.dateField?.value || null;
		if (value) {
			const v = value.getTime() / 1000;
			if (v < this.field.minValue) {
				this.errorText = `Date is less than ${moment(this.field.minValue * 1000).format("MM/DD/YYYY")}`;
				return true;
			}
			if (v > this.field.maxValue) {
				this.errorText = `Date is greater than ${moment(this.field.maxValue * 1000).format("MM/DD/YYYY")}`;
				return true;
			}
		}
		this.errorText = undefined;
		return false;
	}


	override valuesAreSame(value1: Date, value2: Date): boolean {
		return value1?.getTime() == value2?.getTime();
	}


	/** Calculate a max-width style that is roughly based on the maximum char length */
	get maxWidth() {
		const m = this.field?.maxLength || 100;
		return (m + 2) - m / 5;
	}
}