import { DbcCompanyProfile } from "@me-interfaces";
import { OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from "@me-services/core/func";
import { AddressUpdater } from './address-updater';
import { CompanyWrapper } from "./company-wrapper";
import { PublicAddressUpdater } from './public-address-updater';
import { WebLinksUpdater } from "./web-links-updater";

/** @deprecated DO NOT USE THIS!*/
export class CompanyProfileWrapper extends CompanyWrapper implements DbcCompanyProfile, WebLinksUpdater, AddressUpdater, PublicAddressUpdater {

	private readonly companyProfile: DbcCompanyProfile;

	constructor(
		protected profile: DbcCompanyProfile,
		protected override func: FuncService,
		protected override dd: OldDomainDataService
	) {
		super(profile, func, dd);
		this.companyProfile = profile;
	}

	public get description() { return this.companyProfile.description; }
	public get _profileExtracts() { return this.companyProfile._profileExtracts; }

	//
	// Helpers
	//

	public async updateDescription(newValues: { companyId: number, description: string }) {

		const company = await this.func.company.updateDescription(newValues);

		//@ts-ignore
		this.companyProfile.description = company.description;
	}

	//
	//	This is the webLinkUpdater interface implementation
	//

	public async addWebLink(url: string, webLinkTypeId: number) {
		const data = await this.func.entity.webLinks.add({ entityId: this.companyProfile.entityId, url, webLinkTypeId });

		//@ts-ignore TS2540
		this.companyProfile = Object.assign(this.companyProfile, data);
	}

	public async removeWebLink(webLinkId: number) {
		const data = await this.func.entity.webLinks.remove({ entityId: this.companyProfile.entityId, webLinkId });

		//@ts-ignore TS2540
		this.companyProfile = Object.assign(this.companyProfile, data);
	}


	public async updateWebLink(webLinkId: number, url: string, webLinkTypeId: number) {
		const data = await this.func.entity.webLinks.update({ entityId: this.companyProfile.entityId, webLinkId, url, webLinkTypeId });

		//@ts-ignore TS2540
		this.companyProfile = Object.assign(this.companyProfile, data);
	}

	//
	// Address Updater
	//

	public async updateAddress(zipCode: string, inUS: boolean, addressLines: string) {
		const data = await this.func.entity.address.update({ entityId: this.companyProfile.entityId, zipCode, inUS, addressLines });
		//@ts-ignore TS2540
		this.companyProfile = Object.assign(this.companyProfile, data);
	}

	public async publicUpdateAddress(zipCode: string, inUS: boolean, addressLines: string) {
		const data = await this.func.entity.publicAddress.update({ entityId: this.profile.entityId, zipCode, inUS, addressLines });

		//@ts-ignore TS2540
		this.companyProfile = Object.assign(this.companyProfile, data);
	}
}