import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { DbcUser } from '@me-interfaces';
import { ErrorPageService } from '@me-services/ui/error-page';
import { LabelsService } from '@me-services/ui/labels';
import { MenuService } from '@me-services/ui/menu';
import { UserAreaService } from '@me-user-area';
import { Subscription } from 'rxjs';


@Component({
	selector: 'me-access',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './ACS.page.html',
	styleUrls: ['./ACS.page.scss']
})
export class AccessPage implements OnDestroy {

	public routerIsNavigating = false;
	@Input() moveSpeed = 1000;

	mainWrapper; // for scrolling to the top
	user: DbcUser | undefined;
	userSubscription: Subscription;

	public isSpanish = false;

	constructor(
		public menu: MenuService,
		public labels: LabelsService,
		router: Router,
		public userAreaService: UserAreaService,
		public errorPage: ErrorPageService,
	) {
		this.mainWrapper = jQuery('.main-wrapper');

		router.events.subscribe((event: Event) => {

			if (event instanceof NavigationStart) {
				this.routerIsNavigating = true;
				setTimeout(_ => jQuery('.main-wrapper').animate({ scrollTop: 0 }, { duration: this.moveSpeed }), 500);
			}
		});

		this.userSubscription = userAreaService.user$.subscribe(user => {
			this.user = user;
			dataLayer.push({ 'staff': user && user.isCrmUser });
		});

		if (window.location.hostname.includes('eparatodos')) this.isSpanish = true;
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	public hideMenu(): void {
		this.menu.setCollapsed(true);
	}
}
