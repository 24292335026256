import { readClipboardText } from "./read-clipboard-text";
import { writeClipboardText } from "./write-clipboard-text";

export const ClipboardUtilities = {

  /**
   * Read text from the clipboard
   */
  readText: async () => {
    return await readClipboardText();
  },

  /**
   * Write text to the clipboard
   */
  writeText: async (text: string) => {
    return await writeClipboardText(text);
  },

}