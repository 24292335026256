import { ComponentType } from '@angular/cdk/overlay/index';
import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LabelKey } from '@me-interfaces';
import { LayoutDimensions, LayoutService } from '@me-services/ui/layout';
import { ConfirmationDialog, EditTextDialog, MessageDialog, NotesDialog } from '@me-shared-dialogs';
import { DialogAction, DialogActivity, DialogContext, DialogData } from './';

const PADDINGS = {
	xs: 10,
	sm: 12,
	md: 20,
	lg: 25,
};

@Injectable({ providedIn: 'root' })
export class DialogService {

	public activity = new EventEmitter<DialogActivity>();

	constructor(
		private matDialog: MatDialog,
		private layout: LayoutService,
	) {
	}

	/**
	 *  Create and show a custom dialog.
	 * @param component The component to show in the dialog. It should @Inject(MAT_DIALOG_DATA) private dialog: DialogContext<D>
	 * @param reqW The requested width of the dialog. It may be different depending on the screen size
	 * @param reqH The requested height of the dialog. It may be different depending on the screen size
	 */
	async showCustom<C, D>(component: ComponentType<C>, data: DialogData<D>, reqW: number, reqH: number): Promise<DialogAction<any>> {

		return this.dialogActivityResolver<C, D>(component, data, reqW, reqH);
	}



	/**
	 *  Create and show a message dialog with one button to close it.
	 * @param message The displayed text, which can be a literal string or a LabelKey if it needs to be translated.
	 * @param reqW the requested width of the dialog. It may be different depending on the screen size. Default: 400px
	 * @param reqH the requested height of the dialog. It may be different depending on the screen size. Default: 300px
	 * @param buttonText The text on the button text, which can be a literal string or a LabelKey if it needs to be translated.
	 */
	async showMessage(
		message: string | LabelKey,
		reqW = 400,
		reqH = 300,
		buttonText: string | LabelKey = { key: 'Okay' },
		bullets: string[] = [],
	): Promise<DialogAction> {
		if (bullets.length) reqH += (bullets.length) * 21 + 35;	// Extra for the margins on top and bottom of the ul element

		const data: DialogData<{ messageOrLabelKey: string | LabelKey, buttonText: string | LabelKey, bullets: string[] }> = {
			data: { messageOrLabelKey: message, buttonText, bullets },
		};

		return this.dialogActivityResolver<MessageDialog, { messageOrLabelKey: string | LabelKey, buttonText: string | LabelKey }>(MessageDialog, data, reqW, reqH);
	}



	/**
	 * *******DEPRECATED********
	 * Open a simple notes viewing/editing dialog.
	 * IMPORTANT: This is not related to Entity Notes.
	 * 
	 * @param notes The notes to display.
	 * @param updateCallback A callback function used if the notes are changed.
	 * @returns 
	 */
	async showNotes(
		notes: string,
		updateCallback: (notes: string) => Promise<void>,
	): Promise<DialogAction> {

		const action = await this.showCustom(NotesDialog, {
			data: { notes, updateCallback }
		}, 300, 250);

		return action;
	}



	/**
	 *  Create and show a message dialog asking the user to select yes or no.
	 * @param message The displayed text, which can be a literal string or a LabelKey if it needs to be translated.
	 * @param reqW the requested width of the dialog. It may be different depending on the screen size. Default: 400px
	 * @param reqH the requested height of the dialog. It may be different depending on the screen size. Default: 300px
	 * @param yesButtonText The text for the Yes button, which can be a literal string or a LabelKey if it needs to be translated.
	 * @param noButtonText The text for the No button, which can be a literal string or a LabelKey if it needs to be translated.
	 * @param bullets An array of strings to display as bullet items underneath the message. The requested height will be adjusted with an extra 21px for each bullet to try and make the variable number bullets visible.
	 */
	async confirm(
		message: string | LabelKey,
		reqW = 400,
		reqH = 300,
		yesButtonText: string | LabelKey = { key: 'Yes' },
		noButtonText: string | LabelKey = { key: 'No' },
		bullets: string[] = [],
	): Promise<boolean> {

		if (bullets.length) reqH += (bullets.length) * 21 + 35;	// Extra for the margins on top and bottom of the ul element

		const data: DialogData<{ messageOrLabelKey: string | LabelKey, yesButtonText: string | LabelKey, noButtonText: string | LabelKey, bullets: string[] }> = {
			data: { messageOrLabelKey: message, yesButtonText, noButtonText, bullets },
		};

		const activity = await this.dialogActivityResolver<ConfirmationDialog, { messageOrLabelKey: string | LabelKey, yesButtonText: string | LabelKey, noButtonText: string | LabelKey, bullets: string[] }>(ConfirmationDialog, data, reqW, reqH);
		return activity?.id == 'yes';
	}


	private openDialog<C, D>(component: ComponentType<C>, data: DialogData<D>, reqW: number, reqH: number) {

		const [width, height] = this.fitToScreen(reqW, reqH, this.layout.dimensions$.value);

		this.layout.currentDialogSize = { width, height };

		const context: DialogContext<D> = { ...data, width, height };

		const config: MatDialogConfig<DialogData<D>> = {
			panelClass: 'full-width-mat-dialog',
			width: `${width}px`,
			height: `${height}px`,
			data: context,
		}

		return this.matDialog.open(component, config);

	}


	private fitToScreen(w: number, h: number, dimensions: LayoutDimensions) {

		const screenWidth = dimensions.windowWidth - 2 * PADDINGS[dimensions.breakpoint];
		const screenHeight = dimensions.windowHeight - 2 * PADDINGS[dimensions.breakpoint];

		if (w > screenWidth) w = screenWidth;
		if (h > screenHeight) h = screenHeight;

		return [w, h];
	}


	private dialogActivityResolver<C, D>(component: ComponentType<C>, data: DialogData<D>, reqW: number, reqH: number): Promise<DialogAction<any>> {

		return new Promise<DialogAction>(async (resolve, reject) => {
			try {

				const dialogRef = this.openDialog<C, D>(component, data, reqW, reqH);


				if (data.instanceIdentifier) {
					this.activity.emit({
						instanceIdentifier: data.instanceIdentifier,
						dialogClass: component.name,
						activity: 'Open',
						value: data,
					});
				}


				const action: DialogAction = await dialogRef.afterClosed().toPromise();


				if (data.instanceIdentifier) {
					this.activity.emit({
						instanceIdentifier: data.instanceIdentifier,
						dialogClass: component.name,
						activity: 'Close',
						action,
						value: data,
					});
				}

				resolve(action);
			}
			catch (e) {
				reject(e.message);
			}
		});
	}


	/**
	 * Close any open dialogs.
	 */
	closeAll() {
		this.matDialog.closeAll();
	}
}
