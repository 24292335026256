<me-dialog-frame header='Agreement Text' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='info'>

	<div class='agreement-type-dialog' *ngIf="agreementVersionsDropdown">
		<div class="agreement-selection">
			<me-droplist class="views-droplist" [items]='agreementVersionsDropdown'
				[selectedItem]='selectedAgreementVersion' (selectionChange)='onSelectionChange($event)'
				[filterable]='false' [navigation]='true' sortBy='customSort' [groupSortValues]='groupSortValues'
				style='display:inline-block'>
			</me-droplist>
		</div>

		<ng-container *ngIf="selectedAgreementVersion">
			<agreement-text [agreementVersion]="selectedAgreementVersion.data.agreementVersion"
				[agreementType]="selectedAgreementVersion.data.agreementType"
				[languageId]="selectedAgreementVersion.data.languageId"
				[agreementBodyDivHeight]="agreementBodyDivHeight" [readonly]="true"></agreement-text>
		</ng-container>
	</div>
</me-dialog-frame>