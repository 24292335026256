<div class='acc-goals-team-selector-part'>

	<me-droplist [items]='teamDroplistItems' [selectedItem]='teamDroplistSelectedItem' sortBy='text'
		(selectionChange)='teamsDroplistChange($event)'>
	</me-droplist>

	<acc-goals-part *ngIf="teamDroplistSelectedItem" [teamGoalsAndAccess]="teamDroplistSelectedItem.data"
		(editGoal)="editGoal.emit($event)" (addGoal)="addGoal.emit($event)" (addComment)="addComment.emit($event)">
	</acc-goals-part>

</div>