import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { GridColumnType, GridSetup } from '@me-grid';
import { DbsEvent } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DdNearbyVenue, OldDomainDataService } from '@me-services/core/old-dd';
import { ShowEventDialogService } from '../../../event/event-dialog/SHR-ED_show-event-dialog.service';
import { VenueService } from '../../SHR-ED_venue.service';


export interface VenueEventRow extends DbsEvent {
	start: Date,
	eventType: string,
	hostName: string,
}


@Component({
	selector: 'events-venue-part',
	templateUrl: './SHR-ED_events-venue.part.html',
	styleUrls: ['./SHR-ED_events-venue.part.scss']
})
export class VenueEventsPart implements OnChanges {
	@Input() venue: DdNearbyVenue;

	@Output() venueDeleted = new EventEmitter<number>();

	events: DbsEvent[];
	public gridSetup = this.setupGrid();
	public rows: VenueEventRow[];
	showLoadButton = true;

	constructor(
		private venueService: VenueService,
		private ds: DataService,
		private dd: OldDomainDataService,
		private showEventDialogService: ShowEventDialogService,) {
	}


	ngOnChanges() {
		if (!this.venue) throw new Error('Missing required attribute: venue');

		if (this.venue) {
			this.showLoadButton = true;
		}
	}

	async loadEvents() {
		this.events = await this.venueService.getEvents(this.venue.venueId);
		this.showLoadButton = false;
		if (this.events && this.events.length) this.buildRows();
	}

	private async buildRows() {

		const people = await this.ds.admin.person.getManyPackagesAsMap(this.events.map(event => event.hostPersonId));

		this.rows = this.events.map((e, i) => {
			return {
				...e,
				start: new Date(e.startUTC * 1000),
				hostName: people[e.hostPersonId]?.asSingleton._name,
				eventType: this.dd.eventTypes.getById(e.eventTypeId)?.name
			};
		});
	}

	private setupGrid(): GridSetup<VenueEventRow> {

		return {
			experience: 'EVENT',
			size: {
				fitTo: 'INLINE',
				height: 250,
				shrinkBy: 0,
			},
			rowSingularName: 'Event',
			rowPluralName: 'Events',
			rowKey: "eventId",
			stateKey: "events-venue-part-grid",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: 'EVENT_status', header: 'Status', width: 100, type: GridColumnType.text, hidden: false },
				{ field: 'EVENT_toolStartUTC', header: 'Tool Start', width: 150, type: GridColumnType.dateAndTimeUtc, hidden: true },
				{ field: 'EVENT_toolEndUTC', header: 'Tool End', width: 150, type: GridColumnType.dateAndTimeUtc, hidden: true },
				{ field: 'EVENT_venueName', header: 'Venue', width: 250, type: GridColumnType.text, hidden: true },
			],
		};
	}



	async deleteVenue() {
		await this.venueService.delete(this.venue.venueId);
		this.venueDeleted.emit(this.venue.venueId);
	}
}