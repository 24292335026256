<mat-accordion class='simple-accordion' [multi]='true'>
	<mat-expansion-panel *ngFor='let prefixGroup of tagsGroupedByPrefix' [expanded]='expanded'>
		<mat-expansion-panel-header>
			<mat-panel-title>
				<div>{{ prefixGroup.prefix.toUpperCase() }}</div>
			</mat-panel-title>
		</mat-expansion-panel-header>
		<message-line *ngIf='!prefixGroup.tags.length' alertType='error' [closeable]='false'>No
			{{prefixGroup.prefix}} tags
		</message-line>
		<me-tag *ngFor='let tag of prefixGroup.tags' [tag]='tag' [clickable]='inDialog' [showPrefix]='false' (click)='navigateToTagSet(tag)'></me-tag>
	</mat-expansion-panel>
</mat-accordion>