import { DbsEvent, EventTypeId, PicStageId, PitchContest } from "@me-interfaces";
import { getEventStatus } from "./get-event-status";

const PicToolAccess = {
	ReaderTool: [PicStageId.Reading],
	PitchContestJudgeTool: [PicStageId.Contest],
};


/**
 * Calculate the Pitch contest link of the tool and the status(enabled) based on the event type, event status and the accStage.
 * Returns enabled status: boolean and the link for the tool.
 */
export function getPicToolEnabledAndLink(event: DbsEvent, pitchContest: PitchContest): { enabled: boolean, link: string } {
	const eventStatus = getEventStatus(event);
	let link = '';
	let enabled = false;
	const siteCode = pitchContest.siteProgram.site.code.toLowerCase();

	if (event.eventTypeId == EventTypeId.PicContest) {
		link = `/access/sites/${siteCode}/pitch-contests/${pitchContest.picId}/judge`;
		if (PicToolAccess.PitchContestJudgeTool.includes(pitchContest.picStageId)) {
			enabled = true;
		}
	}

	else if (event.eventTypeId == EventTypeId.PicReading) {
		link = `/access/sites/${siteCode}/pitch-contests/${pitchContest.picId}/read`;
		if (PicToolAccess.ReaderTool.includes(pitchContest.picStageId)) {
			enabled = true;
		}
	}

	return { enabled: eventStatus == 'Underway' && enabled, link };
}