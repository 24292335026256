<div class='acc-cohort-view-part' *ngIf="viewDroplistItems.length">

	<ng-container *ngIf='selectedViewItem$ | async; let selectedViewItem'>
		<me-droplist class="views-droplist" [items]='viewDroplistItems' [selectedItem]='selectedViewItem'
			(selectionChange)='onViewDroplistChange($event)' [filterable]='false' [navigation]='true'
			sortBy='customSort'
			[groupSortValues]='{"Teams":1, "Curriculum":2, "Final Presentations":3, "Showcase":4, "Quarter 2":5, "Quarter 3":6, "Quarter 4":7 }'
			style='display:inline-block'>
		</me-droplist>

		<div [style.overflow-y]="getOverflowY(selectedViewItem)" [style.height.px]='height'>

			<acc-cohort-teams-view-part *ngIf="showView('Teams', selectedViewItem)">
			</acc-cohort-teams-view-part>

			<acc-cohort-matched-mentors-view-part *ngIf="showView('MatchedMentors', selectedViewItem)">
			</acc-cohort-matched-mentors-view-part>

			<acc-cohort-goals-and-progress-view-part *ngIf="showView('GoalsAndProgress', selectedViewItem)">
			</acc-cohort-goals-and-progress-view-part>

			<!-- Curriculum -->
			<acc-curriculum-topics-view-part *ngIf="showView('CurriculumTopics', selectedViewItem)">
			</acc-curriculum-topics-view-part>

			<acc-curriculum-topic-specialists-view-part
				*ngIf="showView('CurriculumTopicSpecialists', selectedViewItem)">
			</acc-curriculum-topic-specialists-view-part>

			<acc-curriculum-sessions-view-part *ngIf="showView('CurriculumSessions', selectedViewItem)">
			</acc-curriculum-sessions-view-part>

			<acc-curriculum-surveys-by-session-view-part
				*ngIf="showView('CurriculumSurveysBySession', selectedViewItem)">
			</acc-curriculum-surveys-by-session-view-part>

			<acc-curriculum-surveys-by-topic-view-part *ngIf="showView('CurriculumSurveysByTopic', selectedViewItem)">
			</acc-curriculum-surveys-by-topic-view-part>

			<acc-curriculum-surveys-by-specialist-view-part
				*ngIf="showView('CurriculumSurveysBySpecialist', selectedViewItem)">
			</acc-curriculum-surveys-by-specialist-view-part>

			<!-- Entrepreneurs -->
			<acc-cohort-entrepreneurs-view-part *ngIf="showView('Entrepreneurs', selectedViewItem)">
			</acc-cohort-entrepreneurs-view-part>

			<!-- Final Presentations -->
			<acc-final-pitch-assessments-view-part *ngIf="showView('FinalPitchAssessments', selectedViewItem)">
			</acc-final-pitch-assessments-view-part>

			<acc-final-pitch-teams-view-part *ngIf="showView('FinalPitchTeams', selectedViewItem)">
			</acc-final-pitch-teams-view-part>

			<acc-final-pitch-judges-view-part *ngIf="showView('FinalPitchJudges', selectedViewItem)">
			</acc-final-pitch-judges-view-part>

			<acc-final-pitch-editor-view-part *ngIf="showView('FinalPitchEditor', selectedViewItem)">
			</acc-final-pitch-editor-view-part>

			<acc-final-pitch-events-view-part *ngIf="showView('FinalPitchEvents', selectedViewItem)">
			</acc-final-pitch-events-view-part>

			<acc-final-pitch-feedback-view-part *ngIf="showView('FinalPitchFeedback', selectedViewItem)">
			</acc-final-pitch-feedback-view-part>


			<!-- showcase -->
			<acc-cohort-team-participation-view-part
				*ngIf="showView('TeamParticipation', selectedViewItem)"></acc-cohort-team-participation-view-part>

			<acc-cohort-decide-awards-view-part
				*ngIf="showView('DecideAwards', selectedViewItem)"></acc-cohort-decide-awards-view-part>

			<acc-showcase-event-and-awards-view-part *ngIf="showView('ShowcaseEvent', selectedViewItem)"
				[readonly]="readonly">
			</acc-showcase-event-and-awards-view-part>


			<!-- q2 -->
			<acc-q2-event-and-awards-view-part *ngIf="showView('Q2Event', selectedViewItem)" [readonly]="readonly">
			</acc-q2-event-and-awards-view-part>


			<!-- q3 -->
			<acc-q3-event-and-awards-view-part *ngIf="showView('Q3Event', selectedViewItem)" [readonly]="readonly">
			</acc-q3-event-and-awards-view-part>


			<!-- q4 -->
			<acc-q4-event-and-awards-view-part *ngIf="showView('Q4Event', selectedViewItem)" [readonly]="readonly">
			</acc-q4-event-and-awards-view-part>
		</div>
	</ng-container>
</div>
