import { Accelerator, AccTeam, DbsAccTeam, DbsAccTeamMember, DbsAward, DbsEntityNote, DbsPerson, NoteCategoryId } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { AcceleratorPackageManager } from "./accelerator";
import { ApplicationPackageManager } from "./application";
import { CompanyPackageManager } from "./company";


export class AccTeamPackageManager extends PackageManager<DbsAccTeam, AccTeam> {


	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsAccTeam>,
		private domain: DomainDataManagers,
		private person: SingletonsManager<DbsPerson>,
		private company: CompanyPackageManager,
		private application: ApplicationPackageManager,
		private accelerator: AcceleratorPackageManager,
		private accTeamMember: SingletonsManager<DbsAccTeamMember>,
		private award: SingletonsManager<DbsAward>,
		private note: SingletonsManager<DbsEntityNote>,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}

	/**
	 * Convert an array of DbcAccTeam to an array of AccTeam
	 */
	protected async _createPackages(dbsAccTeams: DbsAccTeam[]): Promise<AccTeam[]> {

		//
		// Get all the people
		//
		const accIds: number[] = [];
		const applicationIds: number[] = [];
		const personIds: number[] = [];
		const accTeamMembers = await this.accTeamMember.getAllAsArray();
		const accTeamMembersPersonIds = accTeamMembers.map(member => member.personId);
		const entityIds: number[] = [];

		const allNotes = await this.note.getAllAsArray();


		for (const accTeam of dbsAccTeams) {
			accIds.push(accTeam.accId);
			applicationIds.push(accTeam.applicationId);
			personIds.push(accTeam.updatedByPersonId);
		}

		const personMap = await this.person.getManyAsMap([...personIds, ...accTeamMembersPersonIds]);
		const accMap = await this.accelerator.getManyPackagesAsMap(accIds);
		const applicationMap = await this.application.getManyPackagesAsMap(applicationIds);
		const companiesMap = await this.company.getManyPackagesAsMap(
			(await this.application.getManyAsArray(applicationIds)).map(a => a.companyId)
		);

		const awardsArray = await this.award.getAllAsArray();

		for (const company in companiesMap) {
			entityIds.push(companiesMap[company].entityId);
		}

		const entrepreneurPersonIds = accTeamMembers.filter(member => member.role === "E").map(member => member.personId);
		for (const personId of entrepreneurPersonIds) {
			entityIds.push(personMap[personId].entityId);
		}

		const redFlagNoteEntityIds = allNotes
			.filter(n => n.noteCategoryId == NoteCategoryId.RedFlag)
			.filter(n => entityIds.includes(n.entityId))
			.map(n => n.entityId);

		//
		// Package 'em up
		//
		const accTeams: AccTeam[] = dbsAccTeams.map(accTeam => {

			const accelerator = accMap[accTeam.accId];
			const application = applicationMap[accTeam.applicationId];
			const company = companiesMap[application.companyId];
			const updatedByPerson = personMap[accTeam.updatedByPersonId];
			const members = accTeamMembers.filter(member => member.accTeamId == accTeam.accTeamId).map(m => ({ member: m, person: personMap[m.personId] }));
			const awards = [...awardsArray].filter(award => award.accTeamId && award.accTeamId == accTeam.accTeamId).map(award => ({ awardId: award.awardId, value: award.value }));
			const awardedValue = awards.reduce((a, b) => { a += b.value; return a; }, 0);
			const companyAndTeamEntityIds = [...members.filter(m => m.member.role === 'E').map(member => member.person.entityId), company.entityId];
			let hasRedFlag = false;

			for (const entityId of companyAndTeamEntityIds) {
				if (redFlagNoteEntityIds.includes(entityId)) {
					hasRedFlag = true;
					break;
				}
			}

			const schedule = [
				accTeam.mon & ~accelerator.accMeetingTimes.mon,
				accTeam.tue & ~accelerator.accMeetingTimes.tue,
				accTeam.wed & ~accelerator.accMeetingTimes.wed,
				accTeam.thur & ~accelerator.accMeetingTimes.thur,
				accTeam.fri & ~accelerator.accMeetingTimes.fri,
				accTeam.sat & ~accelerator.accMeetingTimes.sat,
				accTeam.sun & ~accelerator.accMeetingTimes.sun,
			];

			return {
				...accTeam,
				id: accTeam.accTeamId,
				name: application.company._name,
				explorerName: application.company._name,
				accelerator,
				application,
				company,
				updatedByPersonName: updatedByPerson?._name || `Person #${accTeam.updatedByPersonId}`,
				members,
				awards,
				awardedValue,
				appUrl: this.createAppUrl(accTeam, accelerator),
				hasRedFlag,
				schedule,
			};
		});

		return accTeams;
	}


	private createAppUrl(accTeam: DbsAccTeam, acc: Accelerator): string {
		const siteId = acc.siteId;
		const accId = acc.accId;
		const accTeamId = accTeam.accTeamId;
		return `/access/admin/national/sites/${siteId}/accelerators/${accId}/cohort/${accTeamId}`;
	}
	

	/**
	 * Get all teams for each accId provided
	 */
	public async getByAccIds(accIds: readonly number[]): Promise<Readonly<Record<number, ReadonlyArray<AccTeam>>>> {

		return await this.getPackagesAsArraysByForeignIds('accId', accIds);
	}


	/**
	 * Get all teams for each accId provided
	 */
	public async getByApplicationIds(applicationIds: readonly number[]): Promise<Readonly<Record<number, ReadonlyArray<AccTeam>>>> {

		return await this.getPackagesAsArraysByForeignIds('applicationId', applicationIds);
	}

}