import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@me-environment';
import { LanguageId } from '@me-interfaces';
import { LabelsService } from '@me-services/ui/labels';
import { UrlService } from '@me-services/ui/url';

@Component({
	selector: 'program-lang-jump',
	templateUrl: './SHR-CMN_program-lang-jump.part.html',
	styleUrls: ['SHR-CMN_program-lang-jump.part.scss'],
})
export class ProgramLangJumpPart {

	@Input() languageId: LanguageId;

	env: string;

	constructor(private router: Router, public labels: LabelsService, private urlService: UrlService) {
		this.env = environment.name;
	}


	getWarning() {
		if (this.languageId == LanguageId.Spanish) {
			return 'Program Conducted in Spanish.';
		}
		if (this.languageId == LanguageId.English) {
			return 'Program Conducted in English.';
		}
	}

	getJumpToLink() {
		const baseURL = this.urlService.getBaseUrl(this.languageId);
		return `${baseURL}/${this.router.url}`;
	}

	getJumpToText() {
		if (this.languageId == LanguageId.Spanish) {
			return 'Go to Spanish';
		}
		if (this.languageId == LanguageId.English) {
			return 'Go to English';
		}
	}

	getTarget() {
		return this.urlService.getBaseUrl(this.languageId);
	}
}
