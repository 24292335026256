import { AccAreaService, MMHeatmapDialog, median } from '@ACC-area';
import { SiteAreaService } from '@SITE-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccAreaAccess, AccMatchableMentor, AccMatchingRollups, AccTeam, Accelerator, CaptionedLayout, DbaAccMatchAssessment, SevenNums } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { FuncService } from '@me-services/core/func';
import { DialogData, DialogService } from '@me-services/ui/dialog';
import { DAY_NAMES, MAX_SLOT, SLOT_NAMES, slotsToMeetingStarts } from '@me-services/ui/schedule-utils';
import { GroupResult, groupBy } from '@progress/kendo-data-query';


interface SelectionData {
	captionedLayout: CaptionedLayout,
	type: 'Mentor' | 'Team',
	accTeamId?: number,
	personId?: number,
	rollup: AccMatchingRollups,
	schedule: SevenNums,
	hasDefaultSchedule: boolean,
	text: string,
	id: number,
}

type SlotData = {
	user: boolean,
	others: {
		personId?: number,
		accTeamId?: number,
		name: string,
		companyName?: string,
		given: number,
		received: number,
		hasDefaultSchedule: boolean,
		schedule: SevenNums,
	}[],
	blockout: boolean,
};

interface HeatMapData {
	mentorAndTeamList: GroupResult[] | SelectionData[];
	meetingTimes: [number, number, number, number, number, number, number,],
	assessmentsMap: { [key: string]: [number | undefined, number | undefined] }
	mentors: {
		mentor: AccMatchableMentor,
		rollup: AccMatchingRollups,
	}[],
	teams: {
		team: AccTeam,
		rollup: AccMatchingRollups,
	}[],
}

const DEFAULT_DATA: SelectionData = { captionedLayout: undefined, text: '', type: undefined, hasDefaultSchedule: false, rollup: undefined, schedule: undefined, id: undefined };


@Component({
	selector: 'acc-mm-schedule-heatmap-view-part',
	templateUrl: './acc-mm-schedule-heatmap-view.part.html',
	styleUrls: ['./acc-mm-schedule-heatmap-view.part.scss'],
})
export class AccMMScheduleHeatmapViewPart extends DestroyablePart implements OnInit {

	dayNames = [...DAY_NAMES];
	slotNames = [...SLOT_NAMES];
	meetings: SlotData[][];
	list: {
		num: number,
		name: string,
		companyName?: string,
		given: number,
		received: number,
		totalMeetings: number,
	}[] = [];

	data: HeatMapData;
	backColors: string[] = [];

	assessmentsGiven: { text: string, value: number }[] = [{ text: 'Yes!', value: 4 }, { text: 'Yes', value: 3 }, { text: 'No', value: 2 }, { text: 'No!', value: 1 }, { text: 'None', value: 0 },];
	assessmentsReceived: { text: string, value: number }[] = [{ text: 'Yes!', value: 4 }, { text: 'Yes', value: 3 }, { text: 'No', value: 2 }, { text: 'No!', value: 1 }, { text: 'None', value: 0 },];

	selectedAssessmentsGiven: number[] = [4, 3, 0];
	selectedAssessmentsReceived: number[] = [4, 3, 0];

	selectedMentorOrTeam: SelectionData;
	defaultData: SelectionData = DEFAULT_DATA;
	oppositeRole = '';
	medianMeetings = 0;

	readonly: boolean;

	constructor(
		private siteAreaService: SiteAreaService,
		public accAreaService: AccAreaService,
		public func: FuncService,
		public ds: DataService,
		public dialogService: DialogService,
	) {
		super();
	}


	ngOnInit() {
		super.initDestroyable();

		this.slotNames.pop(); // remove the last two slots, because 90 mins meetings
		this.slotNames.pop();

		super.subscribe(
			[
				this.accAreaService.access$,
				this.accAreaService.accelerator.acc$,
				this.accAreaService.mentorMatching.matchableMentors$,
				this.accAreaService.mentorMatching.mentorsRollup$,
				this.accAreaService.mentorMatching.teamsRollup$,
				this.accAreaService.mentorMatching.assessments$,
				this.accAreaService.teams.teams$,
			],
			async ([access, acc, mentors, mentorsRollup, teamsRollup, assessment, teams]) => this.buildData(access, acc, mentors, mentorsRollup, teamsRollup, assessment, teams)
		);

		this.initializeMeetings();

	}

	async buildData(
		access: AccAreaAccess,
		acc: Accelerator,
		mentors: AccMatchableMentor[],
		mentorRollups: { [key: number]: AccMatchingRollups; },
		teamRollups: { [key: number]: AccMatchingRollups; },
		assessments: readonly DbaAccMatchAssessment[],
		teams: readonly AccTeam[]
	) {
		this.readonly = access?.root == 'Write';
		if (!acc || !mentors || !teams) {
			this.data = undefined;
			return;
		}


		const accMeetingTimes = acc.accMeetingTimes;
		this.data = {
			meetingTimes: [
				accMeetingTimes.mon,
				accMeetingTimes.tue,
				accMeetingTimes.wed,
				accMeetingTimes.thur,
				accMeetingTimes.fri,
				accMeetingTimes.sat,
				accMeetingTimes.sun,
			],
			assessmentsMap: assessments.reduce((map, assessment) => {
				map[`${assessment.personId}-${assessment.accTeamId}`] = [assessment.m2t, assessment.t2m];
				return map;
			}, {}),
			mentors: mentors.map(mentor => ({ mentor, rollup: mentorRollups[mentor.personId] })),
			teams: teams.filter(team => !team.droppedOutWeek).map(team => ({ team, rollup: teamRollups[team.accTeamId] })),
			mentorAndTeamList: [],
		};

		const mentorAndTeamList = [];
		const siteId = this.siteAreaService.getId().id;
		const siteTags = (await this.ds.admin.tag.getAllPackagesAsArray()).filter(tag => tag.site && tag.site.siteId == siteId);
		const coachTagId = siteTags.find(tag => tag.name == 'coach-approved')?.tagId;

		let i = 1;
		for (const mentor of this.data.mentors) {
			mentorAndTeamList.push({
				id: i++,
				captionedLayout: {
					text: `${mentor.mentor.person.fullName}`,
					caption: mentor.mentor.person.tags.find(tag => tag.tagId == coachTagId) ? 'Mentor (c)' : 'Mentor',
					person: mentor.mentor.person.asSingleton
				},
				hasDefaultSchedule: mentor.rollup.hasDefaultSchedule,
				personId: mentor.mentor.personId,
				text: mentor.mentor.person.fullName,
				type: 'Mentor',
				rollup: mentor.rollup,
				schedule: mentor.mentor.schedule,
			});
		}

		for (const team of this.data.teams) {
			mentorAndTeamList.push({
				id: i++,
				captionedLayout: {
					text: `${team.team.application.person._name}`,
					caption: `Team: ${team.team.name}`,
					company: team.team.company
				},
				accTeamId: team.team.accTeamId,
				text: team.team.name,
				type: 'Team',
				rollup: team.rollup,
				schedule: team.team.schedule,
				hasDefaultSchedule: teamRollups[team.team.accTeamId].hasDefaultSchedule,
			});
		}

		mentorAndTeamList.sort((a, b) => `${a.type}-${a.text}` > `${b.type}-${b.text}` ? 1 : -1);

		this.data.mentorAndTeamList = groupBy(mentorAndTeamList, [
			{ field: "type" },
		]);
	}



	initializeMeetings() {
		this.meetings = [];
		this.list = [];
		for (let d = 0; d < DAY_NAMES.length; d++) {
			const slots = [];
			const m = MAX_SLOT - 2;

			this.meetings.push(slots);
			for (let s = 0; s < m; s++) {
				let blockout = false;
				if (this.data) {
					blockout = !!(2 ** s & this.data.meetingTimes[d]);
					if (blockout == false && s < m - 1) blockout = !!(2 ** (s + 1) & this.data.meetingTimes[d]);
					if (blockout == false && s < m - 2) blockout = !!(2 ** (s + 2) & this.data.meetingTimes[d]);
				}

				slots.push({
					user: false,
					others: [],
					blockout,
				});  // push an empty SlotData for each slot
			}
		}
	}


	public handleSelection(e: SelectionData) {
		this.selectedMentorOrTeam = e.id ? e : undefined;
		if (this.selectedMentorOrTeam) {
			this.calcBackColors();
			this.calcMeetings();
			this.overlapNames(this.selectedMentorOrTeam);
		}
		else this.list = [];
	}



	/**
	 * This function is called when assessments selection changes
	 */
	assessmentsChange(e: number[], type: 'g' | 'r') {
		if (type == 'g') this.selectedAssessmentsGiven = e;
		if (type == 'r') this.selectedAssessmentsReceived = e;
		if (this.selectedMentorOrTeam) {
			this.calcMeetings();
		}
	}


	hex2(value: number): string {
		return ('00' + Math.floor(value).toString(16)).substr(-2);
	}


	calcBackColors() {
		//
		// Get the range of white-to-green gradient
		//
		let length = this.data.teams.length;
		if (this.selectedMentorOrTeam.accTeamId) length = this.data.mentors.length;
		if (length > 7) {
			if (length > 21) length = Math.ceil(length / 3);
			else length = 7;
		}

		const GREEN = [48, 240, 48];

		this.backColors = [];
		this.backColors.push('#fff');
		for (let len = 0; len < length; len++) {
			const pct = 1 - ((len + 1) / length);
			this.backColors.push('#' +
				this.hex2(GREEN[0] + (255 - GREEN[0]) * pct) +
				this.hex2(GREEN[1] + (255 - GREEN[1]) * pct) +
				this.hex2(GREEN[2] + (255 - GREEN[2]) * pct)
			);
		}
	}


	calcMeetings() {
		this.initializeMeetings();
		if (this.selectedMentorOrTeam == undefined) return;

		let sched: SevenNums = [0, 0, 0, 0, 0, 0, 0];
		if (!this.selectedMentorOrTeam.hasDefaultSchedule) sched = this.selectedMentorOrTeam.schedule;

		this.calcUserMeetings(sched);

		const assmMap = this.data.assessmentsMap;

		if (this.selectedMentorOrTeam.accTeamId) {
			this.calcOthersMeetings(sched,
				this.data.mentors.filter(m => {
					const assm = assmMap[`${m.mentor.personId}-${this.selectedMentorOrTeam.accTeamId}`] || [undefined, undefined];
					return this.selectedAssessmentsGiven.includes(assm[1] || 0) && this.selectedAssessmentsReceived.includes(assm[0] || 0);
				}).map(mentor => {
					const assm = assmMap[`${mentor.mentor.personId}-${this.selectedMentorOrTeam.accTeamId}`] || [undefined, undefined];
					return {
						given: assm[1] || 0,
						received: assm[0] || 0,
						name: mentor.mentor.person.fullName,
						personId: mentor.mentor.personId,
						hasDefaultSchedule: mentor.rollup.hasDefaultSchedule,
						schedule: mentor.mentor.schedule,
					};
				}));
		}
		else {
			this.calcOthersMeetings(sched, this.data.teams.filter(t => {
				const assm = assmMap[`${this.selectedMentorOrTeam.personId}-${t.team.accTeamId}`] || [undefined, undefined];
				return this.selectedAssessmentsGiven.includes(assm[0] || 0) && this.selectedAssessmentsReceived.includes(assm[1] || 0)
			}).map(team => {
				const assm = assmMap[`${this.selectedMentorOrTeam.personId}-${team.team.accTeamId}`] || [undefined, undefined];
				return {
					given: assm[0] || 0,
					received: assm[1] || 0,
					name: team.team.application.person._name,
					companyName: team.team.name,
					accTeamId: team.team.accTeamId,
					hasDefaultSchedule: team.rollup.hasDefaultSchedule,
					schedule: <SevenNums>team.team.schedule,
				}
			}));
		}

		this.getOtherPersonCounts();
	}


	getOtherPersonCounts() {
		const selectionIsTeam = !!this.selectedMentorOrTeam.accTeamId;
		const countMap = {};

		for (let d = 0; d < DAY_NAMES.length; d++) {
			this.meetings[d].reduce((countMap, slotData) => {
				if (slotData.user) {
					for (const other of slotData.others) {
						const id = selectionIsTeam ? other.personId : other.accTeamId;
						countMap[id] = countMap[id] || 0;
						countMap[id]++;
					}
				}
				return countMap;
			}, countMap);
		}


		this.list = [];
		let i = 0;
		const asmMap = this.data.assessmentsMap;

		if (selectionIsTeam) {
			for (const mentor of this.data.mentors) {
				const count = countMap[mentor.mentor.personId] || 0;
				if (count) {
					const asm = asmMap[`${mentor.mentor.personId}-${this.selectedMentorOrTeam.accTeamId}`];
					this.list.push({
						num: ++i,
						name: mentor.mentor.person.fullName,
						totalMeetings: count,
						given: asm ? asm[1] : undefined,
						received: asm ? asm[0] : undefined,
					});
				}
			}
		}
		else {
			for (const team of this.data.teams) {
				const count = countMap[team.team.accTeamId] || 0;
				if (count) {
					const asm = asmMap[`${this.selectedMentorOrTeam.personId}-${team.team.accTeamId}`];
					this.list.push({
						num: ++i,
						name: team.team.application.person._name,
						companyName: team.team.name,
						totalMeetings: count,
						given: asm ? asm[0] : undefined,
						received: asm ? asm[1] : undefined,
					});
				}
			}
		}

		this.medianMeetings = median(this.list.map(o => o.totalMeetings));
	}


	calcUserMeetings(sched: SevenNums) {
		for (let d = 0; d < DAY_NAMES.length; d++) {

			const ms = slotsToMeetingStarts(sched[d]);

			for (let s = 0; s < MAX_SLOT - 2; s++) {
				this.meetings[d][s].user = !!(ms & 2 ** s);
			}
		}
	}


	calcOthersMeetings(sched: SevenNums, others: {
		personId?: number,
		accTeamId?: number,
		name: string,
		companyName?: string,
		given: number,
		received: number,
		hasDefaultSchedule: boolean,
		schedule: SevenNums,
	}[]) {
		for (let d = 0; d < DAY_NAMES.length; d++) {

			for (const other of others) {
				if (other.hasDefaultSchedule) continue;

				const ms = slotsToMeetingStarts(other.schedule[d]);

				for (let s = 0; s < MAX_SLOT - 2; s++) {
					if (ms & 2 ** s) this.meetings[d][s].others.push(other);
				}
			}
		}
	}


	getSlotClass(day: number, slot: number) {
		const meeting = this.meetings[day][slot];
		const classes = ['slot'];
		if (meeting.user) classes.push('user-meeting');
		if (meeting.blockout) classes.push('blockout');
		if (meeting.others.length == 0) classes.push('zero-meetings');
		return classes.join(' ');
	}


	getSlotBackColor(day: number, slot: number) {
		const meeting = this.meetings[day][slot];
		if (meeting.blockout || !meeting.user) return 'transparent';
		const index = Math.min(meeting.others.length, this.backColors.length - 1);
		return this.backColors[index];
	}


	async openDialog(day: number, slot: number) {

		const data: DialogData<{ selection: any, meetings: SlotData[][], assessmentMap: any, day: number, slot: number }> = {
			data: {
				selection: this.selectedMentorOrTeam, meetings: this.meetings, assessmentMap: this.data.assessmentsMap, day: day, slot: slot
			}
		};

		await this.dialogService.showCustom(MMHeatmapDialog, data, 500, 500);

	}


	// getOverlap(sched: SevenNums, tm: any[]): any[] {

	// 	let results: any[] = [];
	// 	for (let sc of tm) {
	// 		if (sc.schedule && !sc.hasDefaultSchedule) {
	// 			let c = 0;
	// 			for (let d = 0; d < 7; d++) {
	// 				c += hammingWeight(slotsToMeetingStarts(sched[d]) & slotsToMeetingStarts(sc.schedule[d]));
	// 			}
	// 			if (c) {
	// 				sc['meetings'] = c;
	// 				results.push(sc);
	// 			}
	// 		}
	// 	}
	// 	return results;
	// }


	overlapNames(data: SelectionData) {

		if (data.accTeamId) {
			this.oppositeRole = 'mentor';
		}
		else {
			this.oppositeRole = 'entrepreneur'
		}
	}

}