<app-area-access [areaService]='accAreaService'>
	<ng-container>
		<div class='acc-overview-page'>
			<me-toolbar icon='Acc'>
				<acc-toolbar-buttons></acc-toolbar-buttons>
			</me-toolbar>

			<page-tabs [tabNames]='["Overview", "User Tools", "Applications"]'
				[pageService]='pageService' (layoutChange)='onLayoutChange($event)'>
				<ng-template pageTab1>
					<overview-views-part [readonly]='readonly'></overview-views-part>
				</ng-template>

				<ng-template pageTab2>
					<acc-user-tools-tab-part></acc-user-tools-tab-part>
				</ng-template>

				<ng-template pageTab3>
					<acc-applications-tab-part></acc-applications-tab-part>
				</ng-template>
			</page-tabs>

		</div>
	</ng-container>
</app-area-access>