export const RecordUtilities = {

  /**
   * Given a record that maps one number, such as an id, to another number, such as a count, add to the value
   */
  addValue: (record: Record<number, number>, id: number, value: number) => {
    if (!record[id]) record[id] = 0;
    record[id] += value;
  },

  /**
   * Given a record that maps one number, such as an id, to another number, such as a count, bump up the value
   */
  incrementValue: (record: Record<number, number>, id: number) => {
    if (!record[id]) record[id] = 0;
    record[id]++;
  },

  /**
   * Given a record that maps one number, such as an id, to another number, such as a count, bump down the value
   */
  decrementValue: (record: Record<number, number>, id: number) => {
    if (!record[id]) record[id] = 0;
    record[id]--;
  },

  /**
   * Given a record that maps one T, such as an id, to an array of Ts, add a new value to the corresponding array
   */
  insertElement: <T = number>(record: Record<number, T[]>, id: number, value: T) => {
    if (!record[id]) record[id] = [];
    record[id].push(value);
  },

  /**
   * Transform a record (map) where each index is a numeric id and each value is an array of T,
   * return a single array of T with all the indexed arrays merged together.
   */
  convertToArray: <T>(record: Record<number, readonly T[]>, ids: readonly number[]) => {
    const t: T[] = [];
    for (const id of ids) {
      t.push(...(record[id] || []));
    }
    return t;
  }
};
