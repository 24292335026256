import { EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { DbConceptName } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { Icon } from "@me-shared-parts/UI-common";
import { buildColumn, GridColumnConfig } from "../columns";
import { CompanyCols } from '../columns/column-names';
import { EXPLORE_GRID_ACTION_KEY, GRID_EXPERIENCE, GridSetup, OPEN_GRID_ACTION_KEY } from "../interfaces";
import { GridExperience } from "./grid-experience";
import { getProgramResultsForCompanies } from "./program-results/get-program-results-for-companies";


/**
 * Standard experience for a grid of companies
 */
export class CompanyGridExperience<ROW> extends GridExperience<ROW> {

	public static readonly experience: GRID_EXPERIENCE = 'COMPANY';

	constructor(
		private ds: DataService,
		private util: UtilityService,
		setup: GridSetup<ROW>,
		gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
		private router: Router,
	) {
		super(
			util, setup,
			[
				buildColumn(CompanyCols.companyId, GridColumnConfig.companyId, { hidden: true }),
				buildColumn(CompanyCols.personId, GridColumnConfig.personId, { hidden: true }),
			],
			[
				buildColumn(CompanyCols.notes, GridColumnConfig.notesIcon),
				buildColumn(CompanyCols.status, GridColumnConfig.companyStatus, { hidden: true }),
				buildColumn(CompanyCols.name, GridColumnConfig.companyName),
				buildColumn(CompanyCols.shortName, GridColumnConfig.companyShortName, { hidden: true }),
				buildColumn(CompanyCols.companyType, GridColumnConfig.companyType, { hidden: true }),

				buildColumn(CompanyCols.contactName, GridColumnConfig.personFullName, { header: 'Contact Name', hidden: true }), // Remove hidden when company.positionId bug fixed
				buildColumn(CompanyCols.contactTitle, GridColumnConfig.personTitle, { header: 'Contact Title', hidden: true }),
				buildColumn(CompanyCols.contactPhone, GridColumnConfig.phone, { header: 'Contact Phone', hidden: true }),
				buildColumn(CompanyCols.contactPhoneType, GridColumnConfig.phoneType, { header: 'Contact Phone Type', hidden: true }),

				buildColumn(CompanyCols.state, GridColumnConfig.stateCode, { hidden: true }),
				buildColumn(CompanyCols.street, GridColumnConfig.street, { hidden: true }),
				buildColumn(CompanyCols.location, GridColumnConfig.location, { hidden: true }), // Remove hidden when company.positionId bug is fixed
				buildColumn(CompanyCols.zipCode, GridColumnConfig.zipCode, { hidden: true }),
				buildColumn(CompanyCols.phone, GridColumnConfig.phone, { hidden: true }),
				buildColumn(CompanyCols.phoneType, GridColumnConfig.phoneType, { hidden: true }),

				buildColumn(CompanyCols.applications, GridColumnConfig.applicationsCount),
				buildColumn(CompanyCols.acc, GridColumnConfig.accActivity),
				buildColumn(CompanyCols.pic, GridColumnConfig.picActivity),
				buildColumn(CompanyCols.programs, GridColumnConfig.programInstances, { hidden: true }),
				buildColumn(CompanyCols.awards, GridColumnConfig.awardsDollars, { header: 'All Awards', width: 120 }),
			],
			[
				buildColumn(CompanyCols.industry, GridColumnConfig.industry),
				buildColumn(CompanyCols.offerings, GridColumnConfig.offerings, { hidden: true }),
				buildColumn(CompanyCols.createdUtc, GridColumnConfig.createdUtc, { hidden: true }),
				buildColumn(CompanyCols.updatedUtc, GridColumnConfig.updatedUtc, { hidden: true }),
			],
			[
				{ key: EXPLORE_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Explore ${setup.rowSingularName}` },
				{ key: OPEN_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Open ${setup.rowSingularName}` },
				// { key: NOTES_GRID_ACTION_KEY, icon: Icon.action_editNotes, label: `Edit Company Notes` },
			],
			gridAction,
			Icon.concept_company,
		);
	}


	public async applyBaseValues(rows: ROW[]) {

		const companyIds = rows.map(row => row['companyId']);
		const companyMap = await this.ds.admin.company.getManyPackagesAsMap(companyIds);
		const zipMap = await this.ds.domain.zip.getAllAsMap();

		const { accResults, picResults } = await getProgramResultsForCompanies(this.util, this.ds, companyIds);

		for (const row of rows) {

			const companyId = row['companyId'];
			const company = companyMap[companyId];
			const position = company.position;
			const contact = company.contact;

			row[CompanyCols.companyId] = company.companyId;
			row[CompanyCols.createdUtc] = company.createdUTC;
			row[CompanyCols.updatedUtc] = company.updatedUTC;

			row[CompanyCols.notes] = company.hasRedFlag ? null : company.noteIds.length;
			row[CompanyCols.status] = company.status;
			row[CompanyCols.name] = company._name;
			row[CompanyCols.shortName] = company.shortName;
			row[CompanyCols.companyType] = company.companyType;

			row[CompanyCols.applications] = company.accApplicationIds.length + company.picApplicationIds.length;
			row[CompanyCols.awards] = company.awardedValue;
			row[CompanyCols.acc] = accResults[companyId];
			row[CompanyCols.pic] = picResults[companyId];

			row[CompanyCols.phone] = company.phone;
			row[CompanyCols.phoneType] = company.phoneType;

			row[CompanyCols.industry] = company.industry;
			row[CompanyCols.offerings] = company.offerings;

			company.positionId

			if (position) {
				row[CompanyCols.personId] = position.personId;
				row[CompanyCols.contactTitle] = position.title;

				if (contact) {
					row[CompanyCols.contactName] = contact.fullName;
					row[CompanyCols.contactPhone] = contact.asSingleton.phone;
					row[CompanyCols.contactPhoneType] = contact.asSingleton.phoneTypeId;
				}
			}


			//
			// Start off assuming a company with no zipCode (international)
			//
			row[CompanyCols.street] = company.address;
			row[CompanyCols.zipCode] = company.zipId;
			row[CompanyCols.state] = null;
			row[CompanyCols.location] = null;

			let zip = zipMap[company.zipId];
			if (zip) {
				//
				// If there is a valid zipcode then we tack on the details
				//
				row[CompanyCols.state] = zip.state;
				row[CompanyCols.location] = zip.cityAndState;
			}
			else if (contact) {
				zip = zipMap[contact.asSingleton.zipId];
				if (zip) {
					//
					// Otherwise, if we have a contact with a zip code then use the full zip and address
					// info for the company. We use the contact street even if there is one on the company
					// because it is the one related to the zip code we are using.
					//
					row[CompanyCols.street] = contact.asSingleton.address;
					row[CompanyCols.zipCode] = contact.asSingleton.zipId;
					row[CompanyCols.state] = zip.state;
					row[CompanyCols.location] = zip.cityAndState;
				}
			}
		}
	}


	public sortRows(rows: ROW[]) {
		rows.sort((row1: ROW, row2: ROW) => {
			const val1 = row1[CompanyCols.name];
			const val2 = row2[CompanyCols.name];
			return val1 < val2 ? -1 : 1;
		});
	}


	public async explore(row: ROW) {
		await this.ds.explorer.explore(DbConceptName.Company, row[CompanyCols.companyId]);
	}


	public async open(row: ROW) {
		await this.router.navigate([`access/contacts/companies/${row[CompanyCols.companyId]}/overview`]);
	}


	public async editNotes(row: ROW) {
		// Notes dialog not implemented
	}
}