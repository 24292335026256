import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

export interface ListboxOption {
	value: string,
	text: string,
}


@Component({
	selector: 'me-listbox',
	templateUrl: './SHR-CMN_listbox.part.html',
	styleUrls: ['SHR-CMN_listbox.part.scss'],
})
export class ListboxPart implements OnChanges {

	@Input() options: ListboxOption[];
	optionsList: ListboxOption[] = [];

	@Input() value: string;
	@Output() valueChange = new EventEmitter<string>();


	ngOnChanges() {
		if (!this.options || !(this.options.length > 0)) {
			this.optionsList = [];
		}
		else this.optionsList = this.options;
	}

	change(e) {

		const select: HTMLSelectElement = e.target;

		let selectedValue: string;

		//
		// Hack around the "multiple" attribute by finding just the first selected.
		// Need a old for loop because old school dom elements don't support iterators.
		//
		for (let i = 0; i < select.options.length; i++) {
			const o = select.options[i];
			if (o.selected) {
				selectedValue = o.value;
			}
		}

		//
		// Unselect all but the first selected one.
		//
		if (selectedValue) {
			for (let i = 0; i < select.options.length; i++) {
				const o = select.options[i];
				o.selected = o.value == selectedValue;
			}
		}

		//
		// Notify any change
		//
		if (this.value !== selectedValue) {
			this.value = selectedValue;
			this.valueChange.emit(selectedValue);
		}

	}

}
