import { SiteAreaData, SiteAreaService, SiteDashboardPageService } from '@SITE-area';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ApplicationCols, GridColumnType, GridSetup, GridValueStyle, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, combineLatest, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

interface ApplicationRow {
	applicationId: number,
	programId: number,
	accId: number,
	picId: number,
	programName: string,
	type: 'Accelerator' | 'Pitch Contest',
	stageName: string,
	language: string,
	updatedUTC: number,
}

@Component({
	selector: 'site-applications-view-part',
	templateUrl: './applications-view.part.html',
})
export class SiteApplicationsViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();

	public rows$: Observable<ApplicationRow[]> = of([]);

	@ViewChild(GridPart) meGrid: GridPart<ApplicationRow>;

	constructor(
		private util: UtilityService,
		public siteAreaService: SiteAreaService,
		public ds: DataService,
		public pageService: SiteDashboardPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		this.rows$ = combineLatest([
			this.ds.admin.singletonsAsOfUTC$,
			this.siteAreaService.data$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1])));

	}


	async buildRows(
		singletonsAsOfUTC: number,
		data: SiteAreaData,
	): Promise<ApplicationRow[]> {

		if (!data) return [];

		const applications = (await this.ds.admin.application.getAllPackagesAsArray()).filter(app => app.siteProgram.siteId && app.siteProgram.siteId == data.site.siteId && (app.accId || app.picId));


		const rows: ApplicationRow[] = applications.map(a => ({
			applicationId: a.applicationId,
			programId: a.siteProgram.programId,
			accId: a.accId,
			picId: a.picId,
			programName: a.accId ? a.accelerator.name : a.picId ? a.pitchContest.name : `No name ${a.applicationId}?`,
			type: a.accId ? 'Accelerator' : 'Pitch Contest',
			stageName: a.accId ? `${a.accelerator.accStageId / 10}:  ${a.accelerator.stage.name}`
				: a.picId ? `${a.pitchContest?.picStageId / 10}:  ${a.pitchContest.stage.name}` : `No stage ${a.applicationId}?`,
			language: a.siteProgram.program.language.name,
			updatedUTC: a.updatedUTC,
		}));
		return rows;
	}


	private valueStyle(row: ApplicationRow, field: string): GridValueStyle {
		const value = row[field];

		if (field == 'type') { // Program
			if (value == 'Accelerator') return { icon: 'far fa-tachometer-alt-fast', textStyle: 'Hidden' };
			if (value == 'Pitch Contest') return { icon: 'far fa-bullhorn', textStyle: 'Hidden' };
		}

		return { icon: 'fal fa-question', iconColor: 'Red', textStyle: 'Hidden' };
	}


	private setupGrid(): GridSetup<ApplicationRow> {
		const valueStyle = this.valueStyle.bind(this);

		return {
			experience: 'APPLICATION',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Application",
			rowPluralName: "Applications",
			rowKey: "applicationId",
			stateKey: "site-applications",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				{ field: "programId", header: "programId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "accId", header: "accId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "picId", header: "picId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "programName", header: "Program Name", width: 150, type: GridColumnType.text },
				{ field: "type", header: "Program", width: 100, type: GridColumnType.text, valueStyle },
				{ field: "stageName", header: "Stage", width: 160, type: GridColumnType.text },
				{ field: "language", header: "Language", width: 100, type: GridColumnType.text },
				{ field: ApplicationCols.status, header: "Application Status", width: 175, type: GridColumnType.text, hidden: false },
			],
			initialState: {
				sort: [{ field: 'APPLICATION_companyName', dir: 'asc' }],
			},
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: ApplicationRow[] }) {

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}
	}

}