<div class='site-explorer-details-part' *ngIf="site">

	<div id="container" class="explorer-details-container">

		<table class='pro-table pro-table-no-header pro-table-with-labels'>
			<tr>
				<td> Site Id</td>
				<td> {{site.siteId}}</td>
			</tr>

			<tr>
				<td> Name</td>
				<td> {{site.name}}</td>
			</tr>

			<tr>
				<td> Code</td>
				<td> {{site.code}}</td>
			</tr>

			<tr>
				<td> Base Name</td>
				<td> {{site.baseName}}</td>
			</tr>

			<tr *ngIf="site.eforallOrgEn || site.eforallOrgEs">
				<td> Links</td>
				<td>
					<link-button *ngIf="site.eforallOrgEn"
						[link]="{ linkType: 'webLink', webLinkTypeId:'1', url: site.eforallOrgEn, overrideText:'eforall.org' }">
					</link-button>
					<link-button *ngIf='site.eforallOrgEs'
						[link]="{ linkType: 'webLink', webLinkTypeId:'1', url: site.eforallOrgEs, overrideText: 'eparatodos.org'}">
					</link-button>
				</td>
			</tr>

			<tr *ngIf="site.emailEn || site.emailEs">
				<td> Email</td>
				<td>
					<link-button *ngIf="site.emailEn" [link]="{ linkType: 'email', email: site.emailEn}">
					</link-button>
					<link-button *ngIf="site.emailEn" [link]="{ linkType: 'email', email: site.emailEs}">
					</link-button>
				</td>
			</tr>

			<tr>
				<td> Address</td>
				<td>
					<link-button
						[link]="{ linkType: 'address', street: site.location, zipId: site.zipId}"></link-button>
				</td>
			</tr>

			<tr>
				<td>Created On</td>
				<td>
					{{util.date.formatUTC(site.createdUTC, 'MMM D, YYYY', 'H:MM AM EST', urlService.languageId)}}
				</td>
			</tr>

			<tr>
				<td>Last Edited By</td>
				<td>
					<explorer-button [button]="{ explorableId: { conceptName: 'person', id: site.updatedByPersonId }}">
					</explorer-button>
					<div class="small-nowrap-text">
						on {{util.date.formatUTC(site.updatedUTC, 'MMM D, YYYY', 'H:MM AM EST',
						urlService.languageId)}}
					</div>
				</td>
			</tr>

		</table>

		<div class="explorer-details-right">
		</div>

	</div>
</div>