import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { Accelerator } from '@me-interfaces';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';

@Component({
	selector: 'acc-team-dropped-out-week-editor-dialog',
	templateUrl: './dropped-out-week-editor.dialog.html',
})
export class AccTeamDroppedOutWeekEditorDialog extends DestroyablePart implements OnInit {

	droppedOutWeek: number;
	orgDroppedOutWeek: number;

	dataOptions = [
		{ value: null, text: 'No' },
	];

	//
	// The action buttons
	//
	saveAction: DialogAction<number> = {
		id: 'save',
		enabled: false,
		visible: true,
		label: ':Save',
		linkType: 'callback',
		callback: async () => (this.droppedOutWeek),
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: ':Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction<number | void>[] = [this.saveAction, this.cancelAction];
	icon = 'fa-pen';
	headerText = 'Edit Dropped Out';
	readonly: boolean;
	acc: Accelerator;


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ readonly: boolean, droppedOutWeek: number | undefined, acc: Accelerator }>,
		public dialogRef: MatDialogRef<AccTeamDroppedOutWeekEditorDialog>,
	) {
		super();
	}


	ngOnInit() {
		super.initDestroyable();

		const { readonly, droppedOutWeek, acc } = this.dialog.data;
		this.orgDroppedOutWeek = droppedOutWeek;
		this.readonly = readonly;
		this.acc = acc;

		for (let i = 1; i <= 52; i++) this.dataOptions.push({ value: i, text: `Week ${i}` });

	}


	/**
	 * This function is called as the user is typing/pasting/deleting into a field
	 */
	selectionChange(e: number) {
		const value = e;
		this.droppedOutWeek = value;

		this.saveAction.enabled = this.droppedOutWeek != this.orgDroppedOutWeek;
	}
}