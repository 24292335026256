import { Component, Input, OnChanges } from '@angular/core';
import { DBLCLICK_GRID_ACTION_KEY, GridColumnType, GridSetup } from '@me-grid';
import { AssociationType, DbsPerson } from '@me-interfaces';
import { OldDomainDataService } from '@me-services/core/old-dd';
import { UrlService } from '@me-services/ui/url';
import { Icon } from '@me-shared-parts/UI-common';


export const ASSOCIATION_TYPE_BY_PROGRAM = {
	ACC: [AssociationType.AccApplicant, AssociationType.AccCohort, AssociationType.AccReader, AssociationType.AccInterviewer, AssociationType.AccMentor, AssociationType.AccJudge],
	PIC: [AssociationType.PicApplicant, AssociationType.PicParticipant, AssociationType.PicReader, AssociationType.PicJudge],
};

interface PersonAssociationRow {
	id: number,
	program: string,
	programType: 'Acc' | 'Pic',
	role: string,
	site: string,
	date: number,
	url: string,
	languageId: number,
}


@Component({
	selector: 'person-extract-associations',
	templateUrl: './SHR-PN_person-extract-associations.part.html',
	styleUrls: ['./SHR-PN_person-extract-associations.part.scss']
})
export class PersonExtractAssociationsPart implements OnChanges {
	@Input() person: DbsPerson;

	public rows: PersonAssociationRow[];
	public gridSetup = this.setupGrid();

	constructor(
		private dd: OldDomainDataService,
		private urlService: UrlService
	) {
	}

	ngOnChanges() {
		if (!this.person) throw new Error('Missing required attribute: person');
		this.rows = this.person._personExtracts?.associations.map((a, i) => {
			let programType = undefined;
			let programInstance = undefined;
			let languageId = undefined;

			if (ASSOCIATION_TYPE_BY_PROGRAM.ACC.includes(a.associationType)) {
				programType = 'ACC';
				const acc = this.dd.accs.getById(a.programInstanceId);
				programInstance = acc.name;
				languageId = acc.languageId;
			}
			else if (ASSOCIATION_TYPE_BY_PROGRAM.PIC.includes(a.associationType)) {
				programType = 'PIC';
				const pic = this.dd.pics.getById(a.programInstanceId);
				programInstance = pic.name;
				languageId = pic.languageId;
			}

			return {
				id: i + 1,
				programType: programType || '',
				program: programInstance || '',
				role: a.role || '',
				site: a.siteCode || '',
				company: a.companyName || '',
				date: a.dateUTC || a.endDateUTC || undefined,
				languageId,
				url: a.url || '',
			}
		}) || [];
	}

	private setupGrid(): GridSetup<PersonAssociationRow> {
		return {
			experience: 'none',
			size: {
				fitTo: 'INLINE',
				height: 350,
				shrinkBy: 0,
			},
			rowSingularName: 'Association',
			rowPluralName: 'Associations',
			rowKey: 'id',
			stateKey: 'person-extracts-association-grid',
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: 'site', header: 'Community', width: 45, type: GridColumnType.text },
				{ field: 'programType', header: 'Type', width: 50, type: GridColumnType.text },
				{ field: 'program', header: 'Program', width: 80, type: GridColumnType.text },
				{ field: 'role', header: 'Role', width: 80, type: GridColumnType.text },
				{ field: 'company', header: 'Company', width: 120, type: GridColumnType.text },
				{ field: 'date', header: 'Date', width: 60, type: GridColumnType.dateUtc },
			],
			actions: [
				{ key: 'open-program', icon: Icon.concept_program, label: 'Open Program' },
			],
		};
	}


	async gridActionHandler(action: { actionKey: string, rows: PersonAssociationRow[] }) {

		if (action.actionKey == DBLCLICK_GRID_ACTION_KEY || action.actionKey == 'open-program') {
			const row = action.rows[0];
			if (row.url) window.open(`${this.urlService.getBaseUrl(row.languageId)}${row.url}`);
		}
	}
}