
//
// export "categorical" folders in alphabetical order
//
//
// export "noun" folders in alphabetical order
// (the folder names should be SINGULAR!)
//
export * from './DB_LENGTH';
export * from './LEGACY';
export * from './accelerator';
export * from './action';
export * from './action-payloads';
export * from './action-responses';
export * from './address';
export * from './alum';
export * from './alum-report';
export * from './app-areas';
export * from './app-session';
export * from './application';
export * from './assessment';
export * from './audit';
export * from './company';
export * from './connection';
export * from './contact';
export * from './db-concepts';
export * from './db-enums';
export * from './db-extracts';
export * from './deep-readonly';
export * from './entry';
export * from './error';
export * from './event';
export * from './export';
export * from './feedback';
export * from './field';
export * from './i18n';
export * from './link';
export * from './person';
export * from './pitch';
export * from './program';
export * from './rating';
export * from './search';
export * from './site';
export * from './survey';
export * from './tag';
export * from './tech';
export * from './time-zone';
export * from './ui';
export * from './user';
export * from './venue';
export * from './volunteer';



//
// Ids that map to the primary keys in the config table
//
export const enum ConfigId {
	/**
	 * The person Id of the current Director of Programs
	 */
	DirectorOfProgramsPersonId = 11,
}