import { AccAreaService, AccCohortPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId, Event, EventContext, EventTypeId, TeamsForAward } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { PageTabsMainTabGridSize } from '@me-shared-parts/UI-common/grid/interfaces/grid-size';
import { Observable, distinctUntilChanged, lastValueFrom, map, take } from 'rxjs';


@Component({
	selector: 'acc-showcase-event-and-awards-view-part',
	templateUrl: './acc-showcase-event-and-awards-view.part.html',
})
export class AccShowcaseEventAndAwardsViewPart extends DestroyablePart implements OnInit {

	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.Curriculum);

	readonly: boolean;

	event$: Observable<Event>;
	eventContext: EventContext;
	teamsForAward: TeamsForAward[] = [];

	eventGridSize: PageTabsMainTabGridSize = {
		fitTo: 'PAGE-TABS-MAIN-TAB',
		heightMultiplier: .4,
		layout$: this.pageService.layout$,
		shrinkBy: 0,
		viewSelector: true,
	};

	awardsGridSize: PageTabsMainTabGridSize = {
		fitTo: 'PAGE-TABS-MAIN-TAB',
		heightMultiplier: .6,
		layout$: this.pageService.layout$,
		shrinkBy: 0,
		viewSelector: true,
	};

	constructor(
		private accAreaService: AccAreaService,
		private util: UtilityService,
		private ds: DataService,
		public pageService: AccCohortPageService,
	) {
		super();
	}

	async ngOnInit(): Promise<void> {
		super.initDestroyable();

		super.subscribe([this.accessAtStage$], async ([accessAtStage]) => {
			this.readonly = accessAtStage.access != 'Write';
		});

		const acc = await lastValueFrom(this.accAreaService.accelerator.acc$.pipe(take(1)));
		const accTeamsByAccId = await this.ds.admin.accTeam.getByAccIds([acc.accId]);
		const accTeams = accTeamsByAccId[acc.accId];

		for (const accTeam of accTeams) {
			const company = accTeam.application.company;
			this.teamsForAward.push({ teamId: accTeam.accTeamId, text: company.longName, type: 'accTeam' });
		}

		this.eventContext = {
			eventTypeId: EventTypeId.AccShowcase,
			languageId: acc.siteProgram.program.languageId,
			accId: acc.accId,
			siteId: acc.siteId,
			siteProgramId: acc.siteProgramId,
		};

		this.event$ = this.accAreaService.accelerator.events$
			.pipe(
				map(events => events.find(e => e.eventTypeId == EventTypeId.AccShowcase)),
				distinctUntilChanged((e1, e2) => this.util.values.areSame(e1, e2))
			);
	}

}