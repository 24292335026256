import { LanguageId } from '@me-interfaces';
import { DdLanguageRow } from './dd-language-row';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';

/**
 * @deprecated Use the DataService instead
 */
export class DdLanguage extends DdObject<DdLanguageRow> implements DdLanguageRow {

	constructor(dd: OldDomainDataService, data: DdLanguageRow) {
		super(data);
	}

	public get languageId(): LanguageId {
		return this._data.languageId;
	}

	public get name(): string {
		return this._data.name;
	}
}