import { AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId, Link } from '@me-interfaces';
import { UrlService } from '@me-services/ui/url';
import { AccPreAcceleratorStageId } from '../../acc-application-status-helpers';


@Component({
	selector: 'acc-match-tab-part',
	templateUrl: './acc-match-tab.part.html',
})
export class AccMatchTabPart extends DestroyablePart implements OnInit {

	accStageId: AccPreAcceleratorStageId = AccStageId.MatchingMentors;
	toolLink: Link;


	constructor(
		public accAreaService: AccAreaService,
		public urlService: UrlService,
	) {
		super();
	}


	ngOnInit(): void {
		super.initDestroyable();
	}

}
