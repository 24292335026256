import { AccApplicationContext, AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ACC_APPLICATION_STATUS } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface CountsData {
	status: string,
	applicationsCount: number,
	personIds: number[],
}


@Component({
	selector: 'acc-applications-counts-part',
	templateUrl: './acc-applications-counts.part.html',
	styleUrls: ['./acc-applications-counts.part.scss'],
})
export class AccApplicationsCountsPart extends DestroyablePart implements OnInit {

	readonly: boolean;
	public countsData$: Observable<CountsData[]>;

	constructor(
		private accAreaService: AccAreaService,
		private ds: DataService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe([this.accAreaService.accessAndId$], async ([access]) => {
			this.readonly = access?.access?.root != "Write";
		});

		this.countsData$ = this.accAreaService.applications.applications$.pipe(mergeMap(data => this.buildData(data)));
	}



	async buildData(
		applications: readonly AccApplicationContext[],
	): Promise<CountsData[]> {

		const countsData: CountsData[] = [];

		applications = [...applications].sort((a, b) => a.application.applicationStatusId > b.application.applicationStatusId ? 1 : -1)

		for (const app of applications) {

			const statusFound = countsData.find(data => data.status == ACC_APPLICATION_STATUS[app.application.applicationStatusId]);

			if (!statusFound) countsData.push({
				status: ACC_APPLICATION_STATUS[app.application.applicationStatusId],
				applicationsCount: 1,
				personIds: [app.application.personId],
			});

			else {
				statusFound.applicationsCount += 1;
				statusFound.personIds.push(app.application.personId);
			}

		}
		return countsData;

	}

	async openContactor(personIds: number[]) {
		await this.ds.contactor.show(
			this.readonly,
			personIds,
			'acc-applications-counts-part',
		);
	}
}