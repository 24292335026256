<div class='entity-address-editor-part'>
	<me-save-changes-message *ngIf='messagedisplay' [hidden]="messagedisplay.disabled"></me-save-changes-message>
	<div class='entity-address-editor-content'>

		<form [formGroup]="form" novalidate>
			<table class='pro-table pro-table-no-header pro-table-with-labels'>
				<tr>
					<td>
						<span title='Lives in The U.S.'>Lives in The U.S.</span>
					</td>
					<td>
						<mat-checkbox [checked]='form.controls.inUS.value' (change)='toggleCheckbox("US")'
							class='potentialCheckbox'></mat-checkbox>
					</td>
				</tr>
				<tr>
					<td>
						<span title='Address'>
							{{form.controls.inUS.value? 'Street Address' : 'Complete Address'}}</span>
					</td>

					<td>
						<textarea kendoTextArea [class.street-address]='form.controls.inUS.value'
							[class.full-address]='!form.controls.inUS.value'
							placeholder="{{form.controls.inUS.value? 'Street Address' : 'Complete Address including Postal Code'}}"
							formControlName="address" (blur)='constants.blur.trim(form.controls.address)'></textarea>
					</td>
				</tr>
				<tr *ngIf='form.controls.inUS.value'>
					<td>
						<span title='Zip Code'>Zip Code</span>
					</td>
					<td>
						<input class="k-textbox" placeholder="Zip Code" formControlName="zipCode" maxlength="5"
							[class.has-error]='!form.controls.zipCode.value'>
						<div *ngIf='!form.controls.zipCode.value' class="k-tooltip-validation">
							<div>
								Required field
							</div>
						</div>
						<div *ngIf='form.controls.zipCode.errors' class="k-tooltip-validation">
							<div *ngIf='form.controls.zipCode.errors.pattern'>#####
							</div>
							<div *ngIf='form.controls.zipCode.errors.badzip'>Invalid ZIP
							</div>
						</div>
						<div fxLayout="row" fxLayoutAlign="end" style="font-size:0.8em" *ngIf='zip.city'>{{zip.city}}</div>
					</td>
				</tr>
			</table>

			<button-container>
				<button class='btn' (click)='resetForm()' [disabled]='hasNoChanges() || updating'>
					Revert </button>
				<spinner-button #messagedisplay (safeClick)='save()'
					[disabled]='form.invalid || updating || disableSave()' [spinning]='updating'>
					Save Changes </spinner-button>
			</button-container>
		</form>

	</div>
</div>