
import { NationalPage } from './pages/national-page/national.page';
import { NationalGetHelpTabPart } from './pages/national-page/tabs/get-help/national-get-help-tab.part';
import { NationalNewsTabPart } from './pages/national-page/tabs/news/national-news-tab.part';
import { NationalViewsPart } from './pages/national-page/views/national-views.part';
import { NationalReportingChartsViewPart } from './pages/national-page/views/reporting/charts/national-reporting-charts-view.part';
import { NationalReportingLookerViewPart } from './pages/national-page/views/reporting/looker/national-reporting-looker-view.part';
import { ProgramPage } from './pages/program-page/program.page';
import { ProgramAgreementSignaturesViewPart } from './pages/program-page/views/agreement-signatures/agreement-signatures-view.part';
import { ProgramAgreementTypesViewPart } from './pages/program-page/views/agreement-types/agreement-types-view.part';
import { ProgramOverviewViewPart } from './pages/program-page/views/overview/program-overview-view.part';
import { AccProblemsViewPart } from './pages/program-page/views/problems/acc-problems/acc-problems-view.part';
import { ProgramViewsPart } from './pages/program-page/views/program-views.part';
import { TrainingMaterialsByLanguagePart } from './pages/program-page/views/training-materials/training-materials-by-language/training-materials-by-language.part';
import { RegionsPage } from './pages/regions-page/regions.page';
import { SitesPage } from './pages/sites-page/sites.page';
import { StaffOverviewPage } from './pages/staff-page/staff-overview.page';
import { StaffCountsTabPart } from './pages/staff-page/tabs/counts/staff-counts-tab.part';
import { StaffAllStaffViewPart } from './pages/staff-page/views/all-staff/all-staff-view.part';
import { StaffDepartmentsViewPart } from './pages/staff-page/views/departments/departments-view.part';
import { StaffOverviewViewPart } from './pages/staff-page/views/overview/overview-view.part';
import { StaffOverviewViewsPart } from './pages/staff-page/views/staff-overview-views.part';
import { StaffTeamsViewPart } from './pages/staff-page/views/teams/teams-view.part';
import { WhatsNewPage } from './pages/whats-new-page/whats-new.page';
import { NationalToolbarPart } from './toolbar/national/national-toolbar.part';
import { ProgramToolbarPart } from './toolbar/program/program-toolbar.part';
import { ProgramAgreementTypeDialog } from './pages/program-page/views/agreement-types/dialog/agreement-type.dialog';


export { AdminAreaPreparedData } from './area/admin-area-prepared-data';
export { AdminAreaService } from './area/admin-area.service';
export { ApplicationStatusFilter, NationalDashboardPageService, NationalDashboardTabId, NationalDashboardViewId } from './pages/national-page/national-dashboard-page.service';
export { ProgramDashboardTabId, ProgramDashboardViewId, ProgramPageService } from './pages/program-page/program-page.service';

export * from './shared-dashboard-views';



export const adminPages = {
	NationalPage,
	ProgramPage,
	RegionsPage,
	SitesPage,
	StaffOverviewPage,
	WhatsNewPage,
};


export const adminDeclarations = [
	StaffAllStaffViewPart,
	StaffCountsTabPart,
	StaffDepartmentsViewPart,
	StaffOverviewPage,
	StaffOverviewViewPart,
	StaffOverviewViewsPart,
	StaffTeamsViewPart,
	WhatsNewPage,

	NationalGetHelpTabPart,
	NationalNewsTabPart,
	NationalViewsPart,
	NationalPage,
	NationalReportingChartsViewPart,
	NationalReportingLookerViewPart,
	NationalToolbarPart,


	RegionsPage,
	SitesPage,


	ProgramToolbarPart,
	ProgramPage,
	ProgramAgreementSignaturesViewPart,
	ProgramAgreementTypesViewPart,
	ProgramOverviewViewPart,
	AccProblemsViewPart,
	ProgramViewsPart,
	TrainingMaterialsByLanguagePart,
	ProgramAgreementTypeDialog,
];