import { AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DestroyablePart } from '@me-access-parts';
import { AccJudge, AccStageId, AccTeam, Accelerator, Answer, DbaAccJudgement } from '@me-interfaces';
import { QuestionStepper } from '@me-shared-parts/PG-program';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';
import { getIconContextForPackage } from '@me-shared-parts/UI-common/icon/get-icon-context-for-package';
import { Observable, combineLatest, lastValueFrom, map, take } from 'rxjs';
const DEFAULT_EDITOR_DATA = { uniqueId: undefined, iconContext: getIconContext(Icon.empty), data: undefined };


interface EditorData {
	teamList: DroplistItem<AccTeam>[],
	judgeList: DroplistItem<AccJudge>[],
}


@Component({
	selector: 'acc-final-pitch-editor-view-part',
	templateUrl: './acc-final-pitch-editor-view.part.html',
	styleUrls: ['./acc-final-pitch-editor-view.part.scss'],
})
export class AccFinalPitchEditorViewPart extends DestroyablePart implements OnInit {

	public readonly AccStageId_Curriculum = AccStageId.Curriculum;
	data$: Observable<EditorData>;
	public teamSelectedItem: DroplistItem<AccTeam>;
	public judgeSelectedItem: DroplistItem<AccJudge>;
	private judgeAssessments: {
		judgement: DbaAccJudgement,
		answers: readonly Answer[],
	}[];
	private judgeAssessment: {
		answersExist: boolean,
		answers: readonly Answer[]
	};
	private defaultJudgeQuestions: {
		answersExist: boolean,
		answers: readonly Answer[]
	};

	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.Curriculum, 'assessments');
	readonly: boolean; stepper: QuestionStepper;

	ratingStep;
	form: FormGroup;
	accelerator: Accelerator;
	saving = false;

	constructor(
		public accAreaService: AccAreaService,
		private fb: FormBuilder,

	) {
		super();
	}

	async ngOnInit() {

		super.initDestroyable();

		super.subscribe([this.accessAtStage$], async ([accessAtStage]) => {
			this.readonly = accessAtStage.access != 'Write';
		});


		this.data$ = combineLatest([
			this.accAreaService.teams.teams$,
			this.accAreaService.judging.assessments$,
			this.accAreaService.judging.judges$,
			this.accAreaService.judging.defaultQuestions$
		]).pipe(map(data => this.buildData(data[0], data[1], data[2], data[3])));

		this.accelerator = await lastValueFrom(this.accAreaService.accelerator.acc$.pipe(take(1)));

	}

	buildData(
		accTeams: readonly AccTeam[],
		judgeAssessments: {
			judgement: DbaAccJudgement,
			answers: readonly Answer[],
		}[],
		judges: readonly AccJudge[],
		defaultJudgeQuestions: readonly Answer[],
	): EditorData {

		if (!accTeams || !judges || !judgeAssessments || !defaultJudgeQuestions || this.readonly) return {
			teamList: [],
			judgeList: [],
		};



		this.judgeAssessments = judgeAssessments;
		this.defaultJudgeQuestions = {
			answersExist: false,
			answers: defaultJudgeQuestions
		};

		const teamList: DroplistItem<AccTeam>[] = accTeams
			.filter(team => !team.droppedOutWeek)
			.map(accTeam => ({ uniqueId: accTeam.accTeamId, iconContext: getIconContextForPackage(accTeam), data: accTeam }));

		const judgeList: DroplistItem<AccJudge>[] = judges
			.map(a => ({ uniqueId: a.accJudgeId, iconContext: getIconContextForPackage(a.person), data: a }));

		teamList.unshift(DEFAULT_EDITOR_DATA);
		judgeList.unshift(DEFAULT_EDITOR_DATA);


		return {
			teamList,
			judgeList
		};

	}


	onJudgeChange(e: DroplistItem) {
		if (e.uniqueId) this.judgeSelectedItem = e;
		else this.judgeSelectedItem = undefined;
		this.teamSelectedItem = undefined;
		this.judgeAssessment = undefined;
	}

	onTeamChange(e: DroplistItem) {
		if (e.uniqueId) this.teamSelectedItem = e;
		else {
			this.teamSelectedItem = undefined;
			this.judgeAssessment = undefined;
		}
	}


	load() {
		if (!this.teamSelectedItem || !this.judgeSelectedItem) return;

		const answersFound = this.judgeAssessments.find(assessment => assessment.judgement.accJudgeId == this.judgeSelectedItem.uniqueId && assessment.judgement.accTeamId == this.teamSelectedItem.uniqueId);
		this.judgeAssessment = answersFound ? { answers: answersFound.answers, answersExist: true } : this.defaultJudgeQuestions;
		this.stepper = new QuestionStepper(0, this.judgeAssessment.answers.length, 1);
		this.createForm();
	}

	private createForm() {
		this.form = this.fb.group({});

		this.resetForm();
	}

	private resetForm() {
		this.form.reset({});
		this.form.updateValueAndValidity();
	}

	async save() {
		if (this.readonly) return;

		this.saving = true;
		const values = this.form.value;
		const accTeamId = this.teamSelectedItem.uniqueId;
		const accJudgeId = this.judgeSelectedItem.uniqueId;

		const answers = [];

		for (const answer of this.judgeAssessment.answers) {
			answers.push({
				question: answer.question,
				answer: values['q' + answer.question.questionId],
			});
		}

		await this.accAreaService.judging.actions.setAssessment({ accJudgeId, accTeamId, answers });

		this.judgeAssessment = undefined;
		this.saving = false;
	}

}
