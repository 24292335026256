<me-dialog-frame [header]='headerText' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>
	<div class="multiple-events-selector-dialog-part">
		<table style="width: 100%;"
			class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>

			<tr>
				<td style="width:100px">Current Event</td>
				<td *ngIf="orgEventsDates">
					<div *ngFor="let date of orgEventsDates">{{date}}</div>
				</td>
				<td *ngIf="!orgEventsDates"><i>No event set.</i></td>
			</tr>
			<tr>
				<td>Event</td>
				<td>
					<kendo-multiselect [value]="newEventIds" [autoClose]="false"
						(valueChange)='onSelectionChange($event)' [textField]="'numWithDate'" [valueField]="'eventId'"
						[valuePrimitive]="true" [data]='events' [checkboxes]="true" [disabled]='readonly'>
					</kendo-multiselect>
				</td>
			</tr>
		</table>
	</div>
</me-dialog-frame>