import { Component, Input, OnChanges } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanyPositionExtract } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { CompanyExtractDialog } from '@me-shared-parts/CM-company';
import { ShowPersonExtractDialogService } from '../../../PN-person/person-extract-dialog/SHR-PN_show-person-extract-dialog.service';


@Component({
	selector: 'company-extract-positions',
	templateUrl: './SHR-CM_company-extract-positions.part.html',
	styleUrls: ['./SHR-CM_company-extract-positions.part.scss']
})
export class CompanyExtractPositionsPart implements OnChanges {

	@Input() positions: CompanyPositionExtract[];
	@Input() dialogRef: MatDialogRef<CompanyExtractDialog>;


	showAllPositions = false;


	constructor(
		private func: FuncService,
		private showPersonExtractDialogService: ShowPersonExtractDialogService,
	) {
	}

	ngOnChanges() {
		this.showAllPositions = false;
	}

	async openPerson(position: CompanyPositionExtract) {

		if (!position.person?.personId) return;

		const person = await this.func.person.get(position.person.personId);
		this.dialogRef.close();

		await this.showPersonExtractDialogService.show(person);
	}
}