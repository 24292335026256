import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { GridDownloadColumn } from '@me-grid';

export function buildArrays(columns: GridDownloadColumn[], rowObjects: any[]): string[][] {

	if (columns == undefined || columns.length == 0) return [];

	const rows: string[][] = [];

	rows.push(columns.map(col => col.header));

	for (const rowObject of rowObjects) {
		rows.push(columns.map(col => {

			let cell = rowObject[col.property];

			if (col.type == 'date' || col.type == 'datetime' || col.type == 'time') cell = rowObject[`${col.property}__display`] || '';
			if (col.type == 'string' || col.type == 'text') cell = (cell || '').trim().replace(`\t`, ` `);
			if (col.type == 'number') cell = (cell || cell == 0) ? (+cell).toString() : '';
			if (col.type == 'boolean') cell = cell ? 'true' : 'false';
			if (col.type == 'number[]') cell = cell ? cell.join(',') : '';

			// TODO: retrn appropriate values in the future
			if (col.type == 'dbsPerson') cell = '';
			if (col.type == 'dbsCompany') cell = '';
			if (col.type == 'icon') cell = '';
			if (col.type == 'iconContext') cell = '';
			return cell;
		}));
	}

	return rows;
}

export function createHref(rows: string[][]): string {
	return rows.map(row => row.join('\t')).join('\n');
}

export function makeSafeHref(href: string, sanitizer: DomSanitizer): SafeUrl {
	return sanitizer.bypassSecurityTrustUrl('data:attachment/csv,' + encodeURIComponent(href));
}