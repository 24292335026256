import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { FieldTool, LabelKey, QuarterlyFields } from '@me-interfaces';
import { DdAcc } from '@me-services/core/old-dd';
import { LabelsService } from '@me-services/ui/labels';
import { FieldFormState } from '@me-shared-parts/UI-common';
import { combineLatest, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'quarterly-goals',
	templateUrl: './SHR-PG_quarterly-goals.part.html',
	styleUrls: ['./SHR-PG_quarterly-goals.part.scss'],
})
export class QuarterlyGoalsPart extends DestroyablePart implements OnInit, OnChanges {

	@Input() tool: FieldTool;
	@Input() quarterlies: QuarterlyFields = undefined;
	@Input() acc: DdAcc;
	@Input() forceValidation = false;

	//
	// Each form section has its own state which includes the progress
	// details and the list of text area fields for cycling through them.
	//
	states = {
		q0: new FieldFormState(),
		q0Remaining: -1,
		q1: new FieldFormState(),
		q1Remaining: -1,
		q2: new FieldFormState(),
		q2Remaining: -1,
		q3: new FieldFormState(),
		q3Remaining: -1,
		q4: new FieldFormState(),
		q4Remaining: -1,
	};

	fieldFormStateSubscription: Subscription = undefined;

	disabledKey: LabelKey;
	inlineLabels = this.labels.trackInlineLabels(this, [
		'Completed',
		'Remaining',
		'Instructions',
		'Not Editable',
		'Pre-Accelerator Goals & Progress',
		'Accelerator Curriculum Goals & Progress',
		'Quarter 2 Goals & Progress',
		'Quarter 3 Goals & Progress',
		'Quarter 4 Goals & Progress',
	]);

	constructor(public labels: LabelsService) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
		
		this.disabledKey = {
			key: 'md:Disabled Quarterly Field',
			fields: {
				'program-manager': this.acc.getManager()._name,
			}
		}
	}

	async ngOnChanges() {
		if (!this.quarterlies) throw new Error('Missing required attribute: quarterlies');
		if (!this.acc) throw new Error('Missing required attribute: acc');
		if (!this.tool) throw new Error('Missing required attribute: tool');

		//
		// Subscribe to field states
		//
		if (this.fieldFormStateSubscription) this.fieldFormStateSubscription.unsubscribe();

		this.fieldFormStateSubscription = combineLatest([
			this.states.q0.status$,
			this.states.q1.status$,
			this.states.q2.status$,
			this.states.q3.status$,
			this.states.q4.status$,
		])
			.pipe(takeUntil(this.destroyed$))
			.subscribe(([q0Status, q1Status, q2Status, q3Status, q4Status]) => {
				this.states.q0Remaining = q0Status.numTotal - q0Status.numCompleted;
				this.states.q1Remaining = q1Status.numTotal - q1Status.numCompleted;
				this.states.q2Remaining = q2Status.numTotal - q2Status.numCompleted;
				this.states.q3Remaining = q3Status.numTotal - q3Status.numCompleted;
				this.states.q4Remaining = q4Status.numTotal - q4Status.numCompleted;
			});
	}
}
