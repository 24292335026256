import { Component, Input, OnChanges } from '@angular/core';
import { AccStageId } from '@me-interfaces';
import { CountRow, CountsTableData, DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';
import { ApplicationGridApplicationStatus, ApplicationGridFilter, ApplicationGridFilterField } from '../../acc-application-filter-helpers';
import { AccPreAcceleratorStageId } from '../../acc-application-status-helpers';
import { InterviewerGridFilterField } from '../../acc-interviewer-filter-helpers';
import { AccPreAcceleratorPageService } from '../../acc-pre-accelerator-page.service';
import { ReaderGridFilterField } from '../../acc-reader-filter-helpers';


@Component({
	selector: 'acc-application-counts-part',
	templateUrl: './acc-application-counts.part.html',
})
export class AccApplicationCountsPart implements OnChanges {

	@Input() accStageId: AccPreAcceleratorStageId = AccStageId.Accepting;
	@Input() pageService: AccPreAcceleratorPageService;
	@Input() generalCountsData: CountsTableData = undefined; //Optional. A Counts group and table will be added if this is set


	viewDroplistItems: DroplistItem<never, string>[] = [];
	selectedViewItem: DroplistItem<never, string> = undefined;
	groupSortValues: { [index: string]: number } = {};

	constructor() {
		this.createDroplistItems();
	}

	ngOnChanges() {
		this.createDroplistItems();
	}

	createDroplistItems() {

		const generalCountsGroupName = 'General';

		let allGroupName = 'Applications';
		if (this.accStageId == AccStageId.Accepting) allGroupName = `All Applications`;
		if (this.accStageId == AccStageId.Reading) allGroupName = `All Readable Applications`;
		if (this.accStageId == AccStageId.Interviewing) allGroupName = `All Interviewable Applications`;
		if (this.accStageId == AccStageId.SelectingCohort) allGroupName = `All Selectable Applications`;

		let acceptedGroupName = `Accepted Applications`;
		if (this.accStageId == AccStageId.Accepting) acceptedGroupName = `Submitted Applications`;
		if (this.accStageId == AccStageId.Reading) acceptedGroupName = `Read-Accepted Applications`;
		if (this.accStageId == AccStageId.Interviewing) acceptedGroupName = `Interview-Accepted Applications`;
		if (this.accStageId == AccStageId.SelectingCohort) acceptedGroupName = `Selected Applications`;

		const groupSortValues: { [index: string]: number } = {};
		groupSortValues[generalCountsGroupName] = 0;
		groupSortValues[allGroupName] = 1;
		groupSortValues[acceptedGroupName] = 2;
		this.groupSortValues = groupSortValues;

		const icon = Icon.view_applications;

		this.viewDroplistItems = [
			{ uniqueId: 'status', groupName: allGroupName, iconContext: getIconContext(icon, undefined, 'never', 'By Status'), customSort: '.' },

			{ uniqueId: 'all-demographics', groupName: allGroupName, iconContext: getIconContext(icon, undefined, 'never', 'By Demographics'), customSort: 'Demographics' },
			{ uniqueId: 'all-industry', groupName: allGroupName, iconContext: getIconContext(icon, undefined, 'never', 'By Industry'), customSort: 'Industry' },
			{ uniqueId: 'all-location', groupName: allGroupName, iconContext: getIconContext(icon, undefined, 'never', 'By Location'), customSort: 'Location' },
			{ uniqueId: 'all-offerings', groupName: allGroupName, iconContext: getIconContext(icon, undefined, 'never', 'By Offerings'), customSort: 'Offerings' },
			{ uniqueId: 'all-orgType', groupName: allGroupName, iconContext: getIconContext(icon, undefined, 'never', 'By Org Type'), customSort: 'Org Type' },

			{ uniqueId: 'accepted-demographics', groupName: acceptedGroupName, iconContext: getIconContext(icon, undefined, 'never', 'By Demographics'), customSort: 'Demographics' },
			{ uniqueId: 'accepted-industry', groupName: acceptedGroupName, iconContext: getIconContext(icon, undefined, 'never', 'By Industry'), customSort: 'Industry' },
			{ uniqueId: 'accepted-location', groupName: acceptedGroupName, iconContext: getIconContext(icon, undefined, 'never', 'By Location'), customSort: 'Location' },
			{ uniqueId: 'accepted-offerings', groupName: acceptedGroupName, iconContext: getIconContext(icon, undefined, 'never', 'By Offerings'), customSort: 'Offerings' },
			{ uniqueId: 'accepted-orgType', groupName: acceptedGroupName, iconContext: getIconContext(icon, undefined, 'never', 'By Org Type'), customSort: 'Org Type' },
		];


		if (this.generalCountsData) {
			this.viewDroplistItems.unshift({ uniqueId: 'general-counts', groupName: generalCountsGroupName, iconContext: getIconContext(Icon.action_more, undefined, 'never', 'Counts'), customSort: '.' });
		}

		//
		// Maintain a prior selection if there is one. Otherwise, just select the first item.
		//
		if (this.selectedViewItem) {
			this.selectedViewItem = this.viewDroplistItems.find(item => item.uniqueId == this.selectedViewItem.uniqueId);
		}
		else {
			this.selectedViewItem = this.viewDroplistItems[0];
		}
	}


	onViewDroplistChange(item: DroplistItem<never, ApplicationGridFilterField>) {
		this.selectedViewItem = item;
	}


	filter(filter: ApplicationGridFilter) {
		if (this.pageService) this.pageService.applyApplicationsFilter$.next(filter);
	}


	generalCountClick(row: CountRow) {
		if (['Readable Applications', 'Interviewable Applications'].includes(row.label)) this.pageService.applyApplicationsFilter$.next(
			{ accStageId: this.accStageId, applicationStatus: 'All', field: 'status', value: 'All' }
		)
		else if (['Active Readers', 'Imported Readers', 'Readings', 'Configured Readers'].includes(row.label)) {
			let field: ReaderGridFilterField = 'readerType';
			if (row.label == 'Readings' || row.label == 'Active Readers') field = 'countOfReadings';

			this.pageService.readReadersFilter$.next(
				{ accStageId: this.accStageId, field, value: row.label }
			);
		}

		else if (['Active Interviewers', 'Imported Interviewers', 'Interviews', 'Configured Interviewers'].includes(row.label)) {
			let field: InterviewerGridFilterField = 'interviewerType';
			if (row.label == 'Interviews' || row.label == 'Active Interviewers') field = 'countOfInterviews';

			this.pageService.interviewInterviewersFilter$.next(
				{ accStageId: this.accStageId, field, value: row.label }
			);
		}

	}


	getApplicationStatus(): ApplicationGridApplicationStatus {
		if (this.selectedViewItem?.uniqueId.startsWith('accepted-')) return 'AcceptedOrBeyond';
		else return 'All';
	}

}