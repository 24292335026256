import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { LabelsService } from '@me-services/ui/labels';
import { DestroyablePart } from '../ACS_destroyable.part';

@Component({
	selector: 'me-back-top',
	styleUrls: ['./ACS_back-top.part.scss'],
	template: `<i #backTop class="far fa-angle-up back-to-top" [title]='inlineLabels["Back to Top"]'></i>`
})
export class BackTopPart extends DestroyablePart implements AfterViewInit, OnInit {

	@Input() position = 400;
	@Input() showSpeed = 500;
	@Input() moveSpeed = 1000;

	@ViewChild('backTop') private _selector: ElementRef;

	inlineLabels = this.labels.trackInlineLabels(this, ['Back to Top']);
	mainWrapper;
	constructor(public labels: LabelsService) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

	ngAfterViewInit() {
		this.mainWrapper = jQuery('.main-wrapper');
		this.mainWrapper.scroll(this.onScroll.bind(this));
		this.onScroll();
	}

	@HostListener('click') _onClick(): boolean {
		jQuery('.main-wrapper').animate({ scrollTop: 0 }, { duration: this.moveSpeed });
		return false;
	}

	onScroll(): void {
		const w = this.mainWrapper;
		const el = jQuery(this._selector.nativeElement);
		w[0].scrollTop > this.position ? el.fadeIn(this.showSpeed) : el.fadeOut(this.showSpeed);
	}
}
