import { AdminAreaService } from '@ADMIN-area-service';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DbConceptName } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { Icon, getIconContext } from '@me-shared-parts/UI-common';

const DOP_PERSONID = 11;

@Component({
	selector: 'program-toolbar',
	templateUrl: `./program-toolbar.part.html`,
	styleUrls: [`./program-toolbar.part.scss`],
})
export class ProgramToolbarPart extends DestroyablePart implements OnInit {

	icons = {
		dop: getIconContext(Icon.concept_person, undefined, 'lt-sm', 'Director'),
	}



	constructor(private ds: DataService, public adminAreaService: AdminAreaService) {
		super();
	}

	async ngOnInit() {

		super.initDestroyable();

		const director = await this.ds.admin.person.getOne(DOP_PERSONID);

		this.icons.dop.text = `Director: ${director._name}`;

	}


	async inspectDOP() {
		await this.ds.explorer.explore(DbConceptName.Person, DOP_PERSONID);
	}

}