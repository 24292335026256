import { getApplicationStatusName } from "@ACC-area";
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ApplicationAccess, ApplicationFields, ApplicationStatusId, FieldTool, LabelKey, ProgramTypeId } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { DialogService } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';

const REJECTED_STATUSES = [ApplicationStatusId.AcceptAbandoned, ApplicationStatusId.AcceptCopiedForward, ApplicationStatusId.AcceptDeferred, ApplicationStatusId.AcceptRejected
	, ApplicationStatusId.ReadAbandoned, ApplicationStatusId.ReadCopiedForward, ApplicationStatusId.ReadDeferred, ApplicationStatusId.ReadRejected,
	, ApplicationStatusId.InterviewAbandoned, ApplicationStatusId.InterviewCopiedForward, ApplicationStatusId.InterviewDeferred, ApplicationStatusId.InterviewRejected,
	, ApplicationStatusId.SelectAbandoned, ApplicationStatusId.SelectCopiedForward, ApplicationStatusId.SelectDeferred, ApplicationStatusId.SelectRejected,
];


@Component({
	selector: 'application-display',
	templateUrl: './SHR-PG_application-display.part.html',
	styleUrls: ['./SHR-PG_application-display.part.scss'],
})
export class ApplicationDisplayPart extends DestroyablePart implements OnInit, OnChanges {

	@Input() applicationId: number;
	@Input() tool: FieldTool;
	@Input() showHeader = true;

	/**
	 * When the application is shown in readonly mode, a message is displayed to the applicant
	 * with details (powered by <application-viewer-message />) if showViewerMessage is true.
	 */
	@Input() showViewerMessage = false;

	application: ApplicationFields;
	access: ApplicationAccess;
	isApplStaffEditor = false;
	public getApplicationStatusName = getApplicationStatusName;

	divColor: 'green' | 'red' | 'grey';

	forceValidation = false;
	viewerMessage: { applicationStatusId: ApplicationStatusId, acceptingApplications: boolean, programTypeId: number };

	constructor(
		private dialogService: DialogService,
		private func: FuncService,
		public labels: LabelsService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

	async ngOnChanges() {
		if (!this.applicationId) throw new Error('Missing required attribute: applicationId');
		if (!this.tool) throw new Error('Missing required attribute: tool');

		this.handleApplication(await this.func.application.getFields({ applicationId: this.applicationId, tool: this.tool }));
	}


	handleApplication(application: ApplicationFields) {
		this.application = application;
		this.access = this.application.overview.access;
		this.isApplStaffEditor = this.application.overview.tool == FieldTool.Application_StaffEditor;

		const applicationStatusId = this.application.overview.applicationStatusId;
		this.divColor = applicationStatusId == ApplicationStatusId.TeamPromoted ? 'green' :
			REJECTED_STATUSES.includes(applicationStatusId) ? 'red' : 'grey';

		const acceptingApplications = this.application.overview.acceptingApplications;
		const programTypeId = this.application.overview.programTypeId;
		this.viewerMessage = { applicationStatusId, acceptingApplications, programTypeId };
	}


	async changeStatus(applicationStatusId: ApplicationStatusId.AcceptPending | ApplicationStatusId.AcceptWithdrawn | ApplicationStatusId.AcceptSubmitted) {
		if (applicationStatusId == ApplicationStatusId.AcceptSubmitted) await this.submit();
		else if (applicationStatusId == ApplicationStatusId.AcceptWithdrawn) await this.withdraw();
		else if (applicationStatusId == ApplicationStatusId.AcceptPending) await this.reopen();
	}


	async submit() {
		const { state, fields } = await this.func.application.submit({ applicationId: this.applicationId, tool: this.tool });

		let message: LabelKey = { key: 'An Error Occurred' };
		let reqHeight = 200;
		if (state) {
			if (state == 'success') {
				message = { key: 'md:Application Submitted Msg' };
			}
			if (state == 'incomplete-participants') message = { key: 'md:Application Incomplete Participants Msg' };
			if (state == 'missing-required') {
				message = { key: 'md:Application Missing Required Msg' };
				reqHeight = 180;
			}
			if (state == 'no-access') message = { key: 'md:Application No-Access Msg' };
		}

		await this.dialogService.showMessage(message, 300, reqHeight);

		if (fields) this.handleApplication(fields);
	}

	async withdraw() {
		const fields = await this.func.application.withdraw({ applicationStatusId: ApplicationStatusId.AcceptWithdrawn, applicationId: this.applicationId, tool: this.tool });

		if (fields) this.handleApplication(fields);
	}

	async reopen() {
		const fields = await this.func.application.withdraw({ applicationStatusId: ApplicationStatusId.AcceptPending, applicationId: this.applicationId, tool: this.tool });

		if (fields) this.handleApplication(fields);
	}

}
