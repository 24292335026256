import { Component } from '@angular/core';


@Component({
	selector: 'selectable-view-header',
	templateUrl: './SHR-CMN_selectable-view-header.part.html',
	styleUrls: ['SHR-CMN_selectable-view-header.part.scss'],
})
export class SelectableViewHeaderPart {
}
