import { DbcUser, Event, EventAccess, EventAccessTypeId } from "@me-interfaces";
import { DataService } from "./data.service";
import { getEventAccessTypeFlags } from "./get-event-access-type-flags";


/**
 * Quickly calculate a user's access to an event by using singletons (front end only!).
 * Front end singletons could be hacked in a browser, so the backend has a function of
 * the same name but that one reads the data from the database to be sure it is accurate.
 * 
 * The logic of that function and this one must match!
 */
export async function getEventAccess(
	user: DbcUser,
	event: Event,
	ds: DataService,
): Promise<EventAccess> {

	const eventType = ds.domain.eventType.getOne(event.eventTypeId);
	const accessTypeId = eventType.eventAccessTypeId;
	const accessByRole = getEventAccessTypeFlags(accessTypeId);

	let access = EventAccess.None;

	//
	// Quick permission checks
	//
	if (user.isTechAdmin) access = EventAccess.Write;
	if (user.isEForAllAdmin) access = Math.max(access, accessByRole.eforallAdmin);
	if (user.isCrmUser) access = Math.max(access, accessByRole.staff);

	//
	// Host can edit their own event
	//
	if (user.personId == event.hostPersonId) access = EventAccess.Write;


	if (event.siteId) {
		//
		// Site ED or AltED
		//
		const site = await ds.admin.site.getOnePackage(event.siteId);
		if (user.personId == site.directorId) access = Math.max(access, accessByRole.ed);
		if (user.personId == site.altDirectorId) access = Math.max(access, accessByRole.ed);

		//
		// Site PM or AltPM (any program on the site)
		//
		const sitePrograms = await ds.admin.siteProgram.getAllAsArray();
		if (sitePrograms.find(sp => sp.siteId && (user.personId == sp.managerId || user.personId == sp.altManagerId))) access = Math.max(access, accessByRole.pm);
	}

	//
	// ED and PM on an accelerator might be different
	//
	if (event.accId && accessTypeId == EventAccessTypeId.Accelerator) {
		const acc = await ds.admin.accelerator.getOnePackage(event.accId);
		if (user.personId == acc.directorId) access = Math.max(access, accessByRole.ed);
		if (user.personId == acc.managerId) access = Math.max(access, accessByRole.pm);
	}

	//
	// ED and PM on a pitch contest might be different
	//
	if (event.picId && accessTypeId == EventAccessTypeId.PitchContest) {
		const pic = await ds.admin.pitchContest.getOnePackage(event.picId);
		if (user.personId == pic.directorId) access = Math.max(access, accessByRole.ed);
		if (user.personId == pic.managerId) access = Math.max(access, accessByRole.pm);
	}

	
	return access;
}