import { Injectable } from '@angular/core';
import { PageTabsLayoutPageService } from '@me-shared-parts/UI-common';


/**
 * The list of tabs on this page
 */
export enum SiteDashboardTabId {
	Site = 1,
	Counts = 2,
}

/**
 * The list if main tab droplist views on this page
 */
export enum SiteDashboardViewId {
	Indicators = 1,
	Accelerators = 2,
	PitchContests = 3,
	Applications = 4,
	Businesses = 5,
	Entrepreneurs = 6,
	Events = 7,
	Awards = 8,
	HearReport = 11,
	VolunteerApplications = 21,
	VolunteerReaders = 22,
	VolunteerInterviewers = 23,
	VolunteerMentors = 24,
	VolunteerCoaches = 25,
	VolunteerJudges = 26,
	VolunteerSpecialists = 27,
	VolunteerEventhelpers = 28,
	ConfigDirectors = 31,
	ConfigPrograms = 32,
	ConfigInterns = 33,
	ConfigCouncil = 34,
	ConfigSiteDescription = 35,
	ConfigZips = 36,
}

@Injectable({ providedIn: 'root' })
export class SiteDashboardPageService extends PageTabsLayoutPageService<SiteDashboardTabId, SiteDashboardViewId> {

	constructor() {
		super(SiteDashboardPageService.name, SiteDashboardTabId.Site, SiteDashboardViewId.Indicators);
	}

}