import { DbsPerson } from "@me-interfaces";
import { OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from "@me-services/core/func";

/** @deprecated DO NOT USE THIS!*/
export class PersonTags {

	constructor(private readonly person: DbsPerson, private func: FuncService, private dd: OldDomainDataService) {
	}

	public async add(tagId: number) {
		const person = await this.func.person.tags.add({ personId: this.person.personId, tagId });

		//@ts-ignore
		this.person = Object.assign(this.person, person);
	}

	public async remove(tagId: number) {
		const person = await this.func.person.tags.remove({ personId: this.person.personId, tagId });

		//@ts-ignore
		this.person = Object.assign(this.person, person);
	}

	public get ids() {
		return this.person._personExtracts.tagIds;
	}

	public get list() {
		return this.dd.tags.getByIds(this.person._personExtracts.tagIds);
	}


}