<div class='acc-user-tools-tab-part' *ngIf="accelerator">
	<stage-status [statuses]='statuses'></stage-status>

	<div style="margin-top: 2em;">

		<me-h2>Agreement Pages</me-h2>

		<div class="agreement-tool-section">
			<div>Mentor Handbook<a *ngIf="notSignedMentorHandbookCount" (click)="changeViewAndTab()">
					{{notSignedMentorHandbookCount}}/{{totalMentorCount}} Missing</a>
			</div>
			<link-button [link]="mentorHandbookToolLink"></link-button>
		</div>

		<div class="agreement-tool-section">
			<div>Media Consent<a *ngIf="notSignedMediaHandbookCount" (click)="changeViewAndTab()">
					{{notSignedMediaHandbookCount}}/{{totalMediaCount}} Missing</a>
			</div>
			<link-button [link]="mediaConsentToolLink"></link-button>
		</div>

		<div class="agreement-tool-section">
			<div>Entrepreneur Handbook<a *ngIf="notSignedEntrepreneurHandbookCount" (click)="changeViewAndTab()">
					{{notSignedEntrepreneurHandbookCount}}/{{totalEntrepreneurCount}} Missing</a>
			</div>
			<link-button [link]="entrepreneurHandbookToolLink"></link-button>
		</div>

	</div>
</div>