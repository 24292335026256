import { DbConceptName, Event } from "@me-interfaces";
import { Icon } from "@me-shared-parts/UI-common";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForAwards(ds: DataService, event: Event): Promise<ExplorerBox[]> {

	if (!event.awards.length) return [];
	const boxes: ExplorerBox[] = [];

	const awards = await ds.admin.award.getManyPackagesAsArray(event.awards.map(award => award.awardId));

	for (const award of [...awards]) {

		const awardName = ds.domain.awardName.getOne(award.awardNameId).name;
		const awardKind = ds.domain.awardKind.getOne(award.awardKindId).name;
		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Award,
			header: `Award`,
			buttons: [
				{ explorer: { nameOverride: `$${award.value} - ${awardName}`, explorableId: { conceptName: DbConceptName.Event, id: award.eventId }, navIconOverride: Icon.concept_award, navTooltip: `Award: $${award.value} - ${awardName}` } },
			],
			notes: awardKind,
		};

		if (award.accTeamId) {
			const members = award.accTeam.members.filter(m => m.member.role == 'E').map(member => `${member.person.firstName} ${member.person.lastName.slice(0, 1)}.`);
			const memberText = members.sort((a, b) => a > b ? 1 : -1).join(', ');
			box.buttons.push({ explorer: { nameOverride: `${memberText} & Team`, explorableId: { conceptName: DbConceptName.AccTeam, id: award.accTeamId }, navTooltip: `Acc Team: ${memberText} & Team` } },)
		}
		if (award.picTeamId) {
			const members = award.picTeam.members.map(member => `${member.person.firstName} ${member.person.lastName.slice(0, 1)}.`);
			const memberText = members.sort((a, b) => a > b ? 1 : -1).join(', ');
			box.buttons.push({ explorer: { nameOverride: `${memberText} & Team`, explorableId: { conceptName: DbConceptName.PicTeam, id: award.picTeamId }, navTooltip: `Pic Team: ${memberText} & Team` } },)
		}


		boxes.push(box);
	}

	return boxes;
}