import { Injectable } from '@angular/core';
import { PageTabsLayoutPageService } from '@me-shared-parts/UI-common';


/**
	* The list of tabs on this page
	*/
export enum ProgramDashboardTabId {
	Program = 1,
	Counts = 2,
}

/**
	* The list if main tab droplist views on this page
	*/
export enum ProgramDashboardViewId {
	ProgramOverview = 1,
	AgreementTypes = 2,
	AgreementSignatures = 3,
	EnglishTrainingMaterials = 4,
	SpanishTrainingMaterials = 5,
	AcceleratorProblems = 6,
}

@Injectable({ providedIn: 'root' })
export class ProgramPageService extends PageTabsLayoutPageService<ProgramDashboardTabId, ProgramDashboardViewId> {

	constructor() {
		super(ProgramPageService.name, ProgramDashboardTabId.Program, ProgramDashboardViewId.ProgramOverview);
	}

}
