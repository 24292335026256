import { DbcZip, DbdAccMeetingTimes, DbdAccLanguageSeason, DbdAccStage, DbdApplicationStatus, DbdAwardKind, DbdAwardName, DbdAwardNameDecidingRole, DbdAwardNameEventType, DbdCity, DbdCompanyType, DbdDecidingRole, DbdEventType, DbdIndustry, DbdLanguage, DbdNoteCategory, DbdPhoneType, DbdPicStage, DbdPrefix, DbdProgramType, DbdQuestionsType, DbdQuestionType, DbdState, DbdSuffix, DbdTopic, DbdWebLinkType, DbdAccSeason, DbdTimeZone, DbdTopicMaterial, DbdTopicMaterialType } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { ReplaySubject } from "rxjs";
import { DomainDataManager } from "../domain-data-manager";
import { DomainDataManagers } from "../interfaces";


export function initDomainDataManagers(
	domainDataCache$: ReplaySubject<{ [name: string]: any[] }>,
	util: UtilityService,
): DomainDataManagers {

	return {
		accMeetingTimes: new DomainDataManager<DbdAccMeetingTimes>(domainDataCache$, 'accMeetingTimes', 'accMeetingTimesId', util),
		accLanguageSeason: new DomainDataManager<DbdAccLanguageSeason>(domainDataCache$, 'accLanguageSeason', 'accLanguageSeasonId', util),
		accSeason: new DomainDataManager<DbdAccSeason>(domainDataCache$, 'accSeason', 'accSeasonId', util),
		accStage: new DomainDataManager<DbdAccStage>(domainDataCache$, 'accStage', 'accStageId', util),
		applicationStatus: new DomainDataManager<DbdApplicationStatus>(domainDataCache$, 'applicationStatus', 'applicationStatusId', util),
		awardKind: new DomainDataManager<DbdAwardKind>(domainDataCache$, 'awardKind', 'awardKindId', util),
		awardName: new DomainDataManager<DbdAwardName>(domainDataCache$, 'awardName', 'awardNameId', util),
		awardNameDecidingRole: new DomainDataManager<DbdAwardNameDecidingRole>(domainDataCache$, 'awardName_decidingRole', 'awardNameDecidingRoleId', util),
		awardNameEventType: new DomainDataManager<DbdAwardNameEventType>(domainDataCache$, 'awardName_eventType', 'awardNameEventTypeId', util),
		city: new DomainDataManager<DbdCity>(domainDataCache$, 'city', 'cityId', util),
		companyType: new DomainDataManager<DbdCompanyType>(domainDataCache$, 'companyType', 'companyTypeId', util),
		decidingRole: new DomainDataManager<DbdDecidingRole>(domainDataCache$, 'decidingRole', 'decidingRoleId', util),
		eventType: new DomainDataManager<DbdEventType>(domainDataCache$, 'eventType', 'eventTypeId', util),
		industry: new DomainDataManager<DbdIndustry>(domainDataCache$, 'industry', 'industryId', util),
		language: new DomainDataManager<DbdLanguage>(domainDataCache$, 'language', 'languageId', util),
		noteCategory: new DomainDataManager<DbdNoteCategory>(domainDataCache$, 'noteCategory', 'noteCategoryId', util),
		phoneType: new DomainDataManager<DbdPhoneType>(domainDataCache$, 'phoneType', 'phoneTypeId', util),
		picStage: new DomainDataManager<DbdPicStage>(domainDataCache$, 'picStage', 'picStageId', util),
		prefix: new DomainDataManager<DbdPrefix>(domainDataCache$, 'prefix', 'prefixId', util),
		programType: new DomainDataManager<DbdProgramType>(domainDataCache$, 'programType', 'programTypeId', util),
		questionsType: new DomainDataManager<DbdQuestionsType>(domainDataCache$, 'questionsType', 'questionsTypeId', util),
		questionType: new DomainDataManager<DbdQuestionType>(domainDataCache$, 'questionType', 'questionTypeId', util),
		state: new DomainDataManager<DbdState, string>(domainDataCache$, 'state', 'stateCode', util),
		suffix: new DomainDataManager<DbdSuffix>(domainDataCache$, 'suffix', 'suffixId', util),
		timeZone: new DomainDataManager<DbdTimeZone>(domainDataCache$, 'timeZone', 'timeZoneId', util),
		topic: new DomainDataManager<DbdTopic>(domainDataCache$, 'topic', 'topicId', util),
		topicMaterial: new DomainDataManager<DbdTopicMaterial>(domainDataCache$, 'topicMaterial', 'topicMaterialId', util),
		topicMaterialType: new DomainDataManager<DbdTopicMaterialType>(domainDataCache$, 'topicMaterialType', 'topicMaterialTypeId', util),
		webLinkType: new DomainDataManager<DbdWebLinkType>(domainDataCache$, 'webLinkType', 'webLinkTypeId', util),
		zip: new DomainDataManager<DbcZip>(domainDataCache$, 'zip', 'zipId', util),
	};
}