import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { CouncilMember, DbConceptName } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { getIconClass, getIconContext, Icon } from '@me-shared-parts/UI-common';
import { SiteAreaService } from '@SITE-area';


export interface CouncilMemberCallBackValue { councilMemberId: number, personId: number, startUTC: number, endUTC: number }


@Component({
	selector: 'site-council-member-edit-dialog',
	templateUrl: './council-member-edit.dialog.html',
	styleUrls: ['./council-member-edit.dialog.scss'],

})
export class SiteCouncilMemberEditDialog extends DestroyablePart implements OnInit {
	//
	// The action buttons
	//
	updateAction: DialogAction<CouncilMemberCallBackValue> = {
		id: 'update',
		enabled: false,
		visible: true,
		cssClass: 'btn-green',
		label: 'Update',
		linkType: 'callback',
		callback: async () => ({
			councilMemberId: this.dialog.data.councilMemberId,
			personId: this.dialog.data.personId,
			endUTC: this.newSelectedValue.endDate ? this.newSelectedValue.endDate.getTime() / 1000 : undefined,
			startUTC: this.newSelectedValue.startDate.getTime() / 1000,
		}),
		willCloseDialog: true,
	};

	removeAction: DialogAction<CouncilMemberCallBackValue> = {
		id: 'remove',
		enabled: true,
		visible: true,
		label: 'Permanently Delete',
		cssClass: 'btn-red',
		linkType: "callback",
		callback: async () => ({
			councilMemberId: this.dialog.data.councilMemberId,
			personId: this.dialog.data.personId,
			endUTC: this.newSelectedValue.endDate ? this.newSelectedValue.endDate.getTime() / 1000 : undefined,
			startUTC: this.newSelectedValue.startDate.getTime() / 1000,
		}),
		willCloseDialog: true,
	};
	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: 'Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction<CouncilMemberCallBackValue | void>[] = [this.removeAction, this.updateAction, this.cancelAction];
	icon = getIconClass(Icon.action_edit);

	councilMember: CouncilMember;
	personIcon = getIconContext(Icon.dialog_explore, undefined, 'never');
	personName: string;

	header = 'Edit Council Member';

	readonly: boolean;

	newSelectedValue: { personId: number, startDate: Date, endDate: Date };


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ councilMemberId: number, personId: number }>,
		public dialogRef: MatDialogRef<SiteCouncilMemberEditDialog>,
		private util: UtilityService,
		private ds: DataService,
		private siteAreaService: SiteAreaService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		super.subscribe([
			this.siteAreaService.accessAndId$,
		], async ([accessAndId]) => {
			this.readonly = accessAndId?.access?.root != 'Write';
		});

		const { councilMemberId, personId } = this.dialog.data;

		this.councilMember = (await this.ds.admin.councilMember.getOnePackage(councilMemberId));
		this.personName = this.councilMember.person._name;

		this.newSelectedValue = {
			personId,
			endDate: this.councilMember.endUTC ? this.util.date.getDate(this.councilMember.endUTC) : undefined,
			startDate: this.util.date.getDate(this.councilMember.startUTC),
		}

		this.checkIfCanSave();
	}

	checkIfCanSave() {

		this.updateAction.enabled = !this.readonly
			&& this.newSelectedValue.personId !== undefined
			&& !!this.newSelectedValue.startDate
			&& (
				(this.newSelectedValue.startDate.getTime() / 1000) != this.councilMember.startUTC
				|| (this.newSelectedValue.endDate && (this.newSelectedValue.endDate.getTime() / 1000 != this.councilMember.endUTC))
				|| (!this.newSelectedValue.endDate && !!this.councilMember.endUTC)
			);
	}


	dateChange(field: 'startDate' | 'endDate', e: Date) {
		this.newSelectedValue[field] = e;
		this.checkIfCanSave();
	}

	async explorePerson() {
		if (!this.newSelectedValue.personId) return;
		await this.ds.explorer.explore(DbConceptName.Person, this.newSelectedValue.personId);
	}

	clear() {
		this.newSelectedValue.endDate = undefined;
		this.checkIfCanSave();
	}
}