import { SharedDashboardsService } from '@ADMIN-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridSetup } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { Observable, combineLatest, map, of } from 'rxjs';


interface SiteRow {
	siteId: number,
}


@Component({
	selector: 'shared-sites-view-part',
	templateUrl: './sites-view.part.html',
})
export class SharedSitesViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();
	public rows$: Observable<readonly SiteRow[]> = of([]);

	constructor(
		private ds: DataService,
		private sharedViewsService: SharedDashboardsService,
	) {
		super();
	}


	async ngOnInit() {

		super.initDestroyable();

		this.rows$ = combineLatest([this.ds.admin.singletonsAsOfUTC$, this.sharedViewsService.dashboardData$]).pipe(
			map(([utc, data]) => {
				if (data == undefined) return [];
				return data.overall.siteIds.map(siteId => ({ siteId }));
			})
		);
	}


	private setupGrid(): GridSetup<SiteRow> {

		const identifier = this.sharedViewsService.identifier$.value;
		if (!identifier) return undefined;

		return {
			experience: 'SITE',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.sharedViewsService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Site",
			rowPluralName: "Sites",
			rowKey: "siteId",
			stateKey: "shared-sites-view-part-" + (identifier.areaName ?? ''),
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			initialState: {},
		};
	}


	async gridActionHandler(action: { actionKey: string, rows: SiteRow[] }) {
		const row = action.rows[0];
	}
}
