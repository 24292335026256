import { DbConceptName, Site } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { UrlService } from "@me-services/ui/url";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForEvents(util: UtilityService, ds: DataService, urlService: UrlService, site: Site): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const eventsBySiteId = await ds.admin.event.getBySiteIds([site.siteId]);
	const events = eventsBySiteId[site.siteId];

	const activeEvents = events.reduce((a, event) => {
		const status = util.event.getStatus(event);
		if (status != 'Complete') a.push(event);
		return a;
	}, []);
	
	const accs = await ds.admin.accelerator.getManyPackagesAsMap(activeEvents.filter(event => event.accId).map(event => event.accId));
	const pics = await ds.admin.pitchContest.getManyPackagesAsMap(activeEvents.filter(event => event.picId).map(event => event.picId));

	for (const event of [...activeEvents].sort((a, b) => a.name > b.name ? 1 : -1)) {
		const eventType = ds.domain.eventType.getOne(event.eventTypeId).name;
		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Event,
			header: `${eventType} Event`,
			buttons: [
				{ explorer: { explorableId: { conceptName: DbConceptName.Event, id: event.eventId }, navTooltip: `Event: ${event.name}` } },
			],
			notes: util.event.getStatus(event),
		};

		let link: string;
		let enabled: boolean;
		let baseURL: string;
		if (event.accId) {
			const statusAndLink = util.event.getAccToolStatusAndLink(event, accs[event.accId]);
			baseURL = urlService.getBaseUrl(accs[event.accId].siteProgram.program.languageId);
			link = statusAndLink.link;
			enabled = statusAndLink.enabled;
		}
		else if (event.picId) {
			const statusAndLink = util.event.getPicToolStatusAndLink(event, pics[event.picId]);
			baseURL = urlService.getBaseUrl(pics[event.picId].siteProgram.program.languageId);
			link = statusAndLink.link;
			enabled = statusAndLink.enabled;
		}
		if (link) {
			box.buttons.push({ link: { linkType: 'tool', url: `${baseURL}${link}`, enabled } });
		}

		if (event.venueId) box.buttons.push({ explorer: { explorableId: { conceptName: DbConceptName.Venue, id: event.venueId }, navTooltip: `Venue: ${event.venue.name}` } },);
		else box.buttons.push({ explorer: { nameOverride: 'Online', explorableId: { conceptName: DbConceptName.Event, id: event.eventId }, navTooltip: `Venue: Online` } },);

		boxes.push(box);
	}

	return boxes;
}