import { Application, ApplicationStatusId, DbsApplication, DbsApplicationParticipant, DbsCompany, DbsEntityNote, DbsPerson, NoteCategoryId, SiteProgram } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { AcceleratorPackageManager } from "./accelerator";
import { PitchContestPackageManager } from "./pitch-contest";
import { SiteProgramPackageManager } from "./site-program";


/**
 * Applications organized into maps by program type for quick lookup
 */
export interface ApplicationsByProgramInstanceIdMaps {
	accs: { [accId: number]: Application[] },
	pics: { [picId: number]: Application[] },
}


export class ApplicationPackageManager extends PackageManager<DbsApplication, Application> {

	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsApplication>,
		private domain: DomainDataManagers,
		private person: SingletonsManager<DbsPerson>,
		private company: SingletonsManager<DbsCompany>,
		private accelerator: AcceleratorPackageManager,
		private pitchContest: PitchContestPackageManager,
		private siteProgram: SiteProgramPackageManager,
		private applicationParticipant: SingletonsManager<DbsApplicationParticipant>,
		private note: SingletonsManager<DbsEntityNote>,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Convert an array of DbcAccTeam to an array of AccTeam
	 */
	protected async _createPackages(dbsApplications: DbsApplication[]): Promise<Application[]> {

		//
		// Get all the people and companies
		//
		const accIds: number[] = [];
		const picIds: number[] = [];
		const siteProgramIds: number[] = [];
		const personIds: number[] = [];
		const companyIds: number[] = [];
		const entityIds: number[] = [];

		const participants = await this.applicationParticipant.getAllAsArray();
		const participantsPersonIds = participants.map(participant => participant.personId);
		const allNotes = await this.note.getAllAsArray();


		for (const application of dbsApplications) {
			if (application.accId) accIds.push(application.accId);
			if (application.picId) picIds.push(application.picId);

			siteProgramIds.push(application.siteProgramId);
			personIds.push(application.personId, application.updatedByPersonId);
			companyIds.push(application.companyId);
		}

		const personMap = await this.person.getManyAsMap([...personIds, ...participantsPersonIds]);
		const companyMap = await this.company.getManyAsMap(companyIds);
		const acceleratorMap = await this.accelerator.getManyPackagesAsMap(accIds);
		const pitchContestMap = await this.pitchContest.getManyPackagesAsMap(picIds);
		const siteProgramMap = await this.siteProgram.getManyPackagesAsMap(siteProgramIds);


		for (const company in companyMap) {
			entityIds.push(companyMap[company].entityId);
		}

		for (const personId of participantsPersonIds) {
			entityIds.push(personMap[personId].entityId);
		}

		const redFlagNoteEntityIds = allNotes
			.filter(n => n.noteCategoryId == NoteCategoryId.RedFlag)
			.filter(n => entityIds.includes(n.entityId))
			.map(n => n.entityId);

		//
		// Package 'em up
		//
		const applications: Application[] = dbsApplications.map(application => {

			const accelerator = acceleratorMap[application.accId];
			const pitchContest = pitchContestMap[application.picId];
			const siteProgram = siteProgramMap[application.siteProgramId];
			const person = personMap[application.personId];
			const company = companyMap[application.companyId];
			const updatedByPerson = personMap[application.updatedByPersonId];
			const applicationParticipants = participants.filter(participant => participant.applicationId == application.applicationId).map(participant => ({ participant, person: personMap[participant.personId] }));
			const companyAndParticipantsEntityIds = [...applicationParticipants.map(participant => participant.person.entityId), company.entityId];
			let hasRedFlag = false;

			for (const entityId of companyAndParticipantsEntityIds) {
				if (redFlagNoteEntityIds.includes(entityId)) {
					hasRedFlag = true;
					break;
				}
			}

			const isOpen = [
				ApplicationStatusId.AcceptPending,
				ApplicationStatusId.ReadPending,
				ApplicationStatusId.InterviewPending,
				ApplicationStatusId.SelectPending,
				ApplicationStatusId.TeamPending,
			].includes(application.applicationStatusId);


			return {
				...application,
				id: application.applicationId,
				name: application.companyName,
				explorerName: application.companyName,
				isOpen,
				accelerator,
				pitchContest,
				siteProgram,
				person,
				company,
				updatedByPersonName: updatedByPerson?._name || `Person #${application.updatedByPersonId}`,
				applicationParticipants,
				appUrl: this.createAppUrl(application, siteProgram),
				hasRedFlag,
			};
		});

		return applications;
	}


	private createAppUrl(application: DbsApplication, sp: SiteProgram): string {
		const applicationId = application.applicationId;
		const accId = application.accId || undefined;
		const picId = application.picId || undefined;
		const siteCode = sp.site.code;
		const siteId = sp.site.siteId;

		let url = null;
		if (accId) url = `/access/admin/national/sites/${siteId}/accelerators/${accId}/pre-accelerator/${applicationId}`;
		else if (picId) url = `/access/admin/communities/${siteCode.toLowerCase()}/programs/pitch-contests/${picId}/applications/${applicationId}/view`;

		return url;
	}


	/**
	* Return an object with accId, picId maps for quick lookup by id
	*/
	public async getIdMaps(): Promise<ApplicationsByProgramInstanceIdMaps> {

		const applications = await this.getAllPackagesAsArray();

		const maps: ApplicationsByProgramInstanceIdMaps = {
			accs: applications.filter(a => a.accId).reduce((map, a) => { map[a.accId] = map[a.accId] ?? []; map[a.accId].push(a); return map; }, {}),
			pics: applications.filter(a => a.picId).reduce((map, a) => { map[a.picId] = map[a.picId] ?? []; map[a.picId].push(a); return map; }, {}),
		};

		return maps;
	}


	/**
	 * Get all applications for each accId provided
	 */
	public async getByAccIds(accIds: number[]): Promise<Readonly<Record<number, ReadonlyArray<Application>>>> {

		return await this.getPackagesAsArraysByForeignIds('accId', accIds);
	}


	/**
	 * Get all applications for each picId provided
	 */
	public async getByPicIds(picIds: number[]): Promise<Readonly<Record<number, ReadonlyArray<Application>>>> {

		return await this.getPackagesAsArraysByForeignIds('picId', picIds);
	}


	/**
	 * Get all applications for each personId provided
	 */
	public async getByPersonIds(personIds: number[]): Promise<Readonly<Record<number, ReadonlyArray<Application>>>> {

		return await this.getPackagesAsArraysByForeignIds('personId', personIds);
	}

	/**
	 * Get all applications for each companyId provided
	 */
	public async getByCompanyIds(companyIds: number[]): Promise<Readonly<Record<number, ReadonlyArray<Application>>>> {

		return await this.getPackagesAsArraysByForeignIds('companyId', companyIds);
	}

}