import { Injectable } from "@angular/core";
import { AdminAreaAccess, AdminAreaRawData, AppAreaIdentifier } from "@me-interfaces";
import { AppAreaService } from "@me-services/core/area";
import { AuthService } from "@me-services/core/auth";
import { DataService } from "@me-services/core/data";
import { DexieService } from "@me-services/core/dexie";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";
import { PageSpinnerService } from "@me-services/ui/page-spinner";
import { UrlService } from "@me-services/ui/url";
import { UserAreaService } from "@me-user-area";
import { AdminAreaPreparedData } from "./admin-area-prepared-data";
import { AdminSubAreaEvents } from "./admin-subarea-events";
import { prepareAreaData } from "./prepare-area-data";


const NO_ACCESS: AdminAreaAccess = {
	root: 'None',
	sub: {
		eventDialog: 'None',
		staff: 'None',
	},
};

@Injectable({
	providedIn: 'root',
})
export class AdminAreaService extends AppAreaService<AppAreaIdentifier<string>, AdminAreaAccess, AdminAreaRawData, AdminAreaPreparedData> {


	public readonly events = new AdminSubAreaEvents(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this));


	constructor(
		private auth: AuthService,
		urlService: UrlService,
		dexieService: DexieService,
		spinnerService: PageSpinnerService,
		ds: DataService,
		util: UtilityService,
		private func: FuncService,
		userAreaService: UserAreaService,
	) {
		super(
			'Staff',
			userAreaService.user$,
			'Admin',
			dexieService,
			ds,
			spinnerService,
			util,
			urlService.appAreas.admin$,
			func.areas.admin.get,
			async () => ({ area: 'Admin', access: NO_ACCESS, md5Hash: 'NO_ACCESS' }),
			async rawData => await prepareAreaData(rawData, util, ds),
			data => 'Admin Area',
		);
	}
}