<div class="dashboard-card">
	<fieldset>
		<legend>
			<me-label *ngIf="getTypeOf(headerKey) !== 'string'" [key]='headerKey'></me-label>
			<span *ngIf="getTypeOf(headerKey) === 'string'">{{headerKey}}</span>
		</legend>

		<ng-content></ng-content>

		<button-container *ngIf='buttons'>
			<ng-container *ngFor='let button of buttons'>
				<spinner-button *ngIf='button.routerLink' #actionButton [green]='button.callToAction'
					(safeClick)='actionButton.spinning=true; actionButton.disabled=true;'
					[routerLink]='button.routerLink'>
					<me-label [key]='button.labelKey'></me-label>
				</spinner-button>
				<a *ngIf='button.url' class='btn' target="_blank" [href]='button.url'
					[class.btn-green]='button.callToAction'>
					<me-label [key]='button.labelKey'></me-label>
				</a>
				<button *ngIf='button.callback' class="btn" (click)='button.callback()'>
					<me-label [key]='button.labelKey'></me-label>
				</button>
			</ng-container>
		</button-container>

	</fieldset>
</div>