import { SitesAreaRawData } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { SitesAreaData } from "./sites-area-data";


export async function mapToData(
	rawData: SitesAreaRawData,
	util: UtilityService,
	ds: DataService,
): Promise<SitesAreaData> {


	const people = await ds.admin.person.getManyPackagesAsMap([
		...rawData.permissions.map(permission => permission.personId),
		...rawData.openVolunteerApplications.map(application => application.baseApplication.personId),
	]);

	const openVolunteerApplicationSiteIds = [];
	for (const application of rawData.openVolunteerApplications) {
		openVolunteerApplicationSiteIds.push(...application.appliedForSiteIds, application.siteVolunteer?.siteId);
	}

	const sites = await ds.admin.site.getManyPackagesAsMap([
		...openVolunteerApplicationSiteIds,
	]);


	const permissionsData = rawData.permissions.map(permission => {
		return { ...permission, person: people[permission.personId]?.asSingleton };
	});


	const mentorCountsByAccId = rawData.mentorCounts.reduce((map, counts) => {
		map[counts.accId] = counts;
		return map;
	}, {});


	const goalCountsByAccTeamId = rawData.goalCounts.reduce((map, counts) => {
		map[counts.accTeamId] = counts;
		return map;
	}, {});


	const openVolunteerApplications = rawData.openVolunteerApplications.map(application => {

		const baseApplication = { ...application.baseApplication, applicant: people[application.baseApplication.personId]?.asSingleton };
		const appliedForSites = application.appliedForSiteIds.map(siteId => sites[siteId]);
		const siteVolunteer = application.siteVolunteer ? { ...application.siteVolunteer, site: sites[application.siteVolunteer.siteId] } : undefined;

		return { baseApplication, appliedForSites, siteVolunteer };
	});



	return {
		permissionsData,
		mentorCountsByAccId,
		goalCountsByAccTeamId,
		openVolunteerApplications,
	}
}