import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { EntityNote, NoteCategoryId } from '@me-interfaces';
import * as moment from 'moment';
import { ENTITY_NOTE_KEY_PREFIX, getNoteClass } from '../SHR-ED_entity-notes-editor.part';

@Component({
	selector: 'entity-notes-overview',
	templateUrl: './SHR-ED_entity-notes-overview.part.html',
	styleUrls: ['SHR-ED_entity-notes-overview.part.scss'],
})
export class EntityNotesOverviewPart implements OnChanges {

	@Input() entityNotes: EntityNote[];
	@Output() noteSelected = new EventEmitter<string>();

	notesOverview: {
		totalNotes: number,
		redFlags: number,
		notes: {
			noteId: number,
			subject: string,
			icon: string,
			lastEdited: string,
		}[],
	};

	public async ngOnChanges(): Promise<void> {
		this.buildOverview(this.entityNotes);
	}

	buildOverview(entityNotes: EntityNote[]) {
		this.notesOverview = {
			totalNotes: entityNotes.length,
			redFlags: entityNotes.filter(note => note.note.noteCategoryId == NoteCategoryId.RedFlag).length,
			notes: entityNotes.map(note => {
				return {
					noteId: note.noteId,
					subject: note.note.subject,
					icon: getNoteClass(note),
					lastEdited: moment(note.note.updatedUTC * 1000).locale('en').format('lll'),
				}
			}),
		};
	}

	setSelectedNote(key: string) {
		this.noteSelected.emit(`${ENTITY_NOTE_KEY_PREFIX}${key}`);
	}
}