import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DataService } from '@me-services/core/data';
import { PageTabsLayout } from '@me-shared-parts/UI-common';
import { StaffOverviewPageService } from './staff-overview-page.service';


@Component({
	selector: 'staff-overview-page',
	templateUrl: './staff-overview.page.html',
	styleUrls: ['./staff-overview.page.scss'],
})
export class StaffOverviewPage extends DestroyablePart implements OnInit {

	readonly: boolean;

	constructor(
		public pageService: StaffOverviewPageService,
		public ds: DataService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

	onLayoutChange(layout: PageTabsLayout) {
		this.pageService.changeLayout(layout);
	}

}