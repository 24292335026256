<div class='feedback-manager-part'>
	<message-line *ngIf='!vm.reviewTypes.length' alertType='warning'>No feedback provided yet.</message-line>

	<p *ngIf='vm.reviewTypes.length'>Reviewer Type:
		<kendo-dropdownlist [data]="vm.reviewTypes"
			(selectionChange)='selectReviewType($event)'>
		</kendo-dropdownlist>
	</p>
	<ng-container *ngIf='vm.filteredModel.length'>
		<message-line *ngIf='vm.countOfApplicationsUnpolished > 0' alertType='warning'>
			{{vm.countOfApplicationsUnpolished}} {{ vm.countOfApplicationsUnpolished > 1 ? 'companies' : 'company'}}
			require{{ vm.countOfApplicationsUnpolished > 1 ? '' : 's'}} attention.
		</message-line>
		<div class='shell-box'>
			<div class='box'>
				<mat-selection-list (selectionChange)='selectionChangeSetReviewers($event)'>
					<me-h3>Companies</me-h3>
					<mat-list-option #selectOption *ngFor="let feedback of vm.filteredModel"
						[class.value-selected]='selectOption.selected' [value]='feedback' checkboxPosition='before'>
						<div class='name-overflow'>
							{{feedback.company}}
						</div>
						<div>
							<i *ngIf='feedback.countOfUnpolishedReviewerFeedback === 0' class="far fa-check green"></i>
							<i *ngIf='feedback.countOfUnpolishedReviewerFeedback > 0'
								class="fas fa-exclamation-triangle warning">
								{{feedback.countOfUnpolishedReviewerFeedback}}</i>
						</div>
					</mat-list-option>
				</mat-selection-list>
			</div>

			<div class='box'>
				<me-h3>{{vm.reviewType}}</me-h3>
				<ng-container *ngIf='vm.reviewers.length'>
					<mat-selection-list (selectionChange)='selectionChangeSetReviewerFeedback($event)'>
						<mat-list-option #select *ngFor='let review of vm.reviewers' [value]='review'
							[class.value-selected]='select.selected' checkboxPosition='before'>
							<div class='name-overflow'>
								<me-avatar class='mr-1' [avatar]='review.reviewer' size='30px'></me-avatar>
								{{review.reviewer.fullName}}
							</div>
							<div>
								<i *ngIf='review.polishedFeedbackSkipped || review.polishedFeedback'
									class="far fa-check green"></i>
								<i *ngIf='!review.polishedFeedbackSkipped && !review.polishedFeedback '
									class="fas fa-exclamation-triangle warning"></i>
							</div>
						</mat-list-option>
					</mat-selection-list>
				</ng-container>
			</div>
			<div class='box'>
				<me-h3>Feedback</me-h3>
				<ng-container *ngIf='vm.reviewerFeedback'>
					<div class='feedback-field'>
						<p><strong>Company:</strong> {{vm.companySelected.company}}
						</p>
						<p><strong>{{vm.reviewType.slice(0, -1)}}:</strong> {{vm.reviewerFeedback.reviewer.fullName}}
						</p>
						<div>
							<mat-checkbox [checked]='vm.reviewerFeedback.polishedFeedbackSkipped' [disabled]='readonly'
								(change)='setPolishedFeedbackSkipped($event)'>
								Don't include this feedback
							</mat-checkbox>
						</div>
						<mat-form-field class='first-textbox' appearance="outline">
							<mat-label>Given Feedback</mat-label>
							<textarea matInput [value]='vm.reviewerFeedback.originalAndEforallFeedback' readonly
								mat-autosize matAutosizeMinRows='10'></textarea>
						</mat-form-field>
						<ng-container *ngIf='!vm.reviewerFeedback.polishedFeedbackSkipped'>
							<div class='copy-button'>
								<button class='btn' matTooltip="Copy feedback" [disabled]='readonly'
									(click)='vm.polishedFeedback = vm.reviewerFeedback.originalFeedback'><i
										class="fas fa-chevron-down"></i>
								</button>
							</div>
							<mat-form-field class='m-0 extend' appearance="outline">
								<mat-label>Polished Feedback</mat-label>
								<textarea required matInput mat-autosize matAutosizeMinRows='10' [disabled]='readonly'
									placeholder="Enter Polished Feedback" type="text" [value]='vm.polishedFeedback'
									(blur)='setPolishedFeedback($event)'></textarea>
							</mat-form-field>
						</ng-container>
					</div>

					<button-container>
						<a class='btn' target='_blank' routerLink='/access/contacts/people/{{vm.reviewerFeedback.reviewer.personId}}/editors/notes'
							title='Notes'>
							<i *ngIf='vm.reviewerFeedback.reviewer.noteCounts.redFlags' style='color:darkred' class='fas fa-flag'></i>
							<i *ngIf='!vm.reviewerFeedback.reviewer.noteCounts.redFlags'
								class='{{vm.reviewerFeedback.reviewer.noteCounts.total === 0 ? "far fa-sticky-note" : "fas fa-sticky-note"}}'></i>
							Notes</a>
						<spinner-button [hidden]='vm.reviewerFeedback.polishedFeedbackSkipped' (safeClick)='revert()'
							[disabled]='vm.reviewerFeedback.polishedFeedback === vm.polishedFeedback || saving'>Revert
						</spinner-button>
						<spinner-button [hidden]='vm.reviewerFeedback.polishedFeedbackSkipped' (safeClick)='save()'
							[disabled]='!vm.polishedFeedback || vm.reviewerFeedback.polishedFeedback === vm.polishedFeedback || saving'
							[spinning]='saving' [disabled]='readonly'>
							Save</spinner-button>
					</button-container>
				</ng-container>

			</div>
		</div>
		<button-container>
			<download-button label='Export company contact list' [fileName]='downloadFileName'
				[columns]='downloadColumns' [rows]='vm.filteredModel' [disabled]='vm.countOfApplicationsUnpolished > 0'>
			</download-button>
		</button-container>
	</ng-container>
</div>