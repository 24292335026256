<selectable-view *ngIf='profileSelectors' [selectorList]='profileSelectors' listTitle='Profile'
	[initialSelectorKey]='initialSelectorKey' (selectorKeyChanged)='setSelectedKey($event)'>
	<div class='selector-content-section' *ngIf='key'>
		<div class='selector-editor-section'>
			<div *ngIf='key === "Basic Info"'>
				<selectable-view-header>Basic Info</selectable-view-header>
				<company-basic-info-editor [company]='company'></company-basic-info-editor>
			</div>
			<div *ngIf='key === "Description"'>
				<selectable-view-header>Description</selectable-view-header>
				<company-description-editor [company]='company'></company-description-editor>
			</div>
			<div *ngIf='key === "Address"'>
				<selectable-view-header>Address</selectable-view-header>
				<entity-address-editor
					[companyProfileWrapper]='entityService.wrapCompanyProfile(company)'></entity-address-editor>
			</div>
			<div *ngIf='key === "Web Links"'>
				<selectable-view-header>Web Links</selectable-view-header>
				<entity-online-editor [companyProfileWrapper]='entityService.wrapCompanyProfile(company)'>
				</entity-online-editor>
			</div>
		</div>
	</div>
</selectable-view>