import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DbcCompanyProfile } from '@me-interfaces';
import { OldEntityService } from '@me-services/old-services-and-wrappers/entity';
import { ConstantsService } from '@me-services/core/constants';

@Component({
	selector: 'company-description-editor',
	templateUrl: './SHR-ED_company-description-editor.part.html',
	styleUrls: ['./SHR-ED_company-description-editor.part.scss'],
})
export class CompanyDescriptionEditorPart implements OnInit {

	@Input() company: DbcCompanyProfile;

	form: FormGroup;
	updating = false;

	constructor(
		private fb: FormBuilder,
		public constants: ConstantsService,
		private entityService: OldEntityService) { }

	ngOnInit(): void {
		this.createForm();
	}

	private createForm() {

		this.form = this.fb.group({
			description: ['', [Validators.maxLength(2000)]],
		});

		this.resetForm();
	}


	public resetForm() {
		const c = this.company;
		const values = {
			description: c.description || '',
		};

		this.form.reset(values);

		Object.keys(this.form.controls).forEach(key => {
			const ctrl: AbstractControl = this.form.get(key);
			ctrl.markAsTouched();
		});

		this.form.updateValueAndValidity();
	}

	tooManyCharacters(ctrl: AbstractControl) {
		const e = ctrl.errors.maxlength;
		return 'Too many characters. ' + e.actualLength + ' > ' + e.requiredLength;
	}

	async save() {
		this.updating = true;

		const values = {
			companyId: this.company.companyId,
			description: this.form.value.description,
		};

		await this.entityService.wrapCompanyProfile(this.company).updateDescription(values);
		this.resetForm();
		this.updating = false;
	}
}