
import { DdSiteProgramRow } from './dd-site-program-row';
import { DdSiteProgram } from './dd-site-program';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdSitePrograms extends DdObjects<DdSiteProgramRow, DdSiteProgram> {

	constructor(private dd: OldDomainDataService) {
		super('siteProgramId');
	}

	async loadData(hash: string, data: DdSiteProgramRow[]) {
		const objects = data.map(d => new DdSiteProgram(this.dd, d));
		this.loadObjects(hash, objects);
	}
}