import { Component, Input, OnChanges } from '@angular/core';
import { UtilityService } from '@me-services/core/utility';
import { DialogService } from '@me-services/ui/dialog';
import { getIconContext, Icon, IconContext } from '@me-shared-parts/UI-common';
import { getIconForConceptName } from '@me-shared-parts/UI-common/icon/get-icon-for-concept-name';
import { DataService } from '../../data.service';
import { ExplorerButton } from './explorer-button';

@Component({
	selector: 'explorer-button',
	templateUrl: './explorer-button.part.html',
	styleUrls: ['./explorer-button.part.scss'],
})
export class ExplorerButtonPart implements OnChanges {

	@Input() button: ExplorerButton;

	private longPress = false;
	private iconContext: IconContext;
	private clipboardIconContext: IconContext;

	public tooltip: string = undefined;

	public get iconToShow() {
		return this.longPress ? this.clipboardIconContext : this.iconContext;

	}


	constructor(
		private ds: DataService,
		private util: UtilityService,
		private dialogService: DialogService,
	) {
	}


	async ngOnChanges() {

		let icon: Icon;

		if (!this.button) throw new Error(`The button attribute must be provided.`);

		const explorable = await this.ds.admin.get(this.button.explorableId);

		if (this.button.navIconOverride) {
			icon = this.button.navIconOverride;
		}
		else {
			icon = getIconForConceptName(this.button.explorableId.conceptName);
		}

		const name = this.button.nameOverride ?? explorable.name;

		this.iconContext = getIconContext(icon, undefined, undefined, name, explorable.hasRedFlag);
		this.clipboardIconContext = this.getClipboardIcon(name);

		this.tooltip = this.button.navTooltip;
		if (!this.tooltip) this.tooltip = `Explore this ${this.util.text.titleCase(explorable._concept)}`;
		if (!this.tooltip.startsWith('Explore ')) this.tooltip = `Explore ${this.button.navTooltip}`;
	}


	public getTooltip() {
		const t = this.tooltip;
		const { conceptName, id } = this.button?.explorableId;
		if (conceptName) return `${t} (${conceptName} #${id})`;
		else return t;
	}


	onLongPress(longPress: boolean) {
		this.longPress = longPress;
	}


	public async click() {
		if (this.longPress) {

			const clipboardText = this.iconContext.text;

			await this.util.clipboard.writeText(clipboardText);
			await this.dialogService.showMessage(`The following text has been copied to the clipboard.\n\n${clipboardText}`, 400, 250);

			this.longPress = false;
			return false;
		}
		else {
			await this.ds.explorer.explore(this.button.explorableId.conceptName, this.button.explorableId.id);
		}
	}


	getClipboardIcon(text: string): IconContext {
		return getIconContext(Icon.action_clipboard, 'fa-regular', undefined, text);
	}


}