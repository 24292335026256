<me-h3>Positions</me-h3>

<div class='person-extract-positions-part' *ngIf='positions && positions.length'>
	<ng-container *ngFor='let position of positions'>
		<div class='position-details'>

			<div class='company-icon'>

				<ng-container *ngIf='position.company.industryId'>
					<i-icon [icon]="getIconForIndustry(position.company.industryId)">
					</i-icon>
				</ng-container>

				<ng-container *ngIf='!position.company.industryId'>
					<i-icon [icon]="icons.company"></i-icon>
				</ng-container>

			</div>

			<div style="display: inline-grid">
				<a class='link' (click)="openCompany(position)">{{position.company._name}}</a>
				<span>{{position.position.title}}</span>
				<span>
					<ng-container *ngIf='position.position.phone'>{{position.position.phone}} <ng-container
							*ngIf='position.position.extension'>EXT.
							{{position.position.extension}}</ng-container>
					</ng-container>
					<ng-container *ngIf='!position.position.phone'>
						<small><i>No Office Phone provided.</i></small>
					</ng-container>
				</span>
			</div>
		</div>
	</ng-container>
</div>
<div *ngIf='!positions || !positions.length'>No positions.</div>