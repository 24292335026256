<div class='entity-online-editor-part'>
	<div class='entity-online-editor-content'>
		<table class='pro-table pro-table-no-header'>
			<tr>
				<td>
					<button class='btn' *ngIf='!showAddForm' (click)='setAddFormView()'>
						<i class="fas fa-plus-circle fa-fw"></i>
						Add New</button>
					<ng-container *ngIf='showAddForm'>
						<form [formGroup]="addForm" novalidate>
							<span class='add-link-form'>
								<kendo-dropdownlist fxflex='80%' required [data]="webLinkTypes" [textField]="'name'"
									[valueField]="'webLinkTypeId'" [valuePrimitive]="true" formControlName="webLinkTypeId"
									[popupSettings]="{ width: 120 }">
									<ng-template kendoDropDownListValueTemplate let-dataItem>
										<i *ngIf="dataItem && dataItem.webLinkTypeId" [class]="dataItem.iconClass"></i>
									</ng-template>
									<ng-template kendoDropDownListItemTemplate let-dataItem>
										<span class="dropDownList">
											<span *ngIf='dataItem.webLinkTypeId'>
												<i [class]="dataItem.iconClass"></i> -
												{{ dataItem.name }}
											</span>
										</span>
									</ng-template>
								</kendo-dropdownlist>
								<input fxflex='80%' class="k-textbox" placeholder="URL" formControlName="url" required
									data-link-msg="Link format is not valid"
									(blur)='constants.blur.lowerCaseOfValue(addForm.controls.url)'>
								<span class="add-buttons">
									<button class='btn' #messagedisplay (click)='add()' title="Add Web Link"
										[disabled]='addForm.invalid || !addForm.dirty'><i
											class="far fa-check fa-fw"></i>
									</button>
									<button class='btn' (click)='cancel()' title="Cancel"><i
											class="far fa-times fa-fw"></i>
									</button>
								</span>
							</span>

							<div fxLayout="row" *ngIf="addForm.controls.url.errors " class="k-tooltip-validation">
								<div *ngIf='addForm.controls.url.errors.pattern'>
									Invalid Web Link (please include http:// or https://)</div>
								<div *ngIf='addForm.controls.url.errors.maxlength'>
									{{tooManyCharacters(addForm.controls.url)}}</div>
							</div>
						</form>
					</ng-container>
				</td>
			</tr>
			<ng-container *ngIf='!webLinks.length'>
				<tr>
					<td fxLayoutAlign="center">
						<i>No Web Links</i>
					</td>
				</tr>
			</ng-container>
			<ng-container *ngFor='let webLink of webLinks'>
				<tr *ngIf='!editing[webLink.webLinkId]'>
					<td>
						<div class='link-display'>
							<span class='url-section'>
								<i [class]="webLink.iconClass"></i>
								{{webLink.url}}
							</span>
							<span>
								<spinner-button title="Edit" (safeClick)='edit(webLink)'
									[disabled]='editing[webLink.webLinkId] || showAddForm'>
									<i class="fas fa-pencil-alt fa-fw"></i>
								</spinner-button>
								<spinner-button [caution]='true' title="Delete" (safeClick)='delete(webLink)'>
									<i class="fas fa-trash-alt fa-fw"></i>
								</spinner-button>
							</span>
						</div>
					</td>
				</tr>
				<tr *ngIf='editing[webLink.webLinkId]'>
					<td>
						<span fxLayout='row' fxLayoutAlign="space-between" class="header">
							<i [class]="webLink.iconClass"></i>
							<me-h3>{{dd.webLinkTypes.getById(webLink.webLinkTypeId).name}}</me-h3>
							<button class='close-edit' (click)='close(webLink)' title="Cancel"><i
									class="far fa-times fa-fw"></i></button>
						</span>
						<form [formGroup]="editForm" novalidate>
							<div class='url-edit-line' fxLayout="row">
								<p>URL:</p>
								<input fxflex='80%' class="k-textbox" placeholder="URL" required
									data-link-msg="Link format is not valid" formControlName="url"
									(blur)='constants.blur.lowerCaseOfValue(editForm.controls.url)'>
							</div>
							<div fxLayout="row" *ngIf="editForm.controls.url.errors " class="k-tooltip-validation">
								<div class='edit-form-errors' *ngIf='editForm.controls.url.errors.pattern'>
									Invalid Web Link (please include http:// or https://)</div>
								<div *ngIf='editForm.controls.url.errors.maxlength'>
									{{tooManyCharacters(editForm.controls.url)}}</div>
							</div>
						</form>
						<span fxLayout="row" class='edit-buttons'>
							<spinner-button (safeClick)='resetForm(webLink)' title="Revert"
								[disabled]='!editForm.dirty || updating'>
								<i class="far fa-history fa-fw"></i>
							</spinner-button>
							<spinner-button #messagedisplay (safeClick)='save(webLink)' title='Save Changes'
								[disabled]='editForm.invalid || !editForm.dirty || updating' [spinning]='updating'>
								<i class="far fa-check fa-fw"></i>
							</spinner-button>
						</span>
					</td>
				</tr>
			</ng-container>
		</table>
	</div>
</div>