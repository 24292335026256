import { AccAreaService } from '@ACC-area';
import { SiteAreaService } from '@SITE-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccMatchableMentor, AccStageId, AccTeam, AppAreaApplyResponse, CaptionedLayout, DbaAccMatchAssessment } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { Observable, combineLatest, mergeMap } from 'rxjs';


interface EditorData {
	name: string,
	id: number, // accTeamId or personId
	captionedLayout: CaptionedLayout,
	type: 'Mentor' | 'Team'
}

const DEFAULT_EDITOR_DATA = { captionedLayout: undefined, id: undefined, name: '', type: undefined, };


@Component({
	selector: 'acc-mm-editor-view-part',
	templateUrl: './acc-mm-editor-view.part.html',
	styleUrls: ['./acc-mm-editor-view.part.scss'],
})
export class AccMMEditorViewPart extends DestroyablePart implements OnInit {

	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.MatchingMentors, 'assessments');
	readonly: boolean;
	defaultEditorData: EditorData = DEFAULT_EDITOR_DATA;

	public assesserToEdit: EditorData = this.defaultEditorData;
	public assesseeToEdit: EditorData = this.defaultEditorData;


	public assessers$: Observable<GroupResult[] | EditorData[]> = combineLatest([
		this.accAreaService.mentorMatching.matchableMentors$,
		this.accAreaService.teams.teams$,
		this.accAreaService.mentorMatching.assessments$
	]).pipe(mergeMap(data => this.buildData(data[0], data[1], data[2])));

	public assessees: EditorData[] = [];
	public showAssessment = false;
	public assessment: number;


	private assessments: DbaAccMatchAssessment[] = [];
	private _data: EditorData[];

	constructor(
		private ds: DataService,
		private siteAreaService: SiteAreaService,
		public accAreaService: AccAreaService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe([this.accessAtStage$], async ([accessAtStage]) => {
			this.readonly = accessAtStage.access != 'Write';
		});
	}

	async buildData(mentors: AccMatchableMentor[], teams: readonly AccTeam[], assessments: readonly DbaAccMatchAssessment[]) {
		if (this.readonly || (!mentors && !teams)) return [];

		this.assessments = [...assessments];

		this._data = [];
		const siteId = this.siteAreaService.getId().id;
		const siteTags = (await this.ds.admin.tag.getAllPackagesAsArray()).filter(tag => tag.site && tag.site.siteId == siteId);
		const coachTagId = siteTags.find(tag => tag.name == 'coach-approved')?.tagId;


		for (const mentor of mentors) {
			this._data.push({
				captionedLayout: {
					text: `${mentor.person.fullName}`,
					caption: mentor.person.tags.find(tag => tag.tagId == coachTagId) ? 'Mentor (c)' : 'Mentor',
					person: mentor.person.asSingleton
				},
				id: mentor.personId,
				name: mentor.person.fullName,
				type: 'Mentor',
			});
		}

		for (const team of teams) {
			this._data.push({
				captionedLayout: {
					text: `${team.application.person._name}`,
					caption: `Team: ${team.name}`,
					company: team.company
				},
				id: team.accTeamId,
				name: team.name,
				type: 'Team',
			});
		}

		this._data.sort((a, b) => `${a.type}-${a.name}` > `${b.type}-${b.name}` ? 1 : -1);


		return groupBy(this._data, [
			{ field: "type" },
		]);

	}


	handleAssesserToEdit(e: EditorData) {
		this.assesserToEdit = e;
		this.assesseeToEdit = this.defaultEditorData;

		this.assessees = this._data.filter(data => data.type != this.assesserToEdit.type).sort((a, b) => a.name > b.name ? 1 : -1);
		this.showAssessment = false;

	}

	handleAssesseeToEdit(e: EditorData) {
		this.assesseeToEdit = e;
		this.showAssessment = false;
	}

	load(assesserToEdit: EditorData, assesseeToEdit: EditorData) {
		this.showAssessment = true;
		if (assesserToEdit.type == 'Team') {
			const assessment = this.assessments.find(m => m.accTeamId == assesserToEdit.id && m.personId == assesseeToEdit.id);
			if (assessment) this.assessment = assessment.t2m;
		}
		else {
			const assessment = this.assessments.find(m => m.personId == assesserToEdit.id && m.accTeamId == assesseeToEdit.id);
			if (assessment) this.assessment = assessment.m2t;
		}
	}


	getAssessmentClass(i, assessment) {
		return `btn rating-${i}` + (i == assessment ? ' selected' : '');
	}

	assessmentText(i) {
		if (i == 1) return 'NO!';
		else if (i == 2) return 'No';
		else if (i == 3) return 'Yes';
		else if (i == 4) return 'YES!';
	}


	async setAssessment(assesserToEdit: EditorData, assesseeToEdit: EditorData, assessment: number) {
		if (this.assessment == assessment || this.readonly) return;

		let response: AppAreaApplyResponse;
		if (assesserToEdit.type == 'Team') {
			response = await this.accAreaService.mentorMatching.actions.setAssessment({ accTeamId: assesserToEdit.id, personId: assesseeToEdit.id, assessment, assessmentOn: "t2m" });
		}
		else if (assesserToEdit.type == 'Mentor') {
			response = await this.accAreaService.mentorMatching.actions.setAssessment({ accTeamId: assesseeToEdit.id, personId: assesserToEdit.id, assessment, assessmentOn: "m2t" });
		}

		if (response.success) this.assessment = assessment;
	}

}