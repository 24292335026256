import { Component } from '@angular/core';
import { UtilityService } from '@me-services/core/utility';
import { UrlService } from '@me-services/ui/url';
import * as Bowser from "bowser";

@Component({
	selector: 'access-login-logo',
	templateUrl: './login-logo.part.html',
	styleUrls: ['./login-logo.part.scss']
})
export class AccessLoginLogoPart {

	public logo: 'eforall' | 'eparatodos' = 'eforall';
	public browser: string;
	public os: string;

	constructor(urlService: UrlService, private util: UtilityService) {

		this.logo = urlService.logo;

		const bowser = Bowser.getParser(window.navigator.userAgent);
		this.browser = `${bowser.getBrowserName()} ${bowser.getBrowserVersion()}`;
		this.os = `${bowser.getOSName()} ${bowser.getOSVersion()} (${bowser.getPlatformType()})`;
	}

}