import { Component, OnDestroy, OnInit } from "@angular/core";
import { FuncService } from '@me-services/core/func';
import { UtilityService } from "@me-services/core/utility";
import { DialogActivity, DialogService } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { BaseFieldPart } from '../base/SHR-UI_base-field.part';
import { TextAreaDialogFieldDialog } from './dialog/SHR-UI_textarea-dialog-field.dialog';

@Component({
	selector: 'textarea-dialog-field',
	templateUrl: './SHR-UI_textarea-dialog-field.part.html',
	styles: [`
		.textarea-dialog-field-button {
			width: 100%;
			margin: 0;
			padding: 1px 0.5em;
			font-size: 1em;
			height: 50px;
			line-height: 22px;
			border-radius: 1px;
			text-align: left;
			box-shadow: none;
			background-color: #FFF;
			text-overflow: ellipsis;
			overflow: auto;
		}

		.textarea-dialog-field-button > pre {
			white-space: pre-wrap;
			font-family: 'Blackbaud Sans', 'Roboto Condensed', sans-serif;
			font-size: 1em;
			padding: 0;
		}

		.textarea-dialog-field-button span.textarea-dialog-button-placeholder {
    		color: #AAA;
		}

		.textarea-dialog-field-button:hover {
			border-color: rgba(0, 0, 0, 0.15);
    		box-shadow: none;
    		transform: scale(1.00);
    		background-color: #FFF;
		}

		.textarea-dialog-field-button:focus {
			border-color: rgba(0, 0, 0, 0.15);
    		color: #656565;
    		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03), 0 4px 5px 0 rgba(0, 0, 0, 0.04);
		}

		.textarea-dialog-field-button:active {
			transform: scale(1.03, 1.25) !important;
		}
	`],
})

export class TextAreaDialogFieldPart extends BaseFieldPart<string> implements OnInit, OnDestroy {

	readonly INSTANCE_ID = uuidv4();

	basePart: BaseFieldPart<string> = this;

	constructor(
		protected override func: FuncService,
		private util: UtilityService,
		protected override labelsService: LabelsService,
		private dialogService: DialogService,
	) {
		super(func, labelsService);

		dialogService.activity
			.pipe(takeUntil(this.destroyed$))
			.subscribe(this.dialogActivityHandler.bind(this));
	}


	override async ngOnInit() {
		await super.ngOnInit();
		this.state.addTextAreaField(this);
	}

	override cleanValue(value: string): string {
		return this.util.text.fixCase(value, 'sentence-case');
	}


	override checkForAdditionalError(): boolean {
		const length = (this.pendingValue || '').trim().length;
		const lengthLeft = this.field.maxLength - length;

		if (lengthLeft < 0) {
			this.errorText = `Length is greater than ${this.field.maxLength}`;
			return true;
		}

		return false;
	}


	public async editText() {

		const i = this.state.getTextAreaFieldIndex(this.INSTANCE_ID);

		await this.dialogService.showCustom(
			TextAreaDialogFieldDialog,
			{
				instanceIdentifier: this.INSTANCE_ID,
				data: {
					fieldPart: this,
					fieldNumber: i.fieldNumber,
					fieldCount: i.fieldCount,
				}
			},
			1000, 700);

		this.state.addTextAreaField
	}


	public nextEditText: () => Promise<void> = undefined;


	async dialogActivityHandler(activity: DialogActivity) {
		if (activity.instanceIdentifier != this.INSTANCE_ID) return;

		if (activity.activity == 'Action') {

			if (activity.action?.id == 'save') {
				await this.attemptToSave();
			}
			else if (activity.action?.id == 'cancel') {
				this.setPendingValue(this.currentValue);
			}
			else if (activity.action?.id == 'next') {
				setTimeout(this.nextEditText);
			}
		}

		else if (activity.activity == 'Close') {

			//
			// If it is closing but there isn't an action then
			// the user clicked outside the dialog.
			//
			if (!activity.action) {
				this.setPendingValue(this.currentValue);
			}
		}
	}

	override ngOnDestroy() {
		this.state.removeTextAreaField(this);
	}

}