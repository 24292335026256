import { RegionAreaService, RegionPageService } from '@REGION-area';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridSetup, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { PitchContest } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface PitchContestRow {
	picId: number,
	siteCode: string,
	siteId: number,
	updatedUTC: number,
}


@Component({
	selector: 'region-pitch-contests-view-part',
	templateUrl: './pitch-contests-view.part.html',
})
export class RegionPitchContestsViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();

	public rows$: Observable<PitchContestRow[]> = of([]);

	@ViewChild(GridPart) meGrid: GridPart<PitchContestRow>;

	constructor(
		private util: UtilityService,
		public regionAreaService: RegionAreaService,
		public ds: DataService,
		public pageService: RegionPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		this.rows$ = this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(data => this.buildRows(data)));
	}

	async buildRows(singletonsAsOfUTC: number) {

		const identifier = this.regionAreaService.getId();
		const regionId = identifier.id;
		const sites = (await this.ds.admin.site.getByRegionIds([regionId]))[regionId];

		const siteIds = sites.map(site => site.siteId);

		const picsBySiteId = await this.ds.admin.pitchContest.getBySiteIds(siteIds);

		const pics: PitchContest[] = [];
		for (const siteId in picsBySiteId) {
			pics.push(...picsBySiteId[siteId]);
		}

		const rows: PitchContestRow[] = pics.map(p => ({
			picId: p.picId,
			siteCode: p.siteProgram.site.code,
			siteId: p.siteProgram.siteId,
			updatedUTC: p.updatedUTC,
		}));
		return rows;
	}



	private setupGrid(): GridSetup<PitchContestRow> {

		return {
			experience: 'PIC',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Pitch Contest",
			rowPluralName: "Pitch Contests",
			rowKey: "picId",
			stateKey: "region-all-pitch-contests",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			initialState: {
				sort: [{ field: 'PIC_applStartUTC', dir: 'desc' }],
			},
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: PitchContestRow[] }) {

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}
	}

}