import { AccAreaService, AccPreAcceleratorPageService, AccessAtStage } from '@ACC-area';
import { SiteAreaService } from '@SITE-area';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ApplicationCols, GridAction, GridColumnType, GridSetup } from '@me-grid';
import { AccStageId, Application, ApplicationStatusId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart, Icon } from '@me-shared-parts/UI-common';
import { AccApplicationStatusName, SET_TO_ABANDONED_ACTION_KEY, SET_TO_COPIEDFORWARD_ACTION_KEY, SET_TO_REJECTED_ACTION_KEY, SET_TO_WITHDRAWN_ACTION_KEY } from '../../../acc-application-status-helpers';


const STAGE_ID = AccStageId.Accepting;
interface ApplicationDeferralRow {
	applicationId: number,
	applicationStatusId: ApplicationStatusId,
	status: AccApplicationStatusName,
	updatedUTC: number,
}


@Component({
	selector: 'acc-accepting-deferrals-view-part',
	templateUrl: './acc-accepting-deferrals-view.part.html',
})
export class AccAcceptingDeferralsViewPart extends DestroyablePart implements OnInit {

	@ViewChild(GridPart) meGrid: GridPart<ApplicationDeferralRow>;


	private accessAtStage: AccessAtStage;

	public gridSetup = this.setupGrid();
	private accApplicationPersonIds: number[];
	private accApplicationCompanyIds: number[];


	/**
	 * Map applications into grid rows
	 */
	public rows: ApplicationDeferralRow[] = [];




	constructor(
		private util: UtilityService,
		public accAreaService: AccAreaService,
		private siteAreaService: SiteAreaService,
		public ds: DataService,
		public pageService: AccPreAcceleratorPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		const accessAtStage$ = this.accAreaService.getAccessAtStage(STAGE_ID);

		super.subscribe([accessAtStage$, this.ds.admin.singletonsAsOfUTC$, this.siteAreaService.data$, this.accAreaService.accelerator.acc$, this.accAreaService.applications.applications$], async ([accessAtStage, singletonsAsOfUTC, siteAreaData, acc, accApplications]) => {
			this.accessAtStage = accessAtStage;

			const apps = accApplications.map(app => app.application);
			this.accApplicationPersonIds = this.util.array.cleanNumericIds(apps.map(app => app.personId));
			this.accApplicationCompanyIds = this.util.array.cleanNumericIds(apps.map(app => app.companyId));

			const validDeferredStatuses = [
				ApplicationStatusId.AcceptDeferred,
				ApplicationStatusId.ReadDeferred,
				ApplicationStatusId.InterviewDeferred,
				ApplicationStatusId.SelectDeferred,
			];

			//
			// All the accelerator applications for the site that are marked deferred and are past accelerator applications
			//

			const applications = await this.ds.admin.application.getAllPackagesAsArray();

			this.rows = applications
				.filter(application =>
					application.siteProgram.siteId
					&& (application.siteProgram.siteId == siteAreaData.site.siteId)
					&& application.accId
					&& validDeferredStatuses.includes(application.applicationStatusId)
					&& application.accelerator.startUTC < acc.startUTC
					&& application.siteProgramId == acc.siteProgramId
				)
				.map(app => {
					return {
						applicationId: app.applicationId,
						applicationStatusId: app.applicationStatusId,
						status: 'Deferred', // Already filtered to include just the deferred ones.
						updatedUTC: app.updatedUTC,
					}
				});

		});
	}


	setupGrid() {

		const setup: GridSetup<ApplicationDeferralRow> = {
			experience: 'APPLICATION',
			multiselect: true,
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Deferred Application",
			rowPluralName: "Deferred Applications",
			rowKey: "applicationId",
			stateKey: "acc-accepting-deferrals-view",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: 'status', header: "Status", width: 100, type: GridColumnType.text, hidden: false },
				{ field: ApplicationCols.companyCityAndState, header: "Location", width: 150, type: GridColumnType.text, hidden: false },
				{ field: ApplicationCols.participantNames, header: "Partcipants", width: 225, type: GridColumnType.text, hidden: false },
			],
			actionEnabler: this.gridActionEnabler.bind(this),
			initialState: {
				sort: [{ field: ApplicationCols.companyName, dir: 'asc' }],
			},
		};

		setup.actions = [
			{ key: SET_TO_ABANDONED_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Abandoned' },
			{ key: SET_TO_WITHDRAWN_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Withdrawn' },
			{ key: SET_TO_REJECTED_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Rejected' },
			{ key: SET_TO_COPIEDFORWARD_ACTION_KEY, icon: Icon.action_edit, label: 'Set to CopiedForward' },
		];

		return setup;
	}


	gridActionEnabler(action: GridAction, rows: ApplicationDeferralRow[]): boolean {

		if (rows.length == 0) return false;

		if (this.accessAtStage == undefined) {
			console.error(`this.accessAtStage is undefined`);
			return false;
		}

		if (!this.accessAtStage.canUpdateApplicationStatuses) return false;

		return true;
	}


	async gridActionHandler(action: { actionKey: string, rows: ApplicationDeferralRow[] }) {

		const { actionKey, rows } = action;

		if (!rows.length) return;

		const applicationIds = rows.map(row => row.applicationId);
		const applicationsById = await this.ds.admin.application.getManyAsMap(applicationIds);
		const bullets = rows.map(row => applicationsById[row.applicationId]?.companyName ?? `Application #'${row.applicationId}'`).sort();

		//
		// Perform the bulk change
		//
		if ([
			SET_TO_ABANDONED_ACTION_KEY,
			SET_TO_WITHDRAWN_ACTION_KEY,
			SET_TO_REJECTED_ACTION_KEY,
		].includes(actionKey)) {

			let status: "Rejected" | "Abandoned" | "Withdrawn" = 'Rejected';

			if (actionKey == SET_TO_ABANDONED_ACTION_KEY) {
				status = 'Abandoned';
			}
			if (actionKey == SET_TO_WITHDRAWN_ACTION_KEY) {
				status = 'Withdrawn';
			}

			const message = `Would you like to change the status of the following deferred applications to '${status}'? \nThey will be set to ${status} back in the original application and will be removed from this list and will no longer be available for copying forward.`;
			const yes = await this.ds.dialogService.confirm(message, 420, 250, undefined, undefined, bullets);
			if (yes) await this.accAreaService.applications.actions.setBulkStatusForDeferredApplications({ applicationIds, status });
		}

		else if (actionKey == SET_TO_COPIEDFORWARD_ACTION_KEY) {

			const existingApplications: Application[] = applicationIds.reduce((a, id) => {
				if (this.accApplicationPersonIds.includes(applicationsById[id].personId)
					|| this.accApplicationCompanyIds.includes(applicationsById[id].companyId)) {
					a.push(applicationsById[id]);
				}
				return a;
			}, []);

			if (existingApplications.length) {
				const message = `Cannot copy forward because the following applications have an existing application in the accelerator.`;
				const bullets = existingApplications.map(row => row?.companyName ?? `Application #'${row.applicationId}'`).sort();

				await this.ds.dialogService.showMessage(message, 400, 150, undefined, bullets);

			}
			else {
				const message = `Would you like to copy forward the following deferred applications?\nFor each selected application, the old application will be fully copied to this accelerator with status pending and the old application will be set to status copied forward.\nThe applicant will be able to edit the new application and submit.`;
				const yes = await this.ds.dialogService.confirm(message, 450, 250, undefined, undefined, bullets);
				if (yes) await this.accAreaService.applications.actions.copyForward({ applicationIds });
			}

		}
	}

}
