<div class="container">
	<div class="row justify-content-center">
		<div class="col col-xl-3 col-lg-4 col-sm-6 col-xs-12" *ngFor='let card of cards'>
			<button class='btn button-card' routerLink='{{card.path}}'>
				<div class='icon'><i [class]='getStyle(card)'></i></div>
				<span [class]='getStyle(card, true)'>
					<ng-container *ngIf='card.label.key'>
						<me-label [key]='card.label'></me-label>
					</ng-container>
					<ng-container *ngIf='!card.label.key'>
						<me-label *ngIf='card.label.startsWith("key:")' [key]='card.label.substring(4)'></me-label>
						<ng-container *ngIf='!card.label.startsWith("key:")'>{{card.label}}</ng-container>
					</ng-container>
				</span>
				<div class='desc'>
					<ng-container *ngIf='card.desc.key'>
						<me-label [key]='card.desc'></me-label>
					</ng-container>
					<ng-container *ngIf='!card.desc.key'>
						<me-label *ngIf='card.desc.startsWith("key:")' [key]='card.desc.substring(4)'></me-label>
						<ng-container *ngIf='!card.desc.startsWith("key:")'>{{card.desc}}</ng-container>
					</ng-container>
				</div>
			</button>
		</div>
	</div>
</div>