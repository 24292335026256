import { formatPhone } from "./format-phone";
import { makePhoneUrl } from "./make-phone-url";

export const PhoneUtilities = {

  format: (number: string, ext?: string): string => {
    return formatPhone(number, ext);
  },

  makePhoneUrl: (number: string, protocol: 'tel' | 'sms'): string => {
    return makePhoneUrl(number, protocol);
  }
};