import { AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { Award, AwardKindId, EventTypeId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';


interface CountsData {
	q1Awards: number,
	q1AwardeePersonIds: number[],
	q2Awards: number,
	q2AwardeePersonIds: number[],
	q3Awards: number,
	q3AwardeePersonIds: number[],
	q4Awards: number,
	q4AwardeePersonIds: number[],
	totalEforAllCash: number,
	totalAwardedValue: number,
}


@Component({
	selector: 'acc-quarterlies-counts-part',
	templateUrl: './acc-quarterlies-counts.part.html',
	styleUrls: ['./acc-quarterlies-counts.part.scss'],
})
export class AccQuarterliesCountsPart extends DestroyablePart implements OnInit {

	readonly: boolean;
	public countsData: CountsData;

	constructor(
		private accAreaService: AccAreaService,
		private ds: DataService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe([this.accAreaService.accessAndId$], async ([access]) => {
			this.readonly = access?.access?.root !== "Write";
		});

		super.subscribe([this.accAreaService.accelerator.awards$], async ([awards]) => await this.buildData(awards));
	}



	async buildData(awards: readonly Award[]): Promise<void> {

		const q1Awards = awards?.filter(a => a.event.eventTypeId == EventTypeId.AccShowcase) || [];
		const q2Awards = awards?.filter(a => a.event.eventTypeId == EventTypeId.AccQuarterliesQ2) || [];
		const q3Awards = awards?.filter(a => a.event.eventTypeId == EventTypeId.AccQuarterliesQ3) || [];
		const q4Awards = awards?.filter(a => a.event.eventTypeId == EventTypeId.AccQuarterliesQ4) || [];

		const totalAwardedValue = awards?.reduce((t, a) => t += a.value, 0);
		const totalCash = awards?.filter(a => a.awardKindId == AwardKindId.MoneyFromEforAll).reduce((t, a) => t += a.value, 0);

		this.countsData = {
			q1Awards: q1Awards.length,
			q1AwardeePersonIds: this.getAwardeePersonIds(q1Awards),
			q2Awards: q2Awards.length,
			q2AwardeePersonIds: this.getAwardeePersonIds(q2Awards),
			q3Awards: q3Awards.length,
			q3AwardeePersonIds: this.getAwardeePersonIds(q3Awards),
			q4Awards: q4Awards.length,
			q4AwardeePersonIds: this.getAwardeePersonIds(q4Awards),
			totalEforAllCash: totalCash,
			totalAwardedValue: totalAwardedValue,
		};

	}

	getAwardeePersonIds(awards: readonly Award[]): number[] {
		return awards.reduce((ids: number[], a) => {
			const ents = a.accTeam.members.filter(m => m.member.role == 'E');
			ids.push(...ents.map(m => m.person.personId));
			return ids;
		}, []);
	}

	async openContactor(personIds: number[]) {
		await this.ds.contactor.show(
			this.readonly,
			personIds,
			'acc-quarterlies-counts-part',
		);
	}
}