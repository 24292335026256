import { Venue, WebLinkTypeId } from "@me-interfaces";
import { LinkOrExplorable } from "../../link-or-explorerable";

export function getButtons(venue: Venue): LinkOrExplorable[] {

	const buttons: LinkOrExplorable[] = [];

	//
	// Website link
	//

	if (venue.website) {
		buttons.push({ link: { linkType: 'webLink', url: venue.website, webLinkTypeId: WebLinkTypeId.Website } });
	}
	

	//
	// Address
	//
	buttons.push({ link: { linkType: 'address', street: venue.address, zipId: venue.zipId } });


	return buttons;
}