<div class='site-tag-editor-part'>
	<div class='assign-tags'>
		<selectable-view-header>{{ siteCode }} Tags</selectable-view-header>

		<div class='simple-tags'>
			<mat-checkbox *ngIf='followerTag' [checked]='isFollower()' [disabled]='!canEdit'
				(change)='updateTag(isFollower()? undefined : followerTag.tagId, "follower")'>
				<me-tag [tag]="followerTag" [showPrefix]='false' [clickable]='false'></me-tag>
			</mat-checkbox>

			<mat-checkbox *ngIf='mailingListEN' [checked]='isOnMailingListEN()' [disabled]='!canEdit'
				(change)='updateTag(isOnMailingListEN()? undefined : mailingListEN.tagId, "mailing-list-en")'>
				<me-tag [tag]="mailingListEN" [showPrefix]='false' [clickable]='false'></me-tag>
			</mat-checkbox>

			<mat-checkbox *ngIf='mailingListES' [checked]='isOnMailingListES()' [disabled]='!canEdit'
				(change)='updateTag(isOnMailingListES()? undefined : mailingListES.tagId, "mailing-list-es")'>
				<me-tag [tag]="mailingListES" [showPrefix]='false' [clickable]='false'></me-tag>
			</mat-checkbox>

			<mat-checkbox *ngIf='leadershipCurrentTag' [checked]='isLeadershipCurrent()' [disabled]='!canEdit'
				(change)='updateTag(isLeadershipCurrent()? undefined : leadershipCurrentTag.tagId, "leadership")'>
				<me-tag [tag]="leadershipCurrentTag" [showPrefix]='false' [clickable]='false'></me-tag>
			</mat-checkbox>
		</div>

		<kendo-grid [data]="roleTags">

			<kendo-grid-column field="role" title="Role" width="100">
			</kendo-grid-column>

			<kendo-grid-column>
				<ng-template kendoGridHeaderTemplate>
					<div class="center">-</div>
				</ng-template>
				<ng-template kendoGridCellTemplate let-item>
					<div class="center">
						<mat-radio-button class='no-manual' [checked]='item.values[0]' [name]='item.role' value='none'
							(change)='radioChange($event)' [disabled]='!canEdit' [class.disabled]='!canEdit'>
						</mat-radio-button>
					</div>
				</ng-template>
			</kendo-grid-column>

			<kendo-grid-column>
				<ng-template kendoGridHeaderTemplate>
					<div class="center">
						<i class='far fa-question-square fa-fw consider icon' title='Consider'></i>
					</div>
				</ng-template>
				<ng-template kendoGridCellTemplate let-item>
					<div class="center" *ngIf='canShowTag(item.role, "consider")'>
						<mat-radio-button class='consider' [checked]='item.values[1]' [name]='item.role'
							value='consider' (change)='radioChange($event)' [disabled]='!canEdit'
							[class.disabled]='!canEdit'></mat-radio-button>
					</div>
				</ng-template>
			</kendo-grid-column>

			<kendo-grid-column>
				<ng-template kendoGridHeaderTemplate>
					<div class="center">
						<i class='far fa-check-square fa-fw approved icon' title='Approved'></i>
					</div>
				</ng-template>
				<ng-template kendoGridCellTemplate let-item>
					<div class="center" *ngIf='canShowTag(item.role, "approved")'>
						<mat-radio-button class='approved' [checked]='item.values[2]' [name]='item.role'
							value='approved' (change)='radioChange($event)' [disabled]='!canEdit'
							[class.disabled]='!canEdit'></mat-radio-button>
					</div>
				</ng-template>
			</kendo-grid-column>

			<kendo-grid-column>
				<ng-template kendoGridHeaderTemplate>
					<div class="center">
						<i class='far fa-times-square fa-fw not-approved icon' title='Not Approved'></i>
					</div>
				</ng-template>
				<ng-template kendoGridCellTemplate let-item>
					<div class="center" *ngIf='canShowTag(item.role, "not-approved")'>
						<mat-radio-button class='not-approved' [checked]='item.values[3]' [name]='item.role'
							value='not-approved' (change)='radioChange($event)' [disabled]='!canEdit'
							[class.disabled]='!canEdit'></mat-radio-button>
					</div>
				</ng-template>
			</kendo-grid-column>

			<kendo-grid-column>
				<ng-template kendoGridHeaderTemplate>
					<div class="center">
						<i class='far fa-star fa-fw current icon' title='Current'></i>
					</div>
				</ng-template>
				<ng-template kendoGridCellTemplate let-item>
					<div class="center">
						<i class="far fa-check fa-fw current icon" *ngIf='item.values[4]'></i>
					</div>
				</ng-template>
			</kendo-grid-column>

			<kendo-grid-column>
				<ng-template kendoGridHeaderTemplate>
					<div class="center">
						<i class='far fa-calendar-star fa-fw past icon' title='Past/Alumni'></i>
					</div>
				</ng-template>
				<ng-template kendoGridCellTemplate let-item>
					<div class="center">
						<i class="far fa-check fa-fw past icon" *ngIf='item.values[5]'></i>
					</div>
				</ng-template>
			</kendo-grid-column>

		</kendo-grid>
	</div>
</div>