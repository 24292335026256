import { DbcEventAttendee, DbsEvent, MutableDbsAward, MutableDbsEvent } from "@me-interfaces";

/**
 * Given an immutable DbsEvent, transform it to a MutableDbsEvent.
 * Event editors use the mutable version if the DB Concept.
 */
export function convertEventToMutable(immutableEvent: DbsEvent): MutableDbsEvent {

	const awards: MutableDbsAward[] = [...immutableEvent.awards];
	const attendees: DbcEventAttendee[] = [...immutableEvent.attendees];
	const event: MutableDbsEvent = { ...immutableEvent, awards, attendees };

	return event;
}