<div class='demographics-editor-part'>
	<!-- <me-save-changes-message *ngIf='messagedisplay' [hidden]="messagedisplay.disabled"></me-save-changes-message> -->
	<div fxLayout='column' fxLayoutAlign="space-evenly center">
		<fieldset *ngIf='adminAccess' fxFlex>
			<legend>
				Sensitive Information
			</legend>
			<p><strong>Important: </strong>Please remember that this page contains highly confidential information that
				should never be shared without consent from the individual.</p>
		</fieldset>

		<fieldset *ngIf='!adminAccess' fxFlex>
			<legend>
				<me-label key='Why?'></me-label>
			</legend>
			<me-label key='md:Demographics Why Description'></me-label>
		</fieldset>

		<div *ngIf='demographics' fxFlex class='demographics-editor-content' [formGroup]="form">
			<table class='pro-table pro-table-no-header pro-table-with-labels'>
				<tr>
					<td>
						<me-label key='Gender'></me-label>
					</td>
					<td>
						<kendo-dropdownlist formControlName="gender" [data]="genders" [textField]="'text'"
							[defaultItem]='defaultGender' [valueField]="'code'" [valuePrimitive]="true"
							[value]="form.value.gender">
							<ng-template kendoDropDownListValueTemplate let-dataItem>
								<span *ngIf='dataItem && dataItem.text'>
									{{ inlineLabels[dataItem.text] }}
								</span>
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-dataItem>
								<span class="dropDownList">
									<span *ngIf='dataItem && dataItem.code'>
										{{ inlineLabels[dataItem.text] }}
									</span>
								</span>
							</ng-template>
						</kendo-dropdownlist>
					</td>
				</tr>
				<tr *ngIf='form.value.gender === "O"'>
					<td>
						<span [title]='inlineLabels["Other Gender"]'>
							<me-label key='Other Gender'></me-label>
						</span>
					</td>
					<td>
						<input class="k-textbox" [placeholder]="inlineLabels['Other Gender']"
							formControlName="genderOther"
							(blur)='constants.blur.fixCaseOfValue(form.controls.genderOther)' />
						<div *ngIf='form.controls.genderOther.errors && form.controls.genderOther.errors.maxlength'
							class="k-tooltip-validation">
							<span>
								{{tooManyCharacters(form.controls.genderOther)}}
							</span>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<me-label key='Race'></me-label>
					</td>
					<td>
						<kendo-dropdownlist formControlName="races" [data]="racesOptions" [defaultItem]='defaultRace'
							[textField]="'text'" [valueField]="'code'" [valuePrimitive]="true"
							[value]="form.value.races">
							<ng-template kendoDropDownListValueTemplate let-dataItem>
								<span *ngIf='dataItem && dataItem.text'>
									{{ inlineLabels[dataItem.text] }}
								</span>
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-dataItem>
								<span class="dropDownList">
									<span *ngIf='dataItem && dataItem.code'>
										{{ inlineLabels[dataItem.text] }}
									</span>
								</span>
							</ng-template>
						</kendo-dropdownlist>
					</td>
				</tr>
				<tr *ngIf='form.value.races === "O"'>
					<td>
						<me-label key='Other Race(s)'></me-label>
					</td>
					<td>
						<input class="k-textbox" [placeholder]="inlineLabels['Other Race(s)']"
							formControlName="raceOther"
							(blur)='constants.blur.fixCaseOfValue(form.controls.raceOther)' />
						<div *ngIf='form.controls.raceOther.errors && form.controls.raceOther.errors.maxlength'
							class="k-tooltip-validation">
							<span>
								{{tooManyCharacters(form.controls.raceOther)}}
							</span>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<me-label key='Hispanic or Latino'></me-label>
					</td>
					<td>
						<kendo-dropdownlist formControlName="hispanic" [data]="hispanic" [textField]="'text'"
							[valueField]="'code'" [defaultItem]='defaultHispanic' [valuePrimitive]="true"
							[value]="form.value.hispanic">
							<ng-template kendoDropDownListValueTemplate let-dataItem>
								<span *ngIf='dataItem && dataItem.text'>
									{{ inlineLabels[dataItem.text] }}
								</span>
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-dataItem>
								<span class="dropDownList">
									<span *ngIf='dataItem && dataItem.code'>
										{{ inlineLabels[dataItem.text] }}
									</span>
								</span>
							</ng-template>
						</kendo-dropdownlist>
					</td>
				</tr>
				<tr>
					<td>
						<me-label key='Birthday'></me-label>
					</td>
					<td>
						<kendo-datepicker placeholder="Enter birth date..." [formControl]="form.get('birth')">
						</kendo-datepicker>
						<div *ngIf='form.controls.birth.errors' class="k-tooltip-validation">
							<span
								*ngIf='form.controls.birth.errors.tooYoung || form.controls.birth.errors.tooOld || form.controls.birth.errors.invalidDate'>
								<me-label key='Invalid date!'></me-label>
							</span>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<me-label key='Veteran'></me-label>
					</td>
					<td>
						<kendo-dropdownlist formControlName="veteran" [data]="veterans" [textField]="'text'"
							[valueField]="'code'" [defaultItem]='defaultVeteran' [valuePrimitive]="true"
							[value]="form.value.veteran">
							<ng-template kendoDropDownListValueTemplate let-dataItem>
								<span *ngIf='dataItem && dataItem.text'>
									{{ inlineLabels[dataItem.text] }}
								</span>
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-dataItem>
								<span class="dropDownList">
									<span *ngIf='dataItem && dataItem.code'>
										{{ inlineLabels[dataItem.text] }}
									</span>
								</span>
							</ng-template>
						</kendo-dropdownlist>
					</td>
				</tr>
				<tr>
					<td>
						<me-label key='Immigrant'></me-label>
					</td>
					<td>
						<kendo-dropdownlist formControlName="immigrant" [defaultItem]='defaultImmigrant'
							[data]="immigrants" [textField]="'text'" [valueField]="'code'" [valuePrimitive]="true"
							[value]="form.value.immigrant">
							<ng-template kendoDropDownListValueTemplate let-dataItem>
								<span *ngIf='dataItem && dataItem.text'>
									{{ inlineLabels[dataItem.text] }}
								</span>
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-dataItem>
								<span class="dropDownList">
									<span *ngIf='dataItem && dataItem.code'>
										{{ inlineLabels[dataItem.text] }}
									</span>
								</span>
							</ng-template>
						</kendo-dropdownlist>
					</td>
				</tr>
				<tr>
					<td>
						<me-label key='Native English Speaker'></me-label>
					</td>
					<td>
						<kendo-dropdownlist formControlName="nativeEnglish" [data]="nativeEnglish"
							[defaultItem]='defaultNativeEnglish' [textField]="'text'" [valueField]="'code'"
							[valuePrimitive]="true" [value]="form.value.nativeEnglish">
							<ng-template kendoDropDownListValueTemplate let-dataItem>
								<span *ngIf='dataItem && dataItem.text'>
									{{ inlineLabels[dataItem.text] }}
								</span>
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-dataItem>
								<span class="dropDownList">
									<span *ngIf='dataItem && dataItem.code'>
										{{ inlineLabels[dataItem.text] }}
									</span>
								</span>
							</ng-template>
						</kendo-dropdownlist>
					</td>
				</tr>
				<tr>
					<td>
						<me-label key='Native Spanish Speaker'></me-label>
					</td>
					<td>
						<kendo-dropdownlist formControlName="nativeSpanish" [data]="nativeSpanish" [textField]="'text'"
							[valueField]="'code'" [valuePrimitive]="true" [defaultItem]='defaultNativeSpanish'
							[value]="form.value.nativeSpanish">
							<ng-template kendoDropDownListValueTemplate let-dataItem>
								<span *ngIf='dataItem && dataItem.text'>
									{{ inlineLabels[dataItem.text] }}
								</span>
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-dataItem>
								<span class="dropDownList">
									<span *ngIf='dataItem && dataItem.code'>
										{{ inlineLabels[dataItem.text] }}
									</span>
								</span>
							</ng-template>
						</kendo-dropdownlist>
					</td>
				</tr>
			</table>
			<div *ngIf='adminAccess' class='checkbox-layout'>
				<me-h3>Flags that only Staff can View or Edit</me-h3>
				<mat-checkbox [checked]='form.controls.hasOptOutDemographics.value' (change)='toggleCheckbox("O")'
					class='potentialCheckbox' [disabled]='optOutDemographicsDisabled'>Opted out of Demographics
					<small><i (click)='launchInfoDialog()' class='fad fa-info-circle'></i></small>
				</mat-checkbox>
				<mat-checkbox [checked]='form.controls.handicapped.value' (change)='toggleCheckbox("H")'
					class='potentialCheckbox' [disabled]='handicappedDisabled'>Has a handicap or disability
				</mat-checkbox>
				<mat-checkbox [checked]='form.controls.isDeceased.value' (change)='toggleCheckbox("D")'
					class='potentialCheckbox' [disabled]='deceasedDisabled'>Deceased</mat-checkbox>
			</div>

			<button-container>
				<spinner-button (safeClick)='resetForm()' [disabled]='!form.dirty || updating'>
					<me-label key='Revert'></me-label>
				</spinner-button>
				<spinner-button #messagedisplay (safeClick)='save()'
					[disabled]='form.invalid || !form.dirty || updating' [spinning]='updating'>
					<me-label key='Save'></me-label>
				</spinner-button>
			</button-container>
		</div>
	</div>
</div>