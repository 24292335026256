import { Component, Input } from '@angular/core';


@Component({
	selector: 'explorer-note-boxes',
	templateUrl: './explorer-note-boxes.part.html',
	styleUrls: ['./explorer-note-boxes.part.scss'],
})
export class ExplorerNoteBoxesPart {

	@Input() header: string = undefined;

	public get hidden() {
		return !!this.header && this.header.startsWith('0 ');
	}

	public get marginTop() {
		return this.hidden ? '0' : '1em';
	}

}