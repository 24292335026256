import * as equal from 'fast-deep-equal/es6';
import * as stringify from 'json-stable-stringify';

export const SingularValueUtilities = {

  /**
   * Returns whether the provided value is primitive. Use this to distinguish objects and arrays
   * from javascript primitive types (undefined, null, number, bigint, string, boolean).
   * See: https://developer.mozilla.org/en-US/docs/Glossary/Primitive
   */
  isPrimitive: value => Object(value) !== value,


  /**
   * Convert any non-string type into a JSON string.  This is similar to JSON.stringify except
   * the order of the rendered properties will be deterministic (alphabetical) so two objects
   * with the same properties, but different order, will return true when compared.
   */
  stringify: stringify,
};

export const MultipleValuesUtilities = {

  /**
   * Compare any two values (including arrays and objects) and determine if they are the same.
   */
  areSame: (v1: any, v2: any) => {

    //
    // Quick out when values are arrays with different length
    //
    if ((Array.isArray(v1) || Array.isArray(v2)) && v1?.length !== v2?.length) {
      return false;
    }

    //
    // Quick out when values are primitives
    //
    else if (SingularValueUtilities.isPrimitive(v1) || SingularValueUtilities.isPrimitive(v2)) {
      return v1 == v2;
    }

    try {
      // this.ps.addSpinner('util.values.areSame()');

      //
      // Use fast-deep-equal npm package. Objects cannot contain recursive patterns.
      //
      return equal(v1, v2);
    }
    finally {
      // this.ps.removeSpinner('util.values.areSame()');
    }
  }

};