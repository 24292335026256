import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyBasicInfo, DbsCompany } from '@me-interfaces';
import { OldEntityService } from '@me-services/old-services-and-wrappers/entity';
import { ConstantsService } from '@me-services/core/constants';
import { DdPhoneTypeRow, OldDomainDataService } from '@me-services/core/old-dd';
import { setPhoneTypeOnCountryChange } from '@me-shared-parts/UI-common';


@Component({
	selector: 'company-basic-info-editor',
	templateUrl: './SHR-ED_company-basic-info-editor.part.html',
	styleUrls: ['./SHR-ED_company-basic-info-editor.part.scss'],
})
export class CompanyBasicInfoEditorPart implements OnInit {


	@Input() company: DbsCompany;

	statuses = ['Open', 'Closed'];
	offerings = ['Products', 'Services', 'Both'];
	form: FormGroup;
	telInput = undefined;
	zip = { city: undefined, code: undefined, latitude: undefined, longitude: undefined };

	updating = false;
	phoneTypes = this.dd.phoneTypes.objects;
	companyTypes = this.dd.companyTypes.objects;
	industries = this.dd.industries.objects;


	constructor(
		public dd: OldDomainDataService,
		private fb: FormBuilder,
		public constants: ConstantsService,
		private entityService: OldEntityService) {
	}

	ngOnInit(): void {
		const input = document.querySelector("#phone");
		this.telInput = (<any>window).intlTelInput(input, {
			nationalMode: true,
			utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.1.0/js/utils.js"
		});

		this.createForm();
		setPhoneTypeOnCountryChange(input, this.form, this.telInput, 1);
	}

	private createForm() {

		this.form = this.fb.group({
			longName: ['', [Validators.required, Validators.maxLength(40)]],
			shortName: ['', [Validators.maxLength(12)]],
			companyTypeId: [undefined],
			description: ['', [Validators.maxLength(2000)]],
			phone: ['', this.constants.validators.validatePhone(this.telInput)],
			phoneTypeId: [undefined],
			status: [undefined],
			offerings: [undefined],
			industryId: [undefined],
		});

		this.resetForm();
	}


	public resetForm() {
		const c = this.company;
		const values = {
			profileUpdate: false,
			longName: c.longName || '',
			shortName: c.shortName || '',
			companyTypeId: c.companyTypeId,
			phone: c.phone || '',
			phoneTypeId: c.phoneTypeId,
			status: c.status,
			offerings: c.offerings,
			industryId: c.industryId,
		};

		this.form.reset(values);

		Object.keys(this.form.controls).forEach(key => {
			const ctrl: AbstractControl = this.form.get(key);
			ctrl.markAsTouched();
		});

		this.form.updateValueAndValidity();
	}

	public itemDisabled(itemArgs: { dataItem: DdPhoneTypeRow, index: number }) {
		return itemArgs.dataItem.phoneTypeId == 4;
	}

	tooManyCharacters(ctrl: AbstractControl) {
		const e = ctrl.errors.maxlength;
		return 'Too many characters. ' + e.actualLength + ' > ' + e.requiredLength;
	}

	async save() {
		this.updating = true;
		const values: CompanyBasicInfo = {
			companyId: this.company.companyId,
			companyTypeId: this.form.value.companyTypeId,
			longName: this.form.value.longName,
			shortName: this.form.value.shortName,
			phone: this.form.value.phone,
			phoneTypeId: this.form.value.phoneTypeId,
			status: this.form.value.status,
			offerings: this.form.value.offerings,
			industryId: this.form.value.industryId,
			profileUpdate: false,
		};

		await this.entityService.wrapCompany(this.company).updateBasicInfo(values);
		this.resetForm();
		this.updating = false;
	}
}