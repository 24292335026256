import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { DbConceptName, Site } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { getIconContext, Icon } from '@me-shared-parts/UI-common';
import { SiteAreaService } from '@SITE-area';


@Component({
	selector: 'site-toolbar-buttons',
	templateUrl: `./site-toolbar-buttons.part.html`,
	styleUrls: [`./site-toolbar-buttons.part.scss`],
})
export class SiteToolbarButtonsPart extends DestroyablePart implements OnInit {

	site: Site;

	icons = {
		region: getIconContext(Icon.concept_region, undefined, 'always', 'Region'),
		site: getIconContext(Icon.concept_site, undefined, 'lt-md', 'Site'),
		ed: getIconContext(Icon.concept_person, undefined, 'lt-sm', 'ED'),
		oldAdmin: getIconContext(Icon.concept_site, undefined, 'lt-md', 'Old Site Admin'),
		message: getIconContext(Icon.status_doNotContact, undefined, 'always', 'Message'),
	}

	constructor(
		private router: Router,
		private ds: DataService,
		public siteAreaService: SiteAreaService,
	) {
		super();
	}

	ngOnInit() {

		super.initDestroyable();

		super.subscribe(
			[this.siteAreaService.data$],
			async ([data]) => {
				if (data?.site) {
					this.site = data.site;
					this.icons.site.text = `${data.site.name}`;
					this.icons.ed.text = `ED: ${data.site.directorName}`;
				}
			},
		);

	}


	async inspectRegion(regionId: number) {
		if (!regionId) return;
		await this.ds.explorer.explore(DbConceptName.Region, regionId);
	}


	async inspectPerson(personId: number) {
		if (!personId) return;
		await this.ds.explorer.explore(DbConceptName.Person, personId);
	}


	async inspectSite(siteId: number) {
		if (!siteId) return;
		await this.ds.explorer.explore(DbConceptName.Site, siteId);
	}


	async openMessage() {
		if (!this.site.isActive || this.site.hidden) {
			const message = !this.site.isActive ? `The site is marked inactive and can no longer be edited.` : `This site is marked hidden. It is not visible to end users but new applications can be created.`;

			await this.ds.dialogService.showMessage(message, 300, 230);
		}
	}


	goToOldSiteAdmin() {
		this.router.navigateByUrl(`/access/admin/communities/${this.site.code.toLowerCase()}/dashboard`);
	}

}