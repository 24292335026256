import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DbcUser, DbsPerson, DbsWebLink, WebLinkTypeId } from '@me-interfaces';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { DdTag, OldDomainDataService } from '@me-services/core/old-dd';
import { DialogContext, DialogService } from '@me-services/ui/dialog';
import { EntityEditorDialog } from '@me-shared-parts/ED-editors';
import { EntityEditorData } from '@me-shared-parts/ED-editors/dialog/entity-editor-data';
import { PERSON_EDITOR_KEYS } from '@me-shared-parts/ED-editors/person-editors/SHR-ED_person-editors.part';
import { getIconContext, getIconForWebLinkType, Icon, IconContext } from '@me-shared-parts/UI-common';

@Component({
	selector: 'person-extract-dialog',
	templateUrl: './SHR-PN_person-extract-dialog.dialog.html',
	styleUrls: ['./SHR-PN_person-extract-dialog.dialog.scss']
})
export class PersonExtractDialog implements OnInit {

	person: DbsPerson;
	header = 'Person';
	personTags: DdTag[];

	user: DbcUser;
	smsLink: string;
	telLink: string;
	emailLink: string;
	notesIcon: IconContext = getIconContext(Icon.notes_none, undefined, 'always');

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ person: DbsPerson }>,
		public dialogRef: MatDialogRef<PersonExtractDialog>,
		private dialogService: DialogService,
		private dd: OldDomainDataService,
		private userService: OldUserService,
	) {
	}


	async ngOnInit() {
		if (this.dialog.data) this.person = this.dialog.data.person;

		this.user = await this.userService.getUser();
		this.header = this.person?._name || 'No Person Record found';
		this.personTags = this.dd.tags.getByIds(this.person._personExtracts.tagIds);
		if (this.person) await this.setNotesIcon();
		if (this.person.phone) {
			this.telLink = `tel:${this.person.phone}`;
			this.smsLink = `sms:${this.person.phone}`;
		}
		if (this.person._email) this.emailLink = `mailTo:${this.person._email}`;
	}


	/**
	 * Determine the button icon and text based on the weblink type
	 */
	getWebLinkIcon(webLink: DbsWebLink) {
		const icon = getIconForWebLinkType(webLink.webLinkTypeId || WebLinkTypeId.Website)
		return getIconContext(icon, undefined, 'always');
	}

	async openPersonEdit(targetKey?: PERSON_EDITOR_KEYS) {
		this.dialogRef.close();

		const profileEditorData: EntityEditorData = {
			entityId: this.person.entityId,
			selectedEditor: targetKey == 2 ? PERSON_EDITOR_KEYS.NOTES : PERSON_EDITOR_KEYS.PROFILE,
			user: this.user,
		};

		const e = await this.dialogService.showCustom(
			EntityEditorDialog,
			{ data: profileEditorData },
			950, 585);

		if (e) await this.setNotesIcon();
	}

	async setNotesIcon() {
		if (this.person._entityExtracts.noteCounts.redFlags && this.person._entityExtracts.noteCounts.redFlags > 0) this.notesIcon = getIconContext(Icon.notes_redFlag, undefined, 'always');
		else if (this.person._entityExtracts.noteCounts.total && this.person._entityExtracts.noteCounts.total > 0) this.notesIcon = getIconContext(Icon.notes_some, undefined, 'always');
		else this.notesIcon = getIconContext(Icon.notes_none, undefined, 'always');
	}
}