import { GridColumn, GridColumnType } from "../interfaces";


/**
 * Named column configurations. Each config has a default set of GridColumn
 * properties which can be obtained by calling buildColumn(...);
 */
export const enum GridColumnConfig {
	accActivity,
	accAlumniCount,
	accAwardeesCount,
	accAwardsDollars,
	accId,
	accLongName,
	accName,
	accsCount,
	/** The data MUST be a string as 1 - Winter, 2 - Spring, 3 - Summer, 4 - Fall */
	accSeason,
	accTeamId,
	applicationId,
	applicationsCount,
	awardeesCount,
	awardsDollars,
	/** General config for any boolean when there isn't a specific GridColumnConfig. The header and width MUST be overridden. */
	boolean,
	cityAndState,
	companyId,
	companyName,
	companyShortName,
	companyStatus,
	companyType,
	/** TODO: Rename to createdUTC */
	createdUtc,
	email,
	emailMany,
	eventId,
	hiddenFlag,
	/** General config for any id when there isn't a specific GridColumnConfig. The header and width MUST be overridden. */
	id,
	industry,
	location,
	notesIcon,
	/** General config for any number when there isn't a specific GridColumnConfig. The header and width MUST be overridden. */
	number,
	offerings,
	languageCodes,
	personFirstName,
	personFullName,
	personFullNameMany,
	personLastName,
	personId,
	personTitle,
	phone,
	phoneType,
	picActivity,
	picAwardeesCount,
	picAwardsDollars,
	picParticipantsCount,
	picsCount,
	programInstances,
	programsList,
	regionId,
	regionName,
	siteCode,
	siteCodes,
	siteId,
	siteLaunchDate,
	siteName,
	sitesCount,
	staffCount,
	stateCode,
	street,
	/** General config for any text when there isn't a specific GridColumnConfig. The header and width MUST be overridden. */
	text,
	updatedUtc,
	venue,
	/** A four digit year */
	year,
	zipCode,
}

/**
 * Return a default column configuration.
 * 
 * @param order The initial order of this column compared to others
 * @param field The property name on each row that holds the value
 * @param config The named column configuration
 * @param overrides An optional set of column property settings to override the default
 */
export function buildColumn<ROW>(field: string, config: GridColumnConfig, overrides: Partial<GridColumn<ROW>> = {}): GridColumn<ROW> {

	const defaultCol = buildDefaultColumn(field, config);

	return { ...defaultCol, ...overrides };
}


function buildDefaultColumn<ROW>(field: string, config: GridColumnConfig): GridColumn<ROW> {

	if (config == GridColumnConfig.accActivity) return getCol(field, 'Acc Activity', 220, GridColumnType.text, 'Accelerator Activity');
	if (config == GridColumnConfig.accAlumniCount) return getCol(field, 'Alumni', 90, GridColumnType.number, 'Acc Teams still active after 12 weeks');
	if (config == GridColumnConfig.accAwardeesCount) return getCol(field, 'Acc Awardees', 100, GridColumnType.number, 'Unique Acc teams that won one or more awards');
	if (config == GridColumnConfig.accAwardsDollars) return getCol(field, 'Acc Awards', 100, GridColumnType.dollars, 'Total value of awards assigned to Acc teams');
	if (config == GridColumnConfig.accId) return getCol(field, 'accId', 80, GridColumnType.number);
	if (config == GridColumnConfig.accName) return getCol(field, 'Accelerator', 200, GridColumnType.text);
	if (config == GridColumnConfig.accLongName) return getCol(field, 'Accelerator', 300, GridColumnType.text);
	if (config == GridColumnConfig.accsCount) return getCol(field, 'Accs', 90, GridColumnType.number, 'Accelerators');
	if (config == GridColumnConfig.accSeason) return getCol(field, 'Season', 100, GridColumnType.text);
	if (config == GridColumnConfig.accTeamId) return getCol(field, 'accTeamId', 80, GridColumnType.number);
	if (config == GridColumnConfig.applicationId) return getCol(field, 'applicationId', 80, GridColumnType.number);
	if (config == GridColumnConfig.applicationsCount) return getCol(field, 'Applications', 100, GridColumnType.number);
	if (config == GridColumnConfig.awardeesCount) return getCol(field, 'Awardees', 90, GridColumnType.number, 'Unique Acc or Pic teams that won one or more awards');
	if (config == GridColumnConfig.awardsDollars) return getCol(field, 'Awards', 100, GridColumnType.dollars, 'Total value of awards assigned to Acc and Pic teams');
	if (config == GridColumnConfig.boolean) return getCol(field, field, 80, GridColumnType.boolean);
	if (config == GridColumnConfig.cityAndState) return getCol(field, 'City & State', 100, GridColumnType.text);
	if (config == GridColumnConfig.companyId) return getCol(field, 'companyId', 80, GridColumnType.number);
	if (config == GridColumnConfig.companyName) return getCol(field, 'Name', 200, GridColumnType.text);
	if (config == GridColumnConfig.companyShortName) return getCol(field, 'Short Name', 60, GridColumnType.text);
	if (config == GridColumnConfig.companyStatus) return getCol(field, 'Status', 100, GridColumnType.text);
	if (config == GridColumnConfig.companyType) return getCol(field, 'Type', 200, GridColumnType.text);
	if (config == GridColumnConfig.email) return getCol(field, 'Email', 200, GridColumnType.text);
	if (config == GridColumnConfig.emailMany) return getCol(field, 'Emails', 300, GridColumnType.text);
	if (config == GridColumnConfig.eventId) return getCol(field, 'eventId', 80, GridColumnType.number);
	if (config == GridColumnConfig.hiddenFlag) return getCol(field, 'Hidden', 80, GridColumnType.boolean);
	if (config == GridColumnConfig.id) return getCol(field, field, 80, GridColumnType.number);
	if (config == GridColumnConfig.notesIcon) return getCol(field, 'Notes', 70, GridColumnType.entityNotes);
	if (config == GridColumnConfig.number) return getCol(field, field, 90, GridColumnType.number);
	if (config == GridColumnConfig.languageCodes) return getCol(field, 'Languages', 90, GridColumnType.text);
	if (config == GridColumnConfig.personFirstName) return getCol(field, 'First', 120, GridColumnType.text);
	if (config == GridColumnConfig.personFullName) return getCol(field, 'Name', 160, GridColumnType.text);
	if (config == GridColumnConfig.personFullNameMany) return getCol(field, 'Names', 300, GridColumnType.text);
	if (config == GridColumnConfig.personLastName) return getCol(field, 'Last', 120, GridColumnType.text);
	if (config == GridColumnConfig.personTitle) return getCol(field, 'Title', 200, GridColumnType.text);
	if (config == GridColumnConfig.personId) return getCol(field, 'personId', 80, GridColumnType.number);
	if (config == GridColumnConfig.picActivity) return getCol(field, 'Pic Activity', 220, GridColumnType.text, 'Pitch Contest Activity');
	if (config == GridColumnConfig.picAwardeesCount) return getCol(field, 'Pic Awardees', 100, GridColumnType.number, 'Unique Pic teams that won one or more awards');
	if (config == GridColumnConfig.picAwardsDollars) return getCol(field, 'Pic Awards', 100, GridColumnType.number, 'Total value of awards assigned to Pic teams');
	if (config == GridColumnConfig.picParticipantsCount) return getCol(field, 'Pic Participantss', 100, GridColumnType.number, 'Teams that participated in a Pitch Contest');
	if (config == GridColumnConfig.picsCount) return getCol(field, 'Pics', 90, GridColumnType.number, 'Pitch Contests');
	if (config == GridColumnConfig.programInstances) return getCol(field, 'Related Programs', 300, GridColumnType.text);
	if (config == GridColumnConfig.programsList) return getCol(field, 'Programs', 200, GridColumnType.text);
	if (config == GridColumnConfig.regionId) return getCol(field, 'regionId', 80, GridColumnType.number);
	if (config == GridColumnConfig.regionName) return getCol(field, 'Region', 130, GridColumnType.text);
	if (config == GridColumnConfig.siteCode) return getCol(field, 'Site Code', 90, GridColumnType.text);
	if (config == GridColumnConfig.siteCodes) return getCol(field, 'Site Codes', 200, GridColumnType.text);
	if (config == GridColumnConfig.siteLaunchDate) return getCol(field, 'Launched', 125, GridColumnType.dateUtc, 'Site Launched');
	if (config == GridColumnConfig.siteName) return getCol(field, 'Site', 175, GridColumnType.text);
	if (config == GridColumnConfig.siteId) return getCol(field, 'siteId', 80, GridColumnType.number);
	if (config == GridColumnConfig.sitesCount) return getCol(field, 'Sites', 90, GridColumnType.number);
	if (config == GridColumnConfig.staffCount) return getCol(field, 'Staff', 90, GridColumnType.number, 'EforAll Staff Members');
	if (config == GridColumnConfig.stateCode) return getCol(field, 'State', 90, GridColumnType.text, 'State Code');
	if (config == GridColumnConfig.street) return getCol(field, 'Street', 200, GridColumnType.text);
	if (config == GridColumnConfig.text) return getCol(field, field, 100, GridColumnType.text);
	if (config == GridColumnConfig.location) return getCol(field, 'Location', 200, GridColumnType.text);
	if (config == GridColumnConfig.zipCode) return getCol(field, 'ZipCode', 90, GridColumnType.zipCode);
	if (config == GridColumnConfig.phone) return getCol(field, 'Phone', 150, GridColumnType.phone);
	if (config == GridColumnConfig.phoneType) return getCol(field, 'Phone Type', 160, GridColumnType.text);
	if (config == GridColumnConfig.industry) return getCol(field, 'Industry', 220, GridColumnType.text);
	if (config == GridColumnConfig.offerings) return getCol(field, 'Offerings', 170, GridColumnType.text);
	if (config == GridColumnConfig.createdUtc) return getCol(field, 'Created', 100, GridColumnType.dateUtc);
	if (config == GridColumnConfig.updatedUtc) return getCol(field, 'Updated', 100, GridColumnType.dateUtc);
	if (config == GridColumnConfig.venue) return getCol(field, 'Venue', 250, GridColumnType.text);
	if (config == GridColumnConfig.year) return getCol(field, 'Year', 70, GridColumnType.year);

	//
	// Is there a GridColumnConfig enum value not handled above?
	//
	return { field, header: 'NOT CONFIGURED: ' + field, width: 10, type: GridColumnType.text };
}


function getCol<ROW>(
	field: string,
	header: string,
	width: number,
	type: GridColumnType,
	headerTooltip: string = undefined,
	overrides: Partial<GridColumn<ROW>> = {},
): GridColumn<ROW> {

	const col: GridColumn<ROW> = { field, header, width, type };
	if (headerTooltip) col.headerTooltip = headerTooltip;

	return { ...col, ...overrides };
}