import { Injectable } from '@angular/core';
import { DbsCompany, DbcCompanyProfile, DbsPerson, EntityNote } from '@me-interfaces';
import { OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from '@me-services/core/func';
import { CompanyProfileWrapper } from './company-profile-wrapper';
import { CompanyWrapper } from './company-wrapper';
import { EntityNotesWrapper } from './notes-wrapper';
import { PersonWrapper } from './person-wrapper';

/** @deprecated DO NOT USE THIS!*/
@Injectable({ providedIn: 'root' })
export class OldEntityService {

	constructor(private func: FuncService, private dd: OldDomainDataService) {
	}

	public wrapPerson(person: DbsPerson): PersonWrapper {
		return new PersonWrapper(person, this.func, this.dd);
	}

	public wrapCompany(company: DbsCompany): CompanyWrapper {
		return new CompanyWrapper(company, this.func, this.dd);
	}

	public wrapCompanyProfile(companyProfile: DbcCompanyProfile): CompanyProfileWrapper {
		return new CompanyProfileWrapper(companyProfile, this.func, this.dd);
	}

	public wrapNotes(entityId: number, entityNotes: EntityNote[]): EntityNotesWrapper {
		return new EntityNotesWrapper(entityId, this.func, entityNotes);
	}
}