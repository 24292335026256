import { Component, Input, OnChanges } from '@angular/core';
import { GridColumnType, GridSetup, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { EventAndHost, Venue } from '@me-interfaces';
import { DataService } from '@me-services/core/data/data.service';
import { UtilityService } from '@me-services/core/utility';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


interface EventRow {
	eventId: number,
}


@Component({
	selector: 'venue-explorer-events',
	templateUrl: './events.part.html',
	styleUrls: ['./events.part.scss'],
})
export class VenueExplorerEventsPart implements OnChanges {
	@Input() venue: Venue;

	public gridSetup = this.setupGrid();
	public rows$: Observable<EventRow[]>;

	constructor(
		private util: UtilityService,
		private ds: DataService,
	) { }


	ngOnChanges() {
		if (!this.venue) throw new Error('Missing required attribute: venue');

		this.rows$ = this.ds.admin.event
			.observeVenueEvents(this.venue.venueId)
			.pipe(map(eventsAndHosts => this.buildRows(eventsAndHosts)));
	}


	buildRows(eventsAndHosts: EventAndHost[]) {

		if (!eventsAndHosts.length) return [];

		return eventsAndHosts.map(({ event, host }) => {

			if (event) {
				const eventRow: EventRow = {
					eventId: event.eventId,
				};

				return eventRow;
			}

		});
	}

	private setupGrid(): GridSetup<EventRow> {

		return {
			experience: 'EVENT',
			size: {
				fitTo: 'INLINE',
				height: 250,
				shrinkBy: 0,
			},
			rowSingularName: "Event",
			rowPluralName: "Events",
			rowKey: "eventId",
			stateKey: "all-venue-events",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: 'EVENT_status', header: 'Status', width: 100, type: GridColumnType.text, hidden: false },
				{ field: 'EVENT_eventType', header: 'Event Type', width: 200, type: GridColumnType.text, hidden: false },
				{ field: 'EVENT_toolStartUTC', header: 'Tool Start', width: 150, type: GridColumnType.dateAndTimeUtc, hidden: true },
				{ field: 'EVENT_toolEndUTC', header: 'Tool End', width: 150, type: GridColumnType.dateAndTimeUtc, hidden: true },
				{ field: 'EVENT_venueName', header: 'Venue', width: 250, type: GridColumnType.text, hidden: true },
			],
			initialState: { sort: [{ field: 'EVENT_startUTC', dir: 'desc' }] },

		};
	}

}