import { SiteAreaData, SiteAreaService, SiteDashboardPageService } from '@SITE-area';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridSetup, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, combineLatest, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface PitchContestRow {
	picId: number,
	updatedUTC: number,
}


@Component({
	selector: 'site-pitch-contests-view-part',
	templateUrl: './pitch-contests-view.part.html',
})
export class SitePitchContestsViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();

	public rows$: Observable<PitchContestRow[]> = of([]);

	@ViewChild(GridPart) meGrid: GridPart<PitchContestRow>;

	constructor(
		private util: UtilityService,
		public siteAreaService: SiteAreaService,
		public ds: DataService,
		public pageService: SiteDashboardPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		this.rows$ = combineLatest([
			this.ds.admin.singletonsAsOfUTC$,
			this.siteAreaService.data$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1])));
	}


	async buildRows(
		singletonsAsOfUTC: number,
		data: SiteAreaData,
	): Promise<PitchContestRow[]> {

		if (!data) return [];

		const pitchContests = (await this.ds.admin.pitchContest.getBySiteIds([data.site.siteId]))[data.site.siteId];

		const rows: PitchContestRow[] = pitchContests.map(p => ({
			picId: p.picId,
			updatedUTC: p.updatedUTC,
		}));
		return rows;
	}



	private setupGrid(): GridSetup<PitchContestRow> {

		return {
			experience: 'PIC',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Pitch Contest",
			rowPluralName: "Pitch Contests",
			rowKey: "picId",
			stateKey: "site-pitch-contests",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			initialState: {
				sort: [{ field: 'PIC_applStartUTC', dir: 'desc' }],
			},
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: PitchContestRow[] }) {

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}
	}

}