import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
	selector: '[progress-animate]'
})

export class ProgressAnimateDirective implements OnInit {
	public element: any;

	constructor(private _elementRef: ElementRef) {
		this.element = jQuery(_elementRef.nativeElement);
	}

	ngOnInit(): void {
		const elem = this.element;
		let progress = 0;
		const timeout = 0;
		const increment = 1;
		const maxprogress = elem.attr('aria-valuenow');
		function animate() {
			setTimeout(() => {
				progress += increment;
				if (progress < maxprogress) {
					elem.css('width', progress + '%');
					animate();
				}
			}, timeout);
		}
		animate();
	}

	// ngOnInit(): void {
	//     let width = this.element.attr('aria-valuenow'),
	//     $bar = this.element;
	//     $bar.css('opacity', 0);
	//     setTimeout(() => {
	//         $bar.css({
	//             transition: 'none',
	//             width: 0,
	//             opacity: 1
	//         });
	//         setTimeout(() => {
	//             $bar.css('transition', '').css('width', width+'%');
	//         });
	//     });
	// }


}
