import { Component, OnChanges, Input } from '@angular/core';
import { LabelsService } from '@me-services/ui/labels';
import { LanguageId } from '@me-interfaces';

@Component({
	selector: 'rating-four',
	templateUrl: './SHR-CMN_rating-four.part.html',
	styleUrls: ['SHR-CMN_rating-four.part.scss'],
})
export class RatingFourPart implements OnChanges {

	//
	// Accept either an number 1..4 or a four element array of numbers
	// where the index of the first non-zero value is the rating.
	//
	@Input() rating: number | number[] | undefined;
	@Input() short = false;
	r: number | undefined = 0;
	
	constructor(public labels: LabelsService){}

	ngOnChanges() {
		if (this.rating == undefined) {
			this.r = undefined;
		}
		else if (typeof this.rating === 'number') {
			this.r = this.rating;
		}
		else {
			if (this.rating[0]) this.r = 1;
			else if (this.rating[1]) this.r = 2;
			else if (this.rating[2]) this.r = 3;
			else if (this.rating[3]) this.r = 4;
		}
	}

	getClass() {
		const str = this.short ? 'shortrating' : 'rating';
		if (this.r == undefined) return `${str} score-missing`;
		else if (this.r == 1) return `${str} score-1`;
		else if (this.r == 2) return `${str} score-2`;
		else if (this.r == 3) return `${str} score-3`;
		else if (this.r == 4) return `${str} score-4`;


	}

	showRating() {
		if (this.labels.languageId == LanguageId.Spanish) {
			if (this.r == undefined) return '?';
			else if (this.r == 1) return this.short ? 'N!' : 'NO!';
			else if (this.r == 2) return this.short ? 'N' : 'No';
			else if (this.r == 3) return this.short ? 'S' : 'Sí';
			else if (this.r == 4) return this.short ? 'S!' : '¡SÍ!';
		}
		else {
			if (this.r == undefined) return '?';
			else if (this.r == 1) return this.short ? 'N!' : 'NO!';
			else if (this.r == 2) return this.short ? 'N' : 'No';
			else if (this.r == 3) return this.short ? 'Y' : 'Yes';
			else if (this.r == 4) return this.short ? 'Y!' : 'YES!';
		}
	}
}
