import { DbsPerson, DbsProgram, Program } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";


export class ProgramPackageManager extends PackageManager<DbsProgram, Program> {


	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsProgram>,
		private domain: DomainDataManagers,
		private person: SingletonsManager<DbsPerson>,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Convert an array of DbcProgram to an array of Program
	 */
	protected async _createPackages(dbsPrograms: DbsProgram[]): Promise<Program[]> {

		//
		// Get all the people
		//
		const personIds: number[] = [];
		for (const dbsProgram of dbsPrograms) {
			personIds.push(dbsProgram.directorId);
			personIds.push(dbsProgram.updatedByPersonId);
		}

		const personMap = await this.person.getManyAsMap(personIds);


		//
		// Package 'em up
		//
		const programs: Program[] = dbsPrograms.map(dbsProgram => {

			const director = personMap[dbsProgram.directorId];
			const updatedByPerson = personMap[dbsProgram.updatedByPersonId];

			return {
				...dbsProgram,
				directorName: director?._name || `Person #${dbsProgram.directorId}`,
				updatedByPersonName: updatedByPerson?._name || `Person #${dbsProgram.updatedByPersonId}`,
				language: this.domain.language.getOne(dbsProgram.languageId),
				programType: this.domain.programType.getOne(dbsProgram.programTypeId),
			};
		});

		return programs;
	}
}