import { ACC_MATCHING_FULL_DAY } from "@me-interfaces";

/**
 * Given a string representation of meeting times, shift them a number of hours forward or back
 */
export function shiftMeetings(dayMeetings: string, shiftedHours: number): string {

	
	if (shiftedHours == 0) return dayMeetings;

	const emptySlots = Array(Math.abs(shiftedHours) + 1).join('-');	// + 1 because join inserts separators

	//
	// Either insert a dash for each shifted hour at the beginning of the day
	//
	if (shiftedHours < 0) dayMeetings = (emptySlots + dayMeetings).substring(0, 20);

	//
	// ...or at the end of the day
	//
	else dayMeetings = (dayMeetings + emptySlots).substring(emptySlots.length);


	//
	// Fix the hour values
	//
	const hours = dayMeetings.split('');
	for (let i = 0; i < hours.length; i++) {
		if (hours[i] !== '-') hours[i] = ACC_MATCHING_FULL_DAY[i];
	}


	return hours.join('');
}