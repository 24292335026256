import { Component, Input, OnChanges } from '@angular/core';
import { DbcUser, DbsPerson, PhoneTypeId } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { DialogService } from '@me-services/ui/dialog';
import { EntityEditorDialog } from '@me-shared-parts/ED-editors';
import { EntityEditorData } from '@me-shared-parts/ED-editors/dialog/entity-editor-data';
import { PERSON_EDITOR_KEYS } from '@me-shared-parts/ED-editors/person-editors/SHR-ED_person-editors.part';
import { MenuAction } from './menu-action';

@Component({
	selector: 'contact-actions-menu',
	template: `<actions-menu [actions]='actions' [icon]='icon' tooltip='Contact Actions' [header]='header' [location]='location'></actions-menu>`,
})
export class ContactActionsMenuPart implements OnChanges {

	@Input() header: string;
	@Input() location: '' | 'toolbar' | 'grid' | 'kendo-grid' = '';
	@Input() contact: DbsPerson;
	@Input() user: DbcUser;
	@Input() siteCode: string = undefined;
	@Input() pageClass: string = ContactActionsMenuPart.name;

	public actions: MenuAction[] = [];
	public icon = '';

	constructor(
		private dialogService: DialogService,
	) { }

	ngOnChanges() {
		const c = this.contact;
		this.updateIcon(c);


		this.actions = [
			{
				label: 'Edit Profile',
				linkType: 'callback',
				callback: () => this.editProfile(),
				icon: 'fa-id-card',
				url: '',
			},
			{
				label: 'Edit Tags',
				linkType: "callback",
				callback: () => this.editTags(),
				icon: 'fa-user-tag',
				url: '',
			},
			{
				label: 'Edit Notes',
				linkType: "callback",
				callback: () => this.editNotes(),
				icon: 'fa-sticky-note',
				url: '',
			},
			{ label: `Email ${c.firstName}`, linkType: 'href', url: `mailto:${c._email}`, icon: 'fa-envelope', separated: true },

		];

		if (c.phone && c.phoneTypeId) {
			this.actions.push({ label: `Call ${c.firstName}`, linkType: 'href', url: `tel:${c.phone}`, icon: 'fa-phone', separated: true });
			if (c.phoneTypeId == PhoneTypeId.Mobile) {
				this.actions.push({ label: `Text ${c.firstName}`, linkType: 'href', url: `sms:${c.phone}`, icon: 'fa-comment-alt-lines' });
			}
		}
	}

	updateIcon(c: DbsPerson) {
		this.icon = '';
		const noteCounts = c._entityExtracts.noteCounts;
		if (noteCounts && noteCounts.redFlags > 0) this.icon = 'fas fa-flag';
		else if (noteCounts && noteCounts.total > 0) this.icon = 'fa-sticky-note';
		else this.icon = 'fa-ellipsis-v';

	}

	async editTags() {
		const tagEditorData: EntityEditorData = {
			entityId: this.contact.entityId,
			selectedEditor: PERSON_EDITOR_KEYS.TAGS,
			user: this.user,
			initialSelectorKey: `selectable-view-prefix-name-${this.siteCode.toLowerCase()}`,
		};

		await this.dialogService.showCustom(
			EntityEditorDialog,
			{ instanceIdentifier: this.pageClass, data: tagEditorData },
			950, 579);
	}

	async editNotes() {
		const notesEditorData: EntityEditorData = {
			entityId: this.contact.entityId,
			selectedEditor: PERSON_EDITOR_KEYS.NOTES,
			user: this.user,
		};

		await this.dialogService.showCustom(
			EntityEditorDialog,
			{ instanceIdentifier: this.pageClass, data: notesEditorData },
			950, 579);
	}

	async editProfile() {
		const profileEditorData: EntityEditorData = {
			entityId: this.contact.entityId,
			selectedEditor: PERSON_EDITOR_KEYS.PROFILE,
			user: this.user,
		};

		await this.dialogService.showCustom(
			EntityEditorDialog,
			{ instanceIdentifier: this.pageClass, data: profileEditorData },
			950, 579);
	}
}
