import { AppAreaAccess, AppAreaApplyResponse, AppAreaFuncResponse, AppAreaIdentifier, AppAreaIdentifierAndAccess, AppAreaName, AppAreaState, DbcUser, UpdatedFormField } from '@me-interfaces';
import { ReadonlyBehaviorSubject, UtilityService } from '@me-services/core/utility';
import { PageSpinnerService } from '@me-services/ui/page-spinner';
import { Observable, ReplaySubject, Subject, combineLatest, lastValueFrom } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, share, take, takeUntil } from 'rxjs/operators';
import { DataService } from '../data';
import { DexieService } from '../dexie';


export type LimitTo = 'Public' | 'Staff' | 'EforAllAdmin' | 'TechAdmin';


/**
 * Base functionality used by all area services.
 */
export abstract class AppAreaService<IDENTIFIER extends AppAreaIdentifier<string | number>, ACCESS extends AppAreaAccess, RAW_DATA, PREPARED_DATA> {

	/**
	 * STATIC subject is used to push area-specific states to the related AppAreaService instance.
	 * These are the set of states returned from a func in an AppAreaFuncResponse.
	 */
	protected static readonly returnedStatesStatic$ = new ReplaySubject<AppAreaState<AppAreaAccess, unknown>[]>(1);


	/**
	 * The current state values for this service instance.
	 */
	private _areaState$ = new ReplaySubject<AppAreaState<ACCESS, RAW_DATA>>(1);


	/**
	 * Use to observe the latest data.  Action responses are sent to applyResponse which updates
	 * the _rawData$ observable. Whenever the raw data or the singletons are changed then this
	 * observable will report out the combine changes.
	 */
	private readonly _data$ = combineLatest([this._areaState$, this.ds.admin.singletonsAsOfUTC$])
		.pipe(
			mergeMap(
				async latest => {
					const [areaState, singletonsAsOfUTC] = latest;
					const rawData = areaState?.rawData;
					return (rawData && singletonsAsOfUTC) ? await this.prepareAreaData(rawData) : undefined;
				},
			),
			share(),
		);


	/**
	 * Use to observe the latest data.
	 */
	public readonly data$ = new ReplaySubject<PREPARED_DATA>(1);


	public readonly access$ = this._areaState$
		.pipe(
			distinctUntilChanged((a1, a2) => a1?.md5Hash === a2?.md5Hash),
			map(state => state?.access),
			// tap(state => { this.util.log.techMessage(`${this.areaName} access$ RETRIEVED`, { extra: state, extraLogging: 'ExtraToConsole' }) }),
		);


	/**
	 * Emits the app area identifier (e.g. acc's siteId and accId)
	 * Consumers can monitor accessAndId$ to programatically enable
	 * or disable update functionality, or to show <no-access>
	 */
	public readonly accessAndId$: Observable<AppAreaIdentifierAndAccess<IDENTIFIER, ACCESS>> = combineLatest([this.access$, this.id$])
		.pipe(
			map(([access, id]) => ({ access, id }))
		);


	private _id: IDENTIFIER;
	private funcGet: () => Promise<AppAreaFuncResponse>;
	private defaultFuncGet: () => Promise<AppAreaFuncResponse>;
	private noopFuncGet: () => Promise<AppAreaFuncResponse>;


	/**
	 * Monitor when the content is being loaded to show <content-loading>
	 */
	public readonly loading$ = new ReadonlyBehaviorSubject(true);


	/**
	 * The last used identifier is remembered in case reload() is called
	 * and can be read because it must be passed to update action functions.
	 */
	public getId() { return this._id; }




	/**
	 * @param areaName A simple name that describes the subclassed service. e.g. "Communities"
	 * @param defaultFuncGet A FuncService function that will be called to load data if user meets the limitTo.
	 * @param noopFuncGet A fake/ship function call that returns no access and empty rw data. Used if user does not meet the limitTo.
	 * @param prepareAreaData A function to map the raw data from funcGet to processed data.
	 * @param getName A function that will extract a displayable name from the processed data.
	 */
	constructor(
		limitTo: LimitTo,
		private currentUser$: ReplaySubject<DbcUser | undefined>,
		protected areaName: AppAreaName,
		private dexieService: DexieService,
		protected ds: DataService,
		protected spinnerService: PageSpinnerService,
		protected util: UtilityService,
		private id$: Observable<IDENTIFIER>,
		defaultFuncGet: (payload: { identifier: IDENTIFIER, singletonsCacheUTC: number }) => Promise<AppAreaFuncResponse>,
		noopFuncGet: () => Promise<AppAreaState<ACCESS, RAW_DATA>>,
		private prepareAreaData: (data: RAW_DATA) => Promise<PREPARED_DATA>,
		private getName: (object: PREPARED_DATA) => string,
	) {

		this.defaultFuncGet = this.setupDefaultFuncGet(defaultFuncGet, areaName);
		this.noopFuncGet = this.setupNoopFuncGet(noopFuncGet);


		//
		// Always replay the latest data
		//
		this._data$.subscribe(data => {
			this.data$.next(data);
		});


		//
		// Subscribe to the STATIC observable of returned states and use any for this area. 
		//
		AppAreaService.returnedStatesStatic$
			.pipe(
				map(states => states.find(state => state.area == areaName)),
				filter(state => !!state)
			)
			.subscribe(this.applyState.bind(this));


		//
		// Listen for changes to the current user or the area identifier
		//
		combineLatest([currentUser$, id$]).subscribe(async ([user, id]) => {

			if (!user) {
				this._areaState$.next(undefined);
				return;
			}

			const stateDb = await this.dexieService.getAreaDataDb();

			this._id = id;

			this.funcGet = this.determineFuncGet(id, limitTo, user);


			if (id) {
				//
				// Get the cached response, or load from the database if needed
				//
				const cachedState: AppAreaState<ACCESS, RAW_DATA> = await stateDb.getAreaData(id);

				if (cachedState) {

					this.applyState(cachedState, true);
					this.loading$.superNext(false);

					const response = await this.funcGet();
					this.applyResponse(response);

				}
				else {
					const response = await this.funcGetWithLoadingIndicator();
					this.applyResponse(response);
				}
			}
			else {
				this._areaState$.next(undefined);
			}
		});
	}


	/**
	 * The defaultFuncGet is the standard area get action function for this area service.
	 * This function first checks that is is configured with the noPageSpinner flag and
	 * then wraps it in a function that ensures the function is called in a queue.
	 */
	private setupDefaultFuncGet(defaultFuncGet: (payload: { identifier: IDENTIFIER; singletonsCacheUTC: number; }) => Promise<AppAreaFuncResponse>, areaName: string) {

		// if (defaultFuncGet['action']) {
		// 	if (defaultFuncGet['fanClientFlags']?.noPageSpinner !== true) {
		// 		throw new Error(`The ${areaName} AppAreaService was constructed with func.${defaultFuncGet['action']} which doesn't have FanClientFlags.noPageSpinner set to true.`);
		// 	}
		// }

		const name = `Loading ${this.areaName} Data`;

		const funcGet = async () => {

			//
			// Wait for a user to be logged in to the UserAreaService, which will have returned
			// a SingletonsCacheConfig and syncronously merged in the singletons from the cache
			// prior to setting the user. So, we wait for a user before queing a call to funcGet.
			//
			await this.currentUser$
				.pipe(
					filter(user => !!user),
					take(1),
				)
				.toPromise();

			const response = await this.util.queueRunner(name, async () => {
				const identifier = this.getId();
				if (!identifier) throw new Error(`funcGet() called in ${this.areaName}AreaService with undefined identifier.`);
				const singletonsCacheUTC = await lastValueFrom(this.ds.admin.singletonsAsOfUTC$.pipe(take(1)));
				return await defaultFuncGet({ identifier, singletonsCacheUTC });
			});

			return response;
		};

		funcGet['action'] = name;
		return funcGet;
	}


	private setupNoopFuncGet(noopFuncGet: () => Promise<AppAreaState<ACCESS, RAW_DATA>>) {

		//
		// Ensure that the noop function returns a response
		// with isNoop set to true.
		//
		const origNoopFuncGet = noopFuncGet;

		noopFuncGet = async () => {
			const response = await origNoopFuncGet();
			response.isNoop = true;
			return response;
		};

		return async () => {
			return {
				areaStates: [
					await noopFuncGet()
				]
			};
		};
	}



	/**
	 * Use the provided func getter or the noop one depending upon user flags
	 */
	determineFuncGet(identifier: IDENTIFIER, limitTo: LimitTo, user: DbcUser): () => Promise<AppAreaFuncResponse> {

		const defaultFuncGet = this.defaultFuncGet;
		const noopFuncGet = this.noopFuncGet;
		let funcGet = noopFuncGet;

		if (!identifier) funcGet = noopFuncGet;
		else if (this.areaName == 'User') funcGet = defaultFuncGet;
		else if (!user) funcGet = noopFuncGet;
		else if (limitTo == 'Public') funcGet = defaultFuncGet;
		else if (limitTo == 'Staff') funcGet = user.isCrmUser ? defaultFuncGet : noopFuncGet;
		else if (limitTo == 'EforAllAdmin') funcGet = user.isEForAllAdmin ? defaultFuncGet : noopFuncGet;
		else if (limitTo == 'TechAdmin') funcGet = user.isTechAdmin ? defaultFuncGet : noopFuncGet;

		return funcGet;
	}


	/**
	 * Call funcGet() with the loading observable turned on while waiting. 
	 */
	private async funcGetWithLoadingIndicator(emitLoading = true): Promise<AppAreaFuncResponse> {

		if (emitLoading) this.loading$.superNext(true);
		this.spinnerService.addSpinner(this.funcGet['action']);

		const response = await this.funcGet();

		this.spinnerService.removeSpinner(this.funcGet['action']);
		if (emitLoading) this.loading$.superNext(false);

		return response;
	}


	/**
	 * Every time an action function is called, the returned value should
	 * be passed to this function so updated area states will be applied
	 * to each of the area services.
	 * 
	 * @returns If the response indicates the activity was successful
	 */
	public async applyResponse(response: AppAreaFuncResponse): Promise<AppAreaApplyResponse> {

		const label = `Updating ${this.areaName} Cache`;

		const t = this.util.log.createElapsedTimer(label);

		const spinner = label;
		this.spinnerService.addSpinner(spinner);

		t.addSnapshot(`ds.mergeResponseSingletons()`);
		await this.ds.mergeResponseSingletonsForArea(this.areaName, response);
		AppAreaService.returnedStatesStatic$.next(response.areaStates);

		let result = true;

		if (response.message) {
			t.addSnapshot(`ds.showResponseMessage()`, response.message);
			await this.ds.showResponseMessage(response.message);
			result = response.message.success;
		}

		this.spinnerService.removeSpinner(spinner);
		t.endAndRender();

		return { success: result, insertId: response.insertId };
	}


	/**
	 * Every time an action function is called, the returned value should
	 * be passed to this function so it can be cached and translated into
	 * a working value.
	 */
	private async applyState(areaState: AppAreaState<ACCESS, RAW_DATA>, isFromCached = false) {

		if (this._id?.areaName !== this.areaName) return;

		if (areaState.area !== this.areaName) {
			this.util.log.errorMessage(`${this.areaName}AreaService.applyState() called with ${areaState.area} state. It will be ignored.`);
			return;
		}


		if (!isFromCached) {

			//
			// Cache the response in IndexedDb
			//
			const stateDb = await this.dexieService.getAreaDataDb();
			await stateDb.putAreaData(this._id, areaState.isNoop ? null : areaState);

		}


		//
		// Inform all app area listeners
		//
		this._areaState$.next(areaState);
	}


	/**
	 * @deprecated Use .data$ directly
	 */
	protected mapSubset<SUBSET>(mapper: (object: PREPARED_DATA) => SUBSET): ReadonlyBehaviorSubject<SUBSET> {

		const subject = new ReadonlyBehaviorSubject<SUBSET>(undefined);

		this.data$
			.pipe(
				filter(data => data !== undefined),
				map(data => {
					if (data) {
						const mapped = mapper(data);
						return mapped;
					}
					else return undefined;
				}),

				//
				// Note: Don't call util.values.areSame here because the data is
				//   likely to be so large that the cost of running the internal
				//   stringify() is likely higher than any performance gain from
				//   the distinctUntilChanged.
				//
				// distinctUntilChanged((x, y) => this.util.values.areSame(x, y))
			)
			.subscribe(subset => subject.superNext(subset));

		return subject;
	}


	protected _subscribe<T>(observable: Observable<T>, destroyed$: Subject<void>, callback: (value: T) => Promise<void>) {
		observable
			.pipe(takeUntil(destroyed$))
			.subscribe(callback);
	}



	/**
	 * @deprecated Use super.subscribe(accessAndId$) instead.
	 * 
	 * Should always be called in ngOnInit() after a call to this.initDestroyable();
	 * Subscribe to 'access' changes and automatically unsubscribe when destroyed$ is triggered.
	 */
	public subscribeAccess(destroyed$: Subject<void>, callback: (value: { access: ACCESS, id: IDENTIFIER }) => Promise<void>) {
		this._subscribe(
			this.accessAndId$,
			destroyed$,
			callback
		);
	}


	/**
	 * Reload the data.
	 * @param emitLoading If true (default), the loading$ observable will emit to true while the reload is running
	 */
	public async reload(emitLoading = true) {
		const response = await this.funcGetWithLoadingIndicator(emitLoading);
		this.applyResponse(response);
	}


	/**
	 * This should be overidden by app area sub classes that support the questions module.
	 * @param id Id of the thing this form is associated with (e.g. an applicationId or an accTeamId)
	 */
	protected mergeUpdatedFormFieldValue(
		updatedFormField: UpdatedFormField,
		id: number,
		areaState: AppAreaState<ACCESS, RAW_DATA>,
	) {
		// Default implementation does nothing for area services
		// that do not have any updateable forms.
	}


	/**
	 * Changed form field values will be merged into the app area state data so
	 * that we don't need to make another func call to sync the current cache with
	 * the newly update database change.
	 * @param id Optional. e.g. applicationId or accTeamId. If not provided, the id will be pulled from the AppAreaIdentifier.subPath
	 */
	public async mergeUpdatedFormField(updatedFormField: UpdatedFormField, id?: number) {

		const areaState = await lastValueFrom(this._areaState$.pipe(take(1)));

		if (!id) {
			const identifier: IDENTIFIER = await lastValueFrom(this.id$.pipe(take(1)));
			id = identifier.subPath?.id;
		}

		if (areaState.rawData) {
			this.mergeUpdatedFormFieldValue(updatedFormField, id, areaState);
			await this.applyState(areaState, false);
		}
	}

}