import { AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { AccMatchableMentor, AccMatchingRollups, AccTeam, DbaAccMatchAssessment } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';


interface MMAssessment {
	name: string,
	personId: number,
	mentor?: AccMatchableMentor,
	accTeamId: number,
	team?: AccTeam,
	companyName: string,
	rollups: AccMatchingRollups,
	assessments: {
		name: string,
		company: string | undefined,
		given: number,
		received: number,
	}[],
}


@Component({
	selector: 'acc-mm-assessments-view-part',
	templateUrl: './acc-mm-assessments-view.part.html',
	styleUrls: ['./acc-mm-assessments-view.part.scss'],
})
export class AccMMAssessmentsViewPart implements OnInit {
	orderSelectedItem: DroplistItem<string>;
	private readonly _order$ = new BehaviorSubject('mt');

	orderList: DroplistItem<string>[] = [
		{ uniqueId: 1, data: 'mt', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Mentor, Team') },
		{ uniqueId: 2, data: 'tm', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Team, Mentor') },
	];

	data$: Observable<{ mentorsData: MMAssessment[], teamsData: MMAssessment[] }>;
	people$: Observable<MMAssessment[]>;

	constructor(
		public accAreaService: AccAreaService,
		private ds: DataService,
		private util: UtilityService,
	) {
	}

	ngOnInit(): void {
		const i = 0;
		this.orderSelectedItem = this.orderList.find(order => order.data == this._order$.value);

		this.data$ = combineLatest([
			this.accAreaService.mentorMatching.matchableMentors$,
			this.accAreaService.teams.teams$,
			this.accAreaService.mentorMatching.teamsRollup$,
			this.accAreaService.mentorMatching.mentorsRollup$,
			this.accAreaService.mentorMatching.assessments$
		]).pipe(map(data => this.buildData(data[0], data[1], data[2], data[3], data[4])));

		this.people$ = combineLatest([
			this.data$,
			this._order$
		]).pipe(
			map(data => {
				if (data[1] == 'mt') return data[0].mentorsData;
				else return data[0].teamsData;
			})
		);
	}


	buildData(
		mentors: readonly AccMatchableMentor[],
		teams: readonly AccTeam[],
		teamsRollup: { [key: number]: AccMatchingRollups },
		mentorsRollup: { [key: number]: AccMatchingRollups },
		assessments: readonly DbaAccMatchAssessment[],
	) {
		if (!mentors || !teams) return { mentorsData: [], teamsData: [] };

		const teamsMap = this.util.array.toMap<number, AccTeam>(teams.filter(team => !team.droppedOutWeek), team => team.accTeamId);
		const mentorsMap = this.util.array.toMap<number, AccMatchableMentor>(mentors, mentor => mentor.personId);

		const mentorsData: MMAssessment[] = [];
		for (const mentor of mentors) {
			mentorsData.push({
				name: mentor.person.fullName,
				personId: mentor.personId,
				mentor,
				accTeamId: undefined,
				team: undefined,
				companyName: undefined,
				rollups: mentorsRollup[mentor.personId],
				assessments: assessments
					.filter(assessment => assessment.personId == mentor.personId && teamsMap[assessment.accTeamId])
					.map(assessment => {
						return {
							given: assessment.m2t,
							received: assessment.t2m,
							name: teamsMap[assessment.accTeamId].application.person._name,
							company: teamsMap[assessment.accTeamId].name,
						}
					}).sort((a, b) => a.name < b.name ? -1 : 1),
			});
		}


		const teamsData: MMAssessment[] = [];
		for (const team of teams.filter(team => !team.droppedOutWeek)) {
			teamsData.push({
				name: team.application.person._name,
				personId: undefined,
				mentor: undefined,
				accTeamId: team.accTeamId,
				team,
				companyName: team.name,
				rollups: teamsRollup[team.accTeamId],
				assessments: assessments
					.filter(assessment => assessment.accTeamId == team.accTeamId && mentorsMap[assessment.personId])
					.map(assessment => {
						return {
							given: assessment.t2m,
							received: assessment.m2t,
							name: mentorsMap[assessment.personId].person.fullName,
							company: undefined,
						}
					}).sort((a, b) => a.name < b.name ? -1 : 1),
			});
		}

		return { mentorsData: mentorsData.sort((a, b) => a.name > b.name ? 1 : -1), teamsData: teamsData.sort((a, b) => a.name > b.name ? 1 : -1) };

	}


	changeOrder(e: DroplistItem<string>) {
		this.orderSelectedItem = e;
		this._order$.next(e.data);
	}

}