import { Accelerator, DbConceptName, Event } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { UrlService } from "@me-services/ui/url";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForEvents(util: UtilityService, ds: DataService, urlService: UrlService, accelerator: Accelerator): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const events: Event[] = (await ds.admin.event.getAllPackagesAsArray()).filter(event => event.accId == accelerator.accId);

	for (const event of [...events].sort((a, b) => a.name > b.name ? 1 : -1)) {
		const eventType = ds.domain.eventType.getOne(event.eventTypeId).name;
		const { enabled, link } = util.event.getAccToolStatusAndLink(event, accelerator);
		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Event,
			header: `${eventType} Event`,
			buttons: [
				{ explorer: { explorableId: { conceptName: DbConceptName.Event, id: event.eventId }, navTooltip: `Event: ${event.name}` } },
				{ link: { linkType: 'tool', url: `${urlService.getBaseUrl(accelerator.siteProgram.program.languageId)}${link}`, enabled } }
			],
			notes: util.event.getStatus(event),
		};

		if (event.venueId) box.buttons.push({ explorer: { explorableId: { conceptName: DbConceptName.Venue, id: event.venueId }, navTooltip: `Venue: ${event.venue.name}` } },);
		else box.buttons.push({ explorer: { nameOverride: 'Online', explorableId: { conceptName: DbConceptName.Event, id: event.eventId }, navTooltip: `Venue: Online` } },);

		boxes.push(box);
	}

	return boxes;
}