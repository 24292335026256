import { AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccJudge, Answer, DbaAccJudgement } from '@me-interfaces';
import { DataService } from '@me-services/core/data';


interface CountsData {
	judgeCount: number,
	judgePersonIds: number[],
	assessmentCount: number,
}


@Component({
	selector: 'acc-judge-counts-part',
	templateUrl: './acc-judge-counts.part.html',
	styleUrls: ['./acc-judge-counts.part.scss'],
})
export class AccJudgeCountsPart extends DestroyablePart implements OnInit {

	readonly: boolean;
	countsData: CountsData;

	constructor(
		private accAreaService: AccAreaService,
		private ds: DataService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe(
			[
				this.accAreaService.accessAndId$,
				this.accAreaService.judging.judges$,
				this.accAreaService.judging.assessments$,
			],
			async ([access, judges, assessments]) => {
				this.readonly = access?.access?.root !== "Write";
				await this.buildData(judges, assessments);
			}
		);
	}



	async buildData(
		judges: readonly AccJudge[],
		judgeAssessments: { judgement: DbaAccJudgement, answers: readonly Answer[], }[]
	): Promise<void> {

		const judgePersonIds: number[] = judges?.map(r => r.personId);

		this.countsData = {
			judgeCount: judges?.length,
			judgePersonIds,
			assessmentCount: judgeAssessments?.length,
		};

	}


	async openContactor(personIds: number[]) {
		await this.ds.contactor.show(
			this.readonly,
			personIds,
			'acc-judge-counts-part',
		);
	}
}