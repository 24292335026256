import { DbConceptName, Person } from "@me-interfaces";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForInterview(ds: DataService, person: Person): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const accInterviewersByPersonId = await ds.admin.accInterviewer.getByPersonIds([person.personId]);
	const accInterviewers = accInterviewersByPersonId[person.personId];

	const interviewers = accInterviewers
		.filter(interviewer => interviewer.interviewedApplicationIds.length > 0)
		.map(interviewer => ({ applStartUTC: interviewer.accelerator.applStartUTC, interviewer }))
		.sort((a, b) => b.applStartUTC - a.applStartUTC);


	for (const interviewer of interviewers) {
		const site = interviewer.interviewer.accelerator.siteProgram.site;

		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Interview,
			header: 'Interviewer',
			buttons: [
				{ explorer: { explorableId: { conceptName: DbConceptName.Site, id: site.siteId }, navTooltip: `Site: ${site.name}` } },
				{ explorer: { nameOverride: `${interviewer.interviewer.accelerator.name} Accelerator`, explorableId: { conceptName: DbConceptName.Accelerator, id: interviewer.interviewer.accelerator.accId }, navTooltip: `Accelerator: ${site.code} ${interviewer.interviewer.accelerator.name}` } }
			],
			notes: `Applicants Interviewed: ${interviewer.interviewer.interviewedApplicationIds.length}`,
		};

		boxes.push(box);
	}

	return boxes;
}