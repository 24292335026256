<div class='explorer-part event-explorer-part'>

	<div class="icon-bar">
		<me-icon-bar #iconBar [icons]='views' [orientation]="'Vertical'" (selectionsChange)='setViews($event)'>
		</me-icon-bar>
	</div>

	<div class="content">
		<ng-container *ngIf='selectedViews'>
			<div class="explorer-header">
				<me-icon [icon]="icons.event"></me-icon>
			</div>
			<h4 class="explorer-sub-header">{{eventType.fullName}} Event</h4>

			<section>
				<explorer-buttons [buttons]='buttons'>
					<link-button *ngIf="toolLink && toolLink.enabled" [link]="toolLink"></link-button>
					<button *ngIf="toolLink && !toolLink.enabled" class="text-btn text-btn-not-inline"
						(click)="showMessage()">
						<me-icon [icon]="icons.toolDisabled"></me-icon>
					</button>
				</explorer-buttons>
			</section>

			<explorer-section header='Event Details' (tabClose)="tabClose($event)"
				[style.display]="getDisplay('details')">
				<event-explorer-details [event]="event"></event-explorer-details>
			</explorer-section>

			<explorer-section [header]="util.text.singularOrPluralWithCount(awardBoxes.length, 'Award', 'Awards')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('awards')">
				<explorer-boxes>
					<ng-container *ngFor="let box of awardBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

		</ng-container>
	</div>
</div>