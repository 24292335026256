<app-area-access [areaService]='accAreaService'>
	<ng-container *ngIf='accAreaService.accelerator.acc$ | async; let acc'>
		<div class='acc-cohort-part'>
			<me-toolbar icon='Acc'>
				<acc-toolbar-buttons></acc-toolbar-buttons>
			</me-toolbar>

			<page-tabs [tabNames]='["Manage", "Teams", "Curriculum", "Final", "Quarterlies"]'
				[pageService]='pageService' (layoutChange)='onLayoutChange($event)'>

				<ng-template pageTab1>
					<acc-cohort-views-part></acc-cohort-views-part>
				</ng-template>

				<ng-template pageTab2>
					<acc-teams-tab-part></acc-teams-tab-part>
				</ng-template>

				<ng-template pageTab3>
					<acc-curriculum-tab-part></acc-curriculum-tab-part>
				</ng-template>

				<ng-template pageTab4>
					<acc-final-tab-part></acc-final-tab-part>
				</ng-template>

				<ng-template pageTab5>
					<acc-quarterlies-tab-part></acc-quarterlies-tab-part>
				</ng-template>
			</page-tabs>
		</div>
	</ng-container>

</app-area-access>