import { Component, Input, OnChanges } from '@angular/core';
import { PublicPersonForDialog } from '@me-interfaces';
import { DialogService } from '@me-services/ui/dialog';
import { getIconContext, Icon, IconContext, IconHideTextAt } from '@me-shared-parts/UI-common';
import { PublicPersonDialog } from '../public-person-dialog/SHR-PN_public-person-dialog.dialog';


@Component({
	selector: 'public-person-button',
	templateUrl: './SHR-PN_public-person-button.part.html',
	styleUrls: ['./SHR-PN_public-person-button.part.scss']
})
export class PublicPersonButtonPart implements OnChanges {

	@Input() person: PublicPersonForDialog;
	@Input() hideText?: IconHideTextAt;
	@Input() showAddress = false;

	icon: IconContext = getIconContext(Icon.concept_person, undefined, this.hideText || 'never', '');


	constructor(private dialogService: DialogService) {
	}

	ngOnChanges() {
		this.icon = getIconContext(Icon.concept_person, undefined, this.hideText || 'never', this.person._name);
	}


	async open() {
		await this.dialogService.showCustom(
			PublicPersonDialog,
			{
				data: {
					person: this.person,
				},
			},
			800, 500);
	}
}