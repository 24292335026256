import { MenuItem } from "@me-interfaces";


export const adminMenus: MenuItem[] = [
	{
		label: 'National',
		routerLink: '/access/admin/national',
		icon: 'fas fa-earth-americas',
		separator: true,
	},
	{
		label: 'Sites',
		routerLink: '/access/admin/national/sites',
		icon: 'fas fa-map-marker-alt',
	},
	// {
	// 	label: 'Staff',
	// 	routerLink: '/access/admin/staff',
	// 	icon: 'fas fa-user',
	// },
];