<div class="agreement-text-part" *ngIf="agreementContentDisplay">

	<div class="agreement-body" [style.height]="agreementBodyDivSize">
		<div class="agreement-introduction" *ngIf="!closed">

			<div markdown class='markdown-text agreement-intro' [data]='agreementContentDisplay.introduction'></div>
			<button class="alert-close" type="button" [attr.aria-label]="'alert_close'" (click)="close()">
				<span aria-hidden="true">
					X
				</span>
			</button>
		</div>

		<div markdown class='markdown-text agreement-text' [data]='agreementContentDisplay.agreement'></div>

		<agreement-signature *ngIf="!alreadySigned" [readonly]="readonly" (agreementSigned)="signed.emit($event)"></agreement-signature>


	</div>


	<div *ngIf="includeLastEditedBy" class="agreement-footer">
		<i><small>Last Updated on {{agreementContentDisplay.updatedDate}}</small></i>
	</div>
</div>