import { AccAreaService, AccCohortPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { AccTeamCols, GridColumnType, GridSetup, GridValueStyle } from '@me-grid';
import { AccAreaAccess, AccTeam } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogAction } from '@me-services/ui/dialog';
import { Icon } from '@me-shared-parts/UI-common';
import { EditTextDialog } from '@me-shared-parts/UI-common/dialogs';
import { combineLatest } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


const OPEN_APPLICATION_ACTION_KEY = 'open-application';
const EDIT_NOTES_ACTION_KEY = 'edit-notes';

interface TeamRow {
	accTeamId: number,
	teamNotes: string,
	applicationId: number,
}


@Component({
	selector: 'acc-cohort-teams-view-part',
	templateUrl: './acc-cohort-teams-view.part.html',
})
export class AccCohortTeamsViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();
	quarterlyEnabledStr: string;
	readonly: boolean;


	rows$ = combineLatest([
		this.accAreaService.access$,
		this.accAreaService.teams.teams$,
	]).pipe(mergeMap(data => this.buildRows(data[0], data[1])));

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private accAreaService: AccAreaService,
		private ds: DataService,
		private pageService: AccCohortPageService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

	async buildRows(
		access: AccAreaAccess,
		accTeams: readonly AccTeam[],
	): Promise<TeamRow[]> {

		this.readonly = access?.root !== 'Write';

		if (!accTeams) return [];

		return accTeams.map(accTeam => ({
			accTeamId: accTeam.accTeamId,
			teamNotes: accTeam.notes,
			applicationId: accTeam.applicationId,
		}));

	}

	private valueStyle(row: TeamRow, field: string): GridValueStyle {
		const value = row[field];

		if (field == 'teamNotes') {
			if (value) return { icon: 'fas fa-file-alt', textStyle: 'Hidden' };
			else return { icon: 'far fa-file', textStyle: 'Hidden' };
		}

		return undefined;
	}


	private setupGrid(): GridSetup<TeamRow> {
		const valueStyle = this.valueStyle.bind(this);

		return {
			experience: 'ACCTEAM',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Team",
			rowPluralName: "Teams",
			rowKey: "accTeamId",
			stateKey: "adm-acc-cohort-teams-page",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: "teamNotes", header: "Notes", headerTooltip: 'Team Notes', width: 60, type: GridColumnType.text, valueStyle, noColumnMenu: true },
			],
			columnsToAlter: [
				{ field: AccTeamCols.entrepreneurNames, width: 200, hidden: false },
				{ field: AccTeamCols.mentorNames, width: 200, hidden: false },
				{ field: AccTeamCols.companyCityAndState, width: 200, hidden: false },
			],
			actions: [
				{ key: OPEN_APPLICATION_ACTION_KEY, icon: Icon.concept_application, label: 'Open Application', enabled: false },
				{ key: EDIT_NOTES_ACTION_KEY, icon: Icon.action_editNotes, label: 'Edit Team Notes', enabled: false },
			],
			initialState: {
				sort: [{ field: AccTeamCols.companyName, dir: 'asc' }],
			},
		};
	}


	async gridActionHandler(action: { actionKey: string, rows: TeamRow[] }) {

		const row = action.rows[0];

		if (action.actionKey == OPEN_APPLICATION_ACTION_KEY) {
			if (action.rows[0].accTeamId) {
				this.router.navigate([`../pre-accelerator/${action.rows[0].applicationId}`], { relativeTo: this.route });
			}
		}
		else if (action.actionKey == EDIT_NOTES_ACTION_KEY) {
			const action: DialogAction<{ text: string }> = await this.ds.dialogService.showCustom(
				EditTextDialog,
				{
					data: { text: row.teamNotes, readonly: this.readonly, header: 'Edit Team Notes', },
				},
				350, 220
			);

			const id = action?.id;
			if (id == 'save') {
				if (this.readonly) return;
				if (action.callbackResult) {
					//
					// Update notes
					//
					const notes = action.callbackResult.text;

					await this.accAreaService.teams.actions.setNotes({ accTeamId: row.accTeamId, notes });
				}
			}
			return;

		}

	}



}