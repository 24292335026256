import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

//import { Answer }     from '../../../../../state/site/site.state';

@Component({
	selector: 'readonly-questions',
	templateUrl: './SHR-CMN_readonly-questions.part.html',
	styleUrls: ['./SHR-CMN_readonly-questions.part.scss']
})
export class ReadonlyQuestionsPart {
	@Input() answers; //: Answer[];
	@Input() stepper; //: QuestionStepper[];
	@Input() checkboxes = true;

	hasAnswer(answer:string) {
		return answer !== null && answer !== '';
	}
}