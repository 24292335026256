<div class='events-venue-part'>
	<button *ngIf="showLoadButton" class="btn" (click)="loadEvents()">Load Events</button>

	<ng-container *ngIf="!showLoadButton">
		<ng-container *ngIf="rows">
			<me-grid [setup]='gridSetup' [rows]='rows'></me-grid>
		</ng-container>

		<ng-container *ngIf="!rows">
			There are no events associated with the venue, you can delete the venue if you wish.
			<button class="btn" (click)="deleteVenue()">Delete Venue</button>
		</ng-container>
	</ng-container>
</div>