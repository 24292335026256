import { AccApplicationContext, AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId, ApplicationStatusId, DbaAccReading, Event, EventTypeId } from '@me-interfaces';
import { Icon, IconContext, getIconClass } from '@me-shared-parts/UI-common';
import { AccPreAcceleratorStageId } from '../../../acc-application-status-helpers';


export interface StatusIcon {
	icon: IconContext;
	link?: string;
}


@Component({
	selector: 'acc-read-checks-part',
	templateUrl: './acc-read-checks.part.html',
})
export class AccReadChecksPart extends DestroyablePart implements OnInit {

	accStageId: AccPreAcceleratorStageId = AccStageId.Reading;

	readersStatusIcon: StatusIcon;
	applicationsStatusIcon: StatusIcon;
	eventStatusIcon: StatusIcon;


	constructor(public accAreaService: AccAreaService) {
		super();
	}


	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe([this.accAreaService.accelerator.acc$, this.accAreaService.applications.applications$, this.accAreaService.accelerator.events$, this.accAreaService.reading.readers$, this.accAreaService.reading.assessments$], async ([acc, apps, events, readers, assessments]) => {
			this.setApplicationsStatusIcon(apps?.filter(a => a.application.applicationStatusId >= ApplicationStatusId.AcceptSubmitted), assessments?.map(a => a.reading));
			this.setReadersStatusIcon(readers?.length, apps?.filter(a => a.application.applicationStatusId >= ApplicationStatusId.ReadPending).length);
			this.setEventStatusIcon(events?.find(e => e.eventTypeId == EventTypeId.AccReading));
		});
	}


	setApplicationsStatusIcon(apps: readonly AccApplicationContext[], readings: DbaAccReading[]) {
		const minReadings = apps?.reduce((min: number, app) => {
			const readingCount = readings?.filter(r => !!r.rating && r.applicationId == app.application.applicationId).length;
			if (min == undefined) min = readingCount;
			else if (readingCount < min) min = readingCount;
			return min;
		}, undefined);

		const icon: Icon = apps?.length > 0 && minReadings >= 3 ? Icon.status_success : Icon.status_x;
		const link = '';
		const text = apps?.length == 0 ? '0 Applications' : `Each Application has at least ${minReadings} Reading${minReadings != 1 ? 's' : ''}`;

		this.applicationsStatusIcon = {
			icon: {
				icon,
				iconClass: getIconClass(icon),
				text,
			},
			link: apps?.length > 0 ? undefined : link,
		};
	}


	setReadersStatusIcon(totalReaders: number, totalApps: number) {
		const appsPerReader: number = totalReaders > 0 ? (totalApps / totalReaders) : totalApps;
		const icon: Icon = totalReaders > 0 && appsPerReader <= 10 ? Icon.status_success : Icon.status_x;
		const link = '';
		const text = totalReaders == 0 ? `0 Readers` : `${appsPerReader?.toFixed(2)} Application${appsPerReader != 1 ? 's' : ''} per Reader`;

		this.readersStatusIcon = {
			icon: {
				icon,
				iconClass: getIconClass(icon),
				text,
			},
			link: totalReaders > 0 ? undefined : link,
		};
	}


	setEventStatusIcon(event: Event) {
		const now = Date.now() / 1000;
		const toolOpen = (now > event?.toolStartUTC && now < event?.toolEndUTC);
		const icon: Icon = !!event && toolOpen ? Icon.status_success : Icon.status_x;
		const link = '';
		const text = !event ? 'No Reading Event' : toolOpen ? 'Reading Tool Open' : 'Reading Tool Closed';

		this.eventStatusIcon = {
			icon: {
				icon,
				iconClass: getIconClass(icon),
				text,
			},
			link: !!event ? undefined : link,
		};
	}


}
