import { Component, OnInit } from "@angular/core";
import { FieldKindAddress } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { DialogActivity, DialogService } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { BaseFieldPart } from '../base/SHR-UI_base-field.part';
import { AddressFieldDialog } from './dialog/SHR-UI_address-field.dialog';
import { renderAddress } from './render-address';
import { UtilityService } from "@me-services/core/utility";


@Component({
	selector: 'address-field',
	templateUrl: './SHR-UI_address-field.part.html',
	styles: [`
		.address-field-button {
			width: 100%;
			margin: 0;
			padding: 1px 0.5em;
			font-size: 1em;
			line-height: 22px;
			border-radius: 1px;
			text-align: left;
			box-shadow: none;
			background-color: #FFF;
		}

		.address-field-button > pre {
			white-space: pre-wrap;
			font-size: 1em;
			min-height: 2em;
    		padding: 0.2em;
		}

		.address-field-button:hover {
			border-color: rgba(0, 0, 0, 0.15);
    		box-shadow: none;
    		transform: scale(1.00);
    		background-color: #FFF;
		}

		.address-field-button:focus {
			border-color: rgba(0, 0, 0, 0.15);
    		color: #656565;
    		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03), 0 4px 5px 0 rgba(0, 0, 0, 0.04);
		}

		.address-field-button:active {
			transform: scale(1.03, 1.25) !important;
		}
	`],
})

export class AddressFieldPart extends BaseFieldPart<FieldKindAddress> implements OnInit {

	readonly INSTANCE_ID = uuidv4();

	basePart: BaseFieldPart<FieldKindAddress> = this;
	renderAddress = renderAddress;

	preText: string;

	constructor(
		protected override func: FuncService,
		private util: UtilityService,
		protected override labelsService: LabelsService,
		private dialogService: DialogService,
	) {
		super(func, labelsService);

		dialogService.activity
			.pipe(takeUntil(this.destroyed$))
			.subscribe(this.dialogActivityHandler.bind(this));

	}

	override cleanValue(value: FieldKindAddress): FieldKindAddress {
		value.zipId = value.zipId ?? null;
		value.address = value.address?.trim() || null;
		if (value.address) value.address = this.util.text.fixCase(value.address);
		return value;
	}


	public async editAddress() {

		await this.dialogService.showCustom(
			AddressFieldDialog,
			{ instanceIdentifier: this.INSTANCE_ID, data: this },
			400, 380);
	}


	async dialogActivityHandler(activity: DialogActivity) {
		if (activity.instanceIdentifier != this.INSTANCE_ID) return;

		if (activity.action?.id == 'save') {
			await this.attemptToSave();
		}
		else if (activity.action?.id == 'cancel') {
			this.setPendingValue(this.currentValue);
		}
	}


	override valuesAreSame(value1: FieldKindAddress, value2: FieldKindAddress): boolean {
		if (!value1 || !value2) return false;
		if (value1.inUS != value2.inUS) return false;
		if (value1.address != value2.address) return false;
		if (value1.zipId != value2.zipId) return false;
		return true;
	}


	override checkForAdditionalError(): boolean {

		if (this.status != undefined && this.field.required) {

			if (this.pendingValue.inUS == 'Y' && (!this.pendingValue.address || !this.pendingValue.zipId)) {
				this.errorText = 'All fields are required';
				return true;
			}

			if (this.pendingValue.inUS == 'N' && !this.pendingValue.address) {
				this.errorText = 'All fields are required';
				return true;
			}
		}

		return false;
	}


	override hasPendingValue(): boolean {
		return this.hasAddressValue(this.pendingValue);
	}


	override hasCurrentValue(): boolean {
		return this.hasAddressValue(this.currentValue);
	}


	private hasAddressValue(value: FieldKindAddress): boolean {

		if (!value) return false;

		if (value.inUS == 'Y') {
			return !!value.address && !!value.cityAndState;
		}
		else {
			return !!value.address;
		}
	}
}