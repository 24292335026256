import { Injectable } from '@angular/core';
import { DbsEvent, EventContext, TeamsForAward } from '@me-interfaces';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { DataService } from '@me-services/core/data';
import { OldDomainDataService } from '@me-services/core/old-dd';
import { DialogAction, DialogData, DialogService } from '@me-services/ui/dialog';
import { EventDialog } from './dialog/SHR-ED_event.dialog';
import { EventDialogResult } from './SHR-ED_event-dialog-result';


@Injectable({ providedIn: 'root' })
/**
 * Provides utilities for editing and adding dialogs including
 * ability to launches a dialog for user interaction.
 */
export class ShowEventDialogService {

	constructor(
		private dialogService: DialogService,
		private dd: OldDomainDataService,
		public userService: OldUserService,
		private ds: DataService,
	) {
	}

	/**
	 * Record any changes returned from the dialog.
	 */
	private recordResult(result: EventDialogResult) {
		if (result?.action == 'DELETED') {
			this.ds.admin.event.removeEvent(result?.event.eventId);
		}
	}


	/**
	 * Launches the event dialog to edit an existing event.
	 * Informs the DataService and the passed-in manager of any changes.
	 * Usually the a sub area will implement the EventManagement interface.
	 * @param viewOnly If true, the edit table will be hidden. Default: false 
	 * @param teamsForAward the list of teams for awards section
	 */
	async edit(event: DbsEvent, viewOnly = false, teamsForAward: TeamsForAward[] = []) {
		this.recordResult(await this.show('EDIT', event, undefined, viewOnly, teamsForAward));
	}


	/**
	 * @deprecated Use edit() in conjunction with an app area instead.
	 */
	async showEdit(event: DbsEvent, viewOnly = false): Promise<EventDialogResult | undefined> {
		return this.show('EDIT', event, undefined, viewOnly, []);
	}


	/**
	 * Launches the event dialog to add a new event.
	 * Informs the DataService and the passed in manager of any changes.
	 * Usually the a sub area will implement the EventManagement interface.
	 */
	async add(newEvent: EventContext, viewOnly = false) {
		this.recordResult(await this.show('ADD', undefined, newEvent, false, []));
	}


	/**
	 * @deprecated Use add() in conjunction with an app area instead.
	 */
	async showAdd(newEvent: EventContext): Promise<EventDialogResult | undefined> {
		return this.show('ADD', undefined, newEvent, false, []);
	}


	/**
	 * Launches the event dialog to add a new event or edit an existing one.
	 * Returns the saved DbcEvent or undefined if it wasn't saved.
	 */
	private async show(layout: 'ADD' | 'EDIT', event: DbsEvent, newEvent: EventContext, viewOnly: boolean, teamsForAward: TeamsForAward[]): Promise<EventDialogResult | undefined> {

		const adding = !!newEvent;

		let height = 470;
		const eventType = this.dd.eventTypes.getById(event?.eventTypeId ?? newEvent?.eventTypeId);
		if (eventType?.toolNameLabelKey) height = 550;  // Add space for Tool Time row 

		const user = await this.userService.getUser();

		const data: DialogData<any> = { data: { layout, event, newEvent, viewOnly, user, teamsForAward } };
		const action: DialogAction<DbsEvent> = await this.dialogService.showCustom(EventDialog, data, 700, height);

		const id = action?.id;
		const response = action?.callbackResult;

		if (id == 'delete') return { action: 'DELETED', event: response, };
		else if (id == 'save') return { action: adding ? 'ADDED' : 'UPDATED', event: response, };
		else return undefined;
	}
}