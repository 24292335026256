import { GridSetup } from '../interfaces';
import { GroupableGridController } from "./groupable-grid-controller";
import { GridRowSelectionType } from "./types/grid-row-selection-type";


/**
 * @deprecated Use \<me-grid\> instead!
 */
export class GridController<ROW> extends GroupableGridController<ROW> {

	/**
	* @deprecated Use \<me-grid\> instead!
	*/
	constructor(
		idField: string,
		selectionType: GridRowSelectionType,
		stateKey: string,
	) {

		const gridSetup: GridSetup<ROW> = {
			rowKey: idField,
			stateKey,
			rowSingularName: 'Row',
			rowPluralName: 'Rows',
			multiselect: selectionType == 'multiple',
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			size: {
				fitTo: 'INLINE',
				height: 300,
				shrinkBy: 0,
			},
		};

		super(gridSetup);
	}
}