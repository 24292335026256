import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DbcZip, GooglePlace } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction } from '@me-services/ui/dialog';
import { GoogleApiService } from '@me-services/ui/google-api';


@Component({
	selector: 'add-venue-part',
	templateUrl: './SHR-ED_add-venue.part.html',
	styleUrls: ['./SHR-ED_add-venue.part.scss']
})
export class VenueAddPart implements AfterViewInit, OnChanges {

	@Input() zip: DbcZip;
	@Input() addAction: DialogAction;
	@Output() foundPlace = new EventEmitter<GooglePlace>();
	place: GooglePlace = undefined;

	@ViewChild('searchField') searchField: ElementRef;
	@ViewChild('searchMap') searchMap: ElementRef;
	@ViewChild('infowindowContent') infowindowContent: ElementRef;

	map: google.maps.Map;


	constructor(private googleApi: GoogleApiService, private util: UtilityService) {
	}



	ngOnChanges(changes: SimpleChanges) {

		const zipChanges = changes.zip;

		if (zipChanges && this.map) {
			const zip = zipChanges.currentValue;
			this.map.setCenter({ lat: zip.latitude / 100, lng: zip.longitude / 100 });
			this.map.setZoom(17);
		}

	}

	async ngAfterViewInit() {

		const zip = this.zip;
		const maps = await this.googleApi.getMaps();
		const places = await this.googleApi.getPlaces();


		const map = this.map = new google.maps.Map(

			this.searchMap.nativeElement,
			{
				center: { lat: zip.latitude / 100, lng: zip.longitude / 100 },
				zoom: 13,
			}
		);


		const autocomplete = new places.Autocomplete(this.searchField.nativeElement, { types: ["geocode"] });
		autocomplete.bindTo("bounds", map);
		autocomplete.setFields(["address_components", "formatted_address", "utc_offset_minutes", "geometry", "icon", "name", "place_id", "website"]);

		const infowindow = new google.maps.InfoWindow();
		const infowindowContent = this.infowindowContent.nativeElement;
		infowindow.setContent(infowindowContent);

		const marker = new google.maps.Marker({
			map,
			anchorPoint: new google.maps.Point(0, -29),
		});

		const placesService = new google.maps.places.PlacesService(map);

		autocomplete.addListener("place_changed", () => {
			infowindow.close();
			marker.setVisible(false);
			this.foundPlace.next(this.place = undefined);

			const place = autocomplete.getPlace();


			//
			// User entered the name of a Place that was not suggested and
			// pressed the Enter key, or the Place Details request failed.
			//
			if (!place.geometry) return;

			//
			// If the place has a geometry, then present it on a map.
			//
			if (place.geometry.viewport) {
				map.fitBounds(place.geometry.viewport);
			} else {
				map.setCenter(place.geometry.location);
				map.setZoom(17); // Why 17? Because it looks good.
			}

			marker.setPosition(place.geometry.location);
			marker.setVisible(true);

			const zipId = this.getZipId(place.address_components);
			let address = place.formatted_address ?? '';
			if (address.endsWith(', USA')) address = address.substr(0, address.length - 5);

			this.foundPlace.next(this.place = {
				officialName: place.name,
				address,
				zipId,
				placeId: place.place_id,
				latitude: Math.round(place.geometry.location?.lat() * 100) ?? zip.latitude,
				longitude: Math.round(place.geometry.location?.lng() * 100) ?? zip.longitude,
				website: place.website ?? null,
				utcOffset: place.utc_offset_minutes,
			});

			infowindow.open(map, marker);

		});
	}


	getZipId(parts: google.maps.GeocoderAddressComponent[]) {
		const part = parts.find(part => (part.types ?? []).includes('postal_code'));
		if (part) return parseInt(part.short_name, 10);
		else return null;
	}


	logPlace() {
		const p = this.place;
		this.util.log.techMessage(`UPDATE venue SET officialName='${p.officialName}', placeId='${p.placeId}', latitude=${p.latitude}, longitude=${p.longitude}, website='${p.website}', zipId=${p.zipId} WHERE venueId=`);
	}
}