<div class="entity-notes-overview">
	<div class='entity-notes-overview-content'>
		<table class='pro-table pro-table-no-header'>
			<tr>
				<td>
					<button class='btn' (click)='setSelectedNote("add")'>
						<i class="fas fa-plus-circle fa-fw"></i>Add New</button>
				</td>
			</tr>
			<tr *ngIf='!notesOverview.totalNotes'>
				<td>
					<div class='counts-row'>
						<span>There are no notes, be the first to add one!</span>
					</div>
				</td>
			</tr>
			<tr *ngFor='let note of notesOverview.notes'>
				<td>
					<div class='note-display'>
						<span class='note-subject'>
							<i [class]='note.icon'></i>
							<div>
								<p>{{ note.subject }}</p>
								<small>Last edited: {{ note.lastEdited }}</small>
							</div>
						</span>
						<span class='btn' title='Go Edit Note' (click)='setSelectedNote(note.noteId)'><i
								class="fas fa-pencil-alt"></i></span>
					</div>
				</td>
			</tr>
		</table>
		<div class='counts-row' *ngIf='!!notesOverview.totalNotes'>
			<span>Total Notes: {{ notesOverview.totalNotes }}</span>
			<span>Red Flag Notes: {{ notesOverview.redFlags }}</span>
		</div>
	</div>
</div>