import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageId } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { BehaviorSubject } from 'rxjs';
import { AppAreas } from './app-area';
import { determineLanguageFromUrl } from './determine-language-and-configuration';
import { BASE_URL, getBaseUrl } from './get-base-url';


@Injectable({ providedIn: 'root' })
export class UrlService {

	public readonly languageId: LanguageId = LanguageId.English;
	public readonly logo: 'eforall' | 'eparatodos' = 'eforall';
	public readonly baseUrl: BASE_URL;
	public readonly url$ = new BehaviorSubject<string>(undefined);
	public readonly appAreas = new AppAreas(this.router, this.url$, this.util);


	constructor(private router: Router, private util: UtilityService) {

		//
		// Add these four domains to your hosts file to debug in English/Spanish and EforAll
		// https://www.howtogeek.com/howto/27350/beginner-geek-how-to-edit-your-hosts-file/
		//
		// PC: c:\windows\system32\drivers\etc\hosts
		// MAC: /etc/hosts
		//
		// * local.eforall.app (redundant to localhost)
		// * local.eparatodos.app
		//

		const { languageId, isSpanish } = determineLanguageFromUrl();

		this.languageId = languageId;
		this.logo = isSpanish ? 'eparatodos' : 'eforall';


		const body = document.getElementsByTagName('body')[0];
		body.classList.add(this.logo);

		this.baseUrl = getBaseUrl(this.languageId);

	}

	/**
	 * Deterine the base url for a specific language. The environment and app config
	 * of the running application will also be used.  To get the base url for the
	 * language of the running application, just read the urlService.baseUrl property.
	 * @param languageId 
	 */
	public getBaseUrl(languageId: LanguageId): BASE_URL {
		return getBaseUrl(languageId);
	}
}