import { AccAreaData } from "@ACC-area";
import { UtilityService } from "@me-services/core/utility";


/**
 * Given the teams for one accelerator, determine how well each did in the final presentation as determined by the judges.
 */
export async function calculatePresentationScores(util: UtilityService, data: AccAreaData): Promise<Record<number, number>> {


	const assessments = data.judging.assessments;
	const assessmentsByAccTeamId = util.array.toArrayMap(assessments, a => a.judgement.accTeamId);
	const accTeamIds = util.array.cleanNumericIds(assessments.map(a => a.judgement.accTeamId));
	const scoreByAccTeamId: Record<number, number> = {};

	for (const accTeamId of accTeamIds) {

		//
		// Judges rate the entrepreneurs with a series of "rating" questions where each answer is 1..5 where
		// 1 is the worst and 5 is the best.  Here we add up how many of each rating value the team received
		// across all judges and all rating questions.
		//
		const answerRatings: [number, number, number, number, number] = [0, 0, 0, 0, 0];

		for (const assessment of assessmentsByAccTeamId[accTeamId]) {

			for (const answer of assessment.answers) {

				if (answer.question.questionTypeId == 4) {
					
					//
					// Rating answers are stored as "1", "2", "3", "4", or "5" in the database
					//
					const rating = (+answer.answer) - 1;
					answerRatings[rating]++;
				}
			}

		}

		scoreByAccTeamId[accTeamId] = util.scoring.calcScore(answerRatings).score;

	}

	return scoreByAccTeamId;
}

