import { ProgramPageService } from '@ADMIN-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';



@Component({
	selector: 'program-overview-view-part',
	templateUrl: './program-overview-view.part.html',
	styleUrls: ['./program-overview-view.part.scss'],
})
export class ProgramOverviewViewPart extends DestroyablePart implements OnInit {


	constructor(
		private util: UtilityService,
		public ds: DataService,
		public pageService: ProgramPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();


	}

}