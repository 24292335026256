import { AccAreaService } from '@ACC-area';
import { Component, Input, OnChanges } from '@angular/core';
import { AccTeamGoalsAndAccess } from '@me-interfaces';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';


export interface GoalRow {
	quarter: number,
	summary: string,
	details: string,
	progress: string,
}


@Component({
	selector: 'acc-team-goals-view-part',
	templateUrl: './acc-team-goals-view.part.html',
})
export class AccTeamGoalsViewPart implements OnChanges {
	@Input() accTeamId: number;
	private accTeamId$ = new BehaviorSubject<number>(undefined);

	accQuarters = {
		0: 'Q0',
		1: 'Q1',
		2: 'Q2',
		3: 'Q3',
		4: 'Q4',
	}

	goalsData$ = combineLatest([
		this.accAreaService.teams.goals$,
		this.accTeamId$,
	]).pipe(map(data => {
		const [teamGoals, accTeamId] = data;
		return this.buildData(teamGoals, accTeamId);
	}));

	constructor(
		private accAreaService: AccAreaService,
	) {
	}

	ngOnChanges() {
		if (!this.accTeamId) throw new Error('Missing required attribute: accTeamId');
		if (this.accTeamId != this.accTeamId$.value) this.accTeamId$.next(this.accTeamId);
	}

	buildData(
		allTeamGoals: readonly AccTeamGoalsAndAccess[],
		accTeamId: number,
	): GoalRow[] {

		if (!allTeamGoals || !accTeamId) return [];

		const teamGoals = allTeamGoals.find(goal => goal.team.accTeamId === accTeamId);

		const goals = (teamGoals?.goals || []).map(award => {
			return {
				quarter: this.accQuarters[award.quarter],
				summary: award.summary,
				details: award.details,
				progress: award.progress,
			}
		})

		return goals;
	}

}