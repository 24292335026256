import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DbcZip, GooglePlace } from '@me-interfaces';
import { DdVenue } from '@me-services/core/old-dd';
import { ZipService } from '@me-services/core/zip';
import { DialogContext } from '@me-services/ui/dialog';
import { getIconContext, Icon } from '@me-shared-parts/UI-common';
import { VenueService } from '../SHR-ED_venue.service';
import { getActions } from './get-actions';


@Component({
	selector: 'venue-dialog',
	templateUrl: './SHR-ED_venue.dialog.html',
	styleUrls: ['./SHR-ED_venue.dialog.scss']
})
export class VenueDialog {

	loading = false;
	invalid = false;
	zipCode = '';
	zip: DbcZip;
	venue: DdVenue;
	venueInitialChoice: DdVenue;  // Initial selection for the choose-venue part if needed
	adding = false;
	foundPlace: GooglePlace;
	header = 'Venues';
	addIcon = getIconContext(Icon.action_add);
	actions = getActions();


	constructor(
		@Inject(MAT_DIALOG_DATA) public dialog: DialogContext<{ venue: DdVenue }>,
		public dialogRef: MatDialogRef<VenueDialog>,
		private venueService: VenueService,
		private zipService: ZipService,
	) {

		this.venue = dialog.data?.venue;

		this.setupActions();
		if (this.venue) this.showInitialVenue(this.venue);
	}


	async showInitialVenue(venue: DdVenue) {
		await this.setZip(venue.zipId);
		this.zipCode = ("00000" + this.venue.zipId).slice(-5);
		this.venueInitialChoice = venue;
	}


	async onZipInput(event) {

		this.invalid = false;

		if (event?.target?.value.length > 5) {
			event.preventDefault();
			return;
		}

		const zipId = parseInt(event?.target?.value || '0', 10);
		if (zipId && event?.target?.value.length == 5) {
			await this.setZip(zipId);
		}
	}


	async setZip(zipId: number) {
		this.loading = true;
		this.zip = undefined;
		this.zip = await this.zipService.getZipById(zipId);
		if (this.zip && this.zipCode !== this.zip?.code) this.zipCode = this.zip?.code || '';
		this.invalid = !this.zip;
		this.loading = false;
	}


	onZipBlur() {
		this.zipCode = this.zip?.code || '';
		this.invalid = false;
	}


	onVenueSelection(venue: DdVenue) {
		this.actions.select.enabled = !!venue;
		if (this.venue?.venueId == venue?.venueId) return;
		this.venue = venue;
	}


	onFoundPlace(place: GooglePlace) {
		this.foundPlace = place;
		this.actions.add.enabled = !!this.foundPlace?.zipId;
	}


	setAdding(adding: boolean) {
		if (this.adding == adding) return;
		this.adding = adding;
		this.actions.select.visible = !adding;
		this.actions.add.visible = adding;
		if (!adding) this.onFoundPlace(undefined);
	}


	setupActions() {
		this.actions.select.callback = this.selectAction.bind(this);
		this.actions.add.callback = this.addAction.bind(this);
		this.actions.cancel.callback = this.cancelAction.bind(this);
	}


	/**
	* Called when the Select action button is clicked.
	*/
	async selectAction(): Promise<DdVenue> {
		return this.venue;
	}


	/**
	* Called when the Add action button is clicked.
	*/
	async addAction(): Promise<void> {
		const place = this.foundPlace;
		this.onFoundPlace(undefined);
		this.venue = await this.venueService.add(place);
		this.venueInitialChoice = this.venue;
		this.setAdding(false);
	}


	/**
	* Called when the Cancel action button is clicked.
	*/
	async cancelAction() {
		if (this.adding) {
			this.setAdding(false);
		}
		else {
			this.dialogRef.close();
		}
	}

}