import { Dbc } from "@me-interfaces";
import { Subject } from "rxjs";
import { UtilityService } from "../utility";

export class DomainDataManager<T extends Dbc, ID_TYPE = number> {

	cache: T[] = [];


	constructor(
		cache$: Subject<{ [name: string]: any[] }>,
		private name: string,
		private idColumn: string,
		private util: UtilityService,
	) {

		cache$.subscribe(cache => {
			this.cache = cache[name] || [];
			if (!this.cache.length) this.util.log.errorMessage(`No domain data available for type ${name}`);
		});
	}


	/**
	* Get one domain datum by id
	*/
	public getOne(id: ID_TYPE): T {

		const datum: T = this.cache.find(d => d[this.idColumn] == id);
		if (!datum) throw new Error(`Domain Datum not found of type ${this.name} and id ${id}`);
		return datum;
	}


	/**
	 * Get all of the domain data of this named type
	 */
	public getAll(): T[] {
		return this.cache;
	}


	/**
	 * Get all of the domain data of this named type in a map indexed by the id
	 */
	public getAllAsMap(): Readonly<Record<number, T>> {
		return this.util.array.toMap(this.cache, item => item[this.idColumn]);
	}

}