import { calcCombinedScore } from "./calc-combined-score";
import { calcScore } from "./calc-score";


export const ScoringUtilities = {

	/**
	 * Given an array of numbers (four for assessments and five for question ratings),
	 * calculate a score between 0 and 100.  The first element is worth 0 and the last 
	 * element is worth 100.  In-between elements are pro-rated.  The value in each
	 * element is used to weight the score.
	 * @returns The score and the total count of values across the array elements
	 */
	calcScore: (values: [number, number, number, number] | [number, number, number, number, number]) => {
		return calcScore(values);
	},


	/**
	 * Given a set of questions ratings, and optionally a set of assessments,
	 * calculate a combined score using 90% of the assessment score and 10%
	 * of the ratings score.  If assessments aren't provided then the resulting
	 * score is based solely on the question ratings score.
	 */
	calcCombinedScore: (
		ratings: [number, number, number, number, number],
		assessments?: [number, number, number, number],
	) => {
		return calcCombinedScore(ratings, assessments);
	},
};