import { Component, HostBinding, Input, OnChanges } from '@angular/core';

const DEFAULT_THRESHOLDS = [50, 100];
type ColorName = 'red' | 'yellow' | 'green';

@Component({
	selector: 'progress-color-bar',
	templateUrl: './SHR-progress-color-bar.part.html',
	styleUrls: ['SHR-progress-color-bar.part.scss'],
})
export class ProgressColorBarPart implements OnChanges {

	@Input() percent = 0;
	@Input() thresholds = DEFAULT_THRESHOLDS;
	@Input() height = 5;

	@HostBinding('class.progress') p = true;
	@HostBinding('class.red') r = false;
	@HostBinding('class.yellow') y = false;
	@HostBinding('class.green') g = false;
	@HostBinding('style.height') h = '5px';


	ngOnChanges() {
		this.percent = Math.max(0, Math.min(this.percent, 100));
		this.thresholds = this.validateThresholds(this.thresholds);

		const color = this.determineColor(this.percent);

		this.r = color == 'red';
		this.y = color == 'yellow';
		this.g = color == 'green';
		this.h = this.height + 'px';
	}

	
	validateThresholds(t: number[]) {
		if (!Array.isArray(t) || t.length !== 2) return DEFAULT_THRESHOLDS;
		else {
			t[0] = +t[0];
			t[1] = +t[1];
			if (isNaN(t[0]) || isNaN(t[1])) return DEFAULT_THRESHOLDS;
			else {
				t[0] = Math.max(0, Math.min(t[0], 100));
				t[1] = Math.max(0, Math.min(t[1], 100));
				if (t[1] < t[0]) t[1] = t[0];
			}
		}
		return t;
	}


	determineColor(percent: number): ColorName {
		if (percent < this.thresholds[0]) return 'red';
		if (percent >= this.thresholds[1]) return 'green';
		return 'yellow';
	}


	getWidth() {
		return `${this.percent}%`;
	}
}
