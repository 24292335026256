<ng-container *ngIf="!(errorPage.details$|async).error">
	<ng-container *ngIf='user'>
		<me-navbar></me-navbar>
		<div class="container-fluid user-access">
			<div class="row g-0">
				<me-menu></me-menu>

				<div class="main-wrapper" [class.menu-collapsed]='menu.collapsed$ | async'>
					<div class="me-overlay" *ngIf="(menu.collapsed$ | async) === false" (click)="hideMenu()"></div>

					<div class="main">
						<me-breadcrumb></me-breadcrumb>
						<router-outlet></router-outlet>
					</div>

					<me-back-top position="200"></me-back-top>

				</div>
			</div>
		</div>
	</ng-container>
</ng-container>