<div class='explorer-part pic-team-explorer-part'>

	<div class="icon-bar">
		<me-icon-bar #iconBar [icons]='views' [orientation]="'Vertical'" (selectionsChange)='setViews($event)'>
		</me-icon-bar>
	</div>

	<div class="content">
		<ng-container *ngIf='selectedViews'>
			<div class="explorer-header">
				<me-icon [icon]="icons.picTeam"></me-icon>
			</div>
			<h4 class="explorer-sub-header">Pitch Contest Participating Team</h4>

			<div style="display:flex; justify-content: center;">
				<table class="pro-table pro-table-no-header pro-table-with-thin-rows  pro-table-without-border">
					<tr>
						<td>Pitcher: {{picTeam.presentType === 'Pitcher' ? 'Yes (Preselected)' : picTeam.presentType ===
							'Audience' ? 'Yes (Audience Choice)' : 'Pitcher(Wildcard)'}}</td>
						<td>Tabler: {{picTeam.presentType === 'Tabler'? 'Yes' : 'No'}} </td>
					</tr>
				</table>
			</div>

			<section>
				<explorer-buttons [buttons]='buttons'>
					<button *ngIf="picTeam.hasRedFlag" class="text-btn text-btn-not-inline border-red"
						(click)="click('red-flag')">
						<me-icon [icon]="icons.redFlag"></me-icon>
					</button>
					<button *ngIf="picTeam.awardedValue > 0" class="text-btn text-btn-not-inline"
						(click)="click('awards')">
						<me-icon [icon]="icons.award"></me-icon>
					</button>
				</explorer-buttons>
			</section>

			<explorer-section header="Pic Team Details" (tabClose)="tabClose($event)"
				[style.display]="getDisplay('details')">
				<pic-team-explorer-details [picTeam]="picTeam"></pic-team-explorer-details>
			</explorer-section>

			<explorer-section
				[header]="util.text.singularOrPluralWithCount(memberBoxes.length, 'Team Member', 'Team Members')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('members')">
				<explorer-boxes>
					<ng-container *ngFor="let box of memberBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

			<explorer-section
				[header]="util.text.singularOrPluralWithCount(relatedNoteBoxes.length, 'Related Note', 'Related Notes')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('related notes')">
				<explorer-note-boxes>
					<ng-container *ngFor="let box of relatedNoteBoxes">
						<explorer-note-box [box]="box"> </explorer-note-box>
					</ng-container>
				</explorer-note-boxes>
			</explorer-section>

			<explorer-section [header]="util.text.singularOrPluralWithCount(awardBoxes.length, 'Award', 'Awards')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('awards')">
				<explorer-boxes>
					<ng-container *ngFor="let box of awardBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

			<explorer-section header='Pitch Contest' (tabClose)="tabClose($event)"
				[style.display]="getDisplay('pitchContest')">
				<explorer-boxes>
					<ng-container *ngFor="let box of picBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

		</ng-container>
	</div>
</div>