import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-ACS-parts';
import { DBLCLICK_GRID_ACTION_KEY, GridColumnType, GridSetup } from '@me-grid';
import { AppAreaIdentifier, AppAreaIdentifierAndAccess, DbConceptName, Person, SiteAreaAccess, SiteVolunteerApplication } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { LayoutService } from '@me-services/ui/layout';
import { Icon } from '@me-shared-parts/UI-common';
import { SiteAreaData, SiteAreaService, SiteDashboardPageService, SiteVolunteerApplicationEditDialog } from '@SITE-area';
import { combineLatest, map, Observable, of } from 'rxjs';


interface VolunteerApplicationRow {
	volunteerApplicationId: number,
	applicantPersonId: number, //applicant personId
	applicantName: string,
	applicantPhone: string,
	applicantEmail: string,
	status: 'Open' | 'Closed',
	daysOld: string,
	createdUTC: number,
	closedOnUTC: number,
	volunteerApplication: {
		siteVolunteerApplication: SiteVolunteerApplication,
		applicant: Person,
	},
}


@Component({
	selector: 'site-volunteer-applications-view-part',
	templateUrl: './applications.part.html',
})
export class VolunteerApplicationsPart extends DestroyablePart implements OnInit {

	public readonly: boolean;
	rows$: Observable<VolunteerApplicationRow[]> = of([]);
	public gridSetup = this.setupGrid();

	constructor(
		private ds: DataService,
		public layout: LayoutService,
		public siteAreaService: SiteAreaService,
		private util: UtilityService,
		public pageService: SiteDashboardPageService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();

		this.rows$ = combineLatest([
			this.siteAreaService.accessAndId$,
			this.siteAreaService.data$,
		]).pipe(map(data => this.buildRows(data[0], data[1])));

	}

	buildRows(
		accessAndId: AppAreaIdentifierAndAccess<AppAreaIdentifier<number>, SiteAreaAccess>,
		data: SiteAreaData,
	) {

		this.readonly = accessAndId?.access?.root != 'Write';

		if (!data) return;


		const rows: VolunteerApplicationRow[] = data.volunteerApplications.map(application => ({
			volunteerApplicationId: application.siteVolunteerApplication.volunteerApplicationId,
			applicantEmail: application.applicant.asSingleton._email,
			applicantName: application.applicant.name,
			applicantPersonId: application.applicant.personId,
			applicantPhone: application.applicant.asSingleton.phone,
			createdUTC: application.siteVolunteerApplication.application.createdUTC,
			closedOnUTC: application.siteVolunteerApplication.closedUTC,
			daysOld: this.util.date.getDaysSince(application.siteVolunteerApplication.application.createdUTC),
			status: application.siteVolunteerApplication.closedByPersonId ? 'Closed' : 'Open',
			volunteerApplication: application,
		}));

		return rows;
	}





	private setupGrid(): GridSetup<VolunteerApplicationRow> {

		const setup: GridSetup<VolunteerApplicationRow> = {
			experience: 'none',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Volunteer Application",
			rowPluralName: "Volunteer Applications",
			rowKey: "volunteerApplicationId",
			stateKey: "site-volunteer-applications-part",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: 'volunteerApplicationId', header: "volunteerApplicationId", width: 80, type: GridColumnType.number, hidden: true },
				{ field: 'applicantPersonId', header: "personId", width: 80, type: GridColumnType.number, hidden: true },
				{ field: 'applicantName', header: 'Name', width: 200, type: GridColumnType.text },
				{ field: 'applicantPhone', header: 'Phone', width: 130, type: GridColumnType.phone },
				{ field: 'applicantEmail', header: 'Email', width: 220, type: GridColumnType.text },
				{ field: 'status', header: 'Status', width: 100, type: GridColumnType.text },
				{ field: 'daysOld', header: 'Days Old', width: 200, type: GridColumnType.text },
				{ field: 'createdUTC', header: 'Created On', width: 120, type: GridColumnType.dateUtc },
				{ field: 'closedOnUTC', header: 'Closed On', width: 120, type: GridColumnType.dateUtc },
			],
			actions: [
				{ key: 'open-application', icon: Icon.action_open, label: 'Open Application', enabled: false },
				{ key: 'explore-applicant', icon: Icon.dialog_explore, label: `Explore Applicant` },
			],
			initialState: {
				sort: [{ field: 'createdUTC', dir: 'desc' }],
				filter: { logic: 'and', filters: [{ field: 'status', operator: 'neq', value: 'Closed' }] },

			},

		};

		return setup;
	}


	async gridActionHandler(action: { actionKey: string, rows: VolunteerApplicationRow[] }) {
		if (this.readonly) return;


		const row = action.rows[0];

		if (action.actionKey == 'explore-applicant') await this.ds.explorer.explore(DbConceptName.Person, row.applicantPersonId);

		else if (action.actionKey == 'open-application' || action.actionKey == DBLCLICK_GRID_ACTION_KEY) {
			await this.ds.dialogService.showCustom(SiteVolunteerApplicationEditDialog, {
				data: row.volunteerApplication
			}, 850, 650);
		}
	}


}