import { AccAreaService } from '@ACC-area';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId, DbsPersonTag, Person } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { DroplistItem, getIconClass, getIconContext, Icon } from '@me-shared-parts/UI-common';
import { SiteAreaService } from '@SITE-area';


@Component({
	selector: 'acc-team-members-add-dialog',
	templateUrl: './acc-team-members-add.dialog.html',
	styleUrls: ['./acc-team-members-add.dialog.scss']
})
export class AccTeamMembersAddDialog extends DestroyablePart implements OnInit {
	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.Complete);
	readonly: boolean;

	items: DroplistItem<'E' | 'M' | 'C'>[] = [
		{ uniqueId: 1, iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Entrepreneur'), data: 'E' },
		{ uniqueId: 2, iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Coach'), data: 'C' },
		{ uniqueId: 3, iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Mentor'), data: 'M' },
	];

	mentors: DroplistItem<Person>[];
	entrepreneurs: DroplistItem<Person>[];

	selectedRole: DroplistItem<'E' | 'M' | 'C'>;
	selectedMentor: DroplistItem<Person>;
	selectedEntrepreneur: DroplistItem<Person>;

	//
	// The action buttons
	//

	addAction: DialogAction<{ role: 'E' | 'M' | 'C', personId: number }> = {
		id: 'add',
		enabled: false,
		visible: true,
		label: 'Add',
		linkType: 'callback',
		callback: async () => ({
			role: this.selectedRole?.data,
			personId: this.selectedRole ? ['M', 'C'].includes(this.selectedRole.data) ? this.selectedMentor.data?.personId : this.selectedEntrepreneur.data?.personId : undefined,
		}),
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: 'Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction<{ role: 'E' | 'M' | 'C', personId: number } | void>[] = [this.addAction, this.cancelAction];
	icon = getIconClass(Icon.action_add);
	editIcon = getIconContext(Icon.action_edit, undefined, 'always');


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ accTeamId: number, }>,
		public dialogRef: MatDialogRef<AccTeamMembersAddDialog>,
		public accAreaService: AccAreaService,
		private siteAreaService: SiteAreaService,
		private util: UtilityService,
		private ds: DataService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		super.subscribe([
			this.accessAtStage$,
		], async ([accessAtStage]) => {
			this.readonly = accessAtStage.access != 'Write';
		});

		const { accTeamId } = this.dialog.data;
		const accTeam = await this.ds.admin.accTeam.getOnePackage(accTeamId);

		const existingEs = accTeam.members.filter(member => member.member.role == 'E').map(member => member.member.personId);
		const existingMs = accTeam.members.filter(member => member.member.role != 'E').map(member => member.member.personId);

		const positionsByCompanyId = await this.ds.admin.position.getArraysByForeignIds('companyId', [accTeam.application.companyId]);

		const companyPositions = (positionsByCompanyId[accTeam.application.companyId] || [])
			.filter(position => !existingEs.includes(position.personId));

		const people = await this.ds.admin.person.getManyPackagesAsArray(companyPositions.map(pos => pos.personId));


		this.entrepreneurs = people.map(person => {
			return {
				iconContext: getIconContext(Icon.concept_person, undefined, 'never', person.fullName),
				uniqueId: person.personId,
				data: person,
			}
		});

		const siteId = this.siteAreaService.getId().id;
		if (siteId) {
			const siteTags = (await this.ds.admin.tag.getAllPackagesAsArray()).filter(tag => tag.site && tag.site.siteId == siteId);
			const coachTagId = siteTags.find(tag => tag.name == 'coach-approved')?.tagId;
			const tagIds = siteTags.filter(tag => tag.name == 'mentor-approved' || tag.name == 'coach-approved').map(tag => tag.tagId);

			const personTags: DbsPersonTag[] = [];
			const personTagsByTagId = await this.ds.admin.personTag.getArraysByForeignIds('tagId', tagIds);

			for (const tagId of tagIds) {
				personTags.push(...(personTagsByTagId[tagId] || []));
			}

			const totalPersonIds = this.util.array.cleanNumericIds(personTags.map(personTag => personTag.personId));
			this.mentors = (await this.ds.admin.person.getManyPackagesAsArray(totalPersonIds)).map(person => {
				return {
					iconContext: getIconContext(Icon.concept_person, undefined, 'never', `${person.fullName}${person.tags.find(tag => tag.tagId == coachTagId) ? ' (c)' : ''}`),
					uniqueId: person.personId,
					data: person,
					disabled: existingMs.includes(person.personId),
				}
			});
		}

	}


	public roleSelectionChange(e: DroplistItem<'M' | 'E' | 'C'>) {
		this.selectedRole = e;
		this.selectedEntrepreneur = undefined;
		this.selectedMentor = undefined;
	}

	public mentorSelectionChange(e: DroplistItem<Person>) {
		if (!this.selectedRole || this.selectedRole.data == 'E') this.selectedMentor = undefined;
		else this.selectedMentor = e;
		this.checkIfCanSave();
	}

	public entrepreneurSelectionChange(e: DroplistItem<Person>) {
		if (!this.selectedRole || ['M', 'C'].includes(this.selectedRole.data)) this.selectedEntrepreneur = undefined;
		else this.selectedEntrepreneur = e;
		this.checkIfCanSave();
	}

	checkIfCanSave() {
		this.addAction.enabled = this.selectedRole && ((this.selectedRole.data == 'E' && !!this.selectedEntrepreneur)
			|| (['M', 'C'].includes(this.selectedRole.data) && !!this.selectedMentor));
	}


	async showEditMessage() {
		await this.ds.dialogService.showMessage(`Don't forget to add an Entrepreneur to the company positions.`, 250, 200);
	}

}