<div class='application-explorer-details-part' *ngIf="application">

	<div id="container" class="explorer-details-container">

		<table class='pro-table pro-table-no-header pro-table-with-labels'>
			<tr>
				<td> Application Id</td>
				<td> {{application.applicationId}}</td>
			</tr>

			<tr>
				<td>Business</td>
				<td>
					<explorer-button [button]="{ explorableId: { conceptName: 'company', id: application.companyId }}">
					</explorer-button>
				</td>
			</tr>

			<tr *ngIf="application.accId">
				<td>Accelerator</td>
				<td>
					<explorer-button [button]="{ explorableId: { conceptName: 'accelerator', id: application.accId }}">
					</explorer-button>
				</td>
			</tr>

			<tr *ngIf="application.picId">
				<td>Pitch Contest</td>
				<td>
					<explorer-button [button]="{ explorableId: { conceptName: 'pitchContest', id: application.picId }}">
					</explorer-button>
				</td>
			</tr>

			<tr *ngIf="application.notes">
				<td>Notes</td>
				<td>
					{{application.notes}}
				</td>
			</tr>

			<tr>
				<td>Created On</td>
				<td>{{util.date.formatUTC(application.createdUTC, 'MMM D, YYYY', 'H:MM AM EST', urlService.languageId)}}
				</td>
			</tr>

			<tr>
				<td>Last Edited By</td>
				<td>
					<explorer-button
						[button]="{ explorableId: { conceptName: 'person', id: application.updatedByPersonId }}">
					</explorer-button>
					<div class="small-nowrap-text">
						on {{util.date.formatUTC(application.updatedUTC, 'MMM D, YYYY', 'H:MM AM EST',
						urlService.languageId)}}
					</div>
				</td>
			</tr>

		</table>

		<div class="explorer-details-right">
		</div>

	</div>
</div>