import { AccApplicationPageService, AccAreaService, accTransferApplication } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { ACC_APPLICATION_STATUS, AccAreaAccess, AccTeam, Accelerator, Answer, AppAreaIdentifierWithSiteId, Application, ApplicationStatusId, ApplicationStatusLog, AssessmentsData, DbConceptName, DbaAccInterview, DbaAccReading, DbsApplication, FieldTool, Site } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogService } from '@me-services/ui/dialog';
import { DroplistItem, Icon, PageTabsLayout, getIconContext } from '@me-shared-parts/UI-common';
import { getIconContextForPackage } from '@me-shared-parts/UI-common/icon/get-icon-context-for-package';
import { BehaviorSubject, Observable, combineLatest, mergeMap } from 'rxjs';


interface ApplicationRow extends Application {
	acc: Accelerator,
	applicationStatusLogs: ApplicationStatusLog[],
	applicationList: DroplistItem<never>[],
	applicationSelectedItem: DroplistItem<never>,
	accTeam: AccTeam | undefined,
	readings: AssessmentsData[],
	interviews: AssessmentsData[],
}


@Component({
	selector: 'acc-application-page',
	templateUrl: './acc-application.page.html',
	styleUrls: ['./acc-application.page.scss'],
})
export class AccApplicationPage extends DestroyablePart implements OnInit {

	public readonly ApplicationStatusId_SelectSelected = ApplicationStatusId.SelectSelected;
	private applicationId$ = new BehaviorSubject<number>(undefined);
	tool: FieldTool = FieldTool.Application_StaffViewer;
	readonly = false;
	applicationId: number;

	icons = {
		appl: getIconContext(Icon.dialog_explore, undefined, 'lt-md', 'Explore'),
		edit: getIconContext(Icon.action_edit, undefined, 'lt-sm'),
		check: getIconContext(Icon.status_check, undefined, 'lt-sm', "Stop Editing"),
		company: getIconContext(Icon.concept_company, undefined, 'lt-xl'),
		person: getIconContext(Icon.concept_person, undefined, 'lt-xl', 'Applicant'),
		accTeam: getIconContext(Icon.concept_accTeam, undefined, 'lt-sm', "Add to Cohort"),
		transfer: getIconContext(Icon.action_transfer, undefined, 'lt-sm')
	};

	applicationData$: Observable<ApplicationRow>;

	copiedApplicationsMap: Record<number, DbsApplication>;
	site: Site;

	constructor(
		private router: Router,
		public accAreaService: AccAreaService,
		private ds: DataService,
		private route: ActivatedRoute,
		private dialogService: DialogService,
		public pageService: AccApplicationPageService,
	) {
		super();
	}


	async ngOnInit(): Promise<void> {
		super.initDestroyable();

		this.accAreaService.subscribeAccess(this.destroyed$, this.accessChanged);

		this.applicationData$ = combineLatest([
			this.accAreaService.accelerator.acc$,
			this.accAreaService.teams.teams$,
			this.accAreaService.applications.statusLogs$,
			this.applicationId$,
			this.accAreaService.reading.assessments$,
			this.accAreaService.interviewing.assessments$,
		]).pipe(mergeMap(data => {
			const [acc, accTeams, statusLogs, applicationId, readerAssessments, interviewAssessments] = data;
			return this.buildApplicationRow(acc, accTeams, statusLogs, applicationId, readerAssessments, interviewAssessments);
		}));
	}



	accessChanged = async (value: { access: AccAreaAccess, id: AppAreaIdentifierWithSiteId<number> }) => {
		if (!value) return;

		const { access, id } = value;
		if (access) this.readonly = this.readonly = value?.access?.root != 'Write';

		if (id) {
			this.applicationId = id.subPath?.id;
			if (this.applicationId != this.applicationId$.value) {
				this.applicationId$.next(this.applicationId);
			}
		}
	}


	async buildApplicationRow(
		acc: Accelerator,
		accteams: readonly AccTeam[],
		statusLogs: readonly ApplicationStatusLog[],
		applicationId: number,
		readerAssessments: {
			reading: DbaAccReading,
			answers: readonly Answer[],
		}[],
		interviewAssessments: {
			interview: DbaAccInterview,
			answers: readonly Answer[]
		}[],
	): Promise<ApplicationRow> {

		if (!acc || !applicationId || !accteams || !statusLogs || !readerAssessments || !interviewAssessments) return undefined;

		const allApplications: Application[] = (await this.ds.admin.application.getAllPackagesAsArray()).filter(app => app.siteProgram.siteId == acc.siteId);

		const applicationList = allApplications.filter(app => app.accId == acc.accId)
			.map<DroplistItem>(a => ({ uniqueId: a.applicationId, groupName: `${a.applicationStatusId} - ${ACC_APPLICATION_STATUS[a.applicationStatusId]}`, customSort: `${a.applicationStatusId}-${a.company._name}`, iconContext: getIconContextForPackage(a) }));

		const applicationSelectedItem = applicationList.find(a => a.uniqueId == applicationId);

		const app = allApplications.find(a => a.applicationId === applicationId);

		this.site = acc.siteProgram.site;
		//
		// Get the applications for the copiedFromApplicationId and copiedToApplicationId for the navigation link
		//
		const copiedApplicationIds = [];
		if (app.copiedFromApplicationId) copiedApplicationIds.push(app.copiedFromApplicationId);
		if (app.copiedToApplicationId) copiedApplicationIds.push(app.copiedToApplicationId);
		if (copiedApplicationIds.length) {
			this.copiedApplicationsMap = await this.ds.admin.application.getManyAsMap(copiedApplicationIds);
		}

		const accTeam = accteams.find(team => team.applicationId === applicationId);
		const accReaders = await this.ds.admin.accReader.getManyPackagesAsMap(readerAssessments.map(r => r.reading.accReaderId));
		const accInterviewers = await this.ds.admin.accInterviewer.getManyPackagesAsMap(interviewAssessments.map(i => i.interview.accInterviewerId));

		const readings = readerAssessments.filter(ra => ra.reading.applicationId == this.applicationId).reduce((a: AssessmentsData[], ra) => {
			const rating = {
				answers: ra.answers,
				company: app.company,
				rating: ra.reading.rating,
			};
			const assessmentData = a.find(data => data.person.personId == accReaders[ra.reading.accReaderId].personId);
			if (assessmentData) {
				assessmentData.ratings.push(rating);
			}
			else a.push({
				person: accReaders[ra.reading.accReaderId].person.asSingleton,
				ratings: [rating],
			});
			return a;
		}, []);

		const interviews = interviewAssessments.filter(ia => ia.interview.applicationId == this.applicationId).reduce((a: AssessmentsData[], ia) => {
			const rating = {
				answers: ia.answers,
				company: app.company,
				rating: ia.interview.rating,
			};
			const assessmentData = a.find(data => data.person.personId == accInterviewers[ia.interview.accInterviewerId].personId);
			if (assessmentData) {
				assessmentData.ratings.push(rating);
			}
			else a.push({
				person: accInterviewers[ia.interview.accInterviewerId].person.asSingleton,
				ratings: [rating],
			});
			return a;
		}, []);

		const row: ApplicationRow = {
			acc,
			...app,
			applicationList,
			applicationSelectedItem,
			applicationStatusLogs: statusLogs.filter(log => log.applicationId == applicationId),
			accTeam,
			readings,
			interviews,
		};

		return row;

	}


	async updateNotes(applicationId: number, notes: string) {
		await this.accAreaService.applications.actions.setNotes({ applicationId, notes });
	}


	async transferApplication(application: ApplicationRow) {
		await accTransferApplication(this.router, this.readonly, this.ds, this.accAreaService, this.applicationId, application.applicationStatusId);
	}

	onApplicationChange(e: DroplistItem) {
		const selectedId = e?.uniqueId;
		if (selectedId) {
			this.applicationId$.next(selectedId);
			this.applicationId = selectedId;
			this.router.navigate(['../', selectedId], { relativeTo: this.route });
		}
	}


	toggleTool() {
		if (this.tool == FieldTool.Application_StaffEditor) this.tool = FieldTool.Application_StaffViewer;
		else this.tool = FieldTool.Application_StaffEditor;
	}


	onLayoutChange(layout: PageTabsLayout) {
		this.pageService.changeLayout(layout);
	}


	async addAccTeam(applicationData: ApplicationRow) {

		if (applicationData.accTeam || applicationData.applicationStatusId != ApplicationStatusId.TeamPending) return;

		const msg = `Are you sure you want to add this team to the cohort?\nIt cannot be easily undone once added.\n\nIMPORTANT:\nOnly do this after the leadership team has approved this applicant AND you have confirmed that the applicant can attend the sessions.`;

		const yes = await this.dialogService.confirm(msg, 400, 250);

		if (yes) await this.accAreaService.applications.actions.addToCohort({ applicationIds: [this.applicationId] });
	}


	async inspectApplication(applicationId: number) {
		if (!applicationId) return;
		await this.ds.explorer.explore(DbConceptName.Application, applicationId);
	}


	async inspectCompany(companyId: number) {
		if (!companyId) return;
		await this.ds.explorer.explore(DbConceptName.Company, companyId);
	}


	async inspectApplicant(personId: number) {
		if (!personId) return;
		await this.ds.explorer.explore(DbConceptName.Person, personId);
	}

	navigate(applicationId: number) {
		const application = this.copiedApplicationsMap[applicationId];
		if (application) this.router.navigate([`/access/admin/national/sites/${this.site.siteId}/accelerators/${application.accId}/pre-accelerator/${applicationId}`]);
		return;
	}

}
