import { DbConceptName, Person, PhoneTypeId } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";

const RELATIONSHIPTYPES = {
	'FAM': 'Family',
	'FRN': 'Friend',
	'PAR': 'Partner / Spouse',
	'COL': 'Colleague',
	'DUP': 'Duplicate',
};

export async function getBoxesForRelationships(util: UtilityService, ds: DataService, person: Person): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const relationships = (await ds.admin.relationship.getAllAsArray()).filter(relationship => relationship.personId1 == person.personId || relationship.personId2 == person.personId);

	const personIds = util.array.cleanNumericIds(relationships.reduce((a, relationship) => { a.push(relationship.personId1, relationship.personId2); return a; }, []));
	const peopleMap = await ds.admin.person.getManyPackagesAsMap(personIds);

	for (const relationship of relationships) {
		const personId = relationship.personId1 == person.personId ? relationship.personId2 : relationship.personId1;
		const personRelationship = peopleMap[personId];
		const header = relationship.type ? `Relationship - ${RELATIONSHIPTYPES[relationship.type]}` : 'Relationship';

		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Relationship,
			header,
			buttons: [
				{ explorer: { nameOverride: personRelationship.fullName, explorableId: { conceptName: DbConceptName.Person, id: personId }, navTooltip: `Person: ${personId}` } },
			],
			notes: relationship.notes || '',
		};

		if (personRelationship.asSingleton.phone) {
			box.buttons.push({ link: { linkType: 'call', phone: personRelationship.asSingleton.phone } });
			if (personRelationship.asSingleton.phoneTypeId == PhoneTypeId.Mobile) {
				box.buttons.push({ link: { linkType: 'sms', phone: personRelationship.asSingleton.phone } });
			}
		}

		boxes.push(box);
	}

	return boxes;
}