import { ChangeType, Release } from "./interfaces";

export const CHANGE_LOG: Release[] = [
	{
		name: 'Release 70d',
		date: '2024-03-08',
		changes: [
			{
				description: 'Fixed a bug with upcoming session emails going out to removed team members',
				type: ChangeType.Bug,
			},
			{
				description: 'Updated Awards download streamlined according to Finance Team requirements',
				type: ChangeType.Feature,
			},
			{
				description: 'Upgrade to Angular 17.1 and upgraded most dependencies',
				type: ChangeType.Infrastructure,
			},
			{
				description: 'Removed old token and func tables from the database',
				type: ChangeType.Infrastructure,
			},
			{
				description: 'Old search replaced with the Searcher',
				type: ChangeType.Feature,
			},
			{
				description: 'Css fixes due to upgrade to NG 17.1',
				type: ChangeType.Feature,
			},

		],
	},
	{
		name: 'Release 70c',
		date: '2024-02-22',
		changes: [
			{
				description: `Removed Highlight.io configuration`,
				type: ChangeType.Feature,
			},
		]
	},
	{
		name: 'Release 70b',
		date: '2024-02-01',
		changes: [
			{
				description: `New access to all App Walkthroughs from the Site, National, and Regional pages`,
				type: ChangeType.Feature,
			},
			{
				description: `Added curriculum session template for 2024 Spring`,
				type: ChangeType.Feature,
			},
			{
				description: `Added curriculum session template for 2024 Virtual Summer English and Spanish`,
				type: ChangeType.Feature,
			},
			{
				description: `Added curriculum session template for 2024 Summer English and Spanish`,
				type: ChangeType.Feature,
			},
			{
				description: `Added curriculum session template for 2024 Fall English and Spanish`,
				type: ChangeType.Feature,
			},
			{
				description: `Added Team Notes indicator column and edit action to the Cohort -> Teams view`,
				type: ChangeType.Feature,
			},
			{
				description: `Added Program flow walkthrough lists the Accelerator and Pitch Contest toolbars for easy access`,
				type: ChangeType.Feature,
			},
			{
				description: `Fixed upcoming Accelerator session email notification bug`,
				type: ChangeType.Bug,
			},
			{
				description: `Fixed bug with curricumlum calendar dialog handling sessions with no topic`,
				type: ChangeType.Bug,
			},
			{
				description: `Fixed bug not allowing Virtual Site Accelerator cohorts to enter Goals or Progress`,
				type: ChangeType.Bug,
			},

		]
	},
	{
		name: 'Release 70',
		date: '2024-01-17',
		changes: [
			{
				description: `New Agreement added: Entrepreneurs Handbook/Code of Conduct`,
				type: ChangeType.Feature,
			},
			{
				description: `New Agreement added: Leadership Advisory Council handbook`,
				type: ChangeType.Feature,
			},
			{
				description: `New Leadership Advisory Council configuration view in the Site Dashboard, with a Handbook Signed column`,
				type: ChangeType.Feature,
			},
			{
				description: `New Missing Signatures view on the Accelerator Overview`,
				type: ChangeType.Feature,
			},
			{
				description: `Added Media Consent and Handbook Signed columns to the mentors, entrepreneurs, and matched mentors views`,
				type: ChangeType.Feature,
			},
			{
				description: `Added missing signature counts on the Acc->Overview view User tools tab`,
				type: ChangeType.Feature,
			},
			{
				description: `Added entrepreneur signoff on the entrepreneur tool`,
				type: ChangeType.Feature,
			},
			{
				description: `Fixed bug with curricumlum materials not being displayed`,
				type: ChangeType.Bug,
			},
			{
				description: `Fixed Site Toolbar not loading`,
				type: ChangeType.Bug,
			},
			{
				description: `Fixed Site Cache issue causing Hear Report to not load in V2`,
				type: ChangeType.Bug,
			},
		]
	},
	{
		name: 'Release 69',
		date: '2023-12-07',
		changes: [
			{
				description: `Various performance improvements, and initial work for future performance improvements`,
				type: ChangeType.Feature,
			},
			{
				description: `New mentor matching schedule editor that supports time zones, has 60 minute meetings, and mon-fri days`,
				type: ChangeType.Feature,
			},
			{
				description: `Updated the Virtual Test Site zip codes to only support applications from Texas for Summer 2024`,
				type: ChangeType.Feature,
			},
			{
				description: `Added support for a new "Dana Farber Award" award type`,
				type: ChangeType.Feature,
			},
			{
				description: `New Mentor Pitch column on the Matchable Mentors view`,
				type: ChangeType.Feature,
			},
			{
				description: `New action to copy the Mentor Pitch to the clipboard on the Matchable Mentors view`,
				type: ChangeType.Feature,
			},
			{
				description: `Updated the Mentor Ratio check calculation to the new 2:1 standard`,
				type: ChangeType.Feature,
			},
			{
				description: `Fixed bug with displaying tabs in some of the explorer windows`,
				type: ChangeType.Bug,
			},
			{
				description: `Fixed a styling issue with the address field`,
				type: ChangeType.Bug,
			},
			{
				description: `Fixed a styling issue with the date field`,
				type: ChangeType.Bug,
			},
			{
				description: `Fixed a missing translation bug`,
				type: ChangeType.Bug,
			},
			{
				description: `Fixed a navigation bug when using breadcrumbs`,
				type: ChangeType.Bug,
			},
		]
	},
	{
		name: 'Release 68',
		date: '2023-10-12',
		changes: [
			{
				description: `Topic materials now organized in the Google Drive with readonly links`,
				type: ChangeType.Feature,
			},
			{
				description: `Inventory of materials, organized by topic, now stored in the database`,
				type: ChangeType.Feature,
			},
			{
				description: `Program Dashboard now has language-specific lists of all materials with links`,
				type: ChangeType.Feature,
			},
			{
				description: `Accelerator Dashboard now shows per-topic materials and links in the Curriculum -> Topics view`,
				type: ChangeType.Feature,
			},
			{
				description: `Entrepreneurs now have a calendar view with event details and links to materials`,
				type: ChangeType.Feature,
			},
			{
				description: `New Teams -> Entrepreneurs view in the Cohort tab`,
				type: ChangeType.Feature,
			},
			{
				description: `Program Dashboard now has a view that shows all accelerators problems`,
				type: ChangeType.Feature,
			},
			{
				description: `Several new accelerator problems are detected, including missing awards`,
				type: ChangeType.Feature,
			},
			{
				description: `Agreement Page Buttons now use a url that matches the language of the accelerator`,
				type: ChangeType.Bug,
			},
			{
				description: `All application grids now have an action to add application notes`,
				type: ChangeType.Feature,
			},
			{
				description: `Fixed AccTeam Attendance Score calculation`,
				type: ChangeType.Bug,
			},
			{
				description: `Upgraded the Kendo license`,
				type: ChangeType.Infrastructure,
			},
		]
	},
	{
		name: 'Release 67',
		date: '2023-09-06',
		changes: [
			{
				description: `Core functionality added to define agreements and record people signing them`,
				type: ChangeType.Feature,
			},
			{
				description: `Add 'Mentor Handbook' and 'Media Consent' agreements`,
				type: ChangeType.Feature,
			},
			{
				description: `Add a general landing page where anyone can sign any agreement with a direct link`,
				type: ChangeType.Feature,
			},
			{
				description: `Add Handbook column to Matchable Mentors and Matched Mentors grids indicating if handbook was signed`,
				type: ChangeType.Feature,
			},
			{
				description: `Add new 'Program Dashboard' area with initial capability to see all agreements and signatures`,
				type: ChangeType.Feature,
			},
			{
				description: `Program Managers are now cc'd on Class Feedback Survey emails and nudge emails`,
				type: ChangeType.Feature,
			},
			{
				description: `The weekly upcoming events email now shows session topics, and is sorted better`,
				type: ChangeType.Feature,
			},
			{
				description: `App emails now bcc app.email.log@eforall.org for tracking the sent emails`,
				type: ChangeType.Feature,
			},
			{
				description: `Person Explorer now shows which agreements the person has signed, if any`,
				type: ChangeType.Feature,
			},
			{
				description: `Renamed 'Leadership Advisory Board' to 'Local Advisory Council'`,
				type: ChangeType.Feature,
			},
			{
				description: `Improved styling in explorer windows`,
				type: ChangeType.UserInterface,
			},
			{
				description: `Fixed bug with displaying notes in some of the explorer windows`,
				type: ChangeType.Bug,
			},
			{
				description: `Fixed bug with tabs not syncing with selector buttons in explorer windows`,
				type: ChangeType.Bug,
			},
			{
				description: `Fixed bug that wouldn't let a cohort be marked as completed due to 'something'`,
				type: ChangeType.Bug,
			},
		],
	},
	{
		name: 'Release 66',
		date: '2023-08-04',
		changes: [
			{
				description: `The Accelerator Dashboard now detects and shows data problems, such as applications in an unexpected status`,
				type: ChangeType.Feature,
			},
			{
				description: `New Stage Advancer widget makes it easy to advance through accelerator stages and see any related problems`,
				type: ChangeType.Feature,
			},
			{
				description: `More timezone support. Each site is now tagged with a timezone`,
				type: ChangeType.Infrastructure,
			},
			{
				description: `Date and time displays now include timezone info. e.g. EST, EDT, PST, PDT, etc.`,
				type: ChangeType.Feature,
			},
			{
				description: `Fixed timezone related bug in session event generation when Curriculum spans a Daylight Savings time boundary`,
				type: ChangeType.Bug,
			},
		]
	},
	{
		name: 'Release 65c',
		date: '2023-07-23',
		changes: [
			{
				description: `Initial timezones support, assign timezone for each zip code`,
				type: ChangeType.Infrastructure,
			},
			{
				description: `Fix Curriculum Surey emails, allow more than 10 emails to be sent at a time`,
				type: ChangeType.Bug,
			},
			{
				description: `Various other Curriculum Surey bugs`,
				type: ChangeType.Bug,
			},
		]
	},
	{
		name: 'Release 65b',
		date: '2023-07-12',
		changes: [
			{
				description: `Update flow to still allow users to apply to programs at hidden sites.`,
				type: ChangeType.Bug,
			},
		]
	},
	{
		name: 'Release 65',
		date: '2023-07-11',
		changes: [
			{
				description: `All new applicant flow, which takes into account the users zip code and exisiting applications to select available programs.`,
				type: ChangeType.Feature,
			},
			{
				description: `New 'Hidden' property on a Site.`,
				type: ChangeType.Feature,
			},
			{
				description: `Zip Codes have a SiteEN and SiteES designation, visable in the Site -> Zip Codes view.`,
				type: ChangeType.Feature,
			},
			{
				description: `Fixed automated survey emails 'bad email template' bug.`,
				type: ChangeType.Bug,
			},
		]
	}, {
		name: 'Release 64d',
		date: '2023-06-12',
		changes: [
			{
				description: `Fixed Entrepreneur unable to add goals.`,
				type: ChangeType.Bug,
			},
			{
				description: `Fixed Entrepreneur adding an admin to the company while filling out the alumni report.`,
				type: ChangeType.Feature,
			},
		]
	},
	{
		name: 'Release 64c',
		date: '2023-06-10',
		changes: [
			{
				description: `Fixed Mentor Matching Rating Tool. It was still using the old 9-value application statuses.`,
				type: ChangeType.Bug,
			},
			{
				description: `New collapse button for tab layouts, so main tabs can display more grid content.`,
				type: ChangeType.Feature,
			},
			{
				description: `New feature to hide sites from the end users even when they are otherwise active. Used with the virtual test site.`,
				type: ChangeType.Feature,
			},
		]
	},
	{
		name: 'Release 64b',
		date: '2023-05-18',
		changes: [
			{
				description: `Add support for Apple's new sign-in requirements with Safari on IOS and MacOs.`,
				type: ChangeType.Infrastructure,
			},
			{
				description: `Fix rendering of checkboxes`,
				type: ChangeType.Bug,
			},
			{
				description: `Include Add buttons to the Accelerator All Events and All Awards grids`,
				type: ChangeType.Feature,
			},
			{
				description: `Fix download button bug`,
				type: ChangeType.Bug,
			},
		]
	},
	{
		name: 'Release 64',
		date: '2023-05-05',
		videoLink: 'https://eforall.wistia.com/medias/yccw3vyqwh',
		changes: [
			{
				description: 'New Search Window available on app toolbar',
				type: ChangeType.Feature,
			},
			{
				description: 'New Explorer Window available on app toolbar',
				type: ChangeType.Feature,
			},
			{
				description: 'New Recents Menu',
				type: ChangeType.Feature,
			},
			{
				description: 'National, Region, and Site dashboard pages',
				type: ChangeType.Feature,
			},
			{
				description: 'National, Region, and Site performance indicators',
				type: ChangeType.Feature,
			},
			{
				description: 'National, Region, and Site data grids (initial)',
				type: ChangeType.Feature,
			},
			{
				description: 'New Volunteer Management pages replacing Tags',
				type: ChangeType.Feature,
			},
			{
				description: 'Acc Dashboard tabs renamed to overview, pre-accelerator, and cohort',
				type: ChangeType.Feature,
			},
			{
				description: '17 New Application statuses, including Rejected, Adandoned, and Withdrawn',
				type: ChangeType.Feature,
			},
			{
				description: 'Bulk status changing now accessible from the applications lists',
				type: ChangeType.Feature,
			},
			{
				description: 'Application Deferment and CopyForward',
				type: ChangeType.Feature,
			},
			{
				description: 'Application selection and waitlisting support',
				type: ChangeType.Feature,
			},
			{
				description: 'Refresh button on toolbars',
				type: ChangeType.Feature,
			},
			{
				description: 'Curriculum Sessions management',
				type: ChangeType.Feature,
			},
			{
				description: 'Curriculum Topic management with specialists',
				type: ChangeType.Feature,
			},
			{
				description: 'Curriculum attendance tracking',
				type: ChangeType.Feature,
			},
			{
				description: 'Session surveys (class surveys) now in the app',
				type: ChangeType.Feature,
			},
			{
				description: 'One-button sending of session survey emails',
				type: ChangeType.Feature,
			},
			{
				description: 'Session survey tracking and reports in the app',
				type: ChangeType.Feature,
			},
			{
				description: 'Advanced grid filter editor',
				type: ChangeType.UserInterface,
			},
			{
				description: 'Fix Relationships Editor',
				type: ChangeType.Bug,
			},

		],
	},
	{
		name: 'Release 63b',
		date: '2022-10-17',
		changes: [
			{
				description: 'Various Bug Fixes',
				type: ChangeType.Bug,
			},
		],
	},
	{
		name: 'Release 63',
		date: '2022-09-26',
		changes: [
			{
				description: 'Responsive Page-Tabs Layout',
				type: ChangeType.UserInterface,
			},
			{
				description: 'Responsive Row2 and Row3 components',
				type: ChangeType.UserInterface,
			},
			{
				description: 'IconBar component for explorer windows',
				type: ChangeType.UserInterface,
			},
			{
				description: 'Remove Pre-accelerator from accelerator goals and progress',
				type: ChangeType.Feature,
			},
			{
				description: 'Improved singletons caching',
				type: ChangeType.Infrastructure,
			},
			{
				description: 'Fix drop panel offset for jumper toolbar button',
				type: ChangeType.Bug,
			},
			{
				description: 'New Search Engine',
				type: ChangeType.Feature,
			},
			{
				description: 'New Finder Window with custom filters',
				type: ChangeType.Feature,
			},
			{
				description: 'New Explorer window with cached navigation',
				type: ChangeType.Feature,
			},
			{
				description: 'New Explorers: Accelerator, AccTeam, Application, Company, Event, Person, PicTeam, PitchContest, Site, Venue',
				type: ChangeType.Feature,
			},
			{
				description: 'Fix dialog actionbar height which caused scrollbars',
				type: ChangeType.Bug,
			},
			{
				description: 'Place Awards were showing incorrectly in event types',
				type: ChangeType.Bug,
			},
			{
				description: 'Update droppedOut calculation for Alumni Report and DS Dashboard',
				type: ChangeType.Infrastructure,
			},
			{
				description: 'Package managers for acc, pic, reader, interviewer, and judge',
				type: ChangeType.Infrastructure,
			},
			{
				description: 'Adjust Spanish application questions',
				type: ChangeType.Feature,
			},
			{
				description: 'Add option to hide group area for grids that dont need it',
				type: ChangeType.Infrastructure,
			},
			{
				description: 'Page-Tabs Layout has customizable toolbar color and icon',
				type: ChangeType.UserInterface,
			},
			{
				description: 'Selected DroplistItem shows the category, too',
				type: ChangeType.UserInterface,
			},
			{
				description: 'Grid Cells support strikethrough red text with a descriptive tooltip',
				type: ChangeType.UserInterface,
			},
			{
				description: 'Page-Tabs Layout syncs initial tab and view using the URL',
				type: ChangeType.Infrastructure,
			},
			{
				description: 'Change database to support storing multiple races (still need UI changes)',
				type: ChangeType.Feature,
			},
			{
				description: 'New "Program" and "Past Employee" note types',
				type: ChangeType.Feature,
			},
			{
				description: 'Displayer harsher warning if destroyed$ subject is used incorrectly',
				type: ChangeType.Infrastructure,
			},
			{
				description: 'Singletons.getMany should auto-load and cache missing singletons',
				type: ChangeType.Infrastructure,
			},
			{
				description: 'Grid base columns and actions for known row types',
				type: ChangeType.UserInterface,
			},
			{
				description: 'Change GridSetup<T>.width to be numeric',
				type: ChangeType.Bug,
			},
			{
				description: 'Move TechAdmin to be under Admin',
				type: ChangeType.Infrastructure,
			},
			{
				description: 'Improve navigation droplist styling',
				type: ChangeType.UserInterface,
			},
			{
				description: 'Inline text button styling',
				type: ChangeType.UserInterface,
			},
			{
				description: 'Checkbox list component',
				type: ChangeType.UserInterface,
			},
			{
				description: 'Add Region concept (no UI yet)',
				type: ChangeType.Feature,
			},
			{
				description: 'Standardized Icon enum for simpler and consistent icon assignment',
				type: ChangeType.Infrastructure,
			},
			{
				description: 'Fix application counts on Active Programs page',
				type: ChangeType.Bug,
			},
			{
				description: 'Add LongPress support',
				type: ChangeType.UserInterface,
			},
			{
				description: 'Replace ClipboardService with util.clipboard',
				type: ChangeType.Infrastructure,
			},
			{
				description: 'Rename "Final Pitch" to "Final Presentations"',
				type: ChangeType.Feature,
			},
			{
				description: 'Fix applicant nudge emails sent after deadline',
				type: ChangeType.Bug,
			},
			{
				description: 'New Acc Dashboard Overview Page',
				type: ChangeType.Feature,
			},
			{
				description: 'New Acc Dashboard Pre-Accelerator Page',
				type: ChangeType.Feature,
			},
			{
				description: 'New Acc Dashboard Cohort Page',
				type: ChangeType.Feature,
			},
			{
				description: 'New Acc Dashboard Application Page',
				type: ChangeType.Feature,
			},
			{
				description: 'New Acc Dashboard Team Page',
				type: ChangeType.Feature,
			},
			{
				description: 'Make all singletons, packages, and domain data interfaces readonly',
				type: ChangeType.Infrastructure,
			},
			{
				description: 'Add "User Interface" type to the Whats New page',
				type: ChangeType.Infrastructure,
			},
		],
	},
	{
		name: 'Release 62',
		date: '2022-01-04',
		changes: [
			{
				description: 'Continued caching changes and performance improvements',
				type: ChangeType.Infrastructure,
			},
			{
				description: 'New weekly email (sent each Sunday) listing all upcoming acc and pic events',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-592',
			},
			{
				description: 'Rollback floating volunteers support for now. Need to hire National Support staff first',
				type: ChangeType.Feature,
			},
			{
				description: 'Volunteer applicants can now select multiple sites of which they are willing to volunteer at',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-569',
			},
			{
				description: 'New admin tool to assign volunteers that selected multiple sites to the site needing it the most',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-568',
				routerLink: '/access/admin/communities/eforall/pending-volunteers/community-selection',
			},
			{
				description: 'Reorganize some pages related to volunteers to be more intuitive',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-581',
			},
			{
				description: 'Add new Paula Buxbaum award type',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-587',
			},
			{
				description: 'The communities list page now contains more useful columns',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-589',
				routerLink: '/access/admin/national/sites',
			},
			{
				description: 'AppAreaFuncResponse interface now includes the latest insertId',
				type: ChangeType.Infrastructure,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-586',
			},
			{
				description: 'Compress function responses to avoid hitting the 10MB Firebase limit',
				type: ChangeType.Infrastructure,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-570',
			},
			{
				description: 'Grids now support date and time formats',
				type: ChangeType.Infrastructure,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-590',
			},
			{
				description: 'Upgrade to Angular 13 and upgrade most dependencies',
				type: ChangeType.Infrastructure,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-591',
			},
			{
				description: 'Integrate the code with the https://sentry.io service to automatically log and track app issues',
				type: ChangeType.Infrastructure,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-595',
			},
			{
				description: 'Replace the deprecated TSLint with the new ESLint',
				type: ChangeType.Infrastructure,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-593',
			},
			{
				description: 'Cleanup tsconfig typecheck compiler option errors',
				type: ChangeType.Infrastructure,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-594',
			},
		],
	},
	{
		name: 'Release 61',
		date: '2021-10-27',
		changes: [
			{
				description: 'Fix intermittent Gateway Timeout and missing data issue',
				type: ChangeType.Bug,
			},
		]
	},
	{
		name: 'Release 60',
		date: '2021-10-24',
		changes: [
			{
				description: 'General improvements for the floating volunteering feature',
				type: ChangeType.Feature,
			},
			{
				description: 'More singletons functionality continuing with performance improvements',
				type: ChangeType.Feature,
			},
			{
				description: 'Fix bug with singletons',
				type: ChangeType.Bug,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-563',
			},
			{
				description: 'Event Host is now a required field',
				type: ChangeType.Feature,
			},
			{
				description: 'Right-click a grid to show the actions menu in context',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-564',
			},
			{
				description: 'Fix bug with user info (e.g. name) being shown to readers without access',
				type: ChangeType.Bug,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-557',
			},
		],
	},
	{
		name: 'Release 59',
		date: "2021-10-15",
		changes: [
			{
				description: "Revamped MY Volunteering section and added support for floater volunteer applications",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-458",
				routerLink: '/access/my/volunteering'
			},
			{
				description: "Add Floater Management pages",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-458",
				routerLink: '/access/admin/communities/eforall-floaters'
			},
			{
				description: "Add 'class-speaker', 'office-hours', 'workshops' tags to specialty",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-535"
			},
			{
				description: 'Performance boost: Move many things from domain data to singletons',
				type: ChangeType.Infrastructure,
				jiraLink: "https://eforall.atlassian.net/browse/APP-538"
			},
			{
				description: 'Fix menu auto expanding and collapsing',
				type: ChangeType.Bug,
				jiraLink: "https://eforall.atlassian.net/browse/APP-530"
			},
			{
				description: 'Fix broken peer group nightly job when no events are found',
				type: ChangeType.Bug,
				jiraLink: "https://eforall.atlassian.net/browse/APP-534"
			},
			{
				description: "Fix dropdown arrow CSS in application form",
				type: ChangeType.Bug,
				jiraLink: "https://eforall.atlassian.net/browse/APP-537"
			},
			{
				description: "Fix P2P association extract links",
				type: ChangeType.Bug,
				jiraLink: "https://eforall.atlassian.net/browse/APP-537"
			},
			{
				description: "Add LOA support to the Peer Group alert nightly email",
				type: ChangeType.Bug,
				jiraLink: "https://eforall.atlassian.net/browse/APP-523"
			},
			{
				description: "Add All Memberships page to the Eforever Tools",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-545",
				routerLink: '/access/admin/communities/eforever/all-memberships'
			},
			{
				description: "Show counts of items in each group in tables",
				type: ChangeType.Infrastructure,
				jiraLink: "https://eforall.atlassian.net/browse/APP-547",
				routerLink: '/access/admin/national/sites'
			},
			{
				description: "Fix angular configuration to properly emit debug symbols",
				type: ChangeType.Infrastructure,
				jiraLink: "https://eforall.atlassian.net/browse/APP-548",
			},
			{
				description: "Add mentor count details to the Active Programs page",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-549",
				routerLink: '/access/admin/communities/common/active-programs'
			},
		]
	},
	{
		name: 'Release 58b',
		date: "2021-09-13",
		changes: [
			{
				description: 'Fix Dexie asynchrounous race condition bug',
				type: ChangeType.Bug,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-517',
			},
			{
				description: 'Adjust Peer Group Event grid columns',
				type: ChangeType.Infrastructure,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-518',
			},
		]
	},

	{
		name: 'Release 58',
		date: "2021-09-12",
		changes: [
			{
				description: 'Performance Improvements - Faster Loads and Reloads',
				type: ChangeType.Infrastructure,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-507',
			},
			{
				description: 'New "Currency" grid column to format numbers as dollars',
				type: ChangeType.Infrastructure,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-514',
			},
			{
				description: 'Program editor grid now shows dollar amount of awards given',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-515',
			},
			{
				description: 'Program editor grid now shows number of goals created',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-515',
			},
			{
				description: '"My Applications" button added to the welcome card on user dashboard',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-501',
			},
			{
				description: 'Fixed bug when adding an event',
				type: ChangeType.Bug,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-503',
			},
			{
				description: 'P2P Leave of Absence (LOA) editor page',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-504',
			},
			{
				description: 'P2P LOA support on Facilitator tool and various other places',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-506',
			},
			{
				description: 'P2P peer group attendance rollup screen',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-513',
			},
			{
				description: 'Add new Community: "Rhode Island"',
				type: ChangeType.Feature,
				routerLink: '/access/admin/communities/prov/dashboard',
				jiraLink: 'https://eforall.atlassian.net/browse/APP-516',
			},
		]
	},
	{
		name: 'Release 57',
		date: "2021-08-20",
		changes: [
			{
				description: 'Upgrade from Angular 10 to Angular 12 and all dependency libraries',
				type: ChangeType.Infrastructure,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-476',
			},
			{
				description: 'Switch from Github to Bitbucket',
				type: ChangeType.Infrastructure,
			},
			{
				description: 'New top-level Events menu to separate program events and person events.',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-484',
			},
			{
				description: 'Fix Cannot Read edCommunities error',
				type: ChangeType.Bug,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-485',
			},
			{
				description: 'Fix performance issue when loading communities page',
				type: ChangeType.Bug,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-486',
			},
			{
				description: 'Pass the name of each area service to the base class',
				type: ChangeType.Infrastructure,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-487',
			},
			{
				description: 'Add Language to event table and dialog UI',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-489',
			},
			{
				description: 'Fix the BIPOC calculation in the accelerator impact dashboard',
				type: ChangeType.Bug,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-493',
			},
			{
				description: 'Add "Infrastructure" type to the Whats New page',
				type: ChangeType.Infrastructure,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-498',
			},
			{
				description: 'Add nightly peer groups alert emails to notify when there are issues',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-496',
			},
			{
				description: 'Rename "Onboarding" to "Discovery"',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-492',
			},
			{
				description: 'Add initial support for "Person" events with attendance tracking',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-481',
			},
			{
				description: 'Add DEI event types with attendance tracking',
				type: ChangeType.Feature,
				jiraLink: 'https://eforall.atlassian.net/browse/APP-480',
			},
		]
	},

	{
		name: 'Release 56',
		date: "2021-07-27",
		changes: [
			{
				description: "Make all peer to peer 'Tell us More' questions optional.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-466",
			},
			{
				description: "Move some of the P2P 'Tell us More' questions to a new 'Staff Only' section.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-467",
			},
			{
				description: "Add ability for Eforever CDs to create new application on behalf of an applicant.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-462",
			},
			{
				description: "Record when a P2P membership attends their first peer group meeting and display as needed.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-470",
			},
			{
				description: "Aggregate Asian demographic data to be added to the Accelerator Dashboard.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-473",
			},
			{
				description: "Aggregate application statuses to be added to reporting dashboards.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-474",
			},
		],
	},

	{
		name: 'Release 55',
		date: "2021-07-09",
		jiraLink: "https://eforall.atlassian.net/browse/APP-457?jql=project%20%3D%20%22APP%22%20AND%20fixVersion%20%3D%20%2255%22",
		changes: [
			{
				description: "'Abandoned Cart' feature automatically sends a nudge email to any applicant with an unsubmitted pitch contest or accelerator application.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-451",
			},
			{
				description: "New Permissions page gives a view into all the permissions users have across the app.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-454",
				routerLink: "/access/admin/communities/common/permissions",
			},
			{
				description: "New EforAll Missing Demographics page provides a list of all accelerator participants that do not have complete demographics.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-439",
				routerLink: "/access/admin/communities/eforall/missing-demographics",
			},
			{
				description: "New Eforever Missing Demographics page provides a list of all peer-to-peer Members that do not have complete demographics.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-440",
				routerLink: "/access/admin/communities/eforall/missing-demographics",
			},
			{
				description: "Active Programs and Upcoming Events pages moved under new Common Tools menu.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-450",
				routerLink: "/access/admin/communities/common",
			},
			{
				description: "Program Editor and Pending Volunteers pages moved under new EforAll Tools menu.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-448",
				routerLink: "/access/admin/communities/eforall",
			},
			{
				description: "Fixed bug that caused Application pages for Pic and Acc to not load when accessed using a direct link.",
				type: ChangeType.Bug,
				jiraLink: "https://eforall.atlassian.net/browse/APP-444",
			},
			{
				description: "Fixed Application Co-pariticipant Dashboard card bug when tring to go to an incomplete application from the Dashboard.",
				type: ChangeType.Bug,
				jiraLink: "https://eforall.atlassian.net/browse/APP-442",
			},
		],
	},

	{
		name: 'Release 54',
		date: "2021-06-18",
		jiraLink: "https://eforall.atlassian.net/browse/APP-432?jql=project%20%3D%20%22APP%22%20AND%20fixVersion%20%3D%20%2254%22",
		changes: [
			{
				description: "Mentor Matching Sundays are now part of the MM Scheduling tool.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-433",
			},
			{
				description: "The 2 week lead time to have a new program added to the app has been eliminated. Instead of needing to manually update the database and script in the changes with each release, EforAll Cross-Site Admins can now Add & Edit program instances directly in the app.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-427",
				routerLink: "/access/admin/communities/program-editor",
			},
			{
				description: "Created a page to view the history of all major changes to the app by release. It is the page you are on now!",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-424",
				routerLink: "/access/admin/change-history",
			},
			{
				description: "All site staff members now have a Dashboard card with an Admin button to their Site Dashboard by default.",
				type: ChangeType.Feature,
				routerLink: "/access/my/dashboard",
				jiraLink: "https://eforall.atlassian.net/browse/APP-423",
			},
		],
	},

	{
		name: 'Release 53',
		date: "2021-06-05",
		jiraLink: "https://eforall.atlassian.net/browse/APP-419?jql=project%20%3D%20%22APP%22%20AND%20fixVersion%20%3D%20%2253%22",
		changes: [
			{
				description: "Update MY > Goals and Progress page to use the new Accelerator Goals Tool",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-408",
			},
			{
				description: "Add support for Commenting on Goals",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-410",
			},
			{
				description: "MY > Applications page moved to live under MY > Programs",
				type: ChangeType.Feature,
				routerLink: "/access/my/programs",
				jiraLink: "https://eforall.atlassian.net/browse/APP-417",
			},
		],
	},

	{
		name: 'Release 52',
		date: "2021-05-29",
		jiraLink: "https://eforall.atlassian.net/browse/APP-239?jql=project%20%3D%20%22APP%22%20AND%20fixVersion%20%3D%20%2252%22",
		changes: [
			{
				description: "Update all Accelerator Goals & Progress tooling to have support for individually recorded Goals/Progress, peer viewing from Cohort members and Mentors, and an updated UI.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-308",
			},
		],
	},

	{
		name: 'Release 51',
		date: "2021-05-14",
		jiraLink: "https://eforall.atlassian.net/browse/APP-399?jql=project%20%3D%20%22APP%22%20AND%20fixVersion%20%3D%20%2251%22",
		changes: [
			{
				description: "Fix issues with resizing and srollbars turning on and off on the Volunteer page",
				type: ChangeType.Bug,
				jiraLink: "https://eforall.atlassian.net/browse/APP-398",
			},
			{
				description: "Update logic on Event dialog to allow deleting",
				type: ChangeType.Bug,
				jiraLink: "https://eforall.atlassian.net/browse/APP-396",
			},
		],
	},

	{
		name: 'Release 50',
		date: "2021-04-25",
		jiraLink: "https://eforall.atlassian.net/browse/APP-380?jql=project%20%3D%20%22APP%22%20AND%20fixVersion%20%3D%20%2250%22",
		changes: [
			{
				description: "New Awards dialog and management for Accelerators! Both programs can now capture awards data for all applicable event types.",
				type: ChangeType.Feature,
				videoLink: "https://www.loom.com/share/3eb3b432ed9d4ff8af33fafc3883d267",
				jiraLink: "https://eforall.atlassian.net/browse/APP-377",
			},
			{
				description: "Add Event/Award pages for each Accelerator event type; Showcase, Q1, Q2, Q3, Q4",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-377",
			},
			{
				description: "Added a new All Events page to Accelerators to view and manage all events.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-378",
			},
		],
	},

	{
		name: 'Release 49',
		date: "2021-04-17",
		jiraLink: "https://eforall.atlassian.net/browse/APP-373?jql=project%20%3D%20%22APP%22%20AND%20fixVersion%20%3D%20%2249%22",
		changes: [
			{
				description: "New Awards dialog and management for Pitch Contests",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-284",
			},
			{
				description: "Add last updated timestamp to Person and Company",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-346",
			},
			{
				description: "Fixed dynamic menus not showing up when visiting a Site, Accelerator, Pitch Contest, Person, or Company.",
				type: ChangeType.Bug,
				jiraLink: "https://eforall.atlassian.net/browse/APP-372",
			},
			{
				description: "Fixed parse error when editing Feedback",
				type: ChangeType.Bug,
				jiraLink: "https://eforall.atlassian.net/browse/APP-363",
			},
		],
	},

	{
		name: 'Release 48',
		date: "2021-04-03",
		jiraLink: "https://eforall.atlassian.net/browse/APP-360?jql=project%20%3D%20%22APP%22%20AND%20fixVersion%20%3D%2048",
		changes: [
			{
				description: "Added the ability for Facilitators and Admins to mark a member as attended or absent to a peer group event.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-349",
			},
			{
				description: "Import more historic Peer Group data including Application, Events, Memberships and Peer Group assignments.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-322",
			},
			{
				description: "Fixed a bunch of formatting bugs in Peer Group Facilitator tooling",
				type: ChangeType.Bug,
				jiraLink: "https://eforall.atlassian.net/browse/APP-332",
			},
		],
	},

	{
		name: 'Release 47',
		date: "2021-03-27",
		jiraLink: "https://eforall.atlassian.net/browse/APP-360?jql=project%20%3D%20%22APP%22%20AND%20fixVersion%20%3D%2048",
		changes: [
			{
				description: "Import inital historic Peer Group data including People, Companies, and Positions.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-305",
			},
			{
				description: "Alternate Program Managers are CC'd on withdrawn application emails.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-296",
			},
		],
	},

	{
		name: 'Release 46',
		date: "2021-03-20",
		jiraLink: "https://eforall.atlassian.net/browse/APP-280?jql=project%20%3D%20%22APP%22%20AND%20fixVersion%20%3D%2046",
		changes: [
			{
				description: "Peer Group Admins can now edit assignements.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-212",
			},
			{
				description: "Automatically follow a site when a user starts an Accelerator or Pitch Contest application for that site.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-94",
			},
			{
				description: "Dropped out Accelerator Teams no longer show an Alumni Report Dashboard Card",
				type: ChangeType.Bug,
				jiraLink: "https://eforall.atlassian.net/browse/APP-249",
			},
		],
	},

	{
		name: 'Release 45',
		date: "2021-03-13",
		jiraLink: "https://eforall.atlassian.net/browse/APP-248?jql=project%20%3D%20%22APP%22%20AND%20fixVersion%20%3D%2045",
		changes: [
			{
				description: "New 'Reporting' menu with links off to data dashboards.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-247",
			},
			{
				description: "Sites/Site has been rename to Communities/Community throughout the app to be consistent with the website.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-197",
			},
			{
				description: "New ability to add a 'Do Not Contact' flag and note to a Person's profile.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-178",
			},
			{
				description: "Fixed issues when trying to update Business Details as a Peer Group Member",
				type: ChangeType.Bug,
				jiraLink: "https://eforall.atlassian.net/browse/APP-190",
			},
		],
	},

	{
		name: 'Release 44',
		date: "2021-03-06",
		jiraLink: "https://eforall.atlassian.net/browse/APP-198?jql=project%20%3D%20%22APP%22%20AND%20fixVersion%20%3D%2044",
		changes: [
			{
				description: "The app can now be installed to your device",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-196",
			},
			{
				description: "EforAll and Eforever now have their own colors and menus in the app when accessed eforall.app and eforever.app respectively.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-138",
			},
			{
				description: "Update site dashboard cards to link back to the website and show descriptive blur from site pages.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-166",
			},
			{
				description: "Update Peer to Peer Group application to include new compensation questions.",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-129",
			},
			{
				description: "Fix bug preventing users from being able to submit a Peer to Peer Group application",
				type: ChangeType.Bug,
				jiraLink: "https://eforall.atlassian.net/browse/APP-185",
			},
		],
	},

	{
		name: 'Release 43',
		date: "2021-02-20",
		jiraLink: "https://eforall.atlassian.net/browse/APP-114?jql=project%20%3D%20%22APP%22%20AND%20fixVersion%20%3D%2043",
		changes: [
			{
				description: "Add new Sites grid to access Site Admin Dashboards",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-99",
			},
			{
				description: "Add Dashboard cards for Eforever programs",
				type: ChangeType.Feature,
				jiraLink: "https://eforall.atlassian.net/browse/APP-85",
			},
			{
				description: "Fixed bug in new Event dialog that did not save custom dates for event tools",
				type: ChangeType.Bug,
				jiraLink: "https://eforall.atlassian.net/browse/APP-5",
			},
		],
	},

	{
		name: 'Release 35',
		date: "2020-04-11",
		videoLink: "https://vimeo.com/566643753/0ec27d73b9",
		changes: [
			{
				description: "Added RE Syncing for Emails and Relationships",
				type: ChangeType.Feature,
			},
			{
				description: "Admins with access to Demographics can now update a users demographics on their behalf",
				type: ChangeType.Feature,
			},
			{
				description: "Fixed bugs with the login process",
				type: ChangeType.Bug,
			},
		],
	},

	{
		name: 'Release 34',
		date: "2020-03-27",
		videoLink: "https://vimeo.com/566645990/1db2daee05",
		changes: [
			{
				description: "RE Syncing for Profiles, Phone numbers, and Constituent Codes are now supported",
				type: ChangeType.Feature,
			},
			{
				description: "Accelerator and Pitch Contest Dashboards now have a way to view what Tools are available to a user. See release video for more details!",
				type: ChangeType.Feature,
			},
			{
				description: 'New "Mentor or Coach" tag count in the Roles Count tab for a site',
				type: ChangeType.Feature,
			},
			{
				description: "The Volunteer application now supports unlimited links (i.e. LinkedIn) using the new link editor.",
				type: ChangeType.Feature,
			},
		],
	},

	{
		name: 'Release 33',
		date: "2020-03-17",
		videoLink: "https://vimeo.com/566814370/0277bbd738",
		changes: [
			{
				description: "All new Hear Report for each site. Build a report, by program, of how applicants heard about EforAll and the program. ",
				type: ChangeType.Feature,
			},
			{
				description: "Pitch Contest attendance now defaults 'attended' to checked",
				type: ChangeType.Feature,
			},
			{
				description: "New reload buttons on assessment pages",
				type: ChangeType.Feature,
			},
			{
				description: "Updated the user-facing Profile Editor to the new layout",
				type: ChangeType.Feature,
			},
			{
				description: "Fixed bugs with the Reading Tool",
				type: ChangeType.Bug,
			},
		],
	},

	{
		name: 'Release 32',
		date: "2020-03-09",
		videoLink: "https://vimeo.com/567116353/5f895fc6f3",
		changes: [
			{
				description: "New integration with Raiser's Edge!",
				type: ChangeType.Feature,
			},
			{
				description: "New Mailing List tags for each site. Manage a users subscription to your sites newsletters in both English and Spanish.",
				type: ChangeType.Feature,
			},
			{
				description: "Specialists Tab added to the Roles/Tags page for each site.",
				type: ChangeType.Feature,
			},
			{
				description: "Companies now support Notes",
				type: ChangeType.Feature,
			},
			{
				description: "Contact Editor as been updated",
				type: ChangeType.Feature,
			},
		],
	},

	{
		name: 'Release 31',
		date: "2019-10-09",
		videoLink: "https://vimeo.com/567155859/399552a7a9",
		changes: [
			{
				description: "Revamped Sites Rols and Tags page. See video for details on all new approach!",
				type: ChangeType.Feature,
			},
			{
				description: "New Tag Editor that can be used in a pop-up",
				type: ChangeType.Feature,
			},
		],
	},

	{
		name: 'Release 30',
		date: "2019-06-25",
		videoLink: "https://vimeo.com/567159344/eb78d4c941",
		changes: [
			{
				description: "All new Position page layout with more links to Company and Person profiles.",
				videoLink: "https://vimeo.com/567159455/6ca58ecd8b",
				type: ChangeType.Feature,
			},
			{
				description: "Updated the Admin Volunteering pages and tools",
				videoLink: "https://vimeo.com/567159200/14ad706a62",
				type: ChangeType.Feature,
			},
			{
				description: "Added a button to the Volunteer application to apply in English or Spanish",
				type: ChangeType.Feature,
			},
		],
	},

	{
		name: 'Release 29',
		date: "2019-06-11",
		videoLink: "https://vimeo.com/567163765/efa2eefe53",
		changes: [
			{
				description: "Improved security with more granular access permissions",
				type: ChangeType.Feature,
			},
			{
				description: "Major navigational changes",
				videoLink: "https://vimeo.com/567166592/5bbc16baa9",
				type: ChangeType.Feature,
			},
			{
				description: "Added a button to the Volunteer application to apply in English or Spanish",
				type: ChangeType.Feature,
			},
			{
				description: "New Quick Links panel",
				videoLink: "https://vimeo.com/567169348/53ee71fee0",
				type: ChangeType.Feature,
			},
			{
				description: "New Site Configuration management",
				videoLink: "https://vimeo.com/567171919/80b3bdc098",
				type: ChangeType.Feature,
			},
			{
				description: "New Site Program grids",
				videoLink: "https://vimeo.com/567173604/e3537147f6",
				type: ChangeType.Feature,
			},
			{
				description: "Application statuses can now be changed in bulk",
				videoLink: "https://vimeo.com/567174505/f79d61bc31",
				type: ChangeType.Feature,
			},
			{
				description: "New support for app-wide Alerts and Messages!",
				videoLink: "https://vimeo.com/567175247/85f6f1ddad",
				type: ChangeType.Feature,
			},
		],
	},

];
