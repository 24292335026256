import { DdSite, DdTag } from "..";

/**
 * @deprecated Use the DataService instead
 */
export class SiteTags {

	private _tags: DdTag[] = [];
	private _follower: DdTag | undefined = undefined;
	private _mailingListEn: DdTag | undefined = undefined;
	private _mailingListEs: DdTag | undefined = undefined;

	constructor(private site: DdSite) {
		this._construct();
	}

	_construct() {
		this._tags = this.site.tagPrefix.tags;
		this._follower = this._tags.find(tag => tag.name == 'follower');
		this._mailingListEn = this._tags.find(tag => tag.name == 'mailing-list-en');
		this._mailingListEs = this._tags.find(tag => tag.name == 'mailing-list-es');
	}

	public get follower() {
		return this._follower;
	}

	public get mailingListEn() {
		return this._mailingListEn;
	}

	public get mailingListEs() {
		return this._mailingListEs;
	}

	public get all(): DdTag[] {
		return this._tags;
	}

	public get tagIds(): number[] {
		return this._tags.map(tag => tag.tagId);
	}
}