import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { DataService } from "@me-services/core/data";
import { FuncService } from '@me-services/core/func';
import { LabelsService } from '@me-services/ui/labels';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BaseFieldPart } from '../base/SHR-UI_base-field.part';
import { getFieldOptions } from './get-field-options';
import { SelectableOption } from './selectable-option';
import { UtilityService } from "@me-services/core/utility";


@Component({
	selector: 'select-field',
	templateUrl: './SHR-UI_select-field.part.html',
	styles: [`
		.other-field { margin-top: 7px; }
		.display-none { display: none; }
	`]
})

export class SelectFieldPart extends BaseFieldPart<string | number> implements AfterViewInit, OnInit, OnChanges {

	basePart: BaseFieldPart<string | number> = this;

	@Input() otherValue: string;
	@ViewChild('otherField') otherField: ElementRef;

	currentOtherValue: string;
	pendingOtherValue: string;
	isOtherValueFocused: boolean;

	untranslatedOptions: SelectableOption[];
	options: SelectableOption[];
	otherOptionField: { placeholder: string, target: string, };
	maxWidth = 7;

	constructor(
		protected override func: FuncService,
		protected override labelsService: LabelsService,
		private util: UtilityService,
		private ds: DataService,
	) {
		super(func, labelsService);
	}


	override async ngOnChanges(changes: SimpleChanges) {

		await super.ngOnChanges(changes);

		if (changes.otherValue) {

			const otherValue = changes.otherValue.currentValue ?? '';

			if (this.currentOtherValue !== otherValue) {

				this.otherValue = otherValue;

				if (!this.isOtherValueFocused || !this.isOtherValueChanged) {

					this.currentOtherValue = this.otherValue;
					this.pendingOtherValue = this.otherValue;

					if (!changes.otherValue.isFirstChange()) {
						this.setStatus('changed', true);
					}
				}
			}
		}
	}


	override async ngOnInit() {
		await super.ngOnInit();
		this.untranslatedOptions = getFieldOptions(this.field.fieldOptionsList, this.ds);

		const getLabel = await this.labelsService.getLabel();

		this.options = this.untranslatedOptions.map(option => {
			const text = getLabel({ key: option.text });

			// Calcuate a width for the dropdown based on the text length, more or less.
			this.maxWidth = Math.max(this.maxWidth, 8 + (text.length - 8) * .5);
			return { value: option.value, text, showOtherValueField: option.showOtherValueField };
		});

	}


	async onValueChange(option: SelectableOption): Promise<void> {
		this.setPendingValue(option.value);
		await this.attemptToSave();
	}


	get selectedOption(): SelectableOption {
		if (!this.options) return undefined;
		return this.options.find(option => option.value == this.pendingValue);
	}


	/////////////// Input for Other field ///////////////////


	ngAfterViewInit() {

		fromEvent(this.otherField.nativeElement, 'keyup')
			.pipe(
				takeUntil(this.destroyed$),
				debounceTime(50),
			)
			.subscribe(this.onOtherKeyUp.bind(this));

	}


	onOtherKeyUp(event: KeyboardEvent) {
		if (event.isTrusted && event.key == 'Enter') {
			// The user pressed Enter
			this.attemptToSaveOtherValue();
		}
		else {
			this.setPendingOtherValue(this.otherField.nativeElement.value);
		}
	}


	setPendingOtherValue(pendingOtherValue: string) {

		if (this.valuesAreSame(this.currentOtherValue, pendingOtherValue)) {
			if (this.status == 'pending') this.status = undefined;
		}
		else {
			if (this.status !== 'pending') this.setStatus('pending');
		}


		if (this.valuesAreSame(this.pendingOtherValue, pendingOtherValue)) return;

		this.pendingOtherValue = pendingOtherValue;
	}


	onOtherFocus() {
		this.isOtherValueFocused = true;
		this.hasBeenFocused = true;
	}


	onOtherBlur() {
		const wasFocused = this.isOtherValueFocused;
		this.isOtherValueFocused = false;

		if (wasFocused) {
			if (this.isOtherValueChanged) {
				this.attemptToSaveOtherValue();
			}
			else if (this.currentOtherValue !== this.otherValue) {
				this.currentOtherValue = this.pendingOtherValue = this.otherValue;
				this.setStatus('changed', true);
			}
		}
	}


	async attemptToSaveOtherValue() {

		const uncleanedValue = this.pendingOtherValue;
		this.pendingOtherValue = this.util.text.fixCase(('' + this.pendingOtherValue).trim());

		if (!this.isOtherValueChanged) {
			//
			// If pendingValue is different than currentValue only due to pendingValue being 
			// cleaned then we update the current value to the cleaned one.
			//
			if (this.pendingOtherValue == uncleanedValue) {

				//
				// Set the uncleaned value and then the pending value with timeouts to
				// circumvent the distinctUntilChanged behavior of the databinding.
				//
				setTimeout(() => {
					this.currentOtherValue = uncleanedValue;
					setTimeout(() => {
						this.currentOtherValue = this.pendingOtherValue; // pendingValue was cleaned a few lines ago
					});
				});

			}
			return;
		}

		this.setStatus('saving');

		const success = await this.func.field.update({ field: this.field.otherValueContext, value: this.pendingOtherValue, tool: this.tool });

		if (success) {
			this.otherValue = this.currentOtherValue = this.pendingOtherValue;
			this.setStatus('saved', true);
		}
		else {
			this.setStatus('no-auth');
		}
	}


	/**
	 * Returns true if the pending value is different than the current value.
	 */
	public get isOtherValueChanged() {
		return this.pendingOtherValue !== this.currentOtherValue;
	}

}