import { RegionAreaService, RegionPageService } from '@REGION-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AppAreaIdentifier, RegionAreaAccess } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { PageTabsLayout } from '@me-shared-parts/UI-common';


@Component({
	selector: 'region-page',
	templateUrl: './region.page.html',
})
export class RegionPage extends DestroyablePart implements OnInit {

	readonly: boolean;
	public tabNames = ["Region", "Get Help"];
	constructor(
		public pageService: RegionPageService,
		public ds: DataService,
		public regionAreaService: RegionAreaService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();

		this.regionAreaService.subscribeAccess(this.destroyed$, this.accessChanged);

		this.regionAreaService.data$.subscribe(data => {
			if (data?.region) this.tabNames = [data.region.name, "Get Help"]
		});
	}


	accessChanged = async (value: { access: RegionAreaAccess, id: AppAreaIdentifier<number> }) => {
		this.readonly = value?.access?.root != 'Write';
	}

	onLayoutChange(layout: PageTabsLayout) {
		this.pageService.changeLayout(layout);
	}

}