import { EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { DbConceptName } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { Icon } from "@me-shared-parts/UI-common";
import { PicCols } from "../columns";
import { EXPLORE_GRID_ACTION_KEY, GridColumnType, GridSetup, GRID_EXPERIENCE, OPEN_GRID_ACTION_KEY } from "../interfaces";
import { GridExperience } from "./grid-experience";


/**
 * Standard experience for a grid of sites
 */
export class PitchContestGridExperience<ROW> extends GridExperience<ROW> {

	public static readonly experience: GRID_EXPERIENCE = 'PIC';

	constructor(
		private ds: DataService,
		private util: UtilityService,
		setup: GridSetup<ROW>,
		gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
		private router: Router,
	) {
		super(
			util, setup,
			[
				{ field: PicCols.picId, header: 'picId', width: 70, type: GridColumnType.number, hidden: true },
				{ field: PicCols.siteId, header: 'siteId', width: 70, type: GridColumnType.number, hidden: true },
			],
			[
				{ field: PicCols.siteCode6, header: 'Site Code', width: 70, type: GridColumnType.text, hidden: true },
				{ field: PicCols.siteName, header: 'Site', width: 160, type: GridColumnType.text },
				{ field: PicCols.name, header: 'Name', width: 200, type: GridColumnType.text },
				{ field: PicCols.stage, header: 'Stage', width: 160, type: GridColumnType.text },
				{ field: PicCols.language, header: 'Language', width: 100, type: GridColumnType.text },
			],
			[
				{ field: PicCols.applStartUTC, header: 'Appl Start', width: 100, type: GridColumnType.dateUtc, hidden: true },
			],
			[
				{ key: EXPLORE_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Explore ${setup.rowSingularName}` },
				{ key: OPEN_GRID_ACTION_KEY, icon: Icon.action_open, label: `Open ${setup.rowSingularName}` },
				// { key: NOTES_GRID_ACTION_KEY, icon: Icon.action_editNotes, label: `Edit Pitch Contest Notes` },
			],
			gridAction,
			Icon.concept_pitchContest,
		);
	}


	public async applyBaseValues(rows: ROW[]) {

		const picMap = await this.ds.admin.pitchContest.getManyPackagesAsMap(rows.map(row => row['picId']));

		for (const row of rows) {

			const picId = row['picId'];
			const pitchContest = picMap[picId];

			row[PicCols.picId] = pitchContest.picId;
			row[PicCols.siteId] = pitchContest.siteProgram.site.siteId;
			row[PicCols.siteCode6] = pitchContest.siteProgram.site.code6;
			row[PicCols.siteCode] = pitchContest.siteProgram.site.code;
			row[PicCols.siteName] = pitchContest.siteProgram.site.name;
			row[PicCols.name] = pitchContest.name;
			row[PicCols.language] = pitchContest.siteProgram.program.language.name;
			row[PicCols.stage] = `${this.util.number.getProgramStageNumber(pitchContest.picStageId)} - ${pitchContest.stage.name}`;
			row[PicCols.applStartUTC] = pitchContest.applStartUTC;
		}
	}


	public sortRows(rows: ROW[]) {
		rows.sort((row1: ROW, row2: ROW) => {
			const val1 = row1[PicCols.picId];
			const val2 = row2[PicCols.picId];
			return val1 < val2 ? 1 : -1;
		});
	}


	public async explore(row: ROW) {
		await this.ds.explorer.explore(DbConceptName.PitchContest, row[PicCols.picId]);
	}


	public async open(row: ROW) {
		await this.router.navigate([`access/admin/communities/${row[PicCols.siteCode].toLowerCase()}/programs/pitch-contests/${row[PicCols.picId]}`]);
	}


	public async editNotes(row: ROW) {
		// Notes dialog not implemented
	}
}