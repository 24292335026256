<div class='selectable-view-selectors-part'>
	<selectable-view-header>{{selectorListTitle}}</selectable-view-header>
	<div class='main-menu' *ngFor='let selector of selectors' (click)='updateKey(selector)'>
		<div *ngIf='!selector.children' [class.highlight]='displaySubMenu[selector.key]' class='label-icon-flex'>
			<p [class.no-counts]='selector.count === 0'>{{selector.label}}</p>
			<i *ngIf='selector.locked' class="fas fa-lock gray"></i>
		</div>
		<div *ngIf='selector.children'>
			<div [class.sub-menu-main-highlight]='displaySubMenu[selector.key]' class='label-icon-flex'>
				<p>{{selector.label}}</p>
			</div>
			<ng-container *ngIf='displayChildMenu[selector.key]'>
				<div class='sub-menu' *ngFor='let child of selector.children'
					(click)='updateKey(selector,child.key); $event.stopPropagation();'>
					<div [class.sub-menu-highlight]='displaySubMenu[child.key]' class='label-icon-flex'>
						<p [class.no-counts]='child.count === 0'>{{ child.label}}</p>
						<i *ngIf='child.locked' class="fas fa-lock gray"></i>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>