import { Injectable } from '@angular/core';
import { DbsPerson } from '@me-interfaces';
import { DialogAction, DialogData, DialogService } from '@me-services/ui/dialog';
import { PersonExtractDialog } from './SHR-PN_person-extract-dialog.dialog';


@Injectable({ providedIn: 'root' })
/**
 * Provides utilities for launching person extract dialog
 */
export class ShowPersonExtractDialogService {

	constructor(private dialogService: DialogService) { }


	/**
	 * Launches the extract dialog for assigned DbsPerson
	 */
	public async show(person: DbsPerson): Promise<any | undefined> {

		const data: DialogData<any> = { data: { person } };
		const action: DialogAction<any> = await this.dialogService.showCustom(PersonExtractDialog, data, 800, 500);

		return undefined;
	}

}