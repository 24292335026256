import { Answer, AppAreaApplyResponse, AppAreaFuncResponse, AppAreaIdentifierWithSite, AppAreaIdentifierWithSiteId } from "@me-interfaces";
import { AppSubArea } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";

export class AccSubAreaInterviewing extends AppSubArea {

	constructor(
		private func: FuncService,
		ds: DataService,
		util: UtilityService,
		getId: () => AppAreaIdentifierWithSiteId<number>,
		applyResponse: (response: AppAreaFuncResponse) => Promise<AppAreaApplyResponse>,
	) {
		super(ds, util, getId, applyResponse);
	}


	async removeInterviewer(parameters: { accInterviewerId: number }) {
		return await this.call(this.func.areas.acc.interviewing.deleteInterviewer, parameters);
	}

	async setInterviewers(parameters: { personIds: number[] }) {
		return await this.call(this.func.areas.acc.interviewing.setInterviewers, parameters);
	}

	async setEvents(parameters: { accInterviewerId: number, eventIds: number[] }) {
		return await this.call(this.func.areas.acc.interviewing.setEvents, parameters);
	}

	async setPolishedFeedbackSkipped(parameters: { accInterviewerId: number, applicationId: number, polishedFeedbackSkipped: boolean }) {
		return await this.call(this.func.areas.acc.interviewing.setPolishedFeedbackSkipped, parameters);
	}

	async setPolishedFeedback(parameters: { accInterviewerId: number, applicationId: number, polishedFeedback: string }) {
		return await this.call(this.func.areas.acc.interviewing.setPolishedFeedback, parameters);
	}

	async setAssessment(parameters: { accInterviewerId: number, applicationId: number, assessment: { rating: number, answers: Answer[], } }) {
		return await this.call(this.func.areas.acc.interviewing.setAssessment, parameters);
	}

}