import { DbsPerson, DbsTagPrefix, TagPrefix } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";


export class TagPrefixPackageManager extends PackageManager<DbsTagPrefix, TagPrefix> {


	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsTagPrefix>,
		private domain: DomainDataManagers,
		private person: SingletonsManager<DbsPerson>,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Convert an array of DbcTag to an array of TAG
	 */
	protected async _createPackages(dbsTagPrefixes: DbsTagPrefix[]): Promise<TagPrefix[]> {

		//
		// Get all the people
		//
		const personIds: number[] = [];
		for (const tagPrefix of dbsTagPrefixes) {
			personIds.push(tagPrefix.updatedByPersonId);
		}

		const personMap = await this.person.getManyAsMap(personIds);


		//
		// Package 'em up
		//
		const tagPrefixes: TagPrefix[] = dbsTagPrefixes.map(tagPrefix => {
			
			const updatedByPerson = personMap[tagPrefix.updatedByPersonId];

			return {
				...tagPrefix,
				updatedByPersonName: updatedByPerson?._name || `Person #${tagPrefix.updatedByPersonId}`,
			};
		});

		return tagPrefixes;
	}
}