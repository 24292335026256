import { Component, Input, OnInit } from '@angular/core';
import { PublicCompanyForDialog } from '@me-interfaces';
import { ShowPublicCompanyDialogService } from '../public-company-dialog/SHR-CM_show-public-company-dialog.service';

@Component({
	selector: 'public-company-link',
	templateUrl: './SHR-CM_public-company-link.part.html',
	styleUrls: ['./SHR-CM_public-company-link.part.scss']
})
export class PublicCompanyLinkPart implements OnInit {

	@Input() company?: PublicCompanyForDialog;
	@Input() showAddress = false;

	constructor(
		public showPublicCompanyDialogService: ShowPublicCompanyDialogService,) {
	}

	async ngOnInit() {
		if (!this.company) throw new Error(`Missing company attribute`);
	}

	async open() {
		await this.showPublicCompanyDialogService.show(this.company, this.showAddress);
	}
}