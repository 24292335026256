<div class="download-dialog">

	<me-dialog-frame header='Download' [actions]='[]' [dialogRef]='dialogRef' icon='fa-download' alertType='editor'>

		<div class='download-dialog-content'>

			<message-line [closeable]="false" [line]='msg1'></message-line>
			<message-line [closeable]="false" [line]='msg2'></message-line>

			<table style='width:100%'
				class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>

				<tr>
					<td style='max-width:170px;'>File Name</td>
					<td>
						<kendo-textbox [value]='name' style='min-width:200px;width:100%' (valueChange)='nameChange($event)'
							(blur)='onBlur()'>
							<ng-template kendoTextBoxSuffixTemplate>
								<div style='padding-right: 5px;'>.tsv</div>
							</ng-template>
						</kendo-textbox>
					</td>
				</tr>


				<tr>
					<td>Included Rows</td>
					<td>
						<kendo-dropdownlist style='min-width:200px;' [value]="selectedRow"
							(valueChange)='rowSelectionChange($event)' [data]='rows'>
						</kendo-dropdownlist>
					</td>
				</tr>


				<tr>
					<td>Included Columns</td>
					<td>
						<kendo-multiselect style='min-width:200px;' [value]="selectedCols" [autoClose]="false"
							(valueChange)='columnSelectionChange($event)' [textField]="'header'" [valueField]="'field'"
							[data]='columns' (blur)='setColumnsForDownload()'>
						</kendo-multiselect>
					</td>
				</tr>

			</table>

			<button-container>
				<grid-download-button [fileName]='fileName' [columns]='downloadColumns' [rows]='downloadRows' [disabled]='!fileName || !downloadRows.length || !downloadColumns.length'>
				</grid-download-button>
			</button-container>

		</div>

	</me-dialog-frame>
</div>