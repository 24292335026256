<div class="mm-schedule-selector">

	<div class='col-header header'>
		<button class='row-header' (click)='toggleAll()'></button>
		<button *ngFor='let dayName of dayNames; let day=index' (click)='toggleColumn(day)'>{{dayName}}</button>
	</div>

	<div *ngFor='let slotName of slotNames; let slot=index' class='slot-row'>
		<button class='row-header' (click)='toggleRow(slot)'>{{slotName}}</button>
		<button *ngFor='let dayName of dayNames; let day=index' (click)='toggleSlot(day, slot)'
			[class.in-meeting]='inMeeting(day, slot)' [class.blockout]='isBlockout(day, slot)'>
			<i [class]='checkMarkClass(day, slot)'></i>
		</button>
	</div>

</div>