import { AccCohortPageService, AccCohortPageTabId as TabId, AccCohortPageViewId as ViewId } from '@ACC-area';
import { Component, Input, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';
import { map, takeUntil } from 'rxjs/operators';


@Component({
	selector: 'acc-cohort-views-part',
	templateUrl: './cohort-views.part.html',
})
export class AccCohortViewsPart extends DestroyablePart implements OnInit {

	@Input() readonly: boolean;
	// @Output() selectedDropListChanged = new EventEmitter<boolean>();


	viewDroplistItems: DroplistItem<never>[] = [
		{ uniqueId: ViewId.Teams, groupName: 'Teams', iconContext: getIconContext(Icon.view_teams), customSort: '1-coh-1', },
		{ uniqueId: ViewId.Entrepreneurs, groupName: 'Teams', iconContext: getIconContext(Icon.view_entrepreneurs, undefined, undefined, 'Entrepreneurs'), customSort: '1-coh-2' },
		{ uniqueId: ViewId.MatchedMentors, groupName: 'Teams', iconContext: getIconContext(Icon.view_mentors, undefined, undefined, 'Matched Mentors'), customSort: '1-coh-3' },
		{ uniqueId: ViewId.GoalsAndProgress, groupName: 'Teams', iconContext: getIconContext(Icon.view_goals), customSort: '1-coh-4', },
		{ uniqueId: ViewId.CurriculumSessions, groupName: 'Curriculum', iconContext: getIconContext(Icon.view_sessions), customSort: '2-coh-1', },
		{ uniqueId: ViewId.CurriculumTopics, groupName: 'Curriculum', iconContext: getIconContext(Icon.view_topics), customSort: '2-coh-2', },
		{ uniqueId: ViewId.CurriculumTopicSpecialists, groupName: 'Curriculum', iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Topic Specialists'), customSort: '2-coh-3', },
		{ uniqueId: ViewId.CurriculumSurveysBySession, groupName: 'Curriculum', iconContext: getIconContext(Icon.view_surveys, undefined, undefined, 'Surveys By Session'), customSort: '2-coh-4', },
		{ uniqueId: ViewId.CurriculumSurveysByTopic, groupName: 'Curriculum', iconContext: getIconContext(Icon.view_surveys, undefined, undefined, 'Surveys By Topic'), customSort: '2-coh-5', },
		{ uniqueId: ViewId.CurriculumSurveysBySpecialist, groupName: 'Curriculum', iconContext: getIconContext(Icon.view_surveys, undefined, undefined, 'Surveys By Specialist'), customSort: '2-coh-6', },
		{ uniqueId: ViewId.FinalPitchEvents, groupName: 'Final Presentations', iconContext: getIconContext(Icon.view_events), customSort: '3-coh-1' },
		{ uniqueId: ViewId.FinalPitchJudges, groupName: 'Final Presentations', iconContext: getIconContext(Icon.view_judges), customSort: '3-coh-2' },
		{ uniqueId: ViewId.FinalPitchTeams, groupName: 'Final Presentations', iconContext: getIconContext(Icon.view_teams), customSort: '3-coh-3' },
		{ uniqueId: ViewId.FinalPitchAssessments, groupName: 'Final Presentations', iconContext: getIconContext(Icon.view_assessments), customSort: '3-coh-4' },
		{ uniqueId: ViewId.FinalPitchEditor, groupName: 'Final Presentations', iconContext: getIconContext(Icon.view_editor, undefined, undefined, 'Assessments Editor'), customSort: '3-coh-5' },
		{ uniqueId: ViewId.FinalPitchFeedback, groupName: 'Final Presentations', iconContext: getIconContext(Icon.view_feedback), customSort: '3-coh-6' },
		{ uniqueId: ViewId.TeamParticipation, groupName: 'Graduation', iconContext: getIconContext(Icon.view_teams, undefined, undefined, 'Team Participation'), customSort: '4-coh-1', },
		{ uniqueId: ViewId.DecisionMatrix, groupName: 'Graduation', iconContext: getIconContext(Icon.view_decide_awards), customSort: '4-coh-2', },
		{ uniqueId: ViewId.ShowcaseEvent, groupName: 'Graduation', iconContext: getIconContext(Icon.view_events, undefined, undefined, 'Showcase Event & Awards'), customSort: '4-coh-3' },
		{ uniqueId: ViewId.Q2Event, groupName: 'Quarterlies', iconContext: getIconContext(Icon.view_events, undefined, undefined, 'Q2 Event & Awards'), customSort: '5-coh-1' },
		{ uniqueId: ViewId.Q3Event, groupName: 'Quarterlies', iconContext: getIconContext(Icon.view_events, undefined, undefined, 'Q3 Event & Awards'), customSort: '5-coh-2' },
		{ uniqueId: ViewId.Q4Event, groupName: 'Quarterlies', iconContext: getIconContext(Icon.view_events, undefined, undefined, 'Q4 Event & Awards'), customSort: '5-coh-3' },
	];

	selectedViewItem$ = this.pageService.selectedView$.pipe(
		map(viewId => this.viewDroplistItems.find(item => item.uniqueId == viewId)),
	);

	height: number;


	constructor(
		public pageService: AccCohortPageService,
	) {
		super();
	}


	ngOnInit() {
		super.initDestroyable();
		this.pageService.initializeUrl();
		this.pageService.layout$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(layout => {
				this.height = layout.tabContentHeight - layout.tab1ViewDroplistHeight;
			});
	}


	onViewDroplistChange(selectedView: DroplistItem) {
		let tabId: TabId = TabId.Teams;

		if (selectedView.groupName == 'Teams') tabId = TabId.Teams;
		else if (selectedView.groupName == 'Curriculum') tabId = TabId.Curriculum;
		else if (selectedView.groupName == 'Final Presentations' || selectedView.groupName == 'Showcase') tabId = TabId.Final;
		else if (selectedView.groupName == 'Quarterlies') tabId = TabId.Quarterlies;

		this.pageService.selectTabAndView(tabId, selectedView.uniqueId, true);
		// this.selectedDropListChanged.emit(false);
	}


	showView(view: string, viewItem: DroplistItem<never, ViewId>) {

		if (!viewItem?.uniqueId) return false;
		const viewId = viewItem.uniqueId;

		if (viewId == ViewId.Teams && view == 'Teams') return true;
		if (viewId == ViewId.Entrepreneurs && view == 'Entrepreneurs') return true;
		if (viewId == ViewId.MatchedMentors && view == 'MatchedMentors') return true;
		if (viewId == ViewId.GoalsAndProgress && view == 'GoalsAndProgress') return true;
		if (viewId == ViewId.CurriculumSessions && view == 'CurriculumSessions') return true;
		if (viewId == ViewId.CurriculumTopics && view == 'CurriculumTopics') return true;
		if (viewId == ViewId.CurriculumTopicSpecialists && view == 'CurriculumTopicSpecialists') return true;
		if (viewId == ViewId.CurriculumSurveysBySession && view == 'CurriculumSurveysBySession') return true;
		if (viewId == ViewId.CurriculumSurveysByTopic && view == 'CurriculumSurveysByTopic') return true;
		if (viewId == ViewId.CurriculumSurveysBySpecialist && view == 'CurriculumSurveysBySpecialist') return true;
		if (viewId == ViewId.FinalPitchEvents && view == 'FinalPitchEvents') return true;
		if (viewId == ViewId.FinalPitchJudges && view == 'FinalPitchJudges') return true;
		if (viewId == ViewId.FinalPitchTeams && view == 'FinalPitchTeams') return true;
		if (viewId == ViewId.FinalPitchAssessments && view == 'FinalPitchAssessments') return true;
		if (viewId == ViewId.FinalPitchEditor && view == 'FinalPitchEditor') return true;
		if (viewId == ViewId.FinalPitchFeedback && view == 'FinalPitchFeedback') return true;
		if (viewId == ViewId.TeamParticipation && view == 'TeamParticipation') return true;
		if (viewId == ViewId.DecisionMatrix && view == 'DecideAwards') return true;
		if (viewId == ViewId.ShowcaseEvent && view == 'ShowcaseEvent') return true;
		if (viewId == ViewId.Q2Event && view == 'Q2Event') return true;
		if (viewId == ViewId.Q3Event && view == 'Q3Event') return true;
		if (viewId == ViewId.Q4Event && view == 'Q4Event') return true;

		return false;
	}


	/**
	 * Defines whether the div for the tab1 content should automatically add a verical scrollbar as needed.
	 * This should be set to 'initial' for any views that show grids because the grids add a negative
	 * margin which would force an unwanted scrollbar.
	 */
	getOverflowY(viewItem: DroplistItem<never, ViewId>): 'initial' | 'auto' {

		if (!viewItem?.uniqueId) return 'auto';
		const viewId = viewItem.uniqueId;

		if (viewId == ViewId.GoalsAndProgress) return 'auto';
		if (viewId == ViewId.FinalPitchAssessments) return 'auto';
		if (viewId == ViewId.FinalPitchEditor) return 'auto';
		if (viewId == ViewId.FinalPitchFeedback) return 'auto';

		//
		// The remaining views have grids so we return 'initial'
		//
		if (viewId == ViewId.Teams) return 'initial';
		if (viewId == ViewId.Entrepreneurs) return 'initial';
		if (viewId == ViewId.MatchedMentors) return 'initial';
		if (viewId == ViewId.CurriculumSessions) return 'initial';
		if (viewId == ViewId.CurriculumTopics) return 'initial';
		if (viewId == ViewId.CurriculumTopicSpecialists) return 'initial';
		if (viewId == ViewId.CurriculumSurveysBySession) return 'initial';
		if (viewId == ViewId.CurriculumSurveysByTopic) return 'initial';
		if (viewId == ViewId.CurriculumSurveysBySpecialist) return 'initial';
		if (viewId == ViewId.FinalPitchEvents) return 'initial';
		if (viewId == ViewId.FinalPitchJudges) return 'initial';
		if (viewId == ViewId.FinalPitchTeams) return 'initial';
		if (viewId == ViewId.TeamParticipation) return 'initial';
		if (viewId == ViewId.DecisionMatrix) return 'initial';
		if (viewId == ViewId.ShowcaseEvent) return 'initial';
		if (viewId == ViewId.Q2Event) return 'initial';
		if (viewId == ViewId.Q3Event) return 'initial';
		if (viewId == ViewId.Q4Event) return 'initial';

		return 'auto';
	}
}
