<div class='person-email-editor-part'>
	<div class='person-email-editor-content'>
		<h3 class='do-not-contact-text' *ngIf='person.doNotContactNote'>Do not contact</h3>
		<table class='pro-table pro-table-no-header'>
			<tr>
				<td>
					<button class='btn' *ngIf='!showAddForm' (click)='showAddForm = true'><i
							class="fas fa-plus-circle"></i>
						Add an Email</button>
					<ng-container *ngIf='showAddForm'>
						<me-save-changes-message *ngIf='messagedisplay' [hidden]="messagedisplay.disabled">
						</me-save-changes-message>
						<form [formGroup]="addForm" novalidate>
							<span class='add-form'>
								<input fxflex='80%' class="k-textbox" placeholder="Email Address"
									formControlName="email" required data-email-msg="Email format is not valid"
									(blur)='constants.blur.lowerCaseOfValue(addForm.controls.email)'>
								<spinner-button #messagedisplay (safeClick)='add()' [spinning]='adding'
									[disabled]='addForm.invalid || !addForm.dirty'><i class="far fa-check"></i>
								</spinner-button>
								<spinner-button (safeClick)='cancel()'><i class="far fa-times"></i>
								</spinner-button>
							</span>

							<div fxLayout="row" *ngIf="addForm.controls.email.errors " class="k-tooltip-validation">
								<div *ngIf='addForm.controls.email.errors.pattern'>
									Invalid Email</div>
								<div *ngIf='addForm.controls.email.errors.emailInUse'>
									Email already used</div>
							</div>
						</form>
					</ng-container>
				</td>
			</tr>
			<tr *ngFor='let email of emails'>
				<td>
					<div class='email-display'>
						<span>
							<div class='primary-indicator' *ngIf='email.email === person._email' title='Primary Email'>
								<i class="fas fa-star"></i>
							</div>
							<spinner-button *ngIf='email.email !== person._email' title="Make Primary" [action]='true'
								[spinning]='updating[email.emailId]' (safeClick)='makePrimary(email.emailId)'>
								<i class="far fa-star"></i>
							</spinner-button>
						</span>
						<span class='email-name-section'>
							{{email.email}}
						</span>
						<span>
							<spinner-button *ngIf='email.email !== person._email' [caution]='true' title="Delete"
								[spinning]='deleting[email.emailId]' (safeClick)='delete(email.emailId)'>
								<i class="fas fa-trash-alt"></i>
							</spinner-button>
						</span>
					</div>
				</td>
			</tr>
		</table>
	</div>
</div>