<div class='explorer-part person-explorer-part'>

	<div class="icon-bar">
		<me-icon-bar #iconBar [icons]='views' [orientation]="'Vertical'" (selectionsChange)='setViews($event)'>
		</me-icon-bar>
	</div>

	<div class="content">
		<ng-container *ngIf='selectedViews'>
			<div class="explorer-header">
				<me-icon [icon]="icons.person"></me-icon>
			</div>
			<h4 class="explorer-sub-header">Person</h4>

			<section>
				<explorer-buttons [buttons]='buttons'>

					<button *ngIf="person.hasRedFlag" class="text-btn text-btn-not-inline border-red"
						(click)="click('red-flag')">
						<me-icon [icon]="icons.redFlag"></me-icon>
					</button>

					<button *ngIf="person.asSingleton.doNotContactNote" class="text-btn text-btn-not-inline border-red"
						(click)="click('do-not-contact')">
						<me-icon [icon]="icons.doNotContact"></me-icon>
					</button>

					<button *ngIf="person.asSingleton.isDeceased" class="text-btn text-btn-not-inline border-red"
						(click)="click('deceased')">
						<me-icon [icon]="icons.deceased"></me-icon>
					</button>

					<button *ngIf="person.awardedValue > 0" class="text-btn text-btn-not-inline"
						(click)="click('awards')">
						<me-icon [icon]="icons.award"></me-icon>
					</button>

				</explorer-buttons>
			</section>

			<explorer-section header="Person Details" (tabClose)="tabClose($event)"
				[style.display]="getDisplay('details')">
				<person-explorer-details [person]="person"></person-explorer-details>
			</explorer-section>

			<explorer-section [header]="util.text.singularOrPluralWithCount(noteBoxes.length, 'Note', 'Notes')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('notes')">
				<explorer-note-boxes>
					<ng-container *ngFor="let box of noteBoxes">
						<explorer-note-box [box]="box"> </explorer-note-box>
					</ng-container>
				</explorer-note-boxes>
			</explorer-section>

			<explorer-section header="Associations" (tabClose)="tabClose($event)"
				[style.display]="getDisplay('associations')">
				<explorer-boxes>
					<ng-container *ngFor="let box of companyBoxes">
						<explorer-box [box]="box" [style.display]="getDisplay('companies')"> </explorer-box>
					</ng-container>

					<ng-container *ngFor="let box of acceleratorBoxes">
						<explorer-box [box]="box" [style.display]="getDisplay('programming')"> </explorer-box>
					</ng-container>

					<ng-container *ngFor="let box of pitchContestBoxes">
						<explorer-box [box]="box" [style.display]="getDisplay('programming')"> </explorer-box>
					</ng-container>

					<ng-container *ngFor="let box of mentorshipBoxes">
						<explorer-box [box]="box" [style.display]="getDisplay('mentorships')"> </explorer-box>
					</ng-container>

					<ng-container *ngFor="let box of readingBoxes">
						<explorer-box [box]="box" [style.display]="getDisplay('readings')"> </explorer-box>
					</ng-container>

					<ng-container *ngFor="let box of interviewBoxes">
						<explorer-box [box]="box" [style.display]="getDisplay('interviews')"> </explorer-box>
					</ng-container>

					<ng-container *ngFor="let box of judgementBoxes">
						<explorer-box [box]="box" [style.display]="getDisplay('judgements')"> </explorer-box>
					</ng-container>

					<ng-container *ngFor="let box of relationshipBoxes">
						<explorer-box [box]="box" [style.display]="getDisplay('relationships')"> </explorer-box>
					</ng-container>

				</explorer-boxes>
			</explorer-section>

			<explorer-section [header]="util.text.singularOrPluralWithCount(awardBoxes.length, 'Award', 'Awards')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('awards')">
				<explorer-boxes>
					<ng-container *ngFor="let box of awardBoxes">
						<explorer-box [box]="box"> </explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>


			<explorer-section [header]="util.text.singularOrPluralWithCount(totalTags, 'Tag', 'Tags')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('tags')">
				<explorer-boxes>
					<ng-container *ngFor="let box of tagBoxes">
						<explorer-tag-box [box]="box"> </explorer-tag-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>


			<explorer-section header="Languages & Bios" [style.display]="getDisplay('bios')"
				(tabClose)="tabClose($event)">
				<explorer-boxes>
					<ng-container *ngFor="let box of langAndBioBoxes">
						<person-explorer-lang-and-bio-box [box]="box"> </person-explorer-lang-and-bio-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>


			<explorer-section header="Demographics" [style.display]="getDisplay('demographics')"
				(tabClose)="tabClose($event)">
				<person-explorer-demographics *ngIf="user" [person]="person" [user]="user">
				</person-explorer-demographics>
			</explorer-section>


			<!-- <hr>
			<pre>
				{{ person | json }}
			</pre> -->

		</ng-container>
	</div>
</div>