import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CaptionedLayout } from '@me-interfaces';


@Component({
	selector: 'captioned-layout-select',
	templateUrl: './SHR-CMN_captioned-layout-select.part.html',
	styleUrls: ['./SHR-CMN_captioned-layout-select.part.scss'],
})
export class CaptionedLayoutSelectPart implements OnChanges {

	@Input() value: CaptionedLayout;
	@Input() list: CaptionedLayout[];
	@Input() placeholder: string;
	@Input() disabled = false;
	@Output() selection = new EventEmitter();

	ngOnChanges() {

		if (this.value && this.value.person) {
			const matchFound = this.list.find(cl => cl.person && cl.person.personId == this.value.person.personId);
			if (!matchFound) {
				this.value = {
					text: this.value.text,
					person: this.value.person,
					caption: '{No Position}',
				};
				this.list.push(this.value);
				this.list.sort((a, b) => a.text > b.text ? -1 : 1);
			}
			else this.value = matchFound;
		}
	}

	selectPerson(e: CaptionedLayout) {
		this.value = e;
		this.selection.emit(e);
	}
}
