import { FieldNumericFormat } from '@me-interfaces';

export function renderNumeric(value: number, format: FieldNumericFormat, saveZeroAsNull: boolean): string {

	if (value == 0 && !saveZeroAsNull) return `${value.toLocaleString()}`;
	else if (!value) return '';
	else if (format == FieldNumericFormat.Dollar) return `$${value.toLocaleString()}`;
	else if (format == FieldNumericFormat.Percentage) return `${value.toLocaleString()}%`;
	else return `${value.toLocaleString()}`;

}