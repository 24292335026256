import { NationalDashboardPageService, SharedDashboardsService } from '@ADMIN-area';
import { Component, Input, OnChanges } from '@angular/core';
import { DialogService } from '@me-services/ui/dialog';
import { PageTabsLayout } from '@me-shared-parts/UI-common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PerformanceIndicator } from './indicator-interfaces';
import { renderIndicators } from './render-indicators';


@Component({
	selector: 'shared-indicators-view-part',
	templateUrl: './indicators-view.part.html',
	styleUrls: ['indicators-view.part.scss'],
})
export class SharedIndicatorsViewPart implements OnChanges {

	@Input() layout$ = this.pageService.layout$;

	height$ = this.createHeightObservable(this.layout$);
	indicators$ = this.dashboardService.dashboardData$.pipe(map(data => renderIndicators(data)));


	constructor(
		private pageService: NationalDashboardPageService,
		private dialogService: DialogService,
		private dashboardService: SharedDashboardsService,
	) {
	}


	ngOnChanges() {
		if (this.layout$) this.height$ = this.createHeightObservable(this.layout$);
	}


	createHeightObservable(layout$: Observable<PageTabsLayout>): Observable<number> {
		return layout$.pipe(map(layout => layout.tab1ContentHeight - layout.tab1ViewDroplistHeight));
	}


	onClick(indicator: PerformanceIndicator) {
		this.dialogService.showMessage(`Clicking on a performance indicator will navigate to a view with more detail. However, that functionality has not been implemented yet.
		
For now, select the related view in the droplist and use the grid filters and grouping to explore the data.

You clicked indicator number ${indicator.name}`);
	}

}