import { Application, DbConceptName, PhoneTypeId } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForParticipants(ds: DataService, application: Application): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const participants = application.applicationParticipants.map(participant => participant.person);

	for (const participant of [...participants].sort((a, b) => a._name > b._name ? 1 : -1)) {

		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Member,
			header: participant.personId == application.personId ? 'Applicant' : 'Participant',
			buttons: [
				{ explorer: { explorableId: { conceptName: DbConceptName.Person, id: participant.personId }, navTooltip: `${participant.personId == application.personId ? 'Applicant' : 'Participant'}: ${participant._name}` } },
				{ link: { linkType: 'email', email: participant._email } },
			],
			notes: '',
		};


		if (participant.phone) {
			box.buttons.push({ link: { linkType: 'call', phone: participant.phone } });

			if (participant.phoneTypeId == PhoneTypeId.Mobile) {
				box.buttons.push({ link: { linkType: 'sms', phone: participant.phone } });
			}
		}

		if (participant.personId == application.personId) boxes.unshift(box);
		else boxes.push(box);
	}

	return boxes;
}