import { Component, Input, OnChanges } from '@angular/core';
import { Old_Logo } from '@me-interfaces';


@Component({
	selector: 'me-logo',
	templateUrl: './SHR-CMN_logo.part.html',
	styleUrls: ['SHR-CMN_logo.part.scss'],
})
export class LogoPart implements OnChanges {

	@Input() size: '30px' | '35px' | '40px' = '35px';
	@Input() logo: Old_Logo;

	public imgSrc = 'assets/img/company.png';


	ngOnChanges() {
		if (!this.logo) throw new Error('Required logo attribute not provided on me-logo component.');
	}

	getClass() {
		if (this.size == '30px') return 'size30';
		else if (this.size == '35px') return 'size35';
		else if (this.size == '40px') return 'size40';
		else return '';
	}
}