import { DbdState, DbsPerson, DbsSite, Site } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";


export class SitePackageManager extends PackageManager<DbsSite, Site>{

	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsSite>,
		private domain: DomainDataManagers,
		private person: SingletonsManager<DbsPerson>,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}

	/**
	 * Convert an array of DbcSite to an array of Site
	 */
	protected async _createPackages(dbsSites: DbsSite[]): Promise<Site[]> {

		//
		// Get all the people
		//
		const personIds: number[] = [];
		for (const site of dbsSites) {
			if (site.directorId) personIds.push(site.directorId);
			if (site.altDirectorId) personIds.push(site.altDirectorId);
			personIds.push(site.updatedByPersonId);
		}

		const personMap = await this.person.getManyAsMap(personIds);

		//
		// Get all state names
		//
		const stateNameByCode: Record<string, string> = {};
		const states = this.domain.state.getAll();
		for (const state of states) {
			stateNameByCode[state.stateCode] = state.state;
		}


		//
		// Package 'em up
		//
		const sites: Site[] = dbsSites.map(site => {

			const director = personMap[site.directorId];
			const altDirector = personMap[site.altDirectorId];
			const updatedByPerson = personMap[site.updatedByPersonId];

			return {
				...site,
				id: site.siteId,
				stateName: stateNameByCode[site.stateCode],
				explorerName: site.name,
				directorName: director?._name || `Person #${site.directorId}`,
				altDirectorName: altDirector?._name,
				updatedByPersonName: updatedByPerson?._name || `Person #${site.updatedByPersonId}`,
				appUrl: this.createAppUrl(site),
				hasRedFlag: false,
			};
		});

		return sites;
	}

	private createAppUrl(site: DbsSite): string {
		const siteId = site.siteId;
		return `/access/admin/national/sites/${siteId}`;
	}


	/**
	 * Get all sites for a region
	 */
	public async getByRegionIds(regionIds: number[]): Promise<Readonly<Record<number, ReadonlyArray<Site>>>> {

		return await this.getPackagesAsArraysByForeignIds('regionId', regionIds);

	}

}