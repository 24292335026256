import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'no-access',
	templateUrl: './SHR-CMN_no-access.part.html',
	styleUrls: ['SHR-CMN_no-access.part.scss'],
})
export class NoAccessPart implements OnInit {

	show = false;

	ngOnInit() {
		setTimeout(() => {
			this.show = true;
		}, 500);
	}

}
