import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DbsAgreementVersion, LanguageId } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';

@Component({
	selector: 'agreement-text',
	templateUrl: './agreement-text.part.html',
	styleUrls: ['agreement-text.part.scss'],
})
export class AgreementTextPart extends DestroyablePart implements OnInit, OnChanges {

	@Input() agreementVersion: DbsAgreementVersion;
	@Input() agreementType: { agreementTypeId: number, nameEN: string, nameES: string, };
	@Input() languageId: LanguageId;
	@Input() readonly: boolean;
	@Input() agreementBodyDivHeight?: number;
	@Input() alreadySigned = false;
	@Input() includeLastEditedBy = false;
	@Output() signed = new EventEmitter<boolean>();


	public closed = false;
	public agreementContentDisplay: { name: string, introduction: string, agreement: string, updatedDate: string, };
	agreementBodyDivSize = '100%';

	constructor(private util: UtilityService) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

	async ngOnChanges() {
		if (this.agreementVersion.agreementTypeId != this.agreementType.agreementTypeId) {
			console.error(`The agreementTypeId in agreementVersion is not the same as the agreementType agreementTypeId`);
			return;
		}

		await this.getAgreementDisplay();
		if (this.agreementBodyDivSize) this.agreementBodyDivSize = `${this.agreementBodyDivHeight}px`
	}


	async getAgreementDisplay() {
		const lang = this.languageId == LanguageId.Spanish ? 'ES' : 'EN';
		const pleaseSignText = this.languageId == LanguageId.Spanish ? 'Por favor lea y firme a continuación.' : 'Please read and sign below.';

		const introduction = this.alreadySigned ? this.agreementVersion[`introduction${lang}`] : pleaseSignText + '\n\n' + this.agreementVersion[`introduction${lang}`];


		this.agreementContentDisplay = {
			name: this.agreementType[`name${lang}`],
			introduction,
			agreement: this.agreementVersion[`agreement${lang}`],
			updatedDate: this.util.date.formatUTC(this.agreementVersion.updatedUTC, 'MMM D, YYYY', 'H:MM AM EST', LanguageId.English),
		};
	}


	close() {
		this.closed = true;
	}

}