import { ExplorableConceptName } from "@me-interfaces";
import { DataService } from "../..";
import { createSearchMatches } from "./create-search-matches";
import { checkTexts } from "./find/check-texts";
import { findAccTeamMatches } from "./find/find-acc-team-matches";
import { findAcceleratorMatches } from "./find/find-accelerator-matches";
import { findApplicationMatches } from "./find/find-application-matches";
import { findCompanyMatches } from "./find/find-company-matches";
import { findEventMatches } from "./find/find-event-matches";
import { findPersonMatches } from "./find/find-person-matches";
import { findPicTeamMatches } from "./find/find-pic-team-matches";
import { findPitchContestMatches } from "./find/find-pitch-contest-matches";
import { findSiteMatches } from "./find/find-site-matches";
import { findVenueMatches } from "./find/find-venue-matches";
import { getMatchById } from "./get-match-by-id";
import { getSearchableData } from "./get-searchable-data";
import { PendingSearchMatch, SearchFieldMatch, SearchMatch, SearchMatchLevel, SearchRegExps } from "./interfaces";
import { matchAllFromFilters } from "./match-all-from-filters";
import { parseSearchText, SearchFilters } from "./parse-search-text";



export class SearchEngine {

	constructor(private ds: DataService) {
	}


	/**
	 * Given a search phrase and possibly a type of singleton to consider, find all the matches.
	 */
	async search(
		phrase: string,
		filters: SearchFilters,
		/** Search for a specific concept or leave it undefined to search for anything */
		limitedToConcept: ExplorableConceptName = undefined,
	): Promise<SearchMatch[]> {

		phrase = phrase
			.trim()
			.toLowerCase();

		if (phrase.length < 2 && !filters.filtered) return [];

		const text = parseSearchText(phrase);

		let pendingMatches: PendingSearchMatch[] = [];

		//
		// Check for an id match
		//
		const idMatch = await getMatchById(this.ds, text);

		if (idMatch) {
			//
			// If we are searching for a specific concept type and got an id match
			// of a different type then return nothing.
			//
			if (limitedToConcept && idMatch._concept !== limitedToConcept) return [];

			pendingMatches.push({
				explorable: idMatch,
				level: SearchMatchLevel.Exact,
				fieldMatches: [
					{ field: 'id', level: SearchMatchLevel.Exact }
				],
			});
		}



		//
		// If no exact match then we use the filters and/or search text
		//
		else {

			const data = await getSearchableData(this.ds, limitedToConcept, filters);

			if (text.length < 2) {
				if (filters.filtered) {
					pendingMatches.push(...await matchAllFromFilters(data));
				}
				else return [];
			}
			else {
				//
				// Filter out by text
				//
				const re = this.createRegExps(text);

				const termDigits = this.ds.util.number.extractDigits(text);
				const termPhoneDigits = termDigits.length == 11 && termDigits.startsWith('1') ? termDigits.substring(1) : termDigits;

				pendingMatches = [
					...await findAccTeamMatches(data, re),
					...await findAcceleratorMatches(data, re),
					...await findApplicationMatches(data, re),
					...await findCompanyMatches(data, re, text, termPhoneDigits, this.ds),
					...await findEventMatches(data, re),
					...await findPersonMatches(data, re, text, termPhoneDigits, this.ds),
					...await findPicTeamMatches(data, re),
					...await findPitchContestMatches(data, re),
					...await findSiteMatches(data, re, text, this.ds),
					...await findVenueMatches(data, re),
				];

			}
		}



		if (pendingMatches.length == 0) return [];



		//
		// Collapse field matches by name with max(SearchMatchLevel) and sort them
		//
		for (const pendingMatch of pendingMatches) {

			const fields: Record<string, SearchMatchLevel> = {};

			for (const fieldMatch of pendingMatch.fieldMatches) {
				fields[fieldMatch.field] = Math.max(fields[fieldMatch.field] && SearchMatchLevel.None, fieldMatch.level);
			}

			const fieldMatches: SearchFieldMatch[] = [];

			for (const field in fields) {
				fieldMatches.push({ field, level: fields[field] });
			}

			fieldMatches.sort((m1: SearchFieldMatch, m2: SearchFieldMatch) => {
				if (m1.level !== m2.level) return m1.level - m2.level;
				return m2.field > m1.field ? -1 : 1;
			});
		}


		//
		// If one exact match found, and not searching for a specific type, then
		// we can add all things related to that one exact match.
		//
		if (pendingMatches.length == 1 && pendingMatches[0].level == SearchMatchLevel.Exact && limitedToConcept == undefined) {
			// TODO: Determine and add all related explorables as SearchMatchLevel.Related
		}



		//
		// Transform pending matches into actual matches
		//

		const matches = await createSearchMatches(this.ds, pendingMatches);



		//
		// Sort the matches by level descending and updated date ascending
		//
		matches.sort((m1: SearchMatch, m2: SearchMatch) => {

			if (m1.level !== m2.level) return m2.level - m1.level;
			return m2.updatedUTC - m1.updatedUTC;

		});


		return matches;

	}


	createRegExps(term: string): SearchRegExps {
		return {
			full: this.ds.util.regExp.create(term, 'full'),
			startsWith: this.ds.util.regExp.create(term, 'starts-with'),
			endsWith: this.ds.util.regExp.create(term, 'ends-with'),
			contains: this.ds.util.regExp.create(term, 'contains'),
		};
	}

}
