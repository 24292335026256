<div class='site-config-interns-tab'>
	<ng-container *ngIf='(interns$ |async); let interns;'>
		<ng-container *ngIf='interns.length > 0'>
			<me-h2>Current Interns</me-h2>
			<mat-accordion class='grid-accordion'>
				<mat-expansion-panel [@.disabled]='accordionDisableAnimation'
					*ngFor='let intern of interns; let i = index;' [expanded]="i === currentlyExpandedIndex"
					(opened)="onExpand(i)">
					<mat-expansion-panel-header>
						<mat-panel-title>
							<div class='intern'>
								<me-avatar [avatar]='intern.person' size='40px'></me-avatar>
								<div class='names-container'>
									{{intern.person._name}}
								</div>
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div class='config-intern-checkboxwrap'>
						<mat-checkbox #canView
							matTooltip="Cannot change selection as {{intern.person._name}} is a Program Coordinator for {{intern.numProgramsAssigned}} program{{intern.numProgramsAssigned !== 1 ? 's':''}}."
							[matTooltipDisabled]='intern.numProgramsAssigned === 0' matTooltipPosition='right'
							[checked]='intern.siteIntern.canViewContacts'
							[disabled]='readonly || intern.numProgramsAssigned > 0'
							(change)='setCanViewContacts($event, intern)'>Can view contacts
						</mat-checkbox>
						<ng-container *ngIf='canView.checked'>
							<mat-checkbox [disabled]='true || readonly'
								[checked]='intern.siteIntern.canPrepareSiteContacts'>Can
								input {{site.code.toUpperCase()}} contacts for review (coming soon)
							</mat-checkbox>
						</ng-container>
					</div>

					<div *ngIf='intern.siteIntern.canViewContacts' class='mt-3'>
						<me-h2>Can access these programs:</me-h2>
						<div class='ml-5'>
							<p *ngIf='intern.numProgramsAssigned === 0'><i class='fas fa-info-circle info-color'></i>
								Not an
								Intern
								for any program yet. </p>
							<div class='config-intern-checkboxwrap'>
								<mat-checkbox *ngFor='let offeredProgram of intern.programs' [disabled]='readonly'
									(change)='setProgramIntern($event, intern, offeredProgram)'
									[checked]='offeredProgram.isIntern'>
									{{offeredProgram.program.name}}
									<small>({{offeredProgram.program.language.name}}
										{{offeredProgram.program.programType.name}})</small>
								</mat-checkbox>
							</div>
						</div>
					</div>

					<p class='mt-2'>{{intern.person.firstName}} has been an intern for {{intern.daysSince}}</p>

					<button-container>
						<a class='btn' routerLink='/access/contacts/people/{{intern.person.personId}}/editors/profile'
							title='Edit Contact Record'>
							<i class='far fa-id-card'></i></a>
						<a class='btn' *ngIf='intern.person._email' title='Send Email to {{intern.person.firstName}}'
							[href]='sanitize("mailto:" + intern.person._email)'>
							<i class='far fa-envelope'></i></a>
						<a class='btn' *ngIf='intern.phone' title='Call {{intern.person.firstName}}'
							[href]='sanitize("tel:" + intern.person.phone)'>
							<i class='far fa-phone'></i></a>
						<a class='btn' *ngIf='intern.person.phone && intern.person.phoneTypeId === PhoneTypeId_Mobile'
							title='Send a Text to {{intern.person.firstName}}'
							[href]='sanitize("sms:" + intern.person.phone)'>
							<i class='far fa-comment'></i></a>
						<a class='btn' target='_blank'
							routerLink='/access/contacts/people/{{intern.person.personId}}/editors/notes' title='Notes'>
							<i *ngIf='intern.person._entityExtracts.noteCounts.redFlags' style='color:darkred'
								class='fas fa-flag'></i>
							<i *ngIf='!intern.person._entityExtracts.noteCounts.redFlags'
								class='{{intern.person._entityExtracts.noteCounts.total === 0 ? "far fa-sticky-note" : "fas fa-sticky-note"}}'></i>
						</a>
						<spinner-button title='Remove {{intern.person.firstName}} as Intern' [disabled]='readonly'
							[caution]='true' (safeClick)='delete(intern)'><i class='far fa-times'></i></spinner-button>
					</button-container>
				</mat-expansion-panel>
			</mat-accordion>
		</ng-container>
	</ng-container>

	<ng-container>
		<me-h2>Add New Intern</me-h2>

		<div class='person-finder'>
			<button class="text-btn text-btn-not-inline" (click)="searchPerson()">
				<me-icon [icon]="searchIcon"></me-icon>
			</button>
			<button class='btn btn-action' [disabled]='readonly || !personToAdd' (click)='addIntern()'>Add
				<span *ngIf="personToAdd">{{personToAdd._name}}</span></button>
		</div>
	</ng-container>

	<ng-container *ngIf='(interns$ |async); let interns;'>
		<button-container *ngIf='interns.length'>
			<a class='btn' *ngIf='allInternEmails.length' title='Email All Interns'
				[href]='sanitize("mailto:" + allInternEmails.join(";"))'>
				<i class='far fa-envelope'></i> Email All Interns</a>
			<a class='btn' *ngIf='allInternPhoneNumbers.length' title='Text All Interns'
				[href]='sanitize("sms:" + allInternPhoneNumbers.join(";"))'>
				<i class='far fa-comment'></i> Text All Interns</a>
		</button-container>
	</ng-container>
</div>