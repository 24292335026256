import { Injectable } from '@angular/core';
import { DialogAction, DialogData, DialogService } from '@me-services/ui/dialog';
import { ReplaySubject } from 'rxjs';
import { TextDialog } from './SHR-CMN_text.dialog';


@Injectable({ providedIn: 'root' })
/**
 * Provides utilities for editing and adding dialogs including
 * ability to launches a dialog for user interaction.
 * @deprecated Don't use this service. TODO: Move it to DialogService is needed.
 */
export class ShowTextDialogService {

	public textChanged = new ReplaySubject<string>(1);

	constructor(private dialogService: DialogService) { }


	/**
	 * Launches the text dialog to add a new text or edit an existing one.
	 * Returns the saved text or undefined if it wasn't saved.
	 */
	public async show(readonly = false, text: string, header: string): Promise<string | undefined> {

		const data: DialogData<{ readonly: boolean, text: string, header: string }> = { data: { readonly, text, header } };
		const action: DialogAction<string> = await this.dialogService.showCustom(TextDialog, data, 600, 400);

		if (action?.id === 'save') {
			return action.callbackResult;
		}

		return undefined;
	}

}