<div class='acc-overview-view-part' *ngIf="viewDroplistItems.length">

	<ng-container *ngIf='selectedViewItem$ | async; let selectedViewItem'>

		<me-droplist class="views-droplist" [items]='viewDroplistItems' [selectedItem]='selectedViewItem'
			(selectionChange)='onViewDroplistChange($event)' [filterable]='false' [navigation]='true'
			sortBy='customSort' style='display:inline-block'>
		</me-droplist>

		<acc-details-view-part *ngIf="showView('Details', selectedViewItem)">
		</acc-details-view-part>

		<acc-all-applications-view-part *ngIf="showView('AllApplications', selectedViewItem)">
		</acc-all-applications-view-part>

		<acc-all-events-view-part *ngIf="showView('AllEvents', selectedViewItem)">
		</acc-all-events-view-part>

		<acc-all-awards-view-part *ngIf="showView('AllAwards', selectedViewItem)">
		</acc-all-awards-view-part>

		<acc-all-missing-signatures-view-part *ngIf="showView('AllMissingSignatures', selectedViewItem)">
		</acc-all-missing-signatures-view-part>

	</ng-container>
	
</div>