import { NationalDashboardPageService, SharedDashboardsService } from '@ADMIN-area';
import { Component } from '@angular/core';
import { GridColumnType, GridSetup } from '@me-grid';
import { map } from 'rxjs/operators';
import { DashboardDataReader } from '../data/interfaces';


@Component({
	selector: 'shared-acc-readers-view-part',
	templateUrl: './acc-readers-view.part.html',
})
export class SharedAccReadersViewPart {

	public gridSetup = this.setupGrid();

	public rows$ = this.sharedDashboardsService.dashboardData$.pipe(
		map(data => data ? data.accelerator.accReaders : [])
	);


	constructor(
		public pageService: NationalDashboardPageService,
		private sharedDashboardsService: SharedDashboardsService,
	) {
		sharedDashboardsService.identifier$.value
	}


	private setupGrid(): GridSetup<DashboardDataReader> {

		return {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Reader",
			rowPluralName: "Readers",
			rowKey: "personId",
			stateKey: "shared-acc-readers-view-part",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				{ field: 'countOfLocalReaderRole', type: GridColumnType.number, header: 'Reader', width: 200, headerTooltip: 'Count of accelerators with readings at this level (e.g. National, Region, Site)' },
				{ field: 'countOfLocalReadings', type: GridColumnType.number, header: 'Readings', width: 200, headerTooltip: 'Count of application readings at this level (e.g. National, Region, Site)' },
			],
			initialState: {},
		};
	}

}