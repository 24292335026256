import { Component, Input } from '@angular/core';
import { StageStatusItem } from '@me-interfaces';


@Component({
	selector: 'stage-status',
	templateUrl: './SHR-PG_stage-status.part.html',
	styleUrls: ['SHR-PG_stage-status.part.scss'],
})
export class StageStatusPart {

	@Input() statuses: StageStatusItem[];

}
