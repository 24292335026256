import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DataService } from '@me-services/core/data';

@Injectable()
export class AccApplicationMenuLabelResolver {

	constructor(
		private ds: DataService,
	) { }

	async resolve(route: ActivatedRouteSnapshot) {

		const applicationId = +route.params.accApplId;
		if (isNaN(applicationId)) throw new Error(`Unexpected Id: ${applicationId}`);

		const menuLabel = (await this.ds.admin.application.getOnePackage(applicationId))?.company._name || `${applicationId}?`;
		return menuLabel;
	}
}