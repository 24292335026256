<app-area-access [areaService]='adminAreaService'>
	<ng-container>
		<div class='national-overview-page'>
			
			<national-toolbar></national-toolbar>

			<page-tabs [tabNames]='["National", "Get Help"]' [pageService]='pageService'
				(layoutChange)='onLayoutChange($event)'>

				<ng-template pageTab1>
					<national-views-part></national-views-part>
				</ng-template>

				<ng-template pageTab2>
					<!-- <national-news-tab-part></national-news-tab-part> -->
					<national-get-help-tab-part></national-get-help-tab-part>
				</ng-template>
			</page-tabs>

		</div>
	</ng-container>
</app-area-access>