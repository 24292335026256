import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataService } from '@me-services/core/data';



export interface CountRow {
	label: string,
	count: number,
	/** Shows the percentage as a tooltip. Leave undefined to not show a tooltip */
	percent?: number,
	personIds: number[],
}

export interface CountsTableData {
	header: string,
	rows: CountRow[],
}


@Component({
	selector: 'counts-table-part',
	templateUrl: './counts-table.part.html',
	styleUrls: ['./counts-table.part.scss'],
})
export class CountsTablePart {

	@Input() data: CountsTableData = { header: 'Item', rows: [] };
	@Output() countClick = new EventEmitter<CountRow>();


	constructor(private ds: DataService) {
	}


	getTitle(row: CountRow) {
		if (row.percent == undefined) return undefined;
		return Math.round(row.percent) + '%';
	}


	getTitleShowOn(row: CountRow) {
		if (row.percent == undefined) return 'none';
		return 'hover';
	}


	async openContactor(personIds: number[]) {
		await this.ds.contactor.show(
			false,
			personIds,
			'counts-table-part',
		);
	}
}