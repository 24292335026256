import { Injectable } from '@angular/core';
import { AccStageId } from '@me-interfaces';
import { PageTabsLayoutPageService } from '@me-shared-parts/UI-common';
import { BehaviorSubject } from 'rxjs';
import { ApplicationGridFilter } from './acc-application-filter-helpers';
import { InterviewerGridFilter } from './acc-interviewer-filter-helpers';
import { ReaderGridFilter } from './acc-reader-filter-helpers';


/**
 * The list of tabs on this page
 */
export enum AccPreAcceleratorTabId {
	Manage = 1,
	Apply = 2,
	Read = 3,
	Interview = 4,
	Select = 5,
	Match = 6,
}

/**
 * The list if main tab droplist views on this page
 */
export enum AccPreAcceleratorViewId {

	ApplyingApplications = 1,
	ApplyingDeferrals = 2,

	ReadingEvent = 3,
	ReadingReaders = 4,
	ReadingApplications = 5,
	ReadingAssessments = 6,
	ReadingEditor = 7,
	ReadingFeedback = 8,

	InterviewingEvents = 9,
	InterviewingInterviewers = 10,
	InterviewingApplications = 11,
	InterviewingAssessments = 12,
	InterviewingEditor = 13,
	InterviewingFeedback = 14,

	SelectingApplications = 15,
	SelectingTeams = 16,

	MMEvents = 17,
	MMMentors = 18,
	MMTeams = 19,
	MMAssessments = 20,
	MMEditor = 21,
	MMScheduleMap = 22,
	MMScheduleMapEditor = 23,
	MMMatchingHints = 24,
	MMMatchingTool = 25,
}

export type ApplicationStatusFilter = 'All' | 'Open' | 'Accepted' | 'Not Accepted';
export type MatchingStatusFilter = 'All' | 'Can Coach' | 'Multi Use';


@Injectable({ providedIn: 'root' })
export class AccPreAcceleratorPageService extends PageTabsLayoutPageService<AccPreAcceleratorTabId, AccPreAcceleratorViewId> {

	public readonly applicationsStatusFilter$ = new BehaviorSubject<ApplicationStatusFilter>('All');

	public readonly applyApplicationsFilter$ = new BehaviorSubject<ApplicationGridFilter>(undefined);
	public readonly readReadersFilter$ = new BehaviorSubject<ReaderGridFilter>(undefined);
	public readonly interviewInterviewersFilter$ = new BehaviorSubject<InterviewerGridFilter>(undefined);

	public readonly matchingStatusFilter$ = new BehaviorSubject<MatchingStatusFilter>('All');

	constructor() {
		super(AccPreAcceleratorPageService.name, AccPreAcceleratorTabId.Apply, AccPreAcceleratorViewId.ApplyingApplications);

		this.applyApplicationsFilter$.subscribe(filter => {

			if (!filter) return;

			let viewId: AccPreAcceleratorViewId = AccPreAcceleratorViewId.ApplyingApplications;

			if (filter.accStageId == AccStageId.Reading) viewId = AccPreAcceleratorViewId.ReadingApplications;
			if (filter.accStageId == AccStageId.Interviewing) viewId = AccPreAcceleratorViewId.InterviewingApplications;
			if (filter.accStageId == AccStageId.SelectingCohort) viewId = AccPreAcceleratorViewId.SelectingApplications;

			this.selectView(viewId);
		});



		this.readReadersFilter$.subscribe(filter => {
			if (!filter) return;
			const viewId: AccPreAcceleratorViewId = AccPreAcceleratorViewId.ReadingReaders;
			this.selectView(viewId);
		});

		this.interviewInterviewersFilter$.subscribe(filter => {
			if (!filter) return;
			const viewId: AccPreAcceleratorViewId = AccPreAcceleratorViewId.InterviewingInterviewers;
			this.selectView(viewId);
		});
	}

}