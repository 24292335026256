import { _calcScore } from "./calc-score";
import { _getBucket } from "./get-bucket";


export const NPSUtilities = {

	/**
	 * Given an NPS score of 0..10, return the named category
	 * @param score A score from 0 to 10
	 * @param bucketNames  The name to use for each NPS bucket
	 * @returns 
	 */
	getBucket: (
		score: number,
		bucketNames = ['Disagree', 'Neutral', 'Agree']
	): string => {

		return _getBucket(score, bucketNames);

	},


	/**
 * Convert an array of NPS scores, 0..10, calculate a score.
 * @param standardScoring If true, then a standard NPS score ranging from -100 to 100 will be returned. If false, the score will be translated into a range of 0 to 100. Default: false
 */
	calcScore: (ratings: number[], standardScoring = false) => {
		return _calcScore(ratings, standardScoring);
	},

}