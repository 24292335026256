import { Injectable } from "@angular/core";
import { AppAreaIdentifier, RegionAreaAccess, RegionAreaRawData } from "@me-interfaces";
import { AppAreaService } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { DexieService } from "@me-services/core/dexie";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";
import { PageSpinnerService } from "@me-services/ui/page-spinner";
import { UrlService } from "@me-services/ui/url";
import { UserAreaService } from "@me-user-area";
import { mapToData } from "./map-to-data";
import { RegionAreaData } from "./region-area-data";
import { RegionSubAreaApplications } from "./region-subarea-applications";

const NO_ACCESS: RegionAreaAccess = {
	root: 'None',
	sub: {}
};

@Injectable({
	providedIn: 'root',
})
export class RegionAreaService extends AppAreaService<AppAreaIdentifier<number>, RegionAreaAccess, RegionAreaRawData, RegionAreaData> {

	//
	// Sub areas
	//
	public applications = new RegionSubAreaApplications(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this));




	constructor(
		urlService: UrlService,
		dexieService: DexieService,
		ds: DataService,
		spinnerService: PageSpinnerService,
		util: UtilityService,
		private func: FuncService,
		userAreaService: UserAreaService,
	) {
		super(
			'Staff',
			userAreaService.user$,
			'Region',
			dexieService,
			ds,
			spinnerService,
			util,
			urlService.appAreas.regionId$,
			func.areas.region.get,
			async () => ({ area: 'Region', access: NO_ACCESS, md5Hash: 'NO_ACCESS' }),
			async rawData => await mapToData(rawData, util, ds),
			data => 'Region',
		);
	}

}