import { EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { DbConceptName, DbsProgram, DbsSite, DbsSiteProgram, LanguageId, Person, ProgramTypeId } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { Icon } from "@me-shared-parts/UI-common";
import { buildColumn, GridColumnConfig, SiteCols } from "../columns";
import { EXPLORE_GRID_ACTION_KEY, GridSetup, GRID_EXPERIENCE, OPEN_GRID_ACTION_KEY } from "../interfaces";
import { GridExperience } from "./grid-experience";
import { getSiteCounts } from "./site-counts/get-site-counts";


//
// The types of programs that are included in the grid (for the list of programs, and the list of PMs)
//
const INCLUDED_PROGRAM_TYPES = [ProgramTypeId.Accelerator, ProgramTypeId.PitchContest];


/**
 * Standard experience for a grid of sites
 */
export class SiteGridExperience<ROW> extends GridExperience<ROW> {

	public static readonly experience: GRID_EXPERIENCE = 'SITE';

	constructor(
		private ds: DataService,
		private util: UtilityService,
		setup: GridSetup<ROW>,
		gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
		private router: Router,
	) {
		super(
			util, setup,
			[
				buildColumn(SiteCols.siteId, GridColumnConfig.siteId, { hidden: true }),
				buildColumn(SiteCols.regionId, GridColumnConfig.regionId, { hidden: true }),
			],
			[
				buildColumn(SiteCols.stateCode, GridColumnConfig.stateCode, { hidden: true }),
				buildColumn(SiteCols.region, GridColumnConfig.regionName, { hidden: true }),
				buildColumn(SiteCols.regionalDirector, GridColumnConfig.personFullName, { header: 'Regional Director', hidden: true }),
				buildColumn(SiteCols.code, GridColumnConfig.siteCode, { strikethrough: (row) => { return !row[SiteCols.isActive] }, }),
				buildColumn(SiteCols.name, GridColumnConfig.siteName, { strikethrough: (row) => { return !row[SiteCols.isActive] } }),
				buildColumn(SiteCols.hidden, GridColumnConfig.hiddenFlag),
				buildColumn(SiteCols.directorName, GridColumnConfig.personFullName, { header: 'Director' }),
				buildColumn(SiteCols.directorEmail, GridColumnConfig.email, { header: 'Director Email', hidden: true }),
				buildColumn(SiteCols.altDirectorName, GridColumnConfig.personFullName, { header: 'Alt Director', hidden: true }),
				buildColumn(SiteCols.altDirectorEmail, GridColumnConfig.email, { header: 'Alt Director Email', hidden: true }),
				buildColumn(SiteCols.programManagerNames, GridColumnConfig.personFullNameMany, { header: 'Program Managers', hidden: true }),
				buildColumn(SiteCols.programManagerEmails, GridColumnConfig.emailMany, { header: 'Program Manager Emails', hidden: true }),
				buildColumn(SiteCols.staff, GridColumnConfig.staffCount, { hidden: true }),
				buildColumn(SiteCols.englishPrograms, GridColumnConfig.programsList, { header: 'English Programs', hidden: true }),
				buildColumn(SiteCols.englishEmail, GridColumnConfig.email, { header: 'English Email', hidden: true }),
				buildColumn(SiteCols.spanishPrograms, GridColumnConfig.programsList, { header: 'Spanish Programs', hidden: true }),
				buildColumn(SiteCols.spanishEmail, GridColumnConfig.email, { header: 'Spanish Email', hidden: true }),
				buildColumn(SiteCols.accs, GridColumnConfig.accsCount),
				buildColumn(SiteCols.accAlumni, GridColumnConfig.accAlumniCount),
				buildColumn(SiteCols.pics, GridColumnConfig.picsCount),
				buildColumn(SiteCols.picParticipants, GridColumnConfig.picParticipantsCount),
				buildColumn(SiteCols.awardees, GridColumnConfig.awardeesCount),
				buildColumn(SiteCols.awards, GridColumnConfig.awardsDollars),
				buildColumn(SiteCols.accAwardees, GridColumnConfig.accAwardeesCount, { hidden: true }),
				buildColumn(SiteCols.accAwards, GridColumnConfig.accAwardsDollars, { hidden: true }),
				buildColumn(SiteCols.picAwardees, GridColumnConfig.picAwardeesCount, { hidden: true }),
				buildColumn(SiteCols.picAwards, GridColumnConfig.picAwardsDollars, { hidden: true }),
			],
			[
				buildColumn(SiteCols.launchDate, GridColumnConfig.siteLaunchDate, { hidden: true }),
			],
			[
				{ key: EXPLORE_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Explore ${setup.rowSingularName}` },
				{ key: OPEN_GRID_ACTION_KEY, icon: Icon.action_open, label: `Open ${setup.rowSingularName}` },
				// { key: NOTES_GRID_ACTION_KEY, icon: Icon.action_editNotes, label: `Edit Site Notes` },
			],
			gridAction,
			Icon.concept_site,
		);
	}


	public async applyBaseValues(rows: ROW[]) {

		//
		// Load all the related singleton data
		//
		const siteIds: number[] = rows.map(row => row['siteId']);
		const sites = await this.ds.admin.site.getManyPackagesAsArray(siteIds);
		const siteMap = await this.ds.admin.site.createPackagesMap(sites);

		const regionMap = await this.ds.admin.region.getAllPackagesAsMap();
		const sitePrograms = await this.ds.admin.siteProgram.getAllAsArray();
		const programMap = await this.ds.admin.program.getAllAsMap();

		//
		// Determine all the people related to the sites
		//
		const peopleIds = [];

		for (const site of sites) {
			if (site.directorId) peopleIds.push(site.directorId);
			if (site.altDirectorId) peopleIds.push(site.altDirectorId);
		}

		for (const siteProgram of sitePrograms) {
			if (siteProgram.managerId) peopleIds.push(siteProgram.managerId);
			if (siteProgram.altManagerId) peopleIds.push(siteProgram.altManagerId);
		}

		const personMap = await this.ds.admin.person.getManyPackagesAsMap(peopleIds);

		const countsBySiteId = await getSiteCounts(this.util, this.ds);

		for (const row of rows) {
			const siteId = row['siteId'];
			const site = siteMap[siteId];
			const region = regionMap[site.regionId];
			const counts = countsBySiteId[siteId];

			const { programManagerNames, programManagerEmails, englishPrograms, spanishPrograms } = this.getProgramDetailsForSite(site, sitePrograms, personMap, programMap);

			row[SiteCols.siteId] = site.siteId;
			row[SiteCols.regionId] = site.regionId;
			row[SiteCols.region] = region?.name;
			row[SiteCols.regionalDirector] = region?.directorName;

			row[SiteCols.stateCode] = site.stateCode;
			row[SiteCols.code] = site.code6;
			row[SiteCols.name] = site.name;
			row[SiteCols.hidden] = site.hidden;
			row[SiteCols.isActive] = site.isActive;

			row[SiteCols.directorName] = site.directorName;
			row[SiteCols.directorEmail] = site.directorId ? personMap[site.directorId]?.asSingleton._email ?? '' : '';
			row[SiteCols.altDirectorName] = site.altDirectorId ? personMap[site.altDirectorId]?.asSingleton._name ?? `Person #${site.altDirectorId}` : '';
			row[SiteCols.altDirectorEmail] = site.altDirectorId ? personMap[site.altDirectorId]?.asSingleton._email ?? '' : '';
			row[SiteCols.programManagerNames] = programManagerNames;
			row[SiteCols.programManagerEmails] = programManagerEmails;
			row[SiteCols.staff] = counts.staff;

			row[SiteCols.englishPrograms] = englishPrograms;
			row[SiteCols.englishEmail] = site.emailEn;

			row[SiteCols.spanishPrograms] = spanishPrograms;
			row[SiteCols.spanishEmail] = site.emailEs;


			row[SiteCols.accs] = counts.accs;
			row[SiteCols.accAlumni] = counts.accAlumni;
			row[SiteCols.pics] = counts.pics;
			row[SiteCols.picParticipants] = counts.picParticipants;

			row[SiteCols.awardees] = counts.accAwardees + counts.picAwardees;
			row[SiteCols.awards] = counts.accAwards + counts.picAwards;
			row[SiteCols.accAwardees] = counts.accAwardees;
			row[SiteCols.accAwards] = counts.accAwards;
			row[SiteCols.picAwardees] = counts.picAwardees;
			row[SiteCols.picAwards] = counts.picAwards;

			row[SiteCols.launchDate] = site.createdUTC + (12 * 60 * 60);	// Handle UTCs ahead of local US timezones;
		}
	}


	//
	// Determine the comma separated list of program names that a site supports for each of the languages
	//
	getProgramDetailsForSite(
		site: DbsSite,
		sitePrograms: readonly DbsSiteProgram[],
		personMap: Readonly<Record<number, Person>>,
		programMap: Readonly<Record<number, DbsProgram>>,
	) {

		const programManagerNames: string[] = [];
		const programManagerEmails: string[] = [];
		const englishPrograms: string[] = [];
		const spanishPrograms: string[] = [];

		const getProgramTypeName = (programTypeId: ProgramTypeId) => {
			if (programTypeId == ProgramTypeId.Accelerator) return 'Acc';
			if (programTypeId == ProgramTypeId.PitchContest) return 'Pitch';
			return '' + programTypeId;	// Shouldn't get here because INCLUDED_PROGRAM_TYPES only includes Acc and Pic

		}

		for (const siteProgram of sitePrograms.filter(siteProgram => siteProgram.siteId == site.siteId)) {

			const program = programMap[siteProgram.programId];
			if (!INCLUDED_PROGRAM_TYPES.includes(program.programTypeId)) continue;

			//Get all program managers and alt program managers for a site
			const programManagerName = siteProgram.managerId ? personMap[siteProgram.managerId]?.asSingleton._name : undefined;
			const programManagerEmail = siteProgram.managerId ? personMap[siteProgram.managerId]?.asSingleton._email : undefined;

			const altProgramManagerName = siteProgram.altManagerId ? personMap[siteProgram.altManagerId]?.asSingleton._name : undefined;
			const altProgramManagerEmail = siteProgram.altManagerId ? personMap[siteProgram.altManagerId]?.asSingleton._email : undefined;

			programManagerNames.push(programManagerName, altProgramManagerName);
			programManagerEmails.push(programManagerEmail, altProgramManagerEmail);


			if (program.languageId == LanguageId.English) englishPrograms.push(getProgramTypeName(program.programTypeId));
			if (program.languageId == LanguageId.Spanish) spanishPrograms.push(getProgramTypeName(program.programTypeId));
		}

		return {
			programManagerNames: this.util.array.cleanStrings(programManagerNames).join(', '),
			programManagerEmails: this.util.array.cleanStrings(programManagerEmails).join(', '),
			englishPrograms: this.util.array.cleanStrings(englishPrograms).join(', '),
			spanishPrograms: this.util.array.cleanStrings(spanishPrograms).join(', '),
		};
	}


	public sortRows(rows: ROW[]) {
		rows.sort((row1: ROW, row2: ROW) => {
			const val1 = row1[SiteCols.name];
			const val2 = row2[SiteCols.name];
			return val1 < val2 ? -1 : 1;
		});
	}


	public async explore(row: ROW) {
		await this.ds.explorer.explore(DbConceptName.Site, row[SiteCols.siteId]);
	}


	public async open(row: ROW) {
		await this.router.navigate([`access/admin/national/sites/${row[SiteCols.siteId]}`]);
	}


	public async editNotes(row: ROW) {
		// Notes dialog not implemented
	}
}