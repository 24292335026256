//
// These must align with the values in app-variables.scss
//

/** Same as $breakpoint-SM-min in app-variables.scss */ export const BREAKPOINT_SM = 600;
/** Same as $breakpoint-MD-min in app-variables.scss */ export const BREAKPOINT_MD = 1000;
/** Same as $breakpoint-LG-min in app-variables.scss */ export const BREAKPOINT_LG = 1200;
/** Same as $breakpoint-XL-min in app-variables.scss */ export const BREAKPOINT_XL = 1600;

/** Same as $menu-width-at-breakpoint-XS in app-variables.scss */	export const MENU_WIDTH_AT_BREAKPOINT_XS = 0;
/** Same as $menu-width-at-breakpoint-SM in app-variables.scss */	export const MENU_WIDTH_AT_BREAKPOINT_SM = 50;
/** Same as $menu-width-at-breakpoint-MD in app-variables.scss */	export const MENU_WIDTH_AT_BREAKPOINT_MD = 200;
/** Same as $menu-width-at-breakpoint-LG in app-variables.scss */	export const MENU_WIDTH_AT_BREAKPOINT_LG = 215;
/** Same as $menu-width-at-breakpoint-XL in app-variables.scss */	export const MENU_WIDTH_AT_BREAKPOINT_XL = 230;



export const NAVBAR_HEIGHT = 50;
export const BREADCRUMB_HEIGHT = 48;
export const NAVBAR_SPACING = 10;
export const NAVBAR_SPACING_XS = 0;
export const NAV_TABS_HEIGHT = 54;
export const TOOLBAR_HEIGHT = 50;
export const SPACING = 20;
export const SPACING_XS = 8;
export const PAGE_TABS_TAB_HEIGHT = 32;
export const PAGE_TABS_TOP_AND_BOTTOM_PADDING = 13;
export const PAGE_TABS_LEFT_AND_RIGHT_PADDING = 26;

/** CSS Grid gap between the expanded first tab and the side tabs */
export const TABS_COLUMN_GAP = 13;
export const TAB1_VIEW_DROPLIST_HEIGHT = 50; //Droplist = 30 and margin = 20
/** Same as $tabs-tab-padding in app-variables.scss */	export const TABS_TAB_PADDING = 12;



// /** Height of the blue bar area at the top of the page. It contains the hamburger menu and user menu. */
// NAVBAR_HEIGHT: number,

// /** Height of the bread crumbs text area */
// BREADCRUMB_HEIGHT: number,

// /** Padding between the navbar and breadcrumbs on screens larger than XS. */
// NAVBAR_SPACING: number,

// /** Padding between the navbar and breadcrumbs on XS screens. */
// NAVBAR_SPACING_XS: number,

// /** Height of the navigation tabs. It will be 0 if they are hidden. */
// NAV_TABS_HEIGHT: number,

// /** Height of a <me-toolbar> part including the bottom margin. */
// TOOLBAR_HEIGHT: number,

// /** Standard margins around the content area on screens larger than XS. */
// SPACING: number,

// /** Standard margins around the content area on XS screens. */
// SPACING_XS: number,

// /** Width of the left menu when expanded to show the text. */
// FULL_MENU_WIDTH: number,

// /** Width of the left menu when only showing icons. */
// MINI_MENU_WIDTH: number,

// /** Height of the actual tabs in the page tab tabstrip. */
// PAGE_TABS_TAB_HEIGHT: number,

// /** The sum of the top and bottom paddings for the page tab content */
// PAGE_TABS_TOP_AND_BOTTOM_PADDING: number,

// /** The sum of the left and right paddings for the page tab content */
// PAGE_TABS_LEFT_AND_RIGHT_PADDING: number,
 
