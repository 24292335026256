import { AccApplicationContext, AccAreaService, AccPreAcceleratorPageService, AccPreAcceleratorTabId, AccessAtStage } from '@ACC-area';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ApplicationCols, GridAction, GridColumnType, GridSetup } from '@me-grid';
import { AccStageId, Answer, ApplicationStatusId, DbaAccInterview, DbaAccReading } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogService } from '@me-services/ui/dialog';
import { GridPart, Icon } from '@me-shared-parts/UI-common';
import { combineLatest, map } from 'rxjs';
import { applyApplicationGridFilter } from '../../../acc-application-filter-helpers';
import { AccApplicationStatusName, SET_TO_ABANDONED_ACTION_KEY, SET_TO_ACCEPTED_ACTION_KEY, SET_TO_DEFERRED_ACTION_KEY, SET_TO_PENDING_ACTION_KEY, SET_TO_REJECTED_ACTION_KEY, SET_TO_WITHDRAWN_ACTION_KEY, canSetApplicationStatus, getApplicationStatusNameByStage } from '../../../acc-application-status-helpers';


const STAGE_ID = AccStageId.SelectingCohort;
const PENDING_APPLICATION_STATUS_ID = ApplicationStatusId.SelectPending;
const ABANDONED_APPLICATION_STATUS_ID = ApplicationStatusId.SelectAbandoned;
const WITHDRAWN_APPLICATION_STATUS_ID = ApplicationStatusId.SelectWithdrawn;
const REJECTED_APPLICATION_STATUS_ID = ApplicationStatusId.SelectRejected;
const DEFERRED_APPLICATION_STATUS_ID = ApplicationStatusId.SelectDeferred;
const ACCEPTED_APPLICATION_STATUS_ID = ApplicationStatusId.SelectSelected;

interface ApplicationRow {
	applicationId: number,
	applicationStatusId: ApplicationStatusId,
	countOfReadings: number,
	status: AccApplicationStatusName,
	rAssessments: string,
	rScore: number,
	iAssessments: string,
	iScore: number,
	updatedUTC: number,
}


@Component({
	selector: 'acc-selecting-applications-view-part',
	templateUrl: './acc-selecting-applications-view.part.html',
})
export class AccSelectingApplicationsViewPart extends DestroyablePart implements OnInit, AfterViewInit {

	@ViewChild(GridPart) meGrid: GridPart<ApplicationRow>;

	private accessAtStage: AccessAtStage;

	public gridSetup = this.setupGrid(false);


	/**
	 * Map applications into grid rows
	 */
	public rows$ = combineLatest([
		this.ds.admin.singletonsAsOfUTC$,
		this.accAreaService.applications.applications$,
		this.accAreaService.teams.teams$,
		this.accAreaService.reading.assessments$,
		this.accAreaService.interviewing.assessments$,
	])
		.pipe(map(([singletonsAsOfUTC, applications, accTeams, readerAssessments, interviewerAssessments]) => {
			if (!applications || !accTeams || !readerAssessments || !interviewerAssessments) return [];
			return this.buildRows(applications, readerAssessments, interviewerAssessments);

		}));


	constructor(
		public accAreaService: AccAreaService,
		private util: UtilityService,
		public ds: DataService,
		public pageService: AccPreAcceleratorPageService,
		private dialogService: DialogService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();

		const accessAtStage$ = this.accAreaService.getAccessAtStage(STAGE_ID);

		super.subscribe([accessAtStage$], async ([accessAtStage]) => {
			this.accessAtStage = accessAtStage;
			this.gridSetup = this.setupGrid(accessAtStage.canViewDemographics);
		});

	}


	ngAfterViewInit() {

		super.subscribe([this.pageService.applyApplicationsFilter$], async ([filter]) => {
			applyApplicationGridFilter(STAGE_ID, this.meGrid.grid, filter);
			this.pageService.selectTab(AccPreAcceleratorTabId.Manage)
		});

	}


	setupGrid(canViewDemographics: boolean): GridSetup<ApplicationRow> {

		const setup: GridSetup<ApplicationRow> = {
			experience: 'APPLICATION',
			multiselect: true,
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Application",
			rowPluralName: "Applications",
			rowKey: "applicationId",
			stateKey: "acc-reading-applications-view",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			showDemographics: canViewDemographics,
			columnsToAdd: [
				{ field: 'status', header: "Status", width: 100, type: GridColumnType.text, hidden: false },
				{ field: "rAssessments", header: "R Assessments", width: 115, type: GridColumnType.ratings, hidden: true, headerTooltip: `Reading Assessments` },
				{ field: "rScore", header: "R Score", width: 60, type: GridColumnType.number, hidden: true, headerTooltip: `Reading Score`, fractionDigits: 1 },
				{ field: "iAssessments", header: "I Assessments", width: 115, type: GridColumnType.ratings, headerTooltip: `Interview Assessments` },
				{ field: "iScore", header: "I Score", width: 60, type: GridColumnType.number, headerTooltip: `Interview Score`, fractionDigits: 1 },
			],
			actionEnabler: this.gridActionEnabler.bind(this),
			initialState: {
				sort: [{ field: ApplicationCols.companyName, dir: 'asc' }],
				filter: {
					logic: 'and',
					filters: [{ field: 'status', operator: 'neq', value: 'Beyond' }],
				}
			},
		};

		setup.actions = [
			{ key: SET_TO_PENDING_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Pending' },
			{ key: SET_TO_ABANDONED_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Abandoned' },
			{ key: SET_TO_WITHDRAWN_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Withdrawn' },
			{ key: SET_TO_REJECTED_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Rejected' },
			{ key: SET_TO_DEFERRED_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Deferred' },
			{ key: SET_TO_ACCEPTED_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Selected' },
		];

		return setup;
	}


	buildRows(
		applications: readonly AccApplicationContext[],
		readerAssessments: { reading: DbaAccReading, answers: readonly Answer[], }[],
		interviewerAssessments: { interview: DbaAccInterview, answers: readonly Answer[], }[],
	): ApplicationRow[] {

		const map = applications
			.filter(app => app.application.applicationStatusId >= PENDING_APPLICATION_STATUS_ID)
			.map(app => {

				const readAssessments = readerAssessments.filter(assessment => assessment.reading.applicationId == app.application.applicationId)
					.map(assessment => ({ rating: assessment.reading.rating, answers: assessment.answers }));
				const readRatings = this.util.ratings.getRating4AndRatings5(readAssessments);



				const interviewAssessments = interviewerAssessments.filter(assessment => assessment.interview.applicationId == app.application.applicationId)
					.map(assessment => ({ rating: assessment.interview.rating, answers: assessment.answers }));
				const interviewRatings = this.util.ratings.getRating4AndRatings5(interviewAssessments);


				return {
					applicationId: app.application.applicationId,
					applicationStatusId: app.application.applicationStatusId,
					countOfReadings: readerAssessments.filter(ra => ra.reading.applicationId == app.application.applicationId).length,
					status: getApplicationStatusNameByStage(STAGE_ID, app.application.applicationStatusId),
					demographics: app.demographics,
					rAssessments: this.util.ratings.formatForGridCell(readRatings.ratings4),
					rScore: this.util.ratings.calculateScore(readRatings.ratings5, readRatings.ratings4),
					iAssessments: this.util.ratings.formatForGridCell(interviewRatings.ratings4),
					iScore: this.util.ratings.calculateScore(interviewRatings.ratings5, interviewRatings.ratings4),
					updatedUTC: app.application.updatedUTC,
				};
			});

		return map;

	}


	gridActionEnabler(action: GridAction, rows: ApplicationRow[]): boolean {

		if (rows.length == 0) return false;

		if (this.accessAtStage == undefined) {
			console.error(`this.accessAtStage is undefined`);
			return false;
		}

		const canEdit = this.accessAtStage.access == 'Write';
		const currentAccStageId = this.accessAtStage.currentStageId;

		if (
			action.key == SET_TO_PENDING_ACTION_KEY ||
			action.key == SET_TO_ABANDONED_ACTION_KEY ||
			action.key == SET_TO_WITHDRAWN_ACTION_KEY ||
			action.key == SET_TO_REJECTED_ACTION_KEY ||
			action.key == SET_TO_DEFERRED_ACTION_KEY ||
			action.key == SET_TO_ACCEPTED_ACTION_KEY
		) {

			return canSetApplicationStatus(this.accessAtStage.canUpdateApplicationStatuses, currentAccStageId, STAGE_ID, action.key, rows.map(row => row.applicationStatusId));
		}


		if (rows.length > 1) return false;	// Multi is only supported with the application status setters

		return true;
	}


	async gridActionHandler(action: { actionKey: string, rows: ApplicationRow[] }) {

		const { actionKey, rows } = action;

		if (!rows.length) return;

		const applicationIds = rows.map(row => row.applicationId);


		//
		// Perform the bulk change
		//
		if ([
			SET_TO_PENDING_ACTION_KEY,
			SET_TO_ABANDONED_ACTION_KEY,
			SET_TO_WITHDRAWN_ACTION_KEY,
			SET_TO_REJECTED_ACTION_KEY,
			SET_TO_DEFERRED_ACTION_KEY,
			SET_TO_ACCEPTED_ACTION_KEY,
		].includes(actionKey)) {

			let applicationStatusId: ApplicationStatusId = REJECTED_APPLICATION_STATUS_ID;
			let status = 'Rejected';

			if (actionKey == SET_TO_PENDING_ACTION_KEY) {
				applicationStatusId = PENDING_APPLICATION_STATUS_ID;
				status = 'Pending';
			}
			if (actionKey == SET_TO_ABANDONED_ACTION_KEY) {
				applicationStatusId = ABANDONED_APPLICATION_STATUS_ID;
				status = 'Abandoned';
			}
			if (actionKey == SET_TO_WITHDRAWN_ACTION_KEY) {
				applicationStatusId = WITHDRAWN_APPLICATION_STATUS_ID;
				status = 'Withdrawn';
			}
			if (actionKey == SET_TO_REJECTED_ACTION_KEY) {
				applicationStatusId = REJECTED_APPLICATION_STATUS_ID;
				status = 'Rejected';
			}
			if (actionKey == SET_TO_DEFERRED_ACTION_KEY) {
				applicationStatusId = DEFERRED_APPLICATION_STATUS_ID;
				status = 'Deferred';
			}
			if (actionKey == SET_TO_ACCEPTED_ACTION_KEY) {
				applicationStatusId = ACCEPTED_APPLICATION_STATUS_ID;
				status = 'Selected';
			}

			const message = `Would you like to change the status of the following applications to '${status}'?`;

			const applicationsById = await this.ds.admin.application.getManyAsMap(rows.map(row => row.applicationId));
			const bullets = rows.map(row => applicationsById[row.applicationId]?.companyName ?? `Application #'${row.applicationId}'`).sort();

			const yes = await this.dialogService.confirm(message, 400, 150, undefined, undefined, bullets);
			if (yes) await this.accAreaService.applications.actions.setBulkStatus({ applicationIds, applicationStatusId });
		}

	}

}
