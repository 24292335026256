<div class='acc-quarterlies-counts-part'>
	<ng-container *ngIf='countsData'>

		<me-h3>Award Counts</me-h3>
		<table class='pro-table pro-table-without-border pro-table-with-thin-rows'>
			<tr>
				<th>Type</th>
				<th>Total</th>
				<th>Contact</th>
			</tr>
			<tr>
				<td>Showcase Awards</td>
				<td>{{countsData.q1Awards}}</td>
				<td>
					<button *ngIf="countsData.q1AwardeePersonIds.length" class="text-btn text-btn-not-inline"
						(click)="openContactor(countsData.q1AwardeePersonIds)">
						<span class='contact-button-content'>
							<i class='far fa-envelope'></i> <i class='far fa-comment-lines'></i>
						</span>
					</button>
				</td>
			</tr>
			<tr>
				<td>Q2 Awards</td>
				<td>{{countsData.q2Awards}}</td>
				<td>
					<button *ngIf="countsData.q2AwardeePersonIds.length" class="text-btn text-btn-not-inline"
						(click)="openContactor(countsData.q2AwardeePersonIds)">
						<span class='contact-button-content'>
							<i class='far fa-envelope'></i> <i class='far fa-comment-lines'></i>
						</span>
					</button>
				</td>
			</tr>
			<tr>
				<td>Q3 Awards</td>
				<td>{{countsData.q3Awards}}</td>
				<td>
					<button *ngIf="countsData.q3AwardeePersonIds.length" class="text-btn text-btn-not-inline"
						(click)="openContactor(countsData.q3AwardeePersonIds)">
						<span class='contact-button-content'>
							<i class='far fa-envelope'></i> <i class='far fa-comment-lines'></i>
						</span>
					</button>
				</td>
			</tr>
			<tr>
				<td>Q4 Awards</td>
				<td>{{countsData.q4Awards}}</td>
				<td>
					<button *ngIf="countsData.q4AwardeePersonIds.length" class="text-btn text-btn-not-inline"
						(click)="openContactor(countsData.q4AwardeePersonIds)">
						<span class='contact-button-content'>
							<i class='far fa-envelope'></i> <i class='far fa-comment-lines'></i>
						</span>
					</button>
				</td>
			</tr>
			<tr>
				<td>Total Cash Awarded by EforAll</td>
				<td>${{countsData.totalEforAllCash}}</td>
				<td></td>
			</tr>
			<tr>
				<td>Total Value Awarded</td>
				<td>${{countsData.totalAwardedValue}}</td>
				<td></td>
			</tr>
		</table>
	</ng-container>
</div>