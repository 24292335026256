import { NoteCategoryId } from '@me-interfaces';
import { DdNoteCategoryRow } from './dd-note-category-row';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';

/**
 * @deprecated Use the DataService instead
 */
export class DdNoteCategory extends DdObject<DdNoteCategoryRow> implements DdNoteCategoryRow {

	constructor(dd: OldDomainDataService, data: DdNoteCategoryRow) {
		super(data);
	}

	public get noteCategoryId(): NoteCategoryId {
		return this._data.noteCategoryId;
	}

	public get label(): string {
		return this._data.label;
	}
}