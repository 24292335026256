export const enum PhoneTypeId {
	Landline = 1,
	Mobile = 2,
	Mobile_DoNotText = 3,
	International = 4,
}


export const enum RePhoneType {
	Landline = 'Landline',
	Mobile = 'Mobile',
	Mobile_DoNotText = 'Mobile - DO NOT TEXT',
	International = "International",
}

export const enum RePhoneClass {
	Personal = 'Personal',
	Business = 'Business',
	Office = 'Office',
}

export const DEFAULT_PERSONAL_PHONE_CLASS_AND_TYPE = `${RePhoneClass.Personal} - ${RePhoneType.Mobile}`;
export const DEFAULT_BUSINESS_PHONE_CLASS_AND_TYPE = `${RePhoneClass.Business} - ${RePhoneType.Landline}`;
export const DEFAULT_OFFICE_PHONE_CLASS_AND_TYPE = `${RePhoneClass.Office} - ${RePhoneType.Landline}`;