<div class="application-participant-dialog">

	<me-dialog-frame [header]='inlineLabels["Participant"]' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon'
		alertType='editor' (actionClick)='actionClick($event)'>

		<div class="me-field">
			<kendo-label [text]='headerText'></kendo-label>

			<div class="application-participant-dialog-content">

				<kendo-label [text]='inlineLabels["First Name"]'>
					<input firstNameField kendoTextBox [value]='values.firstName.value'
						(input)='onInput("firstName", $event)' (blur)='onBlur("firstName")'
						[placeholder]='getPlaceholder("firstName")' [readonly]='values.firstName.readonly'
						[attr.maxlength]='values.firstName.max' [style.width.%]='100'
						[class.has-error]='!values.firstName.valid' />
				</kendo-label>

				<kendo-label [text]='inlineLabels["Last Name"]'>
					<input kendoTextBox [value]='values.lastName.value' (input)='onInput("lastName", $event)'
						(blur)='onBlur("lastName")' [placeholder]='getPlaceholder("lastName")'
						[readonly]='values.lastName.readonly' [attr.maxlength]='values.lastName.max'
						[style.width.%]='100' [class.has-error]='!values.lastName.valid' />
				</kendo-label>

				<kendo-label [text]='inlineLabels["Title"]'>
					<input kendoTextBox [value]='values.title.value' (input)='onInput("title", $event)'
						(blur)='onBlur("title")' [placeholder]='getPlaceholder("title")'
						[readonly]='values.title.readonly' [attr.maxlength]='values.title.max' [style.width.%]='100'
						[class.has-error]='!values.title.valid' />
				</kendo-label>

				<kendo-label [text]='inlineLabels["Email"]'>
					<input kendoTextBox [value]='values.email.value' (input)='onInput("email", $event)'
						(blur)='onBlur("email")' [placeholder]='getPlaceholder("email")'
						[readonly]='values.email.readonly' [attr.maxlength]='values.email.max' [style.width.%]='100'
						[class.has-error]='!values.email.valid' />
				</kendo-label>

			</div>
		</div>

	</me-dialog-frame>
</div>