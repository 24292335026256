import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { Venue } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { getIconContext, Icon } from '@me-shared-parts/UI-common';
import { getIconBarSelections, IconBarIcon } from '@me-shared-parts/UI-common/icon-bar';
import { IconBarPart, IconBarSelections } from '@me-shared-parts/UI-common/icon-bar/SHR-CMN_icon-bar.part';
import { LinkOrExplorable } from '../../link-or-explorerable';
import { getButtons } from './get-buttons';
import { views, VIEW_DETAILS, VIEW_EVENTS } from './views';

const STORAGE_KEY = 'VenueExplorerIconSelection';

@Component({
	selector: 'venue-explorer',
	templateUrl: './venue-explorer.part.html',
	styleUrls: ['./venue-explorer.part.scss'],
})
export class VenueExplorerPart implements OnChanges {

	@Input() public venue: Venue;
	@ViewChild('iconBar') iconBar: IconBarPart;

	public views = views;
	public icons = {
		venue: getIconContext(Icon.concept_venue)
	};


	public selectedViews: IconBarSelections = undefined;
	public buttons: LinkOrExplorable[] = [];


	constructor(
		public util: UtilityService,
	) { }


	async ngOnChanges() {
		if (!this.venue) throw new Error(`Missing required attribute: venue`);
		this.icons.venue.text = this.venue.name;
		await this.createContent();
		this.views = this.updateIconBar();
	}

	/**
	 * CreateContent is called once when a new accelerator is passed in.  All content is created but
	 * may be shown or hidden using css instead of rebuilding everything again.
	 */
	private async createContent() {

		this.buttons = getButtons(this.venue);

	}

	private updateIconBar(): IconBarIcon[] {

		//
		// Get selections from last time this explorer was accessed. If there
		// isn't on,then build the selections from the initial views in views.ts.
		//
		const selectedViewsStr = sessionStorage.getItem(STORAGE_KEY);
		let selectedViews: IconBarSelections;

		if (selectedViewsStr) {
			this.selectedViews = selectedViews = JSON.parse(selectedViewsStr);
		}
		else {
			this.selectedViews = selectedViews = getIconBarSelections(views);
			sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.selectedViews));
		}

		//
		// Create a new array of icon views using the initial set but overriding
		// whether disabled or not and the tooltip text based on the created content.
		//
		return views.map(view => {
			if (view.key == VIEW_DETAILS) {
				view.disabled = false; // always has a value
				view.selected = this.selectedViews.map[VIEW_DETAILS];
				view.tooltip = `Venue Details`;
			}
			else if (view.key == VIEW_EVENTS) {
				view.disabled = false;
				view.selected = this.selectedViews.map[VIEW_EVENTS];
				view.tooltip = 'Events';
			}
			return view;
		});


	}


	public async setViews(selectedViews: IconBarSelections) {
		if (!selectedViews) return;
		this.selectedViews = selectedViews;
		sessionStorage.setItem(STORAGE_KEY, JSON.stringify(selectedViews));

		for (const view of this.views) {
			view.selected = !!selectedViews.map[view.key];
		}
	}


	public getDisplay(key: string) {
		const view = this.views.find(view => view.key == key);
		if (view == undefined) return 'none';
		return !!view.disabled || !view.selected ? 'none' : 'block';
	}


	public tabClose(header: string) {
		if (header.endsWith('Details')) this.unselect(VIEW_DETAILS);
		if (header.endsWith('Event') || header.endsWith('Events')) this.unselect(VIEW_EVENTS);
	}


	private unselect(key: string) {

		this.iconBar.unselect(key);

		const view = this.views.find(view => view.key == key);
		if (view) view.selected = false;
	}

}