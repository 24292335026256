import { environment } from '@me-environment';
import { UserAgent } from '@me-interfaces';
import * as moment from 'moment';
import * as Bowser from "bowser";

export function getUserAgent(): UserAgent {

	let clientOffsetUTC = 0;	//int
	let userAgent = '';			//varchar(1000)
	let browser = '';     		//varchar(20)
	let browserVersion = ''; 	//varchar(20)
	let engine = '';			//varchar(6)
	let device = '';			//varchar(7)
	let os = '';				//varchar(10)
	let osVersion = '';			//varchar(20)

	try {
		// Get and parse the user agent
		userAgent = navigator.userAgent.trim().substr(0, 1000).trim();
		const bowser = Bowser.getParser(window.navigator.userAgent);

		clientOffsetUTC = moment().utcOffset();
		browser = (bowser.getBrowserName() || '').substr(0, 20).trim().toLowerCase();
		browserVersion = (bowser.getBrowserVersion() || '').substr(0, 20).trim();
		engine = (bowser.getEngine()?.name || '').substr(0, 6).trim();
		device = (bowser.getPlatformType() || '').substr(0, 7).trim();
		os = (bowser.getOSName() || '').substr(0, 10).trim();
		osVersion = (bowser.getOSVersion() || '').substr(0, 20).trim();
	}
	catch (e) {
		console.log(`Error parsing User Agent`, e);
	}

	return {
		appCode: <'ME' | 'YO'>environment.appCode,
		userAgent,
		browser,
		browserVersion,
		engine,
		device,
		os,
		osVersion,
		clientOffsetUTC,
	};

}