import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { BlurService } from '@me-services/ui/blur';
import { Subscription } from 'rxjs';

@Component({
	selector: 'me-blur',
	templateUrl: './SHR-CMN_blur.part.html',
	styleUrls: ['SHR-CMN_blur.part.scss'],
})
export class BlurPart implements OnInit, OnDestroy {

	@HostBinding('class.blurred') blurred = false;
	subscription: Subscription;

	constructor(private blurService: BlurService) { }

	ngOnInit() {
		this.subscription = this.blurService.enabled$.subscribe(value => {
			if (this.blurred != value) this.blurred = value;
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

}
