import { Component, OnChanges, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'rating-five',
	template: `<div [style.background]='bg'></div>`,
	styles: [`
		:host, div {
			width: 72px;
			height: 18px;
			background: url("/assets/img/rating-five.png") 0 0px no-repeat;
		} 
	`],
})
export class RatingFivePart implements OnChanges {

	@Input() rating = 0;
	bg;

	constructor(private ds: DomSanitizer) { }

	ngOnChanges() {
		this.rating = Math.max(0, Math.min(+this.rating, 5));
		this.bg = this.ds.bypassSecurityTrustStyle('url("/assets/img/rating-five.png") 0 -' + (this.rating * 18) + 'px no-repeat');
	}
}