<div class='site-overview-view-part' *ngIf="viewDroplistItems.length">

	<ng-container *ngIf='selectedViewItem$ | async; let selectedViewItem'>

		<me-droplist class='views-droplist' [items]='viewDroplistItems' [selectedItem]='selectedViewItem'
			(selectionChange)='onViewDroplistChange($event)' [filterable]='false' [navigation]='true'
			sortBy='customSort' style='display:inline-block'
			[groupSortValues]='{"Site":1, "Reports":2, "Volunteers":3, "Configure":4}'>
		</me-droplist>

		<shared-dashboard-views-part [layout$]="pageService.layout$"></shared-dashboard-views-part>


		<site-events-view-part *ngIf="showView('Events', selectedViewItem)">
		</site-events-view-part>

		<site-entrepreneurs-view-part *ngIf="showView('Entrepreneurs', selectedViewItem)">
		</site-entrepreneurs-view-part>

		<site-accelerators-view-part *ngIf="showView('Accelerators', selectedViewItem)">
		</site-accelerators-view-part>

		<site-applications-view-part *ngIf="showView('Applications', selectedViewItem)">
		</site-applications-view-part>

		<site-pitch-contests-view-part *ngIf="showView('PitchContests', selectedViewItem)">
		</site-pitch-contests-view-part>

		<site-config-part *ngIf="showView('ConfigDirectors', selectedViewItem)">
		</site-config-part>

		<site-config-interns-part *ngIf="showView('ConfigInterns', selectedViewItem)">
		</site-config-interns-part>

		<site-config-programs-part *ngIf="showView('ConfigPrograms', selectedViewItem)">
		</site-config-programs-part>

		<site-council-members-view-part *ngIf="showView('ConfigCouncil', selectedViewItem)"></site-council-members-view-part>
		
		<site-zip-codes-view-part *ngIf="showView('ConfigZips', selectedViewItem)"></site-zip-codes-view-part>


		<site-businesses-view-part *ngIf="showView('Businesses', selectedViewItem)">
		</site-businesses-view-part>

		<site-awards-view-part *ngIf="showView('Awards', selectedViewItem)">
		</site-awards-view-part>

		<hear-report-view-part *ngIf="showView('HearReport', selectedViewItem)">
		</hear-report-view-part>

		<site-volunteer-specialist-view-part *ngIf="showView('VolunteerSpecialists', selectedViewItem)">
		</site-volunteer-specialist-view-part>

		<site-volunteer-reader-view-part *ngIf="showView('VolunteerReaders', selectedViewItem)">
		</site-volunteer-reader-view-part>

		<site-volunteer-interviewer-view-part *ngIf="showView('VolunteerInterviewers', selectedViewItem)">
		</site-volunteer-interviewer-view-part>

		<site-volunteer-mentor-view-part *ngIf="showView('VolunteerMentors', selectedViewItem)">
		</site-volunteer-mentor-view-part>

		<site-volunteer-judge-view-part *ngIf="showView('VolunteerJudges', selectedViewItem)">
		</site-volunteer-judge-view-part>

		<site-volunteer-coach-view-part *ngIf="showView('VolunteerCoaches', selectedViewItem)">
		</site-volunteer-coach-view-part>

		<site-volunteer-eventhelper-view-part *ngIf="showView('VolunteerEventhelpers', selectedViewItem)">
		</site-volunteer-eventhelper-view-part>

		<site-volunteer-applications-view-part *ngIf="showView('VolunteerApplications', selectedViewItem)">
		</site-volunteer-applications-view-part>

	</ng-container>

</div>