import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ButtonCard } from '@me-interfaces/ui';
import { OldSiteService } from '@me-services/old-services-and-wrappers/site';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { EntryService } from '@me-services/core/entry';
import { UtilityService } from '@me-services/core/utility';

@Component({
	selector: 'nav-button-cards',
	template: `<button-cards [cards]='cards'></button-cards>`,
})
export class NavButtonCardsPage implements OnInit {

	cards: ButtonCard[] = [];

	constructor(
		private route: ActivatedRoute,
		private util: UtilityService,
		private userService: OldUserService,
		private entry: EntryService,
		private siteService: OldSiteService,
	) { }

	async ngOnInit() {
		if (this.route.snapshot.parent.routeConfig.children) {

			const user = await this.userService.getUserAsWrappedUser();
			const site = this.siteService.getDdSiteFromRoute(this.route.snapshot);

			this.route.snapshot.parent.routeConfig.children.forEach(async c => {
				if (c.component !== NavButtonCardsPage && !c.redirectTo) {

					const data = c.data || {};

					if (this.entry.showTo(user, site, data.showTo)) {
						this.cards.push({
							path: c.path || '',
							label: data.breadcrumb || '???',
							desc: data.desc || 'No Description',
							style: data.style,
						});
					}
				}
			});
		}
	}
}