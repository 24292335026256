<readonly-access *ngIf='readonly'></readonly-access>

<div class='closed-volunteer-application' *ngIf='vm'>
	<div class="card-columns">
		<fieldset>
			<legend>Applicant</legend>
			<div class='person-detail-rows'>
				<button class='btn' (click)='explorePerson("applicant")'>
					<me-icon [icon]='vm.icons.person'></me-icon>
				</button>

				<table class="table table-sm">
					<tbody>
						<tr *ngIf='vm.title'>
							<td>Title</td>
							<td>{{vm.title}}</td>
						</tr>
						<tr *ngIf='vm.company'>
							<td>Company</td>
							<td>{{vm.company}}</td>
						</tr>
						<tr>
							<td>Referred by</td>
							<td>{{vm.foundOutHow}}</td>
						</tr>
						<tr>
							<td>Applied on</td>
							<td>{{vm.createdDate}} ({{vm.daysSince}})
							</td>
						</tr>
						<tr>
							<td>Applied for</td>
							<td>
								<ul>
									<li *ngFor='let role of vm.appliedRoles'>{{role}}</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</fieldset>

		<fieldset>
			<legend>Bio</legend>
			<div *ngIf='vm.bioEN'><strong>English:</strong> {{vm.bioEN}}</div>
			<div *ngIf='vm.bioES'><strong>Spanish:</strong> {{vm.bioES}}</div>
			<div *ngIf='!vm.bioES && !vm.bioEN'><i>No bio provided.</i></div>
		</fieldset>

		<fieldset>
			<legend>Closed By</legend>
			<div class='person-detail-rows'>
				<button class='btn' (click)='explorePerson("closedBy")'>
					<me-icon [icon]='vm.icons.closedByPerson'></me-icon>
				</button>

				<table class="table table-sm">
					<tbody>
						<tr>
							<td>Closed on {{vm.closedDate}}</td>
						</tr>
						<tr>
							<td>
								<div class='tags'>
									<p>Tags assigned when this application was closed:</p>
									<i *ngIf='!vm.tags.length'>Applicant has no tags for this community</i>
									<me-tag *ngFor='let tag of vm.approvedRoles' [tag]='tag' [showPrefix]='false'
										[clickable]='false'>
									</me-tag>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class='tags'>
									<p>Current community tags:</p>
									<i *ngIf='!vm.tags.length'>Applicant has no tags for this community</i>
									<me-tag *ngFor='let tag of vm.tags' [tag]='getTag(tag.name)' [showPrefix]='false'
										[clickable]='false'></me-tag>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</fieldset>

	</div>
	<div class='volunteer-action-section'>
		<spinner-button (safeClick)='closeDialog.emit()'>Cancel</spinner-button>
		<spinner-button (safeClick)='reopen()' [disabled]='readonly'>Reopen</spinner-button>
	</div>
</div>