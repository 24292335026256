import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId, Application, DbdAccStage } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { DroplistItem, getIconContextForAccStage } from '@me-shared-parts/UI-common';


@Component({
	selector: 'acc-stage-editor-dialog',
	templateUrl: './stage-editor.dialog.html',
	styleUrls: ['./stage-editor.dialog.scss']
})
export class AccStageEditorDialog extends DestroyablePart implements OnInit {

	selectedAccStageId: AccStageId;

	//
	// The action buttons
	//
	saveAction: DialogAction<AccStageId> = {
		id: 'save',
		enabled: true,
		visible: false,
		label: ':Save',
		linkType: 'callback',
		callback: async () => (this.selectedAccStageId),
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: ':Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction<AccStageId | void>[] = [this.saveAction, this.cancelAction];
	icon = 'fa-pen';
	headerText = 'Edit Accelerator Stage';
	accStageItems: DroplistItem[] = [];
	selectedAccStageItem: DroplistItem;
	readonly: boolean;
	orgAccStage: DbdAccStage;
	applicationsToBeAdjusted: number;
	applications: Application[];

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ readonly: boolean, accStageId: AccStageId, applications: Application[] }>,
		public dialogRef: MatDialogRef<AccStageEditorDialog>,
		private ds: DataService,
	) {
		super();

		this.readonly = this.dialog.data.readonly;
		this.applications = this.dialog.data.applications;
	}


	ngOnInit() {
		super.initDestroyable();

		const accStages = this.ds.domain.accStage.getAll();
		this.orgAccStage = accStages.find(accStage => accStage.accStageId == this.dialog.data.accStageId);
		accStages.forEach(a => {
			this.accStageItems.push({ uniqueId: a.accStageId, iconContext: getIconContextForAccStage(a.accStageId), customSort: a.accStageId })
		});

		this.selectedAccStageItem = this.accStageItems.find(accStageItem => accStageItem.uniqueId == this.orgAccStage.accStageId);



		this.saveAction.visible = true;
		this.checkIfCanSave();
	}

	/**
	 * This function is called as the user selects accStage
	 */
	onDroplistChange(event: DroplistItem) {
		const id = event?.uniqueId;
		this.selectedAccStageId = id;
		this.checkIfCanSave();
	}

	getApplicationAdjustmentCount(accStageId: AccStageId) {
		return;
	}



	/**
	 * Look at selectedAccStageId and determine if the save/update button should be enabled
	 */
	checkIfCanSave() {
		this.saveAction.enabled = !this.readonly && this.selectedAccStageId && this.selectedAccStageId != this.orgAccStage.accStageId;
	}
}