import { AccOverviewPageService, AccOverviewPageViewId as ViewId } from '@ACC-area';
import { Component, Input, OnInit } from '@angular/core';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';
import { map } from 'rxjs';


@Component({
	selector: 'overview-views-part',
	templateUrl: './overview-views.part.html',
})
export class AcceleratorOverviewViewsPart implements OnInit {
	@Input() readonly: boolean;


	viewDroplistItems: DroplistItem<never, ViewId>[] = [
		{ uniqueId: ViewId.Details, groupName: 'Accelerator', iconContext: getIconContext(Icon.view_overview, undefined, 'never', 'Dashboard'), customSort: 'Accelerator-1' },
		{ uniqueId: ViewId.AllApplications, groupName: 'Accelerator', iconContext: getIconContext(Icon.view_applications, undefined, undefined, 'All Applications'), customSort: 'Accelerator-2' },
		{ uniqueId: ViewId.AllEvents, groupName: 'Accelerator', iconContext: getIconContext(Icon.view_events, undefined, undefined, 'All Events'), customSort: 'Accelerator-3' },
		{ uniqueId: ViewId.AllAwards, groupName: 'Accelerator', iconContext: getIconContext(Icon.concept_award, undefined, undefined, 'All Awards'), customSort: 'Accelerator-4' },
		{ uniqueId: ViewId.AllMissingSignatures, groupName: 'Accelerator', iconContext: getIconContext(Icon.concept_agreementSignature, undefined, undefined, 'All Missing Signatures'), customSort: 'Accelerator-5' },
	];

	
	selectedViewItem$ = this.pageService.selectedView$.pipe(
		map(viewId => this.viewDroplistItems.find(item => item.uniqueId == viewId)),
	);


	constructor(
		private pageService: AccOverviewPageService,
	) { }


	ngOnInit() {
		this.pageService.initializeUrl();
	}


	onViewDroplistChange(selectedView: DroplistItem) {
		this.pageService.selectView(selectedView.uniqueId);
	}


	showView(view: string, viewItem: DroplistItem<never, ViewId>) {

		if (!viewItem?.uniqueId) return false;

		const viewId = viewItem.uniqueId;

		if (viewId == ViewId.Details && view == 'Details') return true;
		if (viewId == ViewId.AllApplications && view == 'AllApplications') return true;
		if (viewId == ViewId.AllEvents && view == 'AllEvents') return true;
		if (viewId == ViewId.AllAwards && view == 'AllAwards') return true;
		if (viewId == ViewId.AllMissingSignatures && view == 'AllMissingSignatures') return true;

		return false;
	}
}