import { AccAreaData, AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId, Accelerator } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogService } from '@me-services/ui/dialog';
import { Icon, IconContext, getIconContext } from '@me-shared-parts/UI-common';
import { UserAreaService } from '@me-user-area';
import { Observable, switchMap } from 'rxjs';
import { getAccStageErrors } from '../../../../../get-acc-stage-errors';


const stages = [
	{ accStageId: AccStageId.Setup, name: 'Setup' },
	{ accStageId: AccStageId.Accepting, name: 'Accepting Applications' },
	{ accStageId: AccStageId.Reading, name: 'Reading Applications' },
	{ accStageId: AccStageId.Interviewing, name: 'Interviewing Applicants' },
	{ accStageId: AccStageId.SelectingCohort, name: 'Selecting Cohort' },
	{ accStageId: AccStageId.MatchingMentors, name: 'Matching Mentors' },
	{ accStageId: AccStageId.Curriculum, name: 'Curriculum' },
	{ accStageId: AccStageId.SelectingWinners, name: 'Selecting Winners' },
	{ accStageId: AccStageId.Quarterlies, name: 'Quarterlies' },
	{ accStageId: AccStageId.Complete, name: 'Complete' },
];


interface StageIconDisplay {
	cssClass: 'acc-stage-complete' | 'acc-stage-current' | 'acc-stage-future',
	icon: IconContext,
}


@Component({
	selector: 'acc-stage-advancer-part',
	templateUrl: './acc-stage-advancer.part.html',
	styleUrls: ['./acc-stage-advancer.part.scss'],
})
export class AccStageAdvancerPart extends DestroyablePart implements OnInit {

	isTechAdmin = false;

	icons$: Observable<StageIconDisplay[]>;

	previousIcon = getIconContext(Icon.action_up, 'fa-solid', 'always', 'Previous');
	advanceIcon = getIconContext(Icon.action_down, 'fa-solid', 'never', 'Advance');

	canPrevious = false;
	canAdvance = false;

	acc: Accelerator;



	constructor(
		private userAreaService: UserAreaService,
		public util: UtilityService,
		public ds: DataService,
		private dialogService: DialogService,
		public accAreaService: AccAreaService
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();

		super.subscribe([this.userAreaService.user$], async ([user]) => {
			this.isTechAdmin = user.isTechAdmin;
		});

		this.icons$ = this.accAreaService.data$.pipe(switchMap(data => this.buildIcons(data)));
	}


	async buildIcons(accArea: AccAreaData): Promise<StageIconDisplay[]> {

		if (!accArea) return undefined;

		const acc = accArea.acc;
		const accStageId = acc.accStageId;
		this.acc = acc;

		this.canPrevious = accStageId > stages[0].accStageId;
		this.canAdvance = accStageId < stages[stages.length - 1].accStageId;

		return stages.map(stage => {

			if (accStageId > stage.accStageId || accStageId == AccStageId.Complete) {
				return {
					cssClass: 'acc-stage-complete',
					icon: getIconContext(Icon.status_check, 'fa-regular', 'never', stage.name)
				};
			}
			else if (accStageId == stage.accStageId) {
				return {
					cssClass: 'acc-stage-current',
					icon: getIconContext(Icon.status_selected, 'fa-solid', 'never', stage.name)
				};
			}
			else {
				return {
					cssClass: 'acc-stage-future',
					icon: getIconContext(Icon.empty, 'fa-regular', 'never', stage.name)
				};
			}
		});
	}


	async previous() {
		this.setStage(-1);
	}


	async advance() {
		this.setStage(1);
	}


	private async setStage(direction: -1 | 1) {

		const acc = this.acc;

		const index = stages.findIndex(s => s.accStageId == acc.accStageId);
		const changeTo = stages[index + direction];
		if (!changeTo) return;

		//
		// Get related data for this ONE accelerator
		//
		const applications = (await this.ds.admin.application.getByAccIds([acc.accId]))[acc.accId];
		const teams = (await this.ds.admin.accTeam.getByAccIds([acc.accId]))[acc.accId];
		const events = (await this.ds.admin.event.getByAccIds([acc.accId]))[acc.accId];
		const awards = (await this.ds.admin.award.getByAccIds([acc.accId]))[acc.accId];
		// const teamMembers = this.util.array.mergeChildArrays(teams, t => t.members);

		const stageErrors = getAccStageErrors(this.util, acc, applications, teams, events, awards);
		const problems: string[] = [];

		for (const error of stageErrors) {

			let timing = '';

			if (direction == -1) {
				if (error.logic == 'Before' && acc.accStageId == error.accStageId) timing = 'before ' + stages[index].name;
				if (error.logic == 'At-Or-Before' && changeTo.accStageId <= error.accStageId) timing = 'at or before ' + changeTo.name;
			}
			if (direction == 1) {
				if (error.logic == 'At-Or-Beyond' && changeTo.accStageId >= error.accStageId) timing = 'at or beyond ' + changeTo.name;
				if (error.logic == 'Beyond' && acc.accStageId == error.accStageId) timing = 'beyond ' + stages[index].name;
			}

			if (timing.length) problems.push(`${error.message}, but the stage will be ${timing}`);

		}


		if (problems.length) {
			const ignoreIt = await this.dialogService.confirm(
				`Switching to ${changeTo.name} would cause problems:\n\n` + problems.join(`\n\n`),
				undefined, undefined,
				`Ignore it`, `I'll come back to change the stage after I fix it`
			);

			if (!ignoreIt) return;
		}


		if (problems.length && changeTo.accStageId == AccStageId.Complete) {
			this.dialogService.showMessage(`The accelerator stage cannot be changed to Complete until all the problems are resolved!`)
			return;
		}

		await this.accAreaService.accelerator.actions.setStage({ accStageId: changeTo.accStageId });
	}
}