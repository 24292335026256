import { PendingSearchMatch, SearchableData, SearchMatchLevel, SearchRegExps, TextToMatch } from "../interfaces";
import { checkTexts } from "./check-texts";


export async function findAcceleratorMatches(
	data: SearchableData,
	re: SearchRegExps,
): Promise<PendingSearchMatch[]> {


	const matches: PendingSearchMatch[] = [];


	for (const acc of data.accelerators) {

		const site = acc.siteProgram.site;

		const texts: TextToMatch[] = [
			{ text: `${site.code} ${acc.name}`, field: 'Name', fullMatchIsExact: true },
			{ text: acc.name, field: 'Name', fullMatchIsExact: true },
			{ text: `${site.code} ${acc.longName}`, field: 'Long Name', fullMatchIsExact: true },
			{ text: acc.longName, field: 'Long Name', fullMatchIsExact: true },
		];

		const match = checkTexts(acc, texts, re);

		if (match.level > SearchMatchLevel.None) matches.push(match);

	}

	return matches;
}