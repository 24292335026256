import { Component } from '@angular/core';
import { NationalDashboardPageService } from '../../national-dashboard-page.service';

@Component({
	selector: 'national-get-help-tab-part',
	templateUrl: './national-get-help-tab.part.html',
})
export class NationalGetHelpTabPart {

	constructor(
		public pageService: NationalDashboardPageService,
	) {
	}

}
