import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccMatchingRollups, CaptionedLayout, SevenNums } from '@me-interfaces';
import { DialogContext } from '@me-services/ui/dialog';
import { DAY_NAMES, MAX_SLOT_VALUE, SLOT_NAMES } from '@me-services/ui/schedule-utils';

interface SelectionData {
	captionedLayout: CaptionedLayout,
	type: 'Mentor' | 'Team',
	accTeamId?: number,
	personId?: number,
	rollup: AccMatchingRollups,
	schedule: SevenNums,
	hasDefaultSchedule: boolean,
	text: string,
}

type SlotData = {
	user: boolean,
	others: {
		personId?: number,
		accTeamId?: number,
		name: string,
		companyName?: string,
		given: number,
		received: number,
		hasDefaultSchedule: boolean,
		schedule: SevenNums,
	}[],
	blockout: boolean,
};

@Component({
	selector: 'me-acc-mm-heatmap-dialog',
	templateUrl: './acc-mm-heatmap.dialog.html',
	styleUrls: ['./acc-mm-heatmap.dialog.scss']
})
export class MMHeatmapDialog {
	selection: SelectionData;
	meetings: SlotData[][];
	day: number;
	slot: number;
	assessmentMap: { [key: string]: [number | undefined, number | undefined] };
	role = '';
	dayNames = [...DAY_NAMES];
	slotNames = [...SLOT_NAMES];
	maxValue = MAX_SLOT_VALUE;
	selectedValue: number;
	d: number;
	s: number;
	meetingOthersColumns = ['name', 'given', 'received'];

	constructor(
		@Inject(MAT_DIALOG_DATA) dialog: DialogContext<{ selection: SelectionData, meetings: SlotData[][], assessmentMap: { [key: string]: [number | undefined, number | undefined] }, day: number, slot: number }>,
		public dialogRef: MatDialogRef<MMHeatmapDialog>,
	) {

		this.slotNames.pop();
		this.slotNames.pop();
		this.selection = dialog.data.selection;
		this.role = this.selection.accTeamId ? 'Entrepreneur' : 'Mentor';
		this.meetings = dialog.data.meetings;
		this.day = dialog.data.day;
		this.slot = dialog.data.slot;
		this.assessmentMap = dialog.data.assessmentMap;
		this.selectedValue = this.maxValue * this.day + this.slot;
		this.d = Math.floor(this.selectedValue / this.maxValue);
		this.s = this.selectedValue % this.maxValue;

	}

	onNoClick(): void {
		this.dialogRef.close();

	}

	getSlotNames(d, s) {
		let value = '';

		if (s < 12) value = ' AM';
		else if (s > 12) value = ' PM';

		let meets = '0';

		const meeting = this.meetings[d][s];

		if (meeting) meets = meeting.others.length.toString();
		const check = meeting.user ? '☑' : '☐';

		return `${check} ${this.dayNames[d]} ${this.slotNames[s]}${value}  x${meets}`;
	}

	getSlotColor(d: number, s: number): string {
		const meeting = this.meetings[d][s];
		return meeting.user ? 'black' : '#CCC';
	}

	setSelectedValue(e) {
		this.selectedValue = e.value;
		this.d = Math.floor(this.selectedValue / this.maxValue);
		this.s = this.selectedValue % this.maxValue;
	}

}
