<div class="sidebar menu-sidebar" [class.menu-collapsed]='menu.collapsed$ | async'>

	<ul class="nav nav-sidebar flex-column flex-nowrap menu-scrollbar">

		<ng-container *ngFor='let item1 of menuItems$ | async'>

			<hr *ngIf='item1.separator' class='menu-separator'>

			<li class="sidebar-list-item sidebar-item-expandable" [attr.data-url]='item1.routerLink'>

				<ng-container *ngIf='!item1.subMenu'>

					<a *ngIf="!item1.routerLink" [attr.href]="item1.url || ''" [attr.target]="item1.target || ''"
						(mouseenter)="hoverItem($event)" (mouseleave)="unHoverItem($event)" class="sidebar-list-link"
						[class.admin-menu]='item1.isAdminMenu'>
						<i class="fa-fw {{ item1.icon }}"></i>
						<span>
							<menu-label [label]='item1.label'></menu-label>
						</span>
					</a>

					<a *ngIf="item1.routerLink" [routerLink]="[item1.routerLink]" routerLinkActive="active-link"
						[routerLinkActiveOptions]="{exact:true}" (click)="collapseMenu($event)"
						(mouseenter)="hoverItem($event)" (mouseleave)="unHoverItem($event)" class="sidebar-list-link"
						[class.admin-menu]='item1.isAdminMenu'>
						<i class="fa-fw {{ item1.icon }}"></i>
						<span>
							<menu-label [label]='item1.label'></menu-label>
						</span>
					</a>

				</ng-container>

				<ng-container *ngIf='item1.subMenu'>

					<a [attr.href]="''" (click)="collapseMenu($event)" (mouseenter)="hoverItem($event)"
						(mouseleave)="unHoverItem($event)" class="sidebar-list-link"
						[class.admin-menu]='item1.isAdminMenu' [class.active-link]='router.url===item1.routerLink'>
						<i class="fa-fw {{ item1.icon }}"></i>
						<span class='sidebar-item-expanded-text'>
							<menu-label [label]='item1.label'></menu-label>
						</span>
						<span class='sidebar-item-collapsed-text'>
							<menu-label [label]=' item1.label_collapsed || item1.label'></menu-label>
						</span>
						<b class="far fa-angle-down"></b>
					</a>

					<ul class="sidebar-sublist">
						<li *ngFor="let item2 of item1.subMenu" class="sidebar-sublist-item sidebar-item-expandable"
							[attr.data-url]='item2.routerLink'>

							<ng-container *ngIf="item2.subMenu">
								<a [attr.href]="''" (click)="collapseMenu($event)" (mouseenter)="hoverItem($event)"
									(mouseleave)="unHoverItem($event)" class="sidebar-list-link subitem-submenu-link"
									[class.admin-menu]='item2.isAdminMenu'
									[class.active-link]='router.url===item2.routerLink'>
									<i class="{{ item2.icon }}"></i>
									<span>
										<menu-label [label]='item2.label'></menu-label>
									</span>
									<b class="far fa-angle-down"></b>
								</a>

								<ul class="sidebar-sublist subitem-submenu-list">
									<li *ngFor="let item3 of item2.subMenu" (mouseenter)="hoverItem($event)"
										(mouseleave)="unHoverItem($event)">
										<a *ngIf="!item3.routerLink" (mouseenter)="hoverItem($event)"
											(mouseleave)="unHoverItem($event)" [attr.href]="item3.url || ''"
											[attr.target]="item3.target || ''">
											<i class="{{ item3.icon }}"></i>
											<menu-label [label]='item3.label'></menu-label>
										</a>
										<a *ngIf="item3.routerLink" (mouseenter)="hoverItem($event)"
											(mouseleave)="unHoverItem($event)" [attr.target]="item3.target || ''"
											[routerLink]="[item3.routerLink]" routerLinkActive="active-link"
											[class.admin-menu]='item3.isAdminMenu'>
											<i class="{{ item3.icon }}"></i>
											<menu-label [label]='item3.label'></menu-label>
										</a>
									</li>
								</ul>
							</ng-container>

							<ng-container *ngIf='!item2.subMenu'>
								<a *ngIf="item2.routerLink" [routerLink]="[item2.routerLink]"
									routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}"
									(click)="collapseMenu($event)" (mouseenter)="hoverItem($event)"
									(mouseleave)="unHoverItem($event)" [class.admin-menu]='item2.isAdminMenu'>
									<i class="{{ item2.icon }}"></i>
									<span>
										<menu-label [label]='item2.label'></menu-label>
									</span>
								</a>

								<a *ngIf="!item2.routerLink" [attr.href]="item2.url || ''"
									[attr.target]="item2.target || ''" (mouseenter)="hoverItem($event)"
									(mouseleave)="unHoverItem($event)">
									<i class="{{ item2.icon }}"></i>
									<span>
										<menu-label [label]='item2.label'></menu-label>
									</span>
								</a>
							</ng-container>
						</li>
					</ul>
				</ng-container>
			</li>

		</ng-container>

	</ul>

	<div class="sidebar-hover-elem" [ngStyle]="{top: hoverElemTop + 'px', height: hoverElemHeight + 'px'}"
		[ngClass]="{'show-hover-elem': showHoverElem }"></div>

</div>