import { AccApplicationContext, AccPreAcceleratorPageService, AccPreAcceleratorTabId, AccSingleEventSelectorDialog, accTransferApplication } from '@ACC-area';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AccAreaService, AccessAtStage } from '@ACC-area';
import { DestroyablePart } from '@me-access-parts';
import { ApplicationCols, GridAction, GridColumnType, GridSetup } from '@me-grid';
import { AccStageId, Answer, ApplicationStatusId, DbaAccInterview, Event, EventTypeId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogService } from '@me-services/ui/dialog';
import { GridPart, Icon } from '@me-shared-parts/UI-common';
import { combineLatest, mergeMap } from 'rxjs';
import { applyApplicationGridFilter } from '../../../acc-application-filter-helpers';
import { AccApplicationStatusName, SET_TO_ABANDONED_ACTION_KEY, SET_TO_ACCEPTED_ACTION_KEY, SET_TO_DEFERRED_ACTION_KEY, SET_TO_PENDING_ACTION_KEY, SET_TO_REJECTED_ACTION_KEY, SET_TO_WITHDRAWN_ACTION_KEY, canSetApplicationStatus, getApplicationStatusNameByStage } from '../../../acc-application-status-helpers';


const STAGE_ID = AccStageId.Interviewing;
const PENDING_APPLICATION_STATUS_ID = ApplicationStatusId.InterviewPending;
const ABANDONED_APPLICATION_STATUS_ID = ApplicationStatusId.InterviewAbandoned;
const WITHDRAWN_APPLICATION_STATUS_ID = ApplicationStatusId.InterviewWithdrawn;
const REJECTED_APPLICATION_STATUS_ID = ApplicationStatusId.InterviewRejected;
const DEFERRED_APPLICATION_STATUS_ID = ApplicationStatusId.InterviewDeferred;
const ACCEPTED_APPLICATION_STATUS_ID = ApplicationStatusId.InterviewAccepted;


interface ApplicationRow {
	applicationId: number,
	applicationStatusId: ApplicationStatusId,
	countOfInterviews: number,
	eventId: number,
	eventNum: number,
	eventDate: Date,
	status: AccApplicationStatusName,
	iAssessments: string,
	iScore: number,
	updatedUTC: number,
}


@Component({
	selector: 'acc-interviewing-applications-view-part',
	templateUrl: './acc-interviewing-applications-view.part.html',
})
export class AccInterviewingApplicationsViewPart extends DestroyablePart implements OnInit, AfterViewInit {

	@ViewChild(GridPart) meGrid: GridPart<ApplicationRow>;

	private accessAtStage: AccessAtStage;

	public gridSetup = this.setupGrid(false);


	/**
	 * Map applications into grid rows
	 */
	public rows$ = combineLatest([
		this.ds.admin.singletonsAsOfUTC$,
		this.accAreaService.applications.applications$,
		this.accAreaService.teams.teams$,
		this.accAreaService.interviewing.assessments$,
	])
		.pipe(
			mergeMap(async ([singletonsAsOfUTC, applications, accTeams, assessments]) => {
				if (!applications || !accTeams || !assessments) return [];
				return await this.buildRows(applications, assessments);
			}));

	totalInterviews = 0;

	constructor(
		private util: UtilityService,
		public accAreaService: AccAreaService,
		public ds: DataService,
		public pageService: AccPreAcceleratorPageService,
		private dialogService: DialogService,
		private router: Router,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();

		const accessAtStage$ = this.accAreaService.getAccessAtStage(STAGE_ID);

		super.subscribe([accessAtStage$], async ([accessAtStage]) => {
			this.accessAtStage = accessAtStage;
			this.gridSetup = this.setupGrid(accessAtStage.canViewDemographics);
		});

	}


	ngAfterViewInit() {

		super.subscribe([this.pageService.applyApplicationsFilter$], async ([filter]) => {
			applyApplicationGridFilter(STAGE_ID, this.meGrid.grid, filter);
			this.pageService.selectTab(AccPreAcceleratorTabId.Manage)
		});

	}


	setupGrid(canViewDemographics: boolean): GridSetup<ApplicationRow> {

		const setup: GridSetup<ApplicationRow> = {
			experience: 'APPLICATION',
			multiselect: true,
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Application",
			rowPluralName: "Applications",
			rowKey: "applicationId",
			stateKey: "acc-interviewing-applications-view",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			showDemographics: canViewDemographics,
			columnsToAdd: [
				{ field: "eventId", header: "EventId", width: 85, type: GridColumnType.number, hidden: true },
				{ field: 'status', header: "Status", width: 100, type: GridColumnType.text, hidden: false },
				{ field: "eventNum", header: "Event #", width: 80, type: GridColumnType.number, hidden: true },
				{ field: "eventDate", header: "Interview Time", width: 130, type: GridColumnType.dateAndTime },
				{ field: "countOfInterviews", header: "Interviews", width: 60, type: GridColumnType.number },
				{ field: "iAssessments", header: "I Assessments", width: 115, type: GridColumnType.ratings, headerTooltip: `Interview Assessments` },
				{ field: "iScore", header: "I Score", width: 60, type: GridColumnType.number, headerTooltip: `Interview Score`, fractionDigits: 1 },
			],
			initialState: {
				sort: [{ field: ApplicationCols.companyName, dir: 'asc' }],
				filter: {
					logic: 'and',
					filters: [{ field: 'status', operator: 'neq', value: 'Beyond' }],
				}
			},
			actionEnabler: this.gridActionEnabler.bind(this)
		};

		setup.actions = [
			{ key: 'set-event', icon: Icon.action_calendar, label: 'Set Event', enabled: false },
			{ key: SET_TO_PENDING_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Pending' },
			{ key: SET_TO_ABANDONED_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Abandoned' },
			{ key: SET_TO_WITHDRAWN_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Withdrawn' },
			{ key: SET_TO_REJECTED_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Rejected' },
			{ key: SET_TO_DEFERRED_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Deferred' },
			{ key: SET_TO_ACCEPTED_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Accepted' },
			{ key: 'transfer-action', icon: Icon.action_transfer, label: 'Transfer' },
		];

		return setup;
	}


	async buildRows(
		applications: readonly AccApplicationContext[],
		interviewAssessments: { interview: DbaAccInterview, answers: readonly Answer[], }[],
	): Promise<ApplicationRow[]> {

		if (!applications) return [];

		const eventWithNumAndDate: {
			event: Event,
			num: number,
			numWithDate: string,
		}[] = await this.accAreaService.getEventsWithNumAndDate(EventTypeId.AccInterviewing);


		const rows: ApplicationRow[] = applications
			.filter(app => app.application.applicationStatusId >= PENDING_APPLICATION_STATUS_ID)
			.map(app => {

				const eventWithNum = app.application.eventId ? eventWithNumAndDate.find(e => e.event.eventId == app.application.eventId) : undefined;

				const assessments = interviewAssessments.filter(assessment => assessment.interview.applicationId == app.application.applicationId)
					.map(assessment => ({ rating: assessment.interview.rating, answers: assessment.answers }));
				const { ratings4, ratings5 } = this.util.ratings.getRating4AndRatings5(assessments);


				return {
					applicationId: app.application.applicationId,
					applicationStatusId: app.application.applicationStatusId,
					businessName: app.application.companyName,
					countOfInterviews: interviewAssessments.filter(ra => ra.interview.applicationId == app.application.applicationId).length,
					eventId: app.application.eventId,
					eventDate: eventWithNum ? this.util.date.getDate(eventWithNum.event.startUTC) : undefined,
					eventNum: eventWithNum?.num,
					status: getApplicationStatusNameByStage(AccStageId.Interviewing, app.application.applicationStatusId),
					demographics: app.demographics,
					iAssessments: this.util.ratings.formatForGridCell(ratings4),
					iScore: this.util.ratings.calculateScore(ratings5, ratings4),
					updatedUTC: app.application.updatedUTC,
				}
			});


		return rows;

	}


	gridActionEnabler(action: GridAction, rows: ApplicationRow[]): boolean {

		if (rows.length == 0) return false;

		if (this.accessAtStage == undefined) {
			console.error(`this.accessAtStage is undefined`);
			return false;
		}

		const canEdit = this.accessAtStage.access == 'Write';
		const currentAccStageId = this.accessAtStage.currentStageId;

		if (
			action.key == SET_TO_PENDING_ACTION_KEY ||
			action.key == SET_TO_ABANDONED_ACTION_KEY ||
			action.key == SET_TO_WITHDRAWN_ACTION_KEY ||
			action.key == SET_TO_REJECTED_ACTION_KEY ||
			action.key == SET_TO_DEFERRED_ACTION_KEY ||
			action.key == SET_TO_ACCEPTED_ACTION_KEY
		) {

			return canSetApplicationStatus(this.accessAtStage.canUpdateApplicationStatuses, currentAccStageId, STAGE_ID, action.key, rows.map(row => row.applicationStatusId));
		}

		if (rows.length > 1) return false;	// Multi is only supported with the application status setters

		if (action.key == 'transfer-action') {
			const isTransferrableStatus = [ApplicationStatusId.AcceptPending, ApplicationStatusId.ReadPending, ApplicationStatusId.InterviewPending, ApplicationStatusId.SelectPending].includes(rows[0].applicationStatusId);
			return canEdit && isTransferrableStatus;
		}

		if (action.key == 'set-event') {
			return canEdit;
		}

		return true;
	}


	async gridActionHandler(action: { actionKey: string, rows: ApplicationRow[] }) {

		const { actionKey, rows } = action;

		if (!rows.length) return;

		const applicationIds = rows.map(row => row.applicationId);

		//
		// Perform the bulk change
		//
		if ([
			SET_TO_PENDING_ACTION_KEY,
			SET_TO_ABANDONED_ACTION_KEY,
			SET_TO_WITHDRAWN_ACTION_KEY,
			SET_TO_REJECTED_ACTION_KEY,
			SET_TO_DEFERRED_ACTION_KEY,
			SET_TO_ACCEPTED_ACTION_KEY,
		].includes(actionKey)) {

			let applicationStatusId: ApplicationStatusId = REJECTED_APPLICATION_STATUS_ID;
			let status = 'Rejected';

			if (actionKey == SET_TO_PENDING_ACTION_KEY) {
				applicationStatusId = PENDING_APPLICATION_STATUS_ID;
				status = 'Pending';
			}
			if (actionKey == SET_TO_ABANDONED_ACTION_KEY) {
				applicationStatusId = ABANDONED_APPLICATION_STATUS_ID;
				status = 'Abandoned';
			}
			if (actionKey == SET_TO_WITHDRAWN_ACTION_KEY) {
				applicationStatusId = WITHDRAWN_APPLICATION_STATUS_ID;
				status = 'Withdrawn';
			}
			if (actionKey == SET_TO_REJECTED_ACTION_KEY) {
				applicationStatusId = REJECTED_APPLICATION_STATUS_ID;
				status = 'Rejected';
			}
			if (actionKey == SET_TO_DEFERRED_ACTION_KEY) {
				applicationStatusId = DEFERRED_APPLICATION_STATUS_ID;
				status = 'Deferred';
			}
			if (actionKey == SET_TO_ACCEPTED_ACTION_KEY) {
				applicationStatusId = ACCEPTED_APPLICATION_STATUS_ID;
				status = 'Accepted';
			}

			const message = `Would you like to change the status of the following applications to '${status}'?`;

			const applicationsById = await this.ds.admin.application.getManyAsMap(rows.map(row => row.applicationId));
			const bullets = rows.map(row => applicationsById[row.applicationId]?.companyName ?? `Application #'${row.applicationId}'`).sort();

			const yes = await this.dialogService.confirm(message, 400, 150, undefined, undefined, bullets);
			if (yes) await this.accAreaService.applications.actions.setBulkStatus({ applicationIds, applicationStatusId });
		}

		else if (actionKey == 'transfer-action') {
			if (rows.length > 1) return;

			await accTransferApplication(this.router, this.accessAtStage.access != 'Write', this.ds, this.accAreaService, rows[0].applicationId, rows[0].applicationStatusId);
		}

		else if (action.actionKey == 'set-event') {

			const row = rows[0];

			const data = {
				readonly: false,
				eventId: row.eventId,
				eventTypeId: EventTypeId.AccInterviewing,
				header: `Set Application Event`
			};

			const result: DialogAction<number> = await this.dialogService.showCustom(AccSingleEventSelectorDialog, { data }, 350, 200);

			if (result?.id == 'save') {
				await this.accAreaService.applications.actions.setInterviewEventId({ applicationId: row.applicationId, eventId: result.callbackResult });
			}

		}

	}
}