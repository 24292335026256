import { AppAreaApplyResponse, AppAreaFuncResponse, AppAreaIdentifier, GoalManagement } from "@me-interfaces";
import { AppSubArea } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";

export class UserSubAreaGoals extends AppSubArea implements GoalManagement {

	constructor(
		private func: FuncService,
		ds: DataService,
		util: UtilityService,
		getId: () => AppAreaIdentifier<string>,
		applyResponse: (response: AppAreaFuncResponse) => Promise<AppAreaApplyResponse>,
	) {
		super(ds, util, getId, applyResponse);
	}


	async add(parameters: { accTeamId: number, quarter: number, summary: string, details: string }) {
		return await this.call(this.func.areas.user.goals.addGoal, parameters);
	}

	async set(parameters: { accTeamGoalId: number, summary: string, details: string, progress: string }) {
		return await this.call(this.func.areas.user.goals.setGoal, parameters);
	}

	async addMemberComment(parameters: { accTeamGoalId: number, comment: string }) {
		return await this.call(this.func.areas.user.comment.addMemberComment, parameters);
	}

	async addMentorComment(parameters: { accTeamGoalId: number, comment: string }) {
		return await this.call(this.func.areas.user.comment.addMentorComment, parameters);
	}
}