<app-area-access [areaService]='accAreaService' accessKey="assessments">

	<div class='acc-mm-schedule-heatmap-view-part' *ngIf='data'>
		<div>
			<div>Mentor or Team</div>
			<kendo-dropdownlist [data]="data.mentorAndTeamList" [value]="selectedMentorOrTeam"
				[defaultItem]="defaultData" textField="name" valueField="id" (valueChange)="handleSelection($event)">
				<ng-template kendoDropDownListValueTemplate let-dataItem>
					<captioned-layout *ngIf="dataItem && dataItem.captionedLayout" [layout]='dataItem.captionedLayout'>
					</captioned-layout>
					<overlap-rollups *ngIf='dataItem && dataItem.rollup' [rollups]='dataItem.rollup'
						[thresholds]='[10,20]' [oppositeRole]='overlapNames(dataItem)'></overlap-rollups>
				</ng-template>
				<ng-template kendoDropDownListItemTemplate let-dataItem>
					<captioned-layout *ngIf="dataItem && dataItem.captionedLayout" [layout]='dataItem.captionedLayout'>
					</captioned-layout>
					<overlap-rollups *ngIf='dataItem && dataItem.rollup' [rollups]='dataItem.rollup'
						[thresholds]='[10,20]' [oppositeRole]='overlapNames(dataItem)'></overlap-rollups>
				</ng-template>
			</kendo-dropdownlist>
		</div>

		<ng-container *ngIf='selectedMentorOrTeam'>
			<div class="assessment-toolbar">
				<div>
					<div>When the {{selectedMentorOrTeam.type}} Gave</div>
					<kendo-multiselect [data]='assessmentsGiven' [value]="selectedAssessmentsGiven" [autoClose]="false"
						(valueChange)='assessmentsChange($event, "g")' [textField]="'text'" [valueField]="'value'"
						[valuePrimitive]="true" [checkboxes]="true">
					</kendo-multiselect>
				</div>

				<div>
					<div>When the {{selectedMentorOrTeam.type}} Received</div>
					<kendo-multiselect [data]='assessmentsReceived' [value]="selectedAssessmentsReceived"
						[autoClose]="false" (valueChange)='assessmentsChange($event, "r")' [textField]="'text'"
						[valueField]="'value'" [valuePrimitive]="true" [checkboxes]="true">
					</kendo-multiselect>
				</div>
			</div>
			<div class="heatmap">
				<div class='col-header' class='header-row'>
					<div class='row-header'></div>
					<div *ngFor='let dayName of dayNames; let day=index' class='slot column-header'>{{dayName}}</div>
				</div>

				<div *ngFor='let slotName of slotNames; let slot=index' class='slot-row'>
					<div class='row-header'>{{slotName}}</div>
					<div *ngFor='let dayName of dayNames; let day=index' [class]='getSlotClass(day, slot)'
						[style.backgroundColor]='getSlotBackColor(day, slot)' (click)='openDialog(day, slot)'>
						{{ meetings[day][slot].others.length }}
					</div>
				</div>
			</div>

			<div>
				<message-box alertType='info'>Using the selected assessments above,
					{{list.length}}
					{{oppositeRole}}s have overlapping meetings with an average
					of {{medianMeetings}} meetings per {{oppositeRole}}</message-box>
				<ng-container *ngIf='list.length'>
					<table style='width:100%' class="pro-table pro-table-without-border">
						<tr>
							<th>No.</th>
							<th>Name</th>
							<th>Given</th>
							<th>Received</th>
							<th>Meetings</th>
						</tr>
						<tr *ngFor="let row of list">
							<td>{{row.num}}</td>
							<td>
								<div>{{row.name}}</div>
								<small *ngIf='row.companyName'> {{row.companyName}}</small>
							</td>
							<td>
								<rating-four *ngIf='row.given' title='Ratings Given' [rating]='row.given'></rating-four>
							</td>
							<td>
								<rating-four *ngIf='row.received' title='Ratings Received' [rating]='row.received'>
								</rating-four>
							</td>
							<td>{{row.totalMeetings}}</td>
						</tr>
					</table>
				</ng-container>
			</div>
		</ng-container>
	</div>
</app-area-access>