import { AppAreaApplyResponse, AppAreaFuncResponse, AppAreaIdentifier, DbcVolunteerApplication, SpecialistStatus, TagApprovals } from "@me-interfaces";
import { AppSubArea } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";

export class SiteSubAreaVolunteer extends AppSubArea {

	constructor(
		private func: FuncService,
		ds: DataService,
		util: UtilityService,
		getId: () => AppAreaIdentifier<number>,
		applyResponse: (response: AppAreaFuncResponse) => Promise<AppAreaApplyResponse>,
	) {
		super(ds, util, getId, applyResponse);
	}

	async setSiteFollowerTag(parameters: { personId: number, tagFullName: string }) {
		return await this.call(this.func.areas.site.volunteer.setSiteFollowerTag, parameters);
	}

	async setSiteRoleTag(parameters: { personId: number, tagFullName: string }) {
		return await this.call(this.func.areas.site.volunteer.setSiteRoleTag, parameters);
	}

	async removeSiteRoleTag(parameters: { personId: number, tagFullName: string }) {
		return await this.call(this.func.areas.site.volunteer.removeSiteRoleTag, parameters);
	}

	async setSpecialist(parameters: { personId: number, topicId: number, languageId: number, status: SpecialistStatus, note: string }) {
		return await this.call(this.func.areas.site.volunteer.setSpecialist, parameters);
	}

	async removeSpecialist(parameters: { specialistId: number }) {
		return await this.call(this.func.areas.site.volunteer.removeSpecialist, parameters);
	}

	//
	// Volunteer Application
	//
	async addOrRemoveTags(parameters: { applicantPersonId: number, tags: TagApprovals }) {
		return await this.call(this.func.areas.site.volunteer.addOrRemoveTags, parameters);
	}

	async closeApplication(parameters: { application: DbcVolunteerApplication, tags: TagApprovals }) {
		return await this.call(this.func.areas.site.volunteer.closeApplication, parameters);
	}

	async reopenApplication(parameters: { volunteerApplicationId: number }) {
		return await this.call(this.func.areas.site.volunteer.reopen, parameters);
	}

	async transferApplication(parameters: { volunteerApplicationId: number, transferSiteId: number }) {
		return await this.call(this.func.areas.site.volunteer.transfer, parameters);
	}


}