<div class='pgm-award-edit'>
	<ng-container *ngIf='newAward'>
		<table style='width:100%' class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>


			<tr>
				<td [ngClass]='{"pro-table-label-error": missing.teamId}'>Company</td>
				<td *ngIf='!isAdd'>
					<company-extract-link *ngIf='award.company' [company]='award.company'></company-extract-link>
				</td>
				<td *ngIf='isAdd'>
					<kendo-dropdownlist [style.width.%]='100' [data]='list' textField='text'
						[disabled]='readonly' valueField='teamId' [value]='team' (valueChange)='teamChange($event)'>
					</kendo-dropdownlist>
				</td>
			</tr>


			<tr>
				<td [ngClass]='{"pro-table-label-error": missing.awardNameId}'>Award</td>
				<td *ngIf='!isAdd'>{{award.award}}</td>
				<td *ngIf='isAdd'>
					<kendo-dropdownlist [style.width.%]='100' [value]="newAward.awardNameId"
						(valueChange)='setAwardName($event)' [textField]="'name'" [valueField]="'awardNameId'"
						[valuePrimitive]="true" [disabled]='readonly' [data]='awardNames'>
					</kendo-dropdownlist>
				</td>
			</tr>


			<tr>
				<td [ngClass]='{"pro-table-label-error": missing.decidingRoleId}'>Deciding Role</td>
				<td *ngIf='!isAdd'>{{award.decidedBy}}</td>
				<td *ngIf='isAdd'>
					<kendo-dropdownlist [style.width.%]='100' [value]="newAward.decidingRoleId"
						(valueChange)='setDecidingRole($event)' [textField]="'name'" [valueField]="'decidingRoleId'"
						[valuePrimitive]="true" [disabled]='readonly || !newAward.awardNameId' [data]='decidingRoles'>
					</kendo-dropdownlist>
				</td>
			</tr>


			<tr>
				<td [ngClass]='{"pro-table-label-error": missing.awardKindId}'>Kind</td>
				<td *ngIf='!isAdd'>{{award.kind}}</td>
				<td *ngIf='isAdd'>
					<kendo-dropdownlist [style.width.%]='100' [value]="newAward.awardKindId"
						(valueChange)='setAwardKind($event)' [textField]="'name'" [valueField]="'awardKindId'"
						[valuePrimitive]="true" [disabled]='readonly' [data]='awardKinds'>
					</kendo-dropdownlist>
				</td>
			</tr>


			<tr>
				<td [ngClass]='{"pro-table-label-error": missing.value}'>Value</td>
				<td>
					<kendo-numerictextbox #numericField min='0'
						[value]='newAward.value' [disabled]='readonly' format='c0' [style.width.%]='40'
						[autoCorrect]='true' [autoSize]="false" (valueChange)="setValue($event)">
					</kendo-numerictextbox>
				</td>
			</tr>


			<tr>
				<td>Notes</td>
				<td>
					<textarea kendoTextArea [value]='notes' style="resize:none;" placeholder="Enter notes here"
						(valueChange)='notesChange($event)' (blur)='notesBlur()'></textarea>
				</td>
			</tr>
		</table>
	</ng-container>
</div>