import { Component, Input, OnChanges } from '@angular/core';
import { RatingLevel } from './SHR-CMN_rating-interfaces';

@Component({
	selector: 'rating-level-title',
	template: `
			<img *ngIf='imageUrl' class="img-circle" src="{{imageUrl}}">
			<div title='{{title}}'>{{title}}</div>
		`,
	styles: [`
			:host {
				line-height: 48px;
				vertical-align: middle;
			}
			img.img-circle {
				width: 40px;
				border-radius: 50%;
				float: left;
				margin: 4px 8px 4px 0;
			}
			div {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		`],
})
export class RatingLevelTitlePart implements OnChanges {

	@Input() level: RatingLevel;

	imageUrl: string = undefined;
	title = '';

	ngOnChanges() {
		this.imageUrl = undefined;
		this.title = '';

		const level = this.level;

		if (level.person) {
			this.title = `${level.person.firstName} ${level.person.lastName}`;
			this.imageUrl = '/assets/img/users/avatar.png';
			//if (level.person.hasAvatar) this.imageUrl = 
		}
		else if (level.company) {
			this.title = `${level.company.name}`;
			this.imageUrl = '/assets/img/company.png';
			//if (level.company.hasLogo) this.imageUrl =
		}
		else if (level.question) {
			this.title = `${level.question.internalLabel}`;
		}
	}
}