import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridColumnType, GridSetup, GridValueStyle, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { StaffOverviewPageService } from '../../staff-overview-page.service';


interface StaffRow {
	staffMemberId: number,
	title: string,
	department: string,
	manager: string,
	startUTC: number,
	endUTC: number,
	notes: string,
	updatedUTC: number,
}


@Component({
	selector: 'staff-all-staff-view-part',
	templateUrl: './all-staff-view.part.html',
	styleUrls: ['./all-staff-view.part.scss'],
})
export class StaffAllStaffViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid(false);

	public rows$: Observable<StaffRow[]> = of([]);

	@ViewChild(GridPart) meGrid: GridPart<StaffRow>;

	constructor(
		private util: UtilityService,
		public ds: DataService,
		public pageService: StaffOverviewPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(utc => this.buildRows(this.ds)));
	}


	async buildRows(ds: DataService): Promise<StaffRow[]> {
		
		return [];

	}


	private valueStyle(row: StaffRow, field: string): GridValueStyle {
		const value = row[field];

		if (field == 'notes') {
			if (value) return { icon: 'fas fa-file-alt', textStyle: 'Hidden' };
			else return { icon: 'far fa-file', textStyle: 'Hidden' };
		}

		return undefined;
	}

	private setupGrid(canWrite: boolean): GridSetup<StaffRow> {
		const valueStyle = this.valueStyle.bind(this);

		return {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Member",
			rowPluralName: "Members",
			rowKey: "personId",
			stateKey: "staff-all-staff",
			canAdd: canWrite,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				{ field: "staffMemberId", header: "staffMemberId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "title", header: "Title", width: 100, type: GridColumnType.text },
				{ field: "department", header: "Department", width: 100, type: GridColumnType.text },
				{ field: "manager", header: "Manager", width: 100, type: GridColumnType.text },
				{ field: "startUTC", header: "Start", width: 100, type: GridColumnType.dateUtc },
				{ field: "endUTC", header: "End", width: 100, type: GridColumnType.dateUtc },
				{ field: "notes", header: "Notes", width: 60, type: GridColumnType.text, valueStyle },
			],
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: StaffRow[] }) {

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}
	}

}