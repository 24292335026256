import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DbsAgreementType, DbsAgreementVersion, LanguageId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogAction, DialogContext, DialogService } from '@me-services/ui/dialog';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';

interface AgreementVersionsDropdown {
	agreementVersion: DbsAgreementVersion,
	agreementType: DbsAgreementType,
	languageId: LanguageId,
}

@Component({
	selector: 'agreement-type-dialog',
	templateUrl: './agreement-type.dialog.html',
	styleUrls: ['./agreement-type.dialog.scss']
})
export class ProgramAgreementTypeDialog extends DestroyablePart implements OnInit {
	//
	// The action buttons
	//
	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: 'Close',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction[] = [this.cancelAction];
	icon = 'fa-file-contract';


	public agreementVersionsDropdown: DroplistItem<AgreementVersionsDropdown>[] = [];
	public selectedAgreementVersion: DroplistItem<AgreementVersionsDropdown>;

	groupSortValues: { [index: string]: number };

	agreementBodyDivHeight = this.dialog.height - 168; // dialog height minus the action, header, padding, selector, footer height

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ agreementTypeId: number }>,
		public dialogRef: MatDialogRef<ProgramAgreementTypeDialog>,
		public dialogService: DialogService,
		private ds: DataService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		const { agreementTypeId } = this.dialog.data;

		const agreementType = await this.ds.admin.agreementType.getOne(agreementTypeId);
		const agreementVersions = await this.ds.admin.agreementVersion.getArrayByForeignId('agreementTypeId', agreementTypeId);


		let i = 0;
		let j = 0;

		this.agreementVersionsDropdown = agreementVersions.sort((a, b) => a.updatedUTC > b.updatedUTC ? 1 : -1).reduce((a: DroplistItem<AgreementVersionsDropdown>[], version) => {

			a.push({
				uniqueId: ++i,
				iconContext: getIconContext(Icon.concept_agreementType, undefined, 'always', 'English'),
				data: {
					agreementVersion: version,
					agreementType: agreementType,
					languageId: LanguageId.English,
				},
				groupName: `${agreementType.nameEN} v${version.version}`,
				customSort: `v${version.version}-${version.updatedUTC}`,
			}, {
				uniqueId: ++i,
				iconContext: getIconContext(Icon.concept_agreementType, undefined, 'always', 'Spanish'),
				data: {
					agreementVersion: version,
					agreementType: agreementType,
					languageId: LanguageId.Spanish,
				},
				groupName: `${agreementType.nameEN} v${version.version}`,
				customSort: `v${version.version}-${version.updatedUTC}`,
			});

			this.groupSortValues = { ...this.groupSortValues, [`${agreementType.nameEN}v${version.version}`]: ++j };
			return a;
		}, []);

		this.selectedAgreementVersion = this.agreementVersionsDropdown[0];

	}

	onSelectionChange(e: DroplistItem<AgreementVersionsDropdown>) {
		this.selectedAgreementVersion = e;
	}

}