import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DbsPerson, LanguageId } from '@me-interfaces';
import { OldEntityService } from '@me-services/old-services-and-wrappers/entity';
import { ConstantsService } from '@me-services/core/constants';
import { OldDomainDataService } from '@me-services/core/old-dd';
@Component({
	selector: 'person-languages-editor',
	templateUrl: './SHR-ED_person-languages-editor.part.html',
	styleUrls: ['SHR-ED_person-languages-editor.part.scss'],
})
export class PersonLanguagesEditorPart implements OnInit {

	@Input() person: DbsPerson;

	form: FormGroup;
	updating = false;

	constructor(
		private dd: OldDomainDataService,
		private entityService: OldEntityService,
		private fb: FormBuilder,
		public constants: ConstantsService,
	) { }

	async ngOnInit() {
		this.createForm();
	}

	private createForm() {

		this.form = this.fb.group({
			english: [false],
			enBio: ['', [Validators.maxLength(2000)]],
			spanish: [false],
			esBio: ['', [Validators.maxLength(2000)]],
		});

		this.resetForm();
	}

	public resetForm() {
		const personTags = this.dd.tags.getByIds(this.person._personExtracts.tagIds);
		const engTag = personTags.find(tag => tag.name == 'english' && tag.prefix == 'lang');
		const spaTag = personTags.find(tag => tag.name == 'spanish' && tag.prefix == 'lang');
		const values = {
			english: !!engTag,
			enBio: engTag ? this.person._profileExtracts.bios.english || '' : '',
			spanish: !!spaTag,
			esBio: spaTag ? this.person._profileExtracts.bios.spanish || '' : '',
		};

		this.form.reset(values);

		Object.keys(this.form.controls).forEach(key => {
			const ctrl: AbstractControl = this.form.get(key);
			ctrl.markAsTouched();
		});

		this.form.updateValueAndValidity();
	}

	toggleCheckbox(box: string) {
		let ctrl: AbstractControl = undefined;

		if (box == 'english') ctrl = this.form.controls.english;
		if (box == 'spanish') ctrl = this.form.controls.spanish;

		const checked = !ctrl.value;

		ctrl.patchValue(checked);
		ctrl.markAsDirty();

		if (this.form.value.english) this.form.controls['enBio'].setValue(this.person._profileExtracts.bios.english || '');
		if (this.form.value.spanish) this.form.controls['esBio'].setValue(this.person._profileExtracts.bios.spanish || '');
	}

	public async save() {

		this.updating = true;
		await this.updateEnglishTag();
		await this.updateSpanishTag();

		if (this.form.value.english) await this.saveBio(LanguageId.English, this.form.value.enBio);
		if (this.form.value.spanish) await this.saveBio(LanguageId.Spanish, this.form.value.esBio);
		this.updating = false;
		this.resetForm();
	}

	async saveBio(languageId: number, newBio: string) {
		await this.entityService.wrapPerson(this.person).updateBio(languageId, newBio);
	}


	async updateEnglishTag() {
		const engLangTag = this.dd.tags.getByPrefix('lang').find(tag => tag.name == 'english');
		if (!this.form.value.english) {
			await this.entityService.wrapPerson(this.person).tags.remove(engLangTag.tagId);
		}
		else {
			await this.entityService.wrapPerson(this.person).tags.add(engLangTag.tagId);
		}
	}

	async updateSpanishTag() {
		const spanLangTag = this.dd.tags.getByPrefix('lang').find(tag => tag.name == 'spanish');
		if (!this.form.value.spanish) {
			this.entityService.wrapPerson(this.person).tags.remove(spanLangTag.tagId);
		}
		else {
			this.entityService.wrapPerson(this.person).tags.add(spanLangTag.tagId);
		}
	}
}
