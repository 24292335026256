import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DbcUser, DbsPerson } from '@me-interfaces';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { TagEditorData } from './tag-editor-data';

@Component({
	selector: 'tags-editor-dialog',
	templateUrl: './SHR-CMN_tags-editor.dialog.html',
	styleUrls: ['./SHR-CMN_tags-editor.dialog.scss']
})
export class TagsEditorDialog {

	closeAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: 'Close',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction[] = [this.closeAction];
	user: DbcUser;
	person: DbsPerson;
	initialSelectorKey: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) dialog: DialogContext<TagEditorData>,
		public dialogRef: MatDialogRef<TagsEditorDialog>,
	) {

		this.person = dialog.data.person;
		this.user = dialog.data.user;
		this.initialSelectorKey = dialog.data.initialSelectorKey;
	}
}
