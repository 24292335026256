import { AccAreaService, AccCohortPageService } from '@ACC-area';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { GridAction, GridColumnType, GridSetup, PersonCols } from '@me-grid';
import { AccTeam, DbaAccSessionAttendee, DbsAccSessionSurveyResponse, DbsAccTeamMember, DbsEvent, DbsPerson, Person, SessionAttendeeStatus } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { Icon, getIconClass } from '@me-shared-parts/UI-common';
import { Observable, combineLatest, map } from 'rxjs';


interface AttendanceRow {
	accSessionAttendeeId: number,
	accTeamMemberId: number,
	personId: number,
	accTeamId: number,
	business: string,
	attended: SessionAttendeeStatus,
	survey: 'Yes' | 'No',
	personName: string,
}


@Component({
	selector: 'session-attendance-dialog',
	templateUrl: './attendance.dialog.html',
})
export class SessionAttendanceDialog extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();

	//
	// The action buttons
	//
	sendSurvey = false;
	updateAction: DialogAction = {
		id: 'sendSurvey',
		enabled: true,
		visible: true,
		label: 'Send Surveys',
		linkType: 'callback',
		callback: this.checkIfCanSendSurvey.bind(this),
		willCloseDialog: false,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: 'Close',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction[] = [this.updateAction, this.cancelAction];
	icon = getIconClass(Icon.droplist_pickOne);

	sessionName: string;
	topicNames: string;
	readonly: boolean;
	private rows: AttendanceRow[];
	public rows$: Observable<AttendanceRow[]>;

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ readonly: boolean, accSessionId: number, sessionName: string, topicNames: string, specialistNames: string, event: DbsEvent, }>,
		public dialogRef: MatDialogRef<SessionAttendanceDialog>,
		private util: UtilityService,
		private accAreaService: AccAreaService,
		private ds: DataService,
		public pageService: AccCohortPageService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		const { readonly, sessionName, topicNames } = this.dialog.data;
		this.sessionName = sessionName;
		this.topicNames = topicNames;
		this.readonly = readonly;

		this.rows$ = combineLatest([
			this.ds.admin.singletonsAsOfUTC$,
			this.accAreaService.teams.teams$,
			this.accAreaService.curriculum.sessionAttendees$,
		]).pipe(map(data => this.buildRows(data[0], data[1], data[2])));

	}

	buildRows(
		singletonsAsOfUTC: number,
		teams: readonly AccTeam[],
		sessionAttendees: {
			accSessionAttendeeId: number;
			attendee: DbaAccSessionAttendee,
			person: Person,
			accTeam: AccTeam,
			accSessionSurveyResponse: DbsAccSessionSurveyResponse,
		}[]
	) {

		if (!teams || !sessionAttendees) return;

		const attendees = sessionAttendees.filter(sa => sa.attendee.accSessionId == this.dialog.data.accSessionId);

		this.rows = attendees.map(attendee => {

			return {
				accSessionAttendeeId: attendee.attendee.accSessionAttendeeId,
				attended: attendee.attendee.status,
				business: attendee.accTeam.company._name,
				personId: attendee.attendee.personId,
				accTeamId: attendee.accTeam.accTeamId,
				personName: attendee.person.fullName,
				survey: attendee.attendee.status == 'Present' ? attendee.accSessionSurveyResponse ? 'Yes' : 'No' : undefined,
				accTeamMemberId: attendee.accTeam.members.find(m => m.member.personId == attendee.attendee.personId)?.member.accTeamMemberId,
			}
		});

		const filteredTeams = teams.filter(team => !team.droppedOutWeek || team.droppedOutWeek && team.droppedOutWeek > 13);
		const teamMap = this.util.array.toMap(filteredTeams, team => team.accTeamId);
		const members = filteredTeams.reduce((a: {
			member: DbsAccTeamMember,
			person: DbsPerson,
		}[], team) => {
			a.push(...team.members.filter(team => team.member.role == 'E'));
			return a;
		}, []);

		// Add all the members that do not have an attendee record
		const attendeePersonIds = attendees.map(att => att.attendee.personId);

		for (const member of members.filter(m => !attendeePersonIds.includes(m.member.personId))) {
			this.rows.push({
				accSessionAttendeeId: undefined,
				attended: undefined,
				accTeamId: member.member.accTeamId,
				business: teamMap[member.member.accTeamId].company._name,
				personId: member.member.personId,
				personName: member.person._name,
				survey: undefined,
				accTeamMemberId: member.member.accTeamMemberId
			})
		}


		this.updateAction.label = this.rows.find(r => r.survey == 'Yes') ? 'Send Survey Again' : 'Send Survey';

		return this.rows;
	}



	private setupGrid(): GridSetup<AttendanceRow> {

		const setup: GridSetup<AttendanceRow> = {
			experience: 'PERSON',
			multiselect: true,
			size: {
				fitTo: 'DIALOG',
				dialogActions: true,
				dialogContext: this.dialog,
				heightMultiplier: 1,
				shrinkBy: 0,
			},
			rowSingularName: "Entrepreneur",
			rowPluralName: "Entrepreneurs",
			rowKey: "accTeamMemberId",
			stateKey: "session-attendance-dialog",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: "accTeamMemberId", header: "accTeamMemberId", width: 80, type: GridColumnType.number, hidden: true },
				{ field: "accSessionAttendeeId", header: "accSessionAttendeeId", width: 80, type: GridColumnType.number, hidden: true },
				{ field: "business", header: "Business", width: 150, type: GridColumnType.text },
				{ field: "attended", header: "Attended", width: 80, type: GridColumnType.text },
				{ field: "survey", header: "Survey Responses", width: 100, type: GridColumnType.text },
			],
			actions: [
				{ key: 'set-present', icon: Icon.action_edit, label: 'Mark as Present', enabled: false },
				{ key: 'set-absent', icon: Icon.action_edit, label: 'Mark as Absent', enabled: false },
			],
			actionEnabler: this.gridActionEnabler.bind(this),
			initialState: {
				sort: [{ field: PersonCols.name, dir: 'asc' }],
			},
		};

		return setup;
	}


	/**
	 * Whenever the selection changes, the Grid component will call this for each action
	 * to set whether the action should be enabled or disabled based on the selection.
	 */
	gridActionEnabler(action: GridAction, rows: AttendanceRow[]) {
		if (this.readonly) {
			if (action.key == 'set-present' || action.key == 'set-absent') {
				return false;
			}
		}
		else if (action.key == 'set-present') return !!rows.find(r => !r.attended || r.attended == 'Absent');
		else if (action.key == 'set-absent') return !rows.find(r => r.survey == 'Yes') && (!!rows.find(r => !r.attended || r.attended == 'Present'));
		return true;

	}


	async gridActionHandler(action: { actionKey: string, rows: AttendanceRow[] }) {

		if (action.actionKey == 'set-present' || action.actionKey == 'set-absent') {
			if (this.readonly) return;

			let status: SessionAttendeeStatus;
			if (action.actionKey == 'set-present') status = 'Present';
			if (action.actionKey == 'set-absent') {

				const attendeeSurveyExists = action.rows.filter(r => r.survey == 'Yes').map(r => r.personName).join(', ');
				if (attendeeSurveyExists) {
					await this.ds.dialogService.showMessage(`Entrepreneurs: ${attendeeSurveyExists} have already filled out the surveys so attendance cannot be changed to Absent`, 250, 250, 'Okay');
				}
				else status = 'Absent';
			}

			if (status) await this.accAreaService.curriculum.actions.setAttendeeStatus({ accSessionId: this.dialog.data.accSessionId, teamAndPersonIds: action.rows.map(r => ({ accTeamId: r.accTeamId, personId: r.personId })), status });

		}
	}


	async checkIfCanSendSurvey() {

		const event = this.dialog.data.event;

		const utc = Date.now() / 1000;
		let toolEnabledSendSurvey = true;

		if (utc < event.toolStartUTC) {
			const timePeriod = this.util.date.calculateTimePeriod(utc, event.toolStartUTC);
			const message = `The survey tool is currently inactive, but it will be accessible to entrepreneurs in ${timePeriod}. Would you like to proceed with sending the survey email?`;
			toolEnabledSendSurvey = await this.ds.dialogService.confirm(message, 350, 200);
		}
		else if (utc > event.toolEndUTC) {
			const timePeriod = this.util.date.calculateTimePeriod(event.toolEndUTC, utc);
			const message = `Sending the survey email is no longer possible. The survey is closed as of ${timePeriod} ago.`;
			await this.ds.dialogService.showMessage(message, 350, 190);
			toolEnabledSendSurvey = false;
		}


		if (toolEnabledSendSurvey) {

			const yes = this.dialog.data.specialistNames != 'n/a' ? await this.ds.dialogService.confirm(`Was ${this.dialog.data.specialistNames} the specialist?\n\nIf not, please correct it before sending the survey.`, 450, 170, 'Yes', 'No') : true;
			if (yes) {
				const atleastOneAttendanceNotMarked = !!this.rows.find(r => !r.attended);
				const countMarkedPresentWithNoSurvey = this.rows.filter(r => r.attended && r.attended == 'Present' && r.survey == 'No').length;
				if (atleastOneAttendanceNotMarked) await this.ds.dialogService.showMessage('All attendance must be provided before surveys can be sent.', 300, 140, 'Okay')
				else if (countMarkedPresentWithNoSurvey) {
					const sendMsg = `An email with a link to the survey will be sent to the ${countMarkedPresentWithNoSurvey == 1 ? '1 Entrepreneur' : `${countMarkedPresentWithNoSurvey} Entrepreneurs`} that have been marked present, but haven't submitted it yet.\n\n This survey will close on ${this.util.date.formatUTC(this.dialog.data.event.toolEndUTC, 'MMM D, YYYY (DOW)', 'H:MM AM EST', this.ds.languageId)}`;

					const send = await this.ds.dialogService.confirm(sendMsg, 400, 210, 'Send it', 'Cancel');

					if (send) {
						const emailSent = await this.accAreaService.curriculum.actions.sendEmail({ accSessionId: this.dialog.data.accSessionId });

						if (emailSent) {
							await this.ds.dialogService.showMessage(`Survey email has been sent.`, 250, 120, 'Okay');
							this.dialogRef.close(this.updateAction);
						}
					}
				}
				else await this.ds.dialogService.showMessage('All entrepreneurs have already filled out the survey.', 450, 150, 'Okay');

			}
		}
	}
}
