import { AccAreaService, AccCohortPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { GridColumnType, GridSetup } from '@me-grid';
import { AccTeam, AgreementTypeId, DbConceptName } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { Icon } from '@me-shared-parts/UI-common';
import { combineLatest, mergeMap } from 'rxjs';


const EXPLORE_ACCTEAM_ACTION_KEY = 'explore-accTeam';
const OPEN_TEAM_ACTION_KEY = 'open-team';

interface MentorRow {
	accTeamMemberId: number,
	accTeamId: number,
	personId: number,
	teamName: string,
	entrepreneurs: string,
	teamDroppedOutWeek: number,
	mentorName: string,
	coach: string,
	handbookSigned: string,
	mediaSigned: string,
	quit: string,
}


@Component({
	selector: 'acc-cohort-matched-mentors-view-part',
	templateUrl: './acc-cohort-matched-mentors-view.part.html',
})
export class AccCohortMatchedMentorsViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();
	quarterlyEnabledStr: string;

	rows$ = combineLatest([
		this.accAreaService.teams.teams$,
	]).pipe(mergeMap(data => this.buildRows(data[0])));

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private accAreaService: AccAreaService,
		private pageService: AccCohortPageService,
		private ds: DataService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}


	async buildRows(
		accTeams: readonly AccTeam[],
	): Promise<MentorRow[]> {

		if (!accTeams) return [];

		const rows: MentorRow[] = [];

		const agreementTypes = await this.ds.admin.agreementType.getAllAsArray();
		const latestMentorHandbookVersionId = agreementTypes.find(agreementType => agreementType.agreementTypeId == AgreementTypeId.MentorHandbook).latestAgreementVersionId;
		const handbookVersionIds = (await this.ds.admin.agreementVersion.getArrayByForeignId('agreementTypeId', AgreementTypeId.MentorHandbook)).filter(v => v.agreementVersionId == latestMentorHandbookVersionId).map(version => version.agreementVersionId);

		const latestMediaConsentVersionId = agreementTypes.find(agreementType => agreementType.agreementTypeId == AgreementTypeId.MediaConsent).latestAgreementVersionId;
		const mediaVersionIds = (await this.ds.admin.agreementVersion.getArrayByForeignId('agreementTypeId', AgreementTypeId.MediaConsent)).filter(v => v.agreementVersionId == latestMediaConsentVersionId).map(version => version.agreementVersionId);

		const signatures = await this.ds.admin.agreementSignature.getAllAsArray();
		const mentorSignatures = signatures.filter(signature => handbookVersionIds.includes(signature.agreementVersionId));
		const mediaSignatures = signatures.filter(signature => mediaVersionIds.includes(signature.agreementVersionId));


		for (const accTeam of accTeams) {
			const entrepreneurs = accTeam.members
				.filter(m => m.member.role === 'E')
				.sort((a, b) => a.member.accTeamMemberId - b.member.accTeamMemberId);

			for (const member of accTeam.members) {
				if (member.member.role !== 'E') {
					rows.push({
						accTeamMemberId: member.member.accTeamMemberId,
						accTeamId: accTeam.accTeamId,
						personId: member.member.personId,
						teamName: accTeam.application.companyName,
						entrepreneurs: entrepreneurs.map(m => m.person._name).join(', '),
						teamDroppedOutWeek: accTeam.droppedOutWeek,
						mentorName: member.person._name,
						coach: member.member.role === 'C' ? 'Yes' : 'No',
						handbookSigned: mentorSignatures.find(signature => signature.personId == member.member.personId) ? 'Signed' : '',
						mediaSigned: mediaSignatures.find(signature => signature.personId == member.member.personId) ? 'Signed' : '',
						quit: member.member.role === 'X' ? 'Yes' : 'No',
					})
				}
			}
		}

		return rows;
	}


	private setupGrid(): GridSetup<MentorRow> {

		const mentorQuit = (row: MentorRow) => {
			return row.quit === 'Yes' ? 'Mentor Quit!' : undefined;
		};
		const mentorQuitFlag = (row: MentorRow) => {
			return row.quit === 'Yes' ? true : false;
		};
		const dropoutWeek = (row: MentorRow) => {
			if (row.teamDroppedOutWeek) return `Dropped Out Week ${row.teamDroppedOutWeek}`;
			else return undefined;
		};
		const dropoutWeekFlag = (row: MentorRow) => {
			if (row.teamDroppedOutWeek) return true;
			else return false;
		};

		return {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Mentor",
			rowPluralName: "Mentors",
			rowKey: "accTeamMemberId",
			stateKey: "adm-acc-cohort-matched-mentors-page",
			canAdd: false,
			canRefresh: true,
			canDownload: true,
			columnsToAdd: [
				{ field: "accTeamMemberId", header: "accTeamMemberId", width: 140, type: GridColumnType.number, hidden: true },
				{ field: "quit", header: "Quit", width: 100, type: GridColumnType.text, hidden: true },
				{ field: "teamDroppedOutWeek", header: "Team Dropped Out Week", width: 165, type: GridColumnType.number, hidden: true },
				{ field: "PERSON_name", header: "Mentor", width: 200, type: GridColumnType.text, strikethrough: mentorQuitFlag, tooltip: mentorQuit },
				{ field: "teamName", header: "Team", width: 220, type: GridColumnType.text, strikethrough: dropoutWeekFlag, tooltip: dropoutWeek },
				{ field: "entrepreneurs", header: "Entrepreneurs", width: 240, type: GridColumnType.text, strikethrough: dropoutWeekFlag, tooltip: dropoutWeek },
				{ field: "coach", header: "Coach", width: 100, type: GridColumnType.text },
				{ field: "handbookSigned", header: "Handbook", width: 100, type: GridColumnType.text, headerTooltip: 'Signed Mentor Handbook', hidden: true },
				{ field: "mediaSigned", header: "Media Consent", width: 100, type: GridColumnType.text, headerTooltip: 'Signed Media Consent', hidden: true },

			],
			actions: [
				{ key: EXPLORE_ACCTEAM_ACTION_KEY, icon: Icon.concept_company, label: 'Explore Team', enabled: false },
				{ key: OPEN_TEAM_ACTION_KEY, icon: Icon.concept_accTeam, label: 'Open Team', enabled: false },
			],
			initialState: {
				sort: [{ field: 'PERSON_name', dir: 'asc' }],
			},
		};
	}


	async gridActionHandler(action: { actionKey: string, rows: MentorRow[] }) {

		if (action.actionKey == EXPLORE_ACCTEAM_ACTION_KEY) {
			await this.ds.explorer.explore(DbConceptName.AccTeam, action.rows[0].accTeamId);
		}
		else if (action.actionKey == OPEN_TEAM_ACTION_KEY) {
			this.router.navigate([action.rows[0].accTeamId], { relativeTo: this.route });
		}
	}
}