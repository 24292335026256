import { UtilityService } from "@me-services/core/utility";
import { calcTopicSurveyCounts } from "./calc-topic-survey-counts";
import { SurveyCountsData } from "./get-survey-counts-data";
import { CurriculumSurveySessionCounts } from "./interfaces";


/**
 * Calculate a rollup of survey information for a particular session's data
 */
export function calcSessionSurveyCounts(
	util: UtilityService,
	data: SurveyCountsData,
) {

	const topicIds = util.array.cleanNumericIds(data.topicSurveys.map(s => s.topicId));
	const topicCounts = topicIds.map(topicId => calcTopicSurveyCounts(
		util,
		topicId,
		data.topicByTopicId[topicId].shortNameLabel,
		data.topicSurveysByTopicId[topicId],
		data,
	));


	const surveyCounts: CurriculumSurveySessionCounts = {
		date: data.eventName,
		attendees: data.attendeesPresent,
		surveysCompleted: data.sessionSurveys.length,
		accessibilityScore: util.nps.calcScore(data.sessionSurveys.map(s => s.accessibility)),
		communicationScore: util.nps.calcScore(data.sessionSurveys.map(s => s.communication)),
		organizationScore: util.nps.calcScore(data.sessionSurveys.map(s => s.organization)),
		comments: data.sessionSurveys
			.filter(s => s.other?.trim().length > 0)
			.map(s => {
				return {
					comment: s.other.trim(),
					entrepreneurName: data.attendeeByAttendeeId[s.accSessionAttendeeId]?.person.fullName ?? 'MISSING ATTENDEE',
					personId: data.attendeeByAttendeeId[s.accSessionAttendeeId]?.person.personId,
				}
			}),
		topics: topicCounts,
	}


	return surveyCounts;
}
