import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import {
	CompanyBasicInfoEditorPart,
	CompanyDescriptionEditorPart,
	CompanyEditorsPart,
	CompanyProfileEditorPart,
	EntityAddressEditorPart,
	EntityEditorDialog,
	EntityNotesEditNotePart,
	EntityNotesEditorPart,
	EntityNotesOverviewPart,
	EntityOnlineEditorPart,
	EventButtonPart,
	EventDialog,
	EventDialogEditPart,
	EventDialogViewPart,
	EventIconPart,
	EventTablePart,
	EventAttendeesPart,
	EventAttendeeDialog,
	EventAttendeeEditPart,
	OptOutDemographicsDialog,
	PersonBasicInfoEditorPart,
	PersonDemographicsEditorPart,
	PersonDoNotContactEditorPart,
	PersonEditorsPart,
	PersonEmailsEditorPart,
	PersonLanguagesEditorPart,
	PersonPermissionsEditorPart,
	PersonProfileEditorPart,
	PublicEntityAddressEditorPart,
	PublicEntityOnlineEditorPart,
	VenueAddPart,
	VenueEditPart,
	VenueEventsPart,
	VenueButtonPart,
	VenueChoosePart,
	VenueDialog
} from '@me-shared-parts/ED-editors';
import { SharedPersonModule } from '@me-shared-parts/PN-person/PN.module';
import { SharedCommonModule } from '@me-shared-parts/UI-common/UI.module';
import { SharedProgramModule } from '@me-shared-parts/PG-program/PG.module';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';

@NgModule({
	imports: [
		CommonModule,
		DateInputsModule,
		DropDownsModule,
		FormsModule,
		FlexLayoutModule,
		GridModule,
		InputsModule,
		MatCheckboxModule,
		MatDialogModule,
		MatInputModule,
		MatTableModule,
		PopupModule,
		ReactiveFormsModule,
		SharedCommonModule,
		SharedPersonModule,
		SharedProgramModule,
		TabStripModule,
	],
	declarations: [
		CompanyBasicInfoEditorPart,
		CompanyDescriptionEditorPart,
		CompanyEditorsPart,
		CompanyProfileEditorPart,
		EntityAddressEditorPart,
		EntityEditorDialog,
		EntityNotesEditNotePart,
		EntityNotesEditorPart,
		EntityNotesOverviewPart,
		EntityOnlineEditorPart,
		EventButtonPart,
		EventDialog,
		EventDialogEditPart,
		EventDialogViewPart,
		EventIconPart,
		EventTablePart,
		EventAttendeesPart,
		EventAttendeeDialog,
		EventAttendeeEditPart,
		OptOutDemographicsDialog,
		PersonBasicInfoEditorPart,
		PersonDemographicsEditorPart,
		PersonDoNotContactEditorPart,
		PersonEditorsPart,
		PersonEmailsEditorPart,
		PersonLanguagesEditorPart,
		PersonPermissionsEditorPart,
		PersonProfileEditorPart,
		PublicEntityAddressEditorPart,
		PublicEntityOnlineEditorPart,
		VenueAddPart,
		VenueEditPart,
		VenueEventsPart,
		VenueButtonPart,
		VenueChoosePart,
		VenueDialog,
	],
	exports: [
		CompanyBasicInfoEditorPart,
		CompanyDescriptionEditorPart,
		CompanyEditorsPart,
		CompanyProfileEditorPart,
		EntityAddressEditorPart,
		EntityEditorDialog,
		EntityNotesEditNotePart,
		EntityNotesEditorPart,
		EntityNotesOverviewPart,
		EntityOnlineEditorPart,
		EventButtonPart,
		EventDialog,
		EventDialogEditPart,
		EventDialogViewPart,
		EventIconPart,
		EventTablePart,
		EventAttendeesPart,
		EventAttendeeDialog,
		EventAttendeeEditPart,
		OptOutDemographicsDialog,
		PersonBasicInfoEditorPart,
		PersonDemographicsEditorPart,
		PersonDoNotContactEditorPart,
		PersonEditorsPart,
		PersonEmailsEditorPart,
		PersonLanguagesEditorPart,
		PersonPermissionsEditorPart,
		PersonProfileEditorPart,
		PublicEntityAddressEditorPart,
		PublicEntityOnlineEditorPart,
		VenueAddPart,
		VenueEditPart,
		VenueEventsPart,
		VenueButtonPart,
		VenueChoosePart,
		VenueDialog,
	],
})

export class SharedEditorsModule { }