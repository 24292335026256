import { AccAreaService, AccCohortPageService, SessionAttendanceDialog } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ADDROW_GRID_ACTION_KEY, DBLCLICK_GRID_ACTION_KEY, GridAction, GridColumn, GridColumnType, GridSetup } from '@me-grid';
import { AccStageId, Accelerator, DbsEvent, EventContext, EventTypeId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogService } from '@me-services/ui/dialog';
import { UrlService } from '@me-services/ui/url';
import { ShowEventDialogService } from '@me-shared-parts/ED-editors';
import { Icon } from '@me-shared-parts/UI-common';


const LEGACY_SCHEDULE_TEMPLATE_IDS = [1, 2, 3, 4, 5, 6];
interface SessionRow {
	accSessionId: number,
	eventId: number,
	event: DbsEvent,
	venue: string,
	topicNames: string,
	sessionStartUTC: number,
	sessionName: string,
	toolStartUTC: number,
	toolEndUTC: number,
	specialists: string,
	teamAttendancePercent: number,
	surveyCompletionPercentage: number,
	sessionSurveyResults: number,
	sessionSurveyAccessibilityScore: number,
	sessionSurveyCommunicationScore: number,
	sessionSurveyOrganizationScore: number,
}


@Component({
	selector: 'acc-curriculum-sessions-view-part',
	templateUrl: './acc-curriculum-sessions-view.part.html',
})
export class AccCurriculumSessionsViewPart extends DestroyablePart implements OnInit {
	readonly: boolean;
	public gridSetup = this.setupGrid();

	public rows: SessionRow[];
	eventContext: EventContext;
	acc: Accelerator;

	constructor(
		private accAreaService: AccAreaService,
		public ds: DataService,
		public urlService: UrlService,
		private util: UtilityService,
		private showEventDialogService: ShowEventDialogService,
		private dialogService: DialogService,
		public pageService: AccCohortPageService,) {
		super();
	}

	async ngOnInit(): Promise<void> {
		super.initDestroyable();



		super.subscribe([
			this.accAreaService.access$,
			this.accAreaService.data$,
		], async ([access, data]) => {

			this.readonly = access?.root != 'Write';

			if (!data) return;

			const acc = data.acc;
			const teams = data.team.teams;
			const sessions = data.curriculum.sessions;
			const sessionTopics = data.curriculum.sessionTopics;
			const accTopics = data.curriculum.accTopics;
			const topicSpecialists = data.curriculum.topicSpecialists;
			const sessionAttendees = data.curriculum.sessionAttendees;

			this.acc = acc;

			this.eventContext = {
				eventTypeId: EventTypeId.AccSession,
				languageId: this.acc.siteProgram.program.languageId,
				accId: this.acc.accId,
				siteId: this.acc.siteId,
				siteProgramId: this.acc.siteProgramId,
			};


			const eventIds = sessions.map(session => session.eventId);
			const eventMap = await this.ds.admin.event.getManyAsMap(eventIds);
			const venueMap = await this.ds.admin.venue.getAllAsMap();

			const ddTopics = this.ds.domain.topic.getAll();
			const ddTopicMap = this.util.array.toMap(ddTopics, topic => topic.topicId);

			const sessionSurveyResultsByEventId = await this.ds.admin.accSessionSurveyResponse.getByEventIds(eventIds);

			const teamsNotDroppedOut = teams.filter(team => !team.droppedOutWeek || (team.droppedOutWeek && team.droppedOutWeek > 12));

			this.rows = sessions.map(session => {

				//
				// Event and Venue
				//
				const venueId = eventMap[session.eventId].venueId;


				//
				// Get the attendee records for this session
				//
				const attendees = sessionAttendees.filter(a => a.attendee.accSessionId == session.accSessionId);
				const attendeesPresent = attendees.filter(a => a.attendee.status == 'Present');


				//
				// Get the list of accTeamIds for this session. At a minimum, we include all teams that haven't dropped out.
				// Next, we include accTeamsIds of attendee records. This will bump up the count for past sessions when a team
				// drops out later. If there is an attendance record for a dropped out team then they hadn't dropped out yet.
				//
				const accTeamIds = this.util.array.cleanNumericIds([
					...teamsNotDroppedOut.map(team => team.accTeamId),
					...attendees.map(a => a.accTeam.accTeamId),
				]);

				const accTeamIdsPresent = this.util.array.cleanNumericIds([
					...attendeesPresent.map(a => a.accTeam.accTeamId)
				]);


				//
				// If we have at least one attendee record, either Absent or Presnet, then we will calculate a percentage of
				// the teams that had at least one entrepreneur present for the session.
				//
				let teamAttendancePercent: number = null;
				if (attendees.length) teamAttendancePercent = accTeamIds.length == 0 ? 0 : Math.round(accTeamIdsPresent.length / accTeamIds.length * 100);


				//
				// Topics and Specialists
				//
				const accSessionTopics = sessionTopics.filter(sessionTopic => sessionTopic.accSessionId == session.accSessionId);
				const accTopicIds = accSessionTopics.map(topic => topic.accTopicId);

				const topicIds = accTopics.filter(topic => accTopicIds.includes(topic.accTopicId)).map(topic => topic.topicId);
				const needsSpecialist = !!topicIds.map(topicId => ddTopicMap[topicId]).find(topic => topic.needsSpecialist);

				let specialistNames = 'n/a';
				if (needsSpecialist) {
					specialistNames = topicSpecialists.filter(ts => accTopicIds.includes(ts.topicSpecialist.accTopicId)).map(ts => ts.person.fullName).sort((a, b) => a > b ? 1 : -1).join(', ');
				}


				//
				// Survey results
				//
				const countOfAttendeesThatCompletedSurvey = attendees.filter(attendee => !!attendee.accSessionSurveyResponse).length;

				let surveyCompletionPercentage = null;
				if (attendees.length) surveyCompletionPercentage = attendeesPresent.length ? countOfAttendeesThatCompletedSurvey * 100 / attendeesPresent.length : 0;

				const sessionSurveyResults = sessionSurveyResultsByEventId[session.eventId] ?? [];



				return {
					eventId: session.eventId,
					event: eventMap[session.eventId],
					accSessionId: session.accSessionId,
					toolStartUTC: eventMap[session.eventId].toolStartUTC,
					toolEndUTC: eventMap[session.eventId].toolEndUTC,
					venue: venueId ? venueMap[venueId].displayedName : 'Online',
					topicNames: topicIds.length ? ddTopics.filter(topic => topicIds.includes(topic.topicId)).map(topic => topic.shortNameLabel).sort((a, b) => a > b ? 1 : -1).join(', ') : '',
					sessionStartUTC: eventMap[session.eventId].startUTC,
					sessionName: this.util.date.formatUTC(eventMap[session.eventId].startUTC, 'MMM D, YYYY (DOW)', 'H:MM AM EST', this.urlService.languageId),
					specialists: specialistNames,
					teamAttendancePercent,
					surveyCompletionPercentage,

					sessionSurveyResults: sessionSurveyResults.length || null,
					sessionSurveyAccessibilityScore: this.util.nps.calcScore(sessionSurveyResults.map(result => result.accessibility)),
					sessionSurveyCommunicationScore: this.util.nps.calcScore(sessionSurveyResults.map(result => result.communication)),
					sessionSurveyOrganizationScore: this.util.nps.calcScore(sessionSurveyResults.map(result => result.organization)),
				};

			});

			this.gridSetup.canAdd = !this.readonly && !this.rows.length;

		});

	}

	private setupGrid(): GridSetup<SessionRow> {

		const setup: GridSetup<SessionRow> = {
			experience: 'none',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Session",
			rowPluralName: "Sessions",
			rowKey: "accSessionId",
			stateKey: "acc-curriculum-sessions-view-part",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: "accSessionId", header: "accSessionId", width: 110, type: GridColumnType.number, hidden: true },
				{ field: "eventId", header: "eventId", width: 100, type: GridColumnType.number, hidden: true },
				<GridColumn<SessionRow, Date>>{ field: "sessionStartUTC", header: "Session", width: 180, type: GridColumnType.dateAndTimeUtc, render: d => this.util.date.formatDateAndTime(d, 'MMM D, YYYY (DOW)', this.urlService.languageId) },
				{ field: "toolStartUTC", header: "Survey Start", width: 180, type: GridColumnType.dateAndTimeUtc, hidden: true },
				{ field: "toolEndUTC", header: "Survey End", width: 180, type: GridColumnType.dateAndTimeUtc, hidden: true },
				{ field: "venue", header: "Venue", width: 100, type: GridColumnType.text },
				{ field: "topicNames", header: "Topics", width: 160, type: GridColumnType.text },
				{ field: "specialists", header: "Specialists", width: 140, type: GridColumnType.text },
				{ field: "teamAttendancePercent", header: "Team Attendance", headerTooltip: 'The percentage of teams that had at least one entrepreneur present for this session', width: 80, type: GridColumnType.percent },
				{ field: "surveyCompletionPercentage", header: "Surveys", headerTooltip: 'The progress bar shows the count of submitted entrepreneur surveys compared to the total expected', width: 75, type: GridColumnType.progressBar, progressBarThresholds: [100, 100] },
				{ field: "sessionSurveyResults", header: "# Surveys", headerTooltip: 'Session Surveys Taken', width: 70, type: GridColumnType.number },
				{ field: "sessionSurveyAccessibilityScore", header: "Accessibility Score", headerTooltip: 'Accessibility Score [0-100] Asked: "I was able to easily attend this session"', width: 70, type: GridColumnType.number, hidden: true },
				{ field: "sessionSurveyCommunicationScore", header: "Communication Score", headerTooltip: 'Communication Score [0-100] Asked: "I received all the information I needed ahead of the session"', width: 70, type: GridColumnType.number, hidden: true },
				{ field: "sessionSurveyOrganizationScore", header: "Organization Score", headerTooltip: 'Organization Score [0-100] Asked: "The session was well facilitated by EforAll staff"', width: 70, type: GridColumnType.number, hidden: true },
			],
			actions: [
				{ key: 'edit-attendance', icon: Icon.action_edit, label: 'Edit Attendance', enabled: false },
				{ key: 'open-event', icon: Icon.concept_event, label: 'Open Event', enabled: false },
				{ key: 'open-tool-link', icon: Icon.tool_enabled, label: 'Get Tool Link', enabled: false },
			],
			actionEnabler: this.gridActionEnabler.bind(this),

		};

		return setup;
	}

	gridActionEnabler(action: GridAction, rows: SessionRow[]) {
		if (rows.length == 0) return false;

		return true;
	}

	async gridActionHandler(action: { actionKey: string, rows: SessionRow[] }) {
		const row = action.rows[0];

		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {

			if (this.readonly) await this.ds.dialogService.showMessage(`You are not authorized to add sessions.`);
			else if (this.rows.length) await this.ds.dialogService.showMessage(`The sessions are already added.`);
			else if (LEGACY_SCHEDULE_TEMPLATE_IDS.includes(this.acc.scheduleTemplateId)) await this.ds.dialogService.showMessage(`This accelerator pre-dates the curriculum functionality. Sessions may not be added because the feature is not supported with older accelerators.`);
			else if (this.acc.canceled) await this.ds.dialogService.showMessage(`Cannot add sessions because the accelerator is marked Canceled.`);
			else if (![AccStageId.MatchingMentors, AccStageId.Curriculum].includes(this.acc.accStageId)) await this.ds.dialogService.showMessage(`Cannot add sessions because the accelerator is not in Mentor Matching or Curriculum stage.`);
			else {
				const yes = await this.dialogService.confirm(`The Entrepreneur Experience Team maintains a template of sessions for each year/season. Would you like to apply it to this accelerator?`, 300, 200, 'Yes', 'No');
				if (yes) await this.accAreaService.curriculum.actions.addSessions();
			}
		}

		if (action.actionKey == 'edit-attendance' || action.actionKey == DBLCLICK_GRID_ACTION_KEY) {

			const eventStatus = this.util.event.getStatus(row.event);


			const action: DialogAction<boolean> = await this.ds.dialogService.showCustom(
				SessionAttendanceDialog,
				{
					data: {
						readonly: this.readonly || eventStatus == 'Complete',
						accSessionId: row.accSessionId,
						sessionName: row.sessionName,
						topicNames: row.topicNames,
						specialistNames: row.specialists,
						event: row.event,
					},
				},
				990, 550
			);


		}

		else if (action.actionKey == 'open-event') {
			await this.showEventDialogService.edit(row.event);
		}

		else if (action.actionKey == 'open-tool-link') {
			const url = `${this.urlService.getBaseUrl(this.acc.siteProgram.program.languageId)}/access/survey/acc-session/${row.accSessionId}`;
			await this.ds.dialogService.showMessage(`The link to the survey for the ${row.sessionName} Session:\n\n${url}\n\n This link is also included in the survey email.`, 420, 260);
		}

	}

}