import { Injectable } from '@angular/core';
import { ErrorPageDetails } from '@me-interfaces';
import { AuthService } from '@me-services/core/auth';
import { UtilityService } from '@me-services/core/utility';
import * as Sentry from "@sentry/browser";
import { BehaviorSubject, Observable } from 'rxjs';

const NO_ERROR: ErrorPageDetails = { error: false };

@Injectable({ providedIn: 'root' })
export class ErrorPageService {

	private readonly details = new BehaviorSubject<ErrorPageDetails>(NO_ERROR);
	public readonly details$: Observable<ErrorPageDetails>;
	public snapshot = NO_ERROR;

	constructor(
		private auth: AuthService,
		private util: UtilityService,
	) {
		this.details$ = this.details.asObservable();
	}

	setError(
		activity: string,
		error: Error | string,
		errorName: string,
		path: string,
		payload: any,
		appDeviceUuid: string,
		appInstanceUuid: string,
		appInstanceCallIndex: number,
	) {

		Sentry.setTag("e4a_error_page", "yes");
		Sentry.showReportDialog();

		if (error instanceof Error) {
			this.util.log.fatalError('Red Error Page Shown to User!', error);
		}
		else {
			if (error !== 'Error: No Verified Email Address') {
				this.util.log.fatalError('Red Error Page Shown to User', new Error(error));
			}
		}


		const d: ErrorPageDetails = {
			error: true,
			activity,
			name: '',
			message: '',
			stack: '',
			path,
			payload,
			appDeviceUuid,
			appInstanceUuid,
			appInstanceCallIndex,
		};

		if (error instanceof Error) {
			d.name = errorName || error.name || 'Error';
			d.message = error.message || `Unexpected Error. See the console log`;
			d.stack = error.stack || '';
		}
		else {
			d.name = errorName || 'Error';
			d.message = error || `Unexpected Error! See the console log`;
		}

		this.auth.logErrorToAnalytics(d);
		this.details.next(d);
		this.snapshot = d;
	}

	clearError() {
		this.details.next(NO_ERROR);
		this.snapshot = NO_ERROR;
	}
}