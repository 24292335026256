import { Accelerator, DbConceptName } from "@me-interfaces";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForMentors(ds: DataService, accelerator: Accelerator): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const accTeamsByAccId = await ds.admin.accTeam.getByAccIds([accelerator.accId]);
	const accTeams = accTeamsByAccId[accelerator.accId];


	for (const accTeam of accTeams) {
		const memberText = accTeam.members.filter(m => m.member.role == 'E')
			.map(member => `${member.person.firstName} ${member.person.lastName.slice(0, 1)}.`)
			.sort((a, b) => a > b ? 1 : -1)
			.join(', ');

		for (const member of accTeam.members.filter(member => member.member.role != 'E')) {
			const header = member.member.role == 'C' ? 'Coach' : 'Mentor';
			const notes = [];
			if (accTeam.droppedOutWeek) notes.push(`Team Dropped Out Week: ${accTeam.droppedOutWeek}`);
			if (member.member.role == 'X') notes.push(`Mentor Quit`);

			const box: ExplorerBox = {
				explorerBoxClass: ExplorerBoxClass.Cohort,
				header,
				buttons: [
					{ explorer: { nameOverride: member.person._name, explorableId: { conceptName: DbConceptName.Person, id: member.member.personId }, navTooltip: `${header}: ${member.person._name}` } },
					{ explorer: { nameOverride: `${memberText} & Team`, explorableId: { conceptName: DbConceptName.AccTeam, id: accTeam.accTeamId }, navTooltip: `Team: ${memberText} & Team` } },
					{ explorer: { nameOverride: accTeam.name, explorableId: { conceptName: DbConceptName.Company, id: accTeam.application.companyId }, navTooltip: `Company: ${accTeam.name}` } },
				],
				notes: notes.join(', '),
			};

			boxes.push(box);
		}
	}

	boxes.sort((a, b) => a.buttons[0].explorer.nameOverride.toLowerCase() > b.buttons[0].explorer.nameOverride.toLowerCase() ? 1 : -1);
	return boxes;
}