import { ChangeDetectorRef, Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FuncService } from '@me-services/core/func';
import { from, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UtilityService } from '../utility';

@Injectable({ providedIn: 'root' })
export class ConstantsService {

	constructor(
		private util: UtilityService,
		private func: FuncService,
	) {
	}

	readonly EMAIL_REGEX = /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9][A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/;
	readonly ZIP_REGEX = /^\d{5}$/;
	readonly WEBSITE_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

	readonly blur = {

		fixCaseOfValue: (ctrl: AbstractControl) => {
			const value = ctrl.value ? this.util.text.fixCase(ctrl.value.trim()) : ctrl.value;
			if (value !== ctrl.value) ctrl.patchValue(value);
		},

		lowerCaseOfValue: (ctrl: AbstractControl) => {
			const value = ctrl.value ? ctrl.value.trim().toLowerCase() : ctrl.value;
			if (value !== ctrl.value) ctrl.patchValue(value);
		},

		fixPhoneValue: (ctrl: AbstractControl, telInput) => {
			if (!ctrl.value) return;
			if (!telInput.isValidNumber()) return;
			const value: string = telInput.getNumber();
			ctrl.patchValue(value);
			return value;
		},

		fixWebsiteValue: (ctrl: AbstractControl, useHttps = false) => {
			if (ctrl.errors) return;
			let v = ctrl.value.trim();
			const vLower = v.toLowerCase();

			if (vLower == 'http://') v = '';
			if (vLower == 'https://') v = '';

			if (v.length == 0 || vLower.indexOf('http://') == 0 || v.indexOf('https://') == 0) {
				ctrl.patchValue(v);
			}
			else {
				ctrl.patchValue((useHttps ? 'https://' : 'http://') + v);
			}
		},

		trim: (ctrl: AbstractControl) => {
			if (ctrl.errors) return;
			if (ctrl.value) {
				ctrl.patchValue(ctrl.value.trim());
			}
		}
	};

	validators = {
		validateZip: (zip: { city: string, code: string, latitude: number, longitude: number }, cd: ChangeDetectorRef,) => {
			return (ctrl: AbstractControl): Observable<ValidationErrors | null> => {
				zip.city = undefined;
				zip.code = undefined;
				zip.latitude = undefined;
				zip.longitude = undefined;
				if (ctrl.value.trim() == '') return of(null);

				return from(this.func.public.check.getZipData({ zipcode: ctrl.value }))
					.pipe(
						tap(_ => { cd.markForCheck(); }),
						map(response => {
							if (response.valid) {
								zip.city = response.name;
								zip.code = this.format.zipCode(response.zip);
								zip.latitude = response.latitude;
								zip.longitude = response.longitude;
								return null;
							}
							else return { badzip: true };
						})
					);
			}
		},

		validateEmailNotInUse: func => {
			return (ctrl: AbstractControl): Observable<ValidationErrors | null> => {

				return from(this.func.public.check.getEmailUsage({ email: ctrl.value }))
					.pipe(
						map(response => {
							return response.used ? { emailInUse: true } : null;
						})
					);
			}
		},
		validatePhone: (telInput): ValidatorFn => {
			return (control: AbstractControl): ValidationErrors | null => {
				if (telInput.getNumber()) {
					if (!telInput.isValidNumber()) {
						return { invalid: true };
					}
				}
				return null;
			};
		},
		validatePhoneNotInUse: (telInput, func, cd: ChangeDetectorRef, currentPersonId) => {
			return (ctrl: AbstractControl): Observable<ValidationErrors | null> => {

				if (telInput == undefined) return of(null);
				if (!telInput.isValidNumber()) return of(null);

				const phone = telInput.getNumber();

				return from(this.func.public.check.getPhoneUsage({ phone }))
					.pipe(
						tap(_ => { cd.markForCheck(); }),
						map(response => {
							if (currentPersonId) {
								return response.used && response.personId !== currentPersonId ? { phoneInUse: true } : null;
							}
							else {
								return response.used ? { phoneInUse: true } : null;
							}
						})
					);
			}
		},
		validateSpecificWebsite: (expected: string, https = true) => {
			return (ctrl: AbstractControl): ValidationErrors | null => {
				const v = ctrl.value;
				if (v) {
					const protocol = https ? 'https://' : 'http://';
					if (v.indexOf(protocol) == 0 && v.indexOf(expected) > 0) return null;
					return { specificWebsite: true };
				}
				else return null;
			}
		},
	};

	format = {
		zipCode: (zip: number) => {
			return ("00000" + zip).slice(-5);
		}
	};
}