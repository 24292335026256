<h5 mat-dialog-title>Add a Company</h5>

<mat-dialog-content>
	To add a new company, search for or add a new person and add a position. If the company doesn't already exist, you will be
	given the option to add it.

	<button-container>
		<button class='btn' routerLink="/access/contacts/people" mat-dialog-close>Find Person</button>
		<button class='btn' routerLink="/access/contacts/new-person" mat-dialog-close>Add Person</button>
	</button-container>
</mat-dialog-content>