<div class='person-do-not-contact-editor-part'>
	<!-- <me-save-changes-message *ngIf='messagedisplay' [hidden]="messagedisplay.disabled"></me-save-changes-message> -->
	<div class='person-do-not-contact-editor-content'>
		<me-h3 *ngIf='!person.doNotContactNote'>Do not contact flag is <strong>OFF</strong></me-h3>
		<me-h3 *ngIf='person.doNotContactNote'>Do not contact flag is <strong>ON</strong></me-h3>
		<table class='pro-table pro-table-no-header'>
			<tr>
				<td>
					<textarea kendoTextArea placeholder="Enter notes" [value]='newValue' (input)='onInput($event)'
						rows='3'></textarea>
				</td>
			</tr>
		</table>
		<button-container>
			<ng-container *ngIf='person.doNotContactNote'>
				<button class='btn' *ngIf='newValue.length' [disabled]='newValue === orgValue' (click)='save()'>Update
					Note</button>
				<button *ngIf='newValue === orgValue || !newValue.length' (click)='delete()' class='btn'>Disable "Do Not Contact" flag and
					delete note</button>
				<button *ngIf='newValue !== orgValue' (click)='revert()' class='btn'>Revert</button>
			</ng-container>
			<ng-container *ngIf='!person.doNotContactNote'>
				<button class='btn' (click)='save()' [disabled]='newValue === orgValue'>Enable "Do Not Contact" flag and
					save note</button>
			</ng-container>
		</button-container>
	</div>
</div>