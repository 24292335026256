import { Injectable } from '@angular/core';
import { PageTabsLayoutPageService } from '@me-shared-parts/UI-common';
import { BehaviorSubject } from 'rxjs';


/**
 * The list of tabs on this page
 */
export enum NationalDashboardTabId {
	National = 1,
	News = 2,
}

/**
 * The list if main tab droplist views on this page
 */
export enum NationalDashboardViewId {
	Indicators = 1,
	Regions = 2,
	Sites = 3,
	Businesses = 4,
	Entrepreneurs = 5,
	Awards = 6,
	Events = 7,

	Accs = 10,
	AccApplications = 11,
	AccTeams = 12,
	AccReaders = 13,
	AccInterviewers = 14,
	AccMentors = 15,
	AccJudges = 16,

	Pics = 20,
	PicApplications = 21,
	PicTeams = 22,
	PicReaders = 23,
	PicJudges = 24,

	ReportingLooker = 30,
	ReportingCharts = 31,
}


export type ApplicationStatusFilter = 'All' | 'Open' | 'Not Accepted' | 'Accepted';


@Injectable({ providedIn: 'root' })
export class NationalDashboardPageService extends PageTabsLayoutPageService<NationalDashboardTabId, NationalDashboardViewId> {

	public readonly applicationsStatusFilter$ = new BehaviorSubject<ApplicationStatusFilter>('All');

	constructor() {
		super(NationalDashboardPageService.name, NationalDashboardTabId.National, NationalDashboardViewId.Indicators);
	}

}