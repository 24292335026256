<me-dialog-frame [header]='header' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>

	<div class='matching-hint-edit-dialog'>
		<table style='width:100%' class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>

			<tr>
				<td [style.width.px]='100'>Type</td>
				<td>
					<me-droplist [items]='types' style="width: 300px" [selectedItem]="selectedType"
						(selectionChange)='onTypeSelectionChange($event)'>
					</me-droplist>
				</td>
			</tr>
			<ng-container *ngIf="selectedType">
				<tr *ngIf="roleSelected === 'Mentor'">
					<td [style.width.px]='100'>Mentor</td>
					<td>
						<me-droplist [items]='mentors' style="width: 300px" [selectedItem]="selectedRole" sortBy="text"
							(selectionChange)='onSelectionChange($event)'>
						</me-droplist>
					</td>
				</tr>
				<tr *ngIf="roleSelected === 'Team'">
					<td [style.width.px]='100'>Team</td>
					<td>
						<me-droplist [items]='teams' style="width: 300px" [selectedItem]="selectedRole" sortBy="text"
							(selectionChange)='onSelectionChange($event)'>
						</me-droplist>
					</td>
				</tr>

				<tr>
					<td [style.width.px]='100'>Mentor</td>
					<td>
						<me-droplist [items]='mentors' style="width: 300px" [selectedItem]="selectedMentor"
							sortBy="text" (selectionChange)='onMentorSelectionChange($event)'>
						</me-droplist>
					</td>
				</tr>

				<tr>
					<td [style.width.px]='100'>Importance</td>
					<td>
						<me-droplist [items]='importances' style="width: 300px" [selectedItem]="selectedImportance"
							(selectionChange)='onImportanceSelectionChange($event)'>
						</me-droplist>
					</td>
				</tr>
			</ng-container>
		</table>

		<message-line *ngIf='newSelectedValue.kind && newSelectedValue.id1 && newSelectedValue.id2 && newSelectedValue.kind[1]==="+"' [alertType]='hintOverlapsAlertType()'>{{newSelectedValue.overlaps}}
			overlapping meeting{{newSelectedValue.overlaps === 1? '':'s'}}.</message-line>
	</div>
</me-dialog-frame>