import { Dbs, DbsAccelerator, DbsAccInterviewer, DbsAccJudge, DbsAccReader, DbsAccSessionSpecialistSurveyResponse, DbsAccSessionSurveyResponse, DbsAccSessionTopicSurveyResponse, DbsAccTeam, DbsAccTeamMember, DbsAgreementSignature, DbsAgreementType, DbsAgreementVersion, DbsApplication, DbsApplicationParticipant, DbsAward, DbsCompany, DbsCouncilMember, DbsEmail, DbsEntityNote, DbsEvent, DbsPerson, DbsPersonTag, DbsPicJudge, DbsPicReader, DbsPicTeam, DbsPicTeamMember, DbsPitchContest, DbsPosition, DbsProgram, DbsRegion, DbsRelationship, DbsSite, DbsSiteProgram, DbsSpecialist, DbsTag, DbsTagPrefix, DbsVenue, DbsWebLink } from "@me-interfaces";

/**
 * Singleton data for one type of singleton that has been changed at or after a provided updatedUTC value.
 */
export interface SingletonDelta<T extends Dbs> {

	/**
	 * The UTC date that was compared to the singleton's updatedUTC column
	 * to find the list of updated or deleted singletons.
	 */
	asOfUTC: number,

	/**
	 * An array of objects, as of the utc,  of a given singleton type,
	 * which have a deleted column value of 'N'
	 */
	updated: T[],

	/**
	 * An array of ids, as of the utc, for a given singleton type,
	 * which have a deleted column value of 'Y'
	 */
	deletedIds: number[],
}


/**
 * A named set of singletons.
 */
export type SingletonName =
	'accelerators' |
	'accInterviewers' |
	'accJudges' |
	'accReaders' |
	'accSessionSurveyResponses' |
	'accSessionTopicSurveyResponses' |
	'accSessionSpecialistSurveyResponses' |
	'accTeams' |
	'accTeamMembers' |
	'agreementTypes' |
	'agreementVersions' |
	'agreementSignatures' |
	'applications' |
	'applicationParticipants' |
	'awards' |
	'companies' |
	'councilMembers' |
	'emails' |
	'events' |
	'entityNotes' |
	'people' |
	'personTags' |
	'picJudges' |
	'picReaders' |
	'picTeams' |
	'picTeamMembers' |
	'pitchContests' |
	'positions' |
	'programs' |
	'regions' |
	'relationships' |
	'sitePrograms' |
	'sites' |
	'specialists' |
	'tagPrefixes' |
	'tags' |
	'venues' |
	'webLinks'
	;


/**
* A named set of singleton identity columns.
*/
export type SingletonId =
	'accId' |
	'accInterviewerId' |
	'accJudgeId' |
	'accReaderId' |
	'accSessionSurveyResponseId' |
	'accSessionTopicSurveyResponseId' |
	'accSessionSpecialistSurveyResponseId' |
	'accTeamId' |
	'accTeamMemberId' |
	'agreementTypeId' |
	'agreementVersionId' |
	'agreementSignatureId' |
	'applicationId' |
	'applicationParticipantId' |
	'awardId' |
	'companyId' |
	'councilMemberId' |
	'emailId' |
	'eventId' |
	'noteId' |
	'personId' |
	'personTagId' |
	'picId' |
	'picJudgeId' |
	'picReaderId' |
	'picTeamId' |
	'picTeamMemberId' |
	'positionId' |
	'programId' |
	'personPersonId' |
	'regionId' |
	'siteProgramId' |
	'siteId' |
	'specialistId' |
	'tagId' |
	'tagPrefixId' |
	'venueId' |
	'webLinkId'
	;

/**
 * The set of named singletons. The SingletonData and SingletonTimestamps
 * interfaces should have a property for each string in this array, and
 * vice versa.
 * 
 * This MUST match the array of the same name in create-singleton-cache-files.ts
 */
export const singletonNames: SingletonName[] = [
	'accelerators',
	'accInterviewers',
	'accJudges',
	'accReaders',
	'accSessionSurveyResponses',
	'accSessionTopicSurveyResponses',
	'accSessionSpecialistSurveyResponses',
	'accTeams',
	'accTeamMembers',
	'agreementTypes',
	'agreementVersions',
	'agreementSignatures',
	'applications',
	'applicationParticipants',
	'awards',
	'companies',
	'councilMembers',
	'emails',
	'entityNotes',
	'events',
	'people',
	'personTags',
	'picJudges',
	'picReaders',
	'picTeams',
	'picTeamMembers',
	'pitchContests',
	'positions',
	'programs',
	'regions',
	'relationships',
	'sitePrograms',
	'sites',
	'specialists',
	'tagPrefixes',
	'tags',
	'venues',
	'webLinks'
];



/**
 * Database concept objects mapped by id. Singletons are used
 * to avoid redundancy and improve performance because the latest
 * version of each is cached on the client.
 * 
 * The raw data should never contain these types of concept objects
 * directly, but should instead have ids that can be used to map
 * into the singletons data.
 * 
 * Names and order of properties should match SingletonTimestamps and SingletonIds.
 */
export interface SingletonData {
	accelerators?: SingletonDelta<DbsAccelerator>,
	accInterviewers?: SingletonDelta<DbsAccInterviewer>,
	accJudges?: SingletonDelta<DbsAccJudge>,
	accReaders?: SingletonDelta<DbsAccReader>,
	accSessionSurveyResponses?: SingletonDelta<DbsAccSessionSurveyResponse>,
	accSessionTopicSurveyResponses?: SingletonDelta<DbsAccSessionTopicSurveyResponse>,
	accSessionSpecialistSurveyResponses?: SingletonDelta<DbsAccSessionSpecialistSurveyResponse>,
	accTeams?: SingletonDelta<DbsAccTeam>,
	accTeamMembers?: SingletonDelta<DbsAccTeamMember>,
	agreementTypes?: SingletonDelta<DbsAgreementType>,
	agreementVersions?: SingletonDelta<DbsAgreementVersion>,
	agreementSignatures?: SingletonDelta<DbsAgreementSignature>,
	applications?: SingletonDelta<DbsApplication>,
	applicationParticipants?: SingletonDelta<DbsApplicationParticipant>,
	awards?: SingletonDelta<DbsAward>,
	companies?: SingletonDelta<DbsCompany>,
	councilMembers?: SingletonDelta<DbsCouncilMember>,
	emails?: SingletonDelta<DbsEmail>,
	entityNotes?: SingletonDelta<DbsEntityNote>,
	events?: SingletonDelta<DbsEvent>,
	people?: SingletonDelta<DbsPerson>,
	personTags?: SingletonDelta<DbsPersonTag>,
	picJudges?: SingletonDelta<DbsPicJudge>,
	picReaders?: SingletonDelta<DbsPicReader>,
	picTeams?: SingletonDelta<DbsPicTeam>,
	picTeamMembers?: SingletonDelta<DbsPicTeamMember>,
	pitchContests?: SingletonDelta<DbsPitchContest>,
	positions?: SingletonDelta<DbsPosition>,
	programs?: SingletonDelta<DbsProgram>,
	regions?: SingletonDelta<DbsRegion>,
	relationships?: SingletonDelta<DbsRelationship>,
	sitePrograms?: SingletonDelta<DbsSiteProgram>,
	sites?: SingletonDelta<DbsSite>,
	specialists?: SingletonDelta<DbsSpecialist>,
	tagPrefixes?: SingletonDelta<DbsTagPrefix>,
	tags?: SingletonDelta<DbsTag>,
	venues?: SingletonDelta<DbsVenue>,
	webLinks?: SingletonDelta<DbsWebLink>,
}