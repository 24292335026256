<div class='application-viewer-msg' *ngIf='viewerMessage'>

	<message-line alertType='warning' *ngIf='status==="WITHDRAWN"'>
		<me-label key='md: Application was withdrawn msg'>
		</me-label>
	</message-line>

	<message-line alertType='warning' *ngIf='status==="ABANDONED"'>
		<me-label key='md: App canceled & never submitted msg'></me-label>
	</message-line>

	<message-line alertType='warning' *ngIf='status==="WAITING"'>
		<me-label key='This application is under consideration.'></me-label>
	</message-line>

	<message-line alertType='warning' *ngIf='status==="REJECTED"'>
		<me-label key='This application was not accepted.'></me-label>
	</message-line>

	<message-line alertType='success' *ngIf='status==="ACCEPTED"'>
		<me-label key='md: Application was accepted msg'></me-label>
		<a *ngIf="viewerMessage.programTypeId === 1 || viewerMessage.programTypeId === 4" class="link"
			style="color: #007bff;" (click)="openPrograms()">
			<me-label key='Open'></me-label>
		</a>
	</message-line>
</div>