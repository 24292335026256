import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { BehaviorSubject } from 'rxjs';


interface Zip {
	zipId: number;
	location: string;
	locationLower: string;
	state: string;
}


@Component({
	selector: 'app-zip-selector-widget',
	templateUrl: './app-zip-selector.widget.html',
	styleUrls: ['app-zip-selector.widget.scss'],
})
export class AppZipSelectorWidget implements OnInit, OnChanges, OnDestroy {

	@Input() initialZipId: number = undefined;
	@Input() optional = false;
	@Output() selectionChange = new EventEmitter<Zip>();

	value: Zip = undefined;
	public selectedZip$ = new BehaviorSubject<Zip>(undefined);

	private zips: Zip[] = [];
	filteredZips: Zip[] | GroupResult[] = [];
	lostFocus = false;
	placeholder = 'Select a location';

	constructor(
		private ds: DataService,
		private util: UtilityService,
	) {
		this.zips = this.getZipData();
		this.filterZips();
	}


	ngOnInit() {
		this.lostFocus = false;
		if (this.selectedZip$.value) this.selectedZip$.next(undefined);
	}


	ngOnDestroy() {
		const x = 1;
	}

	ngOnChanges() {
		if (this.value?.zipId !== this.initialZipId) {
			const initialZip = this.zips.find(z => z.zipId == this.initialZipId);
			this.value = initialZip;
			this.selectedZip$.next(initialZip);
		}
	}


	getZipData() {

		const states = this.ds.domain.state.getAll();
		const stateByCode = this.util.array.toMap(states, state => state.stateCode);

		const zips = this.ds.domain.zip.getAll()
			.map(zip => ({
				zipId: zip.zipId,
				location: `${zip.cityAndState} ${zip.code}`,
				locationLower: `${zip.cityAndState} ${zip.code}`.toLocaleLowerCase(),
				state: (stateByCode[zip.state]?.state) ?? '',
			}))
			.sort((z1, z2) => {
				if (z1.state < z2.state) return -1;
				if (z1.state > z2.state) return 1;
				if (z1.locationLower < z2.locationLower) return -1;
				if (z1.locationLower > z2.locationLower) return 1;
				return 0;
			});

		return zips;
	}


	filterZips(searchValue = '') {
		searchValue = searchValue.toLocaleLowerCase();
		const zips = searchValue.length ? this.zips.filter(z => z.locationLower.indexOf(searchValue) !== -1) : this.zips;

		this.filteredZips = groupBy(zips, [{ field: "state" }]);
	}


	onSelection(zip: Zip) {
		console.log(`Zip is now`, zip)
		this.selectedZip$.next(zip);
		this.selectionChange.emit(zip);
	}


	onLostFocus() {
		this.lostFocus = true;
	}


	onFocus() {
		this.lostFocus = false;
	}


	get showMissingZip() {
		if (this.optional) return false;
		return this.lostFocus && this.selectedZip$.value == undefined;
	}
}
