<me-dialog-frame [header]='header' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>

	<div class='matching-hint-edit-dialog'>
		<table style='width:100%' class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>
			<tr>
				<td>Person</td>
				<td> <button class='btn' (click)='explorePerson()'>
						<me-icon [icon]='personIcon'></me-icon>
					</button>
				</td>
			</tr>
			<tr>
				<td [style.width.px]='100'>Topic</td>
				<td>
					<me-droplist *ngIf="isAdd" [items]='topics' [selectedItem]="selectedTopic"
						(selectionChange)='onTopicSelectionChange($event)' [readonly]='readonly'>
					</me-droplist>

					<me-icon *ngIf="!isAdd" [icon]="selectedTopic.iconContext"></me-icon>
				</td>
			</tr>

			<tr>
				<td [style.width.px]='100'>Language</td>
				<td>
					<me-droplist *ngIf="isAdd" [items]='languages' [selectedItem]="selectedLanguage"
						(selectionChange)='onLanguageSelectionChange($event)' [readonly]='readonly'>
					</me-droplist>

					<me-icon *ngIf="!isAdd" [icon]="selectedLanguage.iconContext"></me-icon>
				</td>
			</tr>

			<tr>
				<td>Status</td>
				<td>
					<kendo-dropdownlist [data]='statuses' [value]='newSelectedValue.status' [valuePrimitive]="true"
						(valueChange)='onStatusSelectionChange($event)' [disabled]='readonly'>
					</kendo-dropdownlist>
				</td>
			</tr>

			<tr>
				<td>
					Remark
				</td>
				<td>
					<textarea kendoTextArea [value]='newSelectedValue.note' style="resize:none;"
						(input)='onInput($event)' [readonly]='readonly'></textarea>
				</td>
			</tr>
		</table>
	</div>
</me-dialog-frame>