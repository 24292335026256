import { AccAreaService, AccTeamPageService, AccTeamPageViewId as ViewId } from '@ACC-area';
import { Component, Input, OnInit } from '@angular/core';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';
import { map } from 'rxjs/operators';


@Component({
	selector: 'acc-team-views-part',
	templateUrl: './team-views.part.html',
})
export class AccTeamViewsPart implements OnInit {
	@Input() readonly: boolean;
	@Input() accTeamId: number;


	viewDroplistItems: DroplistItem<never>[] = [
		{ uniqueId: ViewId.Members, groupName: 'Team', iconContext: getIconContext(Icon.view_members, undefined, undefined, 'Members'), customSort: '1-coh-1', },
		{ uniqueId: ViewId.Goals, groupName: 'Team', iconContext: getIconContext(Icon.view_goals, undefined, undefined, 'Goals'), customSort: '1-coh-2', },

	];

	selectedViewItem$ = this.pageService.selectedView$.pipe(
		map(viewId => this.viewDroplistItems.find(item => item.uniqueId == viewId)),
	);


	constructor(
		private accAreaService: AccAreaService,
		public pageService: AccTeamPageService,
	) {
	}

	
	ngOnInit() {
		this.pageService.initializeUrl();
	}


	onViewDroplistChange(selectedView: DroplistItem) {
		this.pageService.selectView(selectedView.uniqueId);
	}


	showView(view: string, viewItem: DroplistItem<never, ViewId>) {

		if (!viewItem?.uniqueId) return false;

		const viewId = viewItem.uniqueId;

		if (viewId == ViewId.Members && view == 'Members') return true;
		if (viewId == ViewId.Goals && view == 'Goals') return true;

		return false;
	}
}