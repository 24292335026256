import { FormGroup } from '@angular/forms';


export function setPhoneTypeOnCountryChange(input: Element, form: FormGroup, telInput: any, USPhoneType: number) {
	input.addEventListener("countrychange", function () {

		const phone = telInput.getNumber();
		form.patchValue({ phone });

		const a = telInput.getSelectedCountryData();

		if (a.iso2 != 'us') {
			form.patchValue({ phoneTypeId: 4 });
		}
		else form.patchValue({ phoneTypeId: USPhoneType });
		form.markAsDirty();
		form.updateValueAndValidity();
	});
}