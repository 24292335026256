import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { DestroyablePart } from '@me-access-parts';
import { ADDROW_GRID_ACTION_KEY, DBLCLICK_GRID_ACTION_KEY, GridAction, GridColumnType, GridSetup, REFRESH_GRID_ACTION_KEY, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { ApplicationFields, ApplicationFieldsForTeam, ApplicationFieldsParticipant, ApplicationParticipantStatus, FieldTool, ProgramTypeId } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { DialogActivity, DialogService } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { FieldFormState, Icon } from '@me-shared-parts/UI-common';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { ApplicationParticipantDialog } from './dialog/SHR-PG_application-participants.dialog';

const EDIT_PARTICIPANT_ACTION_KEY = 'edit-participant';
const REMIND_PARTICIPANT_ACTION_KEY = 'remind-participant';
const REMOVE_PARTICIPANT_ACTION_KEY = 'remove-participant';

export interface ParticipantRow {
	key: string,
	firstName: string,
	lastName: string,
	title: string,
	status: string,
	email: string,
	participant: ApplicationFieldsParticipant,
}

@Component({
	selector: 'application-participants',
	templateUrl: './SHR-PG_application-participants.part.html',
	styleUrls: ['./SHR-PG_application-participants.part.scss'],
})

export class ApplicationParticipantsPart extends DestroyablePart implements OnChanges, OnInit {

	@Input() application: ApplicationFields;
	@Input() forceValidation = false;
	@Input() youState: FieldFormState;
	@Input() teamState: FieldFormState;
	@Output() openAboutYou = new EventEmitter();

	public gridSetup = this.setupGrid();
	public rows: ParticipantRow[] = [];
	public tool: FieldTool;
	public allParticipantsComplete = true;
	public participantInstructionsKey = 'md:Application Participant-Team Msg';

	updatingParticipant: ApplicationFieldsParticipant; // Hen the dialog is open to update, the participant is held here


	constructor(
		private dialogService: DialogService,
		private func: FuncService,
		private labels: LabelsService,
	) {
		super();

		dialogService.activity
			.pipe(takeUntil(this.destroyed$))
			.subscribe(this.dialogActivityHandler.bind(this));
	}


	async ngOnChanges() {
		this.buildRows();
	}


	ngOnInit() {
		super.initDestroyable();

		if (!this.application) throw new Error('Missing required attribute: application');
		if (!this.teamState) throw new Error('Missing required attribute: fieldFormState');
		this.tool = this.application.overview.tool;

		//
		// Listen for changes to the "Tell us About You" questions. The participant completion
		// status gets toggled depending on whether all the You fields are completed or not.
		//
		this.youState.status$
			.pipe(
				takeUntil(this.destroyed$),
				map(status => !!status.numRemaining),
				distinctUntilChanged(),
			)
			.subscribe(incomplete => {

				const complete = !incomplete;
				const applicantRow = this.rows.find(row => row.participant.isApplicant);

				if (applicantRow) {
					applicantRow.participant.status = complete ? ApplicationParticipantStatus.Complete : ApplicationParticipantStatus.Incomplete;
					applicantRow.status = applicantRow.participant.status;
					this.recordWhetherAllParticipantsAreComplete();
					this.rows = [...this.rows]; // Force the grid to re-render
				}
			});
	}


	private setupGrid(): GridSetup<ParticipantRow> {
		return {
			experience: 'none',
			size: {
				fitTo: 'INLINE',
				height: 200,
				shrinkBy: 0,
			},
			rowSingularName: { key: 'Participant' },
			rowPluralName: { key: 'Participants' },
			rowKey: "key",
			stateKey: "application-editor-participants-part",
			canAdd: true,
			canRefresh: true,
			canDownload: false,
			columnsToAdd: [
				{ field: "status", header: { key: 'Status' }, width: 80, type: GridColumnType.text },
				{ field: "firstName", header: { key: 'First' }, width: 100, type: GridColumnType.text },
				{ field: "lastName", header: { key: 'Last' }, width: 100, type: GridColumnType.text },
				{ field: "title", header: { key: 'Title' }, width: 150, type: GridColumnType.text },
				{ field: "email", header: { key: 'Email' }, width: 150, type: GridColumnType.text },
			],
			actions: [
				{ key: EDIT_PARTICIPANT_ACTION_KEY, icon: Icon.action_edit, label: { key: 'Edit Participant' } },
				{ key: REMIND_PARTICIPANT_ACTION_KEY, icon: Icon.action_email, label: { key: 'Send Reminder Email' } },
				{ key: REMOVE_PARTICIPANT_ACTION_KEY, icon: Icon.action_delete, label: { key: 'Remove Participant' } },
			],
			actionEnabler: this.gridActionEnabler.bind(this),
		};
	}


	/**
	 * Opens a dialog to edit a participant (if a participant is passed in) or
	 * to create a new participant if nothing is passed in.
	 */
	async openDialog(participant: ApplicationFieldsParticipant = undefined) {

		this.updatingParticipant = participant;

		await this.dialogService.showCustom(
			ApplicationParticipantDialog,
			{
				instanceIdentifier: ApplicationParticipantsPart.name,
				data: participant,
			},
			300, 440
		);
	}


	/**
	 * Whenever the selection changes, the Grid component will call this for each action
	 * to set whether the action should be enabled or disabled based on the selection.
	 * @param action 
	 * @param rows 
	 */
	gridActionEnabler(action: GridAction, rows: ParticipantRow[]) {

		const row = rows[0];

		const isPending = row.participant.status == ApplicationParticipantStatus.Invited;
		const isApplicant = row.participant.isApplicant;

		if (action.key == EDIT_PARTICIPANT_ACTION_KEY) {
			//
			// isEnabled will open a dialog
			// isApplicant will open the 'Tell us about You' section
			// An accepted person, whether completed or not, cannot be edited
			//
			return isPending || isApplicant;
		}
		else if (action.key == REMIND_PARTICIPANT_ACTION_KEY) {
			//
			// A reminder email can be sent to any non-applicant that
			// has not completed the required fields.
			//
			return !isApplicant && row.participant.status !== ApplicationParticipantStatus.Complete;
		}
		else if (action.key == REMOVE_PARTICIPANT_ACTION_KEY) {
			//
			// Applicant cannot be removed
			// Any other participant in any status can be removed
			//
			return !isApplicant;
		}
	}


	/**
	 * Handle events from the participant grid
	 */
	async gridActionHandler(action: { actionKey: string, rows: ParticipantRow[] }) {

		const { applicationId, tool } = this.application.overview;

		//
		// These first few actions don't require a grid row to be selected
		//
		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {
			await this.openDialog();
		}


		else if (action.actionKey == REFRESH_GRID_ACTION_KEY) {
			this.buildRows(await this.func.application.getFieldsForTeam({ applicationId, tool }));
		}


		else if (action.rows.length) {

			//
			// Gather some values used by the actions
			//
			const participant = action.rows[0].participant;
			const participantId: number = participant.status == ApplicationParticipantStatus.Incomplete || participant.status == ApplicationParticipantStatus.Complete ? participant.participantId : null;
			const participantPendingId: number = participant.status == ApplicationParticipantStatus.Invited ? participant.participantPendingId : null;


			if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
				// Nothing to do with a simple row selection
			}


			else if (action.actionKey == DBLCLICK_GRID_ACTION_KEY || action.actionKey == EDIT_PARTICIPANT_ACTION_KEY) {
				if (participant.isApplicant) this.openAboutYou.emit();
				else await this.openDialog(participant);
			}


			else if (action.actionKey == REMIND_PARTICIPANT_ACTION_KEY) {
				if (participantId) {
					await this.func.application.participant.sendReminderEmail({ applicationId, participantId, tool });
				}
				else if (participantPendingId) {
					await this.func.application.pendingParticipant.sendReminderEmail({ applicationId, participantPendingId, tool });
				}

				await this.dialogService.showMessage({ key: "md:An email has been sent with instructions", fields: { 'email': participant.email } });
			}


			else if (action.actionKey == REMOVE_PARTICIPANT_ACTION_KEY) {
				if (participantId) {
					this.buildRows(await this.func.application.participant.remove({ applicationId, participantId, tool }));
				}
				else if (participantPendingId) {
					this.buildRows(await this.func.application.pendingParticipant.remove({ applicationId, participantPendingId, tool }));
				}
			}
		}
	}


	/**
	* Handle events from the editor dialog
	*/
	async dialogActivityHandler(activity: DialogActivity) {

		if (activity.instanceIdentifier != ApplicationParticipantsPart.name) return;
		const { applicationId, tool } = this.application.overview;

		//
		// The activity can be either 'Open', 'Close' or 'Action'
		// We only care about the actions (when a button is clicked)
		//
		if (activity.activity == 'Action') {

			if (activity.action?.id == 'add') {

				const { firstName, lastName, title, email } = activity.value;

				this.buildRows(await this.func.application.pendingParticipant.invite({
					applicationId: this.application.overview.applicationId,
					tool: this.tool,
					values: { firstName, lastName, title, email, }
				}));

				await this.dialogService.showMessage({ key: "md:An email has been sent with instructions", fields: { 'email': email } });
			}


			else if (activity.action?.id == 'update') {

				const { firstName, lastName, title, email } = activity.value;
				const participant = { ...this.updatingParticipant, ...activity.value };
				const { participantId, participantPendingId } = participant;


				if (participant.participantId) {
					this.buildRows(await this.func.application.participant.update({
						applicationId,
						participantId,
						tool,
						values: { title },	// Only the title can be updated with an accepted participant
					}));
				}


				else if (participant.participantPendingId) {
					this.buildRows(await this.func.application.pendingParticipant.update({
						applicationId,
						participantPendingId,
						tool,
						values: { firstName, lastName, title, email },
					}));
				}
			}
		}
	}


	recordWhetherAllParticipantsAreComplete() {
		this.allParticipantsComplete = !this.application.team.participants.some(p => p.status !== 'Complete');
		this.teamState.setCompleted('participantsGrid', this.allParticipantsComplete);
	}


	async buildRows(newTeamFields: ApplicationFieldsForTeam = undefined) {

		//
		// If a new fields object was passed in (returned from a function)
		// then assign it to the application.
		//
		if (newTeamFields) this.application.team = newTeamFields;

		//
		// Record whether all participants are complete 
		//
		this.recordWhetherAllParticipantsAreComplete();

		//
		// Map the participants to grid row data
		//
		const getLabel = await this.labels.getLabel();

		this.rows = this.application.team.participants.map(p => {
			const key = p.status == ApplicationParticipantStatus.Invited ? `I${p.participantPendingId}` : `P${p.participantId}`;
			return {
				key,
				status: getLabel({ key: p.status }),
				firstName: p.firstName,
				lastName: p.lastName,
				title: p.title,
				email: p.email,
				participant: p,
			};
		});
	}
}