import { Component, Input, OnInit } from '@angular/core';
import { DroplistItem, getIconContext, Icon } from '@me-shared-parts/UI-common';
import { map } from 'rxjs';
import { StaffOverviewPageService, ViewId } from '../staff-overview-page.service';


@Component({
	selector: 'staff-overview-views-part',
	templateUrl: './staff-overview-views.part.html',
})
export class StaffOverviewViewsPart implements OnInit {


	viewDroplistItems: DroplistItem<never, ViewId>[] = [
		{ uniqueId: ViewId.AllStaff, groupName: 'Staff', iconContext: getIconContext(Icon.empty, undefined, undefined, 'People'), customSort: '1-Staff-1' },
		{ uniqueId: ViewId.Departments, groupName: 'Staff', iconContext: getIconContext(Icon.empty, undefined, undefined, 'Departments'), customSort: '1-Staff-2' },
		{ uniqueId: ViewId.Teams, groupName: 'Staff', iconContext: getIconContext(Icon.empty, undefined, undefined, 'Teams'), customSort: '1-Staff-3' },
	];

	selectedViewItem$ = this.pageService.selectedView$.pipe(
		map(viewId => this.viewDroplistItems.find(item => item.uniqueId == viewId)),
	);

	constructor(
		private pageService: StaffOverviewPageService,
	) { }


	ngOnInit() {
		this.pageService.initializeUrl();
	}


	onViewDroplistChange(selectedView: DroplistItem) {
		this.pageService.selectView(selectedView.uniqueId);
	}

	
	showView(view: string, viewItem: DroplistItem<never, ViewId>) {

		if (!viewItem?.uniqueId) return false;

		const viewId = viewItem.uniqueId;

		if (viewId == ViewId.Overview && view == 'Overview') return true;
		if (viewId == ViewId.Departments && view == 'Departments') return true;
		if (viewId == ViewId.AllStaff && view == 'AllStaff') return true;
		if (viewId == ViewId.Teams && view == 'Teams') return true;

		return false;
	}
}