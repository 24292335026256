import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { Accelerator, AccStageId } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';

const validStage: AccStageId[] = [
	AccStageId.MatchingMentors,
	AccStageId.Curriculum,
	AccStageId.SelectingWinners,
	AccStageId.Quarterlies,
];

export interface EnabledQuarterlyFlags { 0: boolean, 1: boolean, 2: boolean, 3: boolean }

@Component({
	selector: 'acc-quarterly-goals-enabler-dialog',
	templateUrl: './quarterly-goals-enabler.dialog.html',
	styleUrls: ['./quarterly-goals-enabler.dialog.scss']
})
export class AccQuarterlyGoalsEnablerDialog extends DestroyablePart implements OnInit {

	public readonly AccStageId_MatchingMentors = AccStageId.MatchingMentors;
	public readonly AccStageId_Quarterlies = AccStageId.Quarterlies;
	orgEnabledQuarters: EnabledQuarterlyFlags = { 0: false, 1: false, 2: false, 3: false };
	enabledQuarters: EnabledQuarterlyFlags = { 0: false, 1: false, 2: false, 3: false };
	quarterNames = ['Curriculum (Q1)', 'Quarter 2', 'Quarter 3', 'Quarter 4'];

	disabled = true;

	selectedAccStageId: AccStageId;

	//
	// The action buttons
	//
	saveAction: DialogAction<EnabledQuarterlyFlags> = {
		id: 'save',
		enabled: true,
		visible: false,
		label: ':Save',
		linkType: 'callback',
		callback: async () => ([this.enabledQuarters[0], this.enabledQuarters[1], this.enabledQuarters[2], this.enabledQuarters[3]]),
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: ':Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction<EnabledQuarterlyFlags | void>[] = [this.saveAction, this.cancelAction];
	icon = 'fa-user-plus';
	headerText = 'Enabled Quarterly Goals';
	readonly: boolean;
	accelerator: Accelerator;

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ readonly: boolean, accelerator: Accelerator }>,
		public dialogRef: MatDialogRef<AccQuarterlyGoalsEnablerDialog>,
		private util: UtilityService,
	) {
		super();
	}


	ngOnInit() {
		super.initDestroyable();

		this.readonly = this.dialog.data.readonly;
		this.accelerator = this.dialog.data.accelerator;

		if (validStage.includes(this.accelerator.accStageId)) this.disabled = false;
		else {
			this.disabled = true;
			this.enabledQuarters = { 0: false, 1: false, 2: false, 3: false };
		}


		if (!this.disabled) {
			const q: [boolean, boolean, boolean, boolean] = [
				(this.accelerator.enabledQuarterlyGoals & Math.pow(2, 0)) == Math.pow(2, 0),
				(this.accelerator.enabledQuarterlyGoals & Math.pow(2, 1)) == Math.pow(2, 1),
				(this.accelerator.enabledQuarterlyGoals & Math.pow(2, 2)) == Math.pow(2, 2),
				(this.accelerator.enabledQuarterlyGoals & Math.pow(2, 3)) == Math.pow(2, 3),
			];
			this.enabledQuarters['0'] = q[0];
			this.enabledQuarters['1'] = q[1];
			this.enabledQuarters['2'] = q[2];
			this.enabledQuarters['3'] = q[3];
		}


		this.orgEnabledQuarters = { ...this.enabledQuarters };

		this.saveAction.visible = true;
		this.checkIfCanSave();
	}

	async setQuarter(quarter: number, value: boolean) {
		this.enabledQuarters[quarter] = value;
		this.checkIfCanSave();

	}

	/**
	 * Look at enabledQuarters and determine if the save/update button should be enabled
	 */
	checkIfCanSave() {
		this.saveAction.enabled = !this.readonly && !this.util.values.areSame(this.enabledQuarters, this.orgEnabledQuarters);
	}
}