import { AccAreaService, AccMatchingDialog, AccPreAcceleratorPageService, AccPreAcceleratorViewId as ViewId } from '@ACC-area';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { Accelerator, DAYLIGHT_SAVINGS_TIMEZONES } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogService } from '@me-services/ui/dialog';
import { map } from 'rxjs/operators';
import { AccMatchingToolState } from './acc-matching-tool-state';
import { AccMatchingTeam } from './team/acc-matching-team';
import { matchV2 } from './matcher2/match-v2';


@Component({
	selector: 'acc-matching-tool-view-part',
	templateUrl: './acc-matching-tool-view.part.html',
	styleUrls: ['./acc-matching-tool-view.part.scss'],
})
export class AccMatchingToolViewPart extends DestroyablePart {

	accelerator: Accelerator;

	state$ = this.accAreaService.teams.teams$.pipe(
		map(teams => {
			const matchingTeams = teams.map(team => {
				this.accelerator = team.accelerator;
				const t: AccMatchingTeam = {
					accTeamId: team.accTeamId,
					droppedOutWeek: team.droppedOutWeek,
					coachPersonIds: team.members.filter(m => m.member.role == 'C').map(m => m.person.personId),
					mentorPersonIds: team.members.filter(m => m.member.role == 'M').map(m => m.person.personId),
				};

				return t;
			});

			return <AccMatchingToolState>{
				teams: matchingTeams,
				hasTeams: !!teams.length,
				hasMentors: !!matchingTeams.find(team => team.coachPersonIds.length || team.mentorPersonIds.length),
			};
		})
	);


	hintsLabel$ = this.accAreaService.mentorMatching.hints$.pipe(
		map(hints => this.ds.util.text.singularOrPluralWithCount(hints?.length ?? 0, 'Hint', 'Hints'))
	);


	constructor(
		private accAreaService: AccAreaService,
		private pageService: AccPreAcceleratorPageService,
		private ds: DataService,
		private dialogService: DialogService,
		private router: Router,
		private route: ActivatedRoute,
	) {
		super();
	}


	openHints() {
		this.pageService.selectView(ViewId.MMMatchingHints);
	}


	async match() {

		//
		// Determine if it is the V2 matching
		//
		const accData = await super.lastValueFrom(this.accAreaService.data$);
		const v2 = !!accData.mentorMatching.v2Schedules;

		if (!v2) {
			const yes = await this.dialogService.confirm(`The v2 Accelerator Dashboard does not yet support mentor matching. Use the v1 tool instead.
		
Would you like to open the v1 mentor matching tool?`);

			if (yes) this.router.navigate([`access/admin/communities/${this.accelerator.siteProgram.site.code.toLowerCase()}/programs/accelerators/${this.accelerator.accId}/v1/events/mentor-matching/matching`]);

			return;
		}


		//
		// To run the matching tool, the user must have write access overall and
		// at least read access for assessments.
		//
		const accessAndId = await super.lastValueFrom(this.accAreaService.accessAndId$);
		const access = accessAndId.access;

		if (access.root !== 'Write' || access.sub.assessments == 'None') {
			this.dialogService.showMessage(`You are not authorized to match mentors for this accelerator.`, 250, 140);
			return;
		}


		//
		// Warn if existing mentors might be replaced
		//
		const state = await super.lastValueFrom(this.state$);

		if (state.hasMentors) {
			const yes = await this.dialogService.confirm(`There are already mentors assigned to teams. You can continue and generate matches for evaluation.
			
			However, if you apply a scenario, the existing mentor assignments will be IRRETRIEVABLY lost.  You will be warned before that happens to avoid mistakes.`, 375, 250, 'Okay', 'Cancel');
			if (!yes) return;
		}


		await matchV2(this.dialogService, accData);


	}


	workerTest() {

		if (typeof Worker !== 'undefined') {

			const worker1 = new Worker(new URL('./matcher/worker/mentor-matching.worker', import.meta.url));
			worker1.onmessage = ({ data }) => {
				alert(`page got message: ${data}`);
			};
			worker1.postMessage('worker1');




			// const worker2 = new Worker(new URL('./matcher2/mentor-matching2.worker', import.meta.url));
			// worker2.onmessage = ({ data }) => {
			// 	alert(`page got message: ${data}`);
			// };
			// worker2.postMessage('worker2');

		} else {
			alert('WORKER NOT SUPPORTED')
		}
	}

}