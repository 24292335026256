import { AccStageId, Dbs, DbsAccelerator, DbsAccInterviewer, DbsAccJudge, DbsAccReader, DbsAccTeam, DbsAccTeamMember, DbsApplication, DbsApplicationParticipant, DbsAward, DbsEmail, DbsEntityNote, DbsPerson, DbsPersonTag, DbsPicJudge, DbsPicReader, DbsPicTeam, DbsPicTeamMember, DbsPitchContest, DbsRelationship, DbsWebLink, NoteCategoryId, Person, PicStageId, RoleTiming, Tag } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { TagPackageManager } from "./tag";



export class PersonPackageManager extends PackageManager<DbsPerson, Person> {


	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsPerson>,
		private domain: DomainDataManagers,
		private personTag: SingletonsManager<DbsPersonTag>,
		private tag: TagPackageManager,
		private email: SingletonsManager<DbsEmail>,
		private webLink: SingletonsManager<DbsWebLink>,
		private note: SingletonsManager<DbsEntityNote>,
		private relationship: SingletonsManager<DbsRelationship>,
		private application: SingletonsManager<DbsApplication>,
		private applicationParticipant: SingletonsManager<DbsApplicationParticipant>,
		private accelerator: SingletonsManager<DbsAccelerator>,
		private accTeam: SingletonsManager<DbsAccTeam>,
		private accTeamMember: SingletonsManager<DbsAccTeamMember>,
		private accReader: SingletonsManager<DbsAccReader>,
		private accInterviewer: SingletonsManager<DbsAccInterviewer>,
		private accJudge: SingletonsManager<DbsAccJudge>,
		private pitchContest: SingletonsManager<DbsPitchContest>,
		private picTeam: SingletonsManager<DbsPicTeam>,
		private picTeamMember: SingletonsManager<DbsPicTeamMember>,
		private picReader: SingletonsManager<DbsPicReader>,
		private picJudge: SingletonsManager<DbsPicJudge>,
		private award: SingletonsManager<DbsAward>,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Finds all of a particular singleton type for each person and returns a map
	 * where each entry is an id (e.g. personId or entityId) that maps to an array
	 * of the singleton type.
	 */
	private async _getSingletonsMapForPeople<T extends Dbs>(
		dbsPersons: DbsPerson[],
		sm: SingletonsManager<T>,
		getId: (person: DbsPerson) => number,
		foreignKeyProperty,
	): Promise<{ [id: number]: T[] }> {

		const foreignIds = dbsPersons.map(person => getId(person));
		const map = await sm.getArraysByForeignIds(foreignKeyProperty, foreignIds);
		return map;
	}


	/**
	 * Finds all of a particular singleton type for each person and returns a map
	 * where each entry is personId that maps to an array of the singleton type.
	 */
	private async getSingletonsMapByPersonId<T extends Dbs>(
		dbsPersons: DbsPerson[],
		sm: SingletonsManager<T>,
	): Promise<{ [personId: number]: T[] }> {

		return await this._getSingletonsMapForPeople(dbsPersons, sm, (person: DbsPerson) => person.personId, 'personId');
	}


	/**
	 * Finds all of a particular singleton type for each person and returns a map
	 * where each entry is entityId that maps to an array of the singleton type.
	 */
	private async getSingletonsMapByEntityId<T extends Dbs>(
		dbsPersons: DbsPerson[],
		sm: SingletonsManager<T>,
	): Promise<{ [entityId: number]: T[] }> {

		return await this._getSingletonsMapForPeople(dbsPersons, sm, (person: DbsPerson) => person.entityId, 'entityId');
	}


	/**
	 * Convert an array of DbcPerson to an array of Person
	 */
	protected async _createPackages(dbsPersons: DbsPerson[]): Promise<Person[]> {


		//
		// Create a map of MANUAL tags for each personId
		//
		const tagsMap = await this.tag.getAllPackagesAsMap();
		const personTags = await this.personTag.getAllAsArray();

		const personTagsMap = personTags.reduce((map, personTag) => {
			if (!map[personTag.personId]) map[personTag.personId] = [];
			const tag = tagsMap[personTag.tagId];
			if (tag) map[personTag.personId].push(tag);
			return map;
		}, <{ [personId: number]: Tag[] }>{});


		//
		// Create maps for singletons
		//
		const emailsByPersonId = await this.getSingletonsMapByPersonId(dbsPersons, this.email);
		const webLinksByEntityId = await this.getSingletonsMapByEntityId(dbsPersons, this.webLink);
		const notesByEntityId = await this.getSingletonsMapByEntityId(dbsPersons, this.note);


		//
		// Acc Teams
		//
		const accTeamMembersByPersonId = await this.getSingletonsMapByPersonId(dbsPersons, this.accTeamMember);
		const { accTeamsByPersonId, accIds } = await this.getAccTeams(dbsPersons, accTeamMembersByPersonId);
		const acceleratorByAccId = await this.accelerator.getManyAsMap(accIds);


		//
		// Pic Teams
		//
		const picTeamMembersByPersonId = await this.getSingletonsMapByPersonId(dbsPersons, this.picTeamMember);
		const { picTeamsByPersonId, picIds } = await this.getPicTeams(dbsPersons, picTeamMembersByPersonId);
		const pitchContestByPicId = await this.pitchContest.getManyAsMap(picIds);


		//
		// Applications
		//
		const { applicationIdsByPersonId, applicationByApplicationId } = await this.getApplications(
			dbsPersons,
			accTeamsByPersonId, accTeamMembersByPersonId,
			picTeamsByPersonId, picTeamMembersByPersonId,
		);


		const accReadershipMap = await this.getSingletonsMapByPersonId(dbsPersons, this.accReader);
		const accInterviewershipMap = await this.getSingletonsMapByPersonId(dbsPersons, this.accInterviewer);
		const accJudgeshipMap = await this.getSingletonsMapByPersonId(dbsPersons, this.accJudge);

		const picReadershipMap = await this.getSingletonsMapByPersonId(dbsPersons, this.picReader);
		const picJudgeshipMap = await this.getSingletonsMapByPersonId(dbsPersons, this.picJudge);


		//
		// Awards
		//
		const awardsByPersonId = await this.getAwards(dbsPersons, accTeamMembersByPersonId, picTeamMembersByPersonId);


		//
		// Package 'em up
		//
		const persons: Person[] = dbsPersons.map(person => {


			const fullName = person.middleInit ? `${person.firstName} ${person.middleInit}. ${person.lastName}` : `${person.firstName} ${person.lastName}`;


			//
			// Emails
			//
			const emails = (emailsByPersonId[person.personId] || [])
				.map(email => ({
					emailId: email.emailId,
					email: email.email,
					isDefault: email.email == person._email,
				}));


			//
			// Tags
			//
			const tags = (personTagsMap[person.personId] || [])
				.sort((t1, t2) => t1.fullName > t2.fullName ? -1 : 1)
				.map(tag => ({
					tagId: tag.tagId,
					tagPrefixId: tag.tagPrefixId,
					isAutoTag: tag.isAutoTag,
					fullName: tag.fullName,
					siteId: tag.tagPrefix.siteId,
				}
				));


			//
			// WebLinks
			//
			const webLinks = (webLinksByEntityId[person.entityId] || [])
				.map(webLink => ({
					webLinkId: webLink.webLinkId,
					webLinkTypeId: webLink.webLinkTypeId,
					url: webLink.url,
				}));

			//
			// Notes
			//
			const notes = notesByEntityId[person.entityId];
			const hasRedFlag = !!notes.find(note => note.noteCategoryId == NoteCategoryId.RedFlag);
			const noteIds = this.util.array.cleanNumericIds(notes.map(note => note.noteId));
			const doNotContact = (person.doNotContactNote || '').trim().length > 1;


			//
			// Applications
			//
			const accApplicationIds = applicationIdsByPersonId[person.personId]
				.map(applicationId => applicationByApplicationId[applicationId])
				.filter(application => !!application.accId)
				.map(application => application.applicationId);

			const picApplicationIds = applicationIdsByPersonId[person.personId]
				.map(applicationId => applicationByApplicationId[applicationId])
				.filter(application => !!application.picId)
				.map(application => application.applicationId);


			//
			// isMentor
			//
			const mentorships = accTeamMembersByPersonId[person.personId].filter(m => ['C', 'M', 'X'].includes(m.role));
			let isMentor: RoleTiming = 'Never';

			const accTeamsMap = this.util.array.toMap(accTeamsByPersonId[person.personId], team => team.accTeamId);

			for (const mentorship of mentorships) {
				const accTeam = accTeamsMap[mentorship.accTeamId];
				const accelerator = acceleratorByAccId[accTeam.accId];
				if (accelerator.accStageId >= AccStageId.Complete) isMentor = 'Past';
				else {
					isMentor = 'Current';
					break;
				}
			}

			//
			// Person has at least one site approved mentor tag
			//
			const isApprovedMentor = false;


			//
			// isVolunteer (any volunteering except mentoring)
			// Always 'Never' or 'Past'
			//
			let isVoluneer: RoleTiming = 'Never';
			if (
				accReadershipMap[person.personId].length > 0 ||
				accInterviewershipMap[person.personId].length > 0 ||
				accJudgeshipMap[person.personId].length > 0 ||
				picReadershipMap[person.personId].length > 0 ||
				picJudgeshipMap[person.personId].length > 0
			) {
				isVoluneer = 'Past';
			}


			//
			// isAccEntrepreneur (accTeamMember with role 'E')
			//
			const accTeamMembers = accTeamMembersByPersonId[person.personId].filter(m => m.role == 'E');
			let isAccEntrepreneur: RoleTiming = 'Never';

			for (const accTeamMember of accTeamMembers) {
				const accTeam = accTeamsMap[accTeamMember.accTeamId];
				const accelerator = acceleratorByAccId[accTeam.accId];
				if (accelerator.accStageId >= AccStageId.Complete) isAccEntrepreneur = 'Past';
				else {
					isAccEntrepreneur = 'Current';
					break;
				}
			}


			//
			// isPicEntrepreneur
			//
			const picTeamsMap = this.util.array.toMap(picTeamsByPersonId[person.personId], team => team.picTeamId);
			const picTeamMembers = picTeamMembersByPersonId[person.personId];
			let isPicEntrepreneur: RoleTiming = 'Never';

			for (const picTeamMember of picTeamMembers) {
				const picTeam = picTeamsMap[picTeamMember.picTeamId];
				const pitchContest = pitchContestByPicId[picTeam.picId];
				if (pitchContest.picStageId >= PicStageId.Complete) isPicEntrepreneur = 'Past';
				else {
					isPicEntrepreneur = 'Current';
					break;
				}
			}


			//
			// Awards
			//
			const awards = awardsByPersonId[person.personId].map(award => ({ awardId: award.awardId, value: award.value }));

			const awardedValue = awards.reduce((a, award) => { a += award.value; return a; }, 0);


			return {
				_concept: person._concept,
				asSingleton: person,
				personId: person.personId,
				id: person.personId,
				name: fullName,
				explorerName: fullName,
				updatedUTC: person.updatedUTC,
				fullName,
				emails,
				tags,
				webLinks,
				hasRedFlag,
				doNotContact,
				noteIds,
				accApplicationIds,
				picApplicationIds,
				activity: {
					accelerator: isAccEntrepreneur,
					pitchContest: isPicEntrepreneur,
					mentor: isMentor,
					otherVolunteer: isVoluneer,
				},
				isApprovedMentor,
				awards,
				awardedValue,
				speaksEnglish: !!tags.find(tag => tag.fullName == 'lang:english'),
				speaksSpanish: !!tags.find(tag => tag.fullName == 'lang:spanish'),
				appUrl: this.createAppUrl(person),
			};
		});

		return persons;
	}


	/**
	 * Create a map of DbsAccTeam[] by each personId given an array of people
	 * and a map of each of those people to accTeamMember[]
	 */
	private async getAccTeams(dbsPersons: DbsPerson[], accTeamMembersByPersonId: Record<number, DbsAccTeamMember[]>) {

		const accTeamIds: number[] = [];	// Get all accTeamIds across all the people to get a map of the objects
		const accTeamIdsByPersonId: Record<number, number[]> = {};
		const accIds: number[] = [];

		for (const person of dbsPersons) {

			accTeamIdsByPersonId[person.personId] = [];

			for (const accTeamMember of accTeamMembersByPersonId[person.personId]) {
				accTeamIdsByPersonId[person.personId].push(accTeamMember.accTeamId);
				accTeamIds.push(accTeamMember.accTeamId);
			}
		}

		const accTeamByAccTeamId = await this.accTeam.getManyAsMap(accTeamIds);
		const accTeamsByPersonId: Record<number, DbsAccTeam[]> = {};

		for (const person of dbsPersons) {
			accTeamsByPersonId[person.personId] = accTeamIdsByPersonId[person.personId].map(accTeamId => accTeamByAccTeamId[accTeamId]);
			accIds.push(...accTeamsByPersonId[person.personId].map(accTeam => accTeam.accId));
		}

		return {
			accTeamsByPersonId,
			accIds,
		};
	}

	private createAppUrl(person: DbsPerson): string {
		const personId = person.personId;
		return `/access/contacts/people/${personId}/overview`;
	}


	/**
	 * Create a map of DbsPicTeam[] by each personId given an array of people
	 * and a map of each of those people to picTeamMember[]
	 */
	private async getPicTeams(dbsPersons: DbsPerson[], picTeamMembersByPersonId: Record<number, DbsPicTeamMember[]>) {

		const picTeamIds: number[] = [];	// Get all picTeamIds across all the people to get a map of the objects
		const picTeamIdsByPersonId: Record<number, number[]> = {};
		const picIds: number[] = [];

		for (const person of dbsPersons) {

			picTeamIdsByPersonId[person.personId] = [];

			for (const picTeamMember of picTeamMembersByPersonId[person.personId]) {
				picTeamIdsByPersonId[person.personId].push(picTeamMember.picTeamId);
				picTeamIds.push(picTeamMember.picTeamId);
			}
		}

		const picTeamByPicTeamId = await this.picTeam.getManyAsMap(picTeamIds);
		const picTeamsByPersonId: Record<number, DbsPicTeam[]> = {};

		for (const person of dbsPersons) {
			picTeamsByPersonId[person.personId] = picTeamIdsByPersonId[person.personId].map(picTeamId => picTeamByPicTeamId[picTeamId]);
			picIds.push(...picTeamsByPersonId[person.personId].map(picTeam => picTeam.picId));
		}

		return {
			picTeamsByPersonId,
			picIds,
		};
	}



	/**
	 * For each person in an array of people, get the list of all applications, regardless of the
	 * program type, associated with that person.  An application is considered associated if...
	 * 
	 *   -  They are the applicant
	 *   -  They are one of the participants
	 *   -  They are a member of an acc or pic team
	 * 
	 */
	private async getApplications(
		dbsPersons: DbsPerson[],
		accTeamsByPersonId: Record<number, DbsAccTeam[]>,
		accTeamMembersByPersonId: Record<number, DbsAccTeamMember[]>,
		picTeamsByPersonId: Record<number, DbsPicTeam[]>,
		picTeamMembersByPersonId: Record<number, DbsPicTeamMember[]>
	) {

		//
		// First get the applications for each person where each is the applicant (any program type)
		//
		const applicationsByPersonId = await this.getSingletonsMapByPersonId(dbsPersons, this.application);

		//
		// Next get all the application participant records for each person (any program type)
		//
		const applicationParticipantsByPersonId = await this.getSingletonsMapByPersonId(dbsPersons, this.applicationParticipant);

		//
		// Map each personId to an array of applicationId[] (any program type)
		// and also remember the full list of them so we can create a lookup map
		//
		const applicationIdsByPersonId: Record<number, number[]> = {};
		const applicationIds: number[] = [];

		for (const person of dbsPersons) {

			const accTeamByAccTeamId = this.util.array.toMap(accTeamsByPersonId[person.personId], team => team.accTeamId);
			const picTeamByPicTeamId = this.util.array.toMap(picTeamsByPersonId[person.personId], team => team.picTeamId);

			applicationIdsByPersonId[person.personId] = this.util.array.cleanNumericIds([

				...applicationsByPersonId[person.personId]
					.map(application => application.applicationId),

				...applicationParticipantsByPersonId[person.personId]
					.map(participant => participant.applicationId),

				...accTeamMembersByPersonId[person.personId]
					.filter(accTeamMember => accTeamMember.role == 'E')
					.map(accTeamMember => accTeamByAccTeamId[accTeamMember.accTeamId])
					.map(accTeam => accTeam.applicationId),

				...picTeamMembersByPersonId[person.personId]
					.map(picTeamMember => picTeamByPicTeamId[picTeamMember.picTeamId])
					.map(picTeam => picTeam.applicationId),
			]);


			applicationIds.push(...applicationIdsByPersonId[person.personId]);

		}

		const applicationByApplicationId = await this.application.getManyAsMap(applicationIds);

		return {
			applicationIdsByPersonId,
			applicationByApplicationId,
		};

	}



	/**
	 * For each person in an array of people, get the list of all awards, regardless of the
	 * program type, associated with that person.  An award is considered associated if...
	 * 
	 *   -  They are a member of an acc or pic team
	 * 
	 */
	private async getAwards(
		dbsPersons: DbsPerson[],
		accTeamMembersByPersonId: Record<number, DbsAccTeamMember[]>,
		picTeamMembersByPersonId: Record<number, DbsPicTeamMember[]>,
	): Promise<{ [personId: number]: DbsAward[]; }> {


		const awardsByPersonId: { [personId: number]: DbsAward[] } = {};


		//
		// Get all of the unique accTeamId values provided
		//
		let allAccTeamIds: number[] = [];

		for (const person of dbsPersons) {
			const accTeamIds = accTeamMembersByPersonId[person.personId]?.filter(m => m.role == 'E').map(accTeam => accTeam.accTeamId);
			allAccTeamIds.push(...accTeamIds);
		}

		allAccTeamIds = this.util.array.cleanNumericIds(allAccTeamIds);


		//
		// Get all of the unique picTeamId values provided
		//
		let allPicTeamIds: number[] = [];

		for (const person of dbsPersons) {
			const picTeamIds = picTeamMembersByPersonId[person.personId]?.map(picTeam => picTeam.picTeamId);
			allPicTeamIds.push(...picTeamIds);
		}

		allPicTeamIds = this.util.array.cleanNumericIds(allPicTeamIds);


		//
		// Get the awards for each acc and pic team
		//
		const awardsByAccTeamId = await this.award.getArraysByForeignIds('accTeamId', allAccTeamIds);
		const awardsByPicTeamId = await this.award.getArraysByForeignIds('picTeamId', allPicTeamIds);



		for (const person of dbsPersons) {

			//
			// Initialize the array for each person
			//
			if (!awardsByPersonId[person.personId]) awardsByPersonId[person.personId] = [];

			//
			// Get this person's acc teams and add any associated awards
			//
			const accTeamIds = this.util.array.cleanNumericIds(accTeamMembersByPersonId[person.personId]?.filter(m => m.role == 'E').map(accTeam => accTeam.accTeamId), false);

			for (const accTeamId of accTeamIds) {
				const awards = awardsByAccTeamId[accTeamId] || [];
				awardsByPersonId[person.personId].push(...awards);
			}


			//
			// get this person's pic teams and add any associated awards
			//
			const picTeamIds = this.util.array.cleanNumericIds(picTeamMembersByPersonId[person.personId]?.map(picTeam => picTeam.picTeamId), false);

			for (const picTeamId of picTeamIds) {
				const awards = awardsByPicTeamId[picTeamId] || [];
				awardsByPersonId[person.personId].push(...awards);
			}
		}

		return awardsByPersonId;
	}

}