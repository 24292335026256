import { AccAreaService, AccOverviewPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ADDROW_GRID_ACTION_KEY, GridColumnType, GridSetup } from '@me-grid';
import { Event, EventTypeId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


const EDIT_EVENT_ACTION_KEY = 'edit-event';


@Component({
	selector: 'acc-all-events-view-part',
	templateUrl: './all-events-view.part.html',
})
export class AccAllEventsViewPart extends DestroyablePart implements OnInit {

	eventNavigateTo: { [id: number]: string } = {};

	public gridSetup = this.setupGrid();
	public rows$: Observable<Event[]>;


	constructor(
		private pageService: AccOverviewPageService,
		public accAreaService: AccAreaService,
		public ds: DataService,
		public util: UtilityService) {
		super();
	}


	ngOnInit() {
		super.initDestroyable();

		const eventTypes = this.ds.domain.eventType.getAll();
		for (const e of eventTypes) {
			if (e.eventTypeId == EventTypeId.AccQuarterliesQ2) this.eventNavigateTo[e.eventTypeId] = 'q2';
			else if (e.eventTypeId == EventTypeId.AccQuarterliesQ3) this.eventNavigateTo[e.eventTypeId] = 'q3';
			else if (e.eventTypeId == EventTypeId.AccQuarterliesQ4) this.eventNavigateTo[e.eventTypeId] = 'q4';
			else if (e.eventTypeId == EventTypeId.AccShowcase) this.eventNavigateTo[e.eventTypeId] = 'showcase';
			else if (e.eventTypeId == EventTypeId.AccReading) this.eventNavigateTo[e.eventTypeId] = e.name.toLowerCase().split(' ').join('-') + '/event';
			else this.eventNavigateTo[e.eventTypeId] = e.name.toLowerCase().split(' ').join('-') + '/events';
		}

		this.rows$ = this.accAreaService.accelerator.events$.pipe(map(events => this.buildRows(events)));
	}

	buildRows(events: readonly Event[]) {
		if (!events) return [];
		return [...events];
	}


	private setupGrid(): GridSetup<Event> {


		return {
			experience: 'EVENT',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				shrinkBy: 0,
				heightMultiplier: 1,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Event",
			rowPluralName: "Events",
			rowKey: "eventId",
			stateKey: "all-acc-events",
			canAdd: true,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: 'EVENT_eventType', header: 'Event Type', width: 200, type: GridColumnType.text, hidden: false },
				{ field: 'EVENT_venueName', header: 'Venue', width: 250, type: GridColumnType.text, hidden: false },
			],
			initialState: { sort: [{ field: 'EVENT-startUTC', dir: 'asc' }] },

		};
	}


	async gridActionHandler(action: { actionKey: string, rows: Event[] }) {

		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {
			await this.ds.dialogService.showMessage(`This is a read-only list of all events. Events can be added to appropriate stages under the Pre-Accelerator and Cohort tabs.`, 320, 200);
		}
	}
}