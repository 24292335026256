import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { SharedCompanyModule } from '@me-shared-parts/CM-company/CM.module';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';

import {
	OldTagPart,
	PersonBadgePart, PersonExtractAssociationsPart, PersonExtractBannerPart, PersonExtractButtonPart,
	PersonExtractDialog, PersonExtractHighlightsPart, PersonExtractInfoPart, PersonExtractLinkPart, PersonExtractPositionsPart,
	PersonExtractRelationshipsPart, PersonPositionsPart,
	PersonRolesPart,
	PersonSelectorByApprovedRolePart, PersonTablePart, PersonTagEditorPart,
	PersonTagsOverviewPart, PublicPersonButtonPart, PublicPersonDialog,
	PublicPersonLinkPart, SimpleTagEditorPart,
	SiteTagEditorPart,
	SiteTagsOverviewPart,
	TagPart,
	TagsEditorDialog,
	TitledPersonGridPart
} from '@me-shared-parts/PN-person';
import { SharedCommonModule } from '@me-shared-parts/UI-common/UI.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { MarkdownModule } from 'ngx-markdown';


@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonToggleModule,
		MatCardModule,
		MatDatepickerModule,
		MatInputModule,
		MatSelectModule,
		MatCheckboxModule,
		MatRadioModule,
		MatExpansionModule,
		MatAutocompleteModule,
		MatDialogModule,
		MatListModule,
		MatMenuModule,
		MatTooltipModule,
		MatTableModule,
		MatTreeModule,
		GridModule,
		FlexLayoutModule,
		DateInputsModule,
		DropDownsModule,
		LayoutModule,
		InputsModule,
		MarkdownModule.forChild(),
		PopupModule,
		SharedCommonModule,
		SharedCompanyModule
	],
	declarations: [
		//
		// Components
		//
		OldTagPart,
		PersonBadgePart,
		PersonPositionsPart,
		PersonRolesPart,
		PersonSelectorByApprovedRolePart,
		PersonTagEditorPart,
		PersonTagsOverviewPart,
		SimpleTagEditorPart,
		SiteTagEditorPart,
		SiteTagsOverviewPart,
		TagPart,
		TitledPersonGridPart,
		PersonExtractInfoPart,
		PersonExtractBannerPart,
		PersonExtractHighlightsPart,
		PersonExtractPositionsPart,
		PersonExtractRelationshipsPart,
		PublicPersonLinkPart,
		PublicPersonButtonPart,


		//
		// Dialogs
		//
		PersonExtractAssociationsPart,
		PersonExtractButtonPart,
		PersonExtractLinkPart,
		PersonTablePart,
		PersonExtractDialog,
		PublicPersonDialog,
		TagsEditorDialog,
	],
	exports: [
		//
		// Components
		//
		OldTagPart,
		PersonBadgePart,
		PersonPositionsPart,
		PersonRolesPart,
		PersonSelectorByApprovedRolePart,
		PersonTagEditorPart,
		PersonTagsOverviewPart,
		SimpleTagEditorPart,
		SiteTagEditorPart,
		SiteTagsOverviewPart,
		TagPart,
		TitledPersonGridPart,
		PersonExtractAssociationsPart,
		PersonExtractButtonPart,
		PersonExtractLinkPart,
		PersonTablePart,
		PersonExtractInfoPart,
		PersonExtractBannerPart,
		PersonExtractHighlightsPart,
		PersonExtractPositionsPart,
		PersonExtractRelationshipsPart,
		PublicPersonLinkPart,
		PublicPersonButtonPart,

		//
		// Dialogs
		//
		PersonExtractDialog,
		PublicPersonDialog,
		TagsEditorDialog,
	],
})

export class SharedPersonModule { }