import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorPageDetails } from '@me-interfaces';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { Observable, ObservableInput, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { AppSessionService } from '../app-session';
import { UtilityService } from '../utility';
import { MeAuthEngine } from './me-auth-engine';

export type AuthEngineName = 'ME';


@Injectable({ providedIn: 'root' })
export class AuthService {

	private _engineName: AuthEngineName | undefined;
	public engineName$ = new ReplaySubject<AuthEngineName>(1);

	public readonly hasWebStorage;

	private _me: MeAuthEngine | undefined;

	public isSignedIn$: Observable<boolean>;

	/**
	 * Firebase Analytics. It is always present with ME Auth.
	 */
	public analytics?: firebase.analytics.Analytics;


	/**
	 * Service that holds the chosen authentication engine.  The service can only be used for
	 * one of the engines and will throw an error if attempted to be used or re-initialized
	 * with a different one.
	 * @param router 
	 */
	constructor(
		private router: Router,
		private util: UtilityService,
	) {

		this.hasWebStorage = this.supportsLocalStorage();

		//
		// If there is an inited AuthEngine, use that engine to determine if there is
		// a login and map the result to a simple true/false.
		//
		this.isSignedIn$ = this.engineName$.pipe(
			switchMap<AuthEngineName, ObservableInput<boolean>>(name => {
				if (name == 'ME') return this.me.hasSession$.pipe(map(hasSession => !!hasSession));
				else return of(false);
			}),
			distinctUntilChanged(),
		);
	}


	/**
	 * The auth service must be initialized before it can be used.
	 * - This function with the appropriate engine is determined in the ROOT bootstrap page by listening to router nav
	 * @param engineName ME
	 * @param appSessionService Used only with the ME engine
	 */
	public init(engineName: AuthEngineName, appSessionService: AppSessionService = undefined,) {

		//
		// Check if already initialized
		//
		if (this._engineName && engineName == this._engineName) return;	// Silently ignore if the requested engine is already initialized
		if (this._engineName && engineName !== this._engineName) throw new Error(`Attempt to initialize the ${engineName} auth engine but already initialized as ${this._engineName}`);

		//
		// Initialize
		//
		if (engineName == 'ME') this._me = new MeAuthEngine(this, this.router, this.hasWebStorage, appSessionService, this.util);
		else throw new Error(`Attempt to initialize with unknown engine: ${engineName}`);

		this._engineName = engineName;
		this.engineName$.next(engineName);
		// this.util.log.techMessage(`AuthService - initialized with ${engineName} auth engine`);

		if (engineName == 'ME') appSessionService.start(this);
	}


	public get engineName() {
		return this._engineName;
	}


	public get me() {
		if (this._engineName == undefined) throw new Error(`Call to auth.me but auth not initialized`);
		if (this._engineName !== 'ME') throw new Error(`Call to auth.me but auth initialized with ${this._engineName} auth engine`);
		return this._me;
	}


	private supportsLocalStorage(): boolean {
		let hasWebStorage = false;

		try {
			const test = 'test-for-support';
			localStorage.setItem(test, test)
			hasWebStorage = localStorage.getItem(test) === test;
			localStorage.removeItem(test);
		}
		catch (e) { hasWebStorage = false; }

		return hasWebStorage;
	}


	//
	// Gets an Id token for the particular engine.  The token string will be prefixed with a token prefix
	// - MEAuthEngine tokens are prefixed with 'firebase:'
	//
	public async GetIdToken(): Promise<string> {

		//
		// Use the observable so we wait until the auth is initialized
		//
		const engineName = await this.engineName$.pipe(take(1)).toPromise();

		if (engineName == 'ME') {

			const user = await this.me.firebaseUser$
				.pipe(
					filter(u => !!u),
					take(1)
				).toPromise();

			const token = await user.getIdToken();
			return 'firebase:' + token;
		}
	}


	/**
	 * Logs an error to Google Analytics and to the console.
	 * @param e 
	 */
	logErrorToAnalytics(e: ErrorPageDetails) {

		this.analytics?.logEvent(firebase.analytics.EventName.EXCEPTION, {
			description: `${e.name}: ${e.message}`,
			name: e.name,
			message: e.message,
			stack: e.stack,
			path: e.path,
			fatal: true,
		});
	}
}