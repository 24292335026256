<div class='person-explorer-demographics-part'>
	<div *ngIf="person.asSingleton.isDeceased">

		<message-box [closeable]='false' alertType='error'>This person is deceased.
		</message-box>
	</div>

	<p *ngIf='!user.canEditDemographics' alertType='warning' [closeable]='false'>You do not have Permissions
		to view Demographics.</p>

	<div *ngIf="user.canEditDemographics">
		<p *ngIf="person.asSingleton.hasOptOutDemographics === 'Y'">This person has Opted out of demographics</p>


		<button *ngIf="!demographics" class="text-btn" (click)="getDemographicsDetails()">Click to See Demographics
			Information</button>

		<ng-cotainer *ngIf="demographics">
			<message-box [closeable]='false'><strong>Important: </strong>This section contains highly
				confidential information that should never be shared without consent from the individual.
			</message-box>



			<table style="width:100%"
				class='pro-table pro-table-with-thin-rows pro-table-no-header pro-table-with-labels'>
				<tr>
					<td>Gender</td>
					<td>{{demographics.gender}}</td>
				</tr>
				<tr>
					<td>Race</td>
					<td>{{demographics.races}}</td>
				</tr>
				<tr>
					<td>Hispanic or Latino</td>
					<td>{{demographics.hispanic}}</td>
				</tr>
				<tr>
					<td>Birthday</td>
					<td>{{demographics.dob}}</td>
				</tr>
				<tr>
					<td>Veteran</td>
					<td>{{demographics.veteran}}</td>
				</tr>

				<tr>
					<td>Immigrant</td>
					<td>{{demographics.immigrant}}</td>
				</tr>

				<tr>
					<td>Native English Speaker</td>
					<td>{{demographics.nativeEnglish}}</td>
				</tr>
				<tr>
					<td>Native Spanish Speaker</td>
					<td>{{demographics.nativeSpanish}}</td>
				</tr>
				<tr>
					<td>Has a handicap or disability</td>
					<td>{{demographics.handicapped}}</td>
				</tr>
			</table>
		</ng-cotainer>
	</div>
</div>