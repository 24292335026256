import { IndustryId } from "@me-interfaces";
import { getIconContext } from "./get-icon-context";
import { Icon } from "./icon";
import { IconContext } from "./icon-context";
import { IconHideTextAt } from "./icon-hide-text-at";
import { IconStyle } from "./icon-style";

/**
 * Return the Icon value for a given IndustryId
 */
export function getIconForIndustry(industryId: IndustryId): Icon {

	if (industryId == IndustryId.Administrative_Support) return Icon.industry_administrativeSupport;
	if (industryId == IndustryId.Agriculture) return Icon.industry_agriculture;
	if (industryId == IndustryId.Animals) return Icon.industry_animals;
	if (industryId == IndustryId.Apparel_Accessories) return Icon.industry_apparel_accessories;
	if (industryId == IndustryId.Arts_Culture) return Icon.industry_arts_culture;
	if (industryId == IndustryId.Automotive) return Icon.industry_automotive;
	if (industryId == IndustryId.Beauty_Cosmetics) return Icon.industry_beauty_cosmetics;
	if (industryId == IndustryId.Childcare_FamilyServices) return Icon.industry_childcare_familyServices;
	if (industryId == IndustryId.Cleaning) return Icon.industry_cleaning;
	if (industryId == IndustryId.Coaching_Mentoring) return Icon.industry_coaching_mentoring;
	if (industryId == IndustryId.Community_Support) return Icon.industry_communitySupport;
	if (industryId == IndustryId.CreativeServices_Multimedia) return Icon.industry_creativeServices_multimedia;
	if (industryId == IndustryId.Education) return Icon.industry_education;
	if (industryId == IndustryId.Entertainment_Recreation) return Icon.industry_entertainment_recreation;
	if (industryId == IndustryId.Environment) return Icon.industry_environment;
	if (industryId == IndustryId.Event_Planning) return Icon.industry_eventPlanning;
	if (industryId == IndustryId.Finance_Accounting) return Icon.industry_finance_accounting;
	if (industryId == IndustryId.Food_Beverage) return Icon.industry_food_beverage;
	if (industryId == IndustryId.Health_Wellness) return Icon.industry_health_wellness;
	if (industryId == IndustryId.Hospitality_Accommodations) return Icon.industry_hospitality_accommodations;
	if (industryId == IndustryId.Lawncare) return Icon.industry_lawncare;
	if (industryId == IndustryId.Legal) return Icon.industry_legal;
	if (industryId == IndustryId.Manufacturing) return Icon.industry_manufacturing;
	if (industryId == IndustryId.Marketing) return Icon.industry_marketing;
	if (industryId == IndustryId.Medical) return Icon.industry_medical;
	if (industryId == IndustryId.Other) return Icon.industry_other;
	if (industryId == IndustryId.Personal_Relationships) return Icon.industry_personal_relationships;
	if (industryId == IndustryId.Philanthropy) return Icon.industry_philanthropy;
	if (industryId == IndustryId.Photography_Media) return Icon.industry_photography_media;
	if (industryId == IndustryId.Property_Management) return Icon.industry_propertyManagement;
	if (industryId == IndustryId.Retail) return Icon.industry_retail;
	if (industryId == IndustryId.Salon_Spa_PersonalCare) return Icon.industry_salon_spa_personalCare;
	if (industryId == IndustryId.Technology) return Icon.industry_technology;
	if (industryId == IndustryId.Trades_Electrician_Plumber) return Icon.industry_trades_electrician_plumber;
	if (industryId == IndustryId.Travel_Tourism) return Icon.industry_travel_tourism;

	return Icon.status_missing;
}


export function getIconContextForIndustry(industryId: IndustryId, iconStyle?: IconStyle, hideText?: IconHideTextAt, text?: string, hasRedFlag?: boolean): IconContext {
	return getIconContext(getIconForIndustry(industryId), iconStyle, hideText, text, hasRedFlag);
}