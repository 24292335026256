import { NavigationEnd, NavigationError, Router } from "@angular/router";
import { AppAreaIdentifier } from '@me-interfaces';
import { UtilityService } from "@me-services/core/utility";
import { BehaviorSubject, combineLatest } from "rxjs";
import { filter, map } from "rxjs/operators";
import { AppAreaPath } from "./app-area-path";
import { mapToIdentifierWithSite, mapToIdentifierWithSiteId } from './map-to-identifier-with-site';



/**
 * An App Area is any tree of routes that start with an identifier path segment,
 * such as the site code, or accId, etc. This class monitors when the URL
 * changes and strips out the identifiers. Each App Area has an observable that
 * broadcasts the latest value. Everytime the router navigates, the corresponding
 * observable is emitted with the current value, or undefined if the URL is not
 * in that specific area. Values are only emitted if they have changed so, for
 * instance, navigating to different URLs within a single site will not re-emit
 * the same site code.  
 */
export class AppAreas {

	/**
	 * The identifier of the currently accessed area
	 */
	public readonly current$ = new BehaviorSubject<AppAreaIdentifier<string | number>>(undefined);

	//
	// Individual identifiers
	//
	private readonly _access$ = new BehaviorSubject<AppAreaIdentifier<string>>(undefined);
	private readonly _admin$ = new BehaviorSubject<AppAreaIdentifier<string>>(undefined);
	private readonly _regionId$ = new BehaviorSubject<AppAreaIdentifier<number>>(undefined);
	private readonly _sites$ = new BehaviorSubject<AppAreaIdentifier<string>>(undefined);
	private readonly _siteCode$ = new BehaviorSubject<AppAreaIdentifier<string>>(undefined);
	private readonly _siteId$ = new BehaviorSubject<AppAreaIdentifier<number>>(undefined);
	private readonly _accId$ = new BehaviorSubject<AppAreaIdentifier<number>>(undefined);
	private readonly _siteAccId$ = new BehaviorSubject<AppAreaIdentifier<number>>(undefined);
	private readonly _sitePicId$ = new BehaviorSubject<AppAreaIdentifier<number>>(undefined);
	private readonly _events$ = new BehaviorSubject<AppAreaIdentifier<string>>(undefined);
	private readonly _eforAll$ = new BehaviorSubject<AppAreaIdentifier<string>>(undefined);
	private readonly _tech$ = new BehaviorSubject<AppAreaIdentifier<string>>(undefined);
	

	//
	// Paths for detecting individual identifiers
	//
	private readonly areaPaths: AppAreaPath[] = [
		{ path: '/access', name: 'User', subject: this._access$, idType: 'Literal' },
		{ path: '/access/admin', name: 'Admin', subject: this._admin$, idType: 'Literal', subPaths: ['national', 'program'] },
		{ path: '/access/admin/national/regions', name: 'Region', subject: this._regionId$, idType: 'Number', subPaths: ['overview'] },
		{ path: '/access/admin/national/sites', name: 'Site', subject: this._siteId$, idType: 'Number', subPaths: ['overview'] },
		{ path: '/access/admin/national/sites/?/accelerators', name: 'Acc', subject: this._accId$, idType: 'Number', subPaths: ['pre-accelerator', 'cohort'] },
		{ path: '/access/admin/communities', name: 'Sites', subject: this._sites$, idType: 'Literal' },
		{ path: '/access/admin/communities', name: 'SiteOld', subject: this._siteCode$, idType: 'String', ignores: ['list', 'common', 'eforall'] },
		{ path: '/access/admin/communities/?/programs/accelerators', name: 'AccOld', subject: this._siteAccId$, idType: 'Number', subPaths: ['pre-accelerator', 'cohort'] },
		{ path: '/access/admin/communities/?/programs/pitch-contests', name: 'Pic', subject: this._sitePicId$, idType: 'Number', subPaths: ['applications', 'teams'] },
		{ path: '/access/admin/communities/eforall', name: 'EforAll', subject: this._eforAll$, idType: 'Literal' },
		{ path: '/access/admin/tech', name: 'Tech', subject: this._tech$, idType: 'Literal' },
	];


	//
	// Fully Qualified identifiers - Sometimes an identifier is only valid in the context of a parent identifier.
	//
	public readonly access$ = this._access$.asObservable();
	public readonly admin$ = this._admin$.asObservable();
	public readonly events$ = this._events$.asObservable();
	public readonly regionId$ = this._regionId$.asObservable();
	public readonly siteCode$ = this._siteCode$.asObservable();
	public readonly sites$ = this._sites$.asObservable();
	public readonly siteId$ = this._siteId$.asObservable();
	public readonly accId$ = combineLatest([this._siteId$, this._accId$]).pipe(map(mapToIdentifierWithSiteId));
	public readonly siteAccId$ = combineLatest([this._siteId$, this._siteAccId$]).pipe(map(mapToIdentifierWithSiteId));
	public readonly sitePicId$ = combineLatest([this._siteCode$, this._sitePicId$]).pipe(map(mapToIdentifierWithSite));
	public readonly eforAll$ = this._eforAll$.asObservable();
	public readonly tech$ = this._tech$.asObservable();


	constructor(router: Router, private url$: BehaviorSubject<string>, private util: UtilityService) {

		router.events
			.pipe(
				filter(e => e instanceof NavigationEnd || e instanceof NavigationError),
				map(() => router.url)
			)
			.subscribe(this.processUrl.bind(this));
	}


	/**
	 * Given a url, split it up, match it to the paths,
	 * and emit the current identifier values.
	 */
	private processUrl(url: string) {

		//
		// Send the new url out urlService.url$
		//
		this.url$.next(url);

		//
		// Process it...
		//
		url = url ?? '';
		const urlParts = url.toLowerCase().split('/');

		for (const areaPath of this.areaPaths) {
			this.processPath(urlParts, areaPath, areaPath.subPaths ?? []);
		}

	}


	/**
	 * Compare a single AppArea with the url to see if it matches.
	 * If so, pull the id off and communicate it out if it has changed. 
	 */
	private processPath(urlParts: string[], areaPath: AppAreaPath, areaSubPaths: string[]) {

		const pathParts = areaPath.path.split('/');
		let identifier: AppAreaIdentifier<number | string> = undefined;
		let subNameSegment: string;
		let subIdSegment: string;

		//
		// If the URL is at least as long then it is a possible match.
		//
		if (urlParts.length >= pathParts.length) {

			let match = true;

			//
			// Check each level in turn to see if they match.
			// Higher-level identifiers, marked with '?', are skipped.
			//
			for (let i = 0; i < pathParts.length; i++) {
				if (pathParts[i] !== '?' && pathParts[i] !== urlParts[i]) {
					match = false;
					break;
				}
			}

			//
			// OK, we got a match. Parse the next level to get the value.
			//
			if (match) {

				let exactMatch = false;

				if (areaPath.idType == 'Literal') {
					//
					// For literal identifiers, we just take the last url segment in
					// the path as a literal value. It's not really needed, but good
					// to pass something at least.
					//
					const id: string = urlParts[pathParts.length - 1];
					const urlBefore = urlParts.slice(0, pathParts.length - 1).join('/');

					identifier = { areaName: areaPath.name, areaCacheKey: urlBefore, id };
					subNameSegment = urlParts[pathParts.length];
					subIdSegment = urlParts[pathParts.length + 1];

					//
					// Check for exact match (this url is IN this area, not beyond it)
					//
					if (pathParts.length == urlParts.length) exactMatch = true;

					else if (pathParts.length + 1 == urlParts.length && areaSubPaths.length) {
						if (areaSubPaths.includes(urlParts[pathParts.length])) exactMatch = true;
					}
				}

				else {
					//
					// For numeric and string identifiers, take the very next segment.
					//
					let id: number | string = urlParts[pathParts.length];


					if (id && !(areaPath.ignores || []).includes(id)) {

						if (urlParts[pathParts.length + 1] == 'v1' || urlParts[pathParts.length + 1] == 'v2') {
							subNameSegment = urlParts[pathParts.length + 2];
							subIdSegment = urlParts[pathParts.length + 3];
						}
						else {
							subNameSegment = urlParts[pathParts.length + 1];
							subIdSegment = urlParts[pathParts.length + 2];
						}

						const urlBefore = urlParts.slice(0, pathParts.length).join('/');
						if (areaPath.idType == 'Number') id = parseInt(id, 10);
						identifier = { areaName: areaPath.name, areaCacheKey: urlBefore, id };
					}


					//
					// Check for exact match (this url is IN this area, not beyond it)
					//
					if (pathParts.length + 1 == urlParts.length) exactMatch = true;

					else if (pathParts.length + 2 == urlParts.length && areaSubPaths.length) {
						if (areaSubPaths.includes(urlParts[pathParts.length + 1])) exactMatch = true;
					}
				}

				if (identifier) {

					//
					// Tack on the subPath if there is one
					//
					if (subIdSegment) {
						identifier.subPath = {
							name: subNameSegment,
							id: +subIdSegment,
						};
					}

					//
					// Exact match
					//
					if (exactMatch) this.current$.next(identifier);

				}
			}
		}

		//
		// Finally, emit if the value is different than before.
		//
		if (!this.util.values.areSame(areaPath.subject.value, identifier)) {
			areaPath.subject.next(identifier);
		}
	}
}