<div class='person-basic-info-editor-part'>
	<me-save-changes-message *ngIf='messagedisplay' [hidden]="messagedisplay.disabled"></me-save-changes-message>
	<div class='person-basic-info-editor-content'>
		<form [formGroup]="form" novalidate>
			<table class='pro-table pro-table-no-header pro-table-with-labels'>
				<tr>
					<td>
						<span title='Prefix'>Prefix</span>
					</td>
					<td>
						<kendo-dropdownlist formControlName="prefixId" [defaultItem]="defaultPrefixItem"
							[data]="prefixes" [textField]="'name'" [valueField]="'prefixId'" [valuePrimitive]="true"
							[value]="form.value.prefixId">
						</kendo-dropdownlist>
					</td>
				</tr>
				<tr>
					<td>
						<span title='First Name'>First</span>
					</td>
					<td><input class="k-textbox" placeholder="First Name" formControlName="firstName" required
							(blur)='constants.blur.fixCaseOfValue(form.controls.firstName)' />
						<div *ngIf='form.controls.firstName.errors' class="k-tooltip-validation">
							<div *ngIf='form.controls.firstName.errors.required'>
								Required field
							</div>
							<div *ngIf='form.controls.firstName.errors.maxlength'>
								{{tooManyCharacters(form.controls.firstName)}}
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<span title='Middle Initial'>M.I.</span>
					</td>

					<td><input class="k-textbox" placeholder="Middle Initial" formControlName="middleInit"
							(blur)='constants.blur.fixCaseOfValue(form.controls.middleInit)' maxlength="1">
					</td>
				</tr>

				<tr>
					<td>
						<span title='Last Name'>Last</span>
					</td>

					<td><input class="k-textbox" placeholder="Last Name" formControlName="lastName" required
							(blur)='constants.blur.fixCaseOfValue(form.controls.lastName)' />

						<div *ngIf='form.controls.lastName.errors' class="k-tooltip-validation">
							<div *ngIf='form.controls.lastName.errors.required'>
								Required field </div>
							<div *ngIf='form.controls.lastName.errors.maxlength'>
								{{tooManyCharacters(form.controls.lastName)}} </div>
						</div>
					</td>
				</tr>

				<tr>
					<td>
						<span title='Suffix'>Suffix</span>
					</td>
					<td>
						<kendo-dropdownlist formControlName="suffixId" [defaultItem]="defaultSuffixItem"
							[data]="suffixes" [textField]="'name'" [valueField]="'suffixId'" [valuePrimitive]="true">
						</kendo-dropdownlist>
					</td>
				</tr>
				<tr>
					<td>
						<span title='Former Name'>Former</span>
					</td>

					<td><input class="k-textbox" placeholder="Former Name" formControlName="formerName"
							(blur)='constants.blur.fixCaseOfValue(form.controls.formerName)' />
						<div *ngIf='form.controls.formerName.errors && form.controls.formerName.errors.maxlength'
							class="k-tooltip-validation">
							{{tooManyCharacters(form.controls.formerName)}}
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<span title='Preferred Name'>Preferred</span>
					</td>

					<td><input class="k-textbox" placeholder="Preferred Name" formControlName="preferredName"
							(blur)='constants.blur.fixCaseOfValue(form.controls.preferredName)' />
						<div *ngIf='form.controls.preferredName.errors && form.controls.preferredName.errors.maxlength'
							class="k-tooltip-validation">
							{{tooManyCharacters(form.controls.preferredName)}}
						</div>
					</td>
				</tr>


				<tr>
					<td>
						<span title="Personal Phone Number">Phone</span>
					</td>
					<td>
						<div class="intl-phone">
							<input class="k-textbox" placeholder="Personal Phone Number" formControlName="phone"
								id="phone" type="tel"
								(blur)='constants.blur.fixPhoneValue(form.controls.phone, telInput)' />
							<div *ngIf='person.doNotContactNote' class='do-not-contact-text'>
								Do Not Contact
							</div>

							<div fxLayout="row" *ngIf='form.controls.phone.errors' class="k-tooltip-validation">
								<div *ngIf='form.controls.phone.errors.invalid'>
									Invalid Phone Number </div>
								<!-- <div *ngIf='form.controls.phone.errors.phoneInUse'>
									Phone already used </div> -->
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<span title='Phone Number Type'>Type</span>
					</td>
					<td>
						<kendo-dropdownlist formControlName="phoneTypeId" [data]="phoneTypes" [textField]="'name'"
							[valueField]="'phoneTypeId'" [valuePrimitive]="true" [itemDisabled]="itemDisabled"
							[disabled]="!form.controls.phone.value || form.controls.phoneTypeId.value === 4">
						</kendo-dropdownlist>
					</td>
				</tr>
			</table>

			<button-container>
				<spinner-button (safeClick)='resetForm()' [disabled]='!form.dirty || updating'>
					Revert </spinner-button>
				<spinner-button #messagedisplay (safeClick)='save()'
					[disabled]='form.invalid || !form.dirty || updating' [spinning]='updating'>
					Save Changes </spinner-button>
			</button-container>
		</form>
	</div>
</div>