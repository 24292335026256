import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {
	CompanyEditorPart,
	CompanyExtractBannerPart,
	CompanyExtractButtonPart,
	CompanyExtractDialog,
	CompanyExtractInfoPart,
	CompanyExtractLinkPart,
	CompanyExtractPositionsPart,
	PublicCompanyButtonPart,
	PublicCompanyDialog,
	PublicCompanyLinkPart,
} from '@me-shared-parts/CM-company';
import { SharedCommonModule } from '@me-shared-parts/UI-common/UI.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';

@NgModule({
	imports: [
		CommonModule,
		DateInputsModule,
		DropDownsModule,
		GridModule,
		InputsModule,
		LayoutModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonToggleModule,
		MatDatepickerModule,
		MatInputModule,
		MatSelectModule,
		MatRadioModule,
		MatExpansionModule,
		MatAutocompleteModule,
		MatListModule,
		MatMenuModule,
		PopupModule,
		SharedCommonModule,
	],
	declarations: [
		CompanyEditorPart,
		CompanyExtractBannerPart,
		CompanyExtractButtonPart,
		CompanyExtractDialog,
		CompanyExtractInfoPart,
		CompanyExtractLinkPart,
		CompanyExtractPositionsPart,
		PublicCompanyButtonPart,
		PublicCompanyDialog,
		PublicCompanyLinkPart,
	],
	exports: [
		CompanyEditorPart,
		CompanyExtractBannerPart,
		CompanyExtractButtonPart,
		CompanyExtractDialog,
		CompanyExtractInfoPart,
		CompanyExtractLinkPart,
		CompanyExtractPositionsPart,
		PublicCompanyButtonPart,
		PublicCompanyDialog,
		PublicCompanyLinkPart,
	],
})

export class SharedCompanyModule { }