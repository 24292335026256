import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId, ApplicationStatusId, DbsAccelerator } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { DroplistItem } from '@me-shared-parts/UI-common';
import { getIconContextForPackage } from '@me-shared-parts/UI-common/icon/get-icon-context-for-package';

@Component({
	selector: 'application-transfer-dialog',
	templateUrl: './application-transfer.dialog.html',
	styleUrls: ['./application-transfer.dialog.scss']
})
export class AccApplicationTransferDialog extends DestroyablePart implements OnInit {
	
	public readonly ApplicationStatusId_TeamPromoted = ApplicationStatusId.TeamPromoted;

	//
	// Configuration for the input fields 
	//
	accTransferId: {
		value: number,
		changed: boolean,
		pending: number,
		valid: boolean,
	} = {
			value: undefined,
			changed: false,
			pending: undefined,
			valid: true,
		};

	readonly: boolean;



	//
	// The action buttons
	//
	transferAction: DialogAction<number> = {
		id: 'transfer',
		enabled: false,
		visible: true,
		label: 'Transfer',
		linkType: 'callback',
		callback: async () => (this.accTransferId.pending),
		willCloseDialog: true,
	};

	cancelAction: DialogAction<void> = {
		id: 'cancel',
		enabled: true,
		visible: true,
		label: 'Cancel',
		linkType: 'no-action',
		willCloseDialog: true,
	};

	actions: DialogAction<unknown>[] = [this.transferAction, this.cancelAction];


	selectedItem: DroplistItem<DbsAccelerator>;
	items: DroplistItem<DbsAccelerator>[] = [];
	language: string;
	applicationStatusId: ApplicationStatusId;


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ applicationId: number, applicationStatusId: number, readonly: boolean }>,
		private ds: DataService,
		public dialogRef: MatDialogRef<AccApplicationTransferDialog>,
	) {
		super();
	}

	async ngOnInit() {
		super.initDestroyable();

		const data = this.dialog.data;

		if (!data?.applicationId) throw new Error('Missing required attribute: applicationId');
		if (!data?.applicationStatusId) throw new Error('Missing required attribute: applicationStatusId');

		this.readonly = data?.readonly;
		this.applicationStatusId = data.applicationStatusId;
		const application = await this.ds.admin.application.getOnePackage(data.applicationId);
		const lang = application.siteProgram.program.language;
		this.language = lang.name;

		if (this.applicationStatusId == ApplicationStatusId.TeamPromoted) return;


		this.accTransferId = { ... this.accTransferId, value: application.accId, pending: application.accId };

		const accs = (await this.ds.admin.accelerator.getAllPackagesAsArray()).filter(p => p.siteProgram.isActive
			&& p.siteProgram.program.languageId == lang.languageId
			&& p.accId != application.accId
			&& p.accStageId <= AccStageId.Interviewing
		);

		this.items = accs.map(acc => {
			return {
				uniqueId: acc.accId,
				data: acc,
				groupName: acc.siteProgram.site.stateName,
				iconContext: getIconContextForPackage(acc, undefined, undefined, acc.explorerName),
			}
		});
	}


	/**
	 * Record the pending value and check if it is valid (within the date range)
	 */
	setPendingValue(accId: number) {

		this.accTransferId.pending = accId;

		this.checkIfCanSave(accId);
	}

	/**
	 * When losing focus and a pending values was added, that pending value may have been
	 * cleaned (e.g. fixCase) so we update the value with the pending value so it is bound
	 * back into the input field and shown to the user. 
	 */
	valueChange(e: DroplistItem) {
		this.accTransferId.changed = true;
		this.setPendingValue(e.uniqueId);
	}

	/**
	 * Look at each value and determine if the save/update button should be enabled
	 */
	checkIfCanSave(transferId: number) {
		this.transferAction.enabled = false;
		if (!transferId) {
			return;
		}

		if (this.accTransferId.value == transferId) return;

		this.accTransferId.valid = !!transferId;

		this.transferAction.enabled = !this.readonly && this.accTransferId.valid;
	}

}