<div style="display:grid; grid-template-columns:1fr 40px;">

	<ol class="breadcrumb" style="max-height:38px; overflow:hidden;">
		<li class="breadcrumb-item" *ngIf='true'>
			<button id="up-path" class="breadcrumb-button" mat-button (click)='goUp()'
				[title]="inlineLabels['Go Up One Level']">
				<i class='fas fa-arrow-up-left'></i>
			</button>
		</li>
		<li *ngFor="let breadcrumb of breadcrumbs; let i = index;" [class]="getBreadcrumbClasses(i)">

			<div *ngIf="breadcrumb.menu && breadcrumb.menu.length > 0; then showMenu else hideMenu"></div>

			<ng-template #showMenu>
				<mat-menu #appMenu="matMenu">
					<button mat-menu-item *ngFor="let menuItem of breadcrumb.menu" [routerLink]="menuItem.path"
						[hidden]="menuItem.siteAdminMenu && !userIsSiteAdmin" class='breadcrumb-menu-item'
						[class.selected-menu-item]='isMenuItemSelected(breadcrumb, menuItem)'>
						<ng-container *ngIf='!menuItem.isLabelKey'>{{menuItem.label}}</ng-container>
						<me-label [key]='menuItem.label' *ngIf='menuItem.isLabelKey'></me-label>
					</button>
				</mat-menu>

				<button class='breadcrumb-button' mat-button [matMenuTriggerFor]="appMenu">
					<ng-container *ngIf='!breadcrumb.isLabelKey'>{{breadcrumb.label}}</ng-container>
					<me-label [key]='breadcrumb.label' *ngIf='breadcrumb.isLabelKey'></me-label>
					<i class="fas fa-caret-down"></i>
				</button>
			</ng-template>

			<ng-template #hideMenu>
				<button mat-button disabled>
					<ng-container *ngIf='!breadcrumb.isLabelKey'>{{breadcrumb.label}}</ng-container>
					<me-label [key]='breadcrumb.label' *ngIf='breadcrumb.isLabelKey'></me-label>
				</button>
			</ng-template>
		</li>
	</ol>


	<button class='breadcrumb-menu-item'
		style='padding:0; border:none; background:transparent; font-size:1.1em; margin-bottom:10px;'
		(click)='toggleNavTabs()'>

		<ng-container *ngIf='layoutService.showNavTabsVisibility$.value === "Auto"'>
			<i *ngIf='!(layoutService.showNavTabs$ | async)' class='fal fa-rectangle-landscape' style='color:#AAA;'></i>
			<i *ngIf='layoutService.showNavTabs$ | async' class='fal fa-folder' style='color:#AAA;'></i>
		</ng-container>

		<ng-container *ngIf='layoutService.showNavTabsVisibility$.value !== "Auto"'>
			<i *ngIf='!(layoutService.showNavTabs$ | async)' class='far fa-rectangle-landscape' style='color:#555;'></i>
			<i *ngIf='layoutService.showNavTabs$ | async' class='far fa-folder' style='color:#555;'></i>
		</ng-container>

	</button>

</div>

<mat-tab-group routerTabs *ngIf="layoutService.showNavTabs$ | async" class='navigation-tabs'>
	<mat-tab routerLink="{{tab.path}}" *ngFor="let tab of tabs">
		<ng-template mat-tab-label>
			<ng-container *ngIf='!tab.isLabelKey'>{{tab.label}}</ng-container>
			<me-label [key]='tab.label' *ngIf='tab.isLabelKey'></me-label>
		</ng-template>
	</mat-tab>
</mat-tab-group>