import { Component } from '@angular/core';
import { environment } from '@me-environment';


@Component({
	selector: 'dev-banner',
	templateUrl: './ACS_dev-banner.part.html',
	styleUrls: ['./ACS_dev-banner.part.scss']
})

export class DevBannerPart {

	isDev = environment.name !== 'ME-PROD';

}