
import { DdProgramTypeRow } from './dd-program-type-row';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';

/**
 * @deprecated Use the DataService instead
 */
export class DdProgramType extends DdObject<DdProgramTypeRow> implements DdProgramTypeRow {

	constructor(dd: OldDomainDataService, data: DdProgramTypeRow) {
		super(data);
	}

	public get programTypeId(): number {
		return this._data.programTypeId;
	}

	public get name(): string {
		return this._data.name;
	}
}