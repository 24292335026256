<app-area-access [areaService]='adminAreaService'>
	<ng-container>
		<div class='program-overview-page'>

			<program-toolbar></program-toolbar>

			<page-tabs [tabNames]='tabNames' [pageService]='pageService' (layoutChange)='onLayoutChange($event)'>
				<ng-template pageTab1>
					<program-views-part [readonly]='readonly'></program-views-part>
				</ng-template>

				<ng-template pageTab2>
					<get-help></get-help>
				</ng-template>
			</page-tabs>

		</div>
	</ng-container>
</app-area-access>