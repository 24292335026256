import { DbConceptName, Person, Site, Tag, TagPrefixId } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { IconContext } from "@me-shared-parts/UI-common";
import { DataService } from "../../../../data.service";
import { ExplorerBoxClass } from "../../../explorer-box/explorer-box";
import { ExplorerTagBox } from "../../../explorer-tag-box/explorer-tag-box";
interface TagsGroupedByPrefix {
	prefix: string,
	icons: IconContext[],
	site: Site,
}

export async function getBoxesForTags(util: UtilityService, ds: DataService, person: Person): Promise<ExplorerTagBox[]> {

	if (person.tags.length == 0) return [];

	const boxes: ExplorerTagBox[] = [];

	// 
	// Filter out the values that aren't displayed like 'hq'
	//
	const personTags = [...person.tags].filter(tag => tag.tagPrefixId != TagPrefixId.HQ);

	//
	// Non site tags
	//
	const nonSiteTags = personTags.filter(tag => !tag.siteId).sort((a, b) => a.fullName > b.fullName ? 1 : -1);
	if (nonSiteTags.length) {
		const generalBox: ExplorerTagBox = {
			explorerBoxClass: ExplorerBoxClass.Tag,
			header: 'General',
			icons: []
		};

		for (const tag of nonSiteTags) {
			generalBox.icons.push({ iconClass: 'fa-regular fa-tag', hideText: 'never', text: tag.fullName });
		}

		boxes.push(generalBox);
	}

	//
	// Site Tags
	//
	const siteTagsGroupedByPrefix: TagsGroupedByPrefix[] = [];

	const tags = await ds.admin.tag.getAllPackagesAsMap();

	for (const tag of personTags.filter(tag => tag.siteId)) {
		const dsTag = tags[tag.tagId];
		const prefixFound = siteTagsGroupedByPrefix.find(t => t.prefix.toUpperCase() == dsTag.tagPrefix.prefix.toUpperCase());
		const icon: IconContext = { iconClass: `${getIconStyleForTag(dsTag)}`, hideText: 'never', text: dsTag.name };
		if (prefixFound) {
			prefixFound.icons.push(icon);
		}
		else {
			siteTagsGroupedByPrefix.push({
				prefix: dsTag.tagPrefix.prefix.toUpperCase(),
				icons: [icon],
				site: dsTag.site
			});
		}
	}

	//
	// Sort first by prefix and then by the tag(icon text)
	//
	siteTagsGroupedByPrefix.sort((a, b) => a.prefix > b.prefix ? 1 : -1)
		.map(siteTag => {
			siteTag.icons.sort((a, b) => a.text > b.text ? 1 : -1);
			return siteTag;
		});

	for (const tag of siteTagsGroupedByPrefix) {
		boxes.push({
			explorerBoxClass: ExplorerBoxClass.Tag,
			header: `${tag.prefix} Tags`,
			button: {
				explorer: { explorableId: { conceptName: DbConceptName.Site, id: tag.site.siteId }, navTooltip: `Site: ${tag.site.name}` }
			},
			icons: tag.icons,
		});

	}

	return boxes;

}


function getIconStyleForTag(dsTag: Tag): string {
	if (dsTag.name.endsWith('consider')) return 'fa-thin fa-tag';
	if (dsTag.name.endsWith('not-approved')) return 'fa-solid color-darkred fa-tag';
	if (dsTag.name.endsWith('current')) return 'fa-solid color-darkgreen fa-tag';
	if (dsTag.name.endsWith('alumni')) return 'fa-solid fa-star color-darkgold';
	return 'fa-regular fa-tag';
}