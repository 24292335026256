import { AccAreaService, AccTeamMemberEditMentorDialogCallbackValue, AccTeamMembersAddDialog, AccTeamMembersEditEntrepreneurDialog, AccTeamMembersEditMentorDialog, AccTeamPageService } from '@ACC-area';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ADDROW_GRID_ACTION_KEY, GridAction, GridColumnType, GridSetup } from '@me-grid';
import { AccStageId, AccTeam } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogAction, DialogService } from '@me-services/ui/dialog';
import { Icon } from '@me-shared-parts/UI-common';
import { BehaviorSubject, combineLatest, mergeMap } from 'rxjs';


interface MemberRow {
	accTeamMemberId: number,
	accTeamId: number,
	accTeamEntrepreneursCount: number,
	personId: number,
	personName: string,
	role: string,
	roleToDisplay: string,
	flags: string,
}

const EDIT_MEMBER_ACTION_KEY = 'edit-member';

@Component({
	selector: 'acc-team-members-view-part',
	templateUrl: './acc-team-members-view.part.html',
})
export class AccTeamMembersViewPart extends DestroyablePart implements OnInit, OnChanges {
	@Input() accTeamId: number;
	private accTeamId$ = new BehaviorSubject<number>(undefined);

	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.Complete);
	readonly: boolean;
	readonlyAccessAtStage: boolean;
	public gridSetup = this.setupGrid(false);

	rows$ = combineLatest([
		this.accAreaService.teams.teams$,
		this.accTeamId$,
	]).pipe(mergeMap(data => this.buildRows(data[0], data[1])));

	constructor(
		private accAreaService: AccAreaService,
		private ds: DataService,
		private pageService: AccTeamPageService,
		private dialogService: DialogService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe([this.accAreaService.access$, this.accessAtStage$], async ([access, accessAtStage]) => {
			this.readonly = access?.root != 'Write';
			this.gridSetup = this.setupGrid(access.root == 'Write');
			this.readonlyAccessAtStage = accessAtStage.access != 'Write';
		});

	}

	ngOnChanges() {
		if (!this.accTeamId) throw new Error('Missing required attribute: teamId');
		if (this.accTeamId != this.accTeamId$.value) this.accTeamId$.next(this.accTeamId);
	}

	async buildRows(
		allAccTeams: ReadonlyArray<AccTeam>,
		accTeamId: number,
	): Promise<MemberRow[]> {

		if (!allAccTeams || !accTeamId) return [];

		const accTeam = allAccTeams.find(team => team.accTeamId === accTeamId);

		const rows = [];

		if (accTeam) {
			const people = await this.ds.admin.person.getManyPackagesAsMap([...accTeam.members.map(m => m.member.personId)]);
			const allPositions = await this.ds.admin.position.getAllAsArray();
			const accTeamEntrepreneursCount = accTeam.members.filter(m => m.member.role === 'E').length;

			for (const member of accTeam.members) {
				const person = people[member.member.personId];
				const position = allPositions.find(position => position.personId === member.member.personId && position.companyId === accTeam.application.companyId);
				const flags = [];
				if (position && position.isAdmin) flags.push('Admin');
				if (position && position.isFounder) flags.push('Founder');

				rows.push({
					accTeamMemberId: member.member.accTeamMemberId,
					accTeamId: member.member.accTeamId,
					accTeamEntrepreneursCount,
					personId: person.personId,
					personName: person.fullName,
					role: member.member.role,
					roleToDisplay: this.getRole(member.member.role),
					flags: flags.join(', '),
				});
			}

		}

		return rows.sort((a, b) => a.name < b.name ? -1 : 1);
	}


	private setupGrid(canWrite: boolean): GridSetup<MemberRow> {

		const mentorQuit = (row: MemberRow) => {
			return row.role === 'X' ? 'Mentor Quit!' : undefined;
		};
		const mentorQuitFlag = (row: MemberRow) => {
			return row.role === 'X' ? true : false;
		};


		const setup: GridSetup<MemberRow> = {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Member",
			rowPluralName: "Members",
			rowKey: "accTeamMemberId",
			stateKey: "adm-acc-team-members-part",
			canAdd: canWrite,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: "accTeamMemberId", header: "accTeamMemberId", width: 150, type: GridColumnType.number, hidden: true },
				{ field: "roleToDisplay", header: "Role", width: 100, type: GridColumnType.text, strikethrough: mentorQuitFlag, tooltip: mentorQuit },
				{ field: "flags", header: "Flags", width: 140, type: GridColumnType.text },
			],
			actions: [
				{ key: EDIT_MEMBER_ACTION_KEY, icon: Icon.action_edit, label: 'Edit Member', enabled: false },
			],
			initialState: {
				sort: [{ field: 'PERSON_name', dir: 'asc' }],
			},
			actionEnabler: this.gridActionEnabler.bind(this),
		};

		return setup;
	}

	gridActionEnabler(action: GridAction, rows: MemberRow[]) {
		if (rows.length == 0) return false;

		if (action.key == EDIT_MEMBER_ACTION_KEY) {
			if (this.readonly) return false;
		}
		return true;
	}

	async gridActionHandler(action: { actionKey: string, rows: MemberRow[] }) {
		const row = action.rows[0];

		if (action.actionKey == EDIT_MEMBER_ACTION_KEY) {
			if (this.readonly) return;
			if (row.role === 'E') this.openEditEntrepreneurDialog(row);
			else this.openEditMentorDialog(row);
		}

		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {
			if (this.readonly) return;

			if (this.readonlyAccessAtStage) {
				await this.ds.dialogService.showMessage(`Cannot add team members as the accelerator is marked complete.`, 300, 170);
			}
			else this.openAddDialog();
		}

	}


	getRole(role: string) {
		if (role === 'E') return 'Entrepreneur';
		else if (role === 'M') return 'Mentor';
		else if (role === 'C') return 'Coach';
		else if (role === 'X') return 'Mentor';
	}

	/**
	 * Opens a dialog to add
	 */
	async openAddDialog() {

		const action: DialogAction<{ role: 'E' | 'M' | 'C', personId: number }> = await this.dialogService.showCustom(AccTeamMembersAddDialog, {
			data: { accTeamId: this.accTeamId },
		}, 400, 300);


		const id = action?.id;

		if (id == 'add') {
			if (!action.callbackResult) return;
			await this.accAreaService.teams.actions.addMember({ accTeamId: this.accTeamId, ...action.callbackResult });
		}

	}


	/**
	 * Opens a dialog to edit Mentor
	 */
	async openEditMentorDialog(row: MemberRow) {


		const payload = { readonly: this.readonly, memberName: row.personName, accTeamMemberId: row.accTeamMemberId, role: row.roleToDisplay };

		const action: DialogAction<AccTeamMemberEditMentorDialogCallbackValue> = await this.dialogService.showCustom(AccTeamMembersEditMentorDialog, { data: payload }, 400, 200);


		const id = action?.id;

		if (id == 'update') {
			const callback = action.callbackResult;
			this.accAreaService.teams.actions.setMentorRole({ accTeamMemberId: callback.accTeamMemberId, role: callback.role, accTeamId: this.accTeamId });
		}

		if (id == 'remove') {

			const yes = await this.dialogService.confirm(
				`It is generally better to mark mentors as Quit instead of removing them. Are you sure you want to remove mentor ${row.personName}?`,
				400, 250, 'Remove', 'Cancel'
			);

			if (yes) this.accAreaService.teams.actions.removeMentor({ accTeamMemberId: row.accTeamMemberId });
		}

	}


	async openEditEntrepreneurDialog(row: MemberRow) {

		const payload = { readonly: this.readonly, memberName: row.personName, accTeamMemberId: row.accTeamMemberId };

		const action: DialogAction = await this.dialogService.showCustom(AccTeamMembersEditEntrepreneurDialog, { data: payload }, 400, 200);
		const id = action?.id;

		if (id == 'remove') {

			if (id == 'remove' && row.accTeamEntrepreneursCount <= 1) {
				await this.dialogService.showMessage(`${row.personName} cannot be removed because there must be at least one entrepreneur on the team.`, 400, 250, 'Okay');
			}

			else {
				const yes = await this.dialogService.confirm(`Are you sure you want to remove the entrepreneur ${row.personName} from the team?`, 400, 250, 'Remove', 'Cancel');
				if (yes) this.accAreaService.teams.actions.removeEntrepreneur({ accTeamMemberId: row.accTeamMemberId, accTeamId: row.accTeamId });
			}
		}
	}
}
