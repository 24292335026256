import { AdminAreaService } from '@ADMIN-area-service';
import { RegionAreaService } from '@REGION-area';
import { SiteAreaService } from '@SITE-area';
import { Component, ViewEncapsulation } from '@angular/core';
import { Event, NavigationEnd, NavigationError, NavigationExtras, NavigationStart, Router } from '@angular/router';
import { OldAccAreaService } from '@me-acc-area-old';
import { PicAreaService } from '@me-pic-area';
import { AppSessionService } from '@me-services/core/app-session';
import { AuthService } from '@me-services/core/auth';
import { DexieService } from '@me-services/core/dexie';
import { FuncService } from '@me-services/core/func';
import { OldDomainDataService } from '@me-services/core/old-dd';
import { ErrorPageService } from '@me-services/ui/error-page';
import { GoogleApiService } from '@me-services/ui/google-api';
import { MarkdownOverrideService } from '@me-services/ui/markdown';
import { PageSpinnerService } from '@me-services/ui/page-spinner';
import { UserAreaService } from '@me-user-area';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OldSiteAreaService } from './admin-old/SITE/area/site-area.service';
import { SitesAreaService } from './admin-old/SITES/area/sites-area.service';


@Component({
	selector: 'bootstrap-page',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './bootstrap.page.html',
	styleUrls: ['./bootstrap.page.scss'],
})

export class BootstrapPage {

	atRootUrl$: Observable<boolean>;

	constructor(
		private router: Router,
		private googleApi: GoogleApiService,

		//
		// IMPORTANT - do not remove these parameters, even if they are seemlingly
		// not used. The services will be instantiated and used from here on down.
		//
		public pageSpinner: PageSpinnerService,
		public errorPage: ErrorPageService,
		private appSession: AppSessionService,
		public auth: AuthService,
		private dexie: DexieService,

		dd: OldDomainDataService,
		func: FuncService,
		markdownOverrideService: MarkdownOverrideService,
		userAreaService: UserAreaService,
		adminAreaService: AdminAreaService,
		regionAreaService: RegionAreaService,
		sitesAreaService: SitesAreaService,
		siteAreaService: SiteAreaService,
		oldSiteAreaService: OldSiteAreaService,
		oldAccAreaService: OldAccAreaService,
		picAreaService: PicAreaService,

	) {

		//
		// Monkeypatch the router.navigate method to log when we change pages
		// and fix query parameters in the url.
		//
		const navigate = router.navigate;
		router.navigate = function (commands: any[], extras?: NavigationExtras) {

			//
			// handle query params (so they don't get URL encoded)
			//
			if (commands.length == 1) {
				const command = commands[0];
				if (command.split) {
					const parts = command.split('?');
					if (parts.length > 1) {
						const queryParams = {};
						const qparts = parts[1].split('&');
						for (const qpart of qparts) {
							const param = qpart.split('=');
							queryParams[param[0]] = param[1];
						}
						extras = extras || {};
						extras['queryParams'] = queryParams;
						commands = [parts[0]];
					}
				}
			}

			return navigate.apply(router, [commands, extras]);
		}

		this.atRootUrl$ = router.events.pipe(
			filter(e => e instanceof NavigationEnd || e instanceof NavigationError),
			map((e: NavigationEnd | NavigationError) => e.url == '/'),
		);


		router.events.subscribe((event: Event) => {
			if (event instanceof NavigationStart) {
				//
				// Initialize the ME auth engine which starts Firebase,
				// Firebase UI and begins listening for auth events.
				//
				this.auth.init('ME', this.appSession);
			}
		});

	}


	addScriptTag(api) {
		const script = document.createElement('script');
		script.src = api;
		script.defer = true;
		document.getElementsByTagName('head')[0].appendChild(script);
	}
}
