/**
 * @deprecated Use the DataService instead
 */
export abstract class DdObject<DATA_TYPE> {

	protected readonly _data: DATA_TYPE;
	public get data() { return { ...this._data } }

	constructor(data: DATA_TYPE) {
		this._data = data;
	}
}