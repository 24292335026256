import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { MinimalTag, Old_Tag } from '@me-interfaces';


@Component({
	selector: 'old-tag',
	templateUrl: './SHR-CMN_old-tag.part.html',
	styleUrls: ['SHR-CMN_old-tag.part.scss'],
})
export class OldTagPart implements OnInit {
	@Input() tag: MinimalTag | Old_Tag;
	@Output() tagClicked?= new EventEmitter();
	@Input() clickable: boolean;
	@Input() disabled?: boolean;
	@Input() showPrefix = true;


	ngOnInit() {
		this.onLoad();
	}


	@HostListener('load')
	onLoad() {
		if (this.tag.prefixName == 'eforall') this.eforall = true;
		else if (this.tag.prefixName == 'hq') this.hq = true;
		else if (this.tag.prefixName == 'lang') this.lang = true;
		else if (this.tag.prefixName == 'specialty') this.specialty = true;
		else if (this.tag.tagName.includes('not-approved')) this.notApproved = true;
		else if (this.tag.isAutoTag || this.tag.tagName == 'follower') this.site = true;
		else this.manualTag = true;

		if (!this.clickable) {
			if (this.disabled) this.restricted = true;
		}
		else this.enabled = true;
	}

	@HostBinding('class.enabled') enabled;

	@HostBinding('class.restricted') restricted;

	@HostBinding('class.eforall') eforall;

	@HostBinding('class.hq') hq;

	@HostBinding('class.lang') lang;

	@HostBinding('class.specialty') specialty;

	@HostBinding('class.site') site;

	@HostBinding('class.manual-tag') manualTag;

	@HostBinding('class.not-approved') notApproved;

	@HostListener('click')
	onClick() {
		if (this.clickable) this.tagClicked.emit(this.tag);
	}
}
