<div *ngIf='data$ | async; let data' class='acc-details-view'>


	<div class="acc-details-content">
		<div>
			<me-h3>{{data.accelerator.name}} Accelerator</me-h3>
			<table style="width: 100%;"
				class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>
				<tr *ngIf='data.accelerator.applStartUTC'>
					<td>Applications Start</td>
					<td>{{data.accApplicationsStartDate}}</td>
				</tr>
				<tr *ngIf='data.accelerator.applStartUTC'>
					<td>Applications Due</td>
					<td>{{data.accApplicationsDueDate}}</td>
				</tr>
				<tr>
					<td>Accelerator Start</td>
					<td> {{data.accStartDate}}</td>
				</tr>

				<tr>
					<td>Submitted Applications</td>
					<td>{{data.counts.numSubmittedApplications}}</td>
				</tr>
				<tr [class.apps-still-open-error]='data.counts.numApplicationsStillOpen>0 && data.counts.numTeams>0'>
					<td>Applications Still Open</td>
					<td>{{data.counts.numApplicationsStillOpen}}</td>
				</tr>
				<tr>
					<td>Teams</td>
					<td>{{data.counts.numTeams}}</td>
				</tr>
				<tr>
					<td>Entrepreneurs</td>
					<td>{{data.counts.numEntrepreneurs}}</td>
				</tr>
				<tr>
					<td>Mentors</td>
					<td>{{data.counts.numMentors}}</td>
				</tr>
			</table>
		</div>


		<div>
			<me-h3>Stage</me-h3>
			<acc-stage-advancer-part></acc-stage-advancer-part>
		</div>


		<div>
			<me-h3>Quarterly Goals</me-h3>
			<p>
				Toggle which quarters cohort entrepreneurs are allowed to add/edit their goals for.
				You can see what they've entered in the <i>Goals and Progress</i> view of the <i>Cohort</i> tab.
			</p>
			<acc-quarterly-controller [areaService]="accAreaService"></acc-quarterly-controller>
		</div>


		<div *ngIf="data.problems.length > 0">
			<me-h3>Problems</me-h3>

			<div style="margin-bottom: 1em">Please fix the following data issues as soon as possible.</div>

			<div *ngFor="let problem of data.problems" class="stage-error-message">
				{{ problem }}
			</div>
		</div>
	</div>

</div>