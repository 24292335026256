import { Injectable } from '@angular/core';
import { PageTabsLayoutPageService } from '@me-shared-parts/UI-common';


/**
 * The list of tabs on this page
 */
export enum AccTeamPageTabId {
	Team = 1,
	Overview = 2,
}

/**
 * The list if main tab droplist views on this page
 */
export enum AccTeamPageViewId {
	Members = 1,
	Goals = 2,
}

@Injectable({ providedIn: 'root' })
export class AccTeamPageService extends PageTabsLayoutPageService<AccTeamPageTabId, AccTeamPageViewId>{

	constructor() {
		super(AccTeamPageService.name, AccTeamPageTabId.Team, AccTeamPageViewId.Members);
	}
}