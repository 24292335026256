import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DdNearbyVenue, DdVenue } from '@me-services/core/old-dd';
import { UtilityService } from '@me-services/core/utility';
import { VenueService } from '../../SHR-ED_venue.service';

@Component({
	selector: 'edit-venue-part',
	templateUrl: './SHR-ED_edit-venue.part.html',
	styleUrls: ['./SHR-ED_edit-venue.part.scss']
})
export class VenueEditPart implements OnChanges {
	@Input() venue: DdNearbyVenue;

	@Output() venueChanged = new EventEmitter<DdVenue>();

	orgValues = {
		displayedName: '',
		publicNotes: '',
		internalNotes: '',
		isActive: true
	}

	newValues = {
		displayedName: '',
		publicNotes: '',
		internalNotes: '',
		isActive: true
	}

	changedSomething = false;

	constructor(
		private venueService: VenueService,
		private util: UtilityService,) {
	}


	ngOnChanges() {
		this.initializeEvent(this.venue);
	}

	initializeEvent(venue: DdNearbyVenue) {
		if (!venue) return;

		// Initialize orgValues and newValues
		this.orgValues.displayedName = this.newValues.displayedName = venue.displayedName;
		this.orgValues.publicNotes = this.newValues.publicNotes = venue.publicNotes || '';
		this.orgValues.internalNotes = this.newValues.internalNotes = venue.internalNotes || '';
	}

	/**
	 * Event raised when the value of the public and internal notes field changes.
	 */
	valueChange(field: 'publicNotes' | 'internalNotes', text: string) {
		this.newValues[field] = text;
		this.newValues[field] = text.trim();
		this.determineCanSave();
	}

	/**
	 * Event raised when the value of the displayed name field changes.
	 */
	onInput(field: 'displayedName', e) {
		const text = (e.target.value ?? '').trim();
		this.newValues[field] = text;
		this.determineCanSave();
	}

	/**
	 * Event raised when the public notes field loses focus.
	 */
	onBlur() {
		this.determineCanSave();
	}

	determineCanSave() {
		this.changedSomething = !this.util.values.areSame(this.orgValues, this.newValues);
	}

	async updateVenue() {
		const venue = await this.venueService.update(this.venue.venueId, this.newValues);
		this.venueChanged.emit(venue);
	}

}