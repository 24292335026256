import { Component, Input, OnInit } from '@angular/core';
import { ADDROW_GRID_ACTION_KEY, DBLCLICK_GRID_ACTION_KEY, GridAction, GridColumnType, GridSetup, REFRESH_GRID_ACTION_KEY, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { DbConceptDataType, DbConceptName, DbcEventAttendee, DbsEvent, DbsPerson } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogService } from '@me-services/ui/dialog';
import { Icon } from '@me-shared-parts/UI-common';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AdminAreaService } from '../../../../../admin/admin-area/area/admin-area.service';
import { EventAttendeeDialog } from './dialog/SHR-ED_event-attendee.dialog';

export interface EventAttendeeRow extends DbcEventAttendee {
	person: DbsPerson,
	name: string,
	phone: string,
	email: string,
	cityAndState: string,
}



const EDIT_ATTENDEE_ACTION_KEY = 'edit-attendee';
const DELETE_ATTENDEE_ACTION_KEY = 'delete-attendee';
const TEXT_PERSON_GRID_ACTION_KEY = "text-person";
const CALL_PERSON_GRID_ACTION_KEY = "call-person"
const EMAIL_PERSON_GRID_ACTION_KEY = "email-person";

@Component({
	selector: 'event-attendees-view',
	templateUrl: './SHR-ED_event-attendees.part.html',
	styleUrls: ['SHR-ED_event-attendees.part.scss'],
})
export class EventAttendeesPart implements OnInit {

	@Input() readonly: boolean;
	@Input() eventId: number;

	public gridSetup = this.setupGrid();

	public rows$: Observable<EventAttendeeRow[]>;

	constructor(
		private adminAreaService: AdminAreaService,
		private ds: DataService,
		private dialogService: DialogService,
	) { }

	ngOnInit() {
		this.rows$ = this.ds.admin.event.observeEvent(this.eventId)
			.pipe(mergeMap(async event => await this.createRows(event)));

		if (this.readonly) this.gridSetup.canAdd = false;
	}

	async createRows(event: DbsEvent): Promise<EventAttendeeRow[]> {
		const attendees = event?.attendees || [];
		const people = await this.ds.admin.person.getManyPackagesAsMap([...attendees.map(attendee => attendee.personId)]);

		return attendees.map(attendee => {
			const person = people[attendee.personId]?.asSingleton;
			return {
				...attendee,
				cityAndState: person?._entityExtracts.zip?.cityAndState || '',
				email: person._email,
				name: person._name,
				phone: person.phone,
				person,
			};
		})
	}


	private setupGrid(): GridSetup<EventAttendeeRow> {
		return {
			size: {
				fitTo: 'INLINE',
				height: 250,
				shrinkBy: 0,
			},
			rowSingularName: "Attendee",
			rowPluralName: "Attendees",
			rowKey: "personId",
			stateKey: "event-attendees-view-part",
			canAdd: true,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: "eventId", header: "eventId", width: 70, type: GridColumnType.number, hidden: true, },
				{ field: "personId", header: "personId", width: 70, type: GridColumnType.number, hidden: true, },
				{ field: "status", header: "Status", width: 80, type: GridColumnType.text },
				{ field: "name", header: "Name", width: 100, type: GridColumnType.text },
				{ field: "phone", header: "Phone", width: 50, type: GridColumnType.text, hidden: true },
				{ field: "email", header: "Email", width: 110, type: GridColumnType.text, hidden: true, },
				{ field: "cityAndState", header: "City", width: 110, type: GridColumnType.text },
			],
			actions: [
				{ key: EDIT_ATTENDEE_ACTION_KEY, icon: Icon.action_edit, label: 'Edit Attendee' },
				{ key: DELETE_ATTENDEE_ACTION_KEY, icon: Icon.action_delete, label: 'Delete Attendee' },
				{ key: EMAIL_PERSON_GRID_ACTION_KEY, icon: Icon.action_email, label: 'Email Attendee' },
				{ key: TEXT_PERSON_GRID_ACTION_KEY, icon: Icon.action_sms, label: 'Text Attendee' },
				{ key: CALL_PERSON_GRID_ACTION_KEY, icon: Icon.action_call, label: 'Call Attendee' }
			],
			actionEnabler: this.gridActionEnabler.bind(this),
			initialState: {
				sort: [{ field: 'name', dir: 'asc' }],
			},
		};
	}


	/**
	 * Whenever the selection changes, the Grid component will call this for each action
	 * to set whether the action should be enabled or disabled based on the selection.
	 */
	gridActionEnabler(action: GridAction, rows: EventAttendeeRow[]) {

		const row = rows[0];

		if (action.key == EDIT_ATTENDEE_ACTION_KEY || action.key == DELETE_ATTENDEE_ACTION_KEY) {
			return true;
		}
		if (action.key == TEXT_PERSON_GRID_ACTION_KEY || action.key == CALL_PERSON_GRID_ACTION_KEY) return !!row.phone;
		if (action.key == EMAIL_PERSON_GRID_ACTION_KEY) return !!row.email;

	}


	/**
	 * Handle events from the grid
	 */
	async gridActionHandler(action: { actionKey: string, rows: EventAttendeeRow[] }) {

		if (action.actionKey == REFRESH_GRID_ACTION_KEY) {
		}

		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {

			if (this.readonly) return;

			const eventAttendee: DbcEventAttendee = {
				_concept: DbConceptName.EventAttendee,
				_dt: DbConceptDataType.AdminAreaData,
				eventId: this.eventId,
				personId: undefined,
				status: undefined,
				createdUTC: undefined,
				createdByPersonId: undefined,
				updatedUTC: undefined,
				updatedByPersonId: undefined,
			};

			await this.dialogService.showCustom(
				EventAttendeeDialog,
				{
					data: {
						readonly: this.readonly,
						eventAttendee,
						isAdd: true,
					}
				},
				300, 280
			);

		}


		const row = action.rows[0];

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}


		else if (action.actionKey == DELETE_ATTENDEE_ACTION_KEY) {

			if (this.readonly) return;

			const success = this.adminAreaService.events.deleteAttendee({
				eventId: row.eventId,
				personId: row.personId,
			});
		}


		else if (action.actionKey == DBLCLICK_GRID_ACTION_KEY || action.actionKey == EDIT_ATTENDEE_ACTION_KEY) {
			await this.dialogService.showCustom(
				EventAttendeeDialog,
				{
					data: {
						readonly: this.readonly,
						eventAttendee: row,
						isAdd: false,
					}
				},
				300, 250
			);
		}

		else if (action.actionKey == EMAIL_PERSON_GRID_ACTION_KEY) {
			if (row.email) window.open(`mailto:${row.email}`);
		}
		else if (action.actionKey == TEXT_PERSON_GRID_ACTION_KEY) {
			if (row.phone) window.open(`sms:${row.phone}`);
		}
		else if (action.actionKey == CALL_PERSON_GRID_ACTION_KEY) {
			if (row.phone) window.open(`tel:${row.phone}`);
		}
	}

}
