import { MenuItem } from "@me-interfaces";


export const myMenu: MenuItem = {
	label: ':My',
	label_collapsed: ':Me',
	routerLink: '/access/my',
	icon: 'fas fa-child',

	subMenu: [
		{
			label: ':Dashboard',
			routerLink: '/access/my/dashboard',
			icon: 'fas fa-grip-horizontal',
		},
		{
			label: ':Profile',
			routerLink: '/access/my/profile',
			icon: 'fas fa-id-card',
		},
		{
			label: ':Programs',
			routerLink: '/access/my/programs',
			icon: 'fas fa-hand-holding-heart',
		},
		{
			label: ':Volunteering',
			routerLink: '/access/my/volunteering',
			icon: 'fas fa-hands-helping',
		},
		{
			label: ':Communities',
			routerLink: '/access/my/sites',
			icon: 'fas fa-map-marker-alt',
		},
	]
};