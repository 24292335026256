import { AccAreaService, AccPreAcceleratorPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { IconContext } from '@me-shared-parts/UI-common';
import { combineLatest, mergeMap } from 'rxjs';
import { AccPreAcceleratorStageId } from '../../acc-application-status-helpers';
import { buildReaderGeneralCounts } from './build-reader-general-counts';


// TODO: Discuss with Peter and remove this
export interface StatusIcon {
	icon: IconContext;
	link?: string;
}

@Component({
	selector: 'acc-read-tab-part',
	templateUrl: './acc-read-tab.part.html',
	styleUrls: ['./acc-read-tab.part.scss'],
})
export class AccReadTabPart extends DestroyablePart implements OnInit {


	accStageId: AccPreAcceleratorStageId = AccStageId.Reading;


	public generalCounts$ = combineLatest([
		this.accAreaService.accessAndId$,
		this.accAreaService.applications.applications$,
		this.accAreaService.reading.readers$,
		this.accAreaService.reading.assessments$,
	]).pipe(
		mergeMap(async ([accessAndId, applications, readers, assessments]) => buildReaderGeneralCounts(this.ds, this.util, this.accStageId, applications, readers, assessments))
	);


	constructor(
		private ds: DataService,
		private util: UtilityService,
		private accAreaService: AccAreaService,
		public pageService: AccPreAcceleratorPageService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();
	}

}
