import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DbsEvent, EventAndHost, ProgramEvents, RoutePermission } from '@me-interfaces';
import { DdAccRow, DdPicRow, OldDomainDataService } from '@me-services/core/old-dd';
import { getIconClass, getIconContext, getIconForEventType } from '@me-shared-parts/UI-common';
import * as moment from 'moment';


export interface EventListRow {
	event: DbsEvent,
	icon: string,
	venueName: string,
	eventType: string,
	eventName: string,
	toolName: string,
	toolState: string,
	toolUntil: string,
}

interface EventItem {
	accId?: number,
	picId?: number,
	siteId: number,
	siteCode: string,
	programName: string,
	events: EventListRow[]
}

@Component({
	selector: 'events-list',
	templateUrl: './SHR-CMN_events-list.part.html',
	styleUrls: ['SHR-CMN_events-list.part.scss'],
})
export class EventsListPart implements OnChanges {

	/** @deprecated Use eventsAndHosts instead */
	@Input() events: DbsEvent[];

	@Input() eventsAndHosts: EventAndHost[];
	@Output() eventClicked = new EventEmitter<EventListRow>();


	currentUTC: number = (new Date().getTime() / 1000);

	programs: EventItem[] = [];


	constructor(
		private dd: OldDomainDataService,
	) {
	}

	ngOnChanges() {
		moment.locale('en');

		if (this.eventsAndHosts) this.events = this.eventsAndHosts.map(eah => eah.event);

		if (!this.events || !this.events.length) {
			this.programs = [];
			return;
		}


		this.programs = this.events.reduce((a, event) => {

			let pe: EventItem = a.find(pe => (event.accId && pe.accId == event.accId) || (event.picId && pe.picId == event.picId));

			//
			// checks if the program event already exists in the list, if not create and push the program event
			//
			if (!pe) {
				let acc: DdAccRow;
				let pic: DdPicRow;
				let programName;
				if (event.accId) {
					acc = this.dd.accs.data.find(acc => acc.accId == event.accId);
					const program = this.dd.programs.data.find(pgm => pgm.programId == this.dd.sitePrograms.data.find(sp => sp.siteProgramId == acc.siteProgramId).programId);
					programName = `${acc.name} ${program.name}`;
				}
				else if (event.picId) {
					pic = this.dd.pics.data.find(pic => pic.picId == event.picId);
					const program = this.dd.programs.data.find(pgm => pgm.programId == this.dd.sitePrograms.data.find(sp => sp.siteProgramId == pic.siteProgramId).programId);
					programName = `${pic.name} ${program.name}`;
				}

				a.push(pe = {
					accId: event.accId || undefined,
					picId: event.picId || undefined,
					siteId: event.siteId,
					siteCode: event.siteId ? this.dd.sites.data.find(site => site.siteId == event.siteId).code : '',
					programName,
					events: [],
				});
			}

			const eventType = this.dd.eventTypes.getById(event.eventTypeId);
			const icon = getIconClass(getIconForEventType(event.eventTypeId), 'fa-duotone');
			const venueName = event.venueId ? this.dd.venues.getById(event.venueId).displayedName || "" : "Online";
			const eventTypeName = eventType.name || "";
			const eventName = eventType.fullName || "";
			let toolName;
			let toolState;
			let toolUntil;

			if (eventType.toolNameLabelKey) {
				const toolStateAndUntil = this.getToolState(event);
				toolName = eventType.toolNameLabelKey || "";
				toolState = toolStateAndUntil.state;
				toolUntil = toolStateAndUntil.until || '';
			}


			pe.events.push({
				event,
				eventName,
				eventType: eventTypeName,
				icon,
				toolName,
				toolState,
				toolUntil,
				venueName,
			});

			return a;

		}, [])
	}


	formatEvent(eventStartUTC: number) {
		return moment(eventStartUTC * 1000).format('YYYY-MM-DD (ddd)');
	}

	getClass(event: DbsEvent) {
		return getIconClass(getIconForEventType(event.eventTypeId));
	}

	formatDate(timeUTC: number) {
		return moment(timeUTC * 1000).format('ddd, MM/DD/YY');
	}

	formatTime(timeUTC: number) {
		return moment(timeUTC * 1000).format('h:mm a');
	}

	formatEndDate(event) {
		return moment(event.endUTC * 1000).format('ddd, MM/DD/YY');
	}

	formatEndTime(event) {
		return moment(event.endUTC * 1000).format('h:mm a');
	}

	getDate(event) {
		const start = this.formatDate(event.startUTC);
		const end = this.formatEndDate(event);

		if (start == end) return true;
		else return false;
	}

	async editEvent(e: EventListRow) {

		this.eventClicked.emit(e);
	}


	getToolState(event: DbsEvent) {
		//Before toolStart
		if (this.currentUTC < event.toolStartUTC) return { state: 'Off', until: ` until ${this.formatDate(event.toolStartUTC)} ${this.formatTime(event.toolStartUTC)}` };
		//Between toolStart and toolEnd
		else if (event.toolStartUTC < this.currentUTC && this.currentUTC < event.toolEndUTC) return { state: 'On', until: ` until ${this.formatDate(event.toolStartUTC)} ${this.formatTime(event.toolStartUTC)}` };
		//After toolEnd
		else return { state: 'Off' };
	}

	public isReadOnly(events: ProgramEvents) {
		return events.routePermission !== RoutePermission.Edit
	}
}
