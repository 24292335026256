import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { DbdEventType, Event, Link } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { DialogService } from '@me-services/ui/dialog';
import { UrlService } from '@me-services/ui/url';
import { ShowEventDialogService } from '@me-shared-parts/ED-editors';
import { convertEventToMutable } from '@me-shared-parts/ED-editors/shared-editors/event/convert-event-to-mutable';
import { DatesProcessor } from '@me-shared-parts/ED-editors/shared-editors/event/event-dialog/dates-processor';
import { getIconContext, Icon } from '@me-shared-parts/UI-common';
import { getIconBarSelections, IconBarIcon } from '@me-shared-parts/UI-common/icon-bar';
import { IconBarPart, IconBarSelections } from '@me-shared-parts/UI-common/icon-bar/SHR-CMN_icon-bar.part';
import { DataService } from '../../../data.service';
import { ExplorerBox } from '../../explorer-box/explorer-box';
import { LinkOrExplorable } from '../../link-or-explorerable';
import { getBoxesForAwards } from './boxes/get-boxes-for-awards';
import { getButtons } from './get-buttons';
import { views, VIEW_AWARDS, VIEW_DETAILS } from './views';

const STORAGE_KEY = 'EventExplorerIconSelection';

@Component({
	selector: 'event-explorer',
	templateUrl: './event-explorer.part.html',
	styleUrls: ['./event-explorer.part.scss'],
})
export class EventExplorerPart implements OnChanges {

	@Input() public event: Event;
	@ViewChild('iconBar') iconBar: IconBarPart;

	public views = views;
	public icons = {
		event: getIconContext(Icon.concept_event),
		eventIcon: getIconContext(Icon.concept_event),
		toolDisabled: getIconContext(Icon.tool_disabled),
	};


	public selectedViews: IconBarSelections = undefined;
	public buttons: LinkOrExplorable[] = [];
	public awardBoxes: ExplorerBox[] = [];
	public eventType: DbdEventType;
	public toolLink: Link;
	public dates: DatesProcessor;

	constructor(
		public util: UtilityService,
		private ds: DataService,
		private dialogService: DialogService,
		private urlService: UrlService,
		private showEventDialogService: ShowEventDialogService,
	) { }


	async ngOnChanges() {
		if (!this.event) throw new Error(`Missing required attribute: event`);
		this.icons.eventIcon.text = this.event.name;
		this.eventType = this.ds.domain.eventType.getOne(this.event.eventTypeId);
		this.dates = new DatesProcessor(convertEventToMutable(this.event), this.util);

		if (this.dates.eventDates.description) this.icons.event.text = `${this.dates.eventDates.description}`;
		if (this.eventType.toolNameLabelKey && this.dates.toolDates.description) {
			let enabled = false;
			let link = '';
			if (this.event.accId) {
				const accelerator = await this.ds.admin.accelerator.getOnePackage(this.event.accId);
				const tool = this.util.event.getAccToolStatusAndLink(this.event, accelerator);
				enabled = tool.enabled;
				link = tool.link;
			}
			if (this.event.picId) {
				const pitchContest = await this.ds.admin.pitchContest.getOnePackage(this.event.picId);
				const tool = this.util.event.getPicToolStatusAndLink(this.event, pitchContest);
				enabled = tool.enabled;
				link = tool.link;
			}
			this.toolLink = { linkType: 'tool', url: `${this.urlService.getBaseUrl(this.event.languageId)}${link}`, enabled, overrideText: `Tool: ${this.dates.toolDates.description}` };
		}
		await this.createContent();
		this.views = this.updateIconBar();
	}

	/**
	 * CreateContent is called once when a new accelerator is passed in.  All content is created but
	 * may be shown or hidden using css instead of rebuilding everything again.
	 */
	private async createContent() {
		this.buttons = await getButtons(this.ds, this.event, this.util, this.urlService);
		this.awardBoxes = await getBoxesForAwards(this.ds, this.event);
	}

	private updateIconBar(): IconBarIcon[] {

		//
		// Get selections from last time this explorer was accessed. If there
		// isn't on,then build the selections from the initial views in views.ts.
		//
		const selectedViewsStr = sessionStorage.getItem(STORAGE_KEY);

		if (selectedViewsStr) {
			this.selectedViews = JSON.parse(selectedViewsStr);
		}
		else {
			this.selectedViews = getIconBarSelections(views);
			sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.selectedViews));
		}

		//
		// Create a new array of icon views using the initial set but overriding
		// whether disabled or not and the tooltip text based on the created content.
		//
		return views.map(view => {
			if (view.key == VIEW_DETAILS) {
				view.disabled = false;
				view.selected = this.selectedViews.map[VIEW_DETAILS];
			}
			if (view.key == VIEW_AWARDS) {
				const count = this.awardBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_AWARDS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Award', 'Awards');
			}

			return view;
		});


	}


	public async setViews(selectedViews: IconBarSelections) {
		if (!selectedViews) return;
		this.selectedViews = selectedViews;
		sessionStorage.setItem(STORAGE_KEY, JSON.stringify(selectedViews));

		for (const view of this.views) {
			view.selected = !!selectedViews.map[view.key];
		}
	}


	public getDisplay(key: string) {
		const view = this.views.find(view => view.key == key);
		if (view == undefined) return 'none';
		return !!view.disabled || !view.selected ? 'none' : 'block';
	}


	async openEvent(): Promise<void> {

		const teamsForAward = [];

		if (this.event.accId) {

			const accTeamsByAccId = await this.ds.admin.accTeam.getByAccIds([this.event.accId]);
			const accTeams = accTeamsByAccId[this.event.accId];

			for (const accTeam of accTeams) {
				const company = accTeam.application.company;
				teamsForAward.push({ teamId: accTeam.accTeamId, text: company.longName, type: 'accTeam' });
			}
		}

		else if (this.event.picId) {

			const picTeamsByPicId = await this.ds.admin.picTeam.getByPicIds([this.event.picId]);
			const picTeams = picTeamsByPicId[this.event.picId];

			for (const picTeam of picTeams) {
				const company = picTeam.application.company;
				const presentType = picTeam.presentType == 'Tabler' ? 'tabler' : picTeam.presentType == 'Pitcher' ? 'pitcher: preselected' : picTeam.presentType == 'Audience' ? 'pitcher: audience choice' : 'pitcher: wildcard';
				teamsForAward.push({ teamId: picTeam.picTeamId, text: `${company.longName} (${presentType})`, type: 'picTeam' });
			}
		}

		await this.showEventDialogService.edit(this.event, false, teamsForAward);
	}

	async showMessage() {
		await this.dialogService.showMessage('The tool is disabled');
	}


	public tabClose(header: string) {
		if (header.endsWith('Details')) this.unselect(VIEW_DETAILS);
		if (header.startsWith('Award') || header.endsWith('Awards')) this.unselect(VIEW_AWARDS);
	}


	private unselect(key: string) {

		this.iconBar.unselect(key);

		const view = this.views.find(view => view.key == key);
		if (view) view.selected = false;
	}

}