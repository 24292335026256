import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@me-services/core/auth';
import { AppSessionService } from '@me-services/core/app-session';
import { UtilityService } from '@me-services/core/utility';
import { ErrorPageService } from '@me-services/ui/error-page';
import { LabelsService } from '@me-services/ui/labels';
import { PageSpinnerService } from '@me-services/ui/page-spinner';


@Component({
	selector: 'access-login-part',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './login.part.html',
	styleUrls: ['./login.part.scss']
})

export class AccessLoginPart {

	constructor(
		public auth: AuthService,
		private router: Router,
		private util: UtilityService,
		public appSession: AppSessionService,
		public labels: LabelsService,
		public pageSpinner: PageSpinnerService,
		public errorPage: ErrorPageService,
	) {
	}


	goToDashboard() {
		this.util.log.techMessage(`Navigating to dashboard`);
		this.router.navigate(['access', 'my', 'dashboard']);
	}
}
