<me-dialog-frame [header]='headerText' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>
	<p>Toggle what quarterly Goal and Progress fields are enabled for entrepreneurs to edit.</p>

	<div class='acc-quarterly-controller'>
		<message-line *ngIf="disabled && accelerator.accStageId < AccStageId_MatchingMentors">Accelerator must be after the "Selecting Cohort" Stage to
			enable quarters.
		</message-line>
		<message-line *ngIf="disabled && accelerator.accStageId > AccStageId_Quarterlies">Quarterlies are disabled because Accelerator is "Complete".
		</message-line>
		<div class='quarterly-grid' *ngFor='let i of [0,1,2,3]'>
			<div>
				<p>{{ quarterNames[i] }}</p>
			</div>
			<div class='switch'>
				<kendo-switch [checked]="enabledQuarters[i]" (valueChange)='setQuarter(i, $event)' offLabel='Off'
					onLabel='On' class="thin-switch" [disabled]='disabled'></kendo-switch>
			</div>
		</div>
	</div>

	<!-- <div class='quarterly-link'>
		<p>View all reponses</p>
		<a routerLink='../cohort'><i class='far fa-external-link'></i></a>
	</div> -->
</me-dialog-frame>