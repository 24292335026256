import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelKey } from '@me-interfaces';
import { DialogAction, DialogData as DialogContext } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';

@Component({
	selector: 'message-dialog',
	templateUrl: './SHR-UI_message.dialog.html',
	styleUrls: ['./SHR-UI_message.dialog.scss']
})
export class MessageDialog implements OnInit {

	message: string | LabelKey;
	bullets: string[];
	header = 'Message';
	translateMessage = false;

	closeAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: 'Okay',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction[] = [this.closeAction];

	constructor(
		@Inject(MAT_DIALOG_DATA) public dialog: DialogContext<{ messageOrLabelKey: string | LabelKey, buttonText?: string | LabelKey, bullets: string[] }>,
		public dialogRef: MatDialogRef<MessageDialog>,
		public labels: LabelsService,
	) {
	}

	async ngOnInit() {
		if (this.dialog.data) {
			this.message = this.dialog.data.messageOrLabelKey || '';
			this.bullets = this.dialog.data.bullets;
		}
		this.translateMessage = typeof this.message !== 'string';
		this.header = await this.labels.get({ key: 'Message' });
		this.closeAction.label = this.dialog.data?.buttonText ? this.dialog.data.buttonText : { key: 'Okay' };
	}

}