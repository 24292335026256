import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogContext } from '@me-services/ui/dialog';

export interface NotesDialogData {
	notes: string,
	updateCallback: (notes: string) => Promise<void>,
}

@Component({
	selector: 'me-notes-dialog',
	templateUrl: './SHR-CMN_notes.dialog.html',
	styleUrls: ['./SHR-CMN_notes.dialog.scss'],
})
export class NotesDialog {

	notes: string;
	saving = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<NotesDialogData>,
		public dialogRef: MatDialogRef<NotesDialog>,
	) {
		this.notes = dialog.data.notes || '';
	}

	setNotes(e) {
		this.notes = (e.target.value || '').trim();
	}

	async save() {
		this.saving = true;
		const notes = this.notes.trim();
		await this.dialog.data.updateCallback(notes);
		this.dialogRef.close();
	}

} 