import { DataService } from "@me-services/core/data";
import { PendingSearchMatch, SearchableData, SearchMatchLevel, SearchRegExps, TextToMatch } from "../interfaces";
import { checkTexts } from "./check-texts";


export async function findSiteMatches(
	data: SearchableData,
	re: SearchRegExps,
	term: string,
	ds: DataService,
): Promise<PendingSearchMatch[]> {


	const matches: PendingSearchMatch[] = [];


	//
	// Build a map of state names by state code
	//
	const states = ds.domain.state.getAll();

	const stateNames = states.reduce((map, state) => {
		map[state.stateCode] = [state.state];
		return map;
	}, <Record<string, string[]>>{});


	stateNames['CA'].push('calif');
	stateNames['CT'].push('conn');
	stateNames['MA'].push('mass');
	stateNames['MN'].push('minn');
	stateNames['MS'].push('miss');
	stateNames['PA'].push('penn');
	stateNames['TN'].push('tenn');
	stateNames['WI'].push('wisc');



	for (const site of data.sites) {

		const texts: TextToMatch[] = [
			{ text: site.code, field: 'Site Code', fullMatchIsExact: true },
			{ text: site.name, field: 'Name', fullMatchIsExact: true },
		];

		texts.push(
			...stateNames[site.stateCode].map(name => ({ text: name, field: 'State Name' }))
		);

		const match = checkTexts(site, texts, re);

		if (match.level > SearchMatchLevel.None) matches.push(match);

	}

	return matches;
}