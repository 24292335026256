<div class="acc-team-members-edit-mentor-dialog">

	<me-dialog-frame header='Edit Mentor' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon'
		alertType='editor'>

		<div class='acc-team-members-edit-mentor-dialog-content' *ngIf='!readonly'>

			<table style='width:100%'
				class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>

				<tr>
					<td>Mentor</td>
					<td>
						{{memberName}}
					</td>
				</tr>

				<tr>
					<td>Role</td>
					<td>
						<me-droplist [ngClass]="selectedItem === null ? 'ng-invalid' : ''" *ngIf="items" [items]='items'
							[selectedItem]='selectedItem' (selectionChange)='onValueChange("role", $event)' [readonly]='readonly'>
						</me-droplist>
					</td>
				</tr>
			</table>

		</div>

	</me-dialog-frame>
</div>