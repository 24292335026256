<me-dialog-frame header='View Report' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>

	<div class='site-hear-report-details-dialog' *ngIf="data">
		<table style='width:100%' class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>
			<tr>
				<td>Applicant</td>
				<td> <button class='btn' (click)='explorePerson()'>
						<me-icon [icon]='icons.person'></me-icon>
					</button>
				</td>
			</tr>
			<tr>
				<td>Business</td>
				<td> <button class='btn' (click)='exploreCompany()'>
						<me-icon [icon]='icons.company'></me-icon>
					</button>
				</td>
			</tr>
			<tr>
				<td>Application</td>
				<td> <button class='btn' (click)='exploreApplication()'>
						<me-icon [icon]='icons.application'></me-icon>
					</button>
				</td>
			</tr>
			<tr>
				<td>Program</td>
				<td> <button class='btn' (click)='exploreProgram()'>
						<me-icon [icon]='icons.program'></me-icon>
					</button>
				</td>
			</tr>

			<tr>
				<td>How did they hear?</td>
				<td> {{data.answer}}</td>
			</tr>



		</table>
	</div>
</me-dialog-frame>