import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { GridColumn, GridColumnType, GridSetup } from '@me-grid';
import { DbsPerson, PhoneTypeId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { GridPart } from '@me-shared-parts/UI-common';
import * as Bowser from 'bowser';
import { takeUntil } from 'rxjs';

interface ContactRow {
	personId: number,
	person: DbsPerson,
	name: string,
	phone: string,
	email: string,
	phoneTypeId: PhoneTypeId,
	canText: string,
	updatedUTC: number,
}

@Component({
	selector: 'contact-dialog',
	templateUrl: './contact-dialog.dialog.html',
	styleUrls: ['./contact-dialog.dialog.scss']
})
export class ContactDialog extends DestroyablePart implements OnInit, AfterViewInit {

	readonly: boolean;
	stateKey: string;
	header = 'Contact People';
	emails: string;
	phones: string;
	emailText: string;
	phoneText: string;
	os: string;
	optionalColumn: { columnName: string, idMap: { [index: number]: string } };
	public gridSetup = this.setupGrid();

	public rows: ContactRow[] = [];
	@ViewChild(GridPart) meGrid: GridPart<ContactRow>;

	//
	// The action buttons
	//
	emailAction: DialogAction<void> = {
		id: 'email',
		enabled: true,
		visible: true,
		label: 'Email',
		linkType: 'callback',
		callback: () => this.sendEmail(),
		willCloseDialog: true,
	};

	textAction: DialogAction<void> = {
		id: 'text',
		enabled: true,
		visible: true,
		label: 'Text',
		linkType: 'callback',
		callback: () => this.sendText(),
		willCloseDialog: true,
	};

	actions: DialogAction<void>[] = [this.emailAction, this.textAction];


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ readonly: boolean, personIds: number[], stateKey: string, header?: string, optionalColumn?: { columnName: string, idMap: { [index: number]: string } } }>,
		public dialogRef: MatDialogRef<ContactDialog>,
		private ds: DataService,
	) {
		super();
		const bowser = Bowser.getParser(window.navigator.userAgent);
		this.os = bowser.getOSName(true);
	}


	async ngOnInit() {
		super.initDestroyable();
		const { personIds, readonly, stateKey, optionalColumn, header } = this.dialog.data;
		this.readonly = readonly;
		this.stateKey = stateKey;
		if (header) this.header = header;
		if (optionalColumn) this.optionalColumn = optionalColumn;


		this.rows = (await this.ds.admin.person.getManyAsArray(personIds)).map(person => ({
			email: person._email,
			name: person._name,
			personId: person.personId,
			person,
			phone: person.phone,
			phoneTypeId: person.phoneTypeId,
			canText: this.getCanText(person.phone, person.phoneTypeId) ? 'Yes' : 'No',
			extra: optionalColumn ? this.optionalColumn.idMap[person.personId] : '',
			updatedUTC: person.updatedUTC,
		}));

		this.getEmailsAndPhones(this.rows);
	}

	ngAfterViewInit(): void {
		if (this.meGrid) {
			this.meGrid.grid.filteredRows$.pipe(takeUntil(this.destroyed$))
				.subscribe(rows => {
					this.getEmailsAndPhones(rows);
				});
		}
	}

	private setupGrid(): GridSetup<ContactRow> {

		const doNotText = (row: ContactRow) => {
			return row.phoneTypeId === PhoneTypeId.Mobile_DoNotText || row.phoneTypeId === PhoneTypeId.Landline ? 'Do not text' : undefined;
		};

		const doNotTextFlag = (row: ContactRow) => {
			return row.phoneTypeId !== PhoneTypeId.Mobile ? true : false;
		};

		const columns: GridColumn<ContactRow>[] = [
			{ field: 'PERSON_email', header: { key: 'Email' }, width: 220, type: GridColumnType.text, hidden: false },
			{ field: 'PERSON_phone', header: { key: 'Phone' }, width: 130, type: GridColumnType.phone, hidden: false, strikethrough: doNotTextFlag, tooltip: doNotText },
			{ field: 'canText', header: 'Can Text', width: 100, type: GridColumnType.text, hidden: true },
		];

		if (this.optionalColumn) columns.unshift({ field: 'extra', header: { key: `${this.optionalColumn.columnName}` }, width: 60, type: GridColumnType.text, hidden: false },)

		return {
			experience: 'PERSON',
			size: {
				fitTo: 'DIALOG',
				dialogActions: true,
				dialogContext: this.dialog,
				heightMultiplier: 1,
				shrinkBy: 0,
			},
			rowSingularName: "person",
			rowPluralName: "people",
			rowKey: "personId",
			stateKey: this.stateKey,
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: columns,
			actions: [],
			initialState: {
				sort: [{ field: 'PERSON_name', dir: 'asc' }],
			},
		};
	}


	async gridActionHandler(action: { actionKey: string, rows: ContactRow[] }) {
		const row = action.rows[0];
	}


	async sendEmail() {
		if (this.readonly) return;
		window.open(`mailto:${this.emails}`);
	}


	async sendText() {
		if (this.readonly) return;
		if (this.os === 'ios' || this.os === 'macos') window.open(`sms:open?addresses=(${this.phones})`);
		else window.open(`sms:${this.phones}`);
	}


	/**
	 * Get the emails and phones of people.
	 */
	private getEmailsAndPhones(rows: ContactRow[]) {
		const phones = [];
		const emails = [];
		for (const r of rows) {
			if (r.phone && this.getCanText(r.phone, r.phoneTypeId)) phones.push(r.phone);
			if (r.email) emails.push(r.email);
		}

		this.emails = emails.join(',');
		this.phones = phones.join(',');
		this.emailText = `Email ${rows.length !== emails.length ? `Only ${emails.length}` : `All ${rows.length}`}`;
		this.phoneText = `Text ${rows.length !== phones.length ? `Only ${phones.length}` : `All ${rows.length}`}`;

		this.emailAction.label = this.emailText;
		this.textAction.label = this.phoneText;
		this.emailAction.enabled = emails.length > 0;
		this.textAction.enabled = phones.length > 0;
	}


	getCanText(phone: string, phoneTypeId: number): boolean {
		if (phone && phoneTypeId == PhoneTypeId.Mobile) return true;
		return false;
	}
}