import { AppAreaApplyResponse, AppAreaFuncResponse, AppAreaIdentifierWithSiteId, SessionAttendeeStatus } from "@me-interfaces";
import { AppSubArea } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";

export class AccSubAreaCurriculum extends AppSubArea {

	constructor(
		private func: FuncService,
		ds: DataService,
		util: UtilityService,
		getId: () => AppAreaIdentifierWithSiteId<number>,
		applyResponse: (response: AppAreaFuncResponse) => Promise<AppAreaApplyResponse>,
	) {
		super(ds, util, getId, applyResponse);
	}


	async removeTopic(parameters: { accTopicId: number }) {
		// return await this.call(this.func.areas.acc.curriculum.deleteTopic, parameters);
	}

	async setTopicSessionAndSpecialists(parameters: { accSessionId: number, topicId: number, personIds: number[] }) {
		return await this.call(this.func.areas.acc.curriculum.setTopicSessionAndSpecialists, parameters);
	}

	async setAttendeeStatus(parameters: { accSessionId: number, teamAndPersonIds: { accTeamId: number, personId: number }[], status: SessionAttendeeStatus }) {
		return await this.call(this.func.areas.acc.curriculum.setSessionAttendeesStatus, parameters);
	}

	async addSessions() {
		return await this.call(this.func.areas.acc.curriculum.addSessions, undefined);
	}

	async sendEmail(parameters: { accSessionId: number }) {
		return await this.call(this.func.areas.acc.curriculum.sendSessionSurveyEmail, parameters);
	}
}