import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Answer } from '@me-interfaces';

@Component({
	selector: 'editable-questions',
	templateUrl: './SHR-CMN_editable-questions.part.html',
	styleUrls: ['./SHR-CMN_editable-questions.part.scss']
})
export class EditableQuestionsPart implements OnChanges {
	@Input() accordionId = 'questionsAccordion';
	@Input() answers: Answer[];
	@Input() stepper; //: QuestionStepper[];
	@Input() formGroup: FormGroup; //: QuestionStepper[];

	ngOnChanges(): void {
		for (const answer of this.answers) {
			const validators = [];
			if (answer.question.isRequired) validators.push(Validators.required);
			if (answer.question.questionTypeId == 3) validators.push(Validators.maxLength(1000));

			answer['q'] = 'q' + answer.question.questionId;
			this.formGroup.addControl(answer['q'], new FormControl(answer.answer || '', validators));
		}
	}

	isOptional(answer: Answer) {
		return !answer.question.isRequired;
	}

	ratingChanged(e) {
		if (this.stepper.step < this.stepper.steps - 1) this.stepper.next();
		else this.stepper.set(-1);
	}

	scrollTo(i) {

		const main = jQuery('.main-wrapper')
		const accord = jQuery('#' + this.accordionId);

		const topOfAccordion = main.scrollTop() + accord.offset().top;
		const offsetForToolbar = 57;
		const collapsedPanelHeight = 48;
		const topBufferWhenOpeningFirst = (i == 0 ? 13 : 0);

		const scrollTop = topOfAccordion - offsetForToolbar + i * collapsedPanelHeight - topBufferWhenOpeningFirst;
		main.animate({ scrollTop }, { duration: 250 });
	}
}