import { DbsPerson, RelationshipType } from "@me-interfaces";
import { OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from "@me-services/core/func";

/** @deprecated DO NOT USE THIS!*/
export class PersonRelationships {

	constructor(private person: DbsPerson, private func: FuncService, private dd: OldDomainDataService) {
	}

	public async add(contactId2: number, type?: RelationshipType, notes?: string) {
		this.person = await this.func.person.relationships.add({ entityId1: this.person.entityId, contactId1: this.person.personId, contactId2, type, notes });
	}

	public async update(contactId2: number, type?: RelationshipType, notes?: string) {
		this.person = await this.func.person.relationships.update({ entityId1: this.person.entityId, contactId1: this.person.personId, contactId2, type, notes });
	}

	public async remove(contactId2: number) {
		this.person = await this.func.person.relationships.remove({ entityId1: this.person.entityId, contactId1: this.person.personId, contactId2 });
	}
}