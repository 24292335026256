import { AppAreaIdentifier, Region } from "@me-interfaces";
import { DataService } from "@me-services/core/data";

/**
 * Given an identifier for national, a region, or a site, return the list of Region packages
 */
export async function getRegions(ds: DataService, identifier: AppAreaIdentifier<number>): Promise<{ regions: ReadonlyArray<Region>, regionIds: Readonly<number[]> }> {
	
	const response: { regions: ReadonlyArray<Region>, regionIds: Readonly<number[]> } = { regions: [], regionIds: [] };

	const areaName = identifier?.areaName;
	if (!['Admin', 'Region', 'Site'].includes(areaName)) return response;


	if (areaName == 'Site') {
		const siteId = identifier.id;
		const site = await ds.admin.site.getOnePackage(siteId);
		response.regions = [await ds.admin.region.getOnePackage(site.regionId)];
	}

	else if (areaName == 'Region') {
		const regionId = identifier.id;
		response.regions = [await ds.admin.region.getOnePackage(regionId)];
	}
		
	else {
		response.regions = await ds.admin.region.getAllPackagesAsArray();
	}

	response.regionIds = response.regions.map(region => region.regionId);

	return response;
}