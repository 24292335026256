<div class='application-viewer-part' *ngIf='application'>

	<section class='readonly-field-section'>
		<me-slanted-header>
			<me-label key='Tell us about Your Business'></me-label>
		</me-slanted-header>

		<field-grid-readonly [fields]='application.business.fields' [values]='application.business.values'>
		</field-grid-readonly>
	</section>


	<section class='readonly-field-section' *ngIf='application.overview.access?.canSeeApplicantDemographics'>
		<me-slanted-header>
			<me-label key='Tell us about You'></me-label>
		</me-slanted-header>

		<field-grid-readonly [fields]='application.applicant.fields' [values]='application.applicant.values'>
		</field-grid-readonly>
	</section>


	<section class='readonly-field-section' *ngIf='application.overview.access?.canSeeApplicantDemographics'>
		<me-slanted-header>
			<me-label key='Tell us about Your Team'></me-label>
		</me-slanted-header>

		<table style='width:50%; margin: 2em 0 3em;' class='pro-table'>
			<tr>
				<td>
					<strong>
						<me-label key='Status'></me-label>
					</strong>
				</td>
				<td>
					<strong>
						<me-label key='First'></me-label>
					</strong>
				</td>
				<td>
					<strong>
						<me-label key='Last'></me-label>
					</strong>
				</td>
				<td>
					<strong>
						<me-label key='Title'></me-label>
					</strong>
				</td>
				<td>
					<strong>
						<me-label key='Email'></me-label>
					</strong>
				</td>
			</tr>
			<tr *ngFor='let participant of application.team.participants'>
				<td>{{participant.status}}</td>
				<td>{{participant.firstName}}</td>
				<td>{{participant.lastName}}</td>
				<td>{{participant.title}}</td>
				<td>{{participant.email}}</td>
			</tr>
		</table>

		<field-grid-readonly [fields]='application.team.fieldsAndValues.fields'
			[values]='application.team.fieldsAndValues.values'>
		</field-grid-readonly>
	</section>


	<section class='readonly-field-section'>
		<me-slanted-header>
			<me-label key='Tell us More'></me-label>
		</me-slanted-header>

		<field-grid-readonly [fields]='application.questions.fields' [values]='application.questions.values'>
		</field-grid-readonly>

	</section>

	<section class='readonly-field-section' *ngIf='application.staffOnlyQuestions.fields.length'>
		<me-slanted-header>
			<me-label key='Staff Only'></me-label>
		</me-slanted-header>

		<field-grid-readonly [fields]='application.staffOnlyQuestions.fields' [values]='application.staffOnlyQuestions.values'>
		</field-grid-readonly>

	</section>

	<button-container
		*ngIf='application.overview.access.applicantCanReopen'>
		<spinner-button [spinning]='reopening' [disabled]='reopening' (safeClick)='reopen()'>
			<me-label key='Reopen'></me-label>
		</spinner-button>
	</button-container>
</div>