import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { DbcUser, Person, TagPrefixId } from '@me-interfaces';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { UtilityService } from '@me-services/core/utility';
import { Icon, getIconContext } from '@me-shared-parts/UI-common';
import { IconBarIcon, getIconBarSelections } from '@me-shared-parts/UI-common/icon-bar';
import { IconBarPart, IconBarSelections } from '@me-shared-parts/UI-common/icon-bar/SHR-CMN_icon-bar.part';
import { DataService } from '../../../data.service';
import { ExplorerBox } from '../../explorer-box/explorer-box';
import { ExplorerNoteBox } from '../../explorer-note-box/explorer-note-box';
import { ExplorerTagBox } from '../../explorer-tag-box/explorer-tag-box';
import { LinkOrExplorable } from '../../link-or-explorerable';
import { getBoxesForAccelerators } from './boxes/get-boxes-for-accelerators';
import { getBoxesForAwards } from './boxes/get-boxes-for-awards';
import { getBoxesForCompanies } from './boxes/get-boxes-for-companies';
import { getBoxesForInterview } from './boxes/get-boxes-for-interview';
import { getBoxesForJudgement } from './boxes/get-boxes-for-judgement';
import { getBoxesForLangAndBio } from './boxes/get-boxes-for-lang-and-bio';
import { getBoxesForMentorship } from './boxes/get-boxes-for-mentoring';
import { getBoxesForNotes } from './boxes/get-boxes-for-notes';
import { getBoxesForPitchContests } from './boxes/get-boxes-for-pitch-contests';
import { getBoxesForReading } from './boxes/get-boxes-for-reading';
import { getBoxesForRelationships } from './boxes/get-boxes-for-relationships';
import { getBoxesForTags } from './boxes/get-boxes-for-tags';
import { getButtons } from './get-buttons';
import { VIEW_AWARDS, VIEW_BIOS, VIEW_COMPANIES, VIEW_DEMOGRAPHICS, VIEW_DETAILS, VIEW_INTERVIEWS, VIEW_JUDGEMENTS, VIEW_MENTORSHIPS, VIEW_NOTES, VIEW_PROGRAMMING, VIEW_READINGS, VIEW_RELATIONSHIPS, VIEW_TAGS, views } from './views';

const STORAGE_KEY = 'PersonExplorerIconSelection';


@Component({
	selector: 'person-explorer',
	templateUrl: './person-explorer.part.html',
	styleUrls: ['./person-explorer.part.scss'],
})
export class PersonExplorerPart implements OnChanges {

	@Input() public person: Person;
	@ViewChild('iconBar') iconBar: IconBarPart;

	public user: DbcUser;
	public views: IconBarIcon[] = undefined;

	public icons = {
		redFlag: getIconContext(Icon.notes_redFlag),
		doNotContact: getIconContext(Icon.status_doNotContact),
		deceased: getIconContext(Icon.status_deceased),
		award: getIconContext(Icon.concept_award),
		person: getIconContext(Icon.concept_person)
	};

	public totalTags = 0;


	public selectedViews: IconBarSelections = undefined;
	public buttons: LinkOrExplorable[] = [];

	public companyBoxes: ExplorerBox[] = [];
	public acceleratorBoxes: ExplorerBox[] = [];
	public pitchContestBoxes: ExplorerBox[] = [];
	public noteBoxes: ExplorerNoteBox[] = [];
	public tagBoxes: ExplorerTagBox[] = [];
	public awardBoxes: ExplorerBox[] = [];
	public mentorshipBoxes: ExplorerBox[] = [];
	public readingBoxes: ExplorerBox[] = [];
	public interviewBoxes: ExplorerBox[] = [];
	public judgementBoxes: ExplorerBox[] = [];
	public relationshipBoxes: ExplorerBox[] = [];
	public langAndBioBoxes: ExplorerBox[] = [];


	constructor(
		private util: UtilityService,
		private ds: DataService,
		private userService: OldUserService,
	) { }


	async ngOnChanges() {

		if (!this.person) throw new Error(`Missing required attribute: person`);

		this.icons.person.text = this.person.fullName;
		this.user = await this.userService.getUser();
		this.totalTags = this.person.tags.filter(tag => tag.tagPrefixId != TagPrefixId.HQ).length;

		if (this.person.awardedValue > 0) this.icons.award.text = `Awarded: $${this.person.awardedValue.toLocaleString()}`;

		await this.createContent();

		this.views = this.updateIconBar();
	}


	/**
	 * CreateContent is called once when a new accelerator is passed in.  All content is created but
	 * may be shown or hidden using css instead of rebuilding everything again.
	 */
	private async createContent() {
		this.buttons = await getButtons(this.ds, this.person);
		this.companyBoxes = await getBoxesForCompanies(this.ds, this.person);
		this.acceleratorBoxes = await getBoxesForAccelerators(this.util, this.ds, this.person);
		this.pitchContestBoxes = await getBoxesForPitchContests(this.util, this.ds, this.person);
		this.noteBoxes = await getBoxesForNotes(this.util, this.ds, this.person);
		this.tagBoxes = await getBoxesForTags(this.util, this.ds, this.person);
		this.awardBoxes = await getBoxesForAwards(this.ds, this.person);
		this.mentorshipBoxes = await getBoxesForMentorship(this.util, this.ds, this.person);
		this.readingBoxes = await getBoxesForReading(this.ds, this.person);
		this.interviewBoxes = await getBoxesForInterview(this.ds, this.person);
		this.judgementBoxes = await getBoxesForJudgement(this.ds, this.person);
		this.relationshipBoxes = await getBoxesForRelationships(this.util, this.ds, this.person);
		this.langAndBioBoxes = await getBoxesForLangAndBio(this.person);
	}

	private updateIconBar(): IconBarIcon[] {

		//
		// Get selections from last time this explorer was accessed. If there
		// isn't one,then build the selections from the initial views in views.ts.
		//
		const selectedViewsStr = sessionStorage.getItem(STORAGE_KEY);
		let selectedViews: IconBarSelections;

		if (selectedViewsStr) {
			this.selectedViews = selectedViews = JSON.parse(selectedViewsStr);
		}
		else {
			this.selectedViews = selectedViews = getIconBarSelections(views);
			sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.selectedViews));
		}

		//
		// Create a new array of icon views using the initial set but overriding
		// whether disabled or not and the tooltip text based on the created content.
		//
		return views.map(view => {
			if (view.key == VIEW_DETAILS) {
				view.disabled = false; // always has a value
				view.selected = this.selectedViews.map[VIEW_DETAILS];
				view.tooltip = `Person Details`;
			}
			else if (view.key == VIEW_NOTES) {
				const count = this.noteBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_NOTES];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Note', 'Notes');
			}
			else if (view.key == VIEW_COMPANIES) {
				const count = this.companyBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_COMPANIES];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Company', 'Companies');
			}
			else if (view.key == VIEW_PROGRAMMING) {
				const count = this.acceleratorBoxes.length + this.pitchContestBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_PROGRAMMING];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Entrepreneur Activity', 'Entrepreneur Activities');
			}
			else if (view.key == VIEW_MENTORSHIPS) {
				const count = this.mentorshipBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_MENTORSHIPS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Mentorship', 'Mentorships');
			}
			else if (view.key == VIEW_READINGS) {
				const count = this.readingBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_READINGS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Reading', 'Readings');
			}
			else if (view.key == VIEW_INTERVIEWS) {
				const count = this.interviewBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_INTERVIEWS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Interview', 'Interviews');
			}
			else if (view.key == VIEW_JUDGEMENTS) {
				const count = this.judgementBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_JUDGEMENTS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Judgement', 'Judgements');
			}
			else if (view.key == VIEW_RELATIONSHIPS) {
				const count = this.relationshipBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_RELATIONSHIPS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Relationship', 'Relationships');
			}
			else if (view.key == VIEW_TAGS) {
				const count = this.totalTags;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_TAGS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Tag', 'Tags');
			}
			else if (view.key == VIEW_BIOS) {
				view.disabled = !this.langAndBioBoxes.length;
				view.selected = this.selectedViews.map[VIEW_BIOS];
				view.tooltip = `Languages & Bios`;
			}
			else if (view.key == VIEW_DEMOGRAPHICS) {
				view.disabled = false;
				view.selected = this.selectedViews.map[VIEW_DEMOGRAPHICS];
				view.tooltip = `Demographics`;
			}
			else if (view.key == VIEW_AWARDS) {
				const count = this.awardBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_AWARDS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Award', 'Awards');
			}
			return view;
		});


	}


	public async setViews(selectedViews: IconBarSelections) {
		if (!selectedViews) return;
		this.selectedViews = selectedViews;
		sessionStorage.setItem(STORAGE_KEY, JSON.stringify(selectedViews));

		for (const view of this.views) {
			view.selected = !!selectedViews.map[view.key];
		}
	}


	public getDisplay(key: string) {

		if (key == 'associations') {

			let display: 'none' | 'block' = 'none';

			for (const view of this.views) {
				if (view.key == 'companies' && !view.disabled && view.selected == true) { display = 'block'; break; }
				if (view.key == 'programming' && !view.disabled && view.selected == true) { display = 'block'; break; }
				if (view.key == 'mentorships' && !view.disabled && view.selected == true) { display = 'block'; break; }
				if (view.key == 'readings' && !view.disabled && view.selected == true) { display = 'block'; break; }
				if (view.key == 'interviews' && !view.disabled && view.selected == true) { display = 'block'; break; }
				if (view.key == 'judgements' && !view.disabled && view.selected == true) { display = 'block'; break; }
				if (view.key == 'relationships' && !view.disabled && view.selected == true) { display = 'block'; break; }
			}

			return display;
		}

		else {
			const view = this.views.find(view => view.key == key);
			if (view == undefined) return 'none';
			return !!view.disabled || !view.selected ? 'none' : 'block';
		}
	}


	public click(action: 'red-flag' | 'do-not-contact' | 'deceased' | 'awards') {
		if (this.iconBar) {
			if (action == 'red-flag') this.iconBar.selectOnlyOne(VIEW_NOTES);
			if (action == 'do-not-contact') this.iconBar.selectOnlyOne(VIEW_DETAILS);
			if (action == 'deceased') this.iconBar.selectOnlyOne(VIEW_DEMOGRAPHICS);
			if (action == 'awards') this.iconBar.selectOnlyOne(VIEW_AWARDS);
		}
	}


	public tabClose(header: string) {

		if (header.endsWith('Association') || header.endsWith('Associations')) {
			this.unselect(VIEW_COMPANIES);
			this.unselect(VIEW_PROGRAMMING);
			this.unselect(VIEW_MENTORSHIPS);
			this.unselect(VIEW_READINGS);
			this.unselect(VIEW_INTERVIEWS);
			this.unselect(VIEW_JUDGEMENTS);
			this.unselect(VIEW_RELATIONSHIPS);

		}

		if (header.endsWith('Details')) this.unselect(VIEW_DETAILS);
		if (header.endsWith('Note') || header.endsWith('Notes')) this.unselect(VIEW_NOTES);
		if (header.endsWith('Award') || header.endsWith('Awards')) this.unselect(VIEW_AWARDS);
		if (header.endsWith('Tag') || header.endsWith('Tags')) this.unselect(VIEW_TAGS);
		if (header.endsWith('Bios')) this.unselect(VIEW_BIOS);
		if (header.endsWith('Demographics')) this.unselect(VIEW_DEMOGRAPHICS);
	}


	private unselect(key: string) {

		this.iconBar.unselect(key);

		const view = this.views.find(view => view.key == key);
		if (view) view.selected = false;
	}

}

