<me-h3>Relationships</me-h3>

<div class='person-extract-relationships-part' *ngIf='relationships && relationships.length'>
	<ng-container *ngFor='let relationship of relationships'>
		<div class='relationship-details'>
			<div class='avatar'>
				<me-avatar size='40px' [avatar]='relationship.person'></me-avatar>
			</div>
			<div style="display: inline-grid">
				<a class='link' (click)=open(relationship.person.personId)>{{relationship.person._name}}</a>
				<span>{{relationship.type}}</span>
			</div>
		</div>
	</ng-container>
</div>
<div *ngIf='!relationships || !relationships.length'><i>{{name}} has no relationships.</i></div>