import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, } from '@angular/material/dialog';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { DownloadColumn } from 'interfaces/ui/download-column';
import { buildArrays, createHref, makeSafeHref } from '@me-shared-parts/UI-common';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
@Component({
	selector: 'me-dialog-comments',
	templateUrl: './comments.dialog.html',
	styleUrls: [`./comments.dialog.scss`],
})
export class CommentsDialog implements OnInit {

	comments: string[]
	label: string
	actions: DialogAction[]
	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: ':Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	downloadAction: DialogAction = {
		id: 'download',
		enabled: false,
		visible: true,
		label: 'Download',
		linkType: "href",
		willCloseDialog: true,
		url: '',
		download: '',
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ comments: string[], label: string }>,
		public dialogRef: MatDialogRef<CommentsDialog>,
		private sanitizer: DomSanitizer,
	) {
		this.comments = this.dialog.data.comments
		this.label = this.dialog.data.label
	}

	ngOnInit(): void {
		this.actions = [this.cancelAction, this.downloadAction]
		this.buildDownload()
	}

	buildDownload() {
		const downloadColumns: DownloadColumn[] = [
			{ header: 'type', property: 'type', type: "string" },
			{ header: 'entrepreneur', property: 'entrepreneur', type: "string" },
			{ header: 'comment', property: 'comment', type: "string" },
		];
		const dataToDownload = this.dialog.data.comments.map((d: any) => {

			return {
				type: this.dialog.data.label,
				comment: d.comment,
				entrepreneur: d.entrepreneurName
			}
		})
		const parsedData = buildArrays(downloadColumns, dataToDownload);
		const href = createHref(parsedData);
		const safeHref = makeSafeHref(href, this.sanitizer);

		this.downloadAction.url = safeHref;
		this.downloadAction.enabled = true;
		this.downloadAction.download = `survey-comments-export-${moment().format('YYYY-MM-DD')}`;
	}
}
