import { Component, Input, OnInit } from "@angular/core";
import { DestroyablePart } from '@me-access-parts';
import { Field, FieldKind, FieldKindAddress, FieldKindPhone, FieldStatus, FieldValueType, LabelKey } from '@me-interfaces';
import { DataService } from "@me-services/core/data";
import { LabelsService } from '@me-services/ui/labels';
import { renderAddress } from '../address/render-address';
import { applyDefaultFieldProperties } from '../apply-default-field-properties';
import { renderDate } from '../date/render-date';
import { renderNumeric } from '../numeric/render-numeric';
import { renderPhone } from '../phone/render-phone';
import { renderSelect } from '../select/render-select';
import { BaseField } from './base-field';

@Component({
	selector: 'readonly-field',
	templateUrl: './SHR-UI_readonly-field.part.html',
})

export class ReadonlyFieldPart<T extends FieldValueType> extends DestroyablePart implements BaseField, OnInit {

	@Input() field: Field;
	@Input() value: FieldValueType;
	@Input() otherValue = '';

	base: ReadonlyFieldPart<T> = this;

	questionKey: LabelKey = undefined;
	questionLiteral: string = undefined;

	text = { label: '', optional: '' };
	completed = true;
	status: FieldStatus = undefined;
	errorText: string = undefined;

	content: string;


	constructor(
		protected ds: DataService,
		protected labelsService: LabelsService,
	) {
		super()
	}


	async ngOnInit() {
		super.initDestroyable();

		//
		// Check for required attributes
		//
		if (!this.field) throw new Error('Missing required attribute: field');

		//
		// Preprocess the field
		//
		const field = this.field;
		applyDefaultFieldProperties(field);


		if (field.labelIsQuestion) {

			if (typeof field.label == 'string') this.questionLiteral = field.label;
			else this.questionKey = field.label;

			this.text.label = await this.labelsService.get({ key: 'Answer' });
		}
		else {
			this.text.label = await this.labelsService.get(field.label);
		}

		this.text.optional = await this.labelsService.get({ key: 'optional' });

		this.content = await this.renderContent();
	}


	async renderContent(): Promise<string> {

		if (this.field.kind == FieldKind.Select) {
			const text = renderSelect(<number | string>this.value, this.field.fieldOptionsList, this.ds, this.otherValue);
			return text ? await this.labelsService.get({ key: text }) : '';
		}

		else if (this.field.kind == FieldKind.Phone) {
			return renderPhone(<FieldKindPhone>this.value);
		}

		else if (this.field.kind == FieldKind.Address) {
			return renderAddress(<FieldKindAddress>this.value);
		}

		else if (this.field.kind == FieldKind.Date) {
			return renderDate(<number>this.value);
		}

		else if (this.field.kind == FieldKind.Numeric) {
			return renderNumeric(<number>this.value, this.field.numericFormat, this.field.saveZeroAsNull);
		}

		else return this.value?.toString() || '';

	}

}