import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { FuncService } from '@me-services/core/func';
import { LabelsService } from '@me-services/ui/labels';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BaseFieldPart } from '../base/SHR-UI_base-field.part';
import { UtilityService } from "@me-services/core/utility";

@Component({
	selector: 'text-field',
	templateUrl: './SHR-UI_text-field.part.html',
})

export class TextFieldPart extends BaseFieldPart<string> implements AfterViewInit {

	basePart: BaseFieldPart<string> = this;

	@ViewChild('textField') textField: ElementRef;

	constructor(
		protected override func: FuncService,
		private util: UtilityService,
		protected override labelsService: LabelsService,
	) {
		super(func, labelsService);
	}


	ngAfterViewInit() {

		fromEvent(this.textField.nativeElement, 'keyup')
			.pipe(
				takeUntil(this.destroyed$),
				debounceTime(50),
			)
			.subscribe(this.onKeyUp.bind(this));
	}


	onKeyUp(event: KeyboardEvent) {
		if (event.isTrusted && event.key == 'Enter') {
			// The user pressed Enter
			this.attemptToSave();
		}
	}


	onInput(event: InputEvent) {
		this.basePart.setPendingValue((<any>event.target).value);
	}


	override cleanValue(value: string): string {
		return this.util.text.fixCase(value);
	}


	override checkForAdditionalError(): boolean {
		const value = (this.pendingValue || '').trim();

		if (value.length < this.field.minLength) {
			this.errorText = `Must be at least ${this.field.minLength} characters`;
			return true;
		}
		this.errorText = undefined;
		return false;
	}

	/** Calculate a max-width style that is roughly based on the maximum char length */
	get maxWidth() {
		const m = this.field?.maxLength || 100;
		return (m + 2) - m / 5;
	}
}