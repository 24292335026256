import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogContext } from '@me-services/ui/dialog';
import { mergeMap } from 'rxjs';
import { DataService } from '../../data.service';
import { Explorable } from '../../explorable';


@Component({
	selector: 'explorer-dialog',
	templateUrl: './explorer.dialog.html',
	styleUrls: ['./explorer.dialog.scss']
})
export class ExplorerDialog {

	icon = 'fa-bullseye-pointer';
	headerText = 'Explorer';


	public selected$ = this.ds
		.explorer
		.navigator
		.recents$
		.pipe(
			mergeMap(recents => this.ds.admin[recents[0].conceptName].getOnePackage(recents[0].id))
		);


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ item: Explorable }>,
		public dialogRef: MatDialogRef<ExplorerDialog>,
		public ds: DataService,
	) {
	}
}