import { Component, OnChanges, Input } from '@angular/core';
import { AccMatchingRollups_old } from '@me-interfaces';

const DEFAULT_THRESHOLDS = [50, 100];
@Component({
	selector: 'positivity-rollups',
	templateUrl: './SHR-CMN_positivity-rollups.part.html',
	styleUrls: ['SHR-CMN_positivity-rollups.part.scss'],
})
export class PositivityRollupsPart implements OnChanges {

	@Input() rollups: AccMatchingRollups_old;
	@Input() thresholds = DEFAULT_THRESHOLDS;
	@Input() countOppositeRoles = 0;

	sumOfGivenAssessments = 0;

	ngOnChanges() {
		for (const i of this.rollups.assessmentsGiven) {
			this.sumOfGivenAssessments = this.sumOfGivenAssessments + i;
		}

	}

}
