<div class="app-404-error container-fluid">
	<div class="row justify-content-center">
		<div class="col-12 col-sm-8 col-md-6 col-lg-4 col-xl-3">
			<div class="card">
				<div class="card-body text-center">
					<h2 class="display-3">404</h2>
					<h6 class="card-title">
						<me-label key='Page does not exist'></me-label>
					</h6>
					<p class="card-text text-left">
						<me-label key='md:404 Message'></me-label>
					</p>
					<button class="btn" type="button" (click)="goToDashboard()"><me-label
							key='Go to the Dashboard'></me-label></button>
					<p></p>
				</div>
			</div>
		</div>
	</div>
</div>