import { DdAcc, OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from "@me-services/core/func";

/** @deprecated DO NOT USE THIS!*/
export class AccWapper {

	acc: DdAcc;

	constructor(
		private readonly _data: DdAcc,
		private func: FuncService,
		private dd: OldDomainDataService
	) { this.acc = _data }


	public async setStage(accStageId: number): Promise<void> {
		const confirmedAccStageId = await this.func.admin.site.acc.setStage({ accId: this._data.accId, accStageId: accStageId, siteCode: this._data.siteProgram.site.code });
		await this.dd.fetchLatestData();
		this.acc.setAccStageId(confirmedAccStageId);
	}

}