import { Router } from '@angular/router';
import { environment } from '@me-environment';
import { ErrorPageDetails } from '@me-interfaces';
import { AppSessionService } from '@me-services/core/app-session';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { UtilityService } from '../utility';


/**
 * Logic for authenticating with Firebase for the ME App
 */
export class MeAuthEngine {

	public readonly firebaseUser$ = new BehaviorSubject<firebase.User>(undefined);

	//
	// There is an appSession if there is a Firebase User AND the appSession status is 'SESSION_STARTED'
	//
	public readonly hasSession$: Observable<boolean> = combineLatest([this.firebaseUser$, this.appSessionService.status$]).pipe(
		map(([user, status]) => {
			if (!!user && status && status.state == 'SESSION_STARTED') return true;
			else return false;
		}),
		share(),
	);

	private app: firebase.app.App;

	constructor(
		private authService: {
			analytics?: firebase.analytics.Analytics,
			logErrorToAnalytics: (e: ErrorPageDetails) => void,
		},
		private router: Router,
		private hasWebStorage: boolean,
		private appSessionService: AppSessionService,
		private util: UtilityService,
	) {
		if (!this.hasWebStorage) return;

		const firebaseConfig = { ...environment.firebaseConfig };
		const hostname = document?.location?.hostname?.toLowerCase();

		if (hostname == 'eforall.app') firebaseConfig.authDomain = 'eforall.app';
		if (hostname == 'eparatodos.app') firebaseConfig.authDomain = 'eparatodos.app';

		if (hostname == 'dev.eforall.app') firebaseConfig.authDomain = 'dev.eforall.app';
		if (hostname == 'dev.eparatodos.app') firebaseConfig.authDomain = 'dev.eparatodos.app';

		this.app = firebase.initializeApp(firebaseConfig);

		this.authService.analytics = firebase.analytics();


		////// TODO: Consider whether to emit these built-in analytics event types...
		// screen_view (automatic?)
		// view_item / view_item_list -- applications, etc. ???

		firebase.auth().onAuthStateChanged(
			this.onAuthStateChanged.bind(this),
			error => {
				this.authService.logErrorToAnalytics({ error: true, name: error.code, message: error.message });
				this.util.log.errorMessage(`${error.code}: ${error.message}`)
			}
		);

	}

	async signout() {
		this.router.navigate(['/signout']);
	}

	private async onAuthStateChanged(user: firebase.User) {


		this.router.resetConfig(this.router.config);  // clear out data stored on the router

		this.util.log.techMessage('onAuthStateChanged', { extra: user, extraLogging: 'ExtraToConsole' })

		if (user) {

			const redirect = await firebase.auth().getRedirectResult();
			if (redirect.user) {
				this.util.log.techMessage(`MeAuthEngine - getRedirectResult`, { extra: redirect, extraLogging: 'ExtraToConsole' });
				// If we are coming back from a login redirect then we will have a redirect user.
				// In that case, do nothing because the signInSuccess callback will be called.
				// If not, and we have a user from the firebase.auth() then it is from the auth's
				// persistence mechanism and we should just use it to sign in.
			}
			else this.firebaseUser$.next(user);  // trigger the appSession service to start a new appSession

			this.authService.analytics?.logEvent(firebase.analytics.EventName.LOGIN, {
				method: user.providerId,
				pulledFromCache: !redirect.user ? 1 : 0,
				displayName: user.displayName,
				email: user.email,
				emailVerified: user.emailVerified ? 1 : 0,
				phoneNumber: user.phoneNumber,
				uid: user.uid,
			});

		}
		else {
			this.firebaseUser$.next(undefined);

			if (this.router.url !== '/') setTimeout(() => {
				if (this.router.url !== '/') this.router.navigate(['/'], { replaceUrl: true });
			});
		}
	}
}