import { AppAreaName, LanguageId, ProgramTypeId } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { getCommonIndicatorValues } from "./indicators/get-common-values";
import { getGeneralIndicatorValues } from "./indicators/get-general-values";
import { getSpecificAccIndicatorValues } from "./indicators/get-specific-acc-values";
import { getSpecificPicIndicatorValues } from "./indicators/get-specific-pic-values";
import { AccIndicatorValues, DashboardData, DashboardDataObjects, PicIndicatorValues, TotalIndicatorValues } from "./interfaces";


export async function calculateIndicators(
	areaName: AppAreaName,
	ds: DataService,
	util: UtilityService,
	objects: DashboardDataObjects,
) {

	const { data, accs, pics } = objects;

	//
	// Determine all the accIds and picIds by language
	//
	const programs = await ds.admin.program.getAllPackagesAsArray();

	const accIds = accs.map(a => a.accId);
	const picIds = pics.map(p => p.picId);

	const englishAccProgram = programs.find(p => p.languageId == LanguageId.English && p.programTypeId == ProgramTypeId.Accelerator);
	const englishAccIds = accs.filter(a => a.siteProgram.programId == englishAccProgram.programId).map(a => a.accId);

	const spanishAccProgram = programs.find(p => p.languageId == LanguageId.Spanish && p.programTypeId == ProgramTypeId.Accelerator);
	const spanishAccIds = accs.filter(a => a.siteProgram.programId == spanishAccProgram.programId).map(a => a.accId);

	const englishPicProgram = programs.find(p => p.languageId == LanguageId.English && p.programTypeId == ProgramTypeId.PitchContest);
	const englishPicIds = pics.filter(p => p.siteProgram.programId == englishPicProgram.programId).map(p => p.picId);

	const spanishPicProgram = programs.find(p => p.languageId == LanguageId.Spanish && p.programTypeId == ProgramTypeId.PitchContest);
	const spanishPicIds = pics.filter(p => p.siteProgram.programId == spanishPicProgram.programId).map(p => p.picId);


	data.indicators.totals = await getTotalIndicatorValues(areaName, data, ds, util, accIds, picIds);

	data.indicators.englishAccs = await getAccIndicatorValues(ds, util, englishAccIds);
	data.indicators.spanishAccs = await getAccIndicatorValues(ds, util, spanishAccIds);

	data.indicators.englishPics = await getPicIndicatorValues(ds, util, englishPicIds);
	data.indicators.spanishPics = await getPicIndicatorValues(ds, util, spanishPicIds);

	return;
}


/**
 * @returns The accelerator, pitch contest and general program values
 */
async function getTotalIndicatorValues(
	areaName: AppAreaName,
	data: DashboardData,
	ds: DataService,
	util: UtilityService,
	accIds: number[],
	picIds: number[],
): Promise<TotalIndicatorValues> {

	return {
		...await getGeneralIndicatorValues(areaName, data, ds, util),
		...await getSpecificAccIndicatorValues(ds, util, accIds),
		...await getSpecificPicIndicatorValues(ds, util, picIds),
		...await getCommonIndicatorValues(ds, util, accIds, picIds),
	};
}



/**
 * @returns The accelerator and general program values
 */
async function getAccIndicatorValues(
	ds: DataService,
	util: UtilityService,
	accIds: number[],
): Promise<AccIndicatorValues> {

	return {
		...await getSpecificAccIndicatorValues(ds, util, accIds),
		...await getCommonIndicatorValues(ds, util, accIds, []),
	};
}





/**
 * @returns The pitch contest and general program values
 */
async function getPicIndicatorValues(
	ds: DataService,
	util: UtilityService,
	picIds: number[],
): Promise<PicIndicatorValues> {

	return {
		...await getSpecificPicIndicatorValues(ds, util, picIds),
		...await getCommonIndicatorValues(ds, util, [], picIds),
	};
}