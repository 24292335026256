import { PendingSearchMatch, SearchableData, SearchMatchLevel } from "./interfaces";

/**
 * If filters were used but no search text then this funciton is called
 * to match everything remaining after the filters were applied.
 */
export async function matchAllFromFilters(data: SearchableData): Promise<PendingSearchMatch[]> {

	const field = 'Filters';
	const level = SearchMatchLevel.Exact;

	const matches: PendingSearchMatch[] = [
		...data.accTeams.map(t => ({ explorable: t, level, fieldMatches: [{ field, level }] })),
		...data.accelerators.map(a => ({ explorable: a, level, fieldMatches: [{ field, level }] })),
		...data.applications.map(a => ({ explorable: a, level, fieldMatches: [{ field, level }] })),
		...data.companies.map(a => ({ explorable: a, level, fieldMatches: [{ field, level }] })),
		...data.events.map(a => ({ explorable: a, level, fieldMatches: [{ field, level }] })),
		...data.persons.map(a => ({ explorable: a, level, fieldMatches: [{ field, level }] })),
		...data.picTeams.map(a => ({ explorable: a, level, fieldMatches: [{ field, level }] })),
		...data.pitchContests.map(a => ({ explorable: a, level, fieldMatches: [{ field, level }] })),
		...data.sites.map(s => ({ explorable: s, level, fieldMatches: [{ field, level }] })),
		...data.venues.map(a => ({ explorable: a, level, fieldMatches: [{ field, level }] })),
	];

	return matches;
}