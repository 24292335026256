import { environment } from '@me-environment';
import { FanActionResult, FanClientFlags, FuncCallBody } from '@me-interfaces';
import { AuthService, CustomAnalyticsEventName } from '@me-services/core/auth';
import { ErrorPageService } from '@me-services/ui/error-page';
import { PageSpinnerService } from '@me-services/ui/page-spinner';
import { UrlService } from '@me-services/ui/url';
import * as Bowser from "bowser";
import * as lzString from 'lz-string';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { stringify } from '../../../../../functions/src/fan/shim/fan-action-stringify';
import { UtilityService } from '../utility';
import { AppInstanceLog, CallLog } from './app-instance-log';

const APP_DEVICE_UUID = 'funcAppDeviceUuid';
const APP_INSTANCE_LOG = 'funcAppInstanceLog';
const MAX_ATTEMPTS = 10;



/**
 * This is the base class for the function shims.  Each shim uses the base
 * _call function which makes the remote call and implements any client flag
 * functionality.  To add additional client flags, add them to FanClientFlags
 * interface and add implementation here.
 */
export class FuncServiceBase {

	appDeviceUuid: string;
	instanceLog: AppInstanceLog;

	constructor(
		private auth: AuthService,
		private errorPage: ErrorPageService,
		private ps: PageSpinnerService,
		private urlService: UrlService,
		private util: UtilityService,
	) {

		//
		// Tag this device with a unique identifier
		//
		this.appDeviceUuid = localStorage.getItem(APP_DEVICE_UUID);
		if (!this.appDeviceUuid) {
			this.appDeviceUuid = uuidv4();
			localStorage.setItem(APP_DEVICE_UUID, this.appDeviceUuid);
		}


		const logObjectText: string = sessionStorage.getItem(APP_INSTANCE_LOG);

		if (logObjectText) {
			this.instanceLog = JSON.parse(logObjectText);
		}
		else {

			let deviceInfo = {};
			try { deviceInfo = Bowser.parse(window.navigator.userAgent); }
			catch { }

			const appInstanceUuid = uuidv4();
			const utc = Math.round(new Date().getTime() / 1000);

			this.instanceLog = {
				host: location.hostname,
				createdUTC: utc,
				updatedUTC: utc,
				appInstanceUuid,
				appDeviceUuid: this.appDeviceUuid,
				numCalls: 0,
				calls: {},
				netError: false,
				appError: false,
				device: deviceInfo,
			};

		}

		let subscription: Subscription;

		auth.engineName$
			.pipe(filter(engineName => engineName == 'ME'))
			.subscribe(() => {

				if (subscription) subscription.unsubscribe();

				subscription = auth.me.firebaseUser$
					.subscribe(firebaseUser => {
						if (firebaseUser) {
							this.instanceLog.uid = firebaseUser.uid;
							this.instanceLog.name = firebaseUser.displayName;
							this.instanceLog.email = firebaseUser.email;
						}
					});
			});
	}



	/**
	 * Shim call with no payload.  If the action function has a payload of void, null or undefined
	 * then this shim call will be used instead and the shim will not take a parameter.
	 */
	protected _callNoPayload = <OUT>(path: string, flags: FanClientFlags = {}): () => Promise<OUT> => {
		const call = this._call<void, OUT>(path, flags);
		return () => call(undefined);
	}


	/**
	 * Shim call with a payload.  The shim will have a single parameter of type IN. The type can
	 * be a JSON object with properties and those values can be accessed through destructuring.
	 */
	protected _call = <IN, OUT>(action: string, flags: FanClientFlags = {}): (payload: IN) => Promise<OUT> => {

		const abortableReqs: { [index: string]: XMLHttpRequest } = {};
		const spin = !flags.noPageSpinner;

		const shimFunc = async <IN, OUT>(payload: IN): Promise<OUT> => {

			const _handleError = (reject, activity: string, error: string, errorName: string = undefined) => {
				this.errorPage.setError(activity, error, errorName, action, payload, this.appDeviceUuid, this.instanceLog.appInstanceUuid, this.instanceLog.numCalls);
				if (reject) reject(errorName);
			}

			try {
				return new Promise<OUT>(async (resolve, reject) => {

					const token = await this.auth.GetIdToken();
					const req = new XMLHttpRequest();
					const start = (new Date()).getTime();
					let attempt = 1;

					const callLog: CallLog = {
						index: ++this.instanceLog.numCalls,
						path: action,
						url: location.pathname,
						network: 'pending',
						timing: { startUTC: Math.round(start / 1000), }
					};

					this.instanceLog.updatedUTC = Math.round(start / 1000);
					this.instanceLog.calls[callLog.index] = callLog;


					//
					// Write the result and elapsed time into Google Analytics
					//
					const logResult = async (callLog: CallLog) => {
						const finish = new Date().getTime();
						callLog.timing.duration = (finish - start) / 1000;
						callLog.timing.stopUTC = Math.round(new Date().getTime() / 1000);

						let customEvent: CustomAnalyticsEventName.FUNC_COMPLETED | CustomAnalyticsEventName.FUNC_ERROR | CustomAnalyticsEventName.FUNC_ABORTED;

						if (callLog.network == 'success') customEvent = CustomAnalyticsEventName.FUNC_COMPLETED;
						else if (callLog.network == 'error') customEvent = CustomAnalyticsEventName.FUNC_ERROR;
						else if (callLog.network == 'abort') customEvent = CustomAnalyticsEventName.FUNC_ABORTED;

						this.auth.analytics?.logEvent(customEvent, { func: action, duration: callLog.timing.duration, callLog });

						this.instanceLog.updatedUTC = Math.round(finish / 1000);
						if (callLog.network == 'error') this.instanceLog.netError = true;
						if (callLog.appError) this.instanceLog.appError = true;
						if (callLog.context && callLog.context.personId) this.instanceLog.personId = callLog.context.personId;
						if (callLog.context && callLog.context.appSessionId) this.instanceLog.appSessionId = callLog.context.appSessionId;

						//
						// Show calls thus far in the console
						//
						const calls: CallLog[] = [];
						for (const callIndex in this.instanceLog.calls) {
							calls.push(this.instanceLog.calls[callIndex]);
						}

						calls.sort((c1, c2) => c1.index - c2.index);
					};

					//
					// The network request returned back successfully, but there may still be an application error
					//
					req.onload = () => {

						callLog.network = 'success';

						if (abortableReqs[action] == req) abortableReqs[action] = undefined;
						if (spin) this.ps.removeSpinner(action);

						if (req.status == 501) {
							//
							// NOTHING TO DO
							//
							// A duplicate attempt was detected in the backend in the checkForDuplicateCall
							// node middleware. The prior call attempt that got through has either already
							// completed and then resolved or rejected the promise or it is still pending
							// and will come back soon.
							//
						}
						else if (req.status === 200) {
							try {
								const responseText = lzString.decompressFromUTF16(req.responseText);
								const response = <FanActionResult<OUT>>JSON.parse(responseText);

								this.util.log.techMessage(`func.${action} - Succeeded`, { extra: response, extraLogging: 'ExtraToConsoleAndSentry' }, 'func')
								callLog.context = response.funcContext;

								// Some functions are flagged with allowNoSession so no appSession will be passed in and
								// a appSessionId of undefined will be returned. In that case, we don't set the appSession id.
								// For functions with allowNoSession set to false, the appSessionId is always returned,
								// but might be 0 when attempting to start a appSession unsuccessfully.
								// See the explanation in functions/src/fan/middleware/check-session.ts
								if (response.funcContext.appSessionId !== undefined && response.funcContext.appSessionId !== null) this.setAppSessionId(response.funcContext.appSessionId);

								logResult(callLog);
								resolve(response.payload);
							}
							catch (error) {

								callLog.appError = `FrontEnd: ${error.message}`;

								logResult(callLog);
								_handleError(reject, 'Response FrontEnd Error', error.message)
							}
						} else {

							const responseText = req.responseText;
							callLog.appError = responseText;

							try {
								const responseText = lzString.decompressFromUTF16(req.responseText);
								const response = <FanActionResult<string>>JSON.parse(responseText);
								if (response.stack) console.error(response.stack);
								callLog.appError = response.payload;
								callLog.context = response.funcContext;
							}
							catch { }

							logResult(callLog);
							_handleError(reject, 'Response BackEnd Error', callLog.appError, `HTTP${req.status} - ${req.statusText}`);
						}
					};


					//
					// The network request failed for some unknown reason
					//
					req.onerror = () => {

						if (attempt < MAX_ATTEMPTS) {

							const error = `${action} - Attempt #${attempt} failed. Will try again in ${attempt} sec.`;
							this.util.log.errorMessage(error);

							dataLayer.push({
								'event': 'data-error',
								'Error Message': `Failed to Connect Attempt #${attempt}`,
								'Error Details': error
							});

							setTimeout(() => {
								attempt++;
								message.callAttempt = attempt;

								req.open('POST', environment.functionUrl + 'action', true);
								req.setRequestHeader('Authorization', 'Bearer ' + token);
								req.setRequestHeader("Content-Type", "application/json");

								req.send(stringify(message));

							}, attempt * 1000);

						}
						else {

							callLog.network = 'error';
							callLog.appError = 'Failed to Connect';

							logResult(callLog);

							if (abortableReqs[action] == req) abortableReqs[action] = undefined;
							if (spin) this.ps.removeSpinner(action);

							const error = `${action} - Failed - Couldn't connect with ${MAX_ATTEMPTS} attempts.`;
							_handleError(reject, 'Network Error', error, 'Failed to Connect')
						}
					};


					//
					// The request was aborted, probably from a call to req.abort()  -- see flags.abortPendingCall below
					//
					req.onabort = () => {
						callLog.network = 'abort';
						callLog.appError = 'Call Aborted';

						logResult(callLog);

						if (abortableReqs[action] == req) abortableReqs[action] = undefined;
						if (spin) this.ps.removeSpinner(action);

						this.util.log.warning(`func.${action} - Aborted`);
						resolve(undefined);
					}


					req.open('POST', environment.functionUrl + 'action', true);
					req.setRequestHeader('Authorization', 'Bearer ' + token);
					req.setRequestHeader("Content-Type", "application/json");


					if (flags.abortPendingCall) {
						if (abortableReqs[action]) {
							this.util.log.warning(`Aborting prior call to action ${action} before making another.`);
							abortableReqs[action].abort();
						}

						abortableReqs[action] = req;
					}

					if (spin) this.ps.addSpinner(action);

					const message: FuncCallBody<IN> = Object.assign({}, { payload }, {
						action,
						appSessionId: this.getAppSessionId(),
						appInstanceUuid: this.instanceLog.appInstanceUuid,
						appDeviceUuid: this.appDeviceUuid,
						callUuid: uuidv4(),
						callAttempt: attempt,
						languageId: this.urlService.languageId,
					});

					this.auth.analytics?.logEvent(CustomAnalyticsEventName.FUNC_CALL, { func: action });
					req.send(stringify(message));
				});
			}
			catch (error) {
				_handleError(undefined, 'Function Preparation Error', error.message);
				return Promise.reject('Error preparing call to Function Service');
			}
		}

		shimFunc.fanClientFlags = flags;
		shimFunc.action = action;

		return shimFunc;
	}

	// These functions will be swapped out by the appSession service, to avoid a circular dep between func and appSession
	public getAppSessionId: () => number = () => {
		if (this.auth.engineName == 'ME') throw new Error('func.getSessionId() not overidden by appSession service.');
		return 0;
	};

	public setAppSessionId: (appSessionId: number) => void = (appSessionId) => {
		if (this.auth.engineName == 'ME') throw new Error('func.setSessionId() not overidden by appSession service.');
	};
}