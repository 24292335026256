<div class='row1'>
	<mat-form-field>
		<mat-select [placeholder]="selectPlaceholder" [value]='selectedPersonIds' multiple #select="matSelect"
			(selectionChange)='selectionChanged($event)' (openedChange)='openChanged($event)'>
			<mat-select-trigger>{{triggerText}}</mat-select-trigger>
			<mat-option *ngFor="let person of people" [value]="person.personId" [disabled]='!person.enabled'>
				{{person.firstName}} {{person.lastName}}
				<small *ngIf='person.message'> - {{person.message}}</small>
				<small *ngIf='person.tagged === false'> - missing {{tag}} tag</small>
			</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-button-toggle [checked]='plus' (change)='showPlus($event)' [class.plus]='plus'><i class='fas fa-plus'></i>
	</mat-button-toggle>
</div>
<div class='row2' *ngIf='plus'>
	<button class="text-btn text-btn-not-inline" (click)="searchPerson()">
		<me-icon [icon]="searchIcon"></me-icon>
	</button>
	<spinner-button [disabled]='!personToAdd || addingPerson' [green]="true" [spinning]='addingPerson' (safeClick)='addPerson()'>Add <span *ngIf="personToAdd">{{personToAdd._name}}</span>
	</spinner-button>
</div>