<div class='simple-tag-editor-part'>
	<selectable-view-header>{{prefix.toUpperCase()}} Tags</selectable-view-header>
	<message-line *ngIf='!canEdit' alertType='info'>
		You do not have access to change <code>{{prefix}}</code> tags
	</message-line>

	<div>Manual Tags</div>
	<div class='simple-tags-section-content' fxLayout='row wrap' fxLayoutGap='3px'>
		<mat-checkbox fxFlex='250px' *ngFor='let tag of manualPrefixTags' [checked]='tagValues[tag.tagId]'
			[disabled]='!canEdit' (change)='toggleTag(tag.tagId)'>
			<me-tag [tag]="tag" [clickable]='false' [showPrefix]='false'></me-tag>
		</mat-checkbox>
	</div>

	<ng-container *ngIf='tagPrefixHasAutoTags'>
		<div>Auto Tags</div>
		<me-tag *ngFor='let tag of autoPrefixTags' [tag]="tag" [clickable]='false' [showPrefix]='false'>
		</me-tag>
		<div *ngIf='!autoPrefixTags.length'>
			<i>None</i>
		</div>
	</ng-container>
</div>