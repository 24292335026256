
import { AdminAreaService, ProgramPageService } from '@ADMIN-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AppAreaIdentifier, AdminAreaAccess } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { PageTabsLayout } from '@me-shared-parts/UI-common';


@Component({
	selector: 'program-page',
	templateUrl: './program.page.html',
})
export class ProgramPage extends DestroyablePart implements OnInit {

	readonly: boolean;
	public tabNames = ["Program", "Get Help"];
	constructor(
		public pageService: ProgramPageService,
		public ds: DataService,
		public adminAreaService: AdminAreaService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();

		this.adminAreaService.subscribeAccess(this.destroyed$, this.accessChanged);
	}


	accessChanged = async (value: { access: AdminAreaAccess, id: AppAreaIdentifier<string> }) => {
		this.readonly = value?.access?.root != 'Write';
	}

	onLayoutChange(layout: PageTabsLayout) {
		this.pageService.changeLayout(layout);
	}

}