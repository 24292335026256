<div class='person-roles-part' kendoTooltip>
	<div title='entrepreneur: {{values.entrepreneur}}' class='role-badge'
		[class.role-badge-entrepreneur]='values.entrepreneur'>E</div>
	<div title='mentor: {{values.mentor}}' class='role-badge' [class.role-badge-mentor]='values.mentor'>M</div>
	<div title='specialist: {{values.specialist}}' class='role-badge' [class.role-badge-specialist]='values.specialist'>
		S</div>
	<div title='reader: {{values.reader}}' class='role-badge' [class.role-badge-reader]='values.reader'>R</div>
	<div title='interviewer: {{values.interviewer}}' class='role-badge'
		[class.role-badge-interviewer]='values.interviewer'>I</div>
	<div title='judge: {{values.judge}}' class='role-badge' [class.role-badge-judge]='values.judge'>J</div>
</div>