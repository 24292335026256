import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DdVenue } from '@me-services/core/old-dd';
import { IconContext } from '@me-shared-parts/UI-common';
import { ShowVenueDialogService } from '../venue-dialog/SHR-ED_show-venue-dialog.service';

const CHOOSE_VENUE_ICON: IconContext = { text: "Choose a Venue", iconClass: "far fa-map-marker-question", hideText: 'always' };


@Component({
	selector: 'venue-button',
	templateUrl: './SHR-ED_venue-button.part.html',
	styleUrls: ['./SHR-ED_venue-button.part.scss']
})
export class VenueButtonPart implements OnChanges {

	@Input() venue: DdVenue = undefined;
	@Input() buttonClass = '';
	@Output() selection = new EventEmitter<DdVenue>();

	icon: IconContext = CHOOSE_VENUE_ICON;


	constructor(private venueDialogService: ShowVenueDialogService) {
	}


	ngOnChanges() {
		if (this.venue) this.icon = { text: this.venue.displayedName, iconClass: "far fa-map-marker-alt", hideText: 'never' };
		this.configure(this.venue);
	}


	async showDialog() {
		this.configure(await this.venueDialogService.show(this.venue));
	}


	configure(venue: DdVenue | undefined) {

		if (venue) {
			if (this.venue?.venueId !== venue?.venueId) {
				this.venue = venue;
				this.icon = { text: venue.displayedName, iconClass: "far fa-map-marker-alt", hideText: 'never' };
				this.selection.emit(venue);
			}
		}
	}
}