import { AccApplicationContext, AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId, AccTeam, Accelerator, Answer, DbaAccInterview, FeedbackData, QuestionTypeId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { combineLatest, mergeMap } from 'rxjs';


interface InterviewersFeedback {
	acc: Accelerator,
	feedback: FeedbackData[],
}


@Component({
	selector: 'acc-interviewing-feedback-view-part',
	templateUrl: './acc-interviewing-feedback-view.part.html',
})
export class AccInterviewingFeedbackViewPart extends DestroyablePart implements OnInit {
	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.Interviewing);
	readonly: boolean;

	interviewersFeedback$ = combineLatest([
		this.accAreaService.accelerator.acc$,
		this.accAreaService.applications.applications$,
		this.accAreaService.interviewing.assessments$,
		this.accAreaService.teams.teams$
	]).pipe(mergeMap(data => this.buildFeedbackData(data[0], data[1], data[2], data[3])));

	constructor(
		private accAreaService: AccAreaService,
		private ds: DataService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe([this.accessAtStage$], async ([accessAtStage]) => {
			this.readonly = accessAtStage.access != 'Write';
		});
	}

	async buildFeedbackData(
		acc: Accelerator,
		allApplications: readonly AccApplicationContext[],
		interviewAssessments: {
			interview: DbaAccInterview,
			answers: readonly Answer[]
		}[],
		accTeams: readonly AccTeam[],
	): Promise<InterviewersFeedback> {

		if (!acc || !allApplications || !interviewAssessments.length || !accTeams) undefined;

		const accInterviewers = await this.ds.admin.accInterviewer.getManyPackagesAsArray([...interviewAssessments.map(ia => ia.interview.accInterviewerId)]);
		const people = await this.ds.admin.person.getManyPackagesAsMap([
			...accInterviewers.map(ai => ai.personId),
		]);

		const interviewsFeedBack = [];

		for (const a of allApplications) {
			const appInterviewAssessments = interviewAssessments.filter(ia => ia.interview.applicationId === a.application.applicationId);

			if (appInterviewAssessments.length) {
				const accTeam = accTeams.find(accTeam => accTeam.applicationId === a.application.applicationId);

				interviewsFeedBack.push({
					applicationId: a.application.applicationId,
					teamId: accTeam?.accTeamId,
					firstNames: a.application.person.firstName,
					emails: a.application.person._email,
					company: a.application.company.longName,
					hasLogo: false,
					status: this.getApplicationStatus(a.application.applicationStatusId),
					feedback: appInterviewAssessments.map(ia => {
						const originalFeedback = ia.answers.find(a => a.question.questionTypeId === QuestionTypeId.EntrepreneurFeedback).answer;
						const feedbackForEforAll = ia.answers.find(a => a.question.questionTypeId === QuestionTypeId.Ent_Feedback_Eforall).answer;
						const accInterviewer = accInterviewers.find(ai => ai.accInterviewerId === ia.interview.accInterviewerId);

						return {
							reviewerId: ia.interview.accInterviewerId,
							reviewer: people[accInterviewer.personId].asSingleton,
							originalFeedback,
							feedbackForEforAll,
							polishedFeedback: ia.interview.polishedFeedback,
							polishedFeedbackSkipped: ia.interview.polishedFeedbackSkipped,
						}
					})
				})
			}
		}

		return { acc, feedback: interviewsFeedBack };
	}

	getApplicationStatus(status: number) {
		if (status < 6) return '<= Interview Pending';
		if (status == 6) return 'Interview Rejected';
		if (status > 6) return '>= Interview Accepted';
	}

	async polishedFeedbackSkippedChanged(data: { reviewerId: number, applicationId: number, teamId: number, polishedFeedbackSkipped: boolean }) {
		if (this.readonly) return;

		const { applicationId, polishedFeedbackSkipped, reviewerId } = data;
		await this.accAreaService.interviewing.actions.setPolishedFeedbackSkipped({ accInterviewerId: reviewerId, applicationId, polishedFeedbackSkipped });
	}

	async polishedFeedbackChanged(data: { reviewerId: number, applicationId: number, teamId: number, polishedFeedback: string }) {
		if (this.readonly) return;

		const { applicationId, polishedFeedback, reviewerId } = data;
		await this.accAreaService.interviewing.actions.setPolishedFeedback({ accInterviewerId: reviewerId, applicationId, polishedFeedback });
	}

}