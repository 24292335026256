<div class="me-multiselect-part">
	<div class="checkbox" *ngFor="let item of items" [class.disabled-check-item]="readonly || item.disabled">

		<input type="checkbox" #cb kendoCheckBox [disabled]="readonly || item.disabled"
			[checked]="item.checked" (change)="onCheckboxChange($event, item)">

		<label [for]="cb">
			<me-icon [icon]="item.iconContext" for="cb"></me-icon>
		</label>

		<button *ngIf="buttonIcon" class="text-btn text-btn-not-inline" (click)="onbuttonClick(item)"><i
				class="{{ getButtonIconClass() }}"></i></button>

	</div>
</div>