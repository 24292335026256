import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { ADDROW_GRID_ACTION_KEY, GridAction, GridColumn, GridColumnType, GridSetup, REFRESH_GRID_ACTION_KEY, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogService } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { LayoutService } from '@me-services/ui/layout';
import { TABS_TAB_PADDING } from '@me-services/ui/layout/layout-constants';
import { PageSpinnerService } from '@me-services/ui/page-spinner';
import { ShowEventDialogService } from '@me-shared-parts/ED-editors';
import { ColumnComponent, GridComponent } from '@progress/kendo-angular-grid';
import { GroupResult } from '@progress/kendo-data-query';
import { BehaviorSubject, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Icon, getIconClass, getIconContext } from '../icon';
import { GridExperience, getGridExperience } from './base';
import { FilterableGridController } from './controller/filterable-grid-controller';
import { GroupableGridController } from './controller/groupable-grid-controller';
import { GridDownloadDialog } from './download/download.dialog';
import { GridFilterDialog } from './filter-dialog/filter.dialog';
import { DialogGridSize, InlineGridSize, PageGridSize, PageTabsMainTabGridSize } from './interfaces/grid-size';
import { ApplicationEditorService } from '../../../admin/editor-services/application/application-editor.service';


@Component({
	selector: 'me-grid',
	templateUrl: './SHR-CMN_grid.part.html',
	styleUrls: ['SHR-CMN_grid.part.scss'],
})
export class GridPart<RowT extends { updatedUTC: number }> extends DestroyablePart implements AfterViewInit, OnInit, OnChanges, OnDestroy {

	@ViewChild('meGridPart') gridPart: ElementRef;
	@ViewChild(GridComponent) gridComponent: GridComponent;

	@Input('setup') public setup: GridSetup<RowT>;
	@Input('rows') public rows: RowT[] = [];
	@Input('loading') public loading = false;

	@Output() public gridAction = new EventEmitter<{ actionKey: string, rows: RowT[] }>();


	public grid: FilterableGridController<RowT>;
	public experience: GridExperience<RowT>;
	private selectedRowIds: (number | string)[] = [];
	public hasActions = false;
	public hasEnabledActions = false;
	public removePageMargins = false;

	public filterClass$ = of('far fa-filter');

	public gridHeight: number;	// Numeric count of pixels
	public gridWidth = '100%';	// Css value like '500px' or '100%'
	public gridMarginTop = '0px';
	public gridMarginRight = '0px';
	public gridMarginBottom = '0px';
	public gridMarginLeft = '0px';

	private _setup: 'Pending' | 'Preparing' | 'Ready' = 'Pending';
	private _data: 'Pending' | 'Preparing' | 'Ready' = 'Pending';
	public loading$ = new BehaviorSubject<boolean>(true);
	public rows$ = new BehaviorSubject<RowT[]>([]);

	public redFlagIcon = getIconContext(Icon.notes_redFlag, undefined, 'always');
	public noRedFlagIcon = getIconContext(Icon.notes_noRedFlag, undefined, 'always');
	public noNotesIcon = getIconContext(Icon.notes_none, 'fa-thin', 'always');
	public someNotesIcon = getIconContext(Icon.notes_some, 'fa-solid', 'always');

	public showCheckboxes = false;

	/** If true then the column headers will also show the order number */
	private showColumnOrders = false;

	counts = '';  // e.g.  '1,000 people' or '21 of 79 mentors' 

	public text = {
		add: 'Add',
		refresh: 'Refresh',
		filter: 'Filter',
		download: 'Download',
		actions: 'Actions',
	};

	constructor(
		public ps: PageSpinnerService,
		public layoutService: LayoutService,
		public labelsService: LabelsService,
		private util: UtilityService,
		private dialogService: DialogService,
		private ds: DataService,
		private router: Router,
		private showEventDialogService: ShowEventDialogService,
		private applicationEditorService: ApplicationEditorService,
	) {
		super();
		this.translateText();
	}


	public getIconClass = getIconClass;


	async ngOnInit() {

		if (!this.setup) throw new Error('The required setup attribute was not provided.');
		if (!this.rows) this.rows = [];

		super.initDestroyable();

		this.grid = this.setup.groupArea == 'hide'
			? new FilterableGridController<RowT>(this.setup)
			: new GroupableGridController<RowT>(this.setup, this.labelsService);


		this.filterClass$ = this.grid.filters$.pipe(
			map(f => f?.filters?.length ? 'fas fa-filter' : 'far fa-filter'),
		);

		this.showCheckboxes = !!this.setup.multiselect;

		await this.prepareRows();

		if (this.rows$.value.length == 0) {
			this._data = 'Ready';
			await this.updateLoadingFlag();
		}

		this.grid.selectedRows$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(rows => {
				this.selectedRowIds = [...rows.map(row => row[this.grid.idField])];
				this.enableActions(rows);
				this.experience.doGridAction(SELECTION_GRID_ACTION_KEY, rows);
				// this.gridAction.emit({ actionKey: SELECTION_GRID_ACTION_KEY, rows });
			});

		this.grid.filteredRows$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(() => {
				this.updateCounts();
			});

		this.grid.doubleClick$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(({ keys, row }) => this.experience.handleDoubleClick(keys, row));

		//this.grid.setFilters


		//
		// Size the grid to a main page tab
		//
		if (this.setup.size.fitTo == 'PAGE-TABS-MAIN-TAB') {

			const size: PageTabsMainTabGridSize = this.setup.size;

			this.gridWidth = '100%';
			this.gridMarginTop = `-${TABS_TAB_PADDING}px`;
			this.gridMarginRight = `-${TABS_TAB_PADDING}px`;
			this.gridMarginBottom = `-${TABS_TAB_PADDING}px`;
			this.gridMarginLeft = `-${TABS_TAB_PADDING}px`;

			size.layout$
				.pipe(takeUntil(this.destroyed$))
				.subscribe(layout => {

					this.gridHeight = layout.tab1ContentHeight;
					if (size.viewSelector) this.gridHeight -= layout.tab1ViewDroplistHeight;


					//
					// If the heightMultiplier is less than 1 then we probably have two grids.
					// In this case, we remove the top negative margin
					//
					let verticalPaddings = 2;

					if (size.heightMultiplier < 1) {
						verticalPaddings = 1;
						this.gridMarginTop = '0px';
					}

					this.gridHeight = (this.gridHeight - size.shrinkBy) * size.heightMultiplier + verticalPaddings * TABS_TAB_PADDING;
					this.gridWidth = (layout.tab1ContentWidth + 2 * TABS_TAB_PADDING) + 'px';
				})
		}


		//
		// Size the grid to a the dialog content area
		//
		else if (this.setup.size.fitTo == 'DIALOG') {

			const size: DialogGridSize = this.setup.size;

			this.gridHeight = size.dialogContext.height - 54;	//subtract header and padding of <dialog-frame>
			if (size.dialogActions) this.gridHeight -= 43;		//subtract height for the <button-container> and DialogActions
			this.gridHeight = (this.gridHeight - size.shrinkBy) * size.heightMultiplier;
			this.gridWidth = (size.dialogContext.width - 14) + 'px';
			this.gridMarginTop = '0px';
			this.gridMarginRight = '0px';
			this.gridMarginBottom = '0px';
			this.gridMarginLeft = '0px';
		}

		else {

			this.gridWidth = '100%';
			this.gridMarginTop = '0px';
			this.gridMarginRight = '0px';
			this.gridMarginBottom = '0px';
			this.gridMarginLeft = '0px';

			this.layoutService.dimensions$
				.pipe(takeUntil(this.destroyed$))
				.subscribe(dims => {

					this.removePageMargins = false;


					//
					// Size the grid to a the page content area
					//
					if (this.setup.size.fitTo == 'PAGE') {

						const size: PageGridSize = this.setup.size;

						if (dims.windowWidth >= 544) {
							this.gridHeight = (dims.contentHeight - size.shrinkBy) * size.heightMultiplier;
							this.gridWidth = dims.contentWidth + 'px';
						}
						else {	// Remove margins on xs screens
							this.removePageMargins = true;
							this.gridHeight = (dims.contentHeightSnugXS - size.shrinkBy) * size.heightMultiplier;
							this.gridWidth = dims.contentWidthSnugXS + 'px';
						}
					}

					//
					// Size the grid as inline
					//
					else if (this.setup.size.fitTo == 'INLINE') {

						const size: InlineGridSize = this.setup.size;
						this.gridHeight = size.height - size.shrinkBy;
						this.gridWidth = '100%';
					}

				});
		}

	}


	ngAfterViewInit() {
		this.grid.setGridComponent(this.gridComponent);
	}


	async ngOnChanges(changes: SimpleChanges) {

		if (changes['setup']) await this.prepareSetup();
		if (changes['rows'] && this.grid) await this.prepareRows();

		this.updateLoadingFlag();
	}


	override ngOnDestroy() {
		this.grid.setGridComponent(undefined);
	}


	/**
	 * Considers whether the [loading] attribute is true/false and whether the
	 * _dataReady flag (rows built) is true/false. The combination is applied to
	 * the loading$ observable to show/hide the grids spinner.
	 */
	async updateLoadingFlag() {
		const loading = this.loading || this._data !== 'Ready';
		if (this.loading$.value !== loading) this.loading$.next(loading);

		// Let the UI catch up to show or hide the spinner
		await this.util.setTimeout();
	}


	/**
	 * When the GridSetup object is given or changed, some
	 * initial adjustments are made to prepare it for use.
	 */
	private async prepareSetup() {

		if (this._setup !== 'Pending') return;	// Only set up once
		this._setup = 'Preparing';

		//
		// The actionEnabler tells whether actions should be enabled given the
		// current set of selected rows, if any.  If an actionEnabler was not
		// provided then set all actions to always enabled.
		//
		if (!this.setup.actionEnabler) this.setup.actionEnabler = (action) => action.enabled = true;


		//
		// Build the object that defines the columns, actions, and action handlers
		//
		this.experience = getGridExperience(this.ds, this.util, this.setup, this.gridAction, this.router, this.showEventDialogService, this.applicationEditorService);



		//
		// Set defaults
		//
		this.setup.multiselect = this.setup.multiselect || false;


		//
		// Set default values of optional column properties
		//
		for (const col of this.experience.columns) {

			if (col.type == GridColumnType.gauge) {
				if (!col.gaugeThresholds) col.gaugeThresholds = [20, 40, 60, 80];
				else col.gaugeThresholds = col.gaugeThresholds.sort((a, b) => a - b);
			}
			else if (col.type == GridColumnType.progressBar) {
				if (!col.progressBarThresholds) col.progressBarThresholds = [50, 100];
				col.progressBarThresholds = col.progressBarThresholds.sort((a, b) => a - b);
			}
		}


		//
		// Translate text as needed
		//
		this.setup.rowSingularName = await this.labelsService.get(this.setup.rowSingularName);
		this.setup.rowPluralName = await this.labelsService.get(this.setup.rowPluralName);

		await this.experience.translateColumns(this.labelsService);

		this._setup = 'Ready';
	}


	/**
	 * When the array of row objects is given or changed, some
	 * initial adjustments are made to prepare each for display.
	 */
	private async prepareRows(): Promise<void> {

		if (!this.setup) return;
		if (!this.rows) return;


		if (this.rows.length == this.rows$.value.length) {

			let changed = false;
			const rowKey = this.setup.rowKey;

			//
			// Check if all the keys match in order
			//
			for (let i = 0; i < this.rows.length; i++) {

				const newRow = this.rows[i];
				const oldRow = this.rows$.value[i];

				if (newRow[rowKey] !== oldRow[rowKey]) {
					changed = true;
					break;
				}

				if (newRow.updatedUTC !== oldRow.updatedUTC) {
					changed = true;
					break;
				}
			}


			if (!changed) {

				//
				// If we get here, we have the same count of rows and each row, by index, has the same key as before.
				// Now we'll do a direct comparison of each column's value to see if anything changed.
				//
				for (let i = 0; i < this.rows.length; i++) {

					const newRow = this.rows[i];
					const oldRow = this.rows$.value[i];
					const columnsToAdd = this.setup.columnsToAdd ?? [];

					for (const col of columnsToAdd) {
						if (newRow[col.field] !== oldRow[col.field]) {
							changed = true;
							break;
						}
					}
				}
			}


			if (!changed) return;
		}


		this._data = 'Preparing';
		await this.updateLoadingFlag();


		//
		// Calculate values for any extra columns added to this experience depending on the setup.base.
		//
		await this.experience.applyBaseValues(this.rows);


		//
		// Sort the rows inline
		//
		this.experience.sortRows(this.rows);


		//
		// Build a new array of rows with the adjustments
		//
		const rows = this.rows.map(row => {

			for (const col of this.experience.columns) {

				const field = col.field;

				//
				// Change any UTC values to Date objects
				//
				if (col.type == GridColumnType.dateUtc || col.type == GridColumnType.timeUtc || col.type == GridColumnType.dateAndTimeUtc) {
					if (row[field] instanceof Date) continue;
					row[field] = row[field] ? new Date(row[field] * 1000) : null;
				}


				//
				// Change any null text values to empty strings
				//
				if (col.type == GridColumnType.text) {
					if (row[field] == null) row[field] = '';
				}

			}

			return row;
		});


		this.grid.setRows(rows);
		this.rows$.next(rows);
		this.updateCounts();

		this._data = 'Ready';
		await this.updateLoadingFlag();
	}


	/**
	 * Transform a cell value into something suitable to be displayed
	 */
	renderCell(row: RowT, col: GridColumn<RowT, unknown>): string | number | number[] {

		const field = col.field;
		const value = row[field];

		let display: string | number | number[];


		if (col.type == GridColumnType.text) {
			display = row[field] = value ?? '';
		}

		//
		// If a render() function was provided, use it instead
		//
		else if (col.render) {
			if (value == undefined) display == null;
			else display = col.render(value);
		}

		else if (value == undefined) {
			display = null;
		}

		else if (col.type == GridColumnType.boolean) {
			display = <string>col.booleanDisplay[!!value ? 1 : 0];
		}

		else if (col.type == GridColumnType.number) {
			display = (value == undefined ? null : +value)?.toLocaleString(undefined, { minimumFractionDigits: col.fractionDigits, maximumFractionDigits: col.fractionDigits });
		}

		else if (col.type == GridColumnType.year) {
			display = (value == undefined ? null : +value)?.toString();
		}

		else if (col.type == GridColumnType.dollars) {
			display = (value == undefined ? null : +value)?.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: col.fractionDigits, maximumFractionDigits: col.fractionDigits });
		}
		else if (col.type == GridColumnType.zipCode) {
			display = (value == undefined ? null : ("00000" + (+value)).slice(-5));
		}

		else if (col.type == GridColumnType.percent) {
			const v = value?.toLocaleString();
			display = null;
			if (v !== undefined) display = v + '%';
		}

		else if (col.type == GridColumnType.phone) {
			display = this.util.phone.format(value);
		}

		//
		// All UTC values are changed to Date objects
		//
		else if (col.type == GridColumnType.date || col.type == GridColumnType.dateUtc) {
			display = this.util.date.formatDate(value, 'MMM D, YYYY', this.ds.languageId);
		}

		else if (col.type == GridColumnType.time || col.type == GridColumnType.timeUtc) {
			display = this.util.date.formatTime(value, this.ds.languageId);
		}

		else if (col.type == GridColumnType.dateAndTime || col.type == GridColumnType.dateAndTimeUtc) {
			display = this.util.date.formatDateAndTime(value, 'MMM D, YYYY', this.ds.languageId);
		}

		else if (col.type == GridColumnType.entityNotes) {
			display = value == null ? 'Red Flag' : this.util.text.singularOrPluralWithCount(value, 'Note', 'Notes');
		}

		else if (col.type == GridColumnType.ratings) {
			display = ("" + value)
				.split('-')
				.map(segment => {
					const v = parseInt(segment.trim(), 10);
					return isNaN(v) ? 0 : v;
				});
		}

		else if (col.type == GridColumnType.season) {
			return 'season';
		}

		return display;
	}


	add() {
		this.gridAction.emit({ actionKey: ADDROW_GRID_ACTION_KEY, rows: [] })
	}


	refresh() {
		this.gridAction.emit({ actionKey: REFRESH_GRID_ACTION_KEY, rows: [] })
	}


	async changeFilter() {

		const action = await this.dialogService.showCustom(
			GridFilterDialog,
			{
				data: { filters: this.grid.filters$.value, columns: this.experience.columns },
			},
			600, 400
		);

		if (!action) return;

		if (action.id == 'clear') this.grid.clearFilters();
		else if (action.id == 'apply') this.grid.setFilters(action.callbackResult);
	}


	async download() {

		//
		// Open dialog to download
		//
		const name = this.setup.downloadFileName ? this.setup.downloadFileName : typeof this.setup.rowPluralName == 'string' ? this.setup.rowPluralName : await this.labelsService.get(this.setup.rowPluralName['key']);
		const columns: {
			header: string,
			field: string,
			type: string,
			hidden: boolean,
		}[] = [];

		for (const col of this.experience.columns.filter(col => ![GridColumnType.icon, GridColumnType.iconContext].includes(col.type))) {
			if (!col.noDownload) columns.push({
				header: typeof col.header == 'string' ? col.header : await this.labelsService.get(col.header),
				field: col.field,
				type: col.type,
				hidden: !!col.hidden,
			});
		}


		await this.dialogService.showCustom(
			GridDownloadDialog,
			{
				data: {
					name,
					columns,
					allRows: this.grid.getRows(),
					filteredRows: this.grid.getFilteredRows(),
				},
			},
			600, 400
		);

	}


	/**
	 * Build the count display under the header buttons. (e.g. "20 Mentors")
	 * If the grid is filtered then prefix it with that count (e.g. "7 of 20 Mentors") 
	 */
	async updateCounts() {

		let counts = this.rows.length.toLocaleString();
		const filteredRows = this.grid.filteredRows$.value;

		if (filteredRows.length < this.rows.length) {
			const getLabel = await this.labelsService.getLabel();
			counts = filteredRows.length.toLocaleString() + ' ' + getLabel({ key: 'of' }) + ' ' + counts;
		}

		this.counts = counts;
	}


	private enableActions(rows: RowT[]) {

		const hasRows = !!rows?.length;
		this.hasActions = hasRows && !!this.experience.actions.length;
		this.hasEnabledActions = false;

		for (const action of this.experience.actions) {

			action.enabled = hasRows;

			if (hasRows && this.setup.actionEnabler) {
				action.enabled = this.setup.actionEnabler(action, rows);
			}

			if (action.enabled) this.hasEnabledActions = true;
		}
	}


	onActionItemClick(action: GridAction) {
		if (!action.enabled) return;
		this.experience.doGridAction(action.key, this.getSelectedRows());
	}


	onActionMenuOpen() {
		this.enableActions(this.getSelectedRows());
	}


	getSelectedRows(): RowT[] {
		return this.rows.filter(row => {
			const id: number | string = row[this.grid.idField];
			return this.selectedRowIds.includes(id);
		});
	}


	async translateText() {

		const getLabel = await this.labelsService.getLabel();

		this.text.add = getLabel({ key: 'Add' });
		this.text.refresh = getLabel({ key: 'Refresh' });
		this.text.filter = getLabel({ key: 'Filter' });
		this.text.download = getLabel({ key: 'Download' });
		this.text.actions = getLabel({ key: 'Actions' });
	}


	getGroupCount(group: GroupResult) {
		return group.aggregates[group.field]?.count;
	}


	getColumnName(group: GroupResult) {
		const column = this.experience.columns.find(c => c.field == group.field);
		if (typeof column?.header == 'string') {
			return `${column.header}:`;
		}
		else return '';
	}


	formatGroupValue(group: GroupResult) {

		if (group.value === '' || group.value == null) return '(empty)';
		return group.value;
	}


	getHeaderTooltip(column: ColumnComponent) {
		const col = this.experience.columns.find(c => c.field == column.field);
		if (!col) return column.title;

		if (this.showColumnOrders) return col.field + ' - ' + col.order;
		else return col.headerTooltip ?? column.title;
	}


	getHeader(col: GridColumn<RowT, unknown>) {
		if (this.showColumnOrders) return col.header + ' - ' + col.order;
		else return col.header;
	}


	countsClicked(e: PointerEvent) {
		if (e.ctrlKey) {
			this.showColumnOrders = !this.showColumnOrders;
		}
	}
}