import { DbsEvent, MutableDbsEvent } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { formatDate } from '@telerik/kendo-intl';

export const MINUTE = 60 * 1000;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const INVALID_EVENT_DURATION = 'Invalid!';


/**
 * Store start and end dates and the caclulated
 * duration display string for the event times or
 * the period the tool is active.
 */
export interface DateRange {
	kind: 'event' | 'tool',
	start?: Date;
	end?: Date;
	duration?: string;
	description?: string;
}


export class DatesProcessor {


	//
	// Dates in JavaScript format for binding with kendo
	//
	eventDates: DateRange = { kind: 'event' };
	toolDates: DateRange = { kind: 'tool' };

	private _useAutomaticToolDates = true;

	get useAutomaticToolDates() {
		return this._useAutomaticToolDates;
	}

	set useAutomaticToolDates(auto: boolean) {
		if (this._useAutomaticToolDates == auto) return;
		this._useAutomaticToolDates = auto;
		if (this._useAutomaticToolDates) this.setAutomaticToolDates();
	}


	constructor(private event: MutableDbsEvent, private util: UtilityService) {

		this.initializeDates(this.event.startUTC, this.event.endUTC, this.eventDates);
		this.initializeDates(this.event.toolStartUTC, this.event.toolEndUTC, this.toolDates);
		this._useAutomaticToolDates = this.hasDefaultToolDates();
	}


	/**
	 * Convert UTC numbers to JavaScript date objects and then process them dates.
	 */
	private initializeDates(startUTC: number, endUTC: number, dates: DateRange) {
		if (startUTC) dates.start = this.util.date.getDate(startUTC);
		if (endUTC) dates.end = this.util.date.getDate(endUTC);
		this.processDates(dates, true);
	}



	/**
	 * Given a date range, check the dates are in the right order and
	 * calculate a duration description.
	 * @param dates A DateRange object of either 'event' or 'tool' kind
	 * @param skipToolDatesAdjustment If dates.kind is 'event' then tool dates will be also adjusted unless this parameter is true
	 */
	processDates(dates: DateRange, skipToolDatesAdjustment = false) {

		dates.duration = undefined;
		dates.description = undefined;

		if (dates.start && dates.end) {

			//
			// Make a simple duration description
			//
			const elapsed = dates.end.getTime() - dates.start.getTime();

			if (elapsed < 0) dates.duration = INVALID_EVENT_DURATION;
			else {
				if (elapsed >= 2 * DAY) dates.duration = Math.round(elapsed / DAY) + ' Days';
				else if (elapsed >= 2 * HOUR) dates.duration = Math.round(elapsed / HOUR) + ' Hours';
				else dates.duration = Math.round(elapsed / MINUTE) + ' Minutes';
			}


			//
			// Make a description for the readonly view
			//
			dates.description = `${formatDate(dates.start, 'EEE, MMM dd, y')} at ${formatDate(dates.start, 'h:mm a')} for ${dates.duration}`;


			//
			// Calculate the database UTC values
			//
			if (dates.kind == 'event') {
				this.event.startUTC = Math.floor(dates.start.getTime() / 1000);
				this.event.endUTC = Math.floor(dates.end.getTime() / 1000);
				if (!skipToolDatesAdjustment) this.setAutomaticToolDates();

			}

			else if (dates.kind == 'tool') {
				this.event.toolStartUTC = Math.floor(dates.start.getTime() / 1000);
				this.event.toolEndUTC = Math.floor(dates.end.getTime() / 1000);
			}
		}

		else {
			this.event[dates.kind + 'StartUTC'] = undefined;
			this.event[dates.kind + 'EndUTC'] = undefined;
		}

	}


	/**
	 * Set the tool dates to one day before the event start
	 * and one day after the event end.
	 */
	private setAutomaticToolDates() {

		if (!this._useAutomaticToolDates) return;

		if (this.eventDates.start) {
			this.toolDates.start = new Date(this.eventDates.start.getTime() - 1 * DAY);
		}

		if (this.eventDates.end) {
			this.toolDates.end = new Date(this.eventDates.end.getTime() + 1 * DAY);
		}

		this.processDates(this.toolDates);
	}


	/**
	 * Determine if the tool dates are exactly one day before and
	 * one day after the event dates.
	 */
	private hasDefaultToolDates(): boolean {

		const eventStart = this.eventDates?.start?.getTime() ?? 0;
		if (!eventStart) return true;

		const eventEnd = this.eventDates?.end?.getTime() ?? 0;
		if (!eventEnd) return true;

		const toolStart = this.toolDates?.start?.getTime() ?? 0;
		if (!toolStart) return false;

		const toolEnd = this.toolDates?.end?.getTime() ?? 0;
		if (!toolEnd) return false;

		return toolStart == eventStart - DAY && toolEnd == eventEnd + DAY;

	}
}