import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { DestroyablePart } from "@me-access-parts";
import { ApplicationStatusId } from "@me-interfaces";

type ApplicationStatus = 'AUTHORING' | 'ABANDONED' | 'WITHDRAWN' | 'REJECTED' | 'DEFERRED' | 'COPIEDFORWARD' | 'WAITING' | 'ACCEPTED';


@Component({
	selector: 'application-viewer-message',
	templateUrl: './SHR-PG_application-viewer-message.part.html',
	styles: []
})
export class ApplicationViewerMessagePart extends DestroyablePart implements OnInit, OnChanges {

	@Input() viewerMessage: { applicationStatusId: ApplicationStatusId, acceptingApplications: boolean, programTypeId: number };

	status: ApplicationStatus;

	constructor(private router: Router) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

	ngOnChanges() {
		if (!this.viewerMessage) throw new Error('Missing required attribute: viewerMessage');

		const isAccepting = this.viewerMessage.acceptingApplications;

		const closedStatuses: { [index: number]: ApplicationStatus } = {

			[ApplicationStatusId.AcceptPending]: 'AUTHORING',
			[ApplicationStatusId.AcceptAbandoned]: 'ABANDONED',
			[ApplicationStatusId.AcceptWithdrawn]: 'WITHDRAWN',
			[ApplicationStatusId.AcceptRejected]: 'REJECTED',
			[ApplicationStatusId.AcceptDeferred]: 'DEFERRED',
			[ApplicationStatusId.AcceptCopiedForward]: 'COPIEDFORWARD',
			
			[ApplicationStatusId.ReadPending]: 'WAITING',
			[ApplicationStatusId.ReadAbandoned]: 'ABANDONED',
			[ApplicationStatusId.ReadWithdrawn]: 'WITHDRAWN',
			[ApplicationStatusId.ReadRejected]: 'REJECTED',
			[ApplicationStatusId.ReadDeferred]: 'DEFERRED',
			[ApplicationStatusId.ReadCopiedForward]: 'COPIEDFORWARD',
			
			[ApplicationStatusId.InterviewPending]: 'WAITING',
			[ApplicationStatusId.InterviewAbandoned]: 'ABANDONED',
			[ApplicationStatusId.InterviewWithdrawn]: 'WITHDRAWN',
			[ApplicationStatusId.InterviewRejected]: 'REJECTED',
			[ApplicationStatusId.InterviewDeferred]: 'DEFERRED',
			[ApplicationStatusId.InterviewCopiedForward]: 'COPIEDFORWARD',
			
			[ApplicationStatusId.SelectPending]: 'WAITING',
			[ApplicationStatusId.SelectAbandoned]: 'ABANDONED',
			[ApplicationStatusId.SelectWithdrawn]: 'WITHDRAWN',
			[ApplicationStatusId.SelectRejected]: 'REJECTED',
			[ApplicationStatusId.SelectDeferred]: 'DEFERRED',
			[ApplicationStatusId.SelectCopiedForward]: 'COPIEDFORWARD',
			
			[ApplicationStatusId.TeamPending]: 'WAITING',
			[ApplicationStatusId.TeamPromoted] : 'ACCEPTED',
		};

		this.status = closedStatuses[this.viewerMessage.applicationStatusId]

	}

	openPrograms() {
		this.router.navigate([`access/my/programs`]);
	}
}
