import { AppAreaApplyResponse, AppAreaFuncResponse, AppAreaIdentifierWithSite, AppAreaIdentifierWithSiteId } from "@me-interfaces";
import { AppSubArea } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";


export class AccSubAreaMentorMatching extends AppSubArea {

	constructor(
		private func: FuncService,
		ds: DataService,
		util: UtilityService,
		getId: () => AppAreaIdentifierWithSiteId<number>,
		applyResponse: (response: AppAreaFuncResponse) => Promise<AppAreaApplyResponse>,
	) {
		super(ds, util, getId, applyResponse);
	}


	async setConfig(parameters: { personId: number, eventId: number, canCoach: boolean, useCount: number }) {
		return await this.call(this.func.areas.acc.mentorMatching.setConfig, parameters);
	}

	async setMentors(parameters: { personIds: number[] }) {
		return await this.call(this.func.areas.acc.mentorMatching.setMentors, parameters);
	}

	async removeMentor(parameters: { personId: number }) {
		return await this.call(this.func.areas.acc.mentorMatching.deleteMentor, parameters);
	}

	async setSchedule(parameters: { tableCode: 'M' | 'T', slot: number, day: number, set: boolean, accTeamId?: number, personId?: number }) {
		return await this.callWithoutStandardResponse(this.func.areas.acc.mentorMatching.setSchedule, parameters);
	}

	async setAssessment(parameters: { accTeamId: number, personId: number, assessment: number, assessmentOn: 'm2t' | 't2m' }) {
		return await this.call(this.func.areas.acc.mentorMatching.setAssessment, parameters);
	}

	async setHints(parameters: { hints: string }) {
		return await this.call(this.func.areas.acc.mentorMatching.setHints, parameters);
	}

}