import { DbsAccSessionTopicSurveyResponse } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { calcSpecialistSurveyCounts } from "./calc-specialist-survey-counts";
import { SurveyCountsData } from "./get-survey-counts-data";
import { CurriculumSurveyTopicCounts } from "./interfaces";


/**
 *
 * Given an array of survey results, calculate roll up values
 *
 * @param topicId - The topicId
 * @param topicName - The name of the topic
 * @param surveys - The personId is the attendee personId for the survey
 */
export function calcTopicSurveyCounts(
	util: UtilityService,
	topicId: number,
	topicName: string,
	surveys: DbsAccSessionTopicSurveyResponse[],
	data: SurveyCountsData,
) {

	const specialistSurveys = data.specialistSurveysByTopicId[topicId] ?? [];
	const specialistSurveysByPersonId = util.array.toArrayMap(specialistSurveys, s => s.personId);
	const specialistPersonIds = util.array.cleanNumericIds(specialistSurveys.map(s => s.personId));
	const specialistCounts = specialistPersonIds.map(personId => calcSpecialistSurveyCounts(
		util,
		personId,
		data.specialistByPersonId[personId].name,
		specialistSurveysByPersonId[personId],
		data,
	));


	const counts: CurriculumSurveyTopicCounts = {
		name: topicName,
		relevanceScore: util.nps.calcScore(surveys.map(s => s.relevance)),
		needScore: util.nps.calcScore(surveys.map(s => s.need)),
		understandingScore: util.nps.calcScore(surveys.map(s => s.understanding)),
		durationScore: util.nps.calcScore(surveys.map(s => s.duration)),
		comments: surveys
			.filter(s => s.other?.trim().length > 0)
			.map(s => ({
				comment: s.other.trim(),
				entrepreneurName: data.attendeeByAttendeeId[s.accSessionAttendeeId]?.person.fullName ?? 'MISSING ATTENDEE',
				personId: data.attendeeByAttendeeId[s.accSessionAttendeeId]?.person.personId,
			})),
		specialists: specialistCounts,
	}

	return counts;
}
