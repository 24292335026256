<selectable-view *ngIf='selectors' [selectorList]='selectors' listTitle='Tag Type'
	[initialSelectorKey]='initialSelectorKey' (selectorKeyChanged)='setSelectedKey($event)'>
	<div class='selector-content-section' *ngIf='key'>
		<div *ngIf='key === "selectable-view-overview" || key.endsWith("options")' class='selection-view-overview'>
			<selectable-view-header>Overview</selectable-view-header>
			<person-tags-overview class='tags-overview-selector' [inDialog]='true' [tags]='personTags'
				(navigateToTagGroup)='selectionChange($event)'>
			</person-tags-overview>
		</div>
		<ng-container *ngIf='key !== "selectable-view-overview" && !key.endsWith("options")'>
			<div class='selector-tag-editor-section'>
				<ng-container *ngFor='let body of bodies'>

					<simple-tag-editor *ngIf='body.component==="simple-tag-editor" && body.key === key' [person]='person'
						(tagsChanged)='tagsChanged($event)' [canEdit]='!body.locked'
						[prefixTags]='getTagsFromPrefix(body.prefix)'>
					</simple-tag-editor>

					<site-tag-editor *ngIf='body.component==="site-tag-editor" && body.key === key' [person]='person'
						(tagsChanged)='tagsChanged($event)' [canEdit]='!body.locked'
						[siteTags]='getTagsFromPrefix(body.prefix)'>
					</site-tag-editor>

				</ng-container>
			</div>
			<div fxShow fxHide.lt-lg class='selector-tags-overview-section'>
				<selectable-view-header>Overview</selectable-view-header>
				<person-tags-overview [tags]='personTags' [inDialog]='true'
					(navigateToTagGroup)='selectionChange($event)'>
				</person-tags-overview>
			</div>
		</ng-container>
	</div>
</selectable-view>