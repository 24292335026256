import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { DbsPerson, DbcUser, PersonAccessBaseFlags } from '@me-interfaces';
import { OldEntityService } from '@me-services/old-services-and-wrappers/entity';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { OldDomainDataService } from '@me-services/core/old-dd';

@Component({
	selector: 'person-permissions-editor',
	templateUrl: './SHR-ED_person-permissions-editor.part.html',
	styleUrls: ['SHR-ED_person-permissions-editor.part.scss'],
})
export class PersonPermissionsEditorPart implements OnInit {

	@Input() person: DbsPerson;

	user: DbcUser;
	crmDisabled = false;
	eForAllAdminDisabled = false;
	sysadmDisabled = false;
	editDemographicsDisabled = false;
	eForEverAdminDisabled = false;
	form: FormGroup;


	constructor(
		private dd: OldDomainDataService,
		private fb: FormBuilder,
		private userService: OldUserService,
		private entityService: OldEntityService,
	) { }

	async ngOnInit() {
		this.user = await this.userService.getUser();
		this.createForm();
	}

	createForm() {
		this.form = this.fb.group({
			canEditDemographics: [false],
			isCrmUser: [false],
			isEForAllAdmin: [false],			//EForAll Admin
			isTechAdmin: [false],		//Tech Admin
		});

		this.resetForm();
	}

	public resetForm() {
		const p = this.person;
		const values = {
			canEditDemographics: p.canEditDemographics,
			isCrmUser: p.isCrmUser,
			isEForAllAdmin: p.isEForAllAdmin,
			isTechAdmin: p.isTechAdmin,
		};

		this.form.reset(values);

		Object.keys(this.form.controls).forEach(key => {
			const ctrl: AbstractControl = this.form.get(key);
			ctrl.markAsTouched();
		});

		this.form.updateValueAndValidity();
		this.enableCheckboxes();
	}

	private enableCheckboxes() {
		const editingCurrentUser = this.user.personId == this.person.personId;
		const personIsGary = this.person.personId == 1;

		this.crmDisabled = editingCurrentUser || this.form.value.isEForAllAdmin || this.form.value.isEForeverAdmin || this.form.value.isTechAdmin || (!this.user.isEForAllAdmin);
		this.editDemographicsDisabled = editingCurrentUser || !this.form.value.isCrmUser || this.form.value.isTechAdmin || this.form.value.isEForAllAdmin || this.form.value.isEForeverAdmin || (!this.user.isEForAllAdmin);
		this.eForAllAdminDisabled = editingCurrentUser || !this.form.value.isCrmUser || this.form.value.isTechAdmin || !this.user.isTechAdmin;
		this.eForEverAdminDisabled = editingCurrentUser || !this.form.value.isCrmUser || this.form.value.isTechAdmin || !this.user.isTechAdmin;
		this.sysadmDisabled = editingCurrentUser || personIsGary || !this.form.value.isCrmUser || !this.form.value.isEForAllAdmin || !this.form.value.isEForeverAdmin || !this.user.isTechAdmin;
	}

	toggleCheckbox(box: string) {
		let ctrl: AbstractControl = undefined;

		if (box == 'C') ctrl = this.form.controls.isCrmUser;
		if (box == 'D') ctrl = this.form.controls.canEditDemographics;

		if (box == 'EFAADM') ctrl = this.form.controls.isEForAllAdmin;
		if (box == 'EFEADM') ctrl = this.form.controls.isEForeverAdmin;
		if (box == 'SYS') ctrl = this.form.controls.isTechAdmin;

		const checked = !ctrl.value;

		if (box == 'EFAADM' && checked) {
			this.form.controls.isCrmUser.patchValue(true);
			this.form.controls.canEditDemographics.patchValue(true);
		}

		if (box == 'EFEADM' && checked) {
			this.form.controls.isCrmUser.patchValue(true);
			this.form.controls.canEditDemographics.patchValue(true);
		}

		if (box == 'SYS' && checked) {
			this.form.controls.isEForAllAdmin.patchValue(true);
			this.form.controls.isEForeverAdmin.patchValue(true);
			this.form.controls.isCrmUser.patchValue(true);
			this.form.controls.canEditDemographics.patchValue(true);
		}

		ctrl.patchValue(checked);
		ctrl.markAsDirty();
		this.enableCheckboxes();
	}

	async save() {
		const values: PersonAccessBaseFlags = {
			isCrmUser: this.form.value.isCrmUser,
			canEditDemographics: this.form.value.canEditDemographics,
			isEForAllAdmin: this.form.value.isEForAllAdmin,
			isTechAdmin: this.form.value.isTechAdmin,
		};

		await this.entityService.wrapPerson(this.person).updateBaseFlags(values);
		this.resetForm();
	}
}
