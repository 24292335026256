import { DbConceptName, Person } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForMentorship(util: UtilityService, ds: DataService, person: Person): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	//
	// Get the accTeamMember packages
	//
	const membersByPersonId = await ds.admin.accTeamMember.getArraysByForeignIds('personId', [person.personId]);
	const members = membersByPersonId[person.personId];
	const mentorMembers = members.filter(member => member.role != 'E');


	//
	// Clean the list of applicationIds, and sort them largest (newest) first.
	//
	const accTeamIds = util.array.cleanNumericIds(mentorMembers.map(m => m.accTeamId));
	const accTeamMap = await ds.admin.accTeam.getManyPackagesAsMap(accTeamIds);



	for (const member of mentorMembers.sort((a, b) => accTeamMap[b.accTeamId].accelerator.applStartUTC - accTeamMap[a.accTeamId].accelerator.applStartUTC)) {
		const accTeam = accTeamMap[member.accTeamId];
		const site = accTeam.accelerator.siteProgram.site;
		const acc = accTeam.accelerator;

		const header = member.role == 'C' ? 'Coach' : 'Mentor';

		const memberText = accTeam.members.filter(m => m.member.role == 'E')
			.map(member => `${member.person.firstName} ${member.person.lastName.slice(0, 1)}.`)
			.sort((a, b) => a > b ? 1 : -1)
			.join(', ');

		const notes = [];
		if (accTeam.awardedValue > 0) notes.push(`Awarded: $${accTeam.awardedValue}`);
		if (accTeam.droppedOutWeek) notes.push(`Team Dropped Out Week: ${accTeam.droppedOutWeek}`);
		if (member.role == 'X') notes.push(`Mentor Quit`);


		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Mentor,
			header,
			buttons: [
				{ explorer: { explorableId: { conceptName: DbConceptName.Site, id: site.siteId }, navTooltip: `Site: ${site.name}` } },
				{ explorer: { nameOverride: `${acc.name} Accelerator`, explorableId: { conceptName: DbConceptName.Accelerator, id: acc.accId }, navTooltip: `Accelerator: ${site.code} ${acc.name}` } },
				{ explorer: { nameOverride: accTeam.name, explorableId: { conceptName: DbConceptName.Company, id: accTeam.application.companyId }, navTooltip: `Company: ${accTeam.name}` } },
				{ explorer: { nameOverride: `${memberText} & Team`, explorableId: { conceptName: DbConceptName.AccTeam, id: member.accTeamId }, navTooltip: `Acc Team: ${memberText} & Team` } }
			],
			notes: notes.join(', '),
		};

		boxes.push(box);
	}

	return boxes;

}