import { Component, Input, OnChanges } from '@angular/core';
import { Application } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { UrlService } from '@me-services/ui/url';


@Component({
	selector: 'application-explorer-details',
	templateUrl: './details.part.html',
	styleUrls: ['./details.part.scss'],
})
export class ApplicationExplorerDetailsPart implements OnChanges {
	@Input() application: Application;

	constructor(
		public urlService: UrlService,
		public util: UtilityService,
	) { }



	ngOnChanges() {
		if (!this.application) throw new Error('Missing required attribute: application');
	}
}