import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction } from '@me-services/ui/dialog';
import { PageSpinnerService } from '@me-services/ui/page-spinner';

@Component({
	selector: 'me-dialog-frame',
	templateUrl: './dialog-frame.part.html',
	styleUrls: ['dialog-frame.part.scss'],
})

export class DialogFramePart implements OnChanges {
	@Input() icon = 'fa-info-circle';
	@Input() header: string;
	@Input() showToolbar = false;
	@Input() actions: DialogAction[] = [];
	@Input() dialogRef: MatDialogRef<any, DialogAction<unknown>>;
	@Input() alertType: 'info' | 'success' | 'warning' | 'error' | 'editor' = 'info';

	@Output() actionClick = new EventEmitter<DialogAction<unknown>>();
	@Output() closed = new EventEmitter<void>();

	handlingClick = false;

	constructor(
		private router: Router,
		public pageSpinner: PageSpinnerService,
		private util: UtilityService,
	) {
	}

	ngOnChanges() {
		/**
		 * Dialogs must be closed with the top-right x or with an action button.
		 * The page spinner will also show on an open dialog, and we do not want
		 * the user to be able to press esc to close the dialog while the spinner
		 * is active (e.g. a func is being awaited).
		 */
		this.dialogRef.disableClose = this.actions.length > 0;
	}

	async click(action: DialogAction<unknown>) {
		this.handlingClick = true;

		//
		// perform the action
		//
		if (action.linkType == 'routerLink') {
			this.router.navigate([action.url]);
		}
		else if (action.linkType == 'href') {
			//
			// Nothing to do. The a[href] will handle
			// the navigation
			//
		}
		else if (action.linkType == 'callback') {
			action.callbackResult = (await action.callback()) ?? action.callbackResult;
		}
		else if (action.linkType == 'actionClick-event') {
			this.actionClick.emit(action);
			if (!this.actionClick.observers.length) {
				this.util.log.errorMessage(`Action '${action.id}' with linkType 'actionClick-event' was clicked but the (actionClick) EventEmitter has no registered observers.`);
			}
		}


		//
		// close the dialog
		//
		if (action.willCloseDialog) {
			this.closed.emit();
			this.dialogRef.close(action);
		}

		this.handlingClick = false;
	}

	public closeClick(): void {
		this.closed.emit();
		this.dialogRef.close();
	}
}
