import { DataService } from "@me-services/core/data";
import { PendingSearchMatch, SearchableData, SearchMatchLevel, SearchRegExps, TextToMatch } from "../interfaces";
import { checkTexts } from "./check-texts";


export async function findCompanyMatches(
	data: SearchableData,
	re: SearchRegExps,
	term: string,
	termPhoneDigits: string,
	ds: DataService,
): Promise<PendingSearchMatch[]> {


	const matches: PendingSearchMatch[] = [];


	for (const company of data.companies) {

		const zip = data.zipByZipId[company.zipId];

		//
		// General Text Properties
		//
		const texts: TextToMatch[] = [
			{ text: company.name, field: 'Name' },
			{ text: company.longName, field: 'Long Name' },
			{ text: company.shortName, field: 'Short Name' },
		];


		//
		// Address
		//
		if (zip) {
			texts.push({ text: `${company.address ?? ''} ${zip.cityAndState}`, field: 'Address' });
		}
		else {
			texts.push({ text: company.address ?? '', field: 'Address' });
		}


		//
		// Notes
		//
		for (const note of data.entityNotesByEntityId[company.entityId] || []) {
			texts.push({ text: note.note, field: 'Note' },);
		}


		//
		// Weblinks
		//
		for (const webLink of company.webLinks) {
			texts.push({ text: webLink.url, field: 'Web Link' },);
		}


		//
		// Match all the literals
		//
		const match = checkTexts(company, texts, re);


		//
		// Five digit zip code
		//
		if (zip) {
			if (re.full.test(zip.code)) {
				match.fieldMatches.push({ field: 'Zip Code', level: SearchMatchLevel.Low });
				match.level = Math.max(match.level, SearchMatchLevel.Low);
			}
		}


		//
		// Phone
		//
		if (company.phone) {
			const digits = ds.util.number.extractDigits(company.phone);

			//
			// US numbers are 10 with area code. International might be smaller? 8 seems like a good number?
			//
			if (digits.length > 8) {
				const phoneDigits = digits.length == 11 && digits.startsWith('1') ? digits.substring(1) : digits;


				if (termPhoneDigits == phoneDigits) {
					match.fieldMatches.push({ field: 'Phone', level: SearchMatchLevel.Exact });
					match.level = SearchMatchLevel.Exact;
				}
				else {
					if (termPhoneDigits.length >= 4 && phoneDigits.endsWith(termPhoneDigits)) {
						match.fieldMatches.push({ field: 'Phone', level: SearchMatchLevel.Low });
						match.level = Math.max(match.level, SearchMatchLevel.Low);
					}
				}
			}
		}

		if (match.level > SearchMatchLevel.None) matches.push(match);

	}

	return matches;
}