import { NationalDashboardPageService } from '@ADMIN-area';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridSetup, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { AppAreaIdentifier } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface PitchContestRow {
	picId: number,
	siteCode: string,
	siteId: number,
	updatedUTC: number,
}

@Component({
	selector: 'shared-pitch-contests-view-part',
	templateUrl: './pics-view.part.html',
})
export class SharedPitchContestsViewPart extends DestroyablePart implements OnInit {

	@Input() identifier: AppAreaIdentifier<number>;
	@ViewChild(GridPart) meGrid: GridPart<PitchContestRow>;

	public gridSetup = this.setupGrid();
	public rows$: Observable<PitchContestRow[]> = of([]);


	constructor(
		private util: UtilityService,
		public ds: DataService,
		public pageService: NationalDashboardPageService
	) {
		super();
	}


	ngOnInit(): void {

		if (!this.identifier) throw new Error(`The identifer attribute was not set for <shared-pitch-contests-view-part>`);

		super.initDestroyable();

		this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(data => this.buildRows(this.ds)));
	}


	async buildRows(ds: DataService): Promise<PitchContestRow[]> {

		const pitchContests = await ds.admin.pitchContest.getAllPackagesAsArray();

		if (!pitchContests) return [];

		const rows: PitchContestRow[] = pitchContests.map(p => ({
			picId: p.picId,
			siteCode: p.siteProgram.site.code,
			siteId: p.siteProgram.siteId,
			updatedUTC: p.updatedUTC,
		}));
		return rows;
	}



	private setupGrid(): GridSetup<PitchContestRow> {

		return {
			experience: 'PIC',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Pitch Contest",
			rowPluralName: "Pitch Contests",
			rowKey: "picId",
			stateKey: "national-all-pitch-contests",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			initialState: {
				sort: [{ field: 'PIC_applStartUTC', dir: 'desc' }],
			},
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: PitchContestRow[] }) {

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}
	}

}