import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { Region } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { UrlService } from '@me-services/ui/url';
import { getIconContext, Icon } from '@me-shared-parts/UI-common';
import { getIconBarSelections, IconBarIcon } from '@me-shared-parts/UI-common/icon-bar';
import { IconBarPart, IconBarSelections } from '@me-shared-parts/UI-common/icon-bar/SHR-CMN_icon-bar.part';
import { DataService } from '../../../data.service';
import { ExplorerBox } from '../../explorer-box/explorer-box';
import { LinkOrExplorable } from '../../link-or-explorerable';
import { getBoxesForSites } from './boxes/get-boxes-for-sites';
import { getButtons } from './get-buttons';
import { views, VIEW_DETAILS, VIEW_SITES } from './views';

const STORAGE_KEY = 'RegionExplorerIconSelection';

@Component({
	selector: 'region-explorer',
	templateUrl: './region-explorer.part.html',
	styleUrls: ['./region-explorer.part.scss'],
})
export class RegionExplorerPart implements OnChanges {

	@Input() public region: Region;
	@ViewChild('iconBar') iconBar: IconBarPart;

	public views = views;
	public icons = {
		region: getIconContext(Icon.concept_region)
	};


	public selectedViews: IconBarSelections = undefined;
	public buttons: LinkOrExplorable[] = [];

	public siteBoxes: ExplorerBox[] = [];


	constructor(
		public util: UtilityService,
		private ds: DataService,
		private urlService: UrlService
	) { }


	async ngOnChanges() {
		if (!this.region) throw new Error(`Missing required attribute: region`);
		this.icons.region.text = this.region.name;
		await this.createContent();
		this.views = this.updateIconBar();
	}

	/**
	 * CreateContent is called once when a new accelerator is passed in.  All content is created but
	 * may be shown or hidden using css instead of rebuilding everything again.
	 */
	private async createContent() {

		this.buttons = getButtons(this.region);
		this.siteBoxes = await getBoxesForSites(this.ds, this.region);

	}

	private updateIconBar(): IconBarIcon[] {

		//
		// Get selections from last time this explorer was accessed. If there
		// isn't on,then build the selections from the initial views in views.ts.
		//
		const selectedViewsStr = sessionStorage.getItem(STORAGE_KEY);

		if (selectedViewsStr) {
			this.selectedViews = JSON.parse(selectedViewsStr);
		}
		else {
			this.selectedViews = getIconBarSelections(views);
			sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.selectedViews));
		}

		//
		// Create a new array of icon views using the initial set but overriding
		// whether disabled or not and the tooltip text based on the created content.
		//
		return views.map(view => {
			if (view.key == VIEW_DETAILS) {
				view.disabled = false; // always has a value
				view.selected = this.selectedViews.map[VIEW_DETAILS];
				view.tooltip = `Site Details`;
			}
			if (view.key == VIEW_SITES) {
				const count = this.siteBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_SITES];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Site', 'Sites');
			}

			return view;
		});


	}


	public async setViews(selectedViews: IconBarSelections) {
		if (!selectedViews) return;
		this.selectedViews = selectedViews;
		sessionStorage.setItem(STORAGE_KEY, JSON.stringify(selectedViews));

		for (const view of this.views) {
			view.selected = !!selectedViews.map[view.key];
		}
	}


	public getDisplay(key: string) {
		const view = this.views.find(view => view.key == key);
		if (view == undefined) return 'none';
		return !!view.disabled || !view.selected ? 'none' : 'block';
	}


	public tabClose(header: string) {
		if (header.endsWith('Details')) this.unselect(VIEW_DETAILS);
		if (header.endsWith('Site') || header.endsWith('Sites')) this.unselect(VIEW_SITES);
	}


	private unselect(key: string) {

		this.iconBar.unselect(key);

		const view = this.views.find(view => view.key == key);
		if (view) view.selected = false;
	}

}