import { Injectable } from '@angular/core';
import { ArrayUtilities } from './arrays';
import { ClipboardUtilities } from './clipboard';
import { DateUtilities } from './date';
import { EventUtilities } from './event';
import { utilLogging } from './logging/utility-logging';
import { NPSUtilities } from './nps';
import { NumberUtilities } from './number';
import { ObjectUtilities } from './object';
import { PhoneUtilities } from './phone';
import { PromiseQueue } from './promise-queue';
import { RatingsUtilities } from './ratings';
import { RecordUtilities } from './record';
import { RegexUtilities } from './regex';
import { RouterUtilities } from './routing';
import { ScoringUtilities } from './scoring';
import { TextUtilities } from './text';
import { MultipleValuesUtilities, SingularValueUtilities } from './value';
import { ZipUtilities } from './zip';


@Injectable({ providedIn: 'root' })
export class UtilityService {

	private promiseQueue = new PromiseQueue();

	/**
	 * Maintains a queue of promise based functions which are run one at a time. Each
	 * function that will run after each prior function has completed and before any
	 * added afterward. Functions are essentially run single file.
	 */
	public readonly queueRunner = this.promiseQueue.enqueue.bind(this.promiseQueue);


	debounce(fn, wait) {
		let timeout;
		return function (...args: any[]) {
			const ctx = <unknown>this;
			clearTimeout(timeout);
			timeout = setTimeout(function () {
				fn.apply(ctx, args);
			}, wait || 100);
		};
	}

	/**
	 * Wraps setTimeout() in a Promise. Since an asynchronouse timer is used,
	 * await timeout(...) will flush the JavaScript event loop first. A value of
	 * zero (default) can be used to resolve right away but still flush existing events.
	 * 
	 * Use this to keep the browser UI responsive when performing an intensive synchronous activity
	 * such as manipulating data in a large loop. See https://www.youtube.com/watch?v=8aGhZQkoFbQ
	 * @param ms The number of milliseconds to wait for
	 * @returns Returns a promise that resolves after the provided number of milliseconds
	 */
	async setTimeout(ms = 0): Promise<void> {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	/** end routher methods? */

	public readonly array = ArrayUtilities;
	public readonly clipboard = ClipboardUtilities;
	public readonly date = DateUtilities;
	public readonly event = EventUtilities;
	public readonly log = utilLogging;
	public readonly nps = NPSUtilities;
	public readonly number = NumberUtilities;
	public readonly object = ObjectUtilities;
	public readonly phone = PhoneUtilities;
	public readonly ratings = RatingsUtilities;
	public readonly record = RecordUtilities;
	public readonly regExp = RegexUtilities;
	public readonly router = RouterUtilities;
	public readonly scoring = ScoringUtilities;
	public readonly text = TextUtilities;
	public readonly value = SingularValueUtilities;
	public readonly values = MultipleValuesUtilities;
	public readonly zip = ZipUtilities;
}