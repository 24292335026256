import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { LayoutService } from '@me-services/ui/layout';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'me-toolbar',
	templateUrl: './SHR-CMN_toolbar.part.html',
	styleUrls: ['SHR-CMN_toolbar.part.scss'],
})
export class ToolbarPart extends DestroyablePart implements OnChanges, OnInit {

	@ViewChild("toolbar") toolbarDiv: ElementRef;
	@Input() icon: 'Acc' | 'National' | 'Program' | 'Pic' | 'Region' | 'Site' | 'Staff';

	isScrollable = false;
	accToolbar = false;
	nationalToolbar = false;
	picToolbar = false;
	programToolbar = false;
	regionToolbar = false;
	siteToolbar = false;
	staffToolbar = false;

	constructor(private layoutService: LayoutService) {
		super();
	}

	ngOnChanges() {
		this.accToolbar = this.icon == 'Acc';
		this.nationalToolbar = this.icon == 'National';
		this.picToolbar = this.icon == 'Pic';
		this.programToolbar = this.icon == 'Program';
		this.regionToolbar = this.icon == 'Region';
		this.siteToolbar = this.icon == 'Site';
		this.staffToolbar = this.icon == 'Staff';
	}

	ngOnInit() {
		super.initDestroyable();

		this.layoutService.dimensions$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(this.checkIfScrollable.bind(this));

		this.checkIfScrollable();
	}


	checkIfScrollable() {
		if (this.toolbarDiv) {
			this.isScrollable = this.layoutService.isScrollable(this.toolbarDiv.nativeElement).horizontallyScrollable;
		}
	}
}