<div class='explorer-part accelerator-explorer-part'>

	<div class="icon-bar">
		<me-icon-bar #iconBar [icons]='views' [orientation]="'Vertical'" (selectionsChange)='setViews($event)'>
		</me-icon-bar>
	</div>

	<div class="content">
		<ng-container *ngIf='selectedViews'>
			<div class="explorer-header">
				<me-icon [icon]="icons.acc"></me-icon>
			</div>
			<h4 class="explorer-sub-header">Accelerator</h4>

			<div class="explorer-small-header">Stage:<span
					class="explorer-highlight-section">{{accelerator.stage.name}}</span></div>

			<section>
				<explorer-buttons [buttons]='buttons'></explorer-buttons>
			</section>


			<explorer-section header="Accelerator Details" (tabClose)="tabClose($event)"
				[style.display]="getDisplay('details')">
				<accelerator-explorer-details [accelerator]="accelerator"></accelerator-explorer-details>
			</explorer-section>

			<explorer-section
				[header]="util.text.singularOrPluralWithCount(cohortBoxes.length, 'Cohort Team', 'Cohort Teams')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('cohort')">
				<explorer-boxes>
					<ng-container *ngFor="let box of cohortBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

			<explorer-section [header]="util.text.singularOrPluralWithCount(mentorBoxes.length, 'Mentor', 'Mentors')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('mentors')">
				<explorer-boxes>
					<ng-container *ngFor="let box of mentorBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

			<explorer-section
				[header]="util.text.singularOrPluralWithCount(applicationBoxes.length, 'Application', 'Applications')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('applications')">
				<explorer-boxes>
					<ng-container *ngFor="let box of applicationBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

			<explorer-section [header]="util.text.singularOrPluralWithCount(eventBoxes.length, 'Event', 'Events')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('events')">
				<explorer-boxes>
					<ng-container *ngFor="let box of eventBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

			<explorer-section [header]="util.text.singularOrPluralWithCount(awardBoxes.length, 'Award', 'Awards')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('awards')">
				<explorer-boxes>
					<ng-container *ngFor="let box of awardBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

		</ng-container>
	</div>
</div>