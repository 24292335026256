import { AwardNameId } from '@me-interfaces';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';
import { DdAwardNameRow } from './dd-award-name-row';

/**
 * @deprecated Use the DataService instead
 */
export class DdAwardName extends DdObject<DdAwardNameRow> implements DdAwardNameRow {

	constructor(dd: OldDomainDataService, data: DdAwardNameRow) {
		super(data);
	}

	public get awardNameId(): AwardNameId {
		return this._data.awardNameId;
	}

	public get name(): string {
		return this._data.name;
	}
	
	public get order(): number {
		return this._data.order;
	}
}