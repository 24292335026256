export function formatPhone(number: string, ext: string = undefined) {

	if (!number) return '';

	const digits = (number || '').match(/\d/g).join('');
	if (digits.length == 0) return '';

	let display = '';

	if (digits.length == 11 && digits[0] == '1') {
		display = `(${digits.substring(1, 4)}) ${digits.substring(4, 7)}-${digits.substring(7, 11)}`;
	}
	else {
		display = '+' + digits;
	}

	ext = (ext || '').trim();
	if (ext.length) display += ` x${ext}`;

	return display;
}