import { Component, Input, OnInit } from '@angular/core';
import { DbsPerson, DbcUser } from '@me-interfaces';
import { OldEntityService, PersonWrapper } from '@me-services/old-services-and-wrappers/entity';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { MainSelector } from '@me-shared-parts/UI-common';

@Component({
	selector: 'person-profile-editor',
	templateUrl: './SHR-ED_person-profile-editor.part.html',
	styleUrls: ['SHR-ED_person-profile-editor.part.scss'],
})
export class PersonProfileEditorPart implements OnInit {

	@Input() person: DbsPerson;
	@Input() initialSelectorKey?: string;

	personWrapper: PersonWrapper;
	user: DbcUser;

	profileSelectors: MainSelector[] = [];
	editorsList = ['Basic Info', 'Emails', 'Do Not Contact', 'Address', 'Languages', 'Web Links', 'Permissions', 'Demographics'];
	key: string;

	constructor(
		public entityService: OldEntityService,
		public userService: OldUserService,
	) { }

	async ngOnInit() {
		this.user = await this.userService.getUser();

		this.buildSelectors();
		if (this.initialSelectorKey) this.setSelectedKey(this.initialSelectorKey);

		this.personWrapper = this.entityService.wrapPerson(this.person);
	}

	buildSelectors() {
		this.profileSelectors = [];
		for (const editor of this.editorsList) {
			this.profileSelectors.push({
				key: editor,
				label: editor,
				locked: editor == 'Demographics' && !this.user?.canEditDemographics ? true : false,
			});
		}
	}

	setSelectedKey(key: string) {
		this.key = key;
	}

	selectionChange(key: string) {
		this.initialSelectorKey = key;
	}

	async personChanged(person: DbsPerson) {
		this.person = person;
	}
}
