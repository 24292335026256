<h5 mat-dialog-title> {{selection.text}}
	<small>
		{{role}}
	</small>
</h5>
<mat-dialog-content>
	<mat-form-field>
		<mat-select placeholder='Select a Day' [value]='selectedValue' (selectionChange)='setSelectedValue($event)'>
			<ng-container *ngFor='let day of dayNames; let d = index;'>
				<ng-container *ngFor='let slot of slotNames; let s = index;'>
					<mat-option [value]='d * maxValue + s' [style.color]='getSlotColor(d,s)'>{{getSlotNames(d,s)}}
					</mat-option>
				</ng-container>
			</ng-container>
		</mat-select>
	</mat-form-field>
</mat-dialog-content>
<mat-dialog-content>
	<ng-container *ngIf='meetings[d][s].others.length > 0'>
		<table style='width:100%' class="pro-table pro-table-without-border">
			<tr>
				<th>Name</th>
				<th>Given</th>
				<th>Received</th>
			</tr>
			<tr *ngFor="let row of meetings[d][s].others">
				<td>
					<div>{{row.name}}</div>
					<small *ngIf='row.companyName'> {{row.companyName}}</small>
				</td>
				<td>
					<rating-four *ngIf='row.given' title='Ratings Given' [rating]='row.given'></rating-four>
				</td>
				<td>
					<rating-four *ngIf='row.received' title='Ratings Received' [rating]='row.received'>
					</rating-four>
				</td>
			</tr>
		</table>
	</ng-container>
</mat-dialog-content>