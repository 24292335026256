<me-toolbar icon='Region'>
	<div class='region-toolbar-part'>

		<app-area-reload-button [areaService]="regionAreaService"></app-area-reload-button>

		<button class='btn' (click)='inspectRegion(region.regionId)'>
			<me-icon [icon]='icons.region'></me-icon>
		</button>

		<button class='btn' (click)='inspectPerson(region.directorId)'>
			<me-icon [icon]='icons.dir'></me-icon>
		</button>

	</div>
</me-toolbar>