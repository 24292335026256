import { FieldOptionsList } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { getFieldOptions } from './get-field-options';
import { SelectableOption } from './selectable-option';

export function renderSelect(value: number | string, list: FieldOptionsList, ds: DataService, otherValue?: string): string {

	const options: SelectableOption[] = getFieldOptions(list, ds);

	const option = options.find(o => o.value == value) || undefined;

	if (!option) return '';
	else return option.showOtherValueField && otherValue ? otherValue : option.text;
}