import { Component, Input, OnChanges } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { Feedback, ReviewerFeedback } from '@me-interfaces';
import { DownloadColumn } from '@me-interfaces/ui';
import { FuncService } from '@me-services/core/func';
import { MenuService } from '@me-services/ui/menu';

interface ViewModel {
	reviewTypes: string[],
	reviewType: 'Readers' | 'Interviewers' | 'Judges',
	filteredModel: Feedback[],
	companySelected: Feedback,
	countOfApplicationsUnpolished: number,
	reviewers: ReviewerFeedback[],
	reviewerFeedback: ReviewerFeedback,
	polishedFeedback: string,
}

@Component({
	selector: 'feedback-manager-v1',
	templateUrl: './SHR-CMN_feedback-manager-v1.part.html',
	styleUrls: ['SHR-CMN_feedback-manager-v1.part.scss'],
})
export class FeedbackManagerV1Part implements OnChanges {

	@Input() model: Feedback[];
	@Input() type: 'acc' | 'pic';
	@Input() name: string; // acc or pic name. 
	@Input() siteCode: string;
	@Input() readonly = false;

	vm: ViewModel = {
		reviewTypes: [],
		reviewType: undefined,
		filteredModel: [],
		companySelected: undefined,
		reviewers: [],
		countOfApplicationsUnpolished: 0,
		reviewerFeedback: undefined,
		polishedFeedback: undefined,
	};

	saving = false;

	public downloadFileName = 'not-loaded-yet';
	public downloadColumns: DownloadColumn[] = [];


	constructor(
		private func: FuncService,
		private menu: MenuService,
	) {
	}

	ngOnChanges() {
		this.loadReviewTypes(this.model);
	}

	loadReviewTypes(model: Feedback[]) {
		this.vm.reviewTypes = [];
		for (const feedback of model) {
			if (!this.vm.reviewTypes.includes('Readers') && feedback.readings.feedback.length) {
				this.vm.reviewTypes.push('Readers');
			}
			if (!this.vm.reviewTypes.includes('Interviewers') && feedback.interviews && feedback.interviews.feedback.length) {
				this.vm.reviewTypes.push('Interviewers');
			}
			if (!this.vm.reviewTypes.includes('Judges') && feedback.judgements.feedback.length) {
				this.vm.reviewTypes.push('Judges');
			}

			if (this.vm.reviewTypes.includes('Readers') && this.vm.reviewTypes.includes('Interviewers') && this.vm.reviewTypes.includes('Judges')) break;
		}
	}

	selectReviewType(e: 'Readers' | 'Interviewers' | 'Judges') {
		this.resetValues();
		this.vm.reviewType = e;
		//
		// updates the view model based on the selection of reviewType 'Readers' | 'Interviewers' | 'Judges'
		//
		if (this.vm.reviewType) this.updateView(this.vm.reviewType, this.model);
	}

	updateView(reviewType: 'Readers' | 'Interviewers' | 'Judges', model: Feedback[]) {
		this.vm.filteredModel = model.filter(feedback => {
			if (reviewType == 'Readers' && feedback.readings.feedback.length) {
				return feedback;
			}
			else if (reviewType == 'Interviewers' && feedback.interviews && feedback.interviews.feedback.length) {
				return feedback;
			}
			else if (reviewType == 'Judges' && feedback.judgements.feedback.length) {
				return feedback;
			}
		}).sort((a, b) => a.company > b.company ? 1 : -1);
		this.setCounts();
		this.configureDownload();
	}

	resetValues() {
		this.vm.filteredModel = [];
		this.vm.reviewers = [];
		this.vm.countOfApplicationsUnpolished = 0;
		this.vm.reviewerFeedback = undefined;
		this.vm.polishedFeedback = undefined;
		this.vm.companySelected = undefined;
	}


	selectionChangeSetReviewers(e: MatSelectionListChange) {
		e.source.deselectAll();
		e.source.selectedOptions.select(e.options[0]);
		this.vm.companySelected = e.options[0].value;
		if (this.vm.reviewType == 'Readers') this.vm.reviewers = this.vm.companySelected.readings.feedback;
		if (this.vm.reviewType == 'Interviewers') this.vm.reviewers = this.vm.companySelected.interviews.feedback;
		if (this.vm.reviewType == 'Judges') this.vm.reviewers = this.vm.companySelected.judgements.feedback;
		this.vm.reviewers.sort((a, b) => a.reviewer.fullName > b.reviewer.fullName ? 1 : -1);
		this.vm.reviewerFeedback = undefined;
	}

	setCounts() {
		this.vm.countOfApplicationsUnpolished = 0;
		this.vm.filteredModel = this.vm.filteredModel.map(feedback => {
			feedback['countOfUnpolishedReviewerFeedback'] = 0;
			if (this.vm.reviewType == 'Readers' && feedback.readings.feedback.length) {
				feedback['status'] = feedback.readings.status;

				for (const reviewerFeedback of feedback.readings.feedback) {
					if (!reviewerFeedback.polishedFeedbackSkipped && !reviewerFeedback.polishedFeedback) {
						feedback['countOfUnpolishedReviewerFeedback']++;
					}
				}
			}
			else if (this.vm.reviewType == 'Interviewers' && feedback.interviews.feedback.length) {
				feedback['status'] = feedback.interviews.status;

				for (const reviewerFeedback of feedback.interviews.feedback) {
					if (!reviewerFeedback.polishedFeedbackSkipped && !reviewerFeedback.polishedFeedback) {
						feedback['countOfUnpolishedReviewerFeedback']++;
					}
				}
			}
			else if (this.vm.reviewType == 'Judges' && feedback.judgements.feedback.length) {
				feedback['status'] = feedback.judgements.status;

				for (const reviewerFeedback of feedback.judgements.feedback) {
					if (!reviewerFeedback.polishedFeedbackSkipped && !reviewerFeedback.polishedFeedback) {
						feedback['countOfUnpolishedReviewerFeedback']++;
					}
				}
			}
			this.vm.countOfApplicationsUnpolished = feedback['countOfUnpolishedReviewerFeedback'] > 0 ? this.vm.countOfApplicationsUnpolished + 1 : this.vm.countOfApplicationsUnpolished;
			feedback['link'] = 'https://eforall.app/access/my/feedback';
			return feedback;
		});
	}

	selectionChangeSetReviewerFeedback(e: MatSelectionListChange) {
		e.source.deselectAll();
		e.source.selectedOptions.select(e.options[0]);
		this.vm.reviewerFeedback = e.options[0].value;
		if (this.vm.reviewerFeedback.feedbackForEforAll) {
			this.vm.reviewerFeedback['originalAndEforallFeedback'] = `${this.vm.reviewerFeedback.originalFeedback} \n\n\n---- Below feedback is for EforAll eyes only ----\n\n\n ${this.vm.reviewerFeedback.feedbackForEforAll}`;
		}
		else {
			this.vm.reviewerFeedback['originalAndEforallFeedback'] = this.vm.reviewerFeedback.originalFeedback;
		}
		this.vm.polishedFeedback = this.vm.reviewerFeedback.polishedFeedback;
	}

	setPolishedFeedback(e) {
		if (this.vm.reviewerFeedback) this.vm.polishedFeedback = (e.target.value || '').trim();
	}

	async setPolishedFeedbackSkipped(e) {
		this.vm.reviewerFeedback.polishedFeedbackSkipped = e.checked;
		let tableName = '';
		if (this.vm.reviewType == 'Readers') tableName = `${this.type}Reading`;
		if (this.vm.reviewType == 'Interviewers') tableName = `${this.type}Interview`;
		if (this.vm.reviewType == 'Judges') tableName = `${this.type}Judgement`;
		await this.func.admin.site.feedback.setSkippedPolished({
			siteCode: this.siteCode, table: `${tableName}`, applicationId: this.vm.companySelected.applicantId, teamId: this.vm.companySelected.teamId, reviewerId: this.vm.reviewerFeedback.reviewerId, polishedFeedbackSkipped: this.vm.reviewerFeedback.polishedFeedbackSkipped
		});
		this.setCounts();
	}

	async save() {
		this.saving = true;
		let tableName = '';
		if (this.vm.reviewType == 'Readers') tableName = `${this.type}Reading`;
		if (this.vm.reviewType == 'Interviewers') tableName = `${this.type}Interview`;
		if (this.vm.reviewType == 'Judges') tableName = `${this.type}Judgement`;
		await this.func.admin.site.feedback.setPolished({
			siteCode: this.siteCode, table: `${tableName}`, applicationId: this.vm.companySelected.applicantId, teamId: this.vm.companySelected.teamId, reviewerId: this.vm.reviewerFeedback.reviewerId, polishedFeedback: this.vm.polishedFeedback
		});
		this.vm.reviewerFeedback.polishedFeedback = this.vm.polishedFeedback;
		this.setCounts();
		this.saving = false;
	}


	revert() {
		this.vm.polishedFeedback = this.vm.reviewerFeedback.polishedFeedback;
	}

	configureDownload() {
		// Acc readings feedback for pgm name
		this.downloadFileName = `${this.type} ${this.vm.reviewType} feedback for ${this.name}`.toLowerCase();
		this.downloadColumns = [
			{ header: 'first name', property: 'firstNames', type: "string" },
			{ header: 'email', property: 'emails', type: "string" },
			{ header: 'link', property: 'link', type: "string" },
			{ header: 'company', property: 'company', type: "string" },
			{ header: 'status', property: 'status', type: "string" },
		];
	}
}
