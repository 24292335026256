/**
 * The timeZoneIds of the four timezones that are a known number of hours apart from each other.
 * e.g. Pacific is exactly 3 hours after Eastern
 * 
 * The zones are in the array in order from Eastern to Pacific, so the indexes can be compared to
 * calculate the hours between any two time zones.
 */
export const DAYLIGHT_SAVINGS_TIMEZONES = [ // The standard four time zones that shift with daylight savings
	1, // America/New_York (Eastern)
	5, // America/Chicago (Central)
	7, // America/Denver (Mountain)
	3, // America/Los_Angeles (Pacific)
];