import { AccStageId } from '@me-interfaces';
import { DdAccStageRow } from './dd-acc-stage-row';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';

/**
 * @deprecated Use AccStage from the DataService instead
 */
export class DdAccStage extends DdObject<DdAccStageRow> implements DdAccStageRow {

	constructor(dd: OldDomainDataService, data: DdAccStageRow) {
		super(data);
	}

	public get accStageId(): AccStageId {
		return this._data.accStageId;
	}

	public get name(): string {
		return this._data.name;
	}
}