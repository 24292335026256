import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { FieldStatus } from '@me-interfaces';
import { DialogAction, DialogContext, DialogService } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TextAreaDialogFieldPart } from '../SHR-UI_textarea-dialog-field.part';

@Component({
	selector: 'textarea-dialog-field-dialog',
	templateUrl: './SHR-UI_textarea-dialog-field.dialog.html',
	styleUrls: ['./SHR-UI_textarea-dialog-field.dialog.scss']
})
export class TextAreaDialogFieldDialog extends DestroyablePart implements OnInit, AfterViewInit {

	@ViewChild('textArea') textArea: ElementRef;

	saveAction: DialogAction = {
		id: 'save',
		enabled: false,
		visible: true,
		label: ':Save',
		linkType: "callback",
		willCloseDialog: false,
		callback: this.doSave.bind(this),
	};

	revertAction: DialogAction = {
		id: 'revert',
		enabled: false,
		visible: false,
		label: ':Cancel',
		linkType: "callback",
		willCloseDialog: false,
		callback: this.doRevert.bind(this),
	};

	closeAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: false,
		label: ':Close',
		linkType: "no-action",
		willCloseDialog: true,
	};

	nextAction: DialogAction = {
		id: 'next',
		enabled: true,
		visible: false,
		label: ':Next',
		linkType: "actionClick-event",
		willCloseDialog: true,
	};

	length = 0;
	lengthLeft = 0;

	actions: DialogAction[] = [this.saveAction, this.revertAction, this.closeAction, this.nextAction];
	basePart: TextAreaDialogFieldPart;
	headerText: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ fieldPart: TextAreaDialogFieldPart, fieldNumber: number, fieldCount: number }>,
		public dialogRef: MatDialogRef<TextAreaDialogFieldDialog>,
		private dialogService: DialogService,
		labelsService: LabelsService,
	) {
		super();

		this.basePart = dialog.data.fieldPart;

		this.setHeaderText(labelsService, dialog.data.fieldNumber, dialog.data.fieldCount);

		this.syncButtonsToStatus(dialog.data.fieldPart.statusChange);
	}

	ngOnInit() {
		super.initDestroyable();
	}


	async setHeaderText(labelsService: LabelsService, fieldNumber: number, fieldCount: number) {

		const getLabel = await labelsService.getLabel();
		this.headerText = getLabel({ key: 'Paragraph Question' });
		if (fieldCount > 1) {
			this.headerText = `${this.headerText} (${fieldNumber} ${getLabel({ key: 'of' })} ${fieldCount})`;
		}
	}


	syncButtonsToStatus(statusChange: BehaviorSubject<FieldStatus>) {

		statusChange
			.pipe(takeUntil(this.destroyed$))
			.subscribe(status => {
				this.saveAction.enabled = this.canSave();
				this.revertAction.enabled = status == 'pending';
				this.revertAction.visible = status == 'pending';
				this.closeAction.visible = status == undefined || status == 'saved';
				this.nextAction.visible = (status == undefined || status == 'saved') && !!this.basePart.nextEditText;
			});
	}


	ngAfterViewInit() {
		this.textArea.nativeElement.focus();
		this.checkText();
	}


	textChange(e) {
		this.basePart.setPendingValue(e.target.value);
		this.checkText();
	}


	checkText() {
		let value: string = this.textArea.nativeElement.value || '';
		value = value.trim();
		this.length = value.length;
		this.lengthLeft = this.basePart.field.maxLength - this.length;

		setTimeout(() => {
			this.saveAction.enabled = this.canSave();
		});
	}


	canSave(): boolean {
		return this.basePart.status == 'pending' && !this.basePart.errorText;
	}


	async doSave() {
		await this.basePart.attemptToSave();
		this.textArea.nativeElement.value = this.basePart.pendingValue;
	}


	async doRevert() {
		this.basePart.revert();
		this.textArea.nativeElement.value = this.basePart.pendingValue;
	}


	actionClick(action: DialogAction) {

		this.dialogService.activity.emit({
			instanceIdentifier: this.dialog.instanceIdentifier,
			dialogClass: TextAreaDialogFieldDialog.name,
			activity: 'Action',
			action,
			value: this.basePart,
		});
	}

}