import { CellClickEvent, GroupableSettings, SelectableSettings, SelectionEvent } from "@progress/kendo-angular-grid";
import { ReplaySubject, Subject } from "rxjs";
import { GridSetup } from "../interfaces";
import { BaseGridController } from "./base-grid-controller";


export interface CtrlAltShift { alt: boolean, ctrl: boolean, shift: boolean }


export class SelectableGridController<ROW> extends BaseGridController<ROW> {

	/**
	* Functionality for controlling a grid that supports adding and patching filters, and row selection
	* @param gridSetup A configuration object that defines grid properties
	*/
	constructor(gridSetup: GridSetup<ROW>) {

		super(gridSetup);

		this.selectableSettings = {
			checkboxOnly: false,
			drag: true,
			mode: gridSetup.multiselect ? 'multiple' : 'single',
		}
	}

	public selectedRowIds: string[] = [];
	private idOfSingleRowSelected: string | number = undefined;  // used for toggling a single selected row
	public groupable: GroupableSettings = undefined;

	public readonly selectedRows$ = new ReplaySubject<ROW[]>(1);
	public readonly doubleClick$ = new Subject<{ keys: CtrlAltShift, row: ROW }>();
	public readonly contextMenu$ = new Subject<{ rowData: ROW, left: number, top: number }>();

	public selectableSettings: SelectableSettings;


	public clearSelection() {
		this.idOfSingleRowSelected = undefined;
		this.selectedRowIds = [];
		this.selectedRows$.next([]);
	}


	public selectionChange(event: SelectionEvent): void {
		setTimeout(() => {
			// The selectionChange event only fires with a new user-triggered selection.
			// If only one row is selected and the user clicks it again, the grid does
			// nothing and selectionChange is not fired. To make the grid toggle a single
			// selected row, the id of the single row's file is remembered and cleared at
			// the end of the cellClick event.  That event will know that if there is only
			// one selected row but no idOfSingleFileSelected then it is a follow-on click
			// and the selection should be removed.
			this.idOfSingleRowSelected = this.selectedRowIds.length !== 1
				? undefined
				: this.selectedRowIds[0];

			this.handleSelection(this.selectedRowIds);
		});
	}


	private handleSelection(selectedRowIds: string[]) {
		const rows = super.getRowsById(selectedRowIds);
		this.selectedRowIds = rows.map(row => row[this.idField]);
		this.selectedRows$.next(rows);
	}

	private _lastClickTime = 0;


	protected override applyState() {
		
		super.applyState();

		//
		// Remove row selections of rows that are no longer there
		//
		const rows = super.getRowsById(this.selectedRowIds);

		if (rows.length !== this.selectedRowIds.length) {
			this.selectedRowIds = rows.map(row => row[this.idField]);
			this.selectedRows$.next(rows);
		}
	}


	public cellClick(event: CellClickEvent) {

		const oe = event.originalEvent ?? {};
		const keys: CtrlAltShift = {
			alt: !!oe.altKey,
			ctrl: !!oe.ctrlKey,
			shift: !!oe.shiftKey,
		};

		if (event.type == 'contextmenu') {

			if (keys.ctrl) return;

			this.showContextMenu(event);

			event.originalEvent.preventDefault();
			return false;
		}

		const clickTime = Date.now();

		if (clickTime - this._lastClickTime < 500) {
			this._lastClickTime = 0;

			setTimeout(() => {
				const row = super.getRowById(event.dataItem[this.idField]);
				this.handleSelection(this.selectedRowIds = [event.dataItem[this.idField]]);
				this.doubleClick$.next({ keys, row });

				this.idOfSingleRowSelected = undefined;
			});
		}
		else {
			this._lastClickTime = clickTime;

			setTimeout(() => {
				// If only one row is selected and selectionChange was not fired before this event,
				// setting idOfSingleFileSelected, then we know it is a follow-on click and toggle
				// the selection of the single row off.
				const removeSelection = this.selectedRowIds.length == 1 && !this.idOfSingleRowSelected;
				if (removeSelection) this.handleSelection(this.selectedRowIds = []);

				this.idOfSingleRowSelected = undefined;
			});
		}
	}


	/**
	 * Display a menu with actions enabled based on the current selection of rows
	 */
	private showContextMenu(event: CellClickEvent) {
		setTimeout(() => {

			//
			// Rerun the action enablers
			//
			this.selectedRows$.next(super.getRowsById(this.selectedRowIds));

			if (this.selectableSettings.mode == 'single' || this.selectedRowIds.length <= 1) {
				const rowData = event.dataItem;
				this.handleSelection([rowData[this.idField]]);
				this.contextMenu$.next({ rowData: undefined, left: event.originalEvent.pageX, top: event.originalEvent.pageY });
			}
			else {
				//
				// If we get here, the mode is 'multiple' AND at least 2 rows are selected.
				// We only show the context menu if they right-clicked on one of the selected rows
				//
				const rowData = event.dataItem;
				if (this.selectedRowIds.includes(rowData[this.idField])) {
					this.contextMenu$.next({ rowData: undefined, left: event.originalEvent.pageX, top: event.originalEvent.pageY });
				}
				else {
					this.contextMenu$.next(undefined);
				}
			}

		});
	}
}