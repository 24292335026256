import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridColumnType, GridSetup, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { StaffOverviewPageService } from '../../staff-overview-page.service';


interface DepartmentRow {
	staffDepartmentId: number,
	name: string,
	headPersonId: number,
	headPersonName: string,
	memberCounts: number,
	updatedUTC: number,
}


@Component({
	selector: 'staff-departments-view-part',
	templateUrl: './departments-view.part.html',
	styleUrls: ['./departments-view.part.scss'],
})
export class StaffDepartmentsViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid(false);

	public rows$: Observable<DepartmentRow[]> = of([]);

	@ViewChild(GridPart) meGrid: GridPart<DepartmentRow>;

	constructor(
		private util: UtilityService,
		public ds: DataService,
		public pageService: StaffOverviewPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(utc => this.buildRows(this.ds)));
	}


	async buildRows(ds: DataService): Promise<DepartmentRow[]> {

		return [];

	}


	private setupGrid(canWrite: boolean): GridSetup<DepartmentRow> {

		return {
			experience: 'none',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Department",
			rowPluralName: "Departments",
			rowKey: "staffDepartmentId",
			stateKey: "staff-departments",
			canAdd: canWrite,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				{ field: "staffDepartmentId", header: "staffDepartmentId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "name", header: "Name", width: 100, type: GridColumnType.text },
				{ field: "headPersonName", header: "Head", width: 100, type: GridColumnType.text },
				{ field: "memberCounts", header: "# Members", width: 100, type: GridColumnType.text },
			],
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: DepartmentRow[] }) {

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}
	}

}