import { Injectable } from '@angular/core';
import { DbcZip } from '@me-interfaces';
import { FuncService } from '../func';

interface ZipCache {
	zips: DbcZip[],
	badZips: number[],
}

const EMPTY_ZIP_CACHE: ZipCache = { zips: [], badZips: [] };
const ZIP_CACHE_KEY = 'zip-cache-v1';
const MAX_CACHE_SIZE = 500; // Maximum count of zip codes to store in web storage


@Injectable({ providedIn: 'root' })
export class ZipService {

	constructor(private func: FuncService) {
	}


	/**
	 * Pass a zip code as a five digit string and the service will return
	 * the matching DbcZip record if there is one, or undefined if the code
	 * is not a valid zip.
	 */
	async getZip(code: string): Promise<DbcZip> {
		const zipId = parseInt(code, 10);
		if (isNaN(zipId)) return undefined;
		return this.getZipById(zipId);
	}


	async getZipById(zipId: number): Promise<DbcZip> {

		//
		// Get the latest cache data from web storage
		//
		const cache: ZipCache = JSON.parse(localStorage.getItem(ZIP_CACHE_KEY) || JSON.stringify(EMPTY_ZIP_CACHE));


		//
		// Already know that this is a bad zip code?
		//
		if (cache.badZips.includes(zipId)) return undefined;


		//
		// Already have this zip in the cache?
		//
		let zip = cache.zips.find(zip => zip.zipId == zipId);
		if (zip) return zip;


		//
		// Go get the zip
		//
		zip = await this.func.zip.get({ zipId });


		//
		// Ensure the zip cache is no larger than the max
		//
		if (cache.zips.length >= MAX_CACHE_SIZE) {
			cache.zips.slice(0, cache.zips.length - MAX_CACHE_SIZE + 1);
		}

		if (cache.badZips.length >= MAX_CACHE_SIZE) {
			cache.badZips.slice(0, cache.badZips.length - MAX_CACHE_SIZE + 1);
		}


		//
		// Add the result to the cache
		//
		if (zip) cache.zips.push(zip);
		else cache.badZips.push(zipId);

		localStorage.setItem(ZIP_CACHE_KEY, JSON.stringify(cache));

		//
		// ...and we're done
		//
		return zip;
	}

}