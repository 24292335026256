/**
 * Calculate a 0..100 score given answer assessments and application ratings.
 * Answers are applied as 10% and the rating as 90%
 * 
 * @param ratings5 The five-level scores used for answers
 * @param ratings4 The four-level scores used for overall assessment
 */
export function calculateRatingsScore(ratings5: [number, number, number, number, number], ratings4?: [number, number, number, number]): number {

	const numRatings5 = ratings5[0] + ratings5[1] + ratings5[2] + ratings5[3] + ratings5[4];
	if (numRatings5 < 1) return null;

	const numRatings4 = ratings4 == undefined ? 0 : (ratings4[0] + ratings4[1] + ratings4[2] + ratings4[3]);

	let score = 0;
	const answersScore = calcScore(ratings5);

	if (numRatings4) score = calcScore(ratings4).score * 0.9 + answersScore.score * 0.1;
	else score = answersScore.score;

	return +score.toFixed(1);
}


function calcScore(r: number[]) {
	const length = r.length;
	if (length < 1) return { score: 0, count: 0 };

	let div = 100;
	if (length > 1) div = 100 / (length - 1);

	const count = r.reduce((p, c) => p + c, 0);
	if (count == 0) return { score: 0, count: 0 };

	return { score: r.reduce((p, c, i) => p + c * i * div, 0) / count };
}


