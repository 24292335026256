export const ObjectUtilities = {
  /**
   * Takes an object and directly modifies it to remove all properties.
   * Use this when there may be references to the original object and
   * you want to remove and then replace the values attached to it.
   */
  clear: (obj: Record<string | number, unknown>) => {
    for (const key in obj) {
      delete obj[key];
    }
  }
};
