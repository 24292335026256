import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DestroyablePart } from '@me-access-parts';
import { DbsWebLink, DB_LENGTH, ImmutableArray } from '@me-interfaces';
import { CompanyProfileWrapper, PersonWrapper } from '@me-services/old-services-and-wrappers/entity';
import { ConstantsService } from '@me-services/core/constants';
import { OldDomainDataService } from '@me-services/core/old-dd';
import { DialogService } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';


@Component({
	selector: 'public-entity-online-editor',
	templateUrl: './SHR-ED_public-online-editor.part.html',
	styleUrls: ['SHR-ED_public-online-editor.part.scss'],
})
export class PublicEntityOnlineEditorPart extends DestroyablePart implements OnInit {

	@Input() personWrapper?: PersonWrapper;
	@Input() companyProfileWrapper?: CompanyProfileWrapper;

	public webLinks: ImmutableArray<DbsWebLink>;
	public editForm: FormGroup;

	dataWrapper: PersonWrapper | CompanyProfileWrapper;
	showAddForm = false;
	editing: { [index: number]: boolean } = {};
	addForm: FormGroup;

	public webLinkTypes: { webLinkTypeId: number, name: string, iconClass: string }[] = [
		...this.dd.webLinkTypes.objects
			.map(type => {
				return {
					name: type.name,
					webLinkTypeId: type.webLinkTypeId,
					iconClass: this.getWebLinkIcon(type.webLinkTypeId),
				}
			})
	];

	public defaultAddForm = { webLinkTypeId: 1, name: 'Website', iconClass: 'logo fas fa-link fa-fw fa-fh' };

	inlineLabels = this.labelsService.trackInlineLabels(this, [
		'URL',
		'Add Web Link',
		'Cancel',
		'Edit',
		'Delete',
		'Revert',
		'Save Changes',
		'Too many characters',
		...this.dd.webLinkTypes.objects.map(t => t.name)
	]);


	constructor(
		public dd: OldDomainDataService,
		public constants: ConstantsService,
		private fb: FormBuilder,
		private dialogService: DialogService,
		public labelsService: LabelsService,
	) {
		super();
	}


	ngOnInit() {
		super.initDestroyable();

		if ((!this.personWrapper && !this.companyProfileWrapper) || (this.personWrapper && this.companyProfileWrapper)) {
			throw new Error('Data Unavailable');
		}

		if (this.personWrapper) this.dataWrapper = this.personWrapper;
		if (this.companyProfileWrapper) this.dataWrapper = this.companyProfileWrapper;

		this.loadData(this.dataWrapper._entityExtracts.webLinks);


		this.createAddForm();
	}


	loadData(webLinks: ImmutableArray<DbsWebLink>) {

		this.webLinks = webLinks;
		for (const webLink of this.webLinks) {
			webLink['iconClass'] = this.getWebLinkIcon(webLink.webLinkTypeId);
			this.editing[webLink.webLinkId] = false;
		}
	}


	createAddForm() {
		this.addForm = this.fb.group({
			webLinkTypeId: [undefined, [Validators.required]],
			url: ['', [Validators.required, Validators.pattern(this.constants.WEBSITE_REGEX), Validators.maxLength(DB_LENGTH.webLink.url[1])]],
		});
		this.initializeForm();
	}


	initializeForm() {
		this.addForm.controls.webLinkTypeId.setValue(this.defaultAddForm.webLinkTypeId);
	}


	createEditForm() {
		this.editForm = this.fb.group({
			webLinkTypeId: [undefined, Validators.required],
			url: ['', [Validators.required, Validators.pattern(this.constants.WEBSITE_REGEX), Validators.maxLength(DB_LENGTH.webLink.url[1])]],
		});
	}


	tooManyCharacters(ctrl: AbstractControl) {
		const e = ctrl.errors.maxlength;
		return `${this.inlineLabels['Too many characters']}. ${e.actualLength} > ${e.requiredLength}`;
	}


	setAddFormView() {
		this.initializeForm();
		this.showAddForm = !this.showAddForm;
		Object.keys(this.editing).map(e => this.editing[e] = false);
	}


	async add() {
		if (!this.addForm.value.url.length) return;
		await this.dataWrapper.addWebLink(this.addForm.value.url, this.addForm.value.webLinkTypeId);
		this.loadData(this.dataWrapper._entityExtracts.webLinks);
		this.showAddForm = false;
		this.createAddForm();
	}


	getWebLinkIcon(webLinkTypeId: number): string {
		if (webLinkTypeId == 1) return 'logo fas fa-link fa-fw fa-fh';
		else if (webLinkTypeId == 2) return 'logo fab fa-facebook-square fa-fw';
		else if (webLinkTypeId == 3) return 'logo fab fa-linkedin fa-fw';
		else if (webLinkTypeId == 4) return 'logo fab fa-twitter-square fa-fw';
	}


	cancel() {
		this.setAddFormView();
		this.addForm.reset();
	}


	edit(webLink: DbsWebLink) {
		this.createEditForm();
		this.resetForm(webLink);
		Object.keys(this.editing).map(e => this.editing[e] = false);
		this.editing[webLink.webLinkId] = true;
	}


	resetForm(webLink: DbsWebLink) {
		const values = {
			webLinkTypeId: webLink.webLinkTypeId,
			url: webLink.url,
		}

		this.editForm.reset(values);

		Object.keys(this.editForm.controls).forEach(key => {
			const ctrl: AbstractControl = this.editForm.get(key);
			ctrl.markAsTouched();
		});

		this.editForm.updateValueAndValidity();
	}


	async delete(webLink: DbsWebLink) {
		const msg = `Do you want to remove "${webLink.url}" from ${this.dataWrapper._name}?`;
		const yes = await this.dialogService.confirm(msg);

		if (yes) {
			await this.dataWrapper.removeWebLink(webLink.webLinkId);
			this.loadData(this.dataWrapper._entityExtracts.webLinks);
		}
	}


	async save(webLink) {
		const form = this.editForm.value;
		await this.dataWrapper.updateWebLink(webLink.webLinkId, form.url, form.webLinkTypeId);
		this.loadData(this.dataWrapper._entityExtracts.webLinks);
	}


	close(webLink: DbsWebLink) {
		this.resetForm(webLink);
		this.editing[webLink.webLinkId] = false;
	}
}