import { Component, ElementRef, ViewChild } from "@angular/core";
import { FuncService } from '@me-services/core/func';
import { UtilityService } from "@me-services/core/utility";
import { LabelsService } from '@me-services/ui/labels';
import { BaseFieldPart } from '../base/SHR-UI_base-field.part';

@Component({
	selector: 'textarea-field',
	templateUrl: './SHR-UI_textarea-field.part.html',
	styleUrls: ['./SHR-UI_textarea-field.part.scss'],
})

export class TextareaFieldPart extends BaseFieldPart<string> {

	basePart: BaseFieldPart<string> = this;

	@ViewChild('textAreaField') textAreaField: ElementRef;

	length = 0;
	lengthLeft = 0;

	constructor(
		protected override func: FuncService,
		private util: UtilityService,
		protected override labelsService: LabelsService,
	) {
		super(func, labelsService);
	}


	textChange(e) {
		this.basePart.setPendingValue(e.target.value);
		this.checkText();
	}
	
	checkText() {
		let value: string = this.textAreaField.nativeElement.value || '';
		value = value.trim();
		this.length = value.length;
		this.lengthLeft = this.basePart.field.maxLength - this.length;
	}

	override cleanValue(value: string): string {
		return this.util.text.fixCase(value);
	}

	override checkForAdditionalError(): boolean {
		const value = (this.pendingValue || '').trim();

		if (value.length < this.field.minLength) {
			this.errorText = `Must be at least ${this.field.minLength} characters`;
			return true;
		}
		this.errorText = undefined;
		return false;
	}

	/** Calculate a max-width style that is roughly based on the maximum char length */
	get maxWidth() {
		const m = this.field?.maxLength || 100;
		return (m + 2) - m / 5;
	}
}