import { Injectable } from '@angular/core';
import { PageTabsLayoutPageService } from '@me-shared-parts/UI-common';


/**
 * The list of tabs on this page
 */
export enum AccApplicationPageTabId {
	Application = 1,
	Ratings = 2,
	Activity = 3,
}

/**
 * The list of main tab droplist views on this page
 */
export enum AccApplicationPageViewId {
	
}


@Injectable({ providedIn: 'root' })
export class AccApplicationPageService extends PageTabsLayoutPageService<AccApplicationPageTabId, AccApplicationPageViewId> {


	constructor() {
		super(AccApplicationPageService.name, AccApplicationPageTabId.Application, undefined);
	}

}