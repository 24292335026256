import { Component } from '@angular/core';


@Component({
	selector: 'button-container',
	templateUrl: './SHR-CMN_button-container.part.html',
	styleUrls: ['SHR-CMN_button-container.part.scss'],
})
export class ButtonContainerPart {
}
