import { DbsEvent } from '@me-interfaces';
import { DialogAction } from '@me-services/ui/dialog';


export function getActions() {
	const actions = {
		close: { ...closeAction },
		save: { ...saveAction },
		delete: { ...deleteAction },
		cancel: { ...cancelAction },
		list: [],
	};

	actions.list.push(...[
		actions.close,
		actions.save,
		actions.delete,
		actions.cancel,
	]);

	return actions;
}


const closeAction: DialogAction<void> = {
	id: 'close',
	enabled: true,
	visible: false,
	label: 'Close',
	linkType: 'callback',
	willCloseDialog: false,
};


const saveAction: DialogAction<DbsEvent> = {
	id: 'save',
	enabled: false,
	visible: false,
	label: 'Update',
	cssClass: 'btn-green',
	linkType: "callback",
	willCloseDialog: false,
};


const deleteAction: DialogAction<DbsEvent> = {
	id: 'delete',
	enabled: true,
	visible: false,
	label: 'Delete',
	cssClass: 'btn-red',
	linkType: "callback",
	willCloseDialog: false,
};

const cancelAction: DialogAction<void> = {
	id: 'cancel',
	enabled: true,
	visible: false,
	label: 'Cancel',
	linkType: 'callback',
	willCloseDialog: false,
};