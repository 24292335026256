import { DialogService } from '@me-services/ui/dialog';
import { ContactDialog } from './dialog/contact-dialog.dialog';

export class Contactor {
	constructor(
		private dialogService: DialogService,
	) {
	}


	async show(readonly: boolean, personIds: number[], stateKey: string, header?: string, optionalColumn?: { columnName: string, idMap: { [index: number]: string } }) {
		await this.dialogService.showCustom(
			ContactDialog,
			{
				data: {
					readonly,
					stateKey,
					personIds,
					header,
					optionalColumn,
				}
			},
			850, 500
		);
	}

}