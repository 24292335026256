import { AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId } from '@me-interfaces';


@Component({
	selector: 'acc-quarterlies-tab-part',
	templateUrl: './acc-quarterlies-tab.part.html',
})
export class AccQuarterliesTabPart extends DestroyablePart implements OnInit {

	accStageId = AccStageId.Quarterlies;

	constructor(
		public accAreaService: AccAreaService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

	}

}
