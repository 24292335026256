<div class='event-table-part' *ngIf='eventTable'>
	<me-h2 *ngIf="showHeader">
		<me-label key='Event Details'></me-label>
	</me-h2>
	<table
		class='pro-table pro-table-no-header pro-table-with-labels pro-table-with-thin-rows pro-table-without-border'>
		<ng-container *ngIf="showSiteProgram">
			<tr *ngFor='let row of eventTable.eventContextRows'>
				<td>{{ row.label }} </td>
				<td *ngIf='!user.isCrmUser || !row.link'>
					{{ row.value }}
				</td>
				<td *ngIf='user.isCrmUser && row.link'>
					<a class='link' (click)='navigateToLink(row)'>{{ row.value }}</a>
				</td>
			</tr>
		</ng-container>
		<tr>
			<td>
				<me-label key='Start'></me-label>
			</td>
			<td>
				{{eventTable.start}}
			</td>
		</tr>

		<tr>
			<td>
				<me-label key='End'></me-label>
			</td>
			<td>
				{{eventTable.end}}
			</td>
		</tr>
		<tr *ngIf='eventTable.toolStart'>
			<td>
				<me-label key='Tool Start'></me-label>
			</td>
			<td>
				{{eventTable.toolStart}}
			</td>
		</tr>

		<tr *ngIf='eventTable.toolEnd'>
			<td>
				<me-label key='Tool End'></me-label>
			</td>
			<td>
				{{eventTable.toolEnd}}
			</td>
		</tr>

		<tr>
			<td>
				<me-label key='Venue'></me-label>
			</td>
			<td>
				{{eventTable.venueName}}
			</td>
		</tr>

		<tr *ngIf="!eventTable.venueId">
			<td>
				<me-label key='Link'></me-label>
			</td>
			<td>
				<a *ngIf='eventTable.link' [href]='eventTable.link' target='_blank'
					style="overflow-wrap: anywhere;">{{eventTable.link}}</a>
				<div *ngIf='!eventTable.link' class="missing-value">
					<me-label key='Link Not Provided'></me-label>
				</div>
			</td>
		</tr>

		<tr *ngIf="eventTable.venueId">
			<td>
				<me-label key='Address'></me-label>
			</td>
			<td>
				<address-link [address]='eventTable.address'></address-link>
			</td>
		</tr>

		<tr>
			{{eventTable.host | json}}
			<td>
				<me-label key='Host'></me-label>
			</td>
			<div *ngIf="!eventTable.hostPersonId" class='missing-value'>
				<td>
					<me-label key='None assigned'></me-label>
				</td>
			</div>
			<td *ngIf="eventTable.hostPersonId && host">
				<person-extract-link *ngIf="!publicFacing" [person]='host'></person-extract-link>
				<public-person-link *ngIf="publicFacing" [person]='host'></public-person-link>
			</td>
		</tr>

		<tr>
			<td *ngIf="!publicFacing">
				<me-label key='Public Notes'></me-label>
			</td>
			<td *ngIf="publicFacing">
				<me-label key='Notes'></me-label>
			</td>
			<td>
				{{eventTable.publicNotes}}
			</td>
		</tr>
	</table>
</div>