import { Injectable } from '@angular/core';
import { environment } from '@me-environment';
import { AppAreaFuncResponse, AppAreaMessage, AppAreaName, CompanyTypeId, DbConceptName, DbcUser, Dbs, Event, EventAccess, IndustryId, NamedConcept, SingletonData, SingletonDelta, SingletonsCacheConfig } from '@me-interfaces';
import { DexieService, SINGLETONS_AS_OF_UTC, SINGLETONS_CACHE_FILES_UTC } from '@me-services/core/dexie';
import { UtilityService } from '@me-services/core/utility';
import { DialogService } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { PageSpinnerService } from '@me-services/ui/page-spinner';
import { UrlService } from "@me-services/ui/url";
import { NotificationService } from '@progress/kendo-angular-notification';
import * as cryptoJS from 'crypto-js';
import * as lzString from 'lz-string';
import { BehaviorSubject, lastValueFrom, ReplaySubject, take } from 'rxjs';
import { cacheConfigs } from '../dexie/dexie-singletons-db';
import { Contactor } from './contactor/contactor';
import { Explorable } from './explorable';
import { Explorer } from './explorer/explorer';
import { ExplorableIdentifier } from './explorer/service/explorable-identifier';
import { getEventAccess } from './get-event-access';
import { initDomainDataManagers } from './initialize/init-domain-data-managers';
import { initSingletonManagers } from './initialize/init-singleton-managers';
import { initSingletonPackages } from './initialize/init-singleton-packages';
import { Searcher } from './search/searcher';
import { ElapsedTimer } from '../utility/logging/elapsed-timer';

const SPINNER_DOWNLOADING = 'Downloading Cache Data';

//
// Note: To add a new singleton, see the instructions
// in \src\interfaces\app-areas\singletons.ts
//

@Injectable({
	providedIn: 'root'
})
/**
 * Service that holds and makes available the currently loaded set of singletons and
 * domain data. Singletons are DBConcept objects that are shared across app areas.
 */
export class DataService {

	/** Static reference to the singleton service to overcome circular dependencies */
	public static instance: DataService;

	private readonly _domainDataCache$ = new ReplaySubject<{ [name: string]: any[] }>(1);
	private readonly _singletonsAsOfUTC$ = new ReplaySubject<number>(1);

	private readonly _pendingSyncs$ = new BehaviorSubject(0);
	private readonly _syncing$ = new BehaviorSubject(false);

	public readonly domain = initDomainDataManagers(this._domainDataCache$, this.util);
	private readonly singletons = initSingletonManagers(this.dexie, this.util);

	public readonly languageId = this.urlService.languageId;


	/**
	 * Singleton packages are available through a property named "admin" to avoid accidentally
	 * using them outside the admin area. Singletons contain sensitive information, not for
	 * public consumption, so they should never be used in the public facing pages.
	 */
	public readonly admin = {

		...initSingletonPackages(
			this.dexie,
			this.domain,
			this.singletons,
			this._singletonsAsOfUTC$,
			this.notifySingletonsChanged.bind(this),
			this.util,
			this.urlService,
		),

		/**
		 * The observable can be used to monitor if the singletons have changed.
		 * It is raised with the latest utc time returned by an action function.
		 */
		singletonsAsOfUTC$: this._singletonsAsOfUTC$.asObservable(),

		/**
		 * Given an explorable concept name and an id, lookup and return the related package.
		 */
		get: async (nav: ExplorableIdentifier): Promise<Explorable> => {
			const manager = this.admin[nav.conceptName];
			if (!manager) throw new Error(`No PackageManager at admin.${nav.conceptName}`);
			return await manager.getOnePackage(nav.id);
		},

		getMany: async (navs: ExplorableIdentifier[]): Promise<Explorable[]> => {
			const packages: Explorable[] = [];
			for (const nav of navs) {
				packages.push(await this.admin.get(nav));
			}

			return packages;
		}
	};


	public readonly explorer = new Explorer(this);
	public readonly searcher = new Searcher(this);
	public readonly contactor = new Contactor(this.dialogService);


	constructor(
		private dexie: DexieService,
		public util: UtilityService,
		private urlService: UrlService,
		private ps: PageSpinnerService,
		private labelsService: LabelsService,
		private notificationService: NotificationService,
		public readonly dialogService: DialogService,
	) {
		DataService.instance = this;
	}


	/**
	 * This method will completely delete and recreate the database. This should only be done
	 * if the database is somehow has a non backward compatible change and old cached data is
	 * causing a runtime issue. The database will be automatically reset when a new release is
	 * detected (different first change log entry) or if a ?reset is included on the URL.
	 */
	public async resetDatabase() {
		await this.dexie.resetDatabase();
	}


	/**
	 * If singleton data is updated or deleted by directly getting the table via dexie.getTable()
	 * and then making changes with the Dexie API (https://dexie.org/docs/Table/Table) then once
	 * done, this ds.notifySingletonsChanged() should be called to inform the rest of the app.
	 * 
	 * The singletonsAsOfUTC$ will be nexted with its current value.
	 */
	public async notifySingletonsChanged() {
		const singletonsAsOfUTC = await lastValueFrom(this._singletonsAsOfUTC$.pipe(take(1)));
		this._singletonsAsOfUTC$.next(singletonsAsOfUTC);
	}


	/**
	 * Check if cache files need to be downloaded and bulk inserted into the database.
	 */
	async applySingletonsCacheConfig(config: SingletonsCacheConfig) {

		if (!config) return;

		const db = await this.dexie.getSingletonsDb();



		if (this.singletonsAsOfUTC < config.filesUTC) this.singletonsAsOfUTC = config.filesUTC;

		//
		// Compare the key of the last applied cache files with the key of the new ones.
		// If they are the same then we don't need to download again.
		//
		if (config.filesUTC <= this.singletonCacheFilesUTC) {
			this._singletonsAsOfUTC$.next(this.singletonsAsOfUTC);
			return;
		}

		let currentSpinner = '';

		try {
			this._pendingSyncs$.next(this._pendingSyncs$.value + 1);
			this._syncing$.next(true);

			this.ps.addSpinner(currentSpinner = SPINNER_DOWNLOADING);
			await this.util.setTimeout(10);	// Let the UI ketchup


			//
			// Process each downloaded file (in series to limit memory consumption)
			//
			const files = await downloadSingletonCacheFiles(config);

			for (const file of files) {

				//
				// Inform through the spinner that we are now DECOMPRESSING the results
				//
				this.ps.removeSpinner(currentSpinner);
				this.ps.addSpinner(currentSpinner = `Opening ${file.singletonName}`);
				await this.util.setTimeout(10);	// Let the UI ketchup


				//
				// Decrypt and Decompress
				//
				const hex = cryptoJS.AES.decrypt(file.content, config.secretPassphrase).toString(cryptoJS.enc.Utf8);
				const numbers = this.util.array.fromHex(hex);
				const bytes = new Uint8Array(numbers);
				file.content = lzString.decompressFromUint8Array(bytes);

				//
				// Inform through the spinner that we are now PARSING the results
				//
				this.ps.removeSpinner(currentSpinner);
				this.ps.addSpinner(currentSpinner = `Parsing ${file.singletonName}`);
				await this.util.setTimeout(10);	// Let the UI ketchup


				//
				// Parse the JSON into a list of a Dbs subclass and upsert into the table
				//
				const updated: Dbs[] = JSON.parse(file.content);


				//
				// Inform through the spinner that we are now CACHING the results
				//
				this.ps.removeSpinner(currentSpinner);
				this.ps.addSpinner(currentSpinner = `Storing ${file.singletonName}`);
				await this.util.setTimeout(10);	// Let the UI ketchup


				//
				// Bulk insert the singletons list
				//
				const delta: SingletonDelta<Dbs> = { asOfUTC: config.filesUTC, updated, deletedIds: [] };
				if (updated.length) await db.applyDelta(file.singletonName, delta, true);
			}


			//
			// Store the timestamp so we can skip loading when the cache files haven't changed
			//
			this.singletonCacheFilesUTC = config.filesUTC;
			this.singletonsAsOfUTC = config.filesUTC;

		}
		catch (error) {
			this.util.log.error('Error applying singletons cache file', error);
		}
		finally {
			this.ps.removeSpinner(currentSpinner);
			this._pendingSyncs$.next(this._pendingSyncs$.value - 1);
			this._syncing$.next(false);
		}
	}


	/**
	 * Get the dd.json manifest file, check the hash of each domain data concept, and check if
	 * we already have the correct version cached. If not, download the related cache file,
	 * parse it, and store it in dexie.
	 */
	async applyDomainCacheFiles() {

		const BASE_URL = `https://storage.googleapis.com/${environment.firebaseConfig.projectId}_dd`;

		const manifestUrl = `${BASE_URL}/dd.json`;
		const response = await fetch(manifestUrl);
		const manifest: { [index: string]: string } = await response.json();


		const domainDb = await this.dexie.getDomainDataDb();
		const { hashes, data } = await domainDb.getHashesAndData();

		const changes: Promise<{ name: string, hash: string, json: any }>[] = [];


		//
		// Fetch (in parallel) any domain data files where the hashes don't
		// match with what we currently have stored in the domain db
		//
		for (const name in manifest) {

			const hash = manifest[name];

			if (hashes[name] !== hash) {

				changes.push((async () => {
					const response = await fetch(`${BASE_URL}/dd_${name}_${hash}.json`);
					const json = await response.json();
					return { name, hash, json };
				})());
			}
		}

		if (changes.length) {
			const downloads = await Promise.all(changes);

			for (const file of downloads) {
				hashes[file.name] = file.hash;
				data[file.name] = file.json;
			}

			await domainDb.putHashesAndData(hashes, data);
		}

		this._domainDataCache$.next(data);
	}


	public async showResponseMessage(message: AppAreaMessage) {

		const style = message.success ? 'success' : 'error';

		this.notificationService.show({
			content: await this.labelsService.get(message.label),
			hideAfter: 5000,
			position: { horizontal: 'center', vertical: 'top' },
			animation: { type: 'fade', duration: 1000 },
			type: { style, icon: true },
		});

	}


	public async getEventAccess(user: DbcUser, event: Event): Promise<EventAccess> {
		return await getEventAccess(user, event, this);
	}


	/**
	 * The UTC (seconds!) of the cache files that were last downloaded
	 */
	public get singletonCacheFilesUTC() {
		return +(localStorage.getItem(SINGLETONS_CACHE_FILES_UTC) ?? 0);
	}


	/**
	 * The UTC (seconds!) of the cache files that were last downloaded
	 */
	private set singletonCacheFilesUTC(utc: number) {
		if (isNaN(utc)) return;
		localStorage.setItem(SINGLETONS_CACHE_FILES_UTC, utc + '');
	}


	/**
	 * The UTC (seconds!) of the last updated set of singletons read. This keeps ratcheting up
	 */
	public get singletonsAsOfUTC() {
		const singletonsCacheUTC = +(localStorage.getItem(SINGLETONS_AS_OF_UTC) ?? 0);
		return Math.max(singletonsCacheUTC, this.singletonCacheFilesUTC);
	}


	/**
	 * The UTC (seconds!) of the last updated set of singletons read. This keeps ratcheting up
	 */
	private set singletonsAsOfUTC(utc: number) {
		if (isNaN(utc)) return;
		localStorage.setItem(SINGLETONS_AS_OF_UTC, utc + '');
		this._singletonsAsOfUTC$.next(utc);
	}




	public async mergeResponseSingletons(data: SingletonData, singletonsAsOfUTC: number, timer: ElapsedTimer = undefined) {
		//
		// Make bulk changes to each table
		//
		const db = await this.dexie.getSingletonsDb();

		for (const singletonTable of cacheConfigs) {

			const tableData: SingletonDelta<Dbs> = data[singletonTable.name];

			if (!tableData) continue;

			if (tableData.updated.length || tableData.deletedIds.length) {
				timer?.addSnapshot(`Applying delta for ${singletonTable.name}`, tableData);
			}
			await db.applyDelta(singletonTable.name, tableData);
		}

		//
		// Store the latest cache date and emit the related observable
		//
		this.singletonsAsOfUTC = singletonsAsOfUTC;

	}


	public async mergeResponseSingletonsForArea(areaName: AppAreaName, response: AppAreaFuncResponse) {


		//
		// Noop functions are simple shims that skip functionality when the area
		// will never be allowed, such as tech area when not a tech admin.
		//
		if (response.isNoop || !response.updatedSingletons) return;

		const t = this.util.log.createElapsedTimer(`ds.mergeResponseSingletons(${areaName})`, response);

		await this.mergeResponseSingletons(response.updatedSingletons, response.newSingletonsCacheUTC, t);

		t.endAndRender();
	}


	/**
	 * Given an instance of a named concept, determine and
	 * return out the "name" of that instance.
	 */
	public getConceptName(c: NamedConcept, shortNameIfAvailable = true) {

		//
		// Domain Data
		//
		if (c._concept === DbConceptName.AccMeetingTimes) return c.description;
		if (c._concept === DbConceptName.AccLanguageSeason) return c.name;
		if (c._concept === DbConceptName.AccSeason) return c.name;
		if (c._concept === DbConceptName.AccStage) return c.name;
		if (c._concept === DbConceptName.ApplicationStatus) return c.name;
		if (c._concept === DbConceptName.AwardKind) return c.name;
		if (c._concept === DbConceptName.AwardName) return c.name;
		if (c._concept === DbConceptName.City) return c.name;
		if (c._concept === DbConceptName.CompanyType) return c.name;
		if (c._concept === DbConceptName.DecidingRole) return c.name;
		if (c._concept === DbConceptName.EventType) return c.name;
		if (c._concept === DbConceptName.Industry) return c.name;
		if (c._concept === DbConceptName.Language) return c.name;
		if (c._concept === DbConceptName.NoteCategory) return c.label;
		if (c._concept === DbConceptName.PhoneType) return c.name;
		if (c._concept === DbConceptName.PicStage) return c.name;
		if (c._concept === DbConceptName.Prefix) return c.name;
		if (c._concept === DbConceptName.ProgramType) return c.name;
		if (c._concept === DbConceptName.QuestionsType) return c.name;
		if (c._concept === DbConceptName.QuestionType) return c.name;
		if (c._concept === DbConceptName.State) return c.state;
		if (c._concept === DbConceptName.Suffix) return c.name;
		if (c._concept === DbConceptName.Topic) return c.shortNameLabel;
		if (c._concept === DbConceptName.WebLinkType) return c.name;

		//
		// Dbs
		//
		if (c._concept === DbConceptName.Accelerator) return c.longName;
		if (c._concept === DbConceptName.Company) return shortNameIfAvailable ? c.shortName : c.longName;
		if (c._concept === DbConceptName.Email) return c.email;
		if (c._concept === DbConceptName.EntityNote) return c.subject;
		// if (c._concept === DbConceptName.Event) return new Date(c.startUTC * 1000).toLocaleString(undefined, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit' })
		if (c._concept === DbConceptName.Event) return this.util.date.formatUTC(c.startUTC, 'MMM D, YYYY (DOW)', 'H:MM AM EST', this.languageId);
		if (c._concept === DbConceptName.Person) return c.middleInit ? `${c.firstName} ${c.middleInit}. ${c.lastName}` : `${c.firstName} ${c.lastName}`;
		if (c._concept === DbConceptName.PitchContest) return c.name;
		if (c._concept === DbConceptName.Position) return c.title;
		if (c._concept === DbConceptName.Program) return c.name;
		if (c._concept === DbConceptName.Site) return shortNameIfAvailable ? c.code : c.name;
		if (c._concept === DbConceptName.Tag) return shortNameIfAvailable ? c.name : c.fullName;
		if (c._concept === DbConceptName.TagPrefix) return c.prefix;
		if (c._concept === DbConceptName.Venue) return c.displayedName;


		// return c._concept; // Just return out the name of the concept itself
		return '';
	}


	public getIndustryName(industryId: IndustryId) {
		if (!industryId) return undefined;
		return this.domain.industry.getOne(industryId).name
	}

	public getCompanyTypeName(companyTypeId: CompanyTypeId) {
		if (!companyTypeId) return undefined;
		return this.domain.companyType.getOne(companyTypeId).name
	}

	public getCityAndState(zipId: number) {
		if (!zipId) return undefined;
		return this.domain.zip.getOne(zipId).cityAndState;
	}

	public getZipCode(zipId: number) {
		if (!zipId) return undefined;
		return ("00000" + zipId).slice(-5);
	}
}




/**
 * Await the download of all files (in parallel)
 */
async function downloadSingletonCacheFiles(config: SingletonsCacheConfig) {

	const filePromises = config.singletonNames.map(async singletonName => {

		const path = `${config.bucketPath}/${singletonName}.txt`;
		const response = await fetch(path, { cache: 'no-store' });
		const content = await response.text();

		return { singletonName, content };
	});


	return await Promise.all(filePromises);
}
