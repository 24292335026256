<div class='acc-interview-tab-part'>

	<acc-stage-summary [accStageId]="accStageId"></acc-stage-summary>

	<section class="tab-section">
		<acc-interview-checks-part></acc-interview-checks-part>
	</section>

	<section class="tab-section">
		<acc-application-counts-part [accStageId]="accStageId" [pageService]="pageService"
			[generalCountsData]="generalCounts$ | async"></acc-application-counts-part>
	</section>

</div>