import { Accelerator, AccTeam, DbsSite } from "@me-interfaces";

export function getAccAlumni(
	sites: readonly DbsSite[],
	accsBySiteId: Record<number, Accelerator[]>,
	accTeams: readonly AccTeam[],
) {

	const alumniBySiteId: Record<number, number> = {};

	const TWELVE_WEEKS_IN_SECONDS = 12 * 7 * 24 * 60 * 60;
	const twelveWeeksAgo = (Date.now() / 1000) - TWELVE_WEEKS_IN_SECONDS;

	for (const site of sites) {

		const siteId = site.siteId;
		const accs = accsBySiteId[siteId] ?? [];

		const accIds = accs
			.filter(acc => acc.startUTC < twelveWeeksAgo)
			.map(acc => acc.accId);

		const teams = accTeams
			.filter(t => accIds.includes(t.accId))
			.filter(t => t.droppedOutWeek == null || t.droppedOutWeek > 12);

		alumniBySiteId[siteId] = teams.length;
	}

	return { alumniBySiteId };
}