<ng-container *ngIf='icon'>

	<div kendoTooltip tooltipClass='me-tooltip'
		[ngClass]='{"me-icon-part":true, "has-show-red-flag":icon.hasRedFlag, "me-icon-no-text":hidingText}'>

		<div class="me-icon fa-stack" [class.icon-sep-line]="!hidingText"
			[attr.title]='hidingText ? icon.text || null : null'>
			<i class="fa-fw {{icon.iconClass}} fa-stack-1x" [class.icon-slashed]="iconSlashed"
				[class.icon-faded]="!hidingText && !iconSlashed"></i>
			<i *ngIf='iconSlashed' class="fa-fw fas fa-slash fa-stack-1x color-icon-slash"></i>
		</div>

		<div *ngIf="!hidingText && icon.hasRedFlag" class="me-icon icon-sep-line" [attr.title]='"Has Red Flag Note"'>
			<i class="fa-fw {{redFlagIconClass}}"></i>
		</div>

		<div class="me-icon-text" [class.me-icon-has-subtext]="!!icon.subText" *ngIf='!hidingText'>{{icon.text}}<div
				*ngIf='icon.subText' class="me-icon-subtext">{{ icon.subText }}</div>
		</div>
	</div>

</ng-container>