<div class='acc-applications-counts-part'>
	<ng-container *ngIf='countsData$ | async; let countsData'>

		<me-h3>Application Counts by Status</me-h3>
		<table class='pro-table pro-table-without-border pro-table-with-thin-rows'>
			<tr>
				<th>Status</th>
				<th>Count</th>
				<th>Contact</th>
			</tr>
			<tr *ngFor="let data of countsData">
				<td>{{data.status}}</td>
				<td>{{data.applicationsCount}}</td>
				<td>
					<button class="text-btn text-btn-not-inline" (click)="openContactor(data.personIds)">
						<span class='contact-button-content'>
							<i class='far fa-envelope'></i> <i class='far fa-comment-lines'></i>
						</span>
					</button>
				</td>
			</tr>
		</table>
	</ng-container>
</div>