import { Injectable } from '@angular/core';
import { PageTabsLayoutPageService } from '@me-shared-parts/UI-common';


/**
 * The list of tabs on this page
 */
export enum RegionDashboardTabId {
	Region = 1,
	Counts = 2,
}

/**
 * The list if main tab droplist views on this page
 */
export enum RegionDashboardViewId {
	Indicators = 1,
	Sites = 2,
	Accelerators = 3,
	PitchContests = 4,
	Applications = 5,
	Businesses = 6,
	Entrepreneurs = 7,
	Events = 8,
	Awards = 9,
}

@Injectable({ providedIn: 'root' })
export class RegionPageService extends PageTabsLayoutPageService<RegionDashboardTabId, RegionDashboardViewId> {

	constructor() {
		super(RegionPageService.name, RegionDashboardTabId.Region, RegionDashboardViewId.Indicators);
	}

}