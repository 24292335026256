import { Concept, DbConceptName } from "@me-interfaces";
import { getIconContext } from "./get-icon-context";
import { Icon } from "./icon";
import { IconContext } from "./icon-context";
import { IconHideTextAt } from "./icon-hide-text-at";
import { IconStyle } from "./icon-style";

/**
 * Return the Icon value for a given Concept
 */
export function getIconForConcept(concept: Concept): Icon {

	//
	// Dba
	//
	// if (concept._concept == DbConceptName.AccInterview) return Icon.;
	// if (concept._concept == DbConceptName.AccInterviewerEvent) return Icon.;
	// if (concept._concept == DbConceptName.AccJudgeEvent) return Icon.;
	// if (concept._concept == DbConceptName.AccJudgement) return Icon.;
	// if (concept._concept == DbConceptName.AccMatchAssessment) return Icon.;
	// if (concept._concept == DbConceptName.AccMentor) return Icon.;
	// if (concept._concept == DbConceptName.AccReading) return Icon.;
	// if (concept._concept == DbConceptName.ApplicationStatusLog) return Icon.;


	//
	// Dbd
	//
	// if (concept._concept == DbConceptName.AccMeetingTimes) return Icon.;
	// if (concept._concept == DbConceptName.AccLanguageSeason) return Icon.;
	// if (concept._concept == DbConceptName.AccStage) return Icon.;
	// if (concept._concept == DbConceptName.ApplicationStatus) return Icon.;
	// if (concept._concept == DbConceptName.AwardKind) return Icon.;
	// if (concept._concept == DbConceptName.AwardName) return Icon.;
	// if (concept._concept == DbConceptName.AwardNameDecidingRole) return Icon.;
	// if (concept._concept == DbConceptName.AwardNameEventType) return Icon.;
	// if (concept._concept == DbConceptName.City) return Icon.;
	// if (concept._concept == DbConceptName.CompanyType) return Icon.;
	// if (concept._concept == DbConceptName.DecidingRole) return Icon.;
	// if (concept._concept == DbConceptName.EventType) return Icon.;
	// if (concept._concept == DbConceptName.Industry) return Icon.;
	// if (concept._concept == DbConceptName.Language) return Icon.;
	// if (concept._concept == DbConceptName.NoteCategory) return Icon.;
	// if (concept._concept == DbConceptName.PhoneType) return Icon.;
	// if (concept._concept == DbConceptName.PicStage) return Icon.;
	// if (concept._concept == DbConceptName.Prefix) return Icon.;
	// if (concept._concept == DbConceptName.ProgramType) return Icon.;
	// if (concept._concept == DbConceptName.QuestionsType) return Icon.;
	// if (concept._concept == DbConceptName.QuestionType) return Icon.;
	// if (concept._concept == DbConceptName.State) return Icon.;
	// if (concept._concept == DbConceptName.Suffix) return Icon.;
	// if (concept._concept == DbConceptName.WebLinkType) return Icon.;

	//
	// Dbf
	//


	//
	// Dbs
	//
	if (concept._concept == DbConceptName.Accelerator) return Icon.concept_accelerator;
	// if (concept._concept == DbConceptName.AccInterviewer) return Icon.;
	// if (concept._concept == DbConceptName.AccJudge) return Icon.;
	// if (concept._concept == DbConceptName.AccReader) return Icon.;
	if (concept._concept == DbConceptName.AccTeam) return Icon.concept_accTeam;
	// if (concept._concept == DbConceptName.AccTeamMember) return Icon.;
	// if (concept._concept == DbConceptName.ApplicationParticipant) return Icon.;
	if (concept._concept == DbConceptName.AgreementType) return Icon.concept_agreementType;
	if (concept._concept == DbConceptName.AgreementSignature) return Icon.concept_agreementSignature;
	// if (concept._concept == DbConceptName.Award) return Icon.;
	if (concept._concept == DbConceptName.Company) return Icon.concept_company;
	// if (concept._concept == DbConceptName.Email) return Icon.;
	// if (concept._concept == DbConceptName.EntityNote) return Icon.;
	// if (concept._concept == DbConceptName.Event) return Icon.;
	// if (concept._concept == DbConceptName.Person) return Icon.;
	// if (concept._concept == DbConceptName.PersonTag) return Icon.;
	// if (concept._concept == DbConceptName.PicJudge) return Icon.;
	// if (concept._concept == DbConceptName.PicReader) return Icon.;
	// if (concept._concept == DbConceptName.PicTeam) return Icon.;
	// if (concept._concept == DbConceptName.PicTeamMember) return Icon.;
	// if (concept._concept == DbConceptName.PitchContest) return Icon.;
	// if (concept._concept == DbConceptName.Position) return Icon.;
	// if (concept._concept == DbConceptName.Program) return Icon.;
	// if (concept._concept == DbConceptName.Region) return Icon.;
	// if (concept._concept == DbConceptName.Relationship) return Icon.concept_relationship;
	if (concept._concept == DbConceptName.Site) return Icon.concept_site;
	if (concept._concept == DbConceptName.Region) return Icon.concept_region;
	// if (concept._concept == DbConceptName.SiteProgram) return Icon.siteProgram;
	// if (concept._concept == DbConceptName.Tag) return Icon.concept_tag;
	// if (concept._concept == DbConceptName.TagPrefix) return Icon.;
	if (concept._concept == DbConceptName.Venue) return Icon.concept_venue;
	// if (concept._concept == DbConceptName.WebLink) return Icon.;

	return Icon.empty;
}


export function getIconContextForConcept(concept: Concept, iconStyle?: IconStyle, hideText?: IconHideTextAt, text?: string, hasRedFlag?: boolean): IconContext {
	return getIconContext(getIconForConcept(concept), iconStyle, hideText, text, hasRedFlag);
}