import { Component, Input, OnChanges } from '@angular/core';
import { DbsPerson } from '@me-interfaces';

@Component({
	selector: 'person-positions',
	styleUrls: ['./SHR-CMN_person-positions.part.scss'],
	templateUrl: './SHR-CMN_person-positions.part.html',
})
export class PersonPositionsPart implements OnChanges {

	@Input() person: DbsPerson;

	ngOnChanges() {
		if (this.person == undefined) throw new Error('The person-positions part requires a person attribute.');
	}
}