import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LabelDetails, LabelKey, LabelsData, LanguageId } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { UtilityService } from '@me-services/core/utility';
import * as moment from 'moment';
import 'moment/locale/es';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UrlService } from '../url';

export const MISSING_TRANSLATION = 'MISSING TRANSLATION: ';
export type GetLabelFunction = (key: string | LabelKey, returnUndefinedIfMissing?: boolean) => string;

const LABELS_STORAGE_KEY = 'me::labels';


@Injectable({ providedIn: 'root' })
export class LabelsService {

	private readonly _labelsData$ = new ReplaySubject<LabelsData>(1);
	public readonly getLabel$ = new ReplaySubject<GetLabelFunction>(1);

	public get languageId(): LanguageId {
		return this.urlService.languageId;
	}


	constructor(
		private urlService: UrlService,
		private func: FuncService,
		private util: UtilityService,
		@Inject(DOCUMENT) document,
	) {


		const oldLabels = this.getLabelsFromCache();
		if (oldLabels) this._labelsData$.next(oldLabels);

		if (localStorage) this._labelsData$.subscribe(labels => {
			localStorage.setItem(LABELS_STORAGE_KEY, JSON.stringify(labels));
		});



		//
		// A function that holds the labels in a closure so it doesn't need to be called async
		//
		this._labelsData$.subscribe(labelsData => {
			this.getLabel$.next(this._getLabelFunction(labelsData));
		});
	}


	/**
	 * Return a function with a closure over a set of labels data that translates a label key
	 * and injects field values if present.
	 * @param labelsData 
	 */
	private _getLabelFunction(labelsData: LabelsData): GetLabelFunction {

		return (key: string | LabelKey, returnUndefinedIfMissing = false): string => {

			let labelKey: LabelKey;

			if (typeof key == 'string') {

				//
				// Return string literals (without the :) as-is, untranslated.
				//
				if (key == '' || key[0] !== ':') return key;

				//
				// Strings that start with : are deprecated. Log the deprecation and convert to a LabelKey object.
				//
				else {
					this.util.log.warning(`Using label keys that start with a colon is DEPRECATED.  Change '${key}' to { key: '${key.substr(1)}' }`);
					labelKey = { key: key.substr(1) };
				}
			}
			else labelKey = key;

			labelKey.fields = labelKey.fields ?? {};

			let label = labelsData[labelKey.key] || '';

			if (label) {

				//
				// See if the expected set of fields matches the actual set.
				//
				const matches = label.match(/{{\s*[-a-z.]+\s*}}/g);
				const fieldNames = matches ? matches.map(x => x.match(/[-a-z.]+/)[0]) : [];

				for (const fieldName of fieldNames) {
					if (labelKey.fields[fieldName]) label = label.replace(`{{${fieldName}}}`, labelKey.fields[fieldName]);
					else this.util.log.errorMessage(`No value provided for field {{$fieldName}}`, { key, label });
				}

				for (const fieldName of Object.keys(labelKey.fields)) {
					if (!fieldNames.includes(fieldName)) {
						if (fieldName.toLowerCase() !== fieldName) this.util.log.errorMessage(`Field not all lowercase: {{${fieldName}}}. Use lower case and dashes to separate words.`, { key, label });
						else this.util.log.errorMessage(`A value was provided but there is no matching field {{${fieldName}}}`, { key, label });
					}
				}
			}
			else {
				if (returnUndefinedIfMissing) return undefined;
				else return `${MISSING_TRANSLATION} ${labelKey.key}`;
			}

			return label;

		}
	}


	/**
	 * Returns a function that can be called synchronously, repeatly.
	 */
	public async getLabel(): Promise<GetLabelFunction> {
		return this.getLabel$.pipe(take(1)).toPromise();
	}


	/**
	 * Pull labels that were loaded in the past and stored in Local Storage
	 */
	private getLabelsFromCache(): LabelsData {

		if (localStorage) {
			const oldLabelsStr = localStorage.getItem(LABELS_STORAGE_KEY);
			if (oldLabelsStr !== 'undefined') return <LabelsData>JSON.parse(oldLabelsStr);
		}
		return undefined;
	}


	/**
	 * Returns the full map of labels data.
	 * Use either getItem() or getLabel() instead for a single label. 
	 */
	public async getLabelsData(): Promise<LabelsData> {
		return this._labelsData$.pipe(take(1)).toPromise();
	}


	/**
	 * A parameter of type string will be passed right back out untranslated.
	 * A parameter of type LabelKey will be translated and returned after the
	 * labels data has been loaded.
	 *  
	 * @param key Strings are returned untranslated. LabelKey objects are returned translated.
	 * @param undefinedIfMissing A missing usually returns a message indicating it is missing. If true, undefined will be returned instead. Default: false 
	 */
	async get(key: string | LabelKey, returnUndefinedIfMissing = false): Promise<string> {

		const getLabel = await this.getLabel();
		return await getLabel(key, returnUndefinedIfMissing);

	}


	/**
	 * Updates one or more label translations for the current language and then reads
	 * the latest set if labels and emits them through labelsData$.
	 */
	public async updateLabels() {
		const labels = await this.func.public.i18n.getLabels({ languageId: this.languageId });
		this._labelsData$.next(labels);
	}


	/**
	 * The AppSessionService calls func.public.appSession.start which returns the initial
	 * set of labels amongst other things. The AppSessionService then calls this function
	 * to initialize the labels.
	 * @param labels 
	 */
	public setInitialLabels(labels: LabelsData) {
		this._labelsData$.next(labels);
	}


	/**
	 * Returns who last edited a label and when it happened
	 */
	public async getLabelChangedDetails(key: string): Promise<LabelDetails> {
		return await this.func.public.i18n.getLabelDetails({ key, languageId: this.languageId });
	}


	/**
	 * Configures Moment.js for the current language (EN or ES) and then returns
	 * a Moment constructor function.
	 */
	public getMoment(): (inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, strict?: boolean) => moment.Moment {
		const m = moment;
		if (this.languageId == LanguageId.Spanish) m.locale('es');
		else m.locale('en');
		return m;
	}


	/**
	 * The me-label part should always be used when possible. However, sometimes it cannot be used,
	 * such as when embedding a label in a title or placeholder attribute, or when labels are needed
	 * inside dropdown options.  For those, the page should create an inlineLabels property and then
	 * call this function.  The property will be automatically updated with the translated labels.
	 * NOTE: This should only be used for simple labels. Markdown is not supported.
	 * @param page A page that extends DestroyablePart
	 * @param keys An array of label keys to be translated
	 */
	public trackInlineLabels(page: { inlineLabels: { [index: string]: string }, destroyedWithInitCheck$: Subject<void> }, keys: string[]): { [index: string]: string } {


		//
		// First create the labels syncronously, in English
		//
		const untranslatedLabels = keys.reduce((labels, key) => {
			labels[key] = key;
			return labels;
		}, {});


		//
		// Second monitor asynchronously for the translated ones
		//
		setTimeout(() => {

			this._labelsData$
				.pipe(takeUntil(page.destroyedWithInitCheck$))
				.subscribe(labels => {

					page.inlineLabels = keys.reduce((values, key) => {
						values[key] = labels[key] || `MISSING TRANSLATION: ${key}`;
						return values;
					}, <{ [index: string]: string }>{});

				});

		});

		return untranslatedLabels;
	}


	/**
	 * Compare two labels and determine if they are equivalent 
	 */
	public labelsAreSame(currentValue: string | LabelKey, previousValue: string | LabelKey) {

		if (typeof currentValue == 'string' || typeof previousValue == 'string') {
			return currentValue == previousValue;
		}

		if (currentValue.key !== previousValue.key) return false;

		const currFields = currentValue.fields ?? {};
		const prevFields = previousValue.fields ?? {};

		for (const field in currFields) {
			if (currFields[field] !== prevFields[field]) return false;
		}

		for (const field in prevFields) {
			if (prevFields[field] !== currFields[field]) return false;
		}

		return true;
	}
}