//
// IMPORTANT!!!  These constants are exported for a reason. If you want to override a column on a local grid then use the
// constant. DO NOT hard code the magic string. https://stoic.software/effective-suitescript/magic-strings-the-anti-pattern/
//



/**
 * Standard columns for the Application Grid Experience
 */
export const enum ApplicationCols {
	applicationId = 'APPLICATION_applicationId',
	notes = 'APPLICATION_hasNotes',
	redFlag = 'APPLICATION_redFlag',
	companyName = 'APPLICATION_companyName',
	status = 'APPLICATION_status',
	applicantName = 'APPLICATION_applicantName',
	applicantEmail = 'APPLICATION_applicantEmail',
	applicantPhone = 'APPLICATION_applicantPhone',
	participantNames = 'APPLICATION_participantNames',
	companyCityAndState = "APPLICATION_companyCityAndState",
	zipCode = "APPLICATION_zipCode",
	companyType = "APPLICATION_companyType",
	industry = "APPLICATION_industry",
	offerings = "APPLICATION_offerings",
	createdDate = 'APPLICATION_createdDate',


	//
	// Application Demographics
	//
	female = 'APPLICATION_female',
	notMale = 'APPLICATION_notMale',
	bipoc = 'APPLICATION_bipoc',
	asian = 'APPLICATION_asian',
	black = 'APPLICATION_black',
	indigenous = 'APPLICATION_indigenous',
	pacificIslander = 'APPLICATION_pacificIslander',
	otherRace = 'APPLICATION_otherRace',
	white = 'APPLICATION_white',
	hispanic = 'APPLICATION_hispanic',
	immigrant = 'APPLICATION_immigrant',
	veteran = 'APPLICATION_veteran',
	spanish = 'APPLICATION_spanish',
	english = 'APPLICATION_english',
	deceased = 'APPLICATION_deceased',
}


/**
 * Standard columns for the Person Grid Experience
 */
export const enum PersonCols {
	personId = 'PERSON_personId',
	name = 'PERSON_name',
	stateCode = 'PERSON_stateCode',
	location = 'PERSON_location',
	zipCode = 'PERSON_zipCode',
	notes = 'PERSON_notes',
	phone = 'PERSON_phone',
	email = 'PERSON_email',
	firstName = 'PERSON_firstName',
	lastName = 'PERSON_lastName',
	applications = 'PERSON_applications',
	awards = 'PERSON_awards',
	language = 'PERSON_language',
}


/**
 * Standard columns for the Company Grid Experience
 */
export const enum CompanyCols {
	acc = 'COMPANY_acc',
	applications = 'COMPANY_applications',
	awards = 'COMPANY_awards',
	companyId = 'COMPANY_companyId',
	companyType = 'COMPANY_type',
	contactName = 'COMPANY_contactName',
	contactPhone = 'COMPANY_contactPhone',
	contactPhoneType = 'COMPANY_contactPhoneType',
	contactTitle = 'COMPANY_contactTitle',
	industry = 'COMPANY_industry',
	location = 'COMPANY_location',
	name = 'COMPANY_name',
	notes = 'COMPANY_notes',
	offerings = 'COMPANY_offerings',
	personId = 'COMPANY_personId',
	phone = 'COMPANY_phone',
	phoneType = 'COMPANY_phoneType',
	pic = 'COMPANY_pic',
	programs = 'COMPANY_programs',
	shortName = 'COMPANY_shortName',
	state = 'COMPANY_state',
	status = 'COMPANY_status',
	street = 'COMPANY_street',
	zipCode = 'COMPANY_zipCode',
	createdUtc = 'COMPANY_createdUtc',
	updatedUtc = 'COMPANY_updatedUtc',
}


/**
 * Standard columns for the Region Grid Experience
 */
export const enum RegionCols {
	regionId = 'REGION_regionId',
	name = 'REGION_name',
	director = 'REGION_director',
	directorEmail = 'REGION_directorEmail',
	directorPhone = 'REGION_directorPhone',
	sites = 'REGION_sites',
	siteCodes = 'REGION_sitesCodes',
	staff = 'REGION_staff',
	accs = 'REGION_accs',
	accAlumni = 'REGION_accAlumni',
	pics = 'REGION_pics',
	picParticipants = 'REGION_picParticipantBusinesses',
	awardees = 'REGION_awardees',
	awards = 'REGION_awards',
	accAwardees = 'REGION_accAwardees',
	accAwards = 'REGION_accAwards',
	picAwardees = 'REGION_picAwardees',
	picAwards = 'REGION_picAwards',
}


/**
 * Standard columns for the Site Grid Experience
 */
export const enum SiteCols {
	siteId = 'SITE_siteId',
	regionId = 'SITE_regionId',
	stateCode = 'SITE_stateCode',
	region = 'SITE_region',
	regionalDirector = 'SITE_regionalDirector',
	code = 'SITE_code',
	name = 'SITE_name',
	hidden = 'SITE_hidden',
	isActive = 'SITE_isActive',
	directorName = 'SITE_directorName',
	directorEmail = 'SITE_directorEmail',
	altDirectorName = 'SITE_altDirectorName',
	altDirectorEmail = 'SITE_altDirectorEmail',
	programManagerNames = 'SITE_programManagerNames',
	programManagerEmails = 'SITE_programManagerEmails',
	staff = 'SITE_staff',
	englishPrograms = 'SITE_englishPrograms',
	englishEmail = 'SITE_englishEmail',
	spanishPrograms = 'SITE_spanishPrograms',
	spanishEmail = 'SITE_spanishEmail',
	accs = 'SITE_accs',
	accAlumni = 'SITE_accAlumni',
	pics = 'SITE_pics',
	picParticipants = 'SITE_picParticipantBusinesses',
	awardees = 'SITE_awardees',
	awards = 'SITE_awards',
	accAwardees = 'SITE_accAwardees',
	accAwards = 'SITE_accAwards',
	picAwardees = 'SITE_picAwardees',
	picAwards = 'SITE_picAwards',
	launchDate = 'SITE_launchDate',
}

/**
 * Standard columns for the Accelerator Grid Experience
 */
export const enum AccCols {
	accId = 'ACC_accId',
	siteId = 'ACC_siteId',
	siteCode = 'ACC_siteCode',
	siteName = 'ACC_siteName',
	name = 'ACC_name',
	language = 'ACC_language',
	startUTC = 'ACC_startUTC',
	stage = 'ACC_stage',
}


/**
 * Standard columns for the Accelerator Team Grid Experience
 */
export const enum AccTeamCols {
	siteId = 'ACCTEAM_siteId',
	accId = 'ACCTEAM_accId',
	accTeamId = 'ACCTEAM_accTeamId',
	applicationId = 'ACCTEAM_applicationId',
	redFlag = 'ACCTEAM_redFlag',
	droppedOutWeek = 'ACCTEAM_droppedOutWeek',
	companyName = 'ACCTEAM_companyName',
	entrepreneurNames = 'ACCTEAM_entrepreneurNames',
	entrepreneurEmails = 'ACCTEAM_entrepreneurEmails',
	mentorNames = 'ACCTEAM_mentorNames',
	mentorEmails = 'ACCTEAM_mentorEmails',
	companyCityAndState = 'ACCTEAM_companyCityAndState',
	zipCode = 'ACCTEAM_zipCode',
	companyType = 'ACCTEAM_companyType',
	industry = 'ACCTEAM_industry',
	offerings = 'ACCTEAM_offerings',
	language = 'ACCTEAM_language',
	siteCode = 'ACCTEAM_siteCode',
}


export const enum AccSurveyResponseSessionCols {
	accSessionSurveyResponseId = 'ACC_SURVEY_RESPONSE_SESSION_accSessionSurveyResponseId',
	accSessionAttendeeId = 'ACC_SURVEY_RESPONSE_SESSION_accSessionAttendeeId',
	siteId = 'ACC_SURVEY_RESPONSE_SESSION_siteId',
	accId = 'ACC_SURVEY_RESPONSE_SESSION_accId',
	eventId = 'ACC_SURVEY_RESPONSE_SESSION_eventId',
	programManagerId = 'ACC_SURVEY_RESPONSE_SESSION_programManagerId',
	session = 'ACC_SURVEY_RESPONSE_SESSION_session',
	venue = 'ACC_SURVEY_RESPONSE_SESSION_venue',
	sessionTopics = 'ACC_SURVEY_RESPONSE_SESSION_sessionTopics',
	accessibilityBucket = 'ACC_SURVEY_RESPONSE_SESSION_accessibilityBucket',
	accessibility = 'ACC_SURVEY_RESPONSE_SESSION_accessibilityRating',
	communicationBucket = 'ACC_SURVEY_RESPONSE_SESSION_communicationBucket',
	communication = 'ACC_SURVEY_RESPONSE_SESSION_communicationRating',
	organizationBucket = 'ACC_SURVEY_RESPONSE_SESSION_organizationBucket',
	organization = 'ACC_SURVEY_RESPONSE_SESSION_organizationRating',
	other = 'ACC_SURVEY_RESPONSE_SESSION_other',
	stateCode = 'ACC_SURVEY_RESPONSE_SESSION_stateCode',
	siteCode = 'ACC_SURVEY_RESPONSE_SESSION_siteCode',
	siteName = 'ACC_SURVEY_RESPONSE_SESSION_siteName',
	accName = 'ACC_SURVEY_RESPONSE_SESSION_accName',
	accYear = 'ACC_SURVEY_RESPONSE_SESSION_accYear',
	accSeason = 'ACC_SURVEY_RESPONSE_SESSION_accSeason',
	createdUTC = 'ACC_SURVEY_RESPONSE_SESSION_createdUTC',
}


export const enum AccSurveyResponseTopicCols {
	accSessionTopicSurveyResponseId = 'ACC_SURVEY_RESPONSE_TOPIC_accSessionTopicSurveyResponseId',
	accSessionAttendeeId = 'ACC_SURVEY_RESPONSE_TOPIC_accSessionAttendeeId',
	topicId = 'ACC_SURVEY_RESPONSE_TOPIC_topicId',
	siteId = 'ACC_SURVEY_RESPONSE_TOPIC_siteId',
	accId = 'ACC_SURVEY_RESPONSE_TOPIC_accId',
	eventId = 'ACC_SURVEY_RESPONSE_TOPIC_eventId',
	programManagerId = 'ACC_SURVEY_RESPONSE_TOPIC_programManagerId',
	session = 'ACC_SURVEY_RESPONSE_TOPIC_session',
	venue = 'ACC_SURVEY_RESPONSE_TOPIC_venue',
	sessionTopics = 'ACC_SURVEY_RESPONSE_TOPIC_sessionTopics',
	topic = 'ACC_SURVEY_RESPONSE_TOPIC_topic',
	relevanceBucket = 'ACC_SURVEY_RESPONSE_TOPIC_relevanceBucket',
	relevance = 'ACC_SURVEY_RESPONSE_TOPIC_relevanceRating',
	needBucket = 'ACC_SURVEY_RESPONSE_TOPIC_needBucket',
	need = 'ACC_SURVEY_RESPONSE_TOPIC_needRating',
	understandingBucket = 'ACC_SURVEY_RESPONSE_TOPIC_understandingBucket',
	understanding = 'ACC_SURVEY_RESPONSE_TOPIC_understandingRating',
	durationBucket = 'ACC_SURVEY_RESPONSE_TOPIC_durationBucket',
	duration = 'ACC_SURVEY_RESPONSE_TOPIC_durationRating',
	other = 'ACC_SURVEY_RESPONSE_TOPIC_other',
	stateCode = 'ACC_SURVEY_RESPONSE_TOPIC_stateCode',
	siteCode = 'ACC_SURVEY_RESPONSE_TOPIC_siteCode',
	siteName = 'ACC_SURVEY_RESPONSE_TOPIC_siteName',
	accName = 'ACC_SURVEY_RESPONSE_TOPIC_accName',
	accYear = 'ACC_SURVEY_RESPONSE_TOPIC_accYear',
	accSeason = 'ACC_SURVEY_RESPONSE_TOPIC_accSeason',
	createdUTC = 'ACC_SURVEY_RESPONSE_TOPIC_createdUTC',
}


export const enum AccSurveyResponseSpecialistCols {
	accSessionSpecialistSurveyResponseId = 'ACC_SURVEY_RESPONSE_SPECIALIST_accSessionSpecialistSurveyResponseId',
	accSessionAttendeeId = 'ACC_SURVEY_RESPONSE_SPECIALIST_accSessionAttendeeId',
	topicId = 'ACC_SURVEY_RESPONSE_SPECIALIST_topicId',
	siteId = 'ACC_SURVEY_RESPONSE_SPECIALIST_siteId',
	accId = 'ACC_SURVEY_RESPONSE_SPECIALIST_accId',
	eventId = 'ACC_SURVEY_RESPONSE_SPECIALIST_eventId',
	programManagerId = 'ACC_SURVEY_RESPONSE_SPECIALIST_programManagerId',
	specialistPersonId = 'ACC_SURVEY_RESPONSE_SPECIALIST_specialistPersonId',
	session = 'ACC_SURVEY_RESPONSE_SPECIALIST_session',
	venue = 'ACC_SURVEY_RESPONSE_SPECIALIST_venue',
	sessionTopics = 'ACC_SURVEY_RESPONSE_SPECIALIST_sessionTopics',
	specialistName = 'ACC_SURVEY_RESPONSE_SPECIALIST_specialistName',
	topic = 'ACC_SURVEY_RESPONSE_SPECIALIST_topic',
	professionalismBucket = 'ACC_SURVEY_RESPONSE_SPECIALIST_professionalismBucket',
	professionalism = 'ACC_SURVEY_RESPONSE_SPECIALIST_professionalismRating',
	knowledgeBucket = 'ACC_SURVEY_RESPONSE_SPECIALIST_knowledgeBucket',
	knowledge = 'ACC_SURVEY_RESPONSE_SPECIALIST_knowledgeRating',
	effectivenessBucket = 'ACC_SURVEY_RESPONSE_SPECIALIST_effectivenessBucket',
	effectiveness = 'ACC_SURVEY_RESPONSE_SPECIALIST_effectivenessRating',
	other = 'ACC_SURVEY_RESPONSE_SPECIALIST_other',
	stateCode = 'ACC_SURVEY_RESPONSE_SPECIALIST_stateCode',
	siteCode = 'ACC_SURVEY_RESPONSE_SPECIALIST_siteCode',
	siteName = 'ACC_SURVEY_RESPONSE_SPECIALIST_siteName',
	accName = 'ACC_SURVEY_RESPONSE_SPECIALIST_accName',
	accYear = 'ACC_SURVEY_RESPONSE_SPECIALIST_accYear',
	accSeason = 'ACC_SURVEY_RESPONSE_SPECIALIST_accSeason',
	createdUTC = 'ACC_SURVEY_RESPONSE_SPECIALIST_createdUTC',
}



export const enum ZipCols {
	zipId = 'ZIP_zipId',
	cityId = 'ZIP_cityId',
	cityAndState = 'ZIP_cityAndState',
	zipCode = 'ZIP_zipCode',
	city = 'ZIP_city',
	state = 'ZIP_state',
	longitude = 'ZIP_longitude',
	latitude = 'ZIP_latitude',
	enSite = 'ZIP_enSite',
	esSite = 'ZIP_esSite',
	timeZone = 'ZIP_timeZone',
}

/**
 * Standard columns for the Pitch Contest Grid Experience
 */
export const enum PicCols {
	picId = 'PIC_picId',
	siteId = 'PIC_siteId',
	siteCode6 = 'PIC_siteCode6',
	siteCode = 'PIC_siteCode',
	siteName = 'PIC_siteName',
	name = 'PIC_name',
	language = 'PIC_language',
	stage = 'PIC_stage',
	applStartUTC = 'PIC_applStartUTC',
}
