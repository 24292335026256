<div *ngIf='icons' class="icon-bar-part" [class.horizontalIconBar]='orientation==="Horizontal"'
	[class.verticalIconBar]='orientation==="Vertical"' [style.width]='getWidthExtent()'
	[style.height]='getHeightExtent()'>

	<button class="btn toggle-button" [class.btn-green]="noneSelected" (click)="toggleAll()">
		<ng-container *ngIf="allSelected">None</ng-container>
		<ng-container *ngIf="!allSelected">All</ng-container>
	</button>

	<div class="icon-bar-icons">
		<kendo-buttongroup>
			<button *ngFor='let icon of wrappedIcons' kendoButton kendoTooltip [toggleable]="true"
				[title]='icon.tooltip' class="btn" [selected]="icon.selected && !icon.disabled"
				[iconClass]="icon.iconClass" (selectedChange)='onSelectedChange(icon, $event)' longPress
				(longPress)="onLongPress(icon, $event)" [attr.disabled]="icon.disabled"></button>
		</kendo-buttongroup>
	</div>
</div>