<div class='edit-venue-part'>
	<table style='width:100%' class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>

		<tr>
			<td>Displayed Name</td>
			<td>
				<input kendoTextBox [value]='orgValues.displayedName' (input)="onInput('displayedName',$event)"
					(blur)='onBlur()' [placeholder]="" />
			</td>
		</tr>

		<tr>
			<td>Public Notes</td>
			<td>
				<textarea kendoTextArea [value]='orgValues.publicNotes' style="resize:none;" [placeholder]=""
					(valueChange)="valueChange('publicNotes',$event)" (blur)='onBlur()'></textarea>
			</td>
		</tr>

		<tr>
			<td>Internal Notes</td>
			<td>
				<textarea kendoTextArea [value]='orgValues.internalNotes' style="resize:none;" [placeholder]=""
					(valueChange)="valueChange('internalNotes',$event)" (blur)='onBlur()'></textarea>
			</td>
		</tr>
	</table>
	<button class="btn" [disabled]="!changedSomething" (click)="updateVenue()">Update</button>
</div>