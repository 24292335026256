import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DbsEntity, EntityNote, NoteCategoryId } from '@me-interfaces';
import { OldEntityService } from '@me-services/old-services-and-wrappers/entity';
import { EntityNotesWrapper } from '@me-services/old-services-and-wrappers/entity/notes-wrapper';
import { MainSelector } from '@me-shared-parts/UI-common';

export const ENTITY_NOTE_KEY_PREFIX = 'entity-notes-editor-key-';

interface NoteSelector extends MainSelector {
	icon: string,
	entityNote: EntityNote,
}

@Component({
	selector: 'entity-notes-editor',
	templateUrl: './SHR-ED_entity-notes-editor.part.html',
	styleUrls: ['SHR-ED_entity-notes-editor.part.scss'],
})
export class EntityNotesEditorPart implements OnInit {

	@Input() entity: DbsEntity;
	@Input() initialNoteId?: number;
	
	entityNotes: EntityNote[] = [];
	key = `${ENTITY_NOTE_KEY_PREFIX}overview`;
	notesWrapper: EntityNotesWrapper;
	notesSelectors: NoteSelector[] = [];

	constructor(public entityService: OldEntityService) { }

	public async ngOnInit() {
		this.notesWrapper = this.entityService.wrapNotes(this.entity.entityId, this.entityNotes);
		await this.notesWrapper.getCurrentNotes();
		this.notesChanged(this.notesWrapper.currentNotes);
		if (this.initialNoteId && !!this.notesSelectors.find(note => note.key == `${ENTITY_NOTE_KEY_PREFIX}${this.initialNoteId}`)) {
			this.key = `${ENTITY_NOTE_KEY_PREFIX}${this.initialNoteId}`;
		}
	}

	initializeSelectors() {
		this.notesSelectors = [{
			label: 'Overview',
			key: `${ENTITY_NOTE_KEY_PREFIX}overview`,
			locked: false,
			icon: undefined,
			entityNote: undefined,
		}, {
			label: 'Add New',
			key: `${ENTITY_NOTE_KEY_PREFIX}add`,
			locked: false,
			icon: 'fas fa-plus-circle fa-fw',
			entityNote: undefined,
		}];
	}

	buildSelectors() {
		for (const entityNote of this.entityNotes) {
			this.notesSelectors.push({
				label: entityNote.note.subject,
				key: `${ENTITY_NOTE_KEY_PREFIX}${entityNote.noteId}`,
				locked: false,
				icon: getNoteClass(entityNote),
				entityNote: entityNote,
			});
		}
	}

	setSelectedKey(e) {
		this.key = e;
	}

	async notesChanged(notes: EntityNote[]) {
		this.entityNotes = notes;
		this.initializeSelectors();
		this.buildSelectors();
	}
}

export function getNoteClass(entityNote: EntityNote): string {
	if (entityNote.note.noteCategoryId == NoteCategoryId.RedFlag) return 'red fas fa-flag fa-fw';
	else return 'far fa-sticky-note fa-fw';
}