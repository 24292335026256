import { EventEmitter } from "@angular/core";
import { DbConceptName, DbsEvent } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { ShowEventDialogService } from "@me-shared-parts/ED-editors";
import { Icon } from "@me-shared-parts/UI-common";
import { EXPLORE_GRID_ACTION_KEY, GridColumnType, GridSetup, GRID_EXPERIENCE, OPEN_GRID_ACTION_KEY } from "../interfaces";
import { GridExperience } from "./grid-experience";

//
// Column keys
//
const EVENT_ID = 'EVENT_eventId';
const EVENT_EVENTTYPE = 'EVENT_eventType';
const EVENT_STATUS = 'EVENT_status';
const EVENT_STARTUTC = 'EVENT_startUTC';
const EVENT_ENDUTC = 'EVENT_endUTC';
const EVENT_TOOLSTARTUTC = 'EVENT_toolStartUTC';
const EVENT_TOOLENDUTC = 'EVENT_toolEndUTC';
const EVENT_EVENTDURATION = 'EVENT_eventDuration';
const EVENT_VENUENAME = 'EVENT_venueName';
const EVENT_HOSTNAME = 'EVENT_hostName';


/**
 * Standard experience for a grid of events
 */
export class EventGridExperience<ROW> extends GridExperience<ROW> {

	public static readonly experience: GRID_EXPERIENCE = 'EVENT';

	constructor(
		private ds: DataService,
		private util: UtilityService,
		setup: GridSetup<ROW>,
		gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
		private showEventDialogService: ShowEventDialogService,
	) {
		super(
			util, setup,
			[
				{ field: EVENT_ID, header: 'eventId', width: 90, type: GridColumnType.number, hidden: true },
			],
			[
				{ field: EVENT_STATUS, header: 'Status', width: 100, type: GridColumnType.text, hidden: true },
				{ field: EVENT_EVENTTYPE, header: 'Event Type', width: 200, type: GridColumnType.text, hidden: true },
				{ field: EVENT_STARTUTC, header: 'Start', width: 150, type: GridColumnType.dateAndTimeUtc },
				{ field: EVENT_ENDUTC, header: 'End', width: 150, type: GridColumnType.dateAndTimeUtc, hidden: true },
				{ field: EVENT_TOOLSTARTUTC, header: 'Tool Start', width: 150, type: GridColumnType.dateAndTimeUtc },
				{ field: EVENT_TOOLENDUTC, header: 'Tool End', width: 150, type: GridColumnType.dateAndTimeUtc },
				{ field: EVENT_VENUENAME, header: 'Venue', width: 250, type: GridColumnType.text },
			],
			[
				{ field: EVENT_EVENTDURATION, header: 'Duration', width: 100, type: GridColumnType.text, hidden: true },
				{ field: EVENT_HOSTNAME, header: 'Host', width: 200, type: GridColumnType.text, hidden: true },
			],
			[
				{ key: EXPLORE_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Explore ${setup.rowSingularName}` },
				{ key: OPEN_GRID_ACTION_KEY, icon: Icon.action_open, label: `Open ${setup.rowSingularName}` },
				// { key: NOTES_GRID_ACTION_KEY, icon: Icon.action_editNotes, label: `Edit Event Notes` },
			],
			gridAction,
			Icon.concept_event,
		);
	}


	public async applyBaseValues(rows: ROW[]) {

		const events = await this.ds.admin.event.getManyAsArray(rows.map(row => row['eventId']));
		const eventMap = this.util.array.toMap(events, event => event.eventId);
		const venueMap = await this.ds.admin.venue.getManyAsMap(events.filter(event => event.venueId).map(event => event.venueId));
		const peopleMap = await this.ds.admin.person.getManyAsMap(events.filter(event => event.hostPersonId).map(event => event.hostPersonId));
		const eventTypes: { [index: number]: string } = this.ds.domain.eventType.getAll().reduce((a, eventType) => { a[eventType.eventTypeId] = eventType.name; return a; }, {});

		for (const row of rows) {
			const eventId = row['eventId'];
			const event = eventMap[eventId];

			row[EVENT_ID] = event.eventId;
			row[EVENT_EVENTTYPE] = eventTypes[event.eventTypeId];
			row[EVENT_STATUS] = this.util.event.getStatus(event);
			row[EVENT_STARTUTC] = event.startUTC;
			row[EVENT_ENDUTC] = event.endUTC;
			row[EVENT_TOOLSTARTUTC] = event.toolStartUTC;
			row[EVENT_TOOLENDUTC] = event.toolEndUTC;
			row[EVENT_EVENTDURATION] = this.util.date.calculateTimePeriod(event.startUTC, event.endUTC);
			row[EVENT_VENUENAME] = event.venueId ? venueMap[event.venueId].displayedName || 'Online' : 'Online';
			row[EVENT_HOSTNAME] = event.hostPersonId ? peopleMap[event.hostPersonId]._name : undefined;
			row['dbsEvent'] = event;
		}
	}


	public sortRows(rows: ROW[]) {
		rows.sort((row1: ROW, row2: ROW) => {
			const val1 = row1[EVENT_STARTUTC];
			const val2 = row2[EVENT_STARTUTC];
			return val1 < val2 ? -1 : 1;
		});
	}


	public async explore(row: ROW) {
		await this.ds.explorer.explore(DbConceptName.Event, row[EVENT_ID]);
	}


	public async open(row: ROW) {

		const teamsForAward = [];
		const event: DbsEvent = row['dbsEvent'];

		if (this.ds.domain.awardNameEventType.getAll().find(a => a.eventTypeId == event.eventTypeId)) {
			if (event.accId) {

				const accTeamsByAccId = await this.ds.admin.accTeam.getByAccIds([event.accId]);
				const accTeams = accTeamsByAccId[event.accId];

				for (const accTeam of accTeams) {
					const company = accTeam.application.company;
					teamsForAward.push({ teamId: accTeam.accTeamId, text: company.longName, type: 'accTeam' });
				}
			}
			else if (event.picId) {

				const picTeamsByPicId = await this.ds.admin.picTeam.getByPicIds([event.picId]);
				const picTeams = picTeamsByPicId[event.picId];

				for (const picTeam of picTeams) {
					const company = picTeam.application.company;
					teamsForAward.push({ teamId: picTeam.picTeamId, text: company.longName, type: 'picTeam' });
				}
			}
		}
		await this.showEventDialogService.edit(event, false, teamsForAward);
	}


	public async editNotes(row: ROW) {
		// Notes dialog not implemented
	}
}