import { Component, Input } from '@angular/core';
import { AccMatchingRollups_old } from '@me-interfaces';

const DEFAULT_THRESHOLDS = [50, 100];
@Component({
	selector: 'overlap-rollups',
	templateUrl: './SHR-CMN_overlap-rollups.page.html',
	styleUrls: ['SHR-CMN_overlap-rollups.page.scss'],
})
export class OverlapRollupsPart {

	@Input() rollups: AccMatchingRollups_old;
	@Input() thresholds = DEFAULT_THRESHOLDS;
	@Input() oppositeRole;

}
