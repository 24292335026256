import { Component, Input } from '@angular/core';
import { ExplorerTagBox } from './explorer-tag-box';

@Component({
	selector: 'explorer-tag-box',
	templateUrl: './explorer-tag-box.part.html',
	styleUrls: ['./explorer-tag-box.part.scss'],
})
export class ExplorerTagBoxPart {

	@Input() public box: ExplorerTagBox;

}