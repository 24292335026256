<field-chrome [basePart]='basePart' (fieldClick)='dateField.focus()'>

	<div class='input-wrapper'>
		<kendo-datepicker #dateField [value]='currentValue' (focus)='setFocused(true)' (blur)='setFocused(false)'
			[disabled]='status === "saving"' [class.has-error]='!!errorText' [style.max-width]="maxWidth + 'em'"
			[min]='translateValueToDate(field.minValue)' [max]='translateValueToDate(field.maxValue)'
			(valueChange)="onValueChange($event)">
		</kendo-datepicker>
	</div>

</field-chrome>