import { Injectable } from '@angular/core';
import { MenuService } from '@me-services/ui/menu/menu.service';
import { BehaviorSubject, combineLatest, fromEvent } from 'rxjs';
import { distinctUntilChanged, map, share, startWith } from 'rxjs/operators';
import { LayoutDimensions } from '.';
import {
	BREADCRUMB_HEIGHT,
	BREAKPOINT_LG,
	BREAKPOINT_MD,
	BREAKPOINT_SM,
	MENU_WIDTH_AT_BREAKPOINT_SM,
	NAVBAR_HEIGHT,
	NAVBAR_SPACING,
	NAVBAR_SPACING_XS,
	NAV_TABS_HEIGHT,
	PAGE_TABS_LEFT_AND_RIGHT_PADDING,
	PAGE_TABS_TAB_HEIGHT,
	PAGE_TABS_TOP_AND_BOTTOM_PADDING,
	SPACING,
	SPACING_XS
} from './layout-constants';


export interface AutoNavTabs {
	hasMinWidth?: boolean,
	hasMinHeight?: boolean,
	hasMultipleTabs?: boolean,
}


@Injectable({ providedIn: 'root' })
export class LayoutService {

	/**
	 * The dimensions$ observable emits all of the various layout
	 * calculations every time the window is resized or the menu
	 * is opened/closed.
	 */
	public dimensions$ = new BehaviorSubject<LayoutDimensions>(
		this.calcDimensions(window, this.menuService.calcMenuWidth(window), true)
	);


	/**
	 * By default, the navigation tabs will automatically hide on small
	 * devices (not wide enough) or if there is only a single tab. However,
	 * the folder icon under th e user menu can be used to force the tabs
	 * to be shown or hidden. Resizing the screen forces the visibility
	 * back to 'Auto'. Set to 'Hide' to make more vertical room above the
	 * fold.
	 */
	public showNavTabsVisibility$ = new BehaviorSubject<'Show' | 'Hide' | 'Auto'>('Auto');


	/**
	 * If showNavTabsVisibility$ is 'Auto' then showNavTabsAuto$ will
	 * determine whether the navigation tabs are shown or hidden.
	 */
	private showNavTabsAuto$ = new BehaviorSubject<AutoNavTabs>({ hasMinHeight: true, hasMinWidth: true, hasMultipleTabs: true });


	/**
	 * Determines if the nav tabs should be visible if
	 * showNavTabsVisibility$ == 'Auto 
	 */
	public showNavTabsAuto(value: AutoNavTabs) {
		const current = this.showNavTabsAuto$.value;

		this.showNavTabsAuto$.next({
			hasMinHeight: value.hasMinHeight ?? current.hasMinHeight,
			hasMinWidth: value.hasMinWidth ?? current.hasMinWidth,
			hasMultipleTabs: value.hasMultipleTabs ?? current.hasMultipleTabs,
		});
	}


	/**
	 * The showNavTabs$ observable defines whether the last breadcrumbs menu
	 * is displayed as navigation tabs directly under the breadcrumbs. 
	 */
	public showNavTabs$ = combineLatest([this.showNavTabsVisibility$, this.showNavTabsAuto$])
		.pipe(
			map(([visibility, auto]) => {
				if (visibility == 'Show') return true;
				else if (visibility == 'Hide') return false;
				else return auto.hasMinHeight && auto.hasMinWidth && auto.hasMultipleTabs;
			}),
			distinctUntilChanged(),
		);



	constructor(private menuService: MenuService) {

		//
		// Listen for the window resizing
		//
		const resize$ = fromEvent(window, 'resize')
			.pipe(
				startWith({ target: window }),
			);

		resize$.subscribe((e) => {
			this.showNavTabsVisibility$.next('Auto');
			this.showNavTabsAuto({
				hasMinHeight: (e.target as Window).innerHeight > 500,
				hasMinWidth: (e.target as Window).innerWidth > BREAKPOINT_MD,
			})
		});


		//
		// Every time the window resizes or the menu width is shown,
		// calculate and emit the new dimensions.
		//
		combineLatest([resize$, menuService.menuWidth$, this.showNavTabs$])
			.pipe(
				map(([e, menuWidth, showNavTabs]) => this.calcDimensions(e.target as Window, menuWidth, showNavTabs)),
				share()
			)
			.subscribe(d => this.dimensions$.next(d));
	}


	/**
	 * Calculate all of the layout sizes. This is called each time the window
	 * is resized of the menu is opened/closed.
	 * @param window The DOM Window object
	 * @param menuWidth The width of the left menu which may be full, collapsed, or hidden.
	 */
	private calcDimensions(window: Window, menuWidth: number, showNavTabs: boolean): LayoutDimensions {

		//
		// The inner values are the browser window size 
		//
		const w = window.innerWidth;
		const h = window.innerHeight;

		//
		// Determine what breakpoint range the window width equates to
		//
		let breakpoint: 'xs' | 'sm' | 'md' | 'lg' = 'xs';

		if (w < BREAKPOINT_SM) breakpoint = 'xs';
		else if (w < BREAKPOINT_MD) breakpoint = 'sm';
		else if (w < BREAKPOINT_LG) breakpoint = 'md';
		else breakpoint = 'lg';

		//
		// These calculations should align with what is in ACS.page.scss
		//
		let d: LayoutDimensions = {
			menuWidth,
			breakpoint,
			screenWidth: window.outerWidth,
			screenHeight: window.outerHeight,
			windowWidth: w,
			windowHeight: h,
			contentWidth: w - 2 * SPACING_XS - 1,	// xs (0 - 543)
			contentHeight: h - NAVBAR_HEIGHT - 3 - NAVBAR_SPACING_XS - BREADCRUMB_HEIGHT, //xs (0 - 543)
			contentWidthSnugXS: 0,
			contentHeightSnugXS: 0,
			pageTabContentWidth: 0,
			pageTabContentHeight: 0,
		};


		if (breakpoint == 'sm') { //544 - 767
			d = Object.assign(d, {
				contentWidth: w - MENU_WIDTH_AT_BREAKPOINT_SM - 2 * SPACING - 1,
				contentHeight: h - NAVBAR_HEIGHT - 3 - NAVBAR_SPACING - BREADCRUMB_HEIGHT,
			});
		}
		else if (breakpoint == 'md') { //768 - 1279
			d = Object.assign(d, {
				contentWidth: w - menuWidth - 2 * SPACING - 1,
				contentHeight: h - NAVBAR_HEIGHT - 3 - NAVBAR_SPACING - BREADCRUMB_HEIGHT,
			});
		}

		else if (breakpoint == 'lg') { //1280+
			d = Object.assign(d, {
				contentWidth: w - menuWidth - 2 * SPACING - 1,
				contentHeight: h - NAVBAR_HEIGHT - NAVBAR_SPACING - BREADCRUMB_HEIGHT - SPACING,
			});
		}

		if (showNavTabs) d.contentHeight -= NAV_TABS_HEIGHT;

		d.pageTabContentWidth = d.contentWidth - PAGE_TABS_LEFT_AND_RIGHT_PADDING;
		d.pageTabContentHeight = d.contentHeight - PAGE_TABS_TAB_HEIGHT - 2 * PAGE_TABS_TOP_AND_BOTTOM_PADDING;

		const spacing = breakpoint == 'xs' ? SPACING_XS : SPACING;

		if (breakpoint == 'xs') {
			d.contentWidthSnugXS = d.contentWidth + 2 * spacing;
			d.contentHeightSnugXS = d.contentHeight + spacing - 7;
		}
		else {
			d.contentWidthSnugXS = d.contentWidth;
			d.contentHeightSnugXS = d.contentHeight;
		}

		return d;
	}


	/**
	 * Determines if a dom element is scrollable. Use @ViewChild(...) to get an ElementRef
	 * and then pass in the ElementRef.nativeElement property to this function. 
	 */
	isScrollable(el) {

		// The scrollTop() method sets or returns the vertical
		// scrollbar position for the selected elements
		const y1 = el.scrollTop;
		el.scrollTop += 1;
		const y2 = el.scrollTop;
		el.scrollTop -= 1;
		const y3 = el.scrollTop;
		el.scrollTop = y1;

		// The scrollLeft() method sets or returns the horizontal  
		// scrollbar position for the selected elements
		const x1 = el.scrollLeft;
		el.scrollLeft += 1;
		const x2 = el.scrollLeft;
		el.scrollLeft -= 1;
		const x3 = el.scrollLeft;
		el.scrollLeft = x1;

		return {
			horizontallyScrollable: x1 !== x2 || x2 !== x3,
			verticallyScrollable: y1 !== y2 || y2 !== y3
		}
	}


	public currentDialogSize = {
		width: 0,
		height: 0,
	};
}