import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';
import { DdAccMeetingTimesRow } from './dd-acc-meeting-times-row';

/**
 * @deprecated Use AccMeetingTimes from the DataService instead
 */
export class DdAccMeetingTimes extends DdObject<DdAccMeetingTimesRow> implements DdAccMeetingTimesRow {

	constructor(dd: OldDomainDataService, data: DdAccMeetingTimesRow) {
		super(data);
	}
	public get accMeetingTimesId(): number {
		return this.data.accMeetingTimesId;
	}
	public get description(): string {
		return this.data.description;
	}
	public get mon(): number {
		return this.data.mon;
	}
	public get tue(): number {
		return this.data.tue;
	}
	public get wed(): number {
		return this.data.wed;
	}
	public get thur(): number {
		return this.data.thur;
	}
	public get fri(): number {
		return this.data.fri;
	}
	public get sat(): number {
		return this.data.sat;
	}
	public get sun(): number {
		return this.data.sun;
	}
}