import { Person, PhoneTypeId } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { LinkOrExplorable } from "../../link-or-explorerable";

export async function getButtons(ds: DataService, person: Person): Promise<LinkOrExplorable[]> {


	const dbsPerson = person.asSingleton;

	const buttons: LinkOrExplorable[] = [];


	//
	// Primary email
	//
	buttons.push({ link: { linkType: 'email', email: person.emails[0].email } });

	//
	// Phone
	//
	if (dbsPerson.phone) {
		buttons.push({ link: { linkType: 'call', phone: dbsPerson.phone } });

		if (dbsPerson.phoneTypeId == PhoneTypeId.Mobile) {
			buttons.push({ link: { linkType: 'sms', phone: dbsPerson.phone } });
		}
	}

	//
	// Weblinks
	//
	const webLinksByEntityId = await ds.admin.webLink.getArraysByForeignIds('entityId', [dbsPerson.entityId]);
	const webLinks = webLinksByEntityId[dbsPerson.entityId];
	const webLinksOrderByType = webLinks.sort((a, b) => a.webLinkTypeId - b.webLinkTypeId);

	for (const webLink of webLinksOrderByType) {
		buttons.push({ link: { linkType: 'webLink', url: webLink.url, webLinkTypeId: webLink.webLinkTypeId } });
	}

	//
	// Address
	//
	if (dbsPerson.inUS && dbsPerson.zipId) {
		buttons.push({ link: { linkType: 'address', street: dbsPerson.address, zipId: dbsPerson.zipId } });
	}

	// dbsPerson.emails
	// dbsPerson.formerName
	// dbsPerson.preferredName
	// dbsPerson.prefixId
	// dbsPerson.suffixId
	// created
	// dbsPerson.updatedByPersonId
	// dbsPerson.updatedUTC

	// accessFlags

	return buttons;
}