import { AccStageId, AccTeam, Accelerator, Application, ApplicationStatusId, Award, AwardKindId, AwardNameId, Event, EventTypeId, LanguageId } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";

export type AccStageErrorCategory
	= 'Accelerator Not Closed'
	| 'Applications Still Pending'
	| 'Missing Awards'
	| 'Missed Deadline'
	| 'Missing Events'
	| 'Missing Notes'
	| 'Unexpected Teams';


/**
 * Defines an accelerator's bad state as compared to it's current stage.
 * 
 * Some things must be completed before advancing to the next stage.
 * e.g. The maximum stage that allows applications in ReadPending is AccStageId.Reading
 * 
 * Some things are not allowed until we are at a particular stage.
 * e.g. The minimum stage that there should be teams is AccStageId.SelectingCohort
 * 
 */
export interface AccStageError {

	level: 'Critical' | 'High',

	/**
	 * Whether the error represents the acc is Beyond where it should be which could
	 * be due to things not cleaned up, or if it isn't at a stage it should be at (Before).
	 */
	logic: 'Before' | 'At-Or-Before' | 'At-Or-Beyond' | 'Beyond',

	/**
	 * The stage in which the error is related. The logic defines the direction used.
	 */
	accStageId: AccStageId,

	/**
	 * A description of the error, in English, suitable to be displayed to staff.
	 */
	message: string,

	/**
	 * The type of problem
	 */
	category: AccStageErrorCategory,
}



/**
 * Calculate the POSSIBLE errors for one accelerator. Note that this list of errors must be filtered
 * using the actual AccStageId to only show the ones that are relevant.
 */
export function getAccStageErrors(
	util: UtilityService,
	acc: Accelerator,
	applications: readonly Application[],
	teams: readonly AccTeam[],
	events: readonly Event[],
	awards: readonly Award[],
): AccStageError[] {

	const accStageId = acc.accStageId;
	const errors: AccStageError[] = [];
	const now = Date.now() / 1000;

	if (awards == undefined) awards = [];


	//
	// Check for applications left pending
	//

	const submitPendingCount = applications.filter(a => a.applicationStatusId == ApplicationStatusId.AcceptPending).length;
	if (submitPendingCount) {
		const message = submitPendingCount == 1 ? `1 application is still Accept Pending` : `${submitPendingCount} applications are still Accept Pending`;
		errors.push({
			level: 'High',
			logic: 'Beyond',
			accStageId: AccStageId.Accepting,
			message,
			category: 'Applications Still Pending',
		});
	}


	const readPendingCount = applications.filter(a => a.applicationStatusId == ApplicationStatusId.ReadPending).length;
	if (readPendingCount) {
		const message = readPendingCount == 1 ? `1 application is still Read Pending` : `${readPendingCount} applications are still Read Pending`;
		errors.push({
			level: 'High',
			logic: 'Beyond',
			accStageId: AccStageId.Reading,
			message,
			category: 'Applications Still Pending',
		});
	}


	const interviewPendingCount = applications.filter(a => a.applicationStatusId == ApplicationStatusId.InterviewPending).length;
	if (interviewPendingCount) {
		const message = interviewPendingCount == 1 ? `1 application is still Interview Pending` : `${interviewPendingCount} applications are still Interview Pending`;
		errors.push({
			level: 'High',
			logic: 'Beyond',
			accStageId: AccStageId.Interviewing,
			message,
			category: 'Applications Still Pending',
		});
	}


	const selectPendingCount = applications.filter(a => a.applicationStatusId == ApplicationStatusId.SelectPending).length;
	if (selectPendingCount) {
		const message = selectPendingCount == 1 ? `1 application is still Select Pending` : `${selectPendingCount} applications are still Select Pending`;
		errors.push({
			level: 'High',
			logic: 'Beyond',
			accStageId: AccStageId.SelectingCohort,
			message,
			category: 'Applications Still Pending',
		});
	}


	//
	// Check if dates have passed but the stage wasn't advanced
	//

	if (now > acc.applStartUTC) {
		const startDate = util.date.formatDate(new Date(acc.applStartUTC * 1000), 'MMM D, YYYY (DOW)', LanguageId.English);
		errors.push({
			level: 'High',
			logic: 'Before',
			accStageId: AccStageId.Accepting,
			message: `The date to start accepting applications is ${startDate} which is in the past`,
			category: 'Missed Deadline',
		});
	}


	if (now > acc.applStartUTC + acc.applDuration * 60) {
		const dueDate = util.date.formatDate(new Date((acc.applStartUTC + acc.applDuration * 60) * 1000), 'MMM D, YYYY (DOW)', LanguageId.English);
		errors.push({
			level: 'High',
			logic: 'Before',
			accStageId: AccStageId.Reading,
			message: `The applications due date is ${dueDate} which is in the past`,
			category: 'Missed Deadline',
		});
	}


	if (now > acc.startUTC) {
		const startDate = util.date.formatDate(new Date(acc.startUTC * 1000), 'MMM D, YYYY (DOW)', LanguageId.English);
		errors.push({
			level: 'High',
			logic: 'Before',
			accStageId: AccStageId.Curriculum,
			message: `The accelerator start date of ${startDate} is in the past`,
			category: 'Missed Deadline',
		});
	}


	//
	// Check if there are teams created but the stage is not yet SelectingCohort
	//

	if (teams.length) {
		errors.push({
			level: 'High',
			logic: 'Before',
			accStageId: AccStageId.SelectingCohort,
			message: `Teams have been added to the cohort`,
			category: 'Unexpected Teams',
		});
	}


	//
	// Check for teams that dropped out that don't have a note
	//

	if (acc.year >= 2023) {

		const droppedOutTeamsWithNoNote = teams.filter(team => team.droppedOutWeek && !(team.notes?.length));
		if (droppedOutTeamsWithNoNote.length) {
			const message = droppedOutTeamsWithNoNote.length == 1 ? `1 team dropped out without a team note` : `${droppedOutTeamsWithNoNote.length} teams dropped out without team notes`;
			errors.push({
				level: 'Critical',
				logic: 'At-Or-Beyond',
				accStageId: AccStageId.SelectingCohort,
				message,
				category: 'Missing Notes',
			});
		}
	}


	//
	// Check if place awards were recorded
	//

	if (!awards.find(award => award.awardNameId == AwardNameId.Place1Award)) {
		errors.push({
			level: 'Critical',
			logic: 'Beyond',
			accStageId: AccStageId.SelectingWinners,
			message: `1st place award not recorded`,
			category: 'Missing Awards',
		});
	}

	if (!awards.find(award => award.awardNameId == AwardNameId.Place2Award)) {
		errors.push({
			level: 'Critical',
			logic: 'Beyond',
			accStageId: AccStageId.SelectingWinners,
			message: `2nd place award not recorded`,
			category: 'Missing Awards',
		});
	}

	if (!awards.find(award => award.awardNameId == AwardNameId.Place3Award)) {
		errors.push({
			level: 'Critical',
			logic: 'Beyond',
			accStageId: AccStageId.SelectingWinners,
			message: `3rd place award not recorded`,
			category: 'Missing Awards',
		});
	}


	//
	// Check if for sponsor or in-kind awards without notes
	//

	if (acc.year >= 2023) {

		const awardsFromSponsorWithNoNotes = awards.filter(award => award.awardNameId == AwardNameId.AwardFromSponsor && !(award.notes?.length));
		if (awardsFromSponsorWithNoNotes.length) {
			const message = awardsFromSponsorWithNoNotes.length == 1 ? `1 "Sponsor" award does not have a note` : `${awardsFromSponsorWithNoNotes.length} "Sponsor" awards do not have notes`;
			errors.push({
				level: 'High',
				logic: 'At-Or-Beyond',
				accStageId: AccStageId.SelectingWinners,
				message,
				category: 'Missing Notes',
			});
		}

		const awardsInKindWithNoNotes = awards.filter(award => award.awardKindId == AwardKindId.InKindServices && !(award.notes?.length));
		if (awardsInKindWithNoNotes.length) {
			const message = awardsInKindWithNoNotes.length == 1 ? `1 "In-Kind Services" award does not have a note` : `${awardsInKindWithNoNotes.length} "In-Kind Services" awards do not have notes`;
			errors.push({
				level: 'High',
				logic: 'At-Or-Beyond',
				accStageId: AccStageId.SelectingWinners,
				message,
				category: 'Missing Notes',
			});
		}
	}


	//
	// Check if quarterly events exist
	//
	const quartersWithoutEvent: string[] = [];
	const q2Event = events.find(event => event.eventTypeId == EventTypeId.AccQuarterliesQ2);
	const q3Event = events.find(event => event.eventTypeId == EventTypeId.AccQuarterliesQ3);
	const q4Event = events.find(event => event.eventTypeId == EventTypeId.AccQuarterliesQ4);

	if (!q2Event) quartersWithoutEvent.push('Q2');
	if (!q3Event) quartersWithoutEvent.push('Q3');
	if (!q4Event) quartersWithoutEvent.push('Q4');

	if (quartersWithoutEvent.length) errors.push({
		level: 'High',
		logic: 'At-Or-Beyond',
		accStageId: AccStageId.Quarterlies,
		message: `No ${quartersWithoutEvent.join(', ')} quarterly event` + (quartersWithoutEvent.length > 1 ? 's' : ''),
		category: 'Missing Events',
	});


	//
	// Check if quarterly awards recorded
	//
	const quartersWithoutAward: string[] = [];
	if (!q2Event || (now > q2Event.endUTC && !(q2Event.awards?.length))) quartersWithoutAward.push('Q2');
	if (!q3Event || (now > q3Event.endUTC && !(q3Event.awards?.length))) quartersWithoutAward.push('Q3');
	if (!q4Event || (now > q4Event.endUTC && !(q4Event.awards?.length))) quartersWithoutAward.push('Q4');

	if (quartersWithoutAward.length) errors.push({
		level: 'Critical', logic: 'At-Or-Beyond', accStageId: AccStageId.Quarterlies,
		message: `No ${quartersWithoutAward.join(', ')} quarterly awards recorded`,
		category: 'Missing Awards',
	});


	//
	// Check if Acc is more than a year old but not marked complete
	//
	const SECONDS_IN_365_DAYS = 365 * 24 * 60 * 60;

	if (now > (acc.startUTC + SECONDS_IN_365_DAYS) && acc.accStageId != AccStageId.Complete) {
		errors.push({
			level: 'Critical',
			logic: 'Before',
			accStageId: AccStageId.Complete,
			message: `Accelerator is more than a year old but not marked complete`,
			category: 'Accelerator Not Closed',
		});
	}


	return errors;
}