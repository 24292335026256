import { SiteAreaData, SiteAreaService, SiteHearReportDetailsDialog } from '@SITE-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridColumnType, GridSetup, GridValueStyle, PersonCols } from '@me-grid';
import { DbConceptName } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { Icon } from '@me-shared-parts/UI-common';
import { Observable, combineLatest, mergeMap, of } from 'rxjs';
import { SiteDashboardPageService } from '../../site-overview-page.service';


export interface HearReportRow {
	personId: number,
	applicationId: number,
	applicantName: string,
	companyId: number,
	companyName: string,
	accId?: number,
	picId?: number,
	answer: string,
	programName: string,
	type: 'Accelerator' | 'Pitch Contest',
	city: string,
}

@Component({
	selector: 'hear-report-view-part',
	templateUrl: './hear-report.part.html',
	styleUrls: ['./hear-report.part.scss'],
})
export class SiteHearReportPart extends DestroyablePart implements OnInit {


	public gridSetup = this.setupGrid();

	public rows$: Observable<HearReportRow[]> = of([]);

	constructor(
		private ds: DataService,
		private util: UtilityService,
		public siteAreaService: SiteAreaService,
		private pageService: SiteDashboardPageService,
	) {
		super();
	}


	async ngOnInit() {

		super.initDestroyable();

		this.rows$ = combineLatest([
			this.ds.admin.singletonsAsOfUTC$,
			this.siteAreaService.data$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1])));
	}

	async buildRows(
		singletonsAsOfUTC: number,
		data: SiteAreaData,
		// applicantHearReport: SiteHearReport[],
	) {

		if (!data) return [];

		const siteId = data.site.siteId;

		const accsBySiteId = await this.ds.admin.accelerator.getBySiteIds([siteId]);
		const accs = accsBySiteId[siteId];
		const accMap = this.util.array.toMap(accs, acc => acc.accId);

		const picsBySiteId = await this.ds.admin.pitchContest.getBySiteIds([siteId]);
		const pics = picsBySiteId[siteId];
		const picMap = this.util.array.toMap(pics, pic => pic.picId);

		const peopleMap = await this.ds.admin.person.getManyPackagesAsMap(data.applicantHearReport.map(app => app.applicantPersonId));
		const companyMap = await this.ds.admin.company.getManyPackagesAsMap(data.applicantHearReport.map(app => app.companyId));

		const rows: HearReportRow[] = data.applicantHearReport.map(r => {

			const acc = r.accId ? accMap[r.accId] : undefined;
			const pic = r.picId ? picMap[r.picId] : undefined;
			if (!acc && !pic) console.error(`Unknown applicationId ${r.applicationId}`);
			else
				return {
					answer: r.answer,
					personId: r.applicantPersonId,
					applicationId: r.applicationId,
					applicantName: peopleMap[r.applicantPersonId].name,
					companyId: r.companyId,
					companyName: companyMap[r.companyId].name,
					programName: r.accId ? accMap[r.accId].name : picMap[r.picId].name,
					type: r.accId ? 'Accelerator' : 'Pitch Contest',
					accId: r.accId,
					picId: r.picId,
					city: peopleMap[r.applicantPersonId].asSingleton.zipId ? this.ds.domain.zip.getOne(peopleMap[r.applicantPersonId].asSingleton.zipId).cityAndState : '',

				}
		});
		return rows;
	}

	private valueStyle(row: HearReportRow, field: string): GridValueStyle {
		const value = row[field];

		if (field == 'type') { // Program
			if (value == 'Accelerator') return { icon: 'far fa-tachometer-alt-fast', textStyle: 'Hidden' };
			if (value == 'Pitch Contest') return { icon: 'far fa-bullhorn', textStyle: 'Hidden' };
		}

		return { icon: 'fal fa-question', iconColor: 'Red', textStyle: 'Hidden' };
	}


	private setupGrid(): GridSetup<HearReportRow> {
		const valueStyle = this.valueStyle.bind(this);

		return {
			experience: 'APPLICATION',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Application",
			rowPluralName: "Applications",
			rowKey: "applicationId",
			stateKey: "site-hear-report",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				{ field: "accId", header: "accId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "picId", header: "picId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "programName", header: "Program Name", width: 150, type: GridColumnType.text, hidden: true },
				{ field: "applicantName", header: "Name", width: 150, type: GridColumnType.text },
				{ field: "type", header: "Program", width: 80, type: GridColumnType.text, valueStyle, hidden: true },
				{ field: "city", header: "Home", width: 100, type: GridColumnType.text, hidden: true },
				{ field: "answer", header: "How did they hear?", width: 500, type: GridColumnType.text },
			],
			actions: [
				{ key: 'explore-company', icon: Icon.dialog_explore, label: `Explore Business` },
				{ key: 'explore-applicant', icon: Icon.dialog_explore, label: `Explore Applicant` },
				{ key: 'open-report', icon: Icon.action_open, label: 'Open Report', enabled: false },
			],
			initialState: {
				sort: [{ field: PersonCols.name, dir: 'asc' }],
				group: [{ field: 'type', dir: 'asc' }, { field: 'programName', dir: 'desc', aggregates: [{ field: 'answer', aggregate: 'count' }] }]
			},
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: HearReportRow[] }) {
		if (action.actionKey == 'explore-company') await this.ds.explorer.explore('company', action.rows[0].companyId);
		else if (action.actionKey == 'explore-applicant') await this.ds.explorer.explore(DbConceptName.Person, action.rows[0].personId);
		else if (action.actionKey == 'open-report') {
			await this.ds.dialogService.showCustom(SiteHearReportDetailsDialog, {
				data: { row: action.rows[0] }
			}, 480, 380);
		}
	}

}