import { AccAreaService, AccCohortPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ADDROW_GRID_ACTION_KEY, GridColumnType, GridSetup } from '@me-grid';
import { AccStageId, AccTeam, DbaAccJudgeEvent, Event, EventContext, EventTypeId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { ShowEventDialogService } from '@me-shared-parts/ED-editors';
import { Observable, combineLatest, distinctUntilChanged, lastValueFrom, mergeMap, take } from 'rxjs';


interface EventRow extends Event {
	numId: number,
	judgeCount: number,
	teamCount: number,
}


@Component({
	selector: 'acc-final-pitch-events-view-part',
	templateUrl: './acc-final-pitch-events-view.part.html',
})
export class AccFinalPitchEventsViewPart extends DestroyablePart implements OnInit {
	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.Curriculum);
	readonly: boolean;
	readonlyAccessAtStage: boolean;

	rows$: Observable<EventRow[]>;

	public gridSetup = this.setupGrid(false);

	eventContext: EventContext;

	constructor(
		private accAreaService: AccAreaService,
		private ds: DataService,
		private util: UtilityService,
		public pageService: AccCohortPageService,
		private showEventDialogService: ShowEventDialogService) {
		super();
	}

	async ngOnInit(): Promise<void> {
		super.initDestroyable();

		super.subscribe([this.accAreaService.access$, this.accessAtStage$], async ([access, accessAtStage]) => {
			this.readonly = access?.root != 'Write';
			this.gridSetup = this.setupGrid(access.root == 'Write');
			this.readonlyAccessAtStage = accessAtStage.access != 'Write';
		});

		const acc = await lastValueFrom(this.accAreaService.accelerator.acc$.pipe(take(1)));
		this.eventContext = {
			eventTypeId: EventTypeId.AccFinalPitch,
			languageId: acc.siteProgram.program.languageId,
			accId: acc.accId,
			siteId: acc.siteId,
			siteProgramId: acc.siteProgramId,
		};

		this.rows$ = combineLatest([
			this.accAreaService.accelerator.events$,
			this.accAreaService.teams.teams$,
			this.accAreaService.judging.judgeEvents$,
		]).pipe(
			mergeMap(data => this.buildEvents(data[0], data[1], data[2])),
			distinctUntilChanged((e1, e2) => this.util.values.areSame(e1, e2))
		);
	}


	async buildEvents(
		events: readonly Event[],
		accTeams: readonly AccTeam[],
		judgeEvents: readonly DbaAccJudgeEvent[],
	): Promise<EventRow[]> {

		if (!events || !accTeams) return [];
		return (await this.accAreaService.getEventsWithNumAndDate(EventTypeId.AccFinalPitch, events))
			.map(eventWithNum => ({
				...eventWithNum.event,
				numId: eventWithNum.num,
				judgeCount: judgeEvents.filter(judge => judge.eventId == eventWithNum.event.eventId).length,
				teamCount: accTeams.filter(team => team.finalEventId && team.finalEventId == eventWithNum.event.eventId).length,
			}));
	}


	private setupGrid(canWrite: boolean): GridSetup<EventRow> {

		return {
			experience: 'EVENT',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				shrinkBy: 0,
				heightMultiplier: 1,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: 'Event',
			rowPluralName: 'Events',
			rowKey: 'eventId',
			stateKey: 'acc-interviewing-events-view',
			canAdd: canWrite,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: 'numId', header: '#', width: 50, type: GridColumnType.number },
				{ field: 'EVENT_toolStartUTC', header: 'Tool Start', width: 150, type: GridColumnType.dateAndTimeUtc, hidden: true },
				{ field: 'EVENT_venueName', header: 'Venue', width: 250, type: GridColumnType.text, hidden: true },
				{ field: 'teamCount', header: 'Teams', width: 100, type: GridColumnType.number },
				{ field: 'judgeCount', header: 'Judges', width: 100, type: GridColumnType.number },
			],
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: EventRow[] }) {

		const row = action.rows[0];
		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {
			if (this.readonly) return;
			if (this.readonlyAccessAtStage) {
				await this.ds.dialogService.showMessage(`Cannot add events as the accelerator stage is not in 'Curriculum' stage.`, 300, 170);
			}
			else await this.showEventDialogService.add({ ...this.eventContext });
		}
	}

}