import { DbsPerson, DbsSiteProgram, Program, Site, SiteProgram } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { ProgramPackageManager } from "./program";
import { SitePackageManager } from "./site";


export class SiteProgramPackageManager extends PackageManager<DbsSiteProgram, SiteProgram> {


	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsSiteProgram>,
		private domain: DomainDataManagers,
		private person: SingletonsManager<DbsPerson>,
		private program: ProgramPackageManager,
		private site: SitePackageManager,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Convert an array of DbcSiteProgram to an array of SiteProgram
	 */
	protected async _createPackages(dbsSitePrograms: DbsSiteProgram[]): Promise<SiteProgram[]> {

		//
		// Get all the related objects
		//
		const personIds: number[] = [];

		for (const siteProgram of dbsSitePrograms) {
			personIds.push(siteProgram.managerId);
			personIds.push(siteProgram.altManagerId);
			personIds.push(siteProgram.updatedByPersonId);
		}

		const personMap = await this.person.getManyAsMap(personIds);
		const allSites = await this.site.getAllPackagesAsArray();
		const allPrograms = await this.program.getAllPackagesAsArray();


		//
		// Package 'em up
		//
		const sitePrograms: SiteProgram[] = dbsSitePrograms.map(siteProgram => {

			const manager = personMap[siteProgram.managerId];
			const altManager = personMap[siteProgram.altManagerId];
			const site: Site = allSites.find(s => s.siteId == siteProgram.siteId);
			const updatedByPerson = personMap[siteProgram.updatedByPersonId];
			const program: Program = allPrograms.find(p => p.programId == siteProgram.programId);

			return {
				...siteProgram,
				manager,
				altManager,
				site,
				updatedByPersonName: updatedByPerson?._name || `Person #${siteProgram.updatedByPersonId}`,
				program,
			};
		});

		return sitePrograms;
	}



	/**
	 * Get all SiteProgram for each siteId provided
	 */
	public async getBySiteIds(siteIds: number[]): Promise<Readonly<Record<number, ReadonlyArray<SiteProgram>>>> {

		return await this.getPackagesAsArraysByForeignIds('siteId', siteIds);
	}
}