import { ActivatedRouteSnapshot } from '@angular/router';

export function findRouteData(routeSnapshot: ActivatedRouteSnapshot, key: string, foundData = undefined) {
	if (routeSnapshot == undefined) return foundData;
	const o = routeSnapshot.data[key];
	return findRouteData(routeSnapshot.parent, key, o || foundData);
}

export function replaceRouteData(routeSnapshot: ActivatedRouteSnapshot, key: string, newData) {
	if (routeSnapshot == undefined) return; // reached the root
	const oldData = routeSnapshot.data[key];
	if (oldData) routeSnapshot.data[key] = newData;
	replaceRouteData(routeSnapshot.parent, key, newData);
}