import { AccAreaService } from '@ACC-area';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { GridColumnType, GridSetup } from '@me-grid';
import { ExplorableConceptName } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogAction, DialogContext, DialogService } from '@me-services/ui/dialog';
import { LayoutService } from '@me-services/ui/layout';
import { Icon } from '@me-shared-parts/UI-common';
import { BehaviorSubject } from 'rxjs';
import { ScenarioRow } from './scenario-row';


@Component({
	selector: 'matching-dialog',
	templateUrl: './matching.dialog.html',
})
export class AccMatchingDialog extends DestroyablePart {

	loading$ = new BehaviorSubject<boolean>(false);
	rows: ScenarioRow[] = [];

	icon = 'fa-search';
	headerText = 'Matching Tool';

	applyAction: DialogAction<{ conceptName: ExplorableConceptName, id: number }> = {
		id: 'apply',
		enabled: false,
		visible: true,
		label: 'Apply Scenario',
		linkType: 'callback',
		// callback: async () => (this.row ? { conceptName: this.row.conceptName, id: this.row.id } : undefined),
		willCloseDialog: true,
	};


	actions: DialogAction<{ conceptName: ExplorableConceptName, id: number } | void>[] = [this.applyAction];


	public gridSetup = this.setupGrid();


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<void>,
		public dialogRef: MatDialogRef<AccMatchingDialog>,
		private accAreaService: AccAreaService,
		private ds: DataService,
		private dialogService: DialogService,
		private layoutService: LayoutService,
	) {
		super();
	}


	private setupGrid(): GridSetup<ScenarioRow> {

		return {
			size: {
				fitTo: 'DIALOG',
				dialogActions: true,
				dialogContext: this.dialog,
				heightMultiplier: 1,
				shrinkBy: 40,
			},
			rowSingularName: 'Scenario',
			rowPluralName: 'Scenarios',
			rowKey: 'uniqueKey',
			stateKey: 'scenario-results-grid',
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				{ field: 'rank', header: 'Rank', width: 100, type: GridColumnType.number, },
				{ field: 'strongNo', header: 'No!', width: 100, type: GridColumnType.number, },
				{ field: 'weakNo', header: 'No', width: 100, type: GridColumnType.number, },
				{ field: 'weakYes', header: 'Yes', width: 100, type: GridColumnType.number, },
				{ field: 'strongYes', header: 'Yes!', width: 100, type: GridColumnType.number, },
				{ field: 'mentorStrongYes', header: 'MSY', width: 100, type: GridColumnType.number, },
				{ field: 'highestTeamNos', header: 'HTN', width: 100, type: GridColumnType.number, },
				{ field: 'lowestTeamMeetings', header: 'LTM', width: 100, type: GridColumnType.number, },
				{ field: 'averageTeamMeetings', header: 'ATM', width: 100, type: GridColumnType.number, },

			],
			actions: [
				{ key: 'apply-scenario', icon: Icon.dialog_explore, label: 'Apply' },
			],
			// actionEnabler: this.gridActionEnabler.bind(this),
		};
	}


	async gridActionHandler(action: { actionKey: string, rows: ScenarioRow[] }) {
		// if (action.rows.length) {
		// 	const row = action.rows[0];

		// 	if (row && action.actionKey == SELECTION_GRID_ACTION_KEY) {
		// 		if (this.canSelect) {
		// 			this.exploreAction.enabled = false;
		// 			this.selectAction.enabled = true;
		// 		}
		// 		else this.exploreAction.enabled = true;
		// 		this.row = { conceptName: row.conceptName, id: row.id };
		// 	}
		// 	else if (action.actionKey == DBLCLICK_GRID_ACTION_KEY || action.actionKey == 'explore-match') {
		// 		this.row = this.exploreAction.callbackResult = { conceptName: row.conceptName, id: row.id };
		// 		this.dialogRef.close(this.exploreAction);
		// 	}
		// 	else if (action.actionKey == 'select-match') {
		// 		this.row = this.selectAction.callbackResult = { conceptName: row.conceptName, id: row.id };
		// 		this.dialogRef.close(this.selectAction);
		// 	}
		// }

		// 
	}
}