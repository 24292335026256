import { EventAccess, EventAccessTypeId } from "@me-interfaces";

/**
 * Map an EventAccessTypeId to a set of access values available by role.
 * 
 * This MUST MATCH the backend function with the same name exactly.
 */
export function getEventAccessTypeFlags(eventAccessTypeId: EventAccessTypeId) {

	let access: {
		eforallAdmin: EventAccess,
		staff: EventAccess,
		ed: EventAccess,
		pm: EventAccess,
	};

	
	//
	// Accelerator
	//
	if (eventAccessTypeId == EventAccessTypeId.Accelerator) access = {
		eforallAdmin: EventAccess.Write,
		staff: EventAccess.Read,
		ed: EventAccess.Write,
		pm: EventAccess.Write,
	};

		
	//
	// Pitch Contest
	//
	else if (eventAccessTypeId == EventAccessTypeId.PitchContest) access = {
		eforallAdmin: EventAccess.Write,
		staff: EventAccess.Read,
		ed: EventAccess.Write,
		pm: EventAccess.Write,
	};


	//
	// DEI
	//
	else if (eventAccessTypeId == EventAccessTypeId.DEI) access = {
		eforallAdmin: EventAccess.Write,
		staff: EventAccess.Read,
		ed: EventAccess.None,
		pm: EventAccess.None,
	};

	return access;
}