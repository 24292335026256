import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DbsAward, DbsEvent, EventTypeId, MutableDbsAward, TeamsForAward } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogContext } from '@me-services/ui/dialog';
import { getActions } from '@me-shared-parts/ED-editors/shared-editors/event/event-dialog/dialog/get-actions';
import { AwardRow } from '../event-awards/SHR-PG_event-awards.part';
import { AdminAreaService } from '../../../../admin/admin-area/area/admin-area.service';

@Component({
	selector: 'award-display-dialog',
	templateUrl: './SHR-PG_award-display.dialog.html',
	styleUrls: ['SHR-PG_award-display.dialog.scss'],
})
export class AwardDisplayDialog {

	actions = getActions();

	award: MutableDbsAward;
	eventTypeId: EventTypeId;
	readonly: boolean;
	isAdd: boolean;
	list: TeamsForAward[];

	pendingChanges: MutableDbsAward;
	icon = 'fa-award';
	headerText = 'Add Award';
	wasSaved = false;
	adding = false;
	editing = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ eventTypeId: EventTypeId, readonly: boolean, award: AwardRow | DbsAward, isAdd: boolean, list?: TeamsForAward[] }>,
		public dialogRef: MatDialogRef<AwardDisplayDialog>,
		private ds: DataService,
		private adminAreaService: AdminAreaService,
	) {
		this.setupActions();

		const { award, isAdd, readonly, eventTypeId, list } = dialog.data;

		this.readonly = readonly;
		this.eventTypeId = eventTypeId;
		this.list = list || [];
		this.isAdd = isAdd;


		this.award = isAdd == true ? <MutableDbsAward>{
			...award,
			awardId: 0,
			accTeamId: undefined,
			picTeamId: undefined,
			awardNameId: undefined,
			decidingRoleId: undefined,
			awardKindId: undefined,
			value: undefined,
			notes: '',
			_concept: 'award'
		} : { ...award };


		this.award.notes = (this.award.notes || '').trim();

		if (!isAdd) this.headerText = 'Update Award';

		if (isAdd) {
			this.award.awardId = 0;
			this.actions.save.label = 'Add';
			this.adding = true;
			this.setEditing(true);
		}
		else {
			this.setEditing(false);
		}

	}


	setPendingChanges(pendingChanges: DbsAward) {
		if (this.pendingChanges && !pendingChanges) this.wasSaved = true;
		this.pendingChanges = pendingChanges;
		this.actions.save.enabled = !!this.pendingChanges;
	}

	setEditing(editing: boolean) {
		this.actions.close.visible = !editing;
		this.actions.save.visible = true;
		this.actions.cancel.visible = editing;
		this.editing = editing;
	}



	setupActions() {
		this.actions.close.callback = this.close.bind(this);
		this.actions.save.callback = this.save.bind(this);
		this.actions.cancel.callback = this.cancelEditing.bind(this);
	}


	/**
	 * Called when the Close action button is clicked. If the user has saved an edit,
	 * then we return the save action with the updated award, instead of the close action.
	 */
	async close(): Promise<void> {
		if (this.wasSaved) {
			this.actions.save.callbackResult = await this.ds.admin.event.getOnePackage(this.award.eventId);
			this.dialogRef.close(this.actions.save);
		}
		else {
			this.dialogRef.close(this.actions.close);
		}
	}


	/**
	 * Called when the Save action button is clicked.
	 */
	async save(): Promise<DbsEvent> {

		if (!this.pendingChanges || this.readonly) return;


		if (this.dialog.data.isAdd) {
			await this.adminAreaService.events.addAward({ award: this.pendingChanges });
			this.award = this.pendingChanges;
		}
		else if (!this.dialog.data.isAdd) {
			await this.adminAreaService.events.updateAward({ award: this.pendingChanges });
			this.award = this.pendingChanges;
		}

		const event = await this.ds.admin.event.getOnePackage(this.pendingChanges.eventId);

		this.setPendingChanges(undefined)
		this.setEditing(false);

		if (this.dialog.data.isAdd) {
			this.dialogRef.close(this.actions.save);
		}

		return event;
	}


	/**
	 * Called when the Cancel action button is clicked.
	 */
	async cancelEditing() {
		if (this.adding) {
			this.dialogRef.close(this.actions.cancel);
		}
		else {
			this.setEditing(false);
		}
	}
}
