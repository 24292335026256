import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ChildActivationStart, Router } from '@angular/router';
import { DbcUser, MenuItem } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { OldDomainDataService } from '@me-services/core/old-dd';
import { UtilityService } from '@me-services/core/utility';
import { Icon, getIconClass } from '@me-shared-parts/UI-common';
import { ButtonCardStyles } from '@me-shared-parts/UI-common/button-cards/button-card-styles';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, fromEvent } from 'rxjs';
import { filter, map, share, startWith } from 'rxjs/operators';
import {
	BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XL,
	MENU_WIDTH_AT_BREAKPOINT_LG, MENU_WIDTH_AT_BREAKPOINT_MD, MENU_WIDTH_AT_BREAKPOINT_SM, MENU_WIDTH_AT_BREAKPOINT_XL, MENU_WIDTH_AT_BREAKPOINT_XS
} from '../layout/layout-constants';
import { UrlService } from '../url';
import { adminMenus } from './menus/admin-menus';
import { adminToolsMenu } from './menus/admin-tools-menu';
import { adminWhatsNewMenu } from './menus/admin-whats-new-menu';
import { helpMenu } from './menus/help-menu copy';
import { myMenu } from './menus/my-menu';
import { techAdminMenu } from './menus/tech-admin-menu';



const RECENTS_STORAGE_KEY = 'RecentsMenuData';

interface RouteConfig {
	path: string,
	data: { breadcrumb: string, desc: string, style: string, },
	children?: RouteConfig[],
}

interface RecentMenu {
	icon: Icon,
	label: string,
	routerLink: string,
}


@Injectable({ providedIn: 'root' })
export class MenuService {

	public menuItems$: Observable<MenuItem[]>;
	public collapsed$ = new BehaviorSubject<boolean>(false);
	public menuWidth$ = combineLatest(
		[
			fromEvent(window, 'resize').pipe(
				startWith({ target: window }),
			),
			this.collapsed$,
		])
		.pipe(
			map(([e, collapsed]) => this.calcMenuWidth(e.target as Window)),
			share()
		);

	private recents$ = new BehaviorSubject<RecentMenu[]>([]);

	user$ = new ReplaySubject<DbcUser | undefined>(1);

	constructor(
		dd: OldDomainDataService,
		router: Router,
		private urlService: UrlService,
		private util: UtilityService,
	) {

		this.loadRecents();

		router.events.pipe(filter(e => e instanceof ChildActivationStart))
			.subscribe((e: ChildActivationStart) => {
				const recentsType = e.snapshot?.data?.recentsType;
				if (recentsType == 'Accelerator') this.addRecentAccelerator(<{ siteId: number, accId: number }>e.snapshot.params);
				if (recentsType == 'PitchContest') this.addRecentPitchContest(<{ siteId: number, picId: number }>e.snapshot.params);
			});


		const combined = combineLatest([
			this.user$,
			this.recents$,
		]);


		this.menuItems$ = combined.pipe(
			map(([user, recents]: [DbcUser, RecentMenu[]]) => {

				if (user == undefined) {
					return [];
				}


				// Not a mentor? Remove the mentoring menu
				if (user._personExtracts.badges.mentor !== 'Good') {
					myMenu.subMenu = myMenu.subMenu.filter(m => m.routerLink !== '/access/my/mentoring');
				}

				const menu: MenuItem[] = [
					myMenu,
				];

				if (user.isCrmUser) {
					menu.push(...adminMenus);
					menu.push(this.createRecentsMenu(recents));
					menu.push(adminToolsMenu);
					menu.push(adminWhatsNewMenu);
					menu.push(helpMenu);

					if (user.isTechAdmin) menu.push(techAdminMenu);
				}


				return menu;
			})
		);
	}


	/**
	 * The userAreaService uses this function to set the user in the menu service
	 * @param user 
	 */
	public userChanged(user: DbcUser | undefined) {
		this.user$.next(user);
	}


	/**
	 * Given the width of the window, determine what the CSS will set the left menu's width to.
	 */
	public calcMenuWidth(window: Window): number {
		const w = window.innerWidth;

		//
		// Menu is forced to small size when collapsed
		//
		if (w >= BREAKPOINT_SM && this.collapsed$.value) return MENU_WIDTH_AT_BREAKPOINT_SM;

		if (w >= BREAKPOINT_XL) return MENU_WIDTH_AT_BREAKPOINT_XL;
		if (w >= BREAKPOINT_LG) return MENU_WIDTH_AT_BREAKPOINT_LG;
		if (w >= BREAKPOINT_MD) return MENU_WIDTH_AT_BREAKPOINT_MD;
		if (w >= BREAKPOINT_SM) return MENU_WIDTH_AT_BREAKPOINT_SM;
		return MENU_WIDTH_AT_BREAKPOINT_XS;
	}


	getAdminMenuRootRouteConfig(route: ActivatedRouteSnapshot, path: string): RouteConfig {

		while (route) {
			if (route.routeConfig && route.routeConfig.path == path) {
				if (route.routeConfig.loadChildren) {
					// If the route is the beginning of a module, it will have a loadChildren attribute
					// instead of a children array. In that case we grab the first child which will have
					// a path of "" and the loaded children.
					return <RouteConfig>route.firstChild.routeConfig;
				}
				else {
					return <RouteConfig>route.routeConfig;
				}
			}
			else route = route.parent;
		}

		return undefined;
	}

	/**
	 * Remove any children that cannot be displayed in the menu
	 * @param children
	 */
	filterRouteChildren(children?: RouteConfig[]): RouteConfig[] {
		if (children) {
			return children.filter(child => {
				if (!child.data) return false;
				if (child.path && child.path.startsWith(':')) return false;
				return true;
			});
		}
		return [];
	}

	addSubMenu(level: number, item: MenuItem, routeConfig: RouteConfig, lastUrl: string[]) {

		const children = this.filterRouteChildren(routeConfig.children);

		if (level < 2 && children.length) {

			level++;

			for (const child of children) {
				if (!child.data) continue;
				if (child.path && child.path.startsWith(':')) continue;

				const url = [...lastUrl];
				if (child.path) url.push(child.path);

				let routerLink: string = undefined;
				//if (level == 2 || this.filterRouteChildren(child.children).length == 0) routerLink = url.join('/');
				routerLink = url.join('/');

				let icon = 'fal fa-caret-right';
				if (child.data.style && ButtonCardStyles[child.data.style]) {
					icon = 'fas ' + ButtonCardStyles[child.data.style].icon;
				}

				const subItem: MenuItem = {
					label: child.data.breadcrumb,
					routerLink,
					icon,
				};

				item.subMenu = item.subMenu || [];
				item.subMenu.push(subItem);
				this.addSubMenu(level, subItem, child, url);
			}
		}
	}


	public setCollapsed(collapsed: boolean) {
		if (collapsed == this.collapsed$.value) return;
		this.menuWidth$.subscribe()
		this.collapsed$.next(collapsed);
	}

	
	public toggleCollapsed() {
		this.setCollapsed(!this.collapsed$.value);
	}


	/**
	 * Retrieve the last list of recents from local storage.
	 */
	loadRecents() {
		const rawRecents = localStorage.getItem(RECENTS_STORAGE_KEY);
		if (rawRecents?.length) {
			const recents = JSON.parse(rawRecents);
			if (recents.length) this.recents$.next(recents);
		}
	}


	/**
	 * Add a new recent to the top of the list, removing it from elsewhere in the list,
	 * and then save the updated list into local storage.
	 */
	addRecent(recent: RecentMenu) {
		const list = this.recents$
			.value
			.filter(r => r.label !== recent.label);

		list.unshift(recent);
		if (list.length > 8) list.length = 8;	// Truncate if needed

		localStorage.setItem(RECENTS_STORAGE_KEY, JSON.stringify(list));

		this.recents$.next(list);
	}


	/**
	 * Add an accelerator to the recents menu
	 */
	async addRecentAccelerator(params: { siteId: number, accId: number }) {
		const ds = DataService.instance;
		const site = await ds.admin.site.getOne(params.siteId);
		const acc = await ds.admin.accelerator.getOne(params.accId);

		if (!site) return;
		if (!acc) return;

		const routerLink = `/access/admin/national/sites/${params.siteId}/accelerators/${params.accId}/overview`;
		const label = `${site.code6} ${acc.name}`;

		this.addRecent({
			icon: Icon.concept_accelerator,
			label,
			routerLink,
		});
	}


	/**
	 * Add a pitch contest to the recents menu
	 */
	async addRecentPitchContest(params: { siteId: number, picId: number }) {
		const ds = DataService.instance;
		const site = await ds.admin.site.getOne(params.siteId);
		const pic = await ds.admin.pitchContest.getOne(params.picId);

		if (!site) return;
		if (!pic) return;


		const routerLink = `/access/admin/communities/${site.code.toLowerCase()}/programs/pitch-contests/${pic.picId}/overview`;
		const label = `${site.code6} ${pic.name}`;

		this.addRecent({
			icon: Icon.concept_pitchContest,
			label,
			routerLink,
		});
	}


	/**
	 * Given an array of recent menu data, generate a menu to be added to the left menu
	 */
	createRecentsMenu(recents: RecentMenu[]) {
		const menu: MenuItem = {

			label: 'Recents',
			icon: getIconClass(Icon.view_list, 'fa-solid'),

			subMenu: []
		}

		menu.subMenu.push(
			...recents.map(r => ({
				label: r.label,
				routerLink: r.routerLink,
				icon: getIconClass(r.icon),
			}))
		);

		return menu;
	}
}
