import { Company, Dbs, DbsAccTeam, DbsApplication, DbsAward, DbsCompany, DbsEntityNote, DbsPicTeam, DbsPosition, DbsWebLink, NoteCategoryId } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { PersonPackageManager } from "./person";



export class CompanyPackageManager extends PackageManager<DbsCompany, Company> {


	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsCompany>,
		private domain: DomainDataManagers,
		private person: PersonPackageManager,
		private position: SingletonsManager<DbsPosition>,
		private webLink: SingletonsManager<DbsWebLink>,
		private note: SingletonsManager<DbsEntityNote>,
		private application: SingletonsManager<DbsApplication>,
		private accTeam: SingletonsManager<DbsAccTeam>,
		private picTeam: SingletonsManager<DbsPicTeam>,
		private award: SingletonsManager<DbsAward>,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Finds all of a particular singleton type for each company and returns a map
	 * where each entry is an id (e.g. companyId or entityId) that maps to an array
	 * of the singleton type.
	 */
	private async _getSingletonsMapForCompanies<T extends Dbs>(
		dbsCompanies: DbsCompany[],
		sm: SingletonsManager<T>,
		getId: (company: DbsCompany) => number,
		foreignKeyProperty,
	): Promise<{ [id: number]: T[] }> {

		const foreignIds = dbsCompanies.map(company => getId(company));
		const map = await sm.getArraysByForeignIds(foreignKeyProperty, foreignIds);
		return map;
	}


	/**
	 * Finds all of a particular singleton type for each company and returns a map
	 * where each entry is companyId that maps to an array of the singleton type.
	 */
	private async getSingletonsMapByCompanyId<T extends Dbs>(
		dbsCompanies: DbsCompany[],
		sm: SingletonsManager<T>,
	): Promise<{ [companyId: number]: T[] }> {

		return await this._getSingletonsMapForCompanies(dbsCompanies, sm, (company: DbsCompany) => company.companyId, 'companyId');
	}


	/**
	 * Finds all of a particular singleton type for each company and returns a map
	 * where each entry is entityId that maps to an array of the singleton type.
	 */
	private async getSingletonsMapByEntityId<T extends Dbs>(
		dbsCompanies: DbsCompany[],
		sm: SingletonsManager<T>,
	): Promise<{ [entityId: number]: T[] }> {

		return await this._getSingletonsMapForCompanies(dbsCompanies, sm, (company: DbsCompany) => company.entityId, 'entityId');
	}



	/**
	 * Convert an array of DbcCompany to an array of Company
	 */
	protected async _createPackages(dbsCompanies: DbsCompany[]): Promise<Company[]> {

		const webLinksByEntityId = await this.getSingletonsMapByEntityId(dbsCompanies, this.webLink);
		const notesByEntityId = await this.getSingletonsMapByEntityId(dbsCompanies, this.note);
		const applicationsByCompanyId = await this.getSingletonsMapByCompanyId(dbsCompanies, this.application);

		const companyTypeMap = this.domain.companyType.getAllAsMap();
		const industryMap = this.domain.industry.getAllAsMap();
		const phoneTypeMap = this.domain.phoneType.getAllAsMap();

		const positionIds = dbsCompanies
			.filter(c => !!c.positionId)
			.map(c => c.positionId);
		const positionMap = await this.position.getManyAsMap(positionIds);

		const personIds = positionIds.map(id => positionMap[id].personId);
		const personMap = await this.person.getManyPackagesAsMap(personIds);


		const applicationIds: number[] = [];
		for (const companyId in applicationsByCompanyId) {
			if (applicationsByCompanyId[companyId].length) {
				applicationIds.push(...applicationsByCompanyId[companyId].map(appl => appl.applicationId));
			}
		}

		const applicationByApplicationId = await this.application.getManyAsMap(applicationIds);



		const accTeamByApplicationId: { [applicationId: number]: DbsAccTeam } = (await this.accTeam.getAllAsArray())
			.filter(accTeam => applicationIds.includes(accTeam.applicationId))
			.reduce((a, accTeam) => {
				a[accTeam.applicationId] = accTeam;
				return a;
			}, {});

		const picTeamByApplicationId: { [applicationId: number]: DbsPicTeam } = (await this.picTeam.getAllAsArray())
			.filter(picTeam => applicationIds.includes(picTeam.applicationId))
			.reduce((a, picTeam) => {
				a[picTeam.applicationId] = picTeam;
				return a;
			}, {});


		//
		// Awards
		//

		const awardsByCompanyId = await this.getAwards(dbsCompanies, applicationsByCompanyId, accTeamByApplicationId, picTeamByApplicationId);
		//
		// Package 'em up
		//
		const companies: Company[] = dbsCompanies.map(company => {

			//
			// WebLinks
			//
			const webLinks = (webLinksByEntityId[company.entityId] || [])
				.map(webLink => ({
					webLinkId: webLink.webLinkId,
					webLinkTypeId: webLink.webLinkTypeId,
					url: webLink.url,
				}));

			//
			// Notes
			//
			const notes = notesByEntityId[company.entityId];
			const hasRedFlag = !!notes.find(note => note.noteCategoryId == NoteCategoryId.RedFlag);
			const noteIds = this.util.array.cleanNumericIds(notes.map(note => note.noteId));


			//
			// Applications
			//

			const accApplicationIds = applicationsByCompanyId[company.companyId]
				.map(application => applicationByApplicationId[application.applicationId])
				.filter(application => !!application.accId)
				.map(application => application.applicationId);

			const picApplicationIds = applicationsByCompanyId[company.companyId]
				.map(application => applicationByApplicationId[application.applicationId])
				.filter(application => !!application.picId)
				.map(application => application.applicationId);


			const awards = awardsByCompanyId[company.companyId].map(award => ({ awardId: award.awardId, value: award.value }));

			const awardedValue = awards.reduce((a, award) => { a += award.value; return a; }, 0);

			const companyType = company.companyTypeId ? companyTypeMap[company.companyTypeId]?.name : null;
			const industry = company.industryId ? industryMap[company.industryId]?.name : null;
			const phoneType = company.phoneTypeId ? phoneTypeMap[company.phoneTypeId]?.name : null;
			const position = positionMap[company.positionId];
			const contact = position ? personMap[position.personId] : null;

			return {
				...company,
				id: company.companyId,
				name: company._name,
				explorerName: company._name,
				accApplicationIds,
				picApplicationIds,
				noteIds,
				hasRedFlag,
				webLinks,
				awards,
				awardedValue,
				companyType,
				industry,
				phoneType,
				position,
				contact,
				appUrl: this.createAppUrl(company),
			};
		});

		return companies;
	}



	private createAppUrl(company: DbsCompany): string {
		const companyId = company.companyId;
		return `/access/contacts/companies/${companyId}/overview`;
	}


	async getAwards(
		dbsCompanies: DbsCompany[],
		applicationsByCompanyId: Record<number, DbsApplication[]>,
		accTeamByApplicationId: Record<number, DbsAccTeam>,
		picTeamByApplicationId: Record<number, DbsPicTeam>,
	): Promise<{ [companyId: number]: DbsAward[]; }> {


		const awardsByCompanyId: { [companyId: number]: DbsAward[] } = {};


		//
		// Get all of the unique accTeamId values provided
		//
		let allAccTeamIds: number[] = [];

		for (const company of dbsCompanies) {
			for (const application of applicationsByCompanyId[company.companyId]) {
				const accTeam = accTeamByApplicationId[application.applicationId];
				if (accTeam) allAccTeamIds.push(accTeam.accTeamId);
			}
		}

		allAccTeamIds = this.util.array.cleanNumericIds(allAccTeamIds);


		//
		// Get all of the unique picTeamId values provided
		//
		let allPicTeamIds: number[] = [];

		for (const company of dbsCompanies) {
			for (const application of applicationsByCompanyId[company.companyId]) {
				const picTeam = picTeamByApplicationId[application.applicationId];
				if (picTeam) allPicTeamIds.push(picTeam.picTeamId);
			}
		}

		allPicTeamIds = this.util.array.cleanNumericIds(allPicTeamIds);


		//
		// Get the awards for each acc and pic team
		//
		const awardsByAccTeamId = await this.award.getArraysByForeignIds('accTeamId', allAccTeamIds);
		const awardsByPicTeamId = await this.award.getArraysByForeignIds('picTeamId', allPicTeamIds);



		for (const company of dbsCompanies) {

			//
			// Initialize the array for each person
			//
			if (!awardsByCompanyId[company.companyId]) awardsByCompanyId[company.companyId] = [];

			for (const application of applicationsByCompanyId[company.companyId]) {

				const applicationId = application.applicationId;

				//
				// Add any awards associated with the acc team
				//
				const accTeam = accTeamByApplicationId[applicationId];
				if (accTeam) {
					const awards = awardsByAccTeamId[accTeam.accTeamId] || [];
					awardsByCompanyId[company.companyId].push(...awards);
				}

				//
				// Add any awards associated with the pic team
				//
				const picTeam = picTeamByApplicationId[applicationId];
				if (picTeam) {
					const awards = awardsByPicTeamId[picTeam.picTeamId] || [];
					awardsByCompanyId[company.companyId].push(...awards);
				}
			}
		}

		return awardsByCompanyId;

	}

}