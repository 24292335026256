import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { FieldNumericFormat } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { LabelsService } from '@me-services/ui/labels';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BaseFieldPart } from '../base/SHR-UI_base-field.part';

@Component({
	selector: 'numeric-field',
	templateUrl: './SHR-UI_numeric-field.part.html',
})

export class NumericFieldPart extends BaseFieldPart<number> implements AfterViewInit {

	basePart: BaseFieldPart<number> = this;

	@ViewChild('numericField') numericField: NumericTextBoxComponent;

	constructor(
		protected override func: FuncService,
		protected override labelsService: LabelsService,
	) {
		super(func, labelsService);
	}


	ngAfterViewInit() {

		fromEvent(this.numericField.numericInput.nativeElement, 'keyup')
			.pipe(
				takeUntil(this.destroyed$),
				debounceTime(50),
			)
			.subscribe(this.onKeyUp.bind(this));
	}


	onKeyUp(event: KeyboardEvent) {
		if (event.isTrusted && event.key == 'Enter') {
			// The user pressed Enter
			this.attemptToSave();
		}
	}


	override checkForAdditionalError(): boolean {

		const value = this.pendingValue;

		if (value < this.field.minValue) {
			this.errorText = `Value is less than ${this.field.minValue}`;
			return true;
		}
		if (value > this.field.maxValue) {
			this.errorText = `Value is greater than ${this.field.maxValue}`;
			return true;
		}

		this.errorText = undefined;
		return false;
	}


	override hasPendingValue(): boolean {
		if (this.pendingValue == undefined || this.pendingValue == null) return false;
		return true;
	}


	override hasCurrentValue(): boolean {
		if (this.currentValue == undefined || this.currentValue == null) return false;
		return true;
	}


	onValueChange(event: number) {
		this.setPendingValue(event);
	}


	getFormat(format: FieldNumericFormat, length: number): string {
		return `${format}${length}`;
	}


	/** Calculate a max-width style that is roughly based on the maximum char length */
	get maxWidth() {
		const m = this.field?.maxLength || 100;
		return (m + 2) - m / 5;
	}
}