<div class="container-fluid">
	<div class="row justify-content-center">
		<div class="col-12 col-sm-8 col-md-6 col-lg-4">
			<div class="card" style='margin: 15px 0;'>
				<div class="card-body">
					<p></p>
					<p>
						<me-label key='md:Validation Code Message'></me-label>
					</p>

					<p>
						<me-label key='Code emailed to:'></me-label> &nbsp;<code>{{email}}</code>
					</p>

					<p></p>

					<div class="text-center" style="margin:3rem 0;">
						<form [formGroup]="form" novalidate>
							<mat-form-field style="width:100%">
								<input matInput placeholder="Validation Code" formControlName="code" required maxlength="5" (blur)='fixCaseOfValue(form.controls.code)'>
								<mat-error *ngIf='form.controls.code.errors && form.controls.code.errors.required'><me-label key='Required field'></me-label></mat-error>
								<mat-error *ngIf='form.controls.code.errors && form.controls.code.errors.maxlength'><me-label key='Too many characters'></me-label></mat-error>
								<mat-error *ngIf='form.controls.code.errors && form.controls.code.errors.pattern'><me-label key='Invalid code'></me-label></mat-error>
								<mat-error *ngIf='form.controls.code.errors && form.controls.code.errors.wrongCode'><me-label key='Code not accepted'></me-label></mat-error>
							</mat-form-field>
							<spinner-button (safeClick)='submit()' [disabled]='form.invalid || working' [spinning]='submitting'><me-label key='Submit'></me-label></spinner-button>
						</form>
					</div>

					<hr>

					<div class="text-center" style="margin-top:3rem;">
						<spinner-button (safeClick)='cancel()' [disabled]='working' [spinning]='canceling'><me-label key='Cancel'></me-label></spinner-button>
						<spinner-button (safeClick)='resend()' [disabled]='!canResend || working' [spinning]='resending'><me-label key='Resend the Code'></me-label></spinner-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>