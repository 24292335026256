import { AccAreaService, AccCohortPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridSetup, PersonCols } from '@me-grid';
import { AccSessionSpecialistSurveyResponse, DbaAccSession, DbaAccSessionTopic, DbaAccTopic, DbaAccTopicSpecialist, Person } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridColumnConfig, buildColumn } from '@me-shared-parts/UI-common/grid/columns';
import { Observable, combineLatest, mergeMap } from 'rxjs';


interface TopicSpecialistRow {
	accTopicSpecialistId: number,
	accTopicId: number,
	personId: number,
	topicId: number,
	accSessionId: number,
	eventId: number,
	topicName: string,
	sessionName: string,
	specialistSurveyResults: number,
	specialistSurveyProfessionalismScore: number,
	specialistSurveyKnowledgeScore: number,
	specialistSurveyEffectivenessScore: number,
}


@Component({
	selector: 'acc-curriculum-topic-specialists-view-part',
	templateUrl: './acc-curriculum-topic-specialists-view.part.html',
})
export class AccCurriculumTopicSpecialistsViewPart extends DestroyablePart implements OnInit {
	public gridSetup = this.setupGrid();

	public rows$: Observable<TopicSpecialistRow[]>;

	constructor(
		private accAreaService: AccAreaService,
		public ds: DataService,
		private util: UtilityService,
		public pageService: AccCohortPageService,) {
		super();
	}

	async ngOnInit(): Promise<void> {
		super.initDestroyable();


		this.rows$ = combineLatest([
			this.ds.admin.singletonsAsOfUTC$,
			this.accAreaService.curriculum.topicSpecialists$,
			this.accAreaService.curriculum.sessionTopics$,
			this.accAreaService.curriculum.sessions$,
			this.accAreaService.curriculum.accTopics$,
		]).pipe(mergeMap(data => this.buildData(data[0], data[1], data[2], data[3], data[4])));
	}


	async buildData(
		singletonsAsOfUTC: number,
		topicSpecialists: {
			person: Person,
			topicSpecialist: DbaAccTopicSpecialist,
		}[],
		sessionTopics: DbaAccSessionTopic[],
		sessions: DbaAccSession[],
		accTopics: DbaAccTopic[],
	) {
		if (!topicSpecialists || !sessionTopics || !sessions || !accTopics) return;

		const acc = this.accAreaService.accelerator.acc$.value;
		const accSessionSpeciaistSurveyResponsesByAccIdMap = await this.ds.admin.accSessionSpecialistSurveyResponse.getByAccIds([acc.accId]);
		const responses = accSessionSpeciaistSurveyResponsesByAccIdMap[acc.accId] || [];

		const sessionTopicMap = this.util.array.toMap(sessionTopics, (session) => session.accTopicId);
		const accTopicMap = this.util.array.toMap(accTopics, (accTopic) => accTopic.accTopicId);
		const sessionMap = this.util.array.toMap(sessions, (session) => session.accSessionId);
		const eventMap = await this.ds.admin.event.getManyAsMap(sessions.map(session => session.eventId));
		const topicMap = this.ds.domain.topic.getAllAsMap();


		const rows: TopicSpecialistRow[] = topicSpecialists.map(topicSpecialist => {
			const sessionTopic = sessionTopicMap[topicSpecialist.topicSpecialist.accTopicId];
			const accSession = sessionMap[sessionTopic.accSessionId];
			const event = eventMap[accSession.eventId];
			const topic = topicMap[accTopicMap[sessionTopic.accTopicId].topicId];
			const allSpecialistResponses: AccSessionSpecialistSurveyResponse[] = responses.filter(r => r.eventId == event.eventId && r.topicId == topic.topicId && r.personId == topicSpecialist.person.personId);


			const row: TopicSpecialistRow = {
				accSessionId: sessionTopic.accSessionId,
				accTopicId: topicSpecialist.topicSpecialist.accTopicId,
				accTopicSpecialistId: topicSpecialist.topicSpecialist.accTopicSpecialistId,
				eventId: accSession.eventId,
				personId: topicSpecialist.person.personId,
				sessionName: this.util.date.formatUTC(event.startUTC, 'MMM D, YYYY (DOW)', 'H:MM AM EST', this.ds.languageId),
				topicId: topic.topicId,
				topicName: topic.shortNameLabel,

				specialistSurveyResults: allSpecialistResponses.length || null,
				specialistSurveyEffectivenessScore: this.util.nps.calcScore(allSpecialistResponses.map(result => result.effectiveness)),
				specialistSurveyKnowledgeScore: this.util.nps.calcScore(allSpecialistResponses.map(result => result.knowledge)),
				specialistSurveyProfessionalismScore: this.util.nps.calcScore(allSpecialistResponses.map(result => result.professionalism)),
			};

			return row;
		});

		return rows;

	}


	private setupGrid(): GridSetup<TopicSpecialistRow> {

		const setup: GridSetup<TopicSpecialistRow> = {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Topic Specialist",
			rowPluralName: "Topic Specialists",
			rowKey: "accTopicSpecialistId",
			stateKey: "acc-curriculum-topic-specialists-view-part",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				buildColumn('accTopicSpecialistId', GridColumnConfig.id, { hidden: true, header: 'accTopicSpecialistId' }),
				buildColumn('accTopicId', GridColumnConfig.id, { hidden: true, header: 'accTopicId' }),
				buildColumn('topicId', GridColumnConfig.id, { hidden: true, header: 'topicId' }),
				buildColumn('accSessionId', GridColumnConfig.id, { hidden: true, header: 'accSessionId' }),
				buildColumn('eventId', GridColumnConfig.eventId, { hidden: true }),
				buildColumn('topicName', GridColumnConfig.text, { header: 'Topic', width: 120, order: 101.5 }),
				buildColumn('sessionName', GridColumnConfig.text, { header: 'Session', width: 120 }),
				buildColumn('specialistSurveyResults', GridColumnConfig.number, { header: "# Surveys", headerTooltip: 'Specialist Surveys Taken', width: 70 }),
				buildColumn('specialistSurveyProfessionalismScore', GridColumnConfig.number, { hidden: true, header: "Professionalism Score", headerTooltip: 'Professionalism Score [0-100] Asked: "The specialist was professional"', width: 70 }),
				buildColumn('specialistSurveyKnowledgeScore', GridColumnConfig.number, { hidden: true, header: "Knowledge Score", headerTooltip: 'Knowledge Score [0-100] Asked: "The specialist was knowledgeable about this topic"', width: 70 }),
				buildColumn('specialistSurveyEffectivenessScore', GridColumnConfig.number, { hidden: true, header: "Effectiveness Score", headerTooltip: 'Effectiveness Score [0-100] Asked: "The specialist effectively taught the topic"', width: 70 }),
			],
			columnsToAlter: [
				{ field: PersonCols.name, header: 'Specialist' },
			],
			initialState: {},
		};

		return setup;
	}

}