import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DbcUser, DbsCompany, DbsWebLink, WebLinkTypeId } from '@me-interfaces';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { DialogContext, DialogService } from '@me-services/ui/dialog';
import { EntityEditorDialog } from '@me-shared-parts/ED-editors';
import { EntityEditorData } from '@me-shared-parts/ED-editors/dialog/entity-editor-data';
import { PERSON_EDITOR_KEYS } from '@me-shared-parts/ED-editors/person-editors/SHR-ED_person-editors.part';
import { getIconContext, getIconForWebLinkType, Icon, IconContext } from '@me-shared-parts/UI-common';


@Component({
	selector: 'company-extract-dialog',
	templateUrl: './SHR-CM_company-extract-dialog.dialog.html',
	styleUrls: ['./SHR-CM_company-extract-dialog.dialog.scss']
})
export class CompanyExtractDialog implements OnInit {

	company: DbsCompany;
	header = 'Company';

	user: DbcUser;
	smsLink: string;
	telLink: string;
	emailLink: string;
	notesIcon: IconContext = getIconContext(Icon.notes_none, undefined, 'always');

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ company: DbsCompany }>,
		public dialogRef: MatDialogRef<CompanyExtractDialog>,
		private dialogService: DialogService,
		private userService: OldUserService,
	) {
	}


	async ngOnInit() {
		if (this.dialog.data) this.company = this.dialog.data.company;

		this.user = await this.userService.getUser();
		this.header = this.company?._name || 'No Company Record found';
		if (this.company) await this.setNotesIcon();
		if (this.company.phone) {
			this.telLink = `tel:${this.company.phone}`;
			this.smsLink = `sms:${this.company.phone}`;
		}
	}


	/**
	 * Determine the button icon and text based on the weblink type
	 */
	getWebLinkIcon(webLink: DbsWebLink) {
		const icon = getIconForWebLinkType(webLink.webLinkTypeId || WebLinkTypeId.Website)
		return getIconContext(icon, undefined, 'always');
	}

	async openCompanyEdit(targetKey?: PERSON_EDITOR_KEYS) {
		this.dialogRef.close();

		const profileEditorData: EntityEditorData = {
			entityId: this.company.entityId,
			selectedEditor: targetKey == 2 ? PERSON_EDITOR_KEYS.NOTES : PERSON_EDITOR_KEYS.PROFILE,
			user: this.user,
		};

		const e = await this.dialogService.showCustom(
			EntityEditorDialog,
			{ data: profileEditorData },
			950, 585);

		if (e) await this.setNotesIcon();
	}

	async setNotesIcon() {
		if (this.company._entityExtracts?.noteCounts?.redFlags > 0) this.notesIcon = getIconContext(Icon.notes_redFlag, undefined, 'always');
		else if (this.company._entityExtracts?.noteCounts?.total > 0) this.notesIcon = getIconContext(Icon.notes_some, undefined, 'always');
		else this.notesIcon = getIconContext(Icon.notes_none, undefined, 'always');
	}
}