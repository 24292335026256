import { AccApplicationContext, AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DestroyablePart } from '@me-access-parts';
import { AccReaderWithMaxReads, AccStageId, Accelerator, Answer, Application, ApplicationStatusId, DbaAccReading } from '@me-interfaces';
import { QuestionStepper } from '@me-shared-parts/PG-program';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';
import { getIconContextForPackage } from '@me-shared-parts/UI-common/icon/get-icon-context-for-package';
import { Observable, combineLatest, lastValueFrom, map, take } from 'rxjs';


const DEFAULT_EDITOR_DATA = { uniqueId: undefined, iconContext: getIconContext(Icon.empty), data: undefined };
interface EditorData {
	applicationList: DroplistItem<Application>[],
	readerList: DroplistItem<AccReaderWithMaxReads>[],
}


@Component({
	selector: 'acc-reading-editor-view-part',
	templateUrl: './acc-reading-editor-view.part.html',
	styleUrls: ['./acc-reading-editor-view.part.scss'],
})
export class AccReadingEditorViewPart extends DestroyablePart implements OnInit {

	public readonly AccStageId_Reading = AccStageId.Reading;
	data$: Observable<EditorData>;
	public applicationSelectedItem: DroplistItem<Application>;
	public readerSelectedItem: DroplistItem<AccReaderWithMaxReads>;
	private readAssessments: {
		reading: DbaAccReading,
		answers: readonly Answer[],
	}[];
	private readerAssessment: {
		rating: number | undefined,
		answers: readonly Answer[]
	};
	private defaultReadQuestions: {
		rating: number | undefined,
		answers: readonly Answer[]
	};

	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.Reading, 'assessments');

	readonly: boolean;

	stepper: QuestionStepper;
	ratingStep;
	form: FormGroup;
	accelerator: Accelerator;
	saving = false;

	constructor(
		public accAreaService: AccAreaService,
		private fb: FormBuilder,

	) {
		super();
	}

	async ngOnInit() {

		super.initDestroyable();

		super.subscribe([this.accessAtStage$], async ([accessAtStage]) => {
			this.readonly = accessAtStage.access != 'Write';
		});

		this.data$ = combineLatest([
			this.accAreaService.applications.applications$,
			this.accAreaService.reading.assessments$,
			this.accAreaService.reading.readers$,
			this.accAreaService.reading.defaultQuestions$
		]).pipe(map(data => this.buildData(data[0], data[1], data[2], data[3])));


		this.accelerator = await lastValueFrom(this.accAreaService.accelerator.acc$.pipe(take(1)));

	}

	buildData(
		applications: readonly AccApplicationContext[],
		readerAssessments: {
			reading: DbaAccReading;
			answers: readonly Answer[];
		}[],
		readers: readonly AccReaderWithMaxReads[],
		defaultReadQuestions: readonly Answer[],
	): EditorData {

		if (!applications || !readers || !readerAssessments || !defaultReadQuestions || this.readonly) return {
			applicationList: [],
			readerList: [],
		};


		this.readAssessments = readerAssessments;

		this.defaultReadQuestions = {
			rating: undefined,
			answers: defaultReadQuestions
		};

		const applicationList: DroplistItem<Application>[] = applications.filter(app => app.application.applicationStatusId >= ApplicationStatusId.ReadPending)
			.map(a => ({ uniqueId: a.application.applicationId, iconContext: getIconContextForPackage(a.application), data: a.application }));

		const readerList = readers.map(a => ({ uniqueId: a.reader.accReaderId, iconContext: getIconContextForPackage(a.reader.person), data: a }));

		applicationList.unshift(DEFAULT_EDITOR_DATA);
		readerList.unshift(DEFAULT_EDITOR_DATA);


		return {
			applicationList,
			readerList,
		};

	}


	onReaderChange(e: DroplistItem) {
		if (e.uniqueId) this.readerSelectedItem = e;
		else {
			this.readerSelectedItem = undefined;
			this.applicationSelectedItem = undefined;
		}
	}

	onApplicationChange(e: DroplistItem) {
		if (e.uniqueId) this.applicationSelectedItem = e;
		else this.applicationSelectedItem = undefined;
	}


	load() {
		if (!this.applicationSelectedItem || !this.readerSelectedItem) return;

		const answersFound = this.readAssessments.find(assessment => assessment.reading.accReaderId == this.readerSelectedItem.uniqueId && assessment.reading.applicationId == this.applicationSelectedItem.uniqueId);
		this.readerAssessment = answersFound ? { answers: answersFound.answers, rating: answersFound.reading.rating } : this.defaultReadQuestions;
		this.stepper = new QuestionStepper(0, this.readerAssessment.answers.length, 1);
		this.ratingStep = this.readerAssessment.answers.length;
		this.createForm();
	}

	private createForm() {
		this.form = this.fb.group({
			rating: [this.readerAssessment.rating, [Validators.required]],
		});

		this.resetForm();
	}

	private resetForm() {
		const values = {
			rating: this.readerAssessment.rating
		}
		this.form.reset(values);
		this.form.updateValueAndValidity();
	}

	async save() {
		if (this.readonly) return;

		this.saving = true;
		const values = this.form.value;
		const applicationId = this.applicationSelectedItem.uniqueId;
		const accReaderId = this.readerSelectedItem.uniqueId;

		const assessment = {
			rating: values.rating,
			answers: [],
		};

		for (const answer of this.readerAssessment.answers) {
			assessment.answers.push({
				question: answer.question,
				answer: values['q' + answer.question.questionId],
			});
		}

		await this.accAreaService.reading.actions.setAssessment({ accReaderId, applicationId, assessment });

		this.readerAssessment = undefined;
		this.saving = false;
	}

}
