<me-dialog-frame [header]='header' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>

	<div class='volunteer-role-add-dialog'>
		<table style='width:100%' class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>
			<tr>
				<td>Person</td>
				<td> <button class='btn' (click)='explorePerson()'>
						<me-icon [icon]='personIcon'></me-icon>
					</button>
				</td>
			</tr>
			

			<tr>
				<td>Status</td>
				<td>
					<kendo-dropdownlist [data]='statuses' [value]='newSelectedValue.status' [valuePrimitive]="true"
						(valueChange)='onStatusSelectionChange($event)' [disabled]='readonly'>
					</kendo-dropdownlist>
				</td>
			</tr>
		</table>
	</div>
</me-dialog-frame>