import { AppAreaApplyResponse, AppAreaFuncResponse, AppAreaIdentifierWithSite, AppAreaIdentifierWithSiteId, ApplicationStatusId } from "@me-interfaces";
import { AppSubArea } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";


export class AccSubAreaApplications extends AppSubArea {

	constructor(
		private func: FuncService,
		ds: DataService,
		util: UtilityService,
		getId: () => AppAreaIdentifierWithSiteId<number>,
		applyResponse: (response: AppAreaFuncResponse) => Promise<AppAreaApplyResponse>,
	) {
		super(ds, util, getId, applyResponse);
	}


	async transferApplication(parameters: { applicationId: number, accId: number }) {
		return await this.call(this.func.areas.acc.applications.transfer, parameters);
	}

	async setStatus(parameters: { applicationId: number, applicationStatusId: ApplicationStatusId }) {
		return await this.call(this.func.areas.acc.applications.setStatus, parameters);
	}

	async setBulkStatus(parameters: { applicationIds: number[], applicationStatusId: ApplicationStatusId }) {
		return await this.call(this.func.areas.acc.applications.setBulkStatus, parameters);
	}

	async setNotes(parameters: { applicationId: number, notes: string }) {
		return await this.call(this.func.areas.acc.applications.setNotes, parameters);
	}

	async addToCohort(parameters: { applicationIds: number[] }) {
		return await this.call(this.func.areas.acc.applications.addToCohort, parameters);
	}

	async setInterviewEventId(parameters: { applicationId: number, eventId: number }) {
		return await this.call(this.func.areas.acc.applications.setInterviewEventId, parameters);
	}
}