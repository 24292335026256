import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { UserAreaService } from '@me-user-area';
import { lastValueFrom, take } from 'rxjs';

@Component({
	selector: 'agreement-signature',
	templateUrl: './agreement-signature.part.html',
	styleUrls: ['agreement-signature.part.scss'],
})
export class AgreementSignaturePart extends DestroyablePart implements OnInit {

	@Input() readonly: boolean;
	@Output() agreementSigned = new EventEmitter<boolean>();

	public checked = false;
	public canSign = false;
	public signedName: string;
	public name: string;

	constructor(private userAreaService: UserAreaService) {
		super();
	}

	async ngOnInit() {
		super.initDestroyable();

		const user = await lastValueFrom(this.userAreaService.user$.pipe(take(1)));
		this.name = `${user.firstName} ${user.lastName}`;
	}


	toggleCheckbox() {
		this.checked = !this.checked;
		if (!this.checked) this.signedName = '';
		this.checkIfCanSign();
	}


	setName(e) {
		this.signedName = e;
		this.checkIfCanSign();
	}

	onBlur() {
		const text = (this.signedName ?? '').trim();
		this.signedName = text;
		this.checkIfCanSign();
	}

	checkIfCanSign() {
		this.canSign = !this.readonly && this.checked && !!this.signedName && this.name.toLowerCase() == this.signedName.toLowerCase();
	}

}