
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';
import { DdApplicationStatusRow } from './dd-application-status-row';
import { DdApplicationStatus } from './dd-application-status';

/**
 * @deprecated Use the DataService instead
 */
export class DdApplicationStatuses extends DdObjects<DdApplicationStatusRow, DdApplicationStatus> {

	constructor(private dd: OldDomainDataService) {
		super('applicationStatusId');
	}

	async loadData(hash: string, data: DdApplicationStatusRow[]) {
		const objects = data.map(d => new DdApplicationStatus(this.dd, d));
		this.loadObjects(hash, objects);
	}
}