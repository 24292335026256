import { EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { DbConceptName } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { Icon } from "@me-shared-parts/UI-common/icon";
import { AccTeamCols, buildColumn, GridColumnConfig } from '../columns';
import { EXPLORE_GRID_ACTION_KEY, GRID_EXPERIENCE, GridColumnType, GridSetup, OPEN_GRID_ACTION_KEY } from "../interfaces";
import { GridExperience } from "./grid-experience";



/**
 * Standard experience for a grid of acc teams
 */
export class AccTeamGridExperience<ROW> extends GridExperience<ROW> {

	public static readonly experience: GRID_EXPERIENCE = 'ACCTEAM';

	constructor(
		private ds: DataService,
		util: UtilityService,
		setup: GridSetup<ROW>,
		gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
		private router: Router,
	) {

		const dropoutWeek = (row: ROW) => {
			if (row[AccTeamCols.droppedOutWeek]) return `Dropped Out Week ${row[AccTeamCols.droppedOutWeek]}`;
			else return undefined;
		};

		const dropoutWeekFlag = (row: ROW) => {
			if (row[AccTeamCols.droppedOutWeek]) return true;
			else return false;
		};

		super(
			util, setup,
			[
				buildColumn(AccTeamCols.siteId, GridColumnConfig.siteId, { hidden: true }),
				buildColumn(AccTeamCols.accId, GridColumnConfig.accId, { hidden: true }),
				buildColumn(AccTeamCols.accTeamId, GridColumnConfig.accTeamId, { hidden: true }),
				buildColumn(AccTeamCols.applicationId, GridColumnConfig.applicationId, { hidden: true }),
			],
			[
				{ field: AccTeamCols.redFlag, header: "Flag", width: 50, type: GridColumnType.redFlag, noColumnMenu: true },
				{ field: AccTeamCols.companyName, header: "Business", width: 200, type: GridColumnType.text, strikethrough: dropoutWeekFlag, tooltip: dropoutWeek },
			],
			[
				{ field: AccTeamCols.entrepreneurNames, header: "Entrepreneurs", width: 280, type: GridColumnType.text, hidden: true },
				{ field: AccTeamCols.entrepreneurEmails, header: "Entrepreneur Emails", width: 300, type: GridColumnType.text, hidden: true },
				{ field: AccTeamCols.droppedOutWeek, header: "Dropped Out Week", width: 100, type: GridColumnType.number, hidden: true },
				{ field: AccTeamCols.companyCityAndState, header: "Location", headerTooltip: "Location of business if there is one, or applicant's home otherwise", width: 140, type: GridColumnType.text, hidden: true },
				{ field: AccTeamCols.zipCode, header: "Zip Code", width: 100, type: GridColumnType.text, hidden: true },
				{ field: AccTeamCols.companyType, header: "Org Type", width: 180, type: GridColumnType.text, hidden: true },
				{ field: AccTeamCols.industry, header: "Industry", width: 180, type: GridColumnType.text, hidden: true },
				{ field: AccTeamCols.offerings, header: "Offerings", width: 100, type: GridColumnType.text, hidden: true },
				{ field: AccTeamCols.mentorNames, header: "Mentors", width: 280, type: GridColumnType.text, hidden: true },
				{ field: AccTeamCols.mentorEmails, header: "Mentor Emails", width: 300, type: GridColumnType.text, hidden: true },
			],
			[
				{ key: EXPLORE_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Explore ${setup.rowSingularName}` },
				{ key: OPEN_GRID_ACTION_KEY, icon: Icon.action_open, label: `Open ${setup.rowSingularName}` },
				// { key: NOTES_GRID_ACTION_KEY, icon: Icon.action_editNotes, label: `Edit Team Notes` },
			],
			gridAction,
			Icon.concept_accTeam,
		);
	}


	public async applyBaseValues(rows: ROW[]) {

		const accTeamMap = await this.ds.admin.accTeam.getManyPackagesAsMap(rows.map(row => row['accTeamId']));

		for (const row of rows) {
			const accTeamId = row['accTeamId'];
			const accTeam = accTeamMap[accTeamId];

			const mentors = accTeam.members
				.filter(m => m.member.role === 'M' || m.member.role === 'C');

			const entrepreneurs = accTeam.members
				.filter(m => m.member.role === 'E')
				.sort((a, b) => a.member.accTeamMemberId - b.member.accTeamMemberId);


			row[AccTeamCols.siteId] = accTeam.application.siteProgram.siteId;
			row[AccTeamCols.accId] = accTeam.accId;
			row[AccTeamCols.accTeamId] = accTeam.accTeamId;
			row[AccTeamCols.applicationId] = accTeam.applicationId;
			row[AccTeamCols.redFlag] = accTeam.hasRedFlag;
			row[AccTeamCols.droppedOutWeek] = accTeam.droppedOutWeek;
			row[AccTeamCols.companyName] = accTeam.application.company._name;
			row[AccTeamCols.entrepreneurNames] = entrepreneurs.map(m => m.person._name).join(', ');
			row[AccTeamCols.entrepreneurEmails] = entrepreneurs.map(m => m.person._email).join(', ');
			row[AccTeamCols.mentorNames] = mentors.map(m => m.person._name).sort().join(', ');
			row[AccTeamCols.mentorEmails] = mentors.map(m => m.person._email).sort().join(', ');
			
			row[AccTeamCols.companyCityAndState] = this.ds.getCityAndState(accTeam.application.company.zipId || accTeam.application.person.zipId);
			row[AccTeamCols.zipCode] = this.ds.getZipCode(accTeam.application.company.zipId || accTeam.application.person.zipId);

			row[AccTeamCols.companyType] = accTeam.application.company.companyTypeId ? this.ds.domain.companyType.getOne(accTeam.application.company.companyTypeId)?.name : '';
			row[AccTeamCols.industry] = accTeam.application.company.industryId ? this.ds.domain.industry.getOne(accTeam.application.company.industryId)?.name : '';
			row[AccTeamCols.offerings] = accTeam.application.company.offerings;
			row[AccTeamCols.language] = accTeam.application.siteProgram.program.language.name;
			row[AccTeamCols.siteCode] = accTeam.application.siteProgram.site.code;

		}
	}


	public sortRows(rows: ROW[]) {
		rows.sort((row1: ROW, row2: ROW) => {
			const val1 = row1[AccTeamCols.companyName];
			const val2 = row2[AccTeamCols.companyName];
			return val1 < val2 ? -1 : 1;
		});
	}


	public async explore(row: ROW) {
		await this.ds.explorer.explore(DbConceptName.AccTeam, row[AccTeamCols.accTeamId]);
	}


	public async open(row: ROW) {
		this.router.navigate([`access/admin/national/sites/${row[AccTeamCols.siteId]}/accelerators/${row[AccTeamCols.accId]}/cohort/${row[AccTeamCols.accTeamId]}`]);
	}


	public async editNotes(row: ROW) {
		// Notes dialog not implemented
	}
}