import { Directive, TemplateRef } from '@angular/core';


@Directive({ selector: '[pageTab1]' })
export class PageTab1Directive {
	constructor(public tpl: TemplateRef<any>) { }
}

@Directive({ selector: '[pageTab2]' })
export class PageTab2Directive {
	constructor(public tpl: TemplateRef<any>) { }
}

@Directive({ selector: '[pageTab3]' })
export class PageTab3Directive {
	constructor(public tpl: TemplateRef<any>) { }
}

@Directive({ selector: '[pageTab4]' })
export class PageTab4Directive {
	constructor(public tpl: TemplateRef<any>) { }
}

@Directive({ selector: '[pageTab5]' })
export class PageTab5Directive {
	constructor(public tpl: TemplateRef<any>) { }
}

@Directive({ selector: '[pageTab6]' })
export class PageTab6Directive {
	constructor(public tpl: TemplateRef<any>) { }
}

@Directive({ selector: '[pageTab7]' })
export class PageTab7Directive {
	constructor(public tpl: TemplateRef<any>) { }
}