import { Answer, AppAreaApplyResponse, AppAreaFuncResponse, AppAreaIdentifierWithSite, AppAreaIdentifierWithSiteId } from "@me-interfaces";
import { AppSubArea } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";

export class AccSubAreaJudging extends AppSubArea {

	constructor(
		private func: FuncService,
		ds: DataService,
		util: UtilityService,
		getId: () => AppAreaIdentifierWithSiteId<number>,
		applyResponse: (response: AppAreaFuncResponse) => Promise<AppAreaApplyResponse>,
	) {
		super(ds, util, getId, applyResponse);
	}


	async removeJudge(parameters: { accJudgeId: number }) {
		return await this.call(this.func.areas.acc.judging.deleteJudge, parameters);
	}

	async setJudges(parameters: { personIds: number[] }) {
		return await this.call(this.func.areas.acc.judging.setJudge, parameters);
	}


	async setEvents(parameters: { accJudgeId: number, eventIds: number[] }) {
		return await this.call(this.func.areas.acc.judging.setEvents, parameters);
	}


	async setPolishedFeedbackSkipped(parameters: { accJudgeId: number, accTeamId: number, polishedFeedbackSkipped: boolean }) {
		return await this.call(this.func.areas.acc.judging.setPolishedFeedbackSkipped, parameters);
	}

	async setPolishedFeedback(parameters: { accJudgeId: number, accTeamId: number, polishedFeedback: string }) {
		return await this.call(this.func.areas.acc.judging.setPolishedFeedback, parameters);
	}

	async setAssessment(parameters: { accJudgeId: number, accTeamId: number, answers: Answer[] }) {
		return await this.call(this.func.areas.acc.judging.setAssessment, parameters);
	}

}