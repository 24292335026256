import { AfterContentInit, ContentChildren, Directive, OnDestroy, QueryList } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router, IsActiveMatchOptions } from '@angular/router';
import { RouterTabDirective } from './ACS_router-tab.directive';

@Directive({
	selector: '[routerTabs]'
})
export class RouterTabsDirective implements AfterContentInit, OnDestroy {

	subscriptions = new Subscription();

	@ContentChildren(RouterTabDirective) routerTabs: QueryList<RouterTabDirective>;


	constructor(private host: MatTabGroup, private router: Router) {
	}


	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}


	ngAfterContentInit(): void {

		this.activateTheTabThatMatchesTheRoute();

		this.subscriptions.add(this.router.events.subscribe((e) => {
			if (e instanceof NavigationEnd) {
				setTimeout(this.activateTheTabThatMatchesTheRoute.bind(this));
			}
		}));

		this.subscriptions.add(this.host.selectedTabChange.subscribe(() => {
			const tab = this.routerTabs.find(item => item.tab.isActive);
			if (!tab) return false;

			this.router.navigateByUrl(tab.link.urlTree);
			return true;
		}));
	}


	private activateTheTabThatMatchesTheRoute() {

		this.routerTabs.find((tab, i) => {

			const options: IsActiveMatchOptions = { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' };
			if (!this.router.isActive(tab.link.urlTree, options)) return false;

			tab.tab.isActive = true;
			this.host.selectedIndex = i;
			return true;
		});
	}
}