<div *ngIf='loading'>
	<i _ngcontent-ubx-c306="" class="fad fa-spinner-third fa-spin fa-2x" style="
	    --fa-secondary-opacity: 1;
	    --fa-primary-color: #369;
	    --fa-secondary-color: #FFF;
		-webkit-animation: fa-spin 700ms infinite linear;
  		animation: fa-spin 700ms infinite linear;
	"></i>
</div>

<ng-container *ngIf="!loading">

	<div *ngIf='!access'> Invalid access key: {{ accessKey }} </div>
	<div *ngIf='access === "Invalid"'> Invalid! </div>

	<no-access *ngIf='access==="None"'></no-access>

	<readonly-access *ngIf='access==="Read" && showReadonlyMessage'></readonly-access>


	<ng-container *ngIf='access==="Read" || access==="Write"'>
		<ng-content></ng-content>
	</ng-container>

</ng-container>