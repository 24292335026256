import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppSessionPersonMatch, AppSessionSearchDetails } from '@me-interfaces';
import { AppSessionService } from '@me-services/core/app-session';
import { Subscription } from 'rxjs';
import { LabelsService } from '@me-services/ui/labels';
import { Router } from '@angular/router';

@Component({
	selector: 'session-is-this-you-page',
	templateUrl: './session-is-this-you.page.html',
	styleUrls: ['./session-is-this-you.page.scss'],
})
export class SessionIsThisYouPage implements OnInit, OnDestroy {

	public index = 0;
	public AppSessionSearchDetails: AppSessionSearchDetails;
	public sessionMatches: AppSessionPersonMatch[] = [];
	private subscription: Subscription;

	constructor(private appSession: AppSessionService, private labels: LabelsService, private router: Router) { }

	ngOnInit() {
		this.subscription = this.appSession.status$.subscribe(appSessionState => {
			this.sessionMatches = appSessionState.matchingPeople;
			this.AppSessionSearchDetails = appSessionState.searchDetails
			this.index = 0;
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	result = async (yes: boolean) => {
		if (yes) await this.appSession.sendCode(this.sessionMatches[this.index].personId, this.labels.languageId);
		else this.index++;
	}

	addMe = async () => {
		await this.appSession.createPerson(this.AppSessionSearchDetails);
		this.router.navigate(['access/my/dashboard']);
	}
}