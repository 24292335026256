import { DbConceptName, Region, Site, WebLinkTypeId } from "@me-interfaces";
import { LinkOrExplorable } from "../../link-or-explorerable";

export function getButtons(region: Region): LinkOrExplorable[] {

	const buttons: LinkOrExplorable[] = [];

	//
	// ED
	//
	buttons.push(
		{ explorer: { nameOverride: `ED: ${region.directorName}`, explorableId: { conceptName: DbConceptName.Person, id: region.directorId }, navTooltip: `ED: ${region.directorName}`, } },
	);


	return buttons;
}