<div class='company-explorer-details-part' *ngIf="companyDetails">

	<div id="container" class="explorer-details-container">

		<table class='pro-table pro-table-no-header pro-table-with-labels'>
			<tr>
				<td> Company Id</td>
				<td> {{company.companyId}}</td>
			</tr>

			<tr *ngIf="company.shortName">
				<td>Short Name</td>
				<td>{{company.shortName}}</td>
			</tr>

			<tr *ngIf="company.longName">
				<td>Long Name</td>
				<td>{{company.longName}}</td>
			</tr>

			<tr *ngIf="companyDetails.orgType">
				<td>Org Type</td>
				<td>{{companyDetails.orgType}}</td>
			</tr>

			<tr *ngIf="companyDetails.industry">
				<td>Industry</td>
				<td>{{companyDetails.industry}}</td>
			</tr>

			<tr *ngIf="companyDetails.offerings">
				<td>Offerings</td>
				<td>{{companyDetails.offerings === 'Both' ? 'Products and Services' : companyDetails.offerings}}</td>
			</tr>

			<tr>
				<td>Created On</td>
				<td>{{util.date.formatUTC(companyDetails.createdUTC, 'MMM D, YYYY', 'H:MM AM EST', ds.languageId)}}</td>

			</tr>

			<tr>
				<td>Last Edited By</td>
				<td>
					<explorer-button
						[button]="{ explorableId: { conceptName: 'person', id: companyDetails.updatedByPersonId }}">
					</explorer-button>
					<div class="small-nowrap-text">
						on {{util.date.formatUTC(companyDetails.updatedUTC, 'MMM D, YYYY', 'H:MM AM EST',
						ds.languageId)}}
					</div>
				</td>
			</tr>

		</table>

		<div class="explorer-details-right">
		</div>

	</div>
</div>