import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MenuAction } from './menu-action';

@Component({
	selector: 'actions-menu',
	templateUrl: './SHR-CMN_actions-menu.part.html',
	styleUrls: ['SHR-CMN_actions-menu.part.scss'],
})
export class ActionsMenuPart implements OnChanges {

	@Input() actions: MenuAction[] = [];
	@Input() header: string;
	@Input() icon = 'fa-ellipsis-v';
	@Input() location: '' | 'toolbar' | 'grid' | 'kendo-grid' = '';
	@Input() tooltip: string;
	@Output() actionClick = new EventEmitter<MenuAction>();

	public hasIcons = false;
	public highlighted = false;

	constructor(
		public sanitizer: DomSanitizer,
	) { }

	public async click(action: MenuAction) {

		if (action.linkType == 'callback') {
			await action.callback();
		}
		else if (action.linkType == 'actionClick-event') {
			this.actionClick.emit(action);
		}
	}

	public sanitize(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}

	ngOnChanges() {
		//
		// Determine if at least one of the actions has icons
		//
		this.hasIcons = !!this.actions.find(a => !!a.icon);

		//
		// If the highlighted class is on the icon, it should also be on the button
		//
		this.highlighted = this.icon && this.icon.indexOf('highlighted') >= 0;

		//
		// If we are going to display icons, set an empty icon on any actions without an icon
		//
		for (const action of this.actions) {
			if (!action.icon) action.icon = '';
		}
	}

}
