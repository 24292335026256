import { EventTypeId } from "@me-interfaces";
import { getIconContext } from "./get-icon-context";
import { Icon } from "./icon";
import { IconContext } from "./icon-context";
import { IconHideTextAt } from "./icon-hide-text-at";
import { IconStyle } from "./icon-style";


/**
 * Return the Icon value for a given EventTypeId
 */
export function getIconForEventType(eventTypeId: EventTypeId): Icon {

	if (eventTypeId == EventTypeId.AccFinalPitch) return Icon.eventType_accFinalPitch;
	if (eventTypeId == EventTypeId.AccInterviewing) return Icon.eventType_accInterviewing;
	if (eventTypeId == EventTypeId.AccMentorMatching) return Icon.eventType_accMentorMatching;
	if (eventTypeId == EventTypeId.AccReading) return Icon.eventType_accReading;
	if (eventTypeId == EventTypeId.PicContest) return Icon.eventType_picContest;
	if (eventTypeId == EventTypeId.PicReading) return Icon.eventType_picReading;
	if (eventTypeId == EventTypeId.AccQuarterliesQ2) return Icon.eventType_accQuarterliesQ2;
	if (eventTypeId == EventTypeId.AccQuarterliesQ3) return Icon.eventType_accQuarterliesQ3;
	if (eventTypeId == EventTypeId.AccQuarterliesQ4) return Icon.eventType_accQuarterliesQ4;
	if (eventTypeId == EventTypeId.AccShowcase) return Icon.eventType_accShowcase;

	return Icon.status_missing;
}


export function getIconContextForEventType(eventTypeId: EventTypeId, iconStyle?: IconStyle, hideText?: IconHideTextAt, text?: string, hasRedFlag?: boolean): IconContext {
	return getIconContext(getIconForEventType(eventTypeId), iconStyle, hideText, text, hasRedFlag);
}