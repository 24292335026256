import { DbConceptName, DbsPerson, PersonAccessBaseFlags, PersonBasicInfo } from "@me-interfaces";
import { DdSite, OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from "@me-services/core/func";
import { EntityWrapper } from "./entity-wrapper";
import { PersonEmail } from "./person-profile/person-email";
import { PersonSites } from "./person-profile/person-sites";
import { PersonAccs } from "./person/person-accs";
import { PersonDoNotContactNote } from "./person/person-do-not-contact-note";
import { PersonNotes } from "./person/person-notes";
import { PersonPics } from "./person/person-pics";
import { PersonPositions } from "./person/person-positions";
import { PersonRelationships } from "./person/person-relationships";
import { PersonTags } from "./person/person-tags";
import { PersonVolunteering } from "./person/person-volunteering";


/** @deprecated DO NOT USE THIS!*/
export class PersonWrapper extends EntityWrapper implements DbsPerson {

	constructor(
		protected person: DbsPerson,
		protected override func: FuncService,
		protected override dd: OldDomainDataService
	) {
		super(person, func, dd);
	}


	override readonly _concept: DbConceptName.Person;

	//
	// DbsPerson Interface
	//
	public get personId() { return this.person.personId; }
	public get firstName() { return this.person.firstName; }
	public get middleInit() { return this.person.middleInit; }
	public get lastName() { return this.person.lastName; }
	public get phone() { return this.person.phone; }
	public get phoneTypeId() { return this.person.phoneTypeId; }
	public get demographicsGiven() { return this.person.demographicsGiven; }
	public get hasOptOutDemographics() { return this.person.hasOptOutDemographics; }
	public get isDeceased() { return this.person.isDeceased; }
	public get isEForAllAdmin() { return this.person.isEForAllAdmin; }
	public get isTechAdmin() { return this.person.isTechAdmin; }
	public get isCrmUser() { return this.person.isCrmUser; }
	public get isPermanent() { return this.person.isPermanent; }
	public get canEditDemographics() { return this.person.canEditDemographics; }
	public get _personExtracts() { return this.person._personExtracts; }
	public get _email() { return this.person._email; }
	public get doNotContactNote() { return this.person.doNotContactNote; }
	public override get updatedUTC() { return this.person.updatedUTC; }
	public override get updatedByPersonId() { return this.person.updatedByPersonId; }
	public get prefixId() { return this.person.prefixId; }
	public get suffixId() { return this.person.suffixId; }
	public get preferredName() { return this.person.preferredName; }
	public get formerName() { return this.person.formerName; }
	public get _profileExtracts() { return this.person._profileExtracts; }
	public get bioEN() { return this.person.bioEN; }
	public get bioES() { return this.person.bioES; }


	//
	// Helpers
	//
	public readonly accs = new PersonAccs(this.person, this.func, this.dd);
	public readonly notes = new PersonNotes(this.person, this.func, this.dd);
	public readonly pics = new PersonPics(this.person, this.func, this.dd);
	public readonly positions = new PersonPositions(this.person, this.func, this.dd);
	public readonly relationships = new PersonRelationships(this.person, this.func, this.dd);
	public readonly tags = new PersonTags(this.person, this.func, this.dd);
	public readonly volunteering = new PersonVolunteering(this.person, this.func, this.dd);
	public readonly doNotContact = new PersonDoNotContactNote(this.person, this.func, this.dd);
	public readonly email = new PersonEmail(this.person, this.func, this.dd);
	public readonly sites = new PersonSites(this.person, this.func, this.dd);


	/**
 * Determine if the person is approved to be either a mentor or a coach. This indicates whether the person will appear
 * in the list of available mentors for a particular site.  This does not state whether the user is a current mentor
 * @param person
 */
	public get isApprovedMentor(): boolean {

		return !!this.dd.tags
			.getByIds(this.person._personExtracts.tagIds)
			.find(tag => tag.name == 'mentor-approved' || tag.name == 'coach-approved');
	}

	/**
	* Determine if the person is currently assigned to an acc team as a mentor or a coach. 
	* @param person
	*/
	public get isCurrentMentor(): boolean {
		return !!this.dd.tags
			.getByIds(this.person._personExtracts.tagIds)
			.find(tag => tag.name == 'mentor-current' || tag.name == 'coach-current');
	}

	/**
	 * Build and return a list of DdSite objects that the person is following
	 * @param person 
	 */
	public get followedSites(): DdSite[] {
		const tagIds = this.person._personExtracts.tagIds;
		return this.dd.sites.objects.filter(site => tagIds.includes(site.tags.follower.tagId));
	}


	public async updateBio(languageId: number, newBio: string) {
		const person = await this.func.person.updateBio({ languageId, newBio, affectedPersonId: this.person.personId });
		this.person = Object.assign(this.person, person);
	}

	public async updateBaseFlags(flags: PersonAccessBaseFlags) {
		const person = await this.func.person.updateBaseAccessFlags({ personId: this.person.personId, flags });
		this.person = Object.assign(this.person, person);
	}

	public async updateBasicInfo(newValues: PersonBasicInfo) {
		const person = await this.func.person.updateBasicInfo(newValues);
		this.person = Object.assign(this.person, person);
	}


	//
	//	This is the webLinkUpdater interface implementation
	//

	public async addWebLink(url: string, webLinkTypeId: number) {
		const data = await this.func.entity.webLinks.add({ entityId: this.person.entityId, url, webLinkTypeId });
		this.person = Object.assign(this.person, data);
	}

	public async removeWebLink(webLinkId: number) {
		const data = await this.func.entity.webLinks.remove({ entityId: this.person.entityId, webLinkId });
		this.person = Object.assign(this.person, data);
	}


	public async updateWebLink(webLinkId: number, url: string, webLinkTypeId: number) {
		const data = await this.func.entity.webLinks.update({ entityId: this.person.entityId, webLinkId, url, webLinkTypeId });
		this.person = Object.assign(this.person, data);
	}

	//
	// Address Updater
	//

	public async updateAddress(zipCode: string, inUS: boolean, addressLines: string) {
		const data = await this.func.entity.address.update({ entityId: this.person.entityId, zipCode, inUS, addressLines });
		this.person = Object.assign(this.person, data);
	}

	public async publicUpdateAddress(zipCode: string, inUS: boolean, addressLines: string) {
		const data = await this.func.entity.publicAddress.update({ entityId: this.person.entityId, zipCode, inUS, addressLines });
		this.person = Object.assign(this.person, data);
	}

}