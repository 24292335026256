import { Injectable } from '@angular/core';
import { SimplePerson } from '@me-interfaces';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';





@Injectable({ providedIn: 'root' })
export class StaffMemberService {

	public readonly staffMembers$ = new ReplaySubject<SimplePerson[]>(1);


	/**
	 * The AppSessionService will return the initial set of members and call this.
	 */
	public setMembers(members: SimplePerson[]) {
		this.staffMembers$.next(members);
	}


	public async getStaffMembers(): Promise<SimplePerson[]> {
		return await this.staffMembers$.pipe(take(1)).toPromise();
	}


	public async getCrmUsers(): Promise<SimplePerson[]> {
		const staffMembers = await this.staffMembers$.pipe(take(1)).toPromise();

		return staffMembers.filter(member => member.isCrmUser);
	}
}