import { Component, Input, OnInit } from '@angular/core';
import { PublicPersonForDialog } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { ShowPublicPersonDialogService } from '../public-person-dialog/SHR-PN_show-public-person-dialog.service';

@Component({
	selector: 'public-person-link',
	templateUrl: './SHR-PN_public-person-link.part.html',
	styleUrls: ['./SHR-PN_public-person-link.part.scss']
})
export class PublicPersonLinkPart implements OnInit {

	@Input() person: PublicPersonForDialog;

	constructor(
		private func: FuncService,
		public showPublicPersonDialogService: ShowPublicPersonDialogService,) {
	}

	ngOnInit() {
		if (!this.person) throw new Error(`Missing person attribute`);
	}

	async open() {
		await this.showPublicPersonDialogService.show(this.person);
	}
}