import { Component, Input } from '@angular/core';
import { ExplorerNoteBox } from './explorer-note-box';

@Component({
	selector: 'explorer-note-box',
	templateUrl: './explorer-note-box.part.html',
	styleUrls: ['./explorer-note-box.part.scss'],
})
export class ExplorerNoteBoxPart {

	@Input() public box: ExplorerNoteBox;

}