import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { DbConceptName, DbsPerson, Person, Site, SiteVolunteerApplication, Tag, TagName } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { getIconContext, Icon, IconContext } from '@me-shared-parts/UI-common';
import { SiteAreaService } from '@SITE-area';


const ADMIN_ROUTE = '/people/volunteers/:volunteerApplicationId';

interface ViewModel {
	volunteerApplicationId: number,
	applicant: Person,
	createdDate: string,
	daysSince: string,
	language: string,
	appliedRoles: string[],
	city?: string,
	closedDate: string,
	closedBy: DbsPerson,
	bioEN: string,
	bioES: string,
	foundOutHow: string,
	company?: string,
	title?: string,
	tags: Tag[],
	approvedRoles: Tag[],
	icons: {
		person: IconContext,
		closedByPerson: IconContext,
	}
}


@Component({
	selector: 'volunteer-application-closed',
	templateUrl: './closed-application.part.html',
	styleUrls: ['./closed-application.part.scss'],
})
export class VolunteerApplicationClosedPart extends DestroyablePart implements OnInit {

	@Input() volunteerApplicationId: number;
	@Output() closeDialog = new EventEmitter();

	public vm: ViewModel = undefined;
	site: Site;
	siteTags: Tag[];
	readonly: boolean;

	constructor(
		public router: Router,
		private siteAreaService: SiteAreaService,
		private util: UtilityService,
		private ds: DataService,
	) {
		super();
	}


	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe([
			this.siteAreaService.accessAndId$,
			this.siteAreaService.data$,
		], async ([accessAndId, data]) => {
			this.readonly = accessAndId?.access?.root != 'Write';

			if (!data) return;
			this.site = data.site;
			this.siteTags = await this.ds.admin.tag.getTagForSite(this.site.siteId);
			this.updateView(data.volunteerApplications.find(app => app.siteVolunteerApplication.volunteerApplicationId == this.volunteerApplicationId));

		});

	}

	private async updateView(model: {
		siteVolunteerApplication: SiteVolunteerApplication,
		applicant: Person,
	}) {

		const langTagIds = model.applicant.tags.filter(tag => tag.tagId == 37 || tag.tagId == 38); // 37 => english; 38 => Spanish;
		const language = langTagIds.length ? langTagIds.map(l => {
			if (l.tagId == 37) return 'English';
			else return 'Spanish';
		}).join(', ') : 'No Language';

		const closedBy = await this.ds.admin.person.getOne(model.siteVolunteerApplication.closedByPersonId);
		this.vm = {
			volunteerApplicationId: model.siteVolunteerApplication.volunteerApplicationId,
			applicant: model.applicant,
			createdDate: this.util.date.formatUTC(model.siteVolunteerApplication.application.createdUTC, 'MMM D, YYYY', 'No Time', this.ds.languageId),
			daysSince: this.util.date.getDaysSince(model.siteVolunteerApplication.application.createdUTC),
			language,
			appliedRoles: this.buildRoles(model),
			city: model.applicant.asSingleton.zipId ? this.ds.domain.zip.getOne(model.applicant.asSingleton.zipId).cityAndState || '' : '',
			closedDate: this.util.date.formatUTC(model.siteVolunteerApplication.closedUTC, 'MMM D, YYYY', 'No Time', this.ds.languageId),
			closedBy,
			bioEN: model.applicant.asSingleton.bioEN,
			bioES: model.applicant.asSingleton.bioES,
			foundOutHow: model.siteVolunteerApplication.application.foundOutHow,
			company: model.siteVolunteerApplication.application.company,
			title: model.siteVolunteerApplication.application.title,
			tags: [...await this.ds.admin.tag.getManyPackagesAsArray(model.applicant.tags.filter(tag => tag.siteId && tag.siteId == this.site.siteId).map(tag => tag.tagId))],
			approvedRoles: this.buildApprovedTags(model),
			icons: {
				person: getIconContext(Icon.concept_person, undefined, 'never', model.applicant.name),
				closedByPerson: getIconContext(Icon.concept_person, undefined, 'never', closedBy._name),
			}
		};
	}

	private buildRoles(model: {
		siteVolunteerApplication: SiteVolunteerApplication,
		applicant: Person,
	}): string[] {
		const roles = [];
		// Roles applicant applied for
		if (model.siteVolunteerApplication.application.applyMentor) roles.push('Mentor');
		if (model.siteVolunteerApplication.application.applyReader) roles.push('Reader');
		if (model.siteVolunteerApplication.application.applyInterviewer) roles.push('Interviewer');
		if (model.siteVolunteerApplication.application.applyClassSpeaker) roles.push('Class Speaker');
		if (model.siteVolunteerApplication.application.applyOfficeHours) roles.push('Office Hours');
		if (model.siteVolunteerApplication.application.applyWorkshops) roles.push('Workshops');
		if (model.siteVolunteerApplication.application.applyEventhelper) roles.push('Event Volunteer');
		return roles;
	}

	private buildApprovedTags(model: {
		siteVolunteerApplication: SiteVolunteerApplication,
		applicant: Person,
	}) {
		const tags: Tag[] = [];

		// Approved Roles
		if (model.siteVolunteerApplication.approveMentor != 'N') model.siteVolunteerApplication.approveMentor == 'Y' ? tags.push(this.getTag('mentor-approved')) : tags.push(this.getTag('mentor-consider'));
		if (model.siteVolunteerApplication.approveReader != 'N') model.siteVolunteerApplication.approveReader == 'Y' ? tags.push(this.getTag('reader-approved')) : tags.push(this.getTag('reader-consider'));
		if (model.siteVolunteerApplication.approveInterviewer != 'N') model.siteVolunteerApplication.approveInterviewer == 'Y' ? tags.push(this.getTag('interviewer-approved')) : tags.push(this.getTag('interviewer-consider'));
		if (model.siteVolunteerApplication.approveClassSpeaker != 'N' || model.siteVolunteerApplication.approveOfficeHours != 'N' || model.siteVolunteerApplication.approveWorkshops != 'N') {
			if (model.siteVolunteerApplication.approveClassSpeaker == 'Y' || model.siteVolunteerApplication.approveOfficeHours == 'Y' || model.siteVolunteerApplication.approveWorkshops == 'Y') tags.push(this.getTag('specialist-approved'));
			else tags.push(this.getTag('specialist-consider'));
		}
		if (model.siteVolunteerApplication.approveEventhelper != 'N') model.siteVolunteerApplication.approveEventhelper == 'Y' ? tags.push(this.getTag('eventhelper-approved')) : tags.push(this.getTag('eventhelper-consider'));
		return tags;
	}

	public getTag(name: TagName) {
		return this.siteTags.find(tag => tag.name == name);
	}

	public async reopen() {
		const volunteerApplicationId = this.volunteerApplicationId;
		await this.siteAreaService.volunteer.actions.reopenApplication({ volunteerApplicationId });
		this.closeDialog.emit();
	}

	async explorePerson(value: 'applicant' | 'closedBy') {
		if (value == 'applicant') await this.ds.explorer.explore(DbConceptName.Person, this.vm.applicant.personId);
		if (value == 'closedBy') await this.ds.explorer.explore(DbConceptName.Person, this.vm.closedBy.personId);
	}
}