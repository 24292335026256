<kendo-popup *ngIf="show" [offset]="offset">
	<ul class="me-grid-menu-part">
		<li *ngFor="let dataItem of actions" (click)="onActionItemClick(dataItem)"
			[class.me-grid-menu-not-enabled]='!dataItem.enabled' [class.me-grid-menu-separated]='dataItem.separated'>
			<div class='me-grid-action' [class.me-grid-action-not-enabled]='!dataItem.enabled'>
				<i class="{{ getIconClass(dataItem.icon) }} fa-fw"></i>
				<span style='padding: 0 0.5em'>{{ (labelsService.getLabel$ | async)(dataItem.label) }}</span>
			</div>
		</li>
	</ul>
</kendo-popup>