import { Component, Input } from '@angular/core';
import { LabelKey } from '@me-interfaces';

@Component({
	selector: 'dashboard-return',
	template: `
		<div class='dashboard-return-part'>
			<message-box alertType='warning'>
				<me-label [key]='labelKey'></me-label>
			</message-box>
			<button-container>
				<spinner-button routerLink='/access/my/dashboard'>
					<me-label key='Go to My Dashboard'></me-label>
				</spinner-button>
			</button-container>
		</div>
		`,
})
export class DashboardReturnPart {
	@Input() labelKey: LabelKey | string = 'You do not have access to view this page.';
}