import { ApplicationStatusId, DbsAccelerator, OpenApplicationStatusIds, SubmittedApplicationStatusIds } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { SpecificAccIndicatorValues } from "../interfaces";

const WEEK_MS = 7 * 24 * 60 * 60 * 1000;


/**
 * @returns Just the accelerator values
 */
export async function getSpecificAccIndicatorValues(
	ds: DataService,
	util: UtilityService,
	accIds: number[],
): Promise<SpecificAccIndicatorValues> {

	//
	// Gather related objects
	//
	const accelerators = await ds.admin.accelerator.getManyAsArray(accIds);

	const applications = (await ds.admin.application.getAllAsArray()).filter(a => accIds.includes(a.accId));
	const applicationIds = applications.map(a => a.applicationId);

	const accTeams = (await ds.admin.accTeam.getAllAsArray()).filter(t => accIds.includes(t.accId));
	const accTeamIds = accTeams.map(t => t.accTeamId);


	//
	// Interviewers
	//
	const accInterviewersByAccId = await ds.admin.accInterviewer.getByAccIds(accIds);
	const accInterviewers = util.array.fromArrayMap(accInterviewersByAccId, accIds).filter(r => r.interviewedApplicationIds.length > 0);
	const accInterviewerPersonIds = accInterviewers.map(r => r.personId);

	const interviewers = util.array.cleanNumericIds(accInterviewerPersonIds).length;


	//
	// Interviews
	//
	let interviews = 0;
	for (const accInterviewer of accInterviewers) { interviews += accInterviewer.interviewedApplicationIds.filter(id => applicationIds.includes(id)).length; }



	//
	// Applications and Entrepreneurs Accepted
	//
	const applicationsByAccId = await ds.admin.application.getByAccIds(accIds);
	const accApplications = util.array.fromArrayMap(applicationsByAccId, accIds);

	const accApplicationsOpen = accApplications.filter(a => OpenApplicationStatusIds.includes(a.applicationStatusId)).length;
	const accApplicationsClosed = accApplications.length - accApplicationsOpen;

	const acceptedApplicationIds = accApplications.filter(a => a.applicationStatusId == ApplicationStatusId.TeamPromoted).map(a => a.applicationId);
	const acceptedApplicationParticipantsByApplicationId = await ds.admin.applicationParticipant.getArraysByForeignIds('applicationId', acceptedApplicationIds);
	const acceptedApplicationParticipants = util.array.fromArrayMap(acceptedApplicationParticipantsByApplicationId, acceptedApplicationIds);
	const acceptedApplicationParticipantPersonIds = acceptedApplicationParticipants.map(p => p.personId);

	const acceptedTeamsByApplicationId = await ds.admin.accTeam.getByApplicationIds(acceptedApplicationIds);
	const acceptedTeams = util.array.fromArrayMap(acceptedTeamsByApplicationId, acceptedApplicationIds);
	const acceptedAccTeamIds = acceptedTeams.map(t => t.accTeamId);
	const acceptedTeamMembersByAccTeamId = await ds.admin.accTeamMember.getArraysByForeignIds('accTeamId', acceptedAccTeamIds);
	const acceptedTeamMembers = util.array.fromArrayMap(acceptedTeamMembersByAccTeamId, acceptedAccTeamIds);
	const acceptedTeamMembersE = acceptedTeamMembers.filter(m => m.role == 'E');
	const acceptedTeamMemberPersonIds = acceptedTeamMembersE.map(m => m.personId);

	const accApplicationsAccepted = acceptedApplicationIds.length;
	const accEntrepreneursAccepted = util.array.cleanNumericIds([...acceptedApplicationParticipantPersonIds, ...acceptedTeamMemberPersonIds]).length;


	//
	// Application Acceptance Rate
	//
	const submittedApplicationIds = accApplications.filter(a => SubmittedApplicationStatusIds.includes(a.applicationStatusId)).map(a => a.applicationId);

	const accApplicationsSubmitted = submittedApplicationIds.length;
	const accApplicationAcceptanceRate = accApplicationsSubmitted > 0 ? accApplicationsAccepted / accApplicationsSubmitted : 0;


	//
	// Mentors
	//
	const mentorships = acceptedTeamMembers.filter(m => m.role !== 'E').length;
	const mentorQuits = acceptedTeamMembers.filter(m => m.role == 'X').length;
	let mentorRatio = acceptedTeams.length > 0 ? mentorships / acceptedTeams.length : 0
	mentorRatio = Math.round(mentorRatio * 10) / 10;

	const mentors = util.array.cleanNumericIds(acceptedTeamMembers.filter(m => m.role !== 'E').map(m => m.personId)).length;


	//
	// Graduation Rate
	//
	let dv = await calcDropoutValues(accelerators, ds, util, 12);
	const teamsGraduated = dv.teamsNotDroppedOut;
	const teamsGraduatable = dv.teamsAll;
	const teamGraduationRate = dv.teamFinishRate;
	const entrepreneursGraduated = dv.entrepreneursNotDroppedOut;


	//
	// Graduation Rate
	//
	dv = await calcDropoutValues(accelerators, ds, util, 52);
	const teamsCompleted = dv.teamsNotDroppedOut;
	const teamsCompletable = dv.teamsAll;
	const teamCompletionRate = dv.teamFinishRate;
	const entrepreneursCompleted = dv.entrepreneursNotDroppedOut;


	//
	// 3 Years
	//
	const year3AccIds = accelerators
		.filter(a => {
			const endOfCurriculum = a.applStartUTC * 1000 + WEEK_MS * 52 * 3;
			return Date.now() > endOfCurriculum;
		})
		.map(a => a.accId);

	const year3AccTeamsByAccId = await ds.admin.accTeam.getByAccIds(year3AccIds);
	const year3AccTeams = util.array.fromArrayMap(year3AccTeamsByAccId, year3AccIds);

	//
	// Get and clean companyIds in case there are multiple teams with one companyId
	//
	const year3CompanyIds = util.array.cleanNumericIds(year3AccTeams.map(t => t.company.companyId));
	const year3Companies = await ds.admin.company.getManyAsArray(year3CompanyIds);

	const acc3s = year3AccIds.length;
	const acc3Businesses = year3Companies.length;
	const acc3BusinessesOpen = year3Companies.filter(c => c.status == 'Open').length;
	const acc3BusinessesOpenRate = acc3Businesses > 0 ? acc3BusinessesOpen / acc3Businesses : 0;

	return {
		accelerators: accelerators.filter(a => !a.canceled).length,
		accApplicationsOpen,
		accApplicationsClosed,
		accApplicationsSubmitted,
		accApplicationsAccepted,
		accApplicationAcceptanceRate,
		accEntrepreneursAccepted,
		interviewers,
		interviews,
		mentors,
		mentorships,
		mentorQuits,
		mentorRatio,
		teamsGraduated,
		teamsGraduatable,
		teamGraduationRate,
		entrepreneursGraduated,
		teamsCompleted,
		teamsCompletable,
		teamCompletionRate,
		entrepreneursCompleted,
		acc3s,
		acc3Businesses,
		acc3BusinessesOpen,
		acc3BusinessesOpenRate, // Open Businesses of 3+ year old accs
	};
}

async function calcDropoutValues(
	accelerators: DbsAccelerator[],
	ds: DataService,
	util: UtilityService,
	week: number,
) {

	const accIds = accelerators
		.filter(a => {
			const weeksLater = a.applStartUTC * 1000 + WEEK_MS * week;
			return Date.now() > weeksLater;
		})
		.map(a => a.accId);

	const accTeamsByAccId = await ds.admin.accTeam.getByAccIds(accIds);
	const accTeams = util.array.fromArrayMap(accTeamsByAccId, accIds);

	const accTeamsNotDroppedOut = accTeams.filter(t => t.droppedOutWeek == undefined || t.droppedOutWeek > week);
	const accTeamsNotDroppedOutAccTeamIds = accTeamsNotDroppedOut.map(t => t.accTeamId);

	const teamsNotDroppedOut = accTeamsNotDroppedOut.length;
	const teamsAll = accTeams.length;
	const teamFinishRate = teamsAll > 0 ? teamsNotDroppedOut / teamsAll : 0;

	const postCurriculumAccTeamsMembersByAccId = await ds.admin.accTeamMember.getArraysByForeignIds('accTeamId', accTeamsNotDroppedOutAccTeamIds);
	const postCurriculumAccTeamsMembers = util.array.fromArrayMap(postCurriculumAccTeamsMembersByAccId, accTeamsNotDroppedOutAccTeamIds);
	const entrepreneursNotDroppedOut = postCurriculumAccTeamsMembers.filter(m => m.role == 'E').length;

	return { teamsNotDroppedOut, teamsAll, entrepreneursNotDroppedOut, teamFinishRate };
}
