import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { FieldKindAddress } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { SelectableOption } from '../../select/selectable-option';
import { AddressFieldPart } from '../SHR-UI_address-field.part';

@Component({
	selector: 'address-field-dialog',
	templateUrl: './SHR-UI_address-field.dialog.html',
	styleUrls: ['./SHR-UI_address-field.dialog.scss']
})
export class AddressFieldDialog extends DestroyablePart implements OnInit {

	@ViewChild('zipField') zipField: ElementRef;
	@ViewChild('textArea') textArea: ElementRef;


	inlineLabels = this.labelsService.trackInlineLabels(this, [
		'Address',
		'Country',
		'Within The U.S.',
		"Outside The U.S.",
		'Zip Code',
		'Street Address',
		'Complete Address including Postal Code',
	]);


	countryOptions: SelectableOption[] = [
		{ value: 'Y', text: 'Within The U.S.' },
		{ value: 'N', text: 'Outside The U.S.' }
	];


	saveAction: DialogAction = {
		id: 'save',
		enabled: false,
		visible: true,
		label: ':Save',
		linkType: "callback",
		willCloseDialog: true,
		callback: this.doSave.bind(this),
	};


	cancelAction: DialogAction = {
		id: 'cancel',
		enabled: true,
		visible: true,
		label: ':Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};


	actions: DialogAction[] = [this.saveAction, this.cancelAction];

	basePart: AddressFieldPart;
	pendingValue: FieldKindAddress;

	lookingUpZip = false;
	zipCode: string;
	invalidZip = false;  // A five digit number that isn't actually a zip code


	constructor(
		@Inject(MAT_DIALOG_DATA) dialog: DialogContext<AddressFieldPart>,
		public dialogRef: MatDialogRef<AddressFieldPart>,
		private func: FuncService,
		private labelsService: LabelsService,
	) {
		super();

		this.basePart = dialog.data;
		this.pendingValue = { ...this.basePart.currentValue };
		this.zipCode = this.formatZipCode(this.pendingValue.zipId);

		this.enableSaveButton();
	}


	ngOnInit() {
		super.initDestroyable();

		//
		// Clicking the launch button calls blur which prematurely sets the Required Field error
		//
		setTimeout(() => this.basePart.errorText = undefined);
	}


	enableSaveButton() {

		//
		// cityAndState will only be set if there is a valid zipId
		//
		const { inUS, cityAndState, address } = this.pendingValue;

		this.saveAction.enabled = !this.basePart.field.required || (!!address && (inUS == 'N' || (inUS == 'Y' && !!cityAndState)));
	}


	inUsChange(inUS: 'N' | 'Y') {
		this.pendingValue.inUS = inUS;
		this.invalidZip = false;
		this.pendingValue.zipId = undefined;
		this.pendingValue.cityAndState = undefined;
		this.zipCode = '';

		if (inUS == 'Y') setTimeout(() => this.zipField.nativeElement.focus());

		this.enableSaveButton();
	}


	formatZipCode(zipId?: number) {
		if (!zipId) return undefined;
		return ("00000" + zipId).slice(-5);
	}


	async zipChange(e) {
		this.saveAction.enabled = false;
		this.invalidZip = false;
		this.pendingValue.zipId = undefined;
		this.pendingValue.cityAndState = undefined;

		//
		// Filter out non-digit characters
		//
		const code = (e.target.value || '')
			.split('')
			.filter(c => ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(c))
			.join('');

		//
		// If it has the correct format, validate that it is actually a real zip
		//
		if (code.length == 5) {

			this.lookingUpZip = true;
			if (this.zipCode !== code) this.zipCode = code;

			const zipData = await this.func.public.check.getZipData({ zipcode: code });

			if (zipData.valid) {
				this.pendingValue.zipId = parseInt(code, 10);
				this.pendingValue.cityAndState = zipData.name;
				this.enableSaveButton();
				setTimeout(() => this.textArea.nativeElement.focus());
			}
			else {
				this.invalidZip = true;
				setTimeout(() => {
					this.zipField.nativeElement.focus();
					this.zipField.nativeElement.select();
				});
			}

			this.lookingUpZip = false;
		}
	}


	streetChange(e) {
		this.pendingValue.address = (e.target.value || '').trim('');
		this.enableSaveButton();
	}


	async doSave() {
		this.basePart.setPendingValue(this.pendingValue);
		await this.basePart.attemptToSave();
	}


	getStreet(inUs: boolean) {
		if (inUs) return this.inlineLabels['Street Address'];
		else return this.inlineLabels['Complete Address including Postal Code'];
	}
}