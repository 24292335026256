import { DbsPerson, PersonProfileAccessFlagsExtractForSite } from "@me-interfaces";
import { OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from "@me-services/core/func";

/** @deprecated DO NOT USE THIS!*/
export class PersonSites {

	constructor(private readonly person: DbsPerson, private func: FuncService, private dd: OldDomainDataService) {
	}


	/**
	 * Determine what access this person has for a given site
	 * @param siteCode 
	 */
	public getAccessFlags(siteCode: string): PersonProfileAccessFlagsExtractForSite {
		if (!this.person) return <PersonProfileAccessFlagsExtractForSite>{};

		const access = this.person._profileExtracts.accessFlags.bySiteCode[siteCode];
		return access || <PersonProfileAccessFlagsExtractForSite>{};
	}

}