import { AssessmentsData } from '@me-interfaces';
import { AssessmentLevel, FlatAssessmentsData } from './SHR-CMN_assessment-interfaces';
import { Assessments } from '../rating-results/SHR-CMN_rating-interfaces';


export function getAnswerKey(personId, companyId, questionId) {
	return `a-${personId}-${companyId}-${questionId}`;
}


//
// The RatingsData is a tree of person -> company -> question
// This function flattens it into arrays so buildLevels()
// can organize into the requested order.
//
export function flattenData(assessmentsData: AssessmentsData[]): FlatAssessmentsData {

	const data: FlatAssessmentsData = {
		personList: [],
		companyList: [],
		questionList: [],
		answers: {},
		assessments: {},
	};

	for (const d of assessmentsData) {
		const pKey = `p${d.person.personId}`;
		data.assessments[pKey] = [0, 0, 0, 0];
		data.personList.push(d.person);

		for (const r of d.ratings) {

			const cKey = `c${r.company.companyId}`;
			data.assessments[pKey + '/' + cKey] = [0, 0, 0, 0];

			if (data.assessments[cKey] == undefined) {
				data.assessments[cKey] = [0, 0, 0, 0];
				data.companyList.push(r.company);
			}

			// We should either have assessments for all or none
			// If none, then just set to NO
			const assessment = (r.rating || 1) - 1;
			data.assessments[pKey][assessment]++; // add to the person totals
			data.assessments[cKey][assessment]++; // add to the company totals
			data.assessments[pKey + '/' + cKey][assessment]++; // set the single p/c combination

			for (const a of r.answers) {
				if (!data.questionList.find(q => q.questionId == a.question.questionId)) {
					data.questionList.push(a.question);
				}

				data.answers[getAnswerKey(d.person.personId, r.company.companyId, a.question.questionId)] = a;
			}
		}
	}

	return data;
}


export function buildLevels([first, second, third]: [string, string, string], data: FlatAssessmentsData): AssessmentLevel[] {

	const levels: AssessmentLevel[] = [];

	for (const l1 of data[first + 'List']) {

		const level1: AssessmentLevel = {
			assessments: [0, 0, 0, 0],
			ratings: [0, 0, 0, 0, 0],
			aScore: 0, rScore: 0, score: 0,
			tooltip: '',
			children: [],
		};
		level1[first] = l1;

		for (const l2 of data[second + 'List']) {

			const level2: AssessmentLevel = {
				assessments: [0, 0, 0, 0],
				ratings: [0, 0, 0, 0, 0],
				aScore: 0, rScore: 0, score: 0,
				tooltip: '',
				children: [],
			};
			level2[second] = l2;

			for (const l3 of data[third + 'List']) {
				const level3: AssessmentLevel = {
					assessments: [0, 0, 0, 0],
					ratings: [0, 0, 0, 0, 0],
					aScore: 0, rScore: 0, score: 0,
					tooltip: '',
				}
				level3[third] = l3;

				//
				// The l1, l2, and l3 variables will be a person, company or question
				// depending on what was passed into this function. Because the key
				// property names are unique, we can just look for the first that is
				// defined.
				//
				const personId = l1.personId || l2.personId || l3.personId;
				const companyId = l1.companyId || l2.companyId || l3.companyId;
				const questionId = l1.questionId || l2.questionId || l3.questionId;

				const a = data.answers[getAnswerKey(personId, companyId, questionId)];
				if (a) {
					level3.answer = a;

					level2.children.push(level3);
					if (a.question.questionTypeId == 4) {
						const questionValue = (+a.answer) - 1;
						level3.ratings[questionValue]++;
						level2.ratings[questionValue]++;
						level1.ratings[questionValue]++;
					}
				}
			}
			if (level2.children.length) level1.children.push(level2);
		}
		if (level1.children.length) levels.push(level1);
	}

	return levels;
}

//
// Tally up the hierarchy of assessment counts
//
export function countAssessments(levels: AssessmentLevel[], assessments: { [key: string]: Assessments }) {
	const a = assessments;

	for (const l1 of levels) {

		const pKey = (l1.person ? `p${l1.person.personId}` : undefined);
		const cKey = (l1.company ? `c${l1.company.companyId}` : undefined);

		if (pKey) l1.assessments = a[pKey];
		else if (cKey) l1.assessments = a[cKey];

		let pKey2 = pKey;
		let cKey2 = cKey;

		for (const l2 of l1.children) {

			pKey2 = (l2.person ? `p${l2.person.personId}` : undefined) || pKey2;
			cKey2 = (l2.company ? `c${l2.company.companyId}` : undefined) || cKey2;

			if (pKey2 && !cKey2) l2.assessments = a[pKey2];
			else if (cKey2 && !pKey2) l2.assessments = a[cKey2];
			else l2.assessments = a[`${pKey2}/${cKey2}`];

			let pKey3 = pKey2;
			let cKey3 = cKey2;

			for (const l3 of l2.children) {

				pKey3 = (l3.person ? `p${l3.person.personId}` : undefined) || pKey3;
				cKey3 = (l3.company ? `c${l3.company.companyId}` : undefined) || cKey3;

				if (pKey3 && !cKey3) l3.assessments = a[pKey3];
				else if (cKey3 && !pKey3) l3.assessments = a[cKey3];
				else l3.assessments = a[`${pKey3}/${cKey3}`];
			}
		}
	}
}