<p>
	<b>
		<me-label [key]='welcomeMessage'></me-label>
	</b>
</p>

<p class='bad-email'>{{(auth.me.firebaseUser$ | async)?.email}}</p>

<p class="text-left">
	<me-label key='md:Add Me Message'></me-label>
</p>
<p></p>
<div class="text-center">
	<button class='btn' (click)='cancelMe()'><me-label key='Cancel'></me-label></button>
	<spinner-button (safeClick)="addMe()" [disabled]='adding' [spinning]='adding'>
		<me-label key='Add Me!'></me-label>
	</spinner-button>
</div>