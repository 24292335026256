import { Component, Input, OnChanges } from '@angular/core';
import { DbsCompany } from '@me-interfaces';
import { DialogService } from '@me-services/ui/dialog';
import { getIconContext, Icon, IconContext, IconHideTextAt } from '@me-shared-parts/UI-common';
import { CompanyExtractDialog } from '../company-extract-dialog/SHR-CM_company-extract-dialog.dialog';

@Component({
	selector: 'company-extract-button',
	templateUrl: './SHR-CM_company-extract-button.part.html',
	styleUrls: ['./SHR-CM_company-extract-button.part.scss']
})
export class CompanyExtractButtonPart implements OnChanges {

	@Input() company: DbsCompany;
	@Input() hideText?: IconHideTextAt = 'never';

	icon: IconContext = getIconContext(Icon.concept_company);


	constructor(private dialogService: DialogService) {
	}

	ngOnChanges() {
		this.icon = getIconContext(Icon.concept_company, undefined, this.hideText || 'never', this.company.longName);
	}


	async open() {
		await this.dialogService.showCustom(
			CompanyExtractDialog,
			{
				data: {
					company: this.company,
				},
			},
			800, 500);
	}
}