import { Component, Input, OnInit } from '@angular/core';
import { DbsPerson } from '@me-interfaces';
import { SelectEvent } from '@progress/kendo-angular-layout';

export const enum PERSON_EDITOR_KEYS {
	PROFILE = 0,
	TAGS = 1,
	NOTES = 2,
	CONNECTIONS = 3,
}

@Component({
	selector: 'person-editors',
	templateUrl: './SHR-ED_person-editors.part.html',
	styleUrls: ['SHR-ED_person-editors.part.scss'],
})
export class PersonEditorsPart implements OnInit {
	@Input() person: DbsPerson;
	@Input() editorKey: PERSON_EDITOR_KEYS = PERSON_EDITOR_KEYS.PROFILE;
	@Input() selectorKey?: string;
	@Input() inDialog?: boolean = false;

	tabStripClass = 'tabs-only';
	popoutUrl = '';

	selectors = {
		profile: undefined,
		notes: undefined,
		tags: undefined,
		connections: undefined,
	}

	async ngOnInit() {

		if (this.selectorKey) {
			if (this.editorKey == PERSON_EDITOR_KEYS.PROFILE) this.selectors.profile = this.selectorKey;
			else if (this.editorKey == PERSON_EDITOR_KEYS.TAGS) this.selectors.tags = this.selectorKey;
			else if (this.editorKey == PERSON_EDITOR_KEYS.NOTES) this.selectors.notes = this.selectorKey;
			else if (this.editorKey == PERSON_EDITOR_KEYS.CONNECTIONS) this.selectors.connections = this.selectorKey;
		}

		if (this.inDialog) this.tabStripClass = `${this.tabStripClass} dialog`;

		this.popoutUrl = `https://eforall.app/access/contacts/people/${this.person.personId}/editors/profile`;

	}

	onTabSelect(e: SelectEvent) {
		this.editorKey = e.index;
	}

}
