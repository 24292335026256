import { ApplicationStatusId, DbsApplication } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { AccProgramResults, CommonProgramResults, createEmptyAccResults, createEmptyPicResults, PicProgramResults } from "./program-results";
import { renderAccResults, renderPicResults } from "./rendering";

export async function getProgramResultsForCompanies(
	util: UtilityService,
	ds: DataService,
	companyIds: number[],
): Promise<{ accResults: Record<number, string>, picResults: Record<number, string> }> {

	const applications = (await ds.admin.application.getAllAsArray()).filter(a => companyIds.includes(a.companyId));

	// const accIds = util.array.cleanNumericIds(applications.filter(a => !!a.accId).map(a => a.accId));
	// const acceleratorMap = await ds.admin.accelerator.getManyAsMap(accIds);

	// const picIds = util.array.cleanNumericIds(applications.filter(a => !!a.picId).map(a => a.picId));
	// const pitchContestMap = await ds.admin.pitchContest.getManyAsMap(picIds);

	const { accResults } = await getAccProgramResultsForCompanies(
		util, ds, companyIds,
		applications.filter(a => !!a.accId)
	);

	const { picResults } = await getPicProgramResultsForCompanies(
		util, ds, companyIds,
		applications.filter(a => !!a.picId)
	);

	return { accResults, picResults };
}


/**
 * Map an application status to a result counter and increment it
 */
function addCommonResult(applicationStatusId: ApplicationStatusId, results: CommonProgramResults) {

	const pendingStatusIds = [ApplicationStatusId.AcceptPending, ApplicationStatusId.ReadPending, ApplicationStatusId.InterviewPending, ApplicationStatusId.SelectPending, ApplicationStatusId.TeamPending];
	const abandonedStatusIds = [ApplicationStatusId.AcceptAbandoned, ApplicationStatusId.ReadAbandoned, ApplicationStatusId.InterviewAbandoned, ApplicationStatusId.SelectAbandoned];
	const withdrawnStatusIds = [ApplicationStatusId.AcceptWithdrawn, ApplicationStatusId.ReadWithdrawn, ApplicationStatusId.InterviewWithdrawn, ApplicationStatusId.SelectWithdrawn];
	const rejectedStatusIds = [ApplicationStatusId.AcceptRejected, ApplicationStatusId.ReadRejected, ApplicationStatusId.InterviewRejected, ApplicationStatusId.SelectRejected];
	const deferredStatusIds = [ApplicationStatusId.AcceptDeferred, ApplicationStatusId.ReadDeferred, ApplicationStatusId.InterviewDeferred, ApplicationStatusId.SelectDeferred];
	const copiedForwardStatusIds = [ApplicationStatusId.AcceptCopiedForward, ApplicationStatusId.ReadCopiedForward, ApplicationStatusId.InterviewCopiedForward, ApplicationStatusId.SelectCopiedForward];
	const promotedStatusIds = [ApplicationStatusId.TeamPromoted];

	if (pendingStatusIds.includes(applicationStatusId)) results.pending++;
	else if (abandonedStatusIds.includes(applicationStatusId)) results.abandoned++;
	else if (withdrawnStatusIds.includes(applicationStatusId)) results.withdrawn++;
	else if (rejectedStatusIds.includes(applicationStatusId)) results.rejected++;
	else if (deferredStatusIds.includes(applicationStatusId)) results.deferred++;
	else if (copiedForwardStatusIds.includes(applicationStatusId)) results.copiedForward++;
	else if (promotedStatusIds.includes(applicationStatusId)) results.promoted++;
}


async function getAccProgramResultsForCompanies(
	util: UtilityService,
	ds: DataService,
	companyIds: number[],
	applications: DbsApplication[],
): Promise<{ accResults: Record<number, string> }> {

	const resultsByCompanyId: Record<number, AccProgramResults> = {};

	for (const application of applications) {

		const companyId = application.companyId;
		const applicationStatusId = application.applicationStatusId;

		if (!resultsByCompanyId[companyId]) resultsByCompanyId[companyId] = createEmptyAccResults();
		const results = resultsByCompanyId[companyId];

		addCommonResult(applicationStatusId, results);

		//TODO: Add acc specific results
	}


	const accResults: Record<number, string> = {};

	for (const companyId of companyIds) {
		const results = resultsByCompanyId[companyId] || createEmptyAccResults();
		accResults[companyId] = renderAccResults(results);
	}

	return { accResults };
}


async function getPicProgramResultsForCompanies(
	util: UtilityService,
	ds: DataService,
	companyIds: number[],
	applications: DbsApplication[],
): Promise<{ picResults: Record<number, string> }> {

	const resultsByCompanyId: Record<number, PicProgramResults> = {};

	for (const application of applications) {

		const companyId = application.companyId;
		const applicationStatusId = application.applicationStatusId;

		if (!resultsByCompanyId[companyId]) resultsByCompanyId[companyId] = createEmptyPicResults();
		const results = resultsByCompanyId[companyId];

		addCommonResult(applicationStatusId, results);

		//TODO: Add pic specific results
	}


	const picResults: Record<number, string> = {};

	for (const companyId of companyIds) {
		const results = resultsByCompanyId[companyId] || createEmptyPicResults();
		picResults[companyId] = renderPicResults(results);
	}

	return { picResults };
}