import { AccAreaService, AccMentorConfigDialog, AccPreAcceleratorPageService } from '@ACC-area';
import { ManageVolunteersDialog, SiteAreaService } from '@SITE-area';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ADDROW_GRID_ACTION_KEY, GridAction, GridColumnType, GridSetup } from '@me-grid';
import { AccMatchableMentor, AccMatchingRollups, AccStageId, AccTeam, AgreementTypeId, DbaAccMatchAssessment, Event, EventTypeId, Tag } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogService } from '@me-services/ui/dialog';
import { GridPart, Icon } from '@me-shared-parts/UI-common';
import { Observable, combineLatest, mergeMap } from 'rxjs';


interface MentorRow {
	personId: number,
	name: string,
	eventId: number,
	eventNum: number,
	eventDate: Date,
	assessmentsGivenTotal: number,
	assessmentsGiven: string,
	assessmentsRecvd: string,
	assessmentsGivenPercentage: number,
	availability: number,
	positivity: number,
	positivityGiven: number,
	positivityReceived: number,
	noOfMeetings: string,
	overlaps: number,
	median: number,
	totalTeams: number,
	totalMeetings: number,
	roleMeetings: number,
	useCount: number,
	canCoach: boolean,
	handbookSigned: string,
	mediaSigned: string,
	teamAssigned: boolean,
	pitch: string,
	updatedUTC: number,
}

@Component({
	selector: 'acc-mm-mentors-view-part',
	templateUrl: './acc-mm-mentors-view.part.html',
})
export class AccMMMentorsViewPart extends DestroyablePart implements OnInit, AfterViewInit {
	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.MatchingMentors);

	readonly: boolean;
	readonlyAccessAtStage: boolean;

	public gridSetup: GridSetup<MentorRow> = this.setupGrid(false);
	@ViewChild(GridPart) meGrid: GridPart<MentorRow>;


	public rows$: Observable<MentorRow[]> = combineLatest([
		this.siteAreaService.data$,
		this.accAreaService.mentorMatching.matchableMentors$,
		this.accAreaService.mentorMatching.assessments$,
		this.accAreaService.mentorMatching.mentorsRollup$,
		this.accAreaService.teams.teams$,
	]).pipe(mergeMap(async data => {

		const siteAreaData = data[0];
		if (!siteAreaData) return [];

		const siteTags = (await this.ds.admin.tag.getAllPackagesAsArray()).filter(tag => tag.site && tag.site.siteId == siteAreaData.site.siteId);
		this.mentorTag = siteTags.find(tag => tag.name == 'mentor-approved');
		this.coachTag = siteTags.find(tag => tag.name == 'coach-approved');

		return this.buildRows(data[1], data[2], data[3], data[4]);
	}));

	private _rows: MentorRow[];

	mentorTag: Tag;
	coachTag: Tag;


	constructor(
		public accAreaService: AccAreaService,
		private siteAreaService: SiteAreaService,
		public ds: DataService,
		public dialogService: DialogService,
		public pageService: AccPreAcceleratorPageService,
		private util: UtilityService,
	) {
		super();
	}

	async ngOnInit() {

		super.initDestroyable();

		super.subscribe([this.accAreaService.access$, this.accessAtStage$], async ([access, accessAtStage]) => {
			this.readonly = access?.root != 'Write';
			this.gridSetup = this.setupGrid(access.root == 'Write');
			this.readonlyAccessAtStage = accessAtStage.access != 'Write';
		});


		super.subscribe([this.pageService.matchingStatusFilter$], async ([filter]) => this.applyStatusFilter(filter));

	}

	ngAfterViewInit() {
		console.log(`ngAfterViewInit - this.meGrid =`, this.meGrid);
		this.applyStatusFilter(this.pageService.matchingStatusFilter$.value);
	}

	applyStatusFilter(filter) {
		if (this.meGrid) {
			const grid = this.meGrid.grid;
			console.log(`meGrid.grid = `, grid);

			if (filter == 'All') {
				grid.removeFilter('canCoach');
				grid.removeFilter('useCount');
			}
			else if (filter == 'Can Coach') {
				grid.removeFilter('useCount');
				grid.patchFilter({ field: 'canCoach', operator: 'eq', value: true });
			}
			else if (filter == 'Multi Use') {
				grid.removeFilter('canCoach');
				grid.patchFilter({ field: 'useCount', operator: 'gt', value: 1 });
			}
			else {
				grid.removeFilter('canCoach');
				grid.removeFilter('useCount');
			}
		}
		else {
			console.error(`this.meGrid == false`);
		}
	}


	async buildRows(
		mentors: AccMatchableMentor[],
		matchAssessments: readonly DbaAccMatchAssessment[],
		mentorsRollup: { [key: number]: AccMatchingRollups },
		accTeams: readonly AccTeam[],
	): Promise<MentorRow[]> {


		const rows: MentorRow[] = [];
		if (!mentors || !matchAssessments || !mentorsRollup || !accTeams) {
			return [];
		}

		// Get the latest Mentor handbook version
		const agreementTypes = await this.ds.admin.agreementType.getAllAsArray();

		const latestMentorHandbookVersionId = agreementTypes.find(agreementType => agreementType.agreementTypeId == AgreementTypeId.MentorHandbook).latestAgreementVersionId;
		const handbookVersionIds = (await this.ds.admin.agreementVersion.getArrayByForeignId('agreementTypeId', AgreementTypeId.MentorHandbook)).filter(v => v.agreementVersionId == latestMentorHandbookVersionId).map(version => version.agreementVersionId);
		
		const latestMediaConsentVersionId = agreementTypes.find(agreementType => agreementType.agreementTypeId == AgreementTypeId.MediaConsent).latestAgreementVersionId;
		const mediaVersionIds = (await this.ds.admin.agreementVersion.getArrayByForeignId('agreementTypeId', AgreementTypeId.MediaConsent)).filter(v => v.agreementVersionId == latestMediaConsentVersionId).map(version => version.agreementVersionId);
		
		const signatures = await this.ds.admin.agreementSignature.getAllAsArray();
		const mentorSignatures = signatures.filter(signature => handbookVersionIds.includes(signature.agreementVersionId));
		const mediaSignatures = signatures.filter(signature => mediaVersionIds.includes(signature.agreementVersionId));


		const mentorPersonIds = mentors.map(mentor => mentor.personId);
		const mentorSignaturesMap = await this.ds.admin.agreementSignature.getByPersonIds(mentorPersonIds);
		const eventWithNumAndDate: {
			event: Event,
			num: number,
			numWithDate: string,
		}[] = await this.accAreaService.getEventsWithNumAndDate(EventTypeId.AccMentorMatching);


		rows.push(...mentors.map(mentor => {
			const eventWithNum = mentor.matchingEventId ? eventWithNumAndDate.find(e => e.event.eventId == mentor.matchingEventId) : undefined;
			const rollup = mentorsRollup[mentor.personId];

			let canCoach: boolean = null;
			if (this.coachTag) canCoach = !!mentor.person.tags.find(tag => tag.tagId == this.coachTag.tagId);

			return {
				personId: mentor.personId,
				name: mentor.person.asSingleton._name,
				eventId: mentor.matchingEventId,
				eventDate: eventWithNum ? this.util.date.getDate(eventWithNum.event.startUTC) : undefined,
				eventNum: eventWithNum?.num,
				assessmentsGivenPercentage: rollup?.assessmentsGivenPercent,
				availability: rollup?.availability,
				roleMeetings: rollup?.roleCountOfMeetings,
				positivityGiven: rollup?.positivityGiven,
				positivityReceived: rollup?.positivityRecvd,
				noOfMeetings: `${rollup?.overlaps} / ${rollup?.medianMeetings}`,
				positivity: Math.min(rollup?.positivityRecvd, rollup?.positivityGiven),
				overlaps: rollup?.overlaps,
				median: rollup?.medianMeetings,
				totalMeetings: rollup?.totalMeetings,
				totalTeams: accTeams.filter(accTeam => !accTeam.droppedOutWeek).length,
				assessmentsGivenTotal: rollup?.assessmentsGiven[0] + rollup?.assessmentsGiven[1] + rollup?.assessmentsGiven[2] + rollup?.assessmentsGiven[3],
				assessmentsGiven: this.util.ratings.formatForGridCell(rollup?.assessmentsGiven),
				assessmentsRecvd: this.util.ratings.formatForGridCell(rollup?.assessmentsRecvd),
				useCount: mentor.useCount,
				canCoach,
				handbookSigned: mentorSignatures.find(signature => signature.personId == mentor.personId) ? 'Signed' : '',
				mediaSigned: mediaSignatures.find(signature => signature.personId == mentor.personId) ? 'Signed' : '',
				pitch: mentor.pitch,
				teamAssigned: !!accTeams
					.find(accTeam => accTeam.members
						.find(member => member.member.role != 'E' && member.member.personId == mentor.personId)
					),
				updatedUTC: mentor.person.updatedUTC,

			};
		}).sort((a, b) => a.name > b.name ? 1 : -1));

		this._rows = [...rows];

		console.log(rows);
		return rows;

	}


	private setupGrid(canAdd: boolean): GridSetup<MentorRow> {

		const assessmentTooltip = (row: MentorRow) => {
			return `${row.assessmentsGivenTotal}/${row.totalTeams} given`;
		};

		const availabilityTooltip = (row: MentorRow) => {
			return `${row.availability}% schedule availabilty.\n${row.roleMeetings}/${row.totalMeetings} meeting times.`;
		};

		const positivityTooltip = (row: MentorRow) => {
			return `${row.positivityGiven}% positivity given.\n${row.positivityReceived}% positivity received.`;
		};

		const overlapTooltip = (row: MentorRow) => {
			return `${row.overlaps} entrepreneurs have overlapping meetings and mutually non-negative assessments.\nThere is an average of ${row.median} meetings with each of the ${row.overlaps} entrepreneurs.`;
		};

		const setup: GridSetup<MentorRow> = {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Mentor",
			rowPluralName: "Mentors",
			rowKey: "personId",
			stateKey: "acc-mm-mentors-view-part",
			canAdd,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: "eventId", header: "EventId", width: 100, type: GridColumnType.number, hidden: true },
				{ field: "useCount", header: "Uses", headerTooltip: 'Uses = Count of teams this mentor has agreed to work with', width: 80, type: GridColumnType.number },
				{ field: "canCoach", header: "Can Coach", width: 95, type: GridColumnType.boolean },
				{ field: "handbookSigned", header: "Handbook", width: 100, type: GridColumnType.text, headerTooltip: 'Signed Mentor Handbook' },
				{ field: "mediaSigned", header: "Media Consent", width: 100, type: GridColumnType.text, headerTooltip: 'Signed Media Consent', hidden: true },
				{ field: "eventNum", header: "Event #", width: 80, type: GridColumnType.number },
				{ field: "eventDate", header: "Event Time", width: 120, type: GridColumnType.dateAndTime, hidden: true },
				{ field: "availability", header: "Availability", width: 85, type: GridColumnType.progressBar, tooltip: availabilityTooltip, progressBarThresholds: [10, 20] },
				{ field: "noOfMeetings", header: "Overlap", width: 90, type: GridColumnType.text, tooltip: overlapTooltip },
				{ field: "assessmentsGiven", header: "Assessments G", width: 130, type: GridColumnType.ratings, headerTooltip: 'Assessments Given' },
				{ field: "assessmentsRecvd", header: "Assessments R", width: 130, type: GridColumnType.ratings, headerTooltip: 'Assessments Received' },
				{ field: "assessmentsGivenPercentage", header: "Assessments %", width: 85, type: GridColumnType.progressBar, tooltip: assessmentTooltip, progressBarThresholds: [100, 100] },
				{ field: "positivity", header: "Positivity", width: 85, type: GridColumnType.progressBar, tooltip: positivityTooltip, progressBarThresholds: [55, 65] },
				{ field: "positivityGiven", header: "Positivity Given", width: 85, type: GridColumnType.progressBar, hidden: true, progressBarThresholds: [55, 65] },
				{ field: "positivityReceived", header: "Positivity Recevied", width: 85, type: GridColumnType.progressBar, hidden: true, progressBarThresholds: [55, 65] },
				{ field: "pitch", header: "Mentor Pitch", width: 500, type: GridColumnType.text, hidden: true },
			],
			actions: [
				{ key: 'config-mentor', icon: Icon.action_edit, label: 'Configure Mentor', enabled: false },
				{ key: 'remove-mentor', icon: Icon.action_delete, label: 'Remove Mentor', enabled: false },
				{ key: 'copy-mentor-pitch', icon: Icon.action_clipboard, label: 'Copy Mentor Pitch', enabled: false },
			],
			actionEnabler: this.gridActionEnabler.bind(this),
			initialState: {
				sort: [{ field: 'PERSON_name', dir: 'asc' }],
			},
		};


		return setup;
	}

	gridActionEnabler(action: GridAction, rows: MentorRow[]) {
		if (rows.length == 0) return false;

		if (action.key == 'config-mentor' || action.key == 'remove-mentor') {
			if (this.readonly) return false;
		}


		if (action.key == 'copy-mentor-pitch') {
			if (rows.length !== 1) return false;
			return (rows[0].pitch ?? '').trim().length > 0;
		}


		return true;
	}


	async gridActionHandler(action: { actionKey: string, rows: MentorRow[] }) {

		const row = action.rows[0];

		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {
			if (this.readonly) return;
			if (this.readonlyAccessAtStage) {
				await this.ds.dialogService.showMessage(`Cannot add mentors as the accelerator stage is not in 'Mentor Matching' stage.`, 300, 170);
			}
			else {
				const dialogAction: DialogAction<number[]> = await this.ds.dialogService.showCustom(
					ManageVolunteersDialog,
					{
						data: {
							readonly: this.readonly,
							initialSelections: this._rows.map(row => ({ personId: row.personId, disabled: row.teamAssigned })),
							tagIds: [this.mentorTag.tagId, this.coachTag.tagId],
							tagFullNameToAdd: this.mentorTag.fullName,
							header: 'Mentors'

						},
					},
					600, 530
				);

				const id = dialogAction?.id;
				if (id == 'update') {
					if (this.readonly) return;
					const selectedPersonIds = dialogAction.callbackResult;
					if (selectedPersonIds) await this.accAreaService.mentorMatching.actions.setMentors({ personIds: selectedPersonIds });
				}
			}
		}


		else if (action.actionKey == 'config-mentor') {
			if (this.readonly) return;

			const action: DialogAction<{ eventId: number, canCoach: boolean, useCount: number }> = await this.ds.dialogService.showCustom(AccMentorConfigDialog, {
				data: {
					readonly: this.readonly,
					eventId: row.eventId,
					eventTypeId: EventTypeId.AccMentorMatching,
					canCoach: row.canCoach,
					useCount: row.useCount,
				}

			}, 350, 250);

			const id = action?.id;
			if (id == 'save') {
				if (action.callbackResult) await this.accAreaService.mentorMatching.actions.setConfig({ personId: row.personId, ...action.callbackResult });
			}

		}


		else if (action.actionKey == 'remove-mentor') {
			if (this.readonly) return;

			if (row.teamAssigned) {
				await this.dialogService.showMessage(`${row.name} cannot be removed because this mentor is assigned to a team.`, 400, 250, 'Okay');
			}

			else {
				const msg = `Are you sure you want to remove ${row.name} from the list of matchable mentors?`;
				const yes = await this.dialogService.confirm(msg, 400, 250, 'Remove', 'Cancel');
				if (yes) await this.accAreaService.mentorMatching.actions.removeMentor({ personId: row.personId });
			}
		}

		else if (action.actionKey == 'copy-mentor-pitch') {
			await this.util.clipboard.writeText(row.pitch);
			await this.dialogService.showMessage(`The mentor pitch has been copied to the clipboard.`, 300, 150);

		}
	}

}