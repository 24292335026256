import { DbsPerson, DbsRegion, Region } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";


export class RegionPackageManager extends PackageManager<DbsRegion, Region>{

	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsRegion>,
		private domain: DomainDataManagers,
		private person: SingletonsManager<DbsPerson>,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}

	/**
	 * Convert an array of DbcSite to an array of Region
	 */
	protected async _createPackages(dbsRegions: DbsRegion[]): Promise<Region[]> {

		//
		// Get all the people
		//
		const personIds: number[] = [];
		for (const region of dbsRegions) {
			if (region.directorId) personIds.push(region.directorId);
			personIds.push(region.updatedByPersonId);
		}

		const personMap = await this.person.getManyAsMap(personIds);


		//
		// Package 'em up
		//
		const regions: Region[] = dbsRegions.map(region => {

			const director = personMap[region.directorId];
			const updatedByPerson = personMap[region.updatedByPersonId];

			return {
				...region,
				explorerName: region.name,
				id: region.regionId,
				directorName: director?._name || `Person #${region.directorId}`,
				updatedByPersonName: updatedByPerson?._name || `Person #${region.updatedByPersonId}`,
				appUrl: this.createAppUrl(region),
				hasRedFlag: false,
			};
		});

		return regions;
	}

	private createAppUrl(region: DbsRegion): string {
		const regionId = region.regionId;
		return `/access/admin/national/regions/${regionId}`;
	}

}