import { AccAreaService, AccCohortPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridColumnType, GridSetup } from '@me-grid';
import { AccMatchableMentor, AccMatchingRollups, AccTeam, DbaAccMatchAssessment } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { Observable, combineLatest, mergeMap } from 'rxjs';


interface TeamRow {
	accTeamId: number,
	businessName: string,
	assessmentsGivenPercentage: number,
	assessmentsGiven: number,
	availability: number,
	positivity: number,
	positivityGiven: number,
	positivityReceived: number,
	noOfMeetings: string,
	overlaps: number,
	median: number,
	totalMentors: number,
	totalMeetings: number,
	roleMeetings: number,
}
@Component({
	selector: 'acc-mm-teams-view-part',
	templateUrl: './acc-mm-teams-view.part.html',
})
export class AccMMTeamsViewPart extends DestroyablePart implements OnInit {
	public gridSetup = this.setupGrid();

	public rows$: Observable<TeamRow[]>;

	constructor(
		public accAreaService: AccAreaService,
		public ds: DataService,
		public pageService: AccCohortPageService
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();

		this.rows$ = combineLatest([
			this.accAreaService.teams.teams$,
			this.accAreaService.mentorMatching.assessments$,
			this.accAreaService.mentorMatching.teamsRollup$,
			this.accAreaService.mentorMatching.matchableMentors$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1], data[2], data[3])));
	}


	async buildRows(
		accTeams: readonly AccTeam[],
		matchAssessments: readonly DbaAccMatchAssessment[],
		teamsRollup: { [key: number]: AccMatchingRollups },
		mentors: AccMatchableMentor[],
	): Promise<TeamRow[]> {

		if (!accTeams || !matchAssessments || !teamsRollup || !mentors) return [];

		return accTeams.filter(accTeam => !accTeam.droppedOutWeek).map(accTeam => {
			const rollup = teamsRollup[accTeam.accTeamId];
			return {
				accTeamId: accTeam.accTeamId,
				businessName: accTeam.application.companyName,
				assessmentsGivenPercentage: rollup.assessmentsGivenPercent,
				availability: rollup.availability,
				positivity: Math.min(rollup.positivityGiven, rollup.positivityRecvd),
				positivityGiven: rollup.positivityGiven,
				positivityReceived: rollup.positivityRecvd,
				noOfMeetings: `${rollup.overlaps} / ${rollup.medianMeetings}`,
				overlaps: rollup.overlaps,
				median: rollup.medianMeetings,
				roleMeetings: rollup.roleCountOfMeetings,
				totalMeetings: rollup.totalMeetings,
				totalMentors: mentors.length,
				assessmentsGiven: rollup.assessmentsGiven[0] + rollup.assessmentsGiven[1] + rollup.assessmentsGiven[2] + rollup.assessmentsGiven[3],
			}
		});

	}


	private setupGrid(): GridSetup<TeamRow> {
		const assessmentTooltip = (row: TeamRow) => {
			return `${row.assessmentsGiven}/${row.totalMentors} given`;
		};

		const availabilityTooltip = (row: TeamRow) => {
			return `${row.availability}% schedule availabilty.\n${row.roleMeetings}/${row.totalMeetings} meeting times.`;
		};
		const positivityTooltip = (row: TeamRow) => {
			return `${row.positivityGiven}% positivity Given.\n${row.positivityReceived}% positivity Received.`;
		};

		const overlapTooltip = (row: TeamRow) => {
			return `${row.overlaps} Mentors have overlapping meetings and mutually positive or not given assessments with ${row.median} meetings on average.`;
		};
		return {
			experience: 'ACCTEAM',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Team",
			rowPluralName: "Teams",
			rowKey: "accTeamId",
			stateKey: "acc-mm-teams-view-part",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: "availability", header: "Availability", width: 110, type: GridColumnType.progressBar, tooltip: availabilityTooltip, progressBarThresholds: [10, 20] },
				{ field: "assessmentsGivenPercentage", header: "Assessments", width: 120, type: GridColumnType.progressBar, tooltip: assessmentTooltip, progressBarThresholds: [100, 100] },
				{ field: "positivity", header: "Positivity", width: 120, type: GridColumnType.progressBar, tooltip: positivityTooltip, progressBarThresholds: [55, 65] },
				{ field: "positivityGiven", header: "Positivity Given", width: 130, type: GridColumnType.progressBar, progressBarThresholds: [55, 65], hidden: true },
				{ field: "positivityReceived", header: "Positivity Recevied", width: 130, type: GridColumnType.progressBar, progressBarThresholds: [55, 65], hidden: true },
				{ field: "noOfMeetings", header: "Overlap", width: 100, type: GridColumnType.text, tooltip: overlapTooltip },
			],
			initialState: {
				sort: [{ field: 'ACCTEAM_companyName', dir: 'asc' }],
			},
		};
	}
}