import { Component, Input } from '@angular/core';
import { Answer } from '@me-interfaces';


@Component({
	selector: 'readonly-question',
	templateUrl: './SHR-CMN_readonly-question.part.html',
	styleUrls: ['./SHR-CMN_readonly-question.part.scss']
})
export class ReadonlyQuestionPart {
	@Input() answer: Answer;
	@Input() showQuestion = true;

	hasAnswer() {
		return this.answer.answer !== null && this.answer.answer !== '';
	}

}