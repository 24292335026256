import { DbsPerson, SiteAreaRawData_old } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { OldDomainDataService } from '@me-services/core/old-dd';
import { UtilityService } from "@me-services/core/utility";
import { SiteAreaData_old } from "./site-area-data";

//
// transform the raw data returned from the action function to
// data that can be used by the Angular app.
//
export async function mapToData(rawData: SiteAreaRawData_old, dd: OldDomainDataService, util: UtilityService, ds: DataService): Promise<SiteAreaData_old> {


	const personIds = [
		rawData.directors.directorId,
		rawData.directors.altDirectorId,
		...rawData.openVolunteerApplications.map(va => va.application.personId),
		...rawData.openVolunteerApplications.map(va => va.closedByPersonId),
		...rawData.personIdsWithSiteTags,
		...rawData.pitchContests.map(p => p.pitch.managerId),
	];

	const companyIds = [];

	//Get personIds from rawData.offeredSitePrograms
	for (const sp of rawData.offeredSitePrograms) {
		personIds.push(sp.managerId, sp.altManagerId, ...sp.internIdsWithAccess);
	}

	//Get personIds and companyIds from rawData.accelerators
	for (const accelerator of rawData.accelerators) {
		for (const t of accelerator.teams) {
			personIds.push(...t.adminIds, ...t.entrepreneurIds);
			companyIds.push(t.companyId);
		}
	}

	const people = await ds.admin.person.getManyPackagesAsMap(personIds);
	const companies = await ds.admin.company.getManyPackagesAsMap(companyIds);
	const specialists = [...(await ds.admin.specialist.getBySiteIds([rawData.siteId]))[rawData.siteId]];

	const data: SiteAreaData_old = {

		site: await ds.admin.site.getOnePackage(rawData.siteId),

		directors: {
			director: people[rawData.directors.directorId]?.asSingleton,
			altDirector: rawData.directors.altDirectorId ? people[rawData.directors.altDirectorId]?.asSingleton : undefined,
		},

		interns: rawData.interns,

		offeredSitePrograms: rawData.offeredSitePrograms.map(sp => ({
			...sp,
			manager: people[sp.managerId].asSingleton,
			altManager: sp.altManagerId ? people[sp.altManagerId]?.asSingleton : undefined,
			internsWithAccess: sp.internIdsWithAccess.map(personId => people[personId]?.asSingleton),
		})),

		openVolunteerApplications: rawData.openVolunteerApplications.map(va => ({
			siteVolunteerApplication: va,
			applicant: people[va.application.personId]?.asSingleton,
			closedBy: va.closedByPersonId ? people[va.closedByPersonId]?.asSingleton : undefined,
		})),

		peopleWithSiteTags: rawData.personIdsWithSiteTags.map(id => people[id]?.asSingleton),

		overview: rawData.overview,


		accs: rawData.accelerators.map(a => {

			const teams = a.teams.map(t => {
				const entrepreneurs: DbsPerson[] = t.entrepreneurIds.map(id => people[id]?.asSingleton);
				const admins: DbsPerson[] = t.adminIds.map(id => people[id]?.asSingleton);

				return {
					accTeamId: t.accTeamId,
					dropOutWeek: t.dropOutWeek,
					company: companies[t.companyId],
					reportImportance: t.reportImportance,
					lastYearUTC: t.lastYearUTC,
					thisYearUTC: t.thisYearUTC,
					entrepreneurs,
					admins,
				}
			});

			return {
				accelerator: a.accelerator,
				applicationCounts: a.applicationCounts,
				teams,
			}
		}),


		pics: rawData.pitchContests.map(p => {
			return {
				pitch: p.pitch,
				applicationCounts: p.applicationCounts,
				teams: p.teams,
				manager: people[p.pitch.managerId]?.asSingleton,
			}
		}),


		specialists,

		roledPeople: await ds.admin.person.getManyAsArray(rawData.roledPersonIds),

	};

	return data;
}