import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DestroyablePart } from '@me-access-parts';
import { AppAreaIdentifier, DbcSiteIntern, DbsPerson, PhoneTypeId, Program, Site, SiteAreaAccess } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogService } from '@me-services/ui/dialog';
import { getIconContext, Icon } from '@me-shared-parts/UI-common';
import { SiteAreaData, SiteAreaService } from '@SITE-area';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, mergeMap } from 'rxjs/operators';


interface Intern {
	siteIntern: DbcSiteIntern,
	person: DbsPerson,
	daysSince: string,
	numProgramsAssigned: number,
	programs: {
		siteProgramId: number,
		program: Program,
		isIntern: boolean,
	}[]
}


@Component({
	selector: 'site-config-interns-part',
	templateUrl: './config-interns.part.html',
	styleUrls: ['./config-interns.part.scss'],
})
export class SiteConfigInternsPart extends DestroyablePart implements OnInit {

	public readonly PhoneTypeId_Mobile = PhoneTypeId.Mobile;

	interns$: Observable<Intern[]>;
	readonly: boolean;
	allInternEmails: string[] = [];
	allInternPhoneNumbers: string[] = [];
	accordionDisableAnimation = true;
	public searchIcon = getIconContext(Icon.action_search, undefined, 'never', 'Search Person');

	personToAdd: DbsPerson;

	site: Site;

	currentlyExpandedIndex = -1;


	constructor(
		private sanitizer: DomSanitizer,
		private dialogService: DialogService,
		private ds: DataService,
		private siteAreaService: SiteAreaService,
		private util: UtilityService,
	) {
		super();
	}


	accessChanged = async (value: { access: SiteAreaAccess, id: AppAreaIdentifier<number> }) => {
		this.readonly = value?.access?.sub['config'] != 'Write';
	}

	async ngOnInit() {
		super.initDestroyable();
		moment.locale('en');
		this.accordionDisableAnimation = true;


		this.siteAreaService.subscribeAccess(this.destroyed$, this.accessChanged);

		this.interns$ = combineLatest([
			this.ds.admin.singletonsAsOfUTC$,
			this.siteAreaService.data$,
		]).pipe(
			mergeMap(async data => await this.buildView(data[0], data[1])),
			distinctUntilChanged((e1, e2) => this.util.values.areSame(e1, e2)));

		setTimeout(() => {
			this.accordionDisableAnimation = false;
		}, 2000);

	}

	async searchPerson() {
		this.personToAdd = undefined;
		const conceptAndId = await this.ds.searcher.selectPerson();
		if (conceptAndId) {
			this.personToAdd = await this.ds.admin.person.getOne(conceptAndId.id);
		}
	}

	onExpand(index: number) {
		this.currentlyExpandedIndex = index;
	}


	async buildView(singletonsAsOfUTC: number, data: SiteAreaData) {
		if (!data) return [];

		this.site = data.site;

		const siteProgramsMap = await this.ds.admin.siteProgram.getManyPackagesAsMap([...data.offeredSitePrograms.map(pgm => pgm.siteProgramId)]);

		const offeredSitePrograms = data.offeredSitePrograms.map(pgm => {
			return {
				...pgm,
				siteProgram: siteProgramsMap[pgm.siteProgramId],
			};
		});

		let internList: Intern[] = [];

		const people = await this.ds.admin.person.getManyPackagesAsMap([
			...data.interns.map(intern => intern.siteIntern.personId),
		]);


		internList = data.interns.map(intern => {

			const person = people[intern.siteIntern.personId];
			const dbsPerson = person.asSingleton;


			if (person) {
				this.allInternEmails.push(dbsPerson._email);
				this.allInternPhoneNumbers.push(dbsPerson.phone);
			}

			const programs = offeredSitePrograms.map(programOffered => {
				return {
					siteProgramId: programOffered.siteProgramId,
					program: programOffered.siteProgram.program,
					isIntern: !!programOffered.internIdsWithAccess.find(personId => dbsPerson.personId == personId),
				};
			})

			return {
				daysSince: moment(intern.siteIntern.createdUTC * 1000).fromNow(true),
				person: dbsPerson,
				siteIntern: intern.siteIntern,
				numProgramsAssigned: programs.reduce((a, i) => {
					if (i.isIntern) a++;
					return a;
				}, 0),
				programs,
			};
		});


		return internList;
	}

	sanitize(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}

	async delete(intern: Intern) {

		const msg = `Do you want to remove intern - ${intern.person._name} from ${this.site.name}? This will remove ${intern.person.firstName} as program coordinator from all programs, if any, and remove their site level access.`;
		const yes = await this.dialogService.confirm(msg);

		if (yes) {
			this.currentlyExpandedIndex = -1;

			await this.siteAreaService.config.actions.removeSiteIntern({
				siteInternId: intern.siteIntern.siteInternId,
				personId: intern.person.personId,
			});
		}
	}

	async setCanViewContacts(e, intern: Intern) {
		const canViewContacts = e.checked;
		const canPrepareSiteContacts = canViewContacts && intern.siteIntern.canPrepareSiteContacts;

		await this.siteAreaService.config.actions.setSiteInternFlags({
			siteInternId: intern.siteIntern.siteInternId,
			canViewContacts,
			canPrepareSiteContacts,
			internPersonId: intern.person.personId,
		});
	}

	async addIntern() {
		if (this.personToAdd?.personId) await this.siteAreaService.config.actions.addSiteIntern({ personId: this.personToAdd.personId });
		this.personToAdd = undefined;
	}

	async setProgramIntern(e, intern: Intern, programOffered: { siteProgramId: number, program: Program, isIntern: boolean }) {

		if (intern && programOffered && programOffered.siteProgramId) {

			if (!e.checked) await this.siteAreaService.config.actions.removeProgramIntern({
				siteInternId: intern.siteIntern.siteInternId,
				siteProgramId: programOffered.siteProgramId,
				personId: intern.person.personId,
			});

			else if (e.checked) {
				await this.siteAreaService.config.actions.addProgramIntern({
					siteInternId: intern.siteIntern.siteInternId,
					siteProgramId: programOffered.siteProgramId,
					personId: intern.person.personId,
				});
			}
		}
	}
}