import { ImmutableArray } from '@me-interfaces';
import { utilLogging } from '../logging/utility-logging';
import { cleanNumericIds, cleanStrings } from './clean';
import { Aggregator, groupBy as _groupBy } from './group-by';

export const ArrayUtilities = {
	/**
	 * Map each element in an array to something else, similar to JavaScript's array.map()
	 * except the mapper can be asynchronous.
	 */
	awaitedMap: async <IN, OUT>(array: IN[], mapper: (item: IN) => Promise<OUT>): Promise<OUT[]> => {

		if (!array) {
			utilLogging.errorMessage(`util.array.awaitedMap() called with an undefined array`);
			array = [];
		}

		const output: OUT[] = [];

		for (const input of array) {
			output.push(await mapper(input));
		}

		return output;
	},

	/**
	 * Transform a string of hex letters to a corresponding array of byte numbers.
	 * e.g. "FF00AA" => [255, 0, 170]
	 */
	fromHex: (hex: string) => {
		const bytes: number[] = [];

		for (let c = 0; c < hex.length; c += 2) {
			bytes.push(parseInt(hex.substring(c, c + 2), 16));
		}
		return bytes;
	},

	/**
	 * Transform an array of byte numbers into a string of hex letters.
	 * e.g. [255, 0, 170] => "FF00AA"
	 */
	toHex: (bytes: readonly number[]) => {

		const hex: string[] = [];

		for (let i = 0; i < bytes.length; i++) {
			const current = bytes[i] < 0 ? bytes[i] + 256 : bytes[i];
			hex.push((current >>> 4).toString(16));
			hex.push((current & 0xF).toString(16));
		}
		return hex.join('');
	},

	/**
	 * Given any array of objects, where each object has a value for a named property,
	 * return a map where each value points to ONE item. If there are multiple items
	 * in the array with the same idProperty value then the LAST one in the array will
	 * be in the map and prior ones will be ignored.
	 */
	toMap: <K extends string | number, T>(
		array: readonly T[],
		getId: (item: T) => K,
	): Record<K, T> => {

		return array.reduce((map, item) => {
			const id = getId(item);
			map[id] = item;
			return map;
		}, <Record<K, T>>{});

	},


	/**
	 * Merge a mapped set of arrays into a single array.
	 */
	fromArrayMap: <T, K extends number>(
		map: Readonly<Record<K, readonly T[]>>,
		/** The ids used to get the mapped arrays */
		ids: readonly K[],
	): T[] => {

		return ids.reduce((array, id) => {
			array.push(...(map[id] ?? []));
			return array;
		}, <T[]>[]);

	},


	/**
	 * Given any array of objects, where each object has a value for a named property,
	 * return a map where each value points to a subset array. Each item in each subset
	 * array will have an idProperty value matching the map key.
	 */
	toArrayMap: <T, K extends string | number>(
		array: readonly T[],
		getId: (item: T) => number,
	): Record<K, T[]> => {

		return array.reduce((map, item) => {
			const id = getId(item);
			map[id] = map[id] ?? [];
			map[id].push(item);
			return map;
		}, <Record<K, T[]>>{});

	},


	/**
	 * Given an array of primitives, return a map where each array item is mapped to
	 * the boolean value true. This is useful for quick checking if a value exists in
	 * the array without calling array.includes(), array.indexOf(), etc., in a loop.
	 */
	toBooleanMap: <K extends string | number | symbol>(array: readonly K[]): Record<K, true> => {

		return array.reduce((map, value) => {
			map[value] = true;
			return map;
		}, <Record<K, true>>{});

	},


	/**
	 * Given an array of numbers, it returns out a new array with any undefined
	 * elements and duplicates filtered out. The new array will be sorted numerically
	 * by default but the optional second parameter can be used to skip the sorting.
	 */
	cleanNumericIds: (original: ImmutableArray<number> | number[], sort = true): number[] => {
		return cleanNumericIds(original, sort);
	},


	/**
	 * Given an array of strings, it returns out a new array with any undefined
	 * elements and duplicates filtered out. The new array will be sorted textually
	 * by default but the optional second parameter can be used to skip the sorting.
	 */
	cleanStrings: (original: ImmutableArray<string> | string[], sort = true): string[] => {
		return cleanStrings(original, sort);
	},

	/**
	 * Add up all the numbers in a number[]
	 */
	sum: (numbers: ImmutableArray<number> | number[], sort = true): number => {
		let sum = 0;
		for (const num of numbers) {
			sum += num;
		}

		return sum;
	},


	/**
	 * Split an array of items into an array of arrays of items where each child array is a
	 * subset of the original array of the chunkSize provided.
	 * 
	 * e.g. chunk([1,2,3,4,5], 2) => [[1,2],[3,4],[5]]
	 * 
	 * @param arr The array to split into sub arrays
	 * @param chunkSize The size of each sub array
	 * @returns 
	 */
	chunk: <T>(arr: readonly T[], chunkSize: number) =>
		Array.from({ length: Math.ceil(arr.length / chunkSize) }, (v, i) =>
			arr.slice(i * chunkSize, i * chunkSize + chunkSize)
		),



	/**
	 * Given an array of items, organize by a grouping value and then perform aggregate functions on it.
	 * This works much like the Group By clause in SQL.
	 * @param groupBy A function that returns the value to group on from each array item
	 * @param aggregators Instructions for calculating aggregate values
	 * @returns An array of resulting groups with calculated aggregates
	 */
	groupBy: <ITEM, GROUP>(
		items: ITEM[],
		groupBy: (i: ITEM) => number,
		setter: (g: GROUP, value: number) => void,
		aggregators: Aggregator<ITEM, GROUP>[],
	): GROUP[] => {

		return _groupBy<ITEM, GROUP>(items, groupBy, setter, aggregators);
	},


	/**
	 * Insert an array of items into another array, avoiding the maximum 
	 * callstack problem with (...)spread operators on large arrays.
	 * @param array The destination array 
	 * @param newItems An array of items to add to the destination array
	 */
	push: <T>(array: T[], newItems: T[]) => {
		for (const item of newItems) {
			array.push(item);
		}
	},


	/**
	 * Given an array of outer objects, where each object contains a its own array of inner
	 * objects, collapse all the inner arrays into a single array and return it.
	 * @param outers An array of objects that contain a nested array
	 * @param map A function that returns the inner array for an outer object
	 * @returns 
	 */
	mergeChildArrays: <OUTER, INNER>(outers: Readonly<OUTER[]>, map: (outer: OUTER) => Readonly<INNER[]>): INNER[] => {

		const inners: INNER[] = [];

		for (const outer of outers) {
			inners.push(...map(outer));
		}

		return inners;
	}
};
