/**
 * Given an NPS score of 0..10, return the named category
 * @param score A score from 0 to 10
 * @param bucketNames  The name to use for each NPS bucket
 * @returns 
 */
export function _getBucket(
	score: number,
	bucketNames = ['Disagree', 'Neutral', 'Agree']
): string {

	return [
		bucketNames[0],  // 0
		bucketNames[0],  // 1
		bucketNames[0],  // 2
		bucketNames[0],  // 3
		bucketNames[0],  // 4
		bucketNames[0],  // 5
		bucketNames[0],  // 6

		bucketNames[1],  // 7
		bucketNames[1],  // 8

		bucketNames[2],  // 9
		bucketNames[2],  // 10
	][score];

}