import { WebLinkTypeId } from '@me-interfaces';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';
import { DdWebLinkTypeRow } from './dd-web-link-type-row';

/**
 * @deprecated Use the DataService instead
 */
export class DdWebLinkType extends DdObject<DdWebLinkTypeRow> implements DdWebLinkTypeRow {

	constructor(dd: OldDomainDataService, data: DdWebLinkTypeRow) {
		super(data);
	}

	public get webLinkTypeId(): WebLinkTypeId {
		return this._data.webLinkTypeId;
	}

	public get name(): string {
		return this._data.name;
	}
}