<div class='adm-events-list'>
		<mat-list *ngFor='let pgm of programs; let i = index'>
			<ng-container *ngIf='pgm.events.length > 0'>
				<h5 *ngIf='programs.length > 1'>{{pgm.programName}}</h5>
				<mat-list-item *ngFor="let event of pgm.events" (click)='editEvent(event)'>
					<i [class]='getClass(event.event)' style="font-size: 1.5em" title='{{event.eventName}}' mat-list-icon></i>
					<h6 mat-line>{{event.venueName}}</h6>
					<small mat-line *ngIf='getDate(event) === true'> {{formatDate(event.event.startUTC)}}
						{{formatTime(event.event.startUTC)}} -> {{formatEndTime(event.event)}}</small>
					<small mat-line *ngIf='getDate(event) === false'>{{formatDate(event.event.startUTC)}}
						{{formatTime(event.event.startUTC)}} -> {{formatEndDate(event.event)}} {{formatEndTime(event.event)}}</small>
					<small mat-line *ngIf='!!event.toolName'>{{event.toolName}}: <span
							[class.Started]='event.toolState === "On"'>{{event.toolState}}</span>{{event.toolUntil}}</small>
				</mat-list-item>
				<mat-divider *ngIf='programs.length > (i + 1)'></mat-divider>
			</ng-container>
		</mat-list>

	<i *ngIf='!programs.length'>No current events</i>
</div>