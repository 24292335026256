import { Accelerator, AccStageId, DbsEvent, EventTypeId } from "@me-interfaces";
import { getEventStatus } from "./get-event-status";

const AccToolAccess = {
	ReaderTool: [AccStageId.Reading],
	InterviewTool: [AccStageId.Interviewing],
	MentorMatchingTool: [
		AccStageId.Setup,
		AccStageId.Accepting,
		AccStageId.Reading,
		AccStageId.Interviewing,
		AccStageId.SelectingCohort,
		AccStageId.MatchingMentors,
	],
	FinalPresJudgeTool: [AccStageId.Curriculum],
	QuarterliesTool: [
		AccStageId.MatchingMentors,
		AccStageId.Curriculum,
		AccStageId.SelectingWinners,
		AccStageId.Quarterlies,
	],
};
/**
 * Calculate the Accelerator link of the tool and the status(enabled) based on the event type, event status and the accStage.
 * Returns enabled status: boolean and the link for the tool.
 */
export function getAccToolEnabledAndLink(event: DbsEvent, accelerator: Accelerator): { enabled: boolean, link: string } {
	const eventStatus = getEventStatus(event);
	let link = '';
	let enabled = false;
	const siteCode = accelerator.siteProgram.site.code.toLowerCase();

	if (event.eventTypeId == EventTypeId.AccFinalPitch) {
		link = `/access/sites/${siteCode}/accelerators/${accelerator.accId}/judge`;
		if (AccToolAccess.FinalPresJudgeTool.includes(accelerator.accStageId)) {
			enabled = true;
		}
	}
	else if (event.eventTypeId == EventTypeId.AccInterviewing) {
		link = `/access/sites/${siteCode}/accelerators/${accelerator.accId}/interview`;
		if (AccToolAccess.InterviewTool.includes(accelerator.accStageId)) {
			enabled = true;
		}
	}
	else if (event.eventTypeId == EventTypeId.AccMentorMatching) {
		link = `/access/sites/${siteCode}/accelerators/${accelerator.accId}/matching`;
		if (AccToolAccess.MentorMatchingTool.includes(accelerator.accStageId)) {
			enabled = true;
		}
	}
	else if (event.eventTypeId == EventTypeId.AccReading) {
		link = `/access/sites/${siteCode}/accelerators/${accelerator.accId}/read`;
		if (AccToolAccess.ReaderTool.includes(accelerator.accStageId)) {
			enabled = true;
		}
	}
	else if ([EventTypeId.AccShowcase, EventTypeId.AccQuarterliesQ2, EventTypeId.AccQuarterliesQ3, EventTypeId.AccQuarterliesQ4].includes(event.eventTypeId)) {
		link = `/access/my/programs/accelerator/quarterlies`;
		if (AccToolAccess.MentorMatchingTool.includes(accelerator.accStageId)) {
			enabled = true;
		}
	}

	return { enabled: eventStatus == 'Underway' && enabled, link };
}