import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DbConceptName } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { getIconClass, getIconContext, Icon } from '@me-shared-parts/UI-common';
import { HearReportRow } from '../hear-report.part';


@Component({
	selector: 'site-hear-report-details-dialog',
	templateUrl: './details.dialog.html',
	styleUrls: ['./details.dialog.scss']
})
export class SiteHearReportDetailsDialog extends DestroyablePart implements OnInit {
	//
	// The action buttons
	//

	closeAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: 'Close',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction[] = [this.closeAction];
	icon = getIconClass(Icon.action_open);
	icons = {
		application: getIconContext(Icon.concept_application, undefined, 'never', 'Application'),
		company: getIconContext(Icon.concept_company, undefined, 'never', 'Company'),
		person: getIconContext(Icon.concept_person, undefined, 'never', 'Applicant'),
		program: getIconContext(Icon.concept_program, undefined, 'never', 'Program'),
	}

	header = 'Details';
	data: HearReportRow;

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ row: HearReportRow }>,
		public dialogRef: MatDialogRef<SiteHearReportDetailsDialog>,
		private ds: DataService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		const { row } = this.dialog.data;
		this.data = row;

		this.icons.company.text = this.data.companyName;
		this.icons.person.text = this.data.applicantName;
		this.icons.program.text = `${this.data.programName} ${this.data.type}`;
		if (this.data.type == 'Accelerator') this.icons.program.icon = Icon.concept_accelerator;
		else this.icons.program.icon = Icon.concept_pitchContest;

	}


	async explorePerson() {
		await this.ds.explorer.explore(DbConceptName.Person, this.data.personId);
	}

	async exploreCompany() {
		await this.ds.explorer.explore(DbConceptName.Company, this.data.companyId);
	}

	async exploreApplication() {
		await this.ds.explorer.explore(DbConceptName.Application, this.data.applicationId);
	}

	async exploreProgram() {
		if (this.data.accId) await this.ds.explorer.explore(DbConceptName.Accelerator, this.data.accId);
		else if (this.data.picId) await this.ds.explorer.explore(DbConceptName.PitchContest, this.data.picId);
	}
}