import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';
import { LabelsData } from '@me-interfaces';
import { OldDomainDataService } from '@me-services/core/old-dd';
import { LabelsService } from '@me-services/ui/labels';
import { accessPages } from 'access';



/**
 * OLD way to wait for OLD domain data to load
 */
@Injectable({ providedIn: 'root' })
export class DdLoadedResolver {

	constructor(private dd: OldDomainDataService) { }

	async resolve() {
		await this.dd.loaded;
		return true;
	}
}


/**
 * With for labels to load
 */
@Injectable({ providedIn: 'root' })
export class LabelsResolver {

	constructor(private labels: LabelsService) { }

	async resolve() {
		await this.labels.updateLabels();
		return this.labels.getLabelsData();
	}

}


/**
 * App level routes
 */
export const routes: Routes = [

	{
		path: '', children: [

			{ path: 'signout', component: accessPages.AccessLogoutPage },

			{ path: 'access', resolve: { ddLoaded: DdLoadedResolver }, loadChildren: () => import('./user-area/ACS.module').then(m => m.AccessModule) },
			{
				path: 'session', resolve: { labels: LabelsResolver, ddLoaded: DdLoadedResolver }, children: [
					{ path: 'enter-search-details', component: accessPages.SessionEnterSearchDetailsPage, pathMatch: 'full' },
					{ path: 'is-this-you', component: accessPages.SessionIsThisYouPage, pathMatch: 'full' },
					{ path: 'enter-code', component: accessPages.SessionEnterCodePage, pathMatch: 'full' },
				]
			},

			{ path: 'unauthorized', resolve: { labels: LabelsResolver, ddLoaded: DdLoadedResolver }, component: accessPages.AccessErrorUnauthorizedPage },

			{ path: 'my', redirectTo: 'access/my' },
			{ path: 'dashboard', redirectTo: '/access/my/dashboard' },
		]
	},

	{ path: '**', resolve: { labels: LabelsResolver }, component: accessPages.AccessError404Page }
];