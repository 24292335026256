import { Injectable } from '@angular/core';
import { Loader } from "@googlemaps/js-api-loader";
import { environment } from '@me-environment';


@Injectable({ providedIn: 'root' })
export class GoogleApiService {

	private _loader = new Loader({
		apiKey: environment.firebaseConfig.apiKey,
		version: "weekly",
		//...additionalOptions,
	});


	public async getMaps(): Promise<google.maps.MapsLibrary> {

		const google = await this._loader.load();
		return await google.maps.importLibrary('maps') as google.maps.MapsLibrary;
	}


	public async getPlaces(): Promise<google.maps.PlacesLibrary> {

		const google = await this._loader.load();
		return await google.maps.importLibrary('places') as google.maps.PlacesLibrary;
	}

}