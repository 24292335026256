import { DbConceptName, PitchContest, WebLinkTypeId } from "@me-interfaces";
import { LinkOrExplorable } from "../../link-or-explorerable";

export function getButtons(pitch: PitchContest): LinkOrExplorable[] {

	const buttons: LinkOrExplorable[] = [];

	//
	// Site
	//
	buttons.push({ explorer: { explorableId: { conceptName: DbConceptName.Site, id: pitch.siteProgram.siteId }, navTooltip: `Site: ${pitch.siteProgram.site.name}`, } });

	//
	// Website
	//
	buttons.push({ link: { linkType: 'webLink', url: `https://eforall.org/pitch-contests/`, webLinkTypeId: WebLinkTypeId.Website } });

	//
	// ED and PM
	//
	buttons.push(
		{ explorer: { nameOverride: `ED: ${pitch.directorName}`, explorableId: { conceptName: DbConceptName.Person, id: pitch.directorId }, navTooltip: `ED: ${pitch.directorName}`, } },
		{ explorer: { nameOverride: `PM: ${pitch.managerName}`, explorableId: { conceptName: DbConceptName.Person, id: pitch.managerId }, navTooltip: `PM: ${pitch.managerName}`, } },
	);


	return buttons;
}