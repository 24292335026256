import { SiteAreaData, SiteAreaService, SiteDashboardPageService } from '@SITE-area';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridColumnType, GridSetup, GridValueStyle, PersonCols, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, combineLatest, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface EntrepreneurRow {
	indexId: number
	personId: number,
	companyId: number,
	accId: number,
	picId: number,
	companyName: string,
	programName: string,
	type: 'Accelerator' | 'Pitch Contest',
	companyIndustry: string,
	companyStatus: string,
	companyOfferings: string,
	language: string,
	updatedUTC: number,
}


@Component({
	selector: 'site-entrepreneurs-view-part',
	templateUrl: './entrepreneurs-view.part.html',
})
export class SiteEntrepreneursViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();

	public rows$: Observable<EntrepreneurRow[]> = of([]);

	@ViewChild(GridPart) meGrid: GridPart<EntrepreneurRow>;

	constructor(
		private util: UtilityService,
		public siteAreaService: SiteAreaService,
		public ds: DataService,
		public pageService: SiteDashboardPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		this.rows$ = combineLatest([
			this.ds.admin.singletonsAsOfUTC$,
			this.siteAreaService.data$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1])));
	}


	async buildRows(
		singletonsAsOfUTC: number,
		data: SiteAreaData,
	): Promise<EntrepreneurRow[]> {

		if (!data) return [];

		const siteId = data.site.siteId;


		const accTeams = (await this.ds.admin.accTeam.getAllPackagesAsArray()).filter(team => siteId == team.accelerator.siteProgram.siteId);
		const allAccTeamsMap = this.util.array.toMap(accTeams, team => team.accTeamId);
		const accTeamIds = accTeams.map(team => team.accTeamId);
		const allAccTeamMembers = (await this.ds.admin.accTeamMember.getAllAsArray()).filter(member => member.role == "E" && accTeamIds.includes(member.accTeamId));

		const picTeams = (await this.ds.admin.picTeam.getAllPackagesAsArray()).filter(team => siteId == team.pitchContest.siteProgram.siteId);
		const allPicTeamsMap = this.util.array.toMap(picTeams, team => team.picTeamId);
		const picTeamIds = picTeams.map(team => team.picTeamId);
		const allPicTeamMembers = (await this.ds.admin.picTeamMember.getAllAsArray()).filter(member => picTeamIds.includes(member.picTeamId));

		let index = 0;
		const accTeamRows: EntrepreneurRow[] = allAccTeamMembers.map(a => ({
			indexId: index++,
			personId: a.personId,
			companyId: allAccTeamsMap[a.accTeamId].company.companyId,
			companyName: allAccTeamsMap[a.accTeamId].company.name,
			companyIndustry: allAccTeamsMap[a.accTeamId].company.industryId ? this.ds.domain.industry.getOne(allAccTeamsMap[a.accTeamId].company.industryId)?.name || '' : '',
			companyStatus: allAccTeamsMap[a.accTeamId].company.status,
			companyOfferings: allAccTeamsMap[a.accTeamId].company.offerings,
			accId: allAccTeamsMap[a.accTeamId].accId,
			picId: undefined,
			programName: allAccTeamsMap[a.accTeamId].accelerator.name,
			type: 'Accelerator',
			language: allAccTeamsMap[a.accTeamId].accelerator.siteProgram.program.language.name,
			updatedUTC: a.updatedUTC,
		}));

		const picTeamRows: EntrepreneurRow[] = allPicTeamMembers.map(a => ({
			indexId: index++,
			personId: a.personId,
			companyId: allPicTeamsMap[a.picTeamId].company.companyId,
			companyName: allPicTeamsMap[a.picTeamId].company.name,
			companyIndustry: allPicTeamsMap[a.picTeamId].company.industryId ? this.ds.domain.industry.getOne(allPicTeamsMap[a.picTeamId].company.industryId)?.name || '' : '',
			companyStatus: allPicTeamsMap[a.picTeamId].company.status,
			companyOfferings: allPicTeamsMap[a.picTeamId].company.offerings,
			picId: allPicTeamsMap[a.picTeamId].picId,
			accId: undefined,
			programName: allPicTeamsMap[a.picTeamId].pitchContest.name,
			type: 'Pitch Contest',
			language: allPicTeamsMap[a.picTeamId].pitchContest.siteProgram.program.language.name,
			updatedUTC: a.updatedUTC,
		}));


		return [...accTeamRows, ...picTeamRows];
	}


	private valueStyle(row: EntrepreneurRow, field: string): GridValueStyle {
		const value = row[field];

		if (field == 'type') { // Program
			if (value == 'Accelerator') return { icon: 'far fa-tachometer-alt-fast', textStyle: 'Hidden' };
			if (value == 'Pitch Contest') return { icon: 'far fa-bullhorn', textStyle: 'Hidden' };
		}

		return { icon: 'fal fa-question', iconColor: 'Red', textStyle: 'Hidden' };
	}


	private setupGrid(): GridSetup<EntrepreneurRow> {
		const valueStyle = this.valueStyle.bind(this);

		return {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Entrepreneur",
			rowPluralName: "Entrepreneurs",
			rowKey: "indexId",
			stateKey: "region-entrepreneurs",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				{ field: "companyId", header: "companyId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "accId", header: "accId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "picId", header: "picId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "programName", header: "Program Name", width: 150, type: GridColumnType.text },
				{ field: "type", header: "Program", width: 100, type: GridColumnType.text, valueStyle },
				{ field: "language", header: "Language", width: 100, type: GridColumnType.text },
				{ field: PersonCols.awards, header: 'Awards', width: 90, type: GridColumnType.dollars, hidden: false },
				{ field: "companyName", header: "Business", width: 150, type: GridColumnType.text },
				{ field: "companyIndustry", header: "Industry", width: 160, type: GridColumnType.text },
				{ field: "companyStatus", header: "Status", width: 100, type: GridColumnType.text },
				{ field: "companyOfferings", header: "Offerings", width: 100, type: GridColumnType.text },
			],
			initialState: {
				sort: [{ field: PersonCols.name, dir: 'asc' }],
			},
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: EntrepreneurRow[] }) {

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}
	}

}