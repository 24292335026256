
import { DdLanguageRow } from './dd-language-row';
import { DdLanguage } from './dd-language';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdLanguages extends DdObjects<DdLanguageRow, DdLanguage> {

	constructor(private dd: OldDomainDataService) {
		super('languageId');
	}

	async loadData(hash: string, data: DdLanguageRow[]) {
		const objects = data.map(d => new DdLanguage(this.dd, d));
		this.loadObjects(hash, objects);
	}
}