import { AccAreaService } from '@ACC-area';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DataService } from '@me-services/core/data';
import { DialogAction, DialogContext, DialogService } from '@me-services/ui/dialog';
import { LayoutService } from '@me-services/ui/layout';
import { BehaviorSubject } from 'rxjs';
import { ScenarioV2 } from './scenario';


@Component({
	selector: 'matcher-v2-dialog',
	templateUrl: './matcher-v2.dialog.html',
})
export class AccMatcherV2Dialog extends DestroyablePart implements OnInit {

	icon = 'fa-search';
	headerText = 'Matching Tool';

	stopAction: DialogAction<void> = {
		id: 'stop',
		enabled: true,
		visible: false,
		label: 'Stop',
		linkType: 'actionClick-event',
		willCloseDialog: false,
	};

	applyAction: DialogAction<void> = {
		id: 'apply',
		enabled: true,
		visible: false,
		label: 'Apply Scenario',
		linkType: 'actionClick-event',
		willCloseDialog: false,
	};

	closeAction: DialogAction<void> = {
		id: 'close',
		enabled: true,
		visible: false,
		label: 'Close Without Applying',
		linkType: 'no-action',
		willCloseDialog: true,
	};

	actions = [this.stopAction, this.applyAction, this.closeAction];

	scenariosFound$ = new BehaviorSubject<number>(0);
	bestScenario$ = new BehaviorSubject<ScenarioV2>(undefined);

	worker: Worker;


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<void>,
		public dialogRef: MatDialogRef<AccMatcherV2Dialog>,
		private accAreaService: AccAreaService,
		private ds: DataService,
		private dialogService: DialogService,
		private layoutService: LayoutService,
	) {
		super();
	}


	ngOnInit() {

		super.initDestroyable();

		this.worker = new Worker(new URL('./worker/matcher-v2.worker', import.meta.url));

		this.worker.onmessage = e => {
			const data = e.data;
			if (data.id == 'PROGRESS') {

				this.scenariosFound$.next(data.scenariosFound);
				if (data.bestScenario) {
					if (this.bestScenario$.value == undefined || this.bestScenario$.value.id !== data.bestScenario.id) {
						this.bestScenario$.next(data.bestScenario);
					}
				}
			}
		};


		const data = this.dialog.data;

		this.worker.postMessage({ id: 'START', data, mostUsesWantedOfAnyOneMeetingTime: 2 });

		this.stopAction.visible = true;
	}


	stop() {
		this.worker.postMessage({ id: 'STOP' });

		this.stopAction.visible = false;
		if (this.bestScenario$.value) this.applyAction.visible = true;
		this.closeAction.visible = true;

	}


	applyScenario() {
		this.dialogService.showMessage('Applying scenario!');
	}


	onActionClick(action: DialogAction<unknown>) {

		if (action.id == 'stop') this.stop();
		if (action.id == 'apply') this.applyScenario();
	}


	onClosed() {
		console.log(`TERMINATING WORKER!`);
		this.stop();
		this.worker.terminate();
	}


	getMentorNames(mentors: { personId: number, name: string }[]) {

		return mentors.map(m => m.name).join(', ');
	}

}