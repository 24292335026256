<app-area-access [areaService]='accAreaService' accessKey="assessments" [showReadonlyMessage]="true">
	<div class='acc-interviewing-editor-view-part' *ngIf="data$|async; let data;">
		<message-line *ngIf='accelerator?.accStageId !== AccStageId_Interviewing && !readonly' alertType='warning' [closeable]="false">
			Accelerator must be in stage "Interviewing Applications" to make changes.
		</message-line>
		<div class="selection-toolbar">
			<div>
				<div>Interviewer</div>
				<me-droplist [items]='data.interviewerList' [selectedItem]='interviewerSelectedItem' sortBy='text'
					(selectionChange)='onInterviewerChange($event)' [readonly]='readonly'>
				</me-droplist>
			</div>
			<div>
				<div>Application</div>
				<me-droplist [items]='data.applicationList' [selectedItem]='applicationSelectedItem' sortBy='text'
					(selectionChange)='onApplicationChange($event)'
					[readonly]="readonly || !interviewerSelectedItem">
				</me-droplist>
			</div>
			<spinner-button
				[disabled]='readonly || !applicationSelectedItem || !interviewerSelectedItem || accelerator.accStageId !== AccStageId_Interviewing'
				(safeClick)='load()'>Add/Edit</spinner-button>

		</div>

		<div *ngIf='interviewerAssessment && !readonly'>
			<div>
				<form [formGroup]="form" novalidate>
					<me-h2>Review of: {{ applicationSelectedItem.data.companyName }}</me-h2>

					<editable-questions [answers]='interviewerAssessment.answers' [stepper]='stepper'
						[formGroup]='form'>
					</editable-questions>

					<mat-expansion-panel [expanded]="stepper.step === ratingStep" (opened)="stepper.set(ratingStep)">
						<mat-expansion-panel-header>
							<mat-panel-title
								[class.mat-error]='form.controls.rating.touched && form.controls.rating.errors'>
								<i *ngIf='form.controls.rating.value' class='fas fa-check-square checkbox'></i>
								<i *ngIf='!form.controls.rating.value' class='far fa-square checkbox'></i>
								Overall Rating*
							</mat-panel-title>
						</mat-expansion-panel-header>

						<div class='mb-3'>Q. Should this applicant be accepted into the accelerator?</div>

						<mat-form-field>
							<mat-select placeholder="Select" formControlName="rating">
								<mat-option [value]="1">No</mat-option>
								<mat-option [value]="2">Lean toward No</mat-option>
								<mat-option [value]="3">Lean toward Yes</mat-option>
								<mat-option [value]="4">Yes</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-action-row>
							<button (click)="stepper.prev()">Previous</button>
							<button (click)="stepper.set(-1)">Done</button>
						</mat-action-row>

					</mat-expansion-panel>


					<button-container>
						<spinner-button (safeClick)='save()' [disabled]='form.invalid || !form.dirty || saving || readonly'
							[spinning]='saving'>
							<span *ngIf='!this.interviewerAssessment.rating'>Submit Review</span>
							<span *ngIf='this.interviewerAssessment.rating'>Update Review</span>
						</spinner-button>
					</button-container>
				</form>
			</div>
		</div>

	</div>
</app-area-access>