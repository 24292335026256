import { AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId, Event, EventTypeId } from '@me-interfaces';
import { Icon, IconContext, getIconClass } from '@me-shared-parts/UI-common';
import { AccPreAcceleratorStageId } from '../../../acc-application-status-helpers';


export interface StatusIcon {
	icon: IconContext;
	link?: string;
}


@Component({
	selector: 'acc-match-checks-part',
	templateUrl: './acc-match-checks.part.html',
})
export class AccMatchChecksPart extends DestroyablePart implements OnInit {

	accStageId: AccPreAcceleratorStageId = AccStageId.MatchingMentors;

	mentorsStatusIcon: StatusIcon;
	coachesStatusIcon: StatusIcon;
	eventsStatusIcon: StatusIcon;

	buttonCounts: {
		allMentors: number,
		canCoach: number,
		multiUse: number,
	}

	constructor(public accAreaService: AccAreaService) {
		super();
	}


	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe([this.accAreaService.teams.teams$, this.accAreaService.mentorMatching.matchableMentors$, this.accAreaService.accelerator.events$], async ([teams, mentors, events]) => {

			this.setCoachesStatusIcon(teams?.length, mentors?.reduce((a, m) => {
				if (!!m.person.tags?.find(t => t.fullName.includes('coach-approved') && t.siteId == events[0].siteId)) a += 1;
				return a;
			}, 0));
			this.setMentorsStatusIcon(mentors?.reduce((a, m) => a += m.useCount, 0), teams?.length);
			this.setEventsStatusIcon(events?.filter(e => e.eventTypeId == EventTypeId.AccMentorMatching));
			this.buttonCounts = {
				allMentors: mentors?.length,
				canCoach: mentors?.reduce((a, m) => {
					if (!!m.person.tags?.find(t => t.fullName.includes('coach-approved') && t.siteId == events[0].siteId)) a += 1;
					return a;
				}, 0),
				multiUse: mentors?.reduce((a, m) => a += m.useCount > 1 ? 1 : 0, 0),
			}
		});
	}


	setCoachesStatusIcon(totalTeams: number, totalCoaches: number) {
		const coachesPerTeam = totalTeams > 0 ? (totalCoaches + 1) / totalTeams : totalCoaches;
		const icon: Icon = coachesPerTeam >= 1 ? Icon.status_success : Icon.status_x;
		const link = '';

		this.coachesStatusIcon = {
			icon: {
				icon,
				iconClass: getIconClass(icon),
				text: `${coachesPerTeam?.toFixed(2)} Matchable Coach${coachesPerTeam != 1 ? 'es' : ''} per Team`,
			},
			link: totalTeams > 0 ? undefined : link,
		};
	}


	setMentorsStatusIcon(totalMentors: number, totalTeams: number) {
		const mentorsPerTeam = totalTeams > 0 ? totalMentors / totalTeams : totalMentors;
		const icon: Icon = mentorsPerTeam >= 2 ? Icon.status_success : Icon.status_x;
		const link = '';

		this.mentorsStatusIcon = {
			icon: {
				icon,
				iconClass: getIconClass(icon),
				text: `${mentorsPerTeam?.toFixed(2)} Matchable Mentor${mentorsPerTeam != 1 ? 's' : ''} ${totalTeams > 0 ? 'per Team' : 'total'}`,
			},
			link: totalMentors > 0 ? undefined : link,
		};
	}


	setEventsStatusIcon(events: Event[]) {
		const icon: Icon = events?.length > 0 ? Icon.status_success : Icon.status_x;
		const link = '';

		this.eventsStatusIcon = {
			icon: {
				icon,
				iconClass: getIconClass(icon),
				text: `${events?.length} Matching Event${events?.length != 1 ? 's' : ''}`,
			},
			link: events?.length > 0 ? undefined : link,
		};
	}

}
