import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FieldKindPhone } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { LabelsService } from '@me-services/ui/labels';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BaseFieldPart } from '../base/SHR-UI_base-field.part';

@Component({
	selector: 'phone-field',
	templateUrl: './SHR-UI_phone-field.part.html',
})
export class PhoneFieldPart extends BaseFieldPart<FieldKindPhone> implements AfterViewInit, OnInit, OnDestroy {

	basePart: BaseFieldPart<FieldKindPhone> = this;
	telInput = undefined;

	@ViewChild('phoneField') phoneField: ElementRef;

	constructor(
		protected override func: FuncService,
		protected override labelsService: LabelsService,
	) {
		super(func, labelsService);
	}


	ngAfterViewInit() {

		fromEvent(this.phoneField.nativeElement, 'keyup')
			.pipe(
				takeUntil(this.destroyed$),
				debounceTime(50),
			)
			.subscribe(this.onKeyUp.bind(this));

		//
		// Hookup the intl-tel-input
		//
		const input = this.phoneField.nativeElement;
		this.telInput = (<any>window).intlTelInput(input, {
			nationalMode: true,
			utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.3/js/utils.js"
		});
	}


	override async ngOnInit() {
		if (!this.value) throw new Error('Missing required attribute: value'); // missing value will still be an empty object
		await super.ngOnInit();
	}


	override ngOnDestroy() {
		super.ngOnDestroy();
		this.telInput.destroy();
	}


	onKeyUp(event: KeyboardEvent) {
		if (event.isTrusted && event.key == 'Enter') {
			// The user pressed Enter
			this.attemptToSave();
		}
	}


	onInput(/* event: InputEvent */) {
		const phone: FieldKindPhone = { ...this.pendingValue };
		phone.number = this.telInput.getNumber();
		this.basePart.setPendingValue(phone);
	}


	override hasPendingValue(): boolean {
		return this.telInput.isValidNumber();
	}


	override hasCurrentValue(): boolean {
		return this.currentValue && this.currentValue.number && !!this.currentValue.phoneTypeId;
	}


	override valuesAreSame(value1: FieldKindPhone, value2: FieldKindPhone): boolean {
		if (!value1 || !value2) return false;
		return value1.number == value2.number && value1.phoneTypeId == value2.phoneTypeId;
	}


	/** Calculate a max-width style that is roughly based on the maximum char length */
	get maxWidth() {
		const m = this.field?.maxLength || 100;
		return (m + 2) - m / 5;
	}
}