import { AccTeam, DbConceptName, NoteCategoryId } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { DataService } from "../../../../data.service";
import { ExplorerBoxClass } from "../../../explorer-box/explorer-box";
import { ExplorerNoteBox } from "../../../explorer-note-box/explorer-note-box";


export async function getBoxesForRelatedNotes(util: UtilityService, ds: DataService, accTeam: AccTeam): Promise<ExplorerNoteBox[]> {

	const boxes: ExplorerNoteBox[] = [];

	const companyRelatedNotes = await getBoxesForCompanyNotes(util, ds, accTeam);

	const entrepreneursRelatedNotes = await getBoxesForEntrepreneursNotes(util, ds, accTeam);

	boxes.push(...companyRelatedNotes, ...entrepreneursRelatedNotes);

	return boxes;

}

async function getBoxesForCompanyNotes(util: UtilityService, ds: DataService, accTeam: AccTeam): Promise<ExplorerNoteBox[]> {
	const boxes: ExplorerNoteBox[] = [];

	//
	// Clean the list of noteIds, and sort them largest (newest) first.
	//

	const noteIds = util.array.cleanNumericIds(accTeam.company.noteIds);

	//
	// Get the notes packages
	//
	const notes = [...(await ds.admin.entityNote.getManyAsArray(noteIds))].sort((a, b) => b.updatedUTC - a.updatedUTC);

	const noteCategories: { [index: number]: string } = [...await ds.domain.noteCategory.getAll()].reduce((a, noteCategory) => { a[noteCategory.noteCategoryId] = noteCategory.label; return a; }, {});

	const people = await ds.admin.person.getManyPackagesAsMap(notes.map(note => note.updatedByPersonId));
	const sites = await ds.admin.site.getManyPackagesAsMap(notes.filter(note => note.siteId).map(note => note.siteId));

	for (const note of notes) {

		const box: ExplorerNoteBox = {
			explorerBoxClass: note.noteCategoryId == NoteCategoryId.RedFlag ? ExplorerBoxClass.RedFlagNote : ExplorerBoxClass.Note,
			header: `${noteCategories[note.noteCategoryId]} Note - ${util.date.formatUTC(note.updatedUTC, 'MM/DD/YYYY', 'No Time', ds.languageId)}`,
			buttons: [
				{ explorer: { nameOverride: `Note For: ${accTeam.company.longName}`, explorableId: { conceptName: DbConceptName.Company, id: accTeam.company.companyId }, navTooltip: `Note For: ${accTeam.company.longName}` } },
				{ explorer: { nameOverride: `Author: ${people[note.updatedByPersonId].fullName}`, explorableId: { conceptName: DbConceptName.Person, id: note.updatedByPersonId }, navTooltip: `Author: ${people[note.updatedByPersonId].fullName}` } },
			],
			subject: note.subject,
			note: note.note
		};

		if (note.siteId) box.buttons.push({ explorer: { explorableId: { conceptName: DbConceptName.Site, id: note.siteId }, navTooltip: `Site: ${sites[note.siteId].name}` } },);


		boxes.push(box);

	}

	return boxes;
}


async function getBoxesForEntrepreneursNotes(util: UtilityService, ds: DataService, accTeam: AccTeam): Promise<ExplorerNoteBox[]> {
	const boxes: ExplorerNoteBox[] = [];

	//
	// Clean the list of noteIds, and sort them largest (newest) first.
	//
	const entrepreneursNoteId = [];

	const entrepreneurIds = accTeam.members.filter(m => m.member.role === 'E').map(m => m.person.personId);
	const entrepreneurs = await ds.admin.person.getManyPackagesAsArray(entrepreneurIds);
	for (const entrepreneur of entrepreneurs) {
		entrepreneursNoteId.push(...entrepreneur.noteIds)
	}

	const noteIds = util.array.cleanNumericIds(entrepreneursNoteId);

	//
	// Get the notes packages
	//
	const notes = [...(await ds.admin.entityNote.getManyAsArray(noteIds))].sort((a, b) => b.updatedUTC - a.updatedUTC);

	const noteCategories: { [index: number]: string } = [...await ds.domain.noteCategory.getAll()].reduce((a, noteCategory) => { a[noteCategory.noteCategoryId] = noteCategory.label; return a; }, {});

	const people = await ds.admin.person.getManyPackagesAsMap(notes.map(note => note.updatedByPersonId));
	const sites = await ds.admin.site.getManyPackagesAsMap(notes.filter(note => note.siteId).map(note => note.siteId));

	for (const note of notes) {
		const person = entrepreneurs.find(e => e.asSingleton.entityId === note.entityId);

		const box: ExplorerNoteBox = {
			explorerBoxClass: note.noteCategoryId == NoteCategoryId.RedFlag ? ExplorerBoxClass.RedFlagNote : ExplorerBoxClass.Note,
			header: `${noteCategories[note.noteCategoryId]} Note - ${util.date.formatUTC(note.updatedUTC, 'MMM D, YYYY', 'No Time', ds.languageId)}`,
			buttons: [
				{ explorer: { nameOverride: `Note For: ${person.asSingleton._name}`, explorableId: { conceptName: DbConceptName.Person, id: person.personId }, navTooltip: `Note For: ${person.asSingleton._name}` } },
				{ explorer: { nameOverride: `Author: ${people[note.updatedByPersonId].fullName}`, explorableId: { conceptName: DbConceptName.Person, id: note.updatedByPersonId }, navTooltip: `Author: ${people[note.updatedByPersonId].fullName}` } },
			],
			subject: note.subject,
			note: note.note
		};

		if (note.siteId) box.buttons.push({ explorer: { explorableId: { conceptName: DbConceptName.Site, id: note.siteId }, navTooltip: `Site: ${sites[note.siteId].name}` } },);


		boxes.push(box);

	}

	return boxes;
}