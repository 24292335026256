import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { AccTeam } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { UrlService } from '@me-services/ui/url';
import { Icon, getIconContext } from '@me-shared-parts/UI-common';
import { IconBarIcon, getIconBarSelections } from '@me-shared-parts/UI-common/icon-bar';
import { IconBarPart, IconBarSelections } from '@me-shared-parts/UI-common/icon-bar/SHR-CMN_icon-bar.part';
import { DataService } from '../../../data.service';
import { ExplorerBox } from '../../explorer-box/explorer-box';
import { ExplorerNoteBox } from '../../explorer-note-box/explorer-note-box';
import { LinkOrExplorable } from '../../link-or-explorerable';
import { getBoxesForAccelerator } from './boxes/get-boxes-for-accelerator';
import { getBoxesForAwards } from './boxes/get-boxes-for-awards';
import { getBoxesForMember } from './boxes/get-boxes-for-members';
import { getBoxesForRelatedNotes } from './boxes/get-boxes-for-related-notes';
import { getButtons } from './get-buttons';
import { VIEW_ACCELERATOR, VIEW_AWARDS, VIEW_DETAILS, VIEW_MEMBERS, VIEW_RELATED_NOTES, views } from './views';

const STORAGE_KEY = 'AccTeamExplorerIconSelection';

@Component({
	selector: 'acc-team-explorer',
	templateUrl: './acc-team-explorer.part.html',
	styleUrls: ['./acc-team-explorer.part.scss'],
})
export class AccTeamExplorerPart implements OnChanges {

	@Input() public accTeam: AccTeam;
	@ViewChild('iconBar') iconBar: IconBarPart;

	public views = views;
	public icons = {
		redFlag: getIconContext(Icon.notes_redFlag),
		accTeam: getIconContext(Icon.concept_accTeam),
		award: getIconContext(Icon.concept_award),
	};


	public selectedViews: IconBarSelections = undefined;
	public buttons: LinkOrExplorable[] = [];

	public memberBoxes: ExplorerBox[] = [];
	public accBoxes: ExplorerBox[] = [];
	public awardBoxes: ExplorerBox[] = [];
	public relatedNoteBoxes: ExplorerNoteBox[] = [];

	constructor(
		public util: UtilityService,
		private ds: DataService,
		private urlService: UrlService
	) { }


	async ngOnChanges() {
		if (!this.accTeam) throw new Error(`Missing required attribute: accTeam`);
		this.icons.accTeam.text = this.accTeam.name;
		if (this.accTeam.awardedValue > 0) this.icons.award.text = `Awarded: $${this.accTeam.awardedValue.toLocaleString()}`;
		await this.createContent();
		this.views = this.updateIconBar();
	}

	/**
	 * CreateContent is called once when a new accelerator is passed in.  All content is created but
	 * may be shown or hidden using css instead of rebuilding everything again.
	 */
	private async createContent() {

		this.buttons = getButtons(this.accTeam);
		this.memberBoxes = await getBoxesForMember(this.accTeam);
		this.awardBoxes = await getBoxesForAwards(this.ds, this.accTeam);
		this.accBoxes = await getBoxesForAccelerator(this.ds, this.accTeam);
		this.relatedNoteBoxes = await getBoxesForRelatedNotes(this.util, this.ds, this.accTeam);

	}


	private updateIconBar(): IconBarIcon[] {

		//
		// Get selections from last time this explorer was accessed. If there
		// isn't on,then build the selections from the initial views in views.ts.
		//
		const selectedViewsStr = sessionStorage.getItem(STORAGE_KEY);
		let selectedViews: IconBarSelections;

		if (selectedViewsStr) {
			this.selectedViews = selectedViews = JSON.parse(selectedViewsStr);
		}
		else {
			this.selectedViews = selectedViews = getIconBarSelections(views);
			sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.selectedViews));
		}

		//
		// Create a new array of icon views using the initial set but overriding
		// whether disabled or not and the tooltip text based on the created content.
		//
		return views.map(view => {
			if (view.key == VIEW_DETAILS) {
				view.disabled = false; // always has a value
				view.selected = this.selectedViews.map[VIEW_DETAILS];
				view.tooltip = `Acc Team Details`;
			}
			else if (view.key == VIEW_RELATED_NOTES) {
				const count = this.relatedNoteBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_RELATED_NOTES];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Related Note', 'Related Notes');
			}
			else if (view.key == VIEW_ACCELERATOR) {
				const count = this.accBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_ACCELERATOR];
				view.tooltip = 'Accelerator';
			}
			else if (view.key == VIEW_MEMBERS) {
				const count = this.memberBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_MEMBERS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Member', 'Members');
			}
			else if (view.key == VIEW_AWARDS) {
				const count = this.awardBoxes.length; // + this.pitchContestBoxes.length
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_AWARDS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Award', 'Awards');
			}

			return view;
		});


	}


	public async setViews(selectedViews: IconBarSelections) {
		if (!selectedViews) return;
		this.selectedViews = selectedViews;
		sessionStorage.setItem(STORAGE_KEY, JSON.stringify(selectedViews));

		for (const view of this.views) {
			view.selected = !!selectedViews.map[view.key];
		}
	}


	public getDisplay(key: string) {
		const view = this.views.find(view => view.key == key);
		if (view == undefined) return 'none';
		return !!view.disabled || !view.selected ? 'none' : 'block';
	}


	public click(action: 'red-flag' | 'awards') {
		if (this.iconBar) {
			if (action == 'red-flag') this.iconBar.selectOnlyOne(VIEW_RELATED_NOTES);
			if (action == 'awards') this.iconBar.selectOnlyOne(VIEW_AWARDS);
		}
	}


	public tabClose(header: string) {

		if (header.endsWith('Details')) this.unselect(VIEW_DETAILS);
		if (header.endsWith('Accelerator')) this.unselect(VIEW_ACCELERATOR);
		if (header.endsWith('Award') || header.endsWith('Awards')) this.unselect(VIEW_AWARDS);
		if (header.endsWith('Member') || header.endsWith('Members')) this.unselect(VIEW_MEMBERS);
	}


	private unselect(key: string) {

		this.iconBar.unselect(key);

		const view = this.views.find(view => view.key == key);
		if (view) view.selected = false;
	}

}