import { Component, Input, OnChanges } from '@angular/core';
import { Address, DbsPerson } from '@me-interfaces';

@Component({
	selector: 'person-extract-banner',
	templateUrl: './SHR-PN_person-extract-banner.part.html',
	styleUrls: ['./SHR-PN_person-extract-banner.part.scss']
})
export class PersonExtractBannerPart implements OnChanges {
	@Input() person: DbsPerson;

	address: Address;

	ngOnChanges() {
		if (!this.person) throw new Error('Missing required attribute: person');

		this.address = { address: this.person.address || undefined, zip: this.person._entityExtracts.zip || undefined };
	}
}