
import { DdIndustryRow } from './dd-industry-row';
import { DdIndustry } from './dd-industry';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdIndustries extends DdObjects<DdIndustryRow, DdIndustry> {

	constructor(private dd: OldDomainDataService) {
		super('industryId');
	}

	async loadData(hash: string, data: DdIndustryRow[]) {
		const objects = data
			.map(d => new DdIndustry(this.dd, d))
			.sort((a, b) => a.industryId > 0 && b.industryId < 0 ? -1 : a.name < b.name ? -1 : 1);
		this.loadObjects(hash, objects);
	}
}