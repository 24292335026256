import { ReplaySubject } from 'rxjs';
import { TextAreaDialogFieldPart } from '..';

export interface FieldFormStatus {
	numCompleted: number;
	numRemaining: number;
	numTotal: number;
}

/**
 * A state class that manages and reports the overall status of multiple field parts.
 */
export class FieldFormState {

	private _complete = new Set<string>();
	private _incomplete = new Set<string>();

	public readonly status$ = new ReplaySubject<FieldFormStatus>(1);


	constructor() {
		this.status$.next(this.getStatus());
	}


	/**
	 * Mark whether a field is considered complete, which
	 * is when there is no error and either it has a value
	 * or it is not required.
	 */
	public setCompleted(key: string, value: boolean) {
		const a = value ? this._complete : this._incomplete;
		const b = value ? this._incomplete : this._complete;

		a.add(key);
		b.delete(key);

		this.status$.next(this.getStatus());
	}


	/**
	 * Returns true if there is at least one field
	 * and all fields are completed.
	 */
	public get isComplete(): boolean {
		return this._incomplete.size == 0 && this._complete.size > 0;
	}

	private getStatus(): FieldFormStatus {

		const numCompleted = this._complete.size;
		const numRemaining = this._incomplete.size;
		const numTotal = numCompleted + numRemaining;

		return { numCompleted, numRemaining, numTotal };
	}



	private _textAreaFields: TextAreaDialogFieldPart[] = [];


	// public get numTextAreaFields() {
	// 	return this._textAreaFields.length;
	// }


	/**
	 * Each text area added in turn will point to the next.
	 * This powers the Next button in the dialog.
	 */
	public addTextAreaField(newPart: TextAreaDialogFieldPart) {
		//
		// Hook up the next button links
		//
		this._textAreaFields.push(newPart);

		if (this._textAreaFields.length > 1) {

			//
			// The priorPart is the one just before the newly added one
			//
			const priorPart = this._textAreaFields[this._textAreaFields.length - 2];
			priorPart.nextEditText = newPart.editText.bind(newPart);


			//
			// The new one will point to the first one (loop back to beginning)
			//
			const firstPart = this._textAreaFields[0];
			newPart.nextEditText = firstPart.editText.bind(firstPart);
		}
	}


	/**
	 * Remove the text area from the list.
	 */
	public removeTextAreaField(newPart: TextAreaDialogFieldPart) {
		const i = this._textAreaFields.indexOf(newPart);
		if (i > -1) this._textAreaFields.splice(i, 1);
	}


	/**
	 * Given the instance identifier of a text area field part, lookup the
	 * index of that field in the array, return it and the total count. This
	 * is used for the display in the dialog header 'Paragraph Question 3 of 5' 
	 */
	public getTextAreaFieldIndex(instanceId: string) {
		const fieldNumber = this._textAreaFields.findIndex(f => f.INSTANCE_ID == instanceId) + 1;
		const fieldCount = this._textAreaFields.length;

		return { fieldNumber, fieldCount };
	}
}