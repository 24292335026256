<!-- <h5>Notes</h5> -->

<mat-form-field appearance="outline">
	<mat-label>Notes</mat-label>
	<textarea #notesTxt matInput mat-autosize matAutosizeMaxRows='10' matAutosizeMinRows='4' placeholder="Notes" type="text" [value]='notes' (change)='setNotes($event)'></textarea>
</mat-form-field>

<button-container>
	<spinner-button (safeClick)='dialogRef.close()' [disabled]='saving'>Cancel</spinner-button>
	<spinner-button (safeClick)='save()' [disabled]='saving' [spinning]='saving'>Save Notes</spinner-button>
</button-container>