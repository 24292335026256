import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DbcEventAttendee, EventAttendeeStatus, SearchContactResult } from '@me-interfaces';
import { OldDomainDataService } from '@me-services/core/old-dd';
import { UtilityService } from '@me-services/core/utility';
import { EventAttendeeRow } from '../SHR-ED_event-attendees.part';
import { Icon, getIconContext } from '@me-shared-parts/UI-common';
import { DataService } from '@me-services/core/data';

@Component({
	selector: 'event-attendee-edit',
	templateUrl: './SHR-ED_event-attendee-edit.part.html',
	styleUrls: ['SHR-ED_event-attendee-edit.part.scss'],
})
export class EventAttendeeEditPart implements OnChanges {

	@Input() eventAttendee: DbcEventAttendee | EventAttendeeRow;
	@Input() readonly: boolean;
	@Input() isAdd: boolean;

	@Output() pendingAttendee = new EventEmitter<DbcEventAttendee>();

	editing = false;
	public searchIcon = getIconContext(Icon.action_search, undefined, 'never', 'Search Person');

	statuses = [EventAttendeeStatus.Registered, EventAttendeeStatus.Attended, EventAttendeeStatus.NoShow];
	orgAttendee: DbcEventAttendee;
	newAttendee: DbcEventAttendee;

	missing = {
		personId: false,
		status: false,
	};

	constructor(
		public dd: OldDomainDataService,
		private ds: DataService,
		public util: UtilityService
	) {
	}

	ngOnChanges() {
		this.initializeAttendee(this.eventAttendee);
	}


	initializeAttendee(attendee: DbcEventAttendee) {
		if (!attendee) return;
		this.orgAttendee = { ...attendee };
		this.newAttendee = { ...attendee };
	}

	async searchPerson() {
		this.newAttendee.personId = undefined;
		const conceptAndId = await this.ds.searcher.selectPerson();
		if (conceptAndId) {
			const person = await this.ds.admin.person.getOne(conceptAndId.id);
			this.newAttendee.personId = person.personId || undefined;
		}
		this.determineCanSave();

	}

	/**
	 * Whenever something changes, check if the save button should be enabled.
	 */
	determineCanSave() {
		this.missing.personId = !this.newAttendee.personId;
		this.missing.status = !this.newAttendee.status;

		const missingSomething = this.missing.personId || this.missing.status;

		const changedSomething = !this.util.values.areSame(this.orgAttendee, this.newAttendee);

		if (changedSomething && !missingSomething) {
			this.pendingAttendee.emit(this.newAttendee);
		}
		else {
			this.pendingAttendee.emit(undefined);
		}
	}


	setStatus(e: EventAttendeeStatus) {
		this.newAttendee.status = e;
		this.determineCanSave();
	}
}
