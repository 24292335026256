import { AccAreaService, AccessAtStage } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { AccMatchableMentor, AccStageId, AccTeam } from '@me-interfaces';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';


interface ScheduleDroplistItem {
	description: string,
	schedulee: AccTeam | AccMatchableMentor,
}


@Component({
	selector: 'acc-mm-schedule-editor-view-part',
	templateUrl: './acc-mm-schedule-editor-view.part.html',
	styleUrls: ['./acc-mm-schedule-editor-view.part.scss'],
})
export class AccMMScheduleEditorViewPart implements OnInit {

	public items$: Observable<DroplistItem<ScheduleDroplistItem, number>[]>;

	public selectedRoleToEdit$ = new BehaviorSubject<DroplistItem<ScheduleDroplistItem, number>>(undefined);

	readonly: boolean;

	constructor(private accAreaService: AccAreaService) {
	}


	ngOnInit(): void {
		this.items$ = combineLatest([
			this.accAreaService.mentorMatching.matchableMentors$,
			this.accAreaService.teams.teams$,
			this.accAreaService.getAccessAtStage(AccStageId.MatchingMentors),
		]
		).pipe(map(([mentors, accTeams, accessAtStage]) => this.buildRows(mentors, accTeams, accessAtStage)));
	}


	buildRows(
		mentors: AccMatchableMentor[],
		accTeams: readonly AccTeam[],
		accessAtStage: AccessAtStage,
	): DroplistItem<ScheduleDroplistItem>[] {

		this.readonly = accessAtStage?.access != 'Write';

		const data: DroplistItem<ScheduleDroplistItem>[] = [];
		if (!mentors || !accTeams) return data;


		let i = 0;
		for (const mentor of mentors) data.push({
			groupName: 'Mentors',
			uniqueId: i++,
			iconContext: getIconContext(Icon.concept_person, undefined, undefined, mentor.person.asSingleton._name),
			customSort: `1-${mentor.person.asSingleton._name}`,
			data: {
				description: `Mentor: ${mentor.person.asSingleton._name}`,
				schedulee: mentor,
			},
		});


		const activeTeams = [...accTeams.filter(accTeam => !accTeam.droppedOutWeek)];


		for (const accTeam of activeTeams) {

			const entrepreneurs = accTeam.members.filter(m => m.member.role == 'E').map(m => m.person._name).join(', ');

			data.push({
				groupName: 'Business',
				uniqueId: i++,
				iconContext: getIconContext(Icon.concept_company, undefined, undefined, accTeam.name),
				customSort: `3-${accTeam.name}`,
				data: {
					description: `Entrepreneurs: ${entrepreneurs}`,
					schedulee: accTeam,
				},
			});
		}


		for (const accTeam of activeTeams) {
			for (const accTeamMember of accTeam.members.filter(m => m.member.role == 'E')) data.push({
				groupName: 'Entrepreneurs',
				uniqueId: i++,
				iconContext: getIconContext(Icon.concept_person, undefined, undefined, accTeamMember.person._name),
				data: {
					description: `Team: ${accTeam.name}`,
					schedulee: accTeam,
				},
				customSort: `2-${accTeamMember.person._name}`,
			});
		}

		return data;
	}

}