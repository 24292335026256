import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import {
	MAX_SLOT_VALUE,
	DAY_NAMES, DAY_NAMES_ES, SLOT_NAMES, SLOT_NAMES_ES,
	isBitOn, isBitOff,
	turnBitOff, turnBitOn,
	slotsToMeetingStarts,
	hammingWeight,
	meetingSlots,
} from '@me-services/ui/schedule-utils';
import { FuncService } from '@me-services/core/func';
import { AccMatchingSchedule, LanguageId, SevenNums } from '@me-interfaces';
import { LabelsService } from '@me-services/ui/labels';

@Component({
	selector: 'schedule-selector',
	styleUrls: ['./SHR-CMN_schedule-selector.part.scss'],
	templateUrl: './SHR-CMN_schedule-selector.part.html'
})
export class ScheduleSelectorPart implements OnInit, OnChanges {

	@Input() func: FuncService;
	@Input() table: 'M' | 'T'; // Mentor, Team
	@Input() accId: number;
	@Input() accTeamId: number = undefined;  // only used if table == 'T'
	@Input() overridePersonId: number = undefined; // overridePersonId = mentor personId( only used if staff)
	@Output() changed = new EventEmitter<{ numMeetings: number, totalMeetings: number }>();

	lastDate = 0;

	// Slots are the values entered by the user where each bit flag represents a half hour slot (2^0 -> 2^29)
	slots: SevenNums = [0, 0, 0, 0, 0, 0, 0];
	blockout: SevenNums = [0, 0, 0, 0, 0, 0, 0];

	// The slot flags that are in a sequence of at least 3 that are on (a valid 90 minute meeting)
	slotsInAMeeting: SevenNums = [0, 0, 0, 0, 0, 0, 0];

	// Bit set on while writing to the database
	slotsSpinning: SevenNums = [MAX_SLOT_VALUE, MAX_SLOT_VALUE, MAX_SLOT_VALUE, MAX_SLOT_VALUE, MAX_SLOT_VALUE, MAX_SLOT_VALUE, MAX_SLOT_VALUE];

	numMeetings = 0;
	totalMeetings = 0;

	dayNames = DAY_NAMES;
	slotNames = SLOT_NAMES;

	constructor(private labels: LabelsService) { }
	ngOnInit() {
		this.dayNames = this.labels.languageId == LanguageId.Spanish ? DAY_NAMES_ES : DAY_NAMES;
		this.slotNames = this.labels.languageId == LanguageId.Spanish ? SLOT_NAMES_ES :SLOT_NAMES;
		this.setup();
	}

	ngOnChanges() {
		this.setup();
	}

	async setup() {
		const response: AccMatchingSchedule = await this.func.public.acc.matching.getSchedule(
			{
				tableCode: this.table,
				accId: this.accId,
				accTeamId: this.accTeamId,
				overridePersonId: this.overridePersonId
			}
		);

		this.setValues(response);
		this.slotsSpinning = [0, 0, 0, 0, 0, 0, 0];
	}

	countMeetingStarts(days: SevenNums, invert = false) {
		let numMeetings = 0;

		for (let d = 0; d < 7; d++) {
			let day = days[d];
			if (invert) day = MAX_SLOT_VALUE - day;

			// A bit is set if it AND the two following slot bits are set as true
			const meetingStarts = slotsToMeetingStarts(day);

			// Count the number of meeting starts (possible meetings)
			numMeetings += hammingWeight(meetingStarts);
		}

		return numMeetings;
	}

	getSlotsInAMeeting(days: SevenNums): SevenNums {
		const slotsInAMeeting: SevenNums = [0, 0, 0, 0, 0, 0, 0];

		for (let d = 0; d < 7; d++) {
			// A bit is set if it AND the two following slot bits are set as true
			const meetingStarts = slotsToMeetingStarts(days[d]);

			// Get the slots in which a person might physically be in a meeting 
			slotsInAMeeting[d] = meetingSlots(meetingStarts);
		}

		return slotsInAMeeting;
	}

	setValues(values: { days: SevenNums, date: number, blockout: SevenNums }) {

		if (values.date < this.lastDate) {
			this.slotsSpinning = [0, 0, 0, 0, 0, 0, 0]; // outa whack! Just reset all the spinners
			return; // could be out of order since async through interwebs
		}

		this.numMeetings = this.countMeetingStarts(values.days);
		this.slotsInAMeeting = this.getSlotsInAMeeting(values.days);
		this.totalMeetings = this.countMeetingStarts(values.blockout, true);

		this.slots = values.days;
		this.blockout = values.blockout;
		this.lastDate = values.date;

		this.changed.emit({
			numMeetings: this.numMeetings,
			totalMeetings: this.totalMeetings,
		});
	}

	checkMarkClass(day, slot) {
		if (this.isSpinning(day, slot)) return 'fal fa-spinner-third fa-spin';
		if (this.isBlockout(day, slot)) return 'far fa-square';

		const on = isBitOn(this.slots[day], slot);
		return on ? 'fas fa-check-square' : 'far fa-square';
	}

	inMeeting(day, slot) {
		return isBitOn(this.slotsInAMeeting[day], slot);
	}

	isSpinning(day, slot) {
		return isBitOn(this.slotsSpinning[day], slot);
	}

	isBlockout(day, slot) {
		return isBitOn(this.blockout[day], slot);
	}

	toggleSlot(day: number, slot: number) {
		if (this.isBlockout(day, slot)) return;
		this.toggle(day, slot, !isBitOn(this.slots[day], slot));
	}

	toggleColumn(day: number) {
		const unblockout = MAX_SLOT_VALUE - this.blockout[day];
		const set = (this.slots[day] & unblockout) !== unblockout;
		this.toggle(day, -1, set);
	}

	toggleRow(slot: number) {
		const set = undefined !== this.slots
			.filter((day, i) => isBitOff(this.blockout[i], slot))
			.find(day => isBitOff(day, slot));

		this.toggle(-1, slot, set);
	}

	toggleAll() {
		const set = undefined !== this.slots
			.find((day, i) => {
				const unblockout = MAX_SLOT_VALUE - this.blockout[i];
				return (day & unblockout) !== unblockout;
			});
		this.toggle(-1, -1, set);
	}

	async toggle(day: number, slot: number, set: boolean) {

		this.setSpinning(day, slot, set, turnBitOn);

		const response = await this.func.public.acc.matching.setSchedule({
			tableCode: this.table,
			accId: this.accId,
			slot,
			day,
			set,
			accTeamId: this.accTeamId,
			overridePersonId: this.overridePersonId
		});

		this.setValues(response);
		this.setSpinning(day, slot, false, turnBitOff);
	}

	setSpinning(day: number, slot: number, set: boolean, turnBitOnOrOff: (value: number, bit: number) => number) {
		
		let dayIndexes = [0, 1, 2, 3, 4, 5, 6];
		if (day !== -1) dayIndexes = [day];

		let slotIndexes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29];
		if (slot !== -1) slotIndexes = [slot];

		for (const d of dayIndexes) {
			for (const s of slotIndexes) {
				if (!set || ((this.slots[d] & (2 ** s)) == 0)) {
					this.slotsSpinning[d] = turnBitOnOrOff(this.slotsSpinning[d], s);
				}
			}
		}
	}
}