import { Injectable } from "@angular/core";
import { AppAreaIdentifier, SitesAreaAccess, SitesAreaRawData } from "@me-interfaces";
import { AppAreaService } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { DexieService } from "@me-services/core/dexie";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";
import { PageSpinnerService } from "@me-services/ui/page-spinner";
import { UrlService } from "@me-services/ui/url";
import { UserAreaService } from "@me-user-area";
import { mapToData } from "./map-to-data";
import { SitesAreaData } from "./sites-area-data";

const NO_ACCESS: SitesAreaAccess = {
	root: 'None',
	sub: {
		activePrograms: 'None',
		upcomingEvents: 'None',
		permissions: 'None',
	}
};

@Injectable({
	providedIn: 'root',
})
export class SitesAreaService extends AppAreaService<AppAreaIdentifier<string>, SitesAreaAccess, SitesAreaRawData, SitesAreaData> {

	//
	// Sub areas
	//



	constructor(
		urlService: UrlService,
		dexieService: DexieService,
		ds: DataService,
		spinnerService: PageSpinnerService,
		util: UtilityService,
		func: FuncService,
		userAreaService: UserAreaService,
	) {
		super(
			'Staff',
			userAreaService.user$,
			'Sites',
			dexieService,
			ds,
			spinnerService,
			util,
			urlService.appAreas.sites$,
			func.areas.sites.get,
			async () => ({ area: 'Sites', access: NO_ACCESS, md5Hash: 'NO_ACCESS' }),
			async rawData => await mapToData(rawData, util, ds),
			data => 'Sites',
		);
	}

}