import { NationalDashboardPageService } from '@ADMIN-area';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridSetup, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { AppAreaIdentifier } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface AcceleratorRow {
	accId: number,
	siteCode: string,
	siteId: number,
	updatedUTC: number,
}

@Component({
	selector: 'shared-accelerators-view-part',
	templateUrl: './accs-view.part.html',
})
export class SharedAcceleratorsViewPart extends DestroyablePart implements OnInit {

	@Input() identifier: AppAreaIdentifier<number>;
	@ViewChild(GridPart) meGrid: GridPart<AcceleratorRow>;

	public gridSetup = this.setupGrid();
	public rows$: Observable<AcceleratorRow[]> = of([]);


	constructor(
		private util: UtilityService,
		public ds: DataService,
		public pageService: NationalDashboardPageService
	) {
		super();
	}


	ngOnInit(): void {

		if (!this.identifier) throw new Error(`The identifer attribute was not set for <shared-accelerators-view-part>`);

		super.initDestroyable();

		this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(utc => this.buildRows(this.ds)));
	}


	async buildRows(ds: DataService): Promise<AcceleratorRow[]> {

		const accelerators = await ds.admin.accelerator.getAllPackagesAsArray();

		if (!accelerators) return [];

		const rows: AcceleratorRow[] = accelerators.map(a => ({
			accId: a.accId,
			siteCode: a.siteProgram.site.code,
			siteId: a.siteProgram.siteId,
			updatedUTC: a.updatedUTC,
		}));
		return rows;
	}



	private setupGrid(): GridSetup<AcceleratorRow> {

		return {
			experience: 'ACC',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Accelerator",
			rowPluralName: "Accelerators",
			rowKey: "accId",
			stateKey: "national-all-accelerator",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			initialState: {
				sort: [{ field: 'ACC_startUTC', dir: 'desc' }],
			},
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: AcceleratorRow[] }) {

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}
	}

}