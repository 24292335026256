import { AccAreaService, AccStageEditorDialog } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId, Accelerator } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogService } from '@me-services/ui/dialog';


@Component({
	selector: 'stage-editor-part',
	templateUrl: './stage-editor.part.html',
})
export class AccStageEditorPart extends DestroyablePart implements OnInit {

	acc: Accelerator;
	readonly: boolean;

	constructor(
		public util: UtilityService,
		public ds: DataService,
		private dialogService: DialogService,
		public accAreaService: AccAreaService,
	) {
		super();
	}

	async ngOnInit() {
		super.initDestroyable();

		super.subscribe([this.accAreaService.accessAndId$, this.accAreaService.accelerator.acc$], async ([accessAndId, acc]) => {
			this.readonly = accessAndId?.access?.root != 'Write';
			this.acc = acc;
		});

	}


	async openStageDialog() {
		if (this.readonly) return;

		const action: DialogAction<AccStageId> = await this.dialogService.showCustom(
			AccStageEditorDialog,
			{
				data: {
					accStageId: this.acc.accStageId,
					readonly: this.readonly
				},
			},
			400, 250
		);

		const id = action?.id;
		if (id == 'save') {
			await this.accAreaService.accelerator.actions.setStage({ accStageId: action.callbackResult })
		}
	}

}