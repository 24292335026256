export const environment = {
	appCode: 'ME',
	name: 'ME-PROD',
	functionUrl: "https://us-central1-me-prod.cloudfunctions.net/",
	firebaseConfig: {
		apiKey: "AIzaSyD4xqUTaFh54w1lwk6wv0o8g2Aes04a8Ho",
		authDomain: "me-prod.firebaseapp.com",
		databaseURL: "https://me-prod.firebaseio.com",
		projectId: "me-prod",
		storageBucket: "me-prod.appspot.com",
		messagingSenderId: "895309017798",
		appId: "1:895309017798:web:84efad2e3ec2c812b79dc7",
		measurementId: "G-2NSDYK0LS4"
	},
	// highlightConfig: {
	// 	projectId: '4d73kq0g',
	// 	environment: 'Prod',
	// },
	google_tag_manager_container_id: {
		eforAll: 'GTM-N9R8TGK',
		eforever: 'GTM-NQPDDSS',
	},
};
