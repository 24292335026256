import { AccApplicationContext, AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId, AccTeam, Accelerator, Answer, DbaAccJudgement, FeedbackData, QuestionTypeId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { combineLatest, mergeMap } from 'rxjs';


interface JudgesFeedback {
	acc: Accelerator,
	feedback: FeedbackData[],
}


@Component({
	selector: 'acc-final-pitch-feedback-view-part',
	templateUrl: './acc-final-pitch-feedback-view.part.html',
})
export class AccFinalPitchFeedbackViewPart extends DestroyablePart implements OnInit {
	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.Curriculum);

	readonly: boolean;

	judgesFeedback$ = combineLatest([
		this.accAreaService.accelerator.acc$,
		this.accAreaService.applications.applications$,
		this.accAreaService.judging.assessments$,
		this.accAreaService.teams.teams$
	]).pipe(mergeMap(data => this.buildFeedbackData(data[0], data[1], data[2], data[3])));

	constructor(
		private accAreaService: AccAreaService,
		private ds: DataService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe([this.accessAtStage$], async ([accessAtStage]) => {
			this.readonly = accessAtStage.access != 'Write';
		});
	}


	async buildFeedbackData(
		acc: Accelerator,
		allApplications: readonly AccApplicationContext[],
		judgeAssessments: {
			judgement: DbaAccJudgement,
			answers: readonly Answer[],
		}[],
		accTeams: readonly AccTeam[],
	): Promise<JudgesFeedback> {

		if (!acc || !allApplications || !judgeAssessments.length || !accTeams) undefined;

		const accJudges = await this.ds.admin.accJudge.getManyAsArray([...judgeAssessments.map(ja => ja.judgement.accJudgeId)]);
		const people = await this.ds.admin.person.getManyPackagesAsMap([
			...accJudges.map(aj => aj.personId),
		]);

		const judgesFeedBack = [];
		const activeAccTeams = accTeams.filter(at => !at.droppedOutWeek);

		for (const at of activeAccTeams) {
			const teamJudgeAssessments = judgeAssessments.filter(ra => ra.judgement.accTeamId === at.accTeamId);

			if (teamJudgeAssessments.length) {

				judgesFeedBack.push({
					applicationId: at.application.applicationId,
					teamId: at.accTeamId,
					firstNames: at.application.person.firstName,
					emails: at.application.person._email,
					company: at.application.company.longName,
					hasLogo: false,
					status: 'Accepted',
					feedback: teamJudgeAssessments.map(ja => {
						const originalFeedback = ja.answers.find(a => a.question.questionTypeId === QuestionTypeId.EntrepreneurFeedback).answer;
						const feedbackForEforAll = ja.answers.find(a => a.question.questionTypeId === QuestionTypeId.Ent_Feedback_Eforall)?.answer;
						const accJudge = accJudges.find(aj => aj.accJudgeId === ja.judgement.accJudgeId);

						return {
							reviewerId: ja.judgement.accJudgeId,
							reviewer: people[accJudge.personId].asSingleton,
							originalFeedback,
							feedbackForEforAll: feedbackForEforAll ?? '',
							polishedFeedback: ja.judgement.polishedFeedback,
							polishedFeedbackSkipped: ja.judgement.polishedFeedbackSkipped,
						}
					})
				})
			}
		}

		return { acc, feedback: judgesFeedBack };
	}

	polishedFeedbackSkippedChanged(data: { reviewerId: number, applicationId: number, teamId: number, polishedFeedbackSkipped: boolean }) {
		if (this.readonly) return;
		const { teamId, polishedFeedbackSkipped, reviewerId } = data;
		this.accAreaService.judging.actions.setPolishedFeedbackSkipped({ accJudgeId: reviewerId, accTeamId: teamId, polishedFeedbackSkipped });
	}

	polishedFeedbackChanged(data: { reviewerId: number, applicationId: number, teamId: number, polishedFeedback: string }) {
		if (this.readonly) return;
		const { teamId, polishedFeedback, reviewerId } = data;
		this.accAreaService.judging.actions.setPolishedFeedback({ accJudgeId: reviewerId, accTeamId: teamId, polishedFeedback });
	}

}