import { AccApplicationContext, AccAreaService, AccPreAcceleratorPageService, AccPreAcceleratorTabId, AccessAtStage, accTransferApplication } from '@ACC-area';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { ApplicationCols, GridAction, GridColumnType, GridSetup } from '@me-grid';
import { AccStageId, ApplicationStatusId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogService } from '@me-services/ui/dialog';
import { GridPart, Icon } from '@me-shared-parts/UI-common';
import { combineLatest, map } from 'rxjs';
import { applyApplicationGridFilter } from '../../../acc-application-filter-helpers';
import { AccApplicationStatusName, SET_TO_ABANDONED_ACTION_KEY, SET_TO_ACCEPTED_ACTION_KEY, SET_TO_DEFERRED_ACTION_KEY, SET_TO_PENDING_ACTION_KEY, SET_TO_REJECTED_ACTION_KEY, SET_TO_WITHDRAWN_ACTION_KEY, canSetApplicationStatus, getApplicationStatusNameByStage } from '../../../acc-application-status-helpers';


const STAGE_ID = AccStageId.Accepting;
const PENDING_APPLICATION_STATUS_ID = ApplicationStatusId.AcceptPending;
const ABANDONED_APPLICATION_STATUS_ID = ApplicationStatusId.AcceptAbandoned;
const WITHDRAWN_APPLICATION_STATUS_ID = ApplicationStatusId.AcceptWithdrawn;
const REJECTED_APPLICATION_STATUS_ID = ApplicationStatusId.AcceptRejected;
const DEFERRED_APPLICATION_STATUS_ID = ApplicationStatusId.AcceptDeferred;
const ACCEPTED_APPLICATION_STATUS_ID = ApplicationStatusId.AcceptSubmitted;


interface ApplicationRow {
	applicationId: number,
	applicationStatusId: ApplicationStatusId,
	status: AccApplicationStatusName,
	updatedUTC: number,
}


@Component({
	selector: 'acc-accepting-applications-view-part',
	templateUrl: './acc-accepting-applications-view.part.html',
})
export class AccAcceptingApplicationsViewPart extends DestroyablePart implements OnInit, AfterViewInit {

	@ViewChild(GridPart) meGrid: GridPart<ApplicationRow>;

	private accessAtStage: AccessAtStage;


	public gridSetup = this.setupGrid(false);


	/**
	 * Map applications into grid rows
	 */
	public rows$ = combineLatest([
		this.ds.admin.singletonsAsOfUTC$,
		this.accAreaService.applications.applications$,
		this.accAreaService.teams.teams$,
	])
		.pipe(map(([singletonsAsOfUTC, applications, accTeams]) => {
			if (!applications || !accTeams) return [];
			return this.buildRows(applications);

		}));




	constructor(
		public accAreaService: AccAreaService,
		public ds: DataService,
		public pageService: AccPreAcceleratorPageService,
		private dialogService: DialogService,
		private router: Router
	) {
		super();
	}


	ngOnInit() {

		super.initDestroyable();

		const accessAtStage$ = this.accAreaService.getAccessAtStage(STAGE_ID);

		super.subscribe([accessAtStage$], async ([accessAtStage]) => {
			this.accessAtStage = accessAtStage;
			this.gridSetup = this.setupGrid(accessAtStage.canViewDemographics);
		});

	}


	ngAfterViewInit() {

		super.subscribe([this.pageService.applyApplicationsFilter$], async ([filter]) => {
			applyApplicationGridFilter(STAGE_ID, this.meGrid.grid, filter);
			this.pageService.selectTab(AccPreAcceleratorTabId.Manage)
		});

	}


	setupGrid(canViewDemographics: boolean): GridSetup<ApplicationRow> {

		const setup: GridSetup<ApplicationRow> = {
			experience: 'APPLICATION',
			multiselect: true,
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Application",
			rowPluralName: "Applications",
			rowKey: "applicationId",
			stateKey: "acc-applying-applications-view",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			showDemographics: canViewDemographics,
			columnsToAdd: [
				{ field: 'status', header: "Status", width: 100, type: GridColumnType.text, hidden: false },
				{ field: ApplicationCols.companyCityAndState, header: "Location", width: 150, type: GridColumnType.text, hidden: false },
				{ field: ApplicationCols.participantNames, header: "Participants", width: 225, type: GridColumnType.text, hidden: false },
			],
			actionEnabler: this.gridActionEnabler.bind(this),
			initialState: {
				sort: [{ field: ApplicationCols.companyName, dir: 'asc' }],
				filter: {
					logic: 'and',
					filters: [{ field: 'status', operator: 'neq', value: 'Beyond' }],
				}
			},
		};

		setup.actions = [
			{ key: SET_TO_PENDING_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Pending' },
			{ key: SET_TO_ABANDONED_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Abandoned' },
			{ key: SET_TO_WITHDRAWN_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Withdrawn' },
			{ key: SET_TO_REJECTED_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Rejected' },
			{ key: SET_TO_DEFERRED_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Deferred' },
			{ key: SET_TO_ACCEPTED_ACTION_KEY, icon: Icon.action_edit, label: 'Set to Submitted' },
			{ key: 'transfer-action', icon: Icon.action_transfer, label: 'Transfer' },
		];

		return setup;
	}


	buildRows(
		applications: readonly AccApplicationContext[],
	): ApplicationRow[] {

		const map = applications
			.map(a => ({
				applicationId: a.application.applicationId,
				applicationStatusId: a.application.applicationStatusId,
				status: getApplicationStatusNameByStage(STAGE_ID, a.application.applicationStatusId),
				demographics: a.demographics,
				updatedUTC: a.application.updatedUTC,
			}));

		return map;

	}


	gridActionEnabler(action: GridAction, rows: ApplicationRow[]): boolean {

		if (rows.length == 0) return false;

		if (this.accessAtStage == undefined) {
			console.error(`this.accessAtStage is undefined`);
			return false;
		}

		const canEdit = this.accessAtStage.access == 'Write';
		const currentAccStageId = this.accessAtStage.currentStageId;

		if (
			action.key == SET_TO_PENDING_ACTION_KEY ||
			action.key == SET_TO_ABANDONED_ACTION_KEY ||
			action.key == SET_TO_WITHDRAWN_ACTION_KEY ||
			action.key == SET_TO_REJECTED_ACTION_KEY ||
			action.key == SET_TO_DEFERRED_ACTION_KEY ||
			action.key == SET_TO_ACCEPTED_ACTION_KEY
		) {

			return canSetApplicationStatus(this.accessAtStage.canUpdateApplicationStatuses, currentAccStageId, STAGE_ID, action.key, rows.map(row => row.applicationStatusId));
		}


		if (rows.length > 1) return false;	// Multi is only supported with the application status setters

		if (action.key == 'transfer-action') {
			return canEdit;
		}

		return true;
	}


	async gridActionHandler(action: { actionKey: string, rows: ApplicationRow[] }) {

		const { actionKey, rows } = action;

		if (!rows.length) return;

		const applicationIds = rows.map(row => row.applicationId);


		//
		// Perform the bulk change
		//
		if ([
			SET_TO_PENDING_ACTION_KEY,
			SET_TO_ABANDONED_ACTION_KEY,
			SET_TO_WITHDRAWN_ACTION_KEY,
			SET_TO_REJECTED_ACTION_KEY,
			SET_TO_DEFERRED_ACTION_KEY,
			SET_TO_ACCEPTED_ACTION_KEY,
		].includes(actionKey)) {

			let applicationStatusId: ApplicationStatusId = REJECTED_APPLICATION_STATUS_ID;
			let status = 'Rejected';

			if (actionKey == SET_TO_PENDING_ACTION_KEY) {
				applicationStatusId = PENDING_APPLICATION_STATUS_ID;
				status = 'Pending';
			}
			if (actionKey == SET_TO_ABANDONED_ACTION_KEY) {
				applicationStatusId = ABANDONED_APPLICATION_STATUS_ID;
				status = 'Abandoned';
			}
			if (actionKey == SET_TO_WITHDRAWN_ACTION_KEY) {
				applicationStatusId = WITHDRAWN_APPLICATION_STATUS_ID;
				status = 'Withdrawn';
			}
			if (actionKey == SET_TO_REJECTED_ACTION_KEY) {
				applicationStatusId = REJECTED_APPLICATION_STATUS_ID;
				status = 'Rejected';
			}
			if (actionKey == SET_TO_DEFERRED_ACTION_KEY) {
				applicationStatusId = DEFERRED_APPLICATION_STATUS_ID;
				status = 'Deferred';
			}
			if (actionKey == SET_TO_ACCEPTED_ACTION_KEY) {
				applicationStatusId = ACCEPTED_APPLICATION_STATUS_ID;
				status = 'Submitted';
			}

			const message = `Would you like to change the status of the following applications to '${status}'?`;

			const applicationsById = await this.ds.admin.application.getManyAsMap(rows.map(row => row.applicationId));
			const bullets = rows.map(row => applicationsById[row.applicationId]?.companyName ?? `Application #'${row.applicationId}'`).sort();

			const yes = await this.dialogService.confirm(message, 400, 150, undefined, undefined, bullets);
			if (yes) await this.accAreaService.applications.actions.setBulkStatus({ applicationIds, applicationStatusId });
		}

		else if (actionKey == 'transfer-action') {

			if (rows.length > 1) return;

			await accTransferApplication(this.router, this.accessAtStage.access != 'Write', this.ds, this.accAreaService, rows[0].applicationId, rows[0].applicationStatusId);
		}

	}
}