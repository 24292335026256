import { Component, Input, OnChanges } from '@angular/core';
import { GridColumn, GridColumnType, GridValueStyle } from '@me-grid';


@Component({
	selector: 'me-grid-cell',
	templateUrl: './SHR-CMN_grid-cell.part.html',
	styleUrls: ['SHR-CMN_grid-cell.part.scss'],
})
export class GridCellPart implements OnChanges {

	@Input('column') column: GridColumn<unknown>;
	@Input('row') row: unknown;

	public style: GridValueStyle = {};
	public isNumeric = false;
	public tooltip: string = undefined;
	public strikethrough = false;

	ngOnChanges() {

		if (this.column && this.row) {
			this.style = this.getStyle(this.row, this.column);
			this.isNumeric = [GridColumnType.number, GridColumnType.percent, GridColumnType.dollars, GridColumnType.zipCode].includes(this.column.type);

			if (this.column.strikethrough) this.strikethrough = this.column.strikethrough(this.row);
			if (this.column.tooltip) this.tooltip = this.column.tooltip(this.row);
		}
	}


	getStyle(row: unknown, col: GridColumn<unknown>) {
		return !col.valueStyle ? {} : col.valueStyle(row, col.field) ?? {};
	}

}