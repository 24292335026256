import { AccAreaService, AccCohortPageService } from '@ACC-area';
import { Component } from '@angular/core';
import { AccSurveyResponseSessionCols, GridSetup } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridColumnConfig, buildColumn } from '@me-shared-parts/UI-common/grid/columns';
import { mergeMap } from 'rxjs';


@Component({
	selector: 'acc-curriculum-surveys-by-session-view-part',
	templateUrl: './acc-curriculum-surveys-by-session-view.part.html',
})
export class AccCurriculumSurveysBySessionViewPart {

	public gridSetup = this.setupGrid();

	public rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(async utc => {

		const acc = this.areaService.accelerator.acc$.value;
		const accSessionSurveyResponsesByAccIdMap = await this.ds.admin.accSessionSurveyResponse.getByAccIds([acc.accId]);
		const responses = accSessionSurveyResponsesByAccIdMap[acc.accId];

		const attendees = this.areaService.curriculum.sessionAttendees$.value;
		const attendeeById = this.util.array.toMap(attendees, attendee => attendee.accSessionAttendeeId);

		const rows = responses.map(response => {

			const attendee = attendeeById[response.accSessionAttendeeId];

			return {
				accSessionSurveyResponseId: response.accSessionSurveyResponseId,
				entrepreneurName: attendee.person.fullName,
				entrepreneurPersonId: attendee.person.personId,
				companyName: attendee.accTeam.company.name,
				companyId: attendee.accTeam.company.companyId,
			};
		});

		return rows;
	}));


	constructor(
		private ds: DataService,
		private util: UtilityService,
		public pageService: AccCohortPageService,
		private areaService: AccAreaService,
	) {
	}


	private setupGrid(): GridSetup<{ accSessionSurveyResponseId: number }> {

		return {
			experience: 'ACC_SURVEY_RESPONSE_SESSION',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Session Response",
			rowPluralName: "Session Responses",
			rowKey: "accSessionSurveyResponseId",
			stateKey: "acc-curriculum-surveys-by-session-part",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				buildColumn('companyId', GridColumnConfig.companyId, { hidden: true }),
				buildColumn('entrepreneurPersonId', GridColumnConfig.personId, { hidden: true }),
				buildColumn('companyName', GridColumnConfig.companyName, { hidden: true, header: 'Business' }),
				buildColumn('entrepreneurName', GridColumnConfig.personFullName, { header: 'Entrepreneur' }),
			],
			columnsToAlter: [
				{ field: AccSurveyResponseSessionCols.session, hidden: true }
			],
			initialState: {
				group: [{ field: AccSurveyResponseSessionCols.session, dir: 'asc' }],
				sort: [{ field: 'entrepreneurName', dir: 'asc' }],
			},
		};
	}

}