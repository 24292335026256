import { AccAreaData, AccMatcherV2Dialog } from "@ACC-area";
import { DialogService } from "@me-services/ui/dialog";
import { ensureCompatibleTimeZones } from "./ensure-compatible-timezones";


export async function matchV2(dialogService: DialogService, data: AccAreaData): Promise<void> {

	data = await ensureCompatibleTimeZones(dialogService, data);
	if (!data) return;

	const action = await dialogService.showCustom(AccMatcherV2Dialog, { data }, 1000, 1000);
}