import { QuestionTypeId } from '@me-interfaces';
import { DdQuestionTypeRow } from './dd-question-type-row';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';

/**
 * @deprecated Use the DataService instead
 */
export class DdQuestionType extends DdObject<DdQuestionTypeRow> implements DdQuestionTypeRow {

	constructor(dd: OldDomainDataService, data: DdQuestionTypeRow) {
		super(data);
	}

	public get questionTypeId(): QuestionTypeId {
		return this._data.questionTypeId;
	}

	public get name(): string {
		return this._data.name;
	}
}