
import { DdQuestionTypeRow } from './dd-question-type-row';
import { DdQuestionType } from './dd-question-type';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdQuestionTypes extends DdObjects<DdQuestionTypeRow, DdQuestionType> {

	constructor(private dd: OldDomainDataService) {
		super('questionTypeId');
	}

	async loadData(hash: string, data: DdQuestionTypeRow[]) {
		const objects = data.map(d => new DdQuestionType(this.dd, d));
		this.loadObjects(hash, objects);
	}
}