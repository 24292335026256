import { TopicMaterialTypeId, WebLinkTypeId } from "@me-interfaces";
import { getIconContext } from "./get-icon-context";
import { Icon } from "./icon";
import { IconContext } from "./icon-context";
import { IconHideTextAt } from "./icon-hide-text-at";
import { IconStyle } from "./icon-style";

/**
 * Return the Icon value for a given TopicMaterialTypeId
 */
export function getIconForTopicMaterialType(topicMaterialTypeId: TopicMaterialTypeId): Icon {


	if (topicMaterialTypeId == TopicMaterialTypeId.Blog) return Icon.material_blog;
	if (topicMaterialTypeId == TopicMaterialTypeId.Doc) return Icon.material_doc;
	if (topicMaterialTypeId == TopicMaterialTypeId.Handout) return Icon.material_handout;
	if (topicMaterialTypeId == TopicMaterialTypeId.Pdf) return Icon.material_pdf;
	if (topicMaterialTypeId == TopicMaterialTypeId.Slide) return Icon.material_slide;
	if (topicMaterialTypeId == TopicMaterialTypeId.Spreadsheet) return Icon.material_spreadsheet;
	if (topicMaterialTypeId == TopicMaterialTypeId.Video) return Icon.material_video;
	if (topicMaterialTypeId == TopicMaterialTypeId.Weblink) return Icon.material_weblink;
	
	return Icon.undefined;
}


export function getIconContextForTopicMaterialType(topicMaterialTypeId: TopicMaterialTypeId, iconStyle?: IconStyle, hideText?: IconHideTextAt, text?: string, hasRedFlag?: boolean): IconContext {
	return getIconContext(getIconForTopicMaterialType(topicMaterialTypeId), iconStyle, hideText, text, hasRedFlag);
}