import { AccInterviewer, Answer, DbaAccInterview } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { CountRow, CountsTableData } from '@me-shared-parts/UI-common';
import { AccApplicationContext } from '@ACC-area';
import { AccPreAcceleratorStageId, getStageStatuses } from '../../acc-application-status-helpers';


export async function buildInterviewGeneralCounts(
	ds: DataService,
	util: UtilityService,
	accStageId: AccPreAcceleratorStageId,
	applications: readonly AccApplicationContext[],
	interviewers: readonly AccInterviewer[],
	interviewAssessments: { interview: DbaAccInterview, answers: readonly Answer[] }[],
): Promise<CountsTableData> {

	if (!applications || !interviewers || !interviewAssessments) return undefined;

	const statuses = getStageStatuses(accStageId);

	const apps = applications
		.map(ctx => ctx.application)
		.filter(app => app.applicationStatusId >= statuses.pending.applicationStatusId);


	const applicantPersonIds: number[] = apps.map(app => app.personId);

	const applicationsRow: CountRow = {
		label: 'Interviewable Applications',
		count: apps.length,
		personIds: applicantPersonIds,
	}


	const configuredInterviewerPersonIds: number[] = util.array.cleanNumericIds(interviewers.map(i => i.personId));

	const configuredInterviewersRow: CountRow = {
		label: 'Configured Interviewers',
		count: configuredInterviewerPersonIds.length,
		personIds: configuredInterviewerPersonIds,
	}


	const activeInterviewers = await ds.admin.accInterviewer.getManyAsArray(interviewAssessments.map(a => a.interview.accInterviewerId));
	const activeInterviewerPersonIds: number[] = activeInterviewers.map(i => i.personId);

	const activeInterviewersRow: CountRow = {
		label: 'Active Interviewers',
		count: activeInterviewerPersonIds.length,
		personIds: activeInterviewerPersonIds,
	}

	const importedInterviewerPersonIds: number[] = util.array.cleanNumericIds(activeInterviewerPersonIds.filter(personId => !configuredInterviewerPersonIds.includes(personId)));

	const importedInterviewerRow: CountRow = {
		label: 'Imported Interviewers',
		count: importedInterviewerPersonIds.length,
		personIds: importedInterviewerPersonIds,
	}
	

	const interviewsRow: CountRow = {
		label: 'Interviews',
		count: interviewAssessments.length,
		personIds: [],
	}


	const result: CountsTableData = {
		header: 'Item',
		rows: [
			applicationsRow,
			activeInterviewersRow,
			configuredInterviewersRow,
			importedInterviewerRow,
			interviewsRow,
		],
	};

	return result;
}