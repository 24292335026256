<div class="public-person-dialog">
	<me-dialog-frame [header]='header' [showToolbar]='false' [dialogRef]='dialogRef' icon='far fa-user'>
		<me-avatar [avatar]='person'></me-avatar>

		<table
			class='pro-table pro-table-no-header pro-table-with-labels pro-table-with-thin-rows pro-table-without-border'>
			<tr>
				<td>
					{{inlineLabels['Name']}}
				</td>
				<td>
					{{person._name}}
				</td>
			</tr>

			<tr>
				<td>
					{{inlineLabels['Phone']}}
				</td>
				<td>
					<div *ngIf="!personPhone?.phone" class='missing-value'>
						<me-label key='Phone not provided'></me-label>
					</div>
					<phone-link *ngIf="personPhone?.phone" [phone]="personPhone"></phone-link>
				</td>
			</tr>
		</table>

		<div class="weblinks">
			<a class="btn" *ngIf='emailLink' [href]='emailLink'>
				<me-icon [icon]="emailIcon"></me-icon>
			</a>

			<a class="btn" *ngFor='let webLink of person.webLinks || []' target='_blank' [href]='webLink.url'>
				<me-icon [icon]="getWebLinkIcon(webLink)">
				</me-icon>
			</a>
		</div>

	</me-dialog-frame>
</div>