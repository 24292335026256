<div class="add-venue-part">
	<div class='search-row'>
		<div>Search for a Venue:</div>
		<input #searchField type="text" placeholder="Search" kendoTextBox />
	</div>

	<div #searchMap style='height:350px;'></div>

	<div #infowindowContent>
		<ng-container *ngIf='place'>
			{{place.officialName}}
		</ng-container>
	</div>

	<ng-container *ngIf='place'>
		<div *ngIf='place.zipId' class="address" (click)='logPlace()'>
			<div>
				<b>{{place.officialName}}</b>
				<br>
				<small>{{place.address}}</small>
			</div>
		</div>
		<div *ngIf='!place.zipId' class="address-without-zip" (click)='logPlace()'>
			<div>
				{{place.officialName}} is not a valid venue.<br>It does not have a zip code.
			</div>
		</div>
	</ng-container>

</div>