/**
 * A structure of length constants with the pattern
 * DB_LENGTH.table.column = [min, max];
 */
export const DB_LENGTH = {

	webLink: {
		url: [0, 100] as const,
	} as const,

	company: {
		longName: [5, 40] as const,
	} as const,

	email: {
		email: [0, 100] as const,
	} as const,

	person: {
		firstName: [0, 20] as const,
		middleInit: [0, 1] as const,
		lastName: [0, 20] as const,
		phone: [0, 50] as const,
		genderOther: [0, 45] as const,
		raceOther: [0, 45] as const,
		wpUserName: [0, 45] as const,
		wpTitle: [0, 45] as const,
		wpLocation: [0, 45] as const,
		preferredName: [0, 35] as const,
		formerName: [0, 35] as const,
	} as const,

	position: {
		extension: [0, 6] as const,
		phone: [0, 50] as const,
		title: [3, 50] as const,
	} as const,

	event: {
		name: [0, 45] as const,
	} as const,

	accTeamGoal: {
		summary: [1, 60] as const
	} as const,

};