<div class="public-company-dialog">
	<me-dialog-frame [header]='header' [showToolbar]='false' [dialogRef]='dialogRef' [icon]='icon'>
		<table
			class='pro-table pro-table-no-header pro-table-with-labels pro-table-with-thin-rows pro-table-without-border'>
			<tr>
				<td>
					{{inlineLabels['Name']}}
				</td>
				<td>
					{{company._name}}
				</td>
			</tr>

			<tr>
				<td>
					{{inlineLabels['Phone']}}
				</td>
				<td>
					<div *ngIf="!companyPhone?.phone" class='missing-value'>
						<me-label key='Phone not provided'></me-label>
					</div>
					<phone-link *ngIf="companyPhone?.phone" [phone]="companyPhone"></phone-link>
				</td>
			</tr>

			<tr *ngIf="showAddress">
				<td>
					{{inlineLabels['Address']}}
				</td>
				<td>
					<address-link [address]='company.address'></address-link>
				</td>
			</tr>

		</table>

		<div class="weblinks">
			<a class="btn" *ngFor='let webLink of company.webLinks || []' target='_blank' [href]='webLink.url'>
				<me-icon [icon]="getWebLinkIcon(webLink)">
				</me-icon>
			</a>

			<a class="btn" *ngIf='smsLink' [href]='smsLink'>
				<me-icon [icon]="smsIcon">
				</me-icon>
			</a>
		</div>
	</me-dialog-frame>
</div>